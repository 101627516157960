import Axios from '../Helpers/Axios';

export function GetAllCategory(token) {
    return Axios('LostAndFound/Category/GetAll', 'get', null, token)
}
export function AddOrEditCategory(data, token) {
    return Axios('LostAndFound/Category/AddorEdit', 'post', data, token)
}
export function GetActiveCategory(token) {
    return Axios('LostAndFound/Category/ActiveCategories', 'get', null, token)
}
export function GetCategory(token) {
    return Axios('LostAndFound/Category/GetAll', 'get', null, token)
}
export function GetStatusForLostItem(token) {
    return Axios('LostAndFound/Lost/Status', 'get', null, token)
}