import Axios from "../Helpers/Axios";

export function GetPantryCategoryList(token){
    return Axios(`VIPLounge/VIPPantryStaff/ListItemCategory`, "get", null, token);
};  
export function AddPantryCategory(data, token){
    return Axios(`VIPLounge/VIPPantryStaff/AddItemCategory`, "post", data, token);
};  
export function EditPantryCategory(data, token){
    return Axios(`VIPLounge/VIPPantryStaff/EditItemCategory`, "post", data, token);
};  
export function DeletePantryCategory(data, token){
    return Axios(`VIPLounge/VIPPantryStaff/DeleteItemCategory`, "post", data, token);
};  
