import { useCookies } from 'react-cookie';
import React, { useEffect, useState } from 'react';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import './style.scss';
import { Button } from 'primereact/button';
import { useNavigate, useParams } from 'react-router-dom';
import { AddReturnFormForm } from '../../Services/LostandFoundService';
import { InputTextarea } from 'primereact/inputtextarea';

//----------services---------------------------
import { GetReturnItem } from '../../Services/AdminReturnService'
import { GetAllImageFiles } from '../../Services/Files';
//---------------------------------------------


function AdminReturnView() {
    const { id } = useParams();
    const navigate = useNavigate();
    const [cookies, setCookie, removeCookie] = useCookies();
    const [returnFormdata, setReturnFormdata] = useState({
        "firstName": "",
        "lastName": "",
        "email": "",
        "phoneNumber": "",
        "witnessPersonName": "",
        "comment": "",
        "foundId": 0,
        "foundCode": "",
        "status": ""
    });
    const [isInvalid, setIsInvalid] = useState(false);

    const items = [
        { label: "item1" },
        { label: "item2" },
        { label: "item3" },
    ]


    const status = [
        { label: "Pending" },
        { label: "Delivered" },
    ]



    const getReturnItem = async (foundId) => {
        await GetReturnItem(foundId, cookies.bearerToken)
            .then((res) => {
                if (res.status === 200) {
                    //console.log("Found Item", res?.data);
                    setReturnFormdata(res?.data);
                    getAllImages(res?.data?.id);
                }
            })
            .catch((err) => {
                //console.log(err);
            })
            .finally(() => {
            })
    };

    const getAllImages = async (id) => {
        let data = {
            id: id,
            module: "Return",
            isForInspection: "",
            inspectionId: ""
        }
        await GetAllImageFiles(data, cookies.bearerToken)
            .then((res) => {
                if (res.status === 200) {
                    //console.log("Images", res?.data);
                }
            })
            .catch((err) => {
                //console.log(err);
            })

    };












    useEffect(() => {
        getReturnItem(id);
    }, [])



    return (
        <div className="ReturnForm">
            <div className="header" style={{ backgroundColor: '#ae9d81', color: 'white' }}>
                <i className="pi pi-arrow-left" style={{ cursor: 'pointer' }} onClick={() => navigate('/admin-lost-found/return')}></i>
                <div style={{ margin: 'auto', fontWeight: '600', fontSize: '18px' }}>View Return Form Details</div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', margin: '0px 50px' }}>
                <div>
                    <p>First Name<span style={{ color: 'red' }}>*</span></p>
                    <div>
                        <InputText placeholder='Enter First Name' style={{ width: '350px' }}
                            value={returnFormdata?.firstName} onChange={(e) => {
                                setReturnFormdata({ ...returnFormdata, firstName: e.target.value })
                            }} disabled />
                    </div>
                    {isInvalid && returnFormdata?.firstName == "" &&
                        <p style={{ color: "red" }}>First name  is required</p>
                    }
                </div>
                <div>
                    <p>Last Name<span style={{ color: 'red' }}>*</span></p>
                    <div>
                        <InputText placeholder='Enter Last Name' style={{ width: '350px' }}
                            value={returnFormdata?.lastName} onChange={(e) => {
                                setReturnFormdata({ ...returnFormdata, lastName: e.target.value })
                            }} disabled
                        />
                    </div>
                    {isInvalid && returnFormdata?.lastName == "" &&
                        <p style={{ color: "red" }}>Last name  is required</p>
                    }
                </div>
                <div>
                    <p>Email<span style={{ color: 'red' }}>*</span></p>
                    <div>
                        <InputText placeholder='Enter Email' style={{ width: '350px' }}
                            value={returnFormdata?.email} onChange={(e) => {
                                setReturnFormdata({ ...returnFormdata, email: e.target.value })
                            }} disabled
                        />
                    </div>
                    {isInvalid && returnFormdata?.email == "" &&
                        <p style={{ color: "red" }}>Email  is required</p>
                    }
                </div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', margin: '20px 50px 0px 50px' }}>
                <div>
                    <p>Mobile Number<span style={{ color: 'red' }}>*</span></p>
                    <div>
                        <InputText placeholder='Enter Mobile Number' type='number' style={{ width: '350px' }}
                            value={returnFormdata?.phoneNumber}
                            onChange={(e) => {
                                setReturnFormdata({ ...returnFormdata, phoneNumber: e.target.value })
                            }} disabled
                        />
                    </div>
                    {isInvalid && returnFormdata?.phoneNumber == "" &&
                        <p style={{ color: "red" }}>Mobile number  is required</p>
                    }
                </div>
                <div>
                    <p>Returned Item<span style={{ color: 'red' }}>*</span></p>
                    <div>
                        <InputText disabled placeholder='Enter Return Item Id' style={{ width: '350px', borderColor: '#ae9d81' }} value={returnFormdata?.foundCode} />
                    </div>
                    {isInvalid && id == "" &&
                        <p style={{ color: "red" }}>Found Id  is required</p>
                    }
                </div>
                <div>
                    <p></p>
                    <div style={{ width: '350px' }}>
                        {/* <Dropdown options={status} placeholder='Select Status' style={{ width: '350px', borderColor: '#ae9d81' }}
                            value={returnFormdata?.status}
                            onChange={(e) => {
                                setReturnFormdata({ ...returnFormdata, status: e.value })
                            }}
                        /> */}
                    </div>
                </div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', margin: '20px 50px 0px 50px' }}>
                <div>
                    <p> Witness Person Image<span style={{ color: 'red' }}>*</span></p>
                    <div>
                        <InputText className="calendar" style={{ width: '350px' }} disabled
                        />
                        <i className='pi pi-camera' style={{ marginLeft: '-35px' }}></i>
                    </div>
                    {/* {isInvalid && images == "" &&
                        <p style={{ color: "red" }}>Mobile number  is required</p>
                    } */}
                </div>
                <div>
                    <p>Witness Person Name<span style={{ color: 'red' }}>*</span></p>
                    <div>
                        <InputText placeholder='Witness Person Name' style={{ width: '350px', borderColor: '#ae9d81' }}
                            value={returnFormdata?.witnessPersonName} onChange={(e) => {
                                setReturnFormdata({ ...returnFormdata, witnessPersonName: e.target.value })
                            }} disabled
                        />
                    </div>
                    {isInvalid && returnFormdata?.witnessPersonName == "" &&
                        <p style={{ color: "red" }}>Mobile number  is required</p>
                    }
                </div>
                <div style={{ marginTop: '-103px' }}>
                    <p>Comment</p>
                    <div>
                        <InputTextarea style={{ width: '350px', height: '150px', borderColor: "#ae9d81" }} value={returnFormdata?.comment}
                            onChange={(e) => {
                                setReturnFormdata({ ...returnFormdata, comment: e.target.value })
                            }} disabled
                        />
                    </div>
                    {isInvalid && returnFormdata?.comment == "" &&
                        <p style={{ color: "red" }}>Comment  is required</p>
                    }
                </div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'end', gap: '20px', margin: "10px 50px 50px 0px" }}>
                <Button label='Cancel' className='cnl-btn' style={{ border: 'none' }} onClick={() => navigate('/admin-lost-found/return')} />

            </div>

        </div>
    )
}

export default AdminReturnView