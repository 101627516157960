// import Webcam from "react-webcam";
// import { useRef, useState, useCallback } from "react";
// import { Dialog } from "primereact/dialog";

// function CustomWebcam({ setIsVisible, image, setImage, isMultiplePhotos, setAttachments, files }) {
//   const webcamRef = useRef(null);
//   const [imgSrc, setImgSrc] = useState(null);
//   const [mirrored, setMirrored] = useState(true);

//   const capture = useCallback(() => {
//     const imageSrc = webcamRef.current.getScreenshot();
//     setImgSrc(imageSrc);
//     // setImage(imageSrc);
//     //console.log('img', imageSrc)
//   }, [webcamRef]);

//   const retake = () => {
//     setImgSrc(null);
//   };
//   const submit = () => {
//     if (isMultiplePhotos) {
//       setAttachments((() => ({ ...files, attachments: [...files.attachments, { base64: imgSrc, type: "image", name: `Feedback` + new Date().getFullYear() + new Date().getMonth() + new Date().getDate() + new Date().getSeconds() + "." + imgSrc?.split(";base64,")[0].split("/")[1] }] })))
//     }
//     else {
//       setImage(imgSrc)
//     }
//     setIsVisible(false);

//   }

//   // return (
//   //   <Dialog
//   //     header="Take Picture"
//   //     visible={true}
//   //     style={{ width: "50vw" }}
//   //     onHide={() => setIsVisible(false)}
//   //   >
//   //     <div className="container" style={{ textAlign: "center" }}>
//   //       {/* <h1 style={{ textAlign: "left", padding: "0 16px", fontSize: 24, fontWeight: "600" }}>Camera</h1> */}
//   //       {imgSrc ? (
//   //         <img src={imgSrc} alt="webcam" height={400} width={600} />
//   //       ) : (
//   //         <Webcam
//   //           ref={webcamRef}
//   //           mirrored={true}
//   //           screenshotFormat="image/jpeg"
//   //           screenshotQuality={0.8}
//   //           height={400}
//   //           width={600}
//   //         />
//   //       )}
//   //       <div className="controls"></div>
//   //       <div
//   //         className="btn-container"
//   //         style={{ margin: "10px 0", textAlign: "center" }}
//   //       >
//   //         {imgSrc ? (
//   //           <>
//   //             {/* <button onClick={retake} style={{ backgroundColor: '#ae9d81', border: 'none', height: '30px', width: '140px', color: 'white', fontSize: '15px', fontWeight: '600', borderRadius: '5px' }}>Retake photo</button> */}
//   //             <button
//   //               onClick={() => {
//   //                 setIsVisible(false);
//   //                 setImage(imgSrc);
//   //                 getImage(imgSrc);
//   //               }}
//   //               style={{
//   //                 backgroundColor: "#ae9d81",
//   //                 border: "none",
//   //                 height: "30px",
//   //                 width: "140px",
//   //                 color: "white",
//   //                 fontSize: "15px",
//   //                 fontWeight: "600",
//   //                 borderRadius: "5px",
//   //                 margin: "0 10px",
//   //               }}
//   //             >
//   //               Submit{" "}
//   //             </button>
//   //             <button
//   //               onClick={retake}
//   //               style={{
//   //                 backgroundColor: "#ae9d81",
//   //                 border: "none",
//   //                 height: "30px",
//   //                 width: "140px",
//   //                 color: "white",
//   //                 fontSize: "15px",
//   //                 fontWeight: "600",
//   //                 borderRadius: "5px",
//   //                 margin: "0 10px",
//   //               }}
//   //             >
//   //               Retake photo
//   //             </button>
//   //             <button
//   //               onClick={() => {
//   //                 setIsVisible(false);
//   //                 setImage("");
//   //               }}
//   //               style={{
//   //                 backgroundColor: "#ae9d81",
//   //                 border: "none",
//   //                 height: "30px",
//   //                 width: "140px",
//   //                 color: "white",
//   //                 fontSize: "15px",
//   //                 fontWeight: "600",
//   //                 borderRadius: "5px",
//   //                 margin: "0 10px",
//   //               }}
//   //             >
//   //               Cancel{" "}
//   //             </button>
//   //           </>
//   //         ) : (
//   //           <div
//   //             style={{
//   //               display: "flex",
//   //               alignItems: "center",
//   //               justifyContent: "center",
//   //             }}
//   //           >
//   //             <button
//   //               onClick={capture}
//   //               style={{
//   //                 backgroundColor: "#ae9d81",
//   //                 border: "none",
//   //                 height: "30px",
//   //                 width: "140px",
//   //                 color: "white",
//   //                 fontSize: "15px",
//   //                 fontWeight: "600",
//   //                 borderRadius: "5px",
//   //                 margin: "0 10px",
//   //               }}
//   //             >
//   //               Capture photo
//   //             </button>
//   //             <button
//   //               onClick={() => setIsVisible(false)}
//   //               style={{
//   //                 backgroundColor: "#ae9d81",
//   //                 border: "none",
//   //                 height: "30px",
//   //                 width: "140px",
//   //                 color: "white",
//   //                 fontSize: "15px",
//   //                 fontWeight: "600",
//   //                 borderRadius: "5px",
//   //                 margin: "0 10px",
//   //               }}
//   //             >
//   //               Discard{" "}
//   //             </button>

//   return (
//     <Dialog header="Take Picture" visible={true} style={{ width: '50vw' }} onHide={() => setIsVisible(false)}>

//       <div className="container" style={{ textAlign: "center" }}>
//         {/* <h1 style={{ textAlign: "left", padding: "0 16px", fontSize: 24, fontWeight: "600" }}>Camera</h1> */}
//         {imgSrc ? (
//           <img src={imgSrc} alt="webcam" height={400} width={600} />
//         ) : (
//           <Webcam ref={webcamRef} mirrored={true} screenshotFormat="image/jpeg"
//             screenshotQuality={0.8} height={400} width={600} />
//         )}
//         <div className="controls">
//         </div>
//         <div className="btn-container" style={{ margin: "10px 0", textAlign: "center" }}>
//           {imgSrc ? (
//             <>
//               {/* <button onClick={retake} style={{ backgroundColor: '#ae9d81', border: 'none', height: '30px', width: '140px', color: 'white', fontSize: '15px', fontWeight: '600', borderRadius: '5px' }}>Retake photo</button> */}
//               <button onClick={() => { submit() }} style={{ backgroundColor: '#ae9d81', border: 'none', height: '30px', width: '140px', color: 'white', fontSize: '15px', fontWeight: '600', borderRadius: '5px', margin: "0 10px" }}>Submit </button>
//               <button onClick={retake} style={{ backgroundColor: '#ae9d81', border: 'none', height: '30px', width: '140px', color: 'white', fontSize: '15px', fontWeight: '600', borderRadius: '5px', margin: "0 10px" }}>Retake photo</button>
//               <button onClick={() => { setIsVisible(false); setImage("") }} style={{ backgroundColor: '#ae9d81', border: 'none', height: '30px', width: '140px', color: 'white', fontSize: '15px', fontWeight: '600', borderRadius: '5px', margin: "0 10px" }}>Cancel </button>
//             </>) : (
//             <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
//               <button onClick={capture} style={{ backgroundColor: '#ae9d81', border: 'none', height: '30px', width: '140px', color: 'white', fontSize: '15px', fontWeight: '600', borderRadius: '5px', margin: "0 10px" }}>Capture photo</button>
//               <button onClick={() => setIsVisible(false)} style={{ backgroundColor: '#ae9d81', border: 'none', height: '30px', width: '140px', color: 'white', fontSize: '15px', fontWeight: '600', borderRadius: '5px', margin: "0 10px" }}>Discard </button>
//             </div>
//           )}
//         </div>

//       </div>

//     </Dialog >
//   );
// }
// export default CustomWebcam;

import Webcam from "react-webcam";
import { useRef, useState, useCallback } from "react";
import { Dialog } from "primereact/dialog";

function CustomWebcam({ setIsVisible, image, setImage, getImage = () => { }, isMultiplePhotos = false, setAttachments, value }) {
  const webcamRef = useRef(null);
  const [imgSrc, setImgSrc] = useState(null);
  const [mirrored, setMirrored] = useState(true);

  const capture = useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    setImgSrc(imageSrc);
    // setImage(imageSrc);
    // console.log('img', imageSrc?.split(',')[1]);
  }, [webcamRef]);

  const retake = () => {
    setImgSrc(null);
  };

  return (
    <Dialog
      header="Take picture"
      visible={true}
      style={{ width: "50vw" }}
      onHide={() => setIsVisible(false)}
    >
      <div className="container" style={{ textAlign: "center" }}>
        {/* <h1 style={{ textAlign: "left", padding: "0 16px", fontSize: 24, fontWeight: "600" }}>Camera</h1> */}
        {imgSrc ? (
          <img src={imgSrc} alt="webcam" height={400} width={600} />
        ) : (
          <Webcam
            ref={webcamRef}
            mirrored={true}
            screenshotFormat="image/jpeg"
            screenshotQuality={0.8}
            height={400}
            width={600}
          />
        )}
        <div className="controls"></div>
        <div
          className="btn-container"
          style={{ margin: "10px 0", textAlign: "center" }}
        >
          {imgSrc ? (
            <>
              {/* <button onClick={retake} style={{ backgroundColor: '#ae9d81', border: 'none', height: '30px', width: '140px', color: 'white', fontSize: '15px', fontWeight: '600', borderRadius: '5px' }}>Retake photo</button> */}
              <button
                onClick={() => {
                  setIsVisible(false);
                  if (isMultiplePhotos) {
                    console.log(imgSrc.split('base64,'))
                    let base64 = imgSrc.split(';base64,')[1];
                    let filename = imgSrc.split(';base64,')[0].split('/')[1];
                    setAttachments({
                      ...value, attachments: [...value.attachments, {
                        base64: base64,
                        name: `attachment-${Date.now()}.${filename}`,
                      }]
                    })
                  }
                  else {
                    setImage(imgSrc);
                    getImage(imgSrc);
                  }
                }}
                style={{
                  backgroundColor: "#ae9d81",
                  border: "none",
                  height: "30px",
                  width: "140px",
                  color: "white",
                  fontSize: "15px",
                  fontWeight: "600",
                  borderRadius: "5px",
                  margin: "0 10px",
                }}
              >
                Save{" "}
              </button>
              <button
                onClick={retake}
                style={{
                  backgroundColor: "#ae9d81",
                  border: "none",
                  height: "30px",
                  width: "140px",
                  color: "white",
                  fontSize: "15px",
                  fontWeight: "600",
                  borderRadius: "5px",
                  margin: "0 10px",
                }}
              >
                Retake photo
              </button>
              <button
                onClick={() => {
                  setIsVisible(false);
                  setImage("");
                }}
                style={{
                  backgroundColor: "#ae9d81",
                  border: "none",
                  height: "30px",
                  width: "140px",
                  color: "white",
                  fontSize: "15px",
                  fontWeight: "600",
                  borderRadius: "5px",
                  margin: "0 10px",
                }}
              >
                Cancel{" "}
              </button>
            </>
          ) : (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <button
                onClick={capture}
                style={{
                  backgroundColor: "#ae9d81",
                  border: "none",
                  height: "30px",
                  width: "140px",
                  color: "white",
                  fontSize: "15px",
                  fontWeight: "600",
                  borderRadius: "5px",
                  margin: "0 10px",
                }}
              >
                Capture photo
              </button>
              <button
                onClick={() => setIsVisible(false)}
                style={{
                  backgroundColor: "#ae9d81",
                  border: "none",
                  height: "30px",
                  width: "140px",
                  color: "white",
                  fontSize: "15px",
                  fontWeight: "600",
                  borderRadius: "5px",
                  margin: "0 10px",
                }}
              >
                Discard{" "}
              </button>
            </div>
          )}
        </div>
      </div>
    </Dialog>
  );
}
export default CustomWebcam;
