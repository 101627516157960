import React, { useEffect, useState } from 'react';
import logo from '../../assets/images/logo.jpg';
import { useNavigate, useLocation } from 'react-router-dom'
import './style.scss';
import { ToastContainer, toast } from 'react-toastify'
import { useCookies } from 'react-cookie';





// import DropdownComponent from '../utilize/DropdownComponent'; 

import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { UpdatePassword } from '../../Services/ResetPasswordService';




function ForgotPassword() {
    const navigate = useNavigate();
    const [cookies, setCookie, removeCookie] = useCookies();
    const [email, setEmail] = useState('')
    const [emailError, setEmailError] = useState(false);
    const updatePassword = (() => {
        if (!isEmailValid()) {
            setEmailError(true);
            return
        };
        setEmailError(false);
        let token = cookies?.bearerToken
        UpdatePassword(email, token)
            .then((res) => {
                setEmail(
                    "")
                toast.success("A new password has been sent to your email.", { position: "top-right" });
                navigate('/login');

            })
            .catch((error) => {
                toast.error("No User Found");
            })
    });

    const backToDashboard = () => {
        navigate('/login')
    }

    const isEmailValid = () => {
        return email?.length > 0
    }



    return (
        <div className="ResetPassword">
            <div className='logo' >
                <img alt='image' src={logo} style={{ width: "200px", height: "80px", marginTop: "10px", background: "#fff", margin: "10px" }} />
            </div>

            <div
                style={{ width: '500px', padding: "20px", color: 'white', margin: 'auto', marginTop: '100px', backgroundColor: '#ae9d81d0', borderRadius: '5px' }} >
                <div className='defaultWidth'>
                    <p style={{ fontWeight: 700, fontSize: 25, color: "##2D6C8B", textAlign: 'center' }}>Forgot Password</p>
                </div>
                <div  >
                    <div className='defaultWidth' >
                        <p className='defaultStyle' >Email</p>
                        <div className='textinputstyle'>
                            <div style={{ flex: 0.1, alignItems: "center", justifyContent: "center", paddingHorizontal: 6 }}>

                            </div>
                            <div style={{ flex: 0.9, flexGrow: 1 }}>

                                <InputText className='textField'
                                    placeholder='Enter Email'
                                    value={email}
                                    style={{ color: "white" }}
                                    onChange={(e) => { setEmail(e.target.value) }}

                                />
                            </div>
                        </div>
                        {emailError && !email && <p style={{ color: "red" }}>Email is required</p>}
                    </div>
                </div>


                <div className='defaultWidth' style={{ display: 'flex', justifyContent: 'center', gap: '10px' }}>
                    <Button style={{ border: "1px solid #AE9D81", width: "30%", height: "40px", backgroundColor: "White", color: '#8E8E8E', cursor: "pointer", borderRadius: "5px", boxShadow: "none" }} onClick={backToDashboard}
                        className='loginButton button' >Cancel</Button>
                    <Button style={{ border: "1px solid #AE9D81", width: "30%", height: "40px", backgroundColor: "White", color: '#ae9d81', cursor: "pointer", borderRadius: "5px", boxShadow: "none" }} onClick={updatePassword}
                        className='loginButton button' >Submit</Button>
                </div>
            </div>

        </div>

    )

}
export default ForgotPassword

