import { toast } from 'react-toastify';
import notificationMessage from '../../assets/sound/message.mp3';

export const WebNotification= (message)=>{

    let sound =new Audio(notificationMessage);
    sound.muted = true;
    sound.play().then(() => {
        sound.muted = false;
        sound.play();
    }).catch((error) => {
        console.error('Playback failed:', error);
    });
    toast.info(message);

}