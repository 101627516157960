import { Sidebar } from "primereact/sidebar";
import React, { useState, useEffect } from "react";
import { PanelMenu } from 'primereact/panelmenu';
import { CgUserList } from "react-icons/cg";
import { CiShop } from "react-icons/ci";
import { FaUserCog } from "react-icons/fa";
import { MdFeedback, MdPersonSearch } from "react-icons/md";
import { FcInspection } from "react-icons/fc";
import { TbSettingsSearch } from "react-icons/tb";
import { SiGoogledocs } from "react-icons/si";
import { TbTruckDelivery } from "react-icons/tb";
import { AiFillSetting } from "react-icons/ai";
import { RiCustomerService2Fill } from "react-icons/ri";
import { GrShieldSecurity } from "react-icons/gr";
import { LiaFileInvoiceDollarSolid } from "react-icons/lia";
import { FaUserCheck } from "react-icons/fa6";
import { TbSettingsCheck } from "react-icons/tb";
import { MdOutlineInventory } from "react-icons/md";
import { GiShoppingBag } from "react-icons/gi";
import { CgSearchFound } from "react-icons/cg";
import { TbListCheck } from "react-icons/tb";
import { AiOutlineFileDone } from "react-icons/ai";
import { LuCheckSquare } from "react-icons/lu";
import { FaFileCircleCheck } from "react-icons/fa6";
import { TbPencilCog } from "react-icons/tb";
import { MdScreenShare } from "react-icons/md";
import { MdShareLocation } from "react-icons/md";
import { FaClipboardUser } from "react-icons/fa6";
import { MdFactCheck } from "react-icons/md";
// import { PiUserListDuotone } from "react-icons/pi";
// import { PiInvoiceThin } from "react-icons/pi";
// import { PiInvoiceDuotone } from "react-icons/pi";
import { HiOutlineClipboardDocumentList } from "react-icons/hi2";
import "./SideNav.scss";
import { useNavigate, useLocation } from "react-router-dom";

//-----------Context ---------------
import { useDispatch, useSelector } from "react-redux";
import { setNavVisibilty } from "../../Redux/AppSlice";
// import logo-icon from '../../assets/icons/logo-icon.png';
// import logoIcon from '../../assets/icons/logoIcon.png'
import logoIcon from "../../assets/images/logo.jpg";
import { Button } from "primereact/button";
import { useCookies } from "react-cookie";


import { FaComments, FaClipboardList, FaBox, FaClipboard, FaTasks, FaUsers, FaBoxOpen, FaBoxes, FaWarehouse, FaTruckLoading, FaClipboardCheck, FaUserTag, FaRegListAlt, FaMoneyCheckAlt, FaMoneyBillWave, FaClipboardList as FaClipboardListAlt, FaClipboardCheck as FaClipboardCheckAlt, FaTicketAlt } from 'react-icons/fa';
import { RiVipFill } from "react-icons/ri";
import { FiUsers } from "react-icons/fi";
import { BiCategory } from "react-icons/bi";
//----------------------------------------
const SideNav = () => {
  const [cookies, setCookie, removeCookie] = useCookies();
  const Context = useSelector((state) => state.AppContext);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [activeTab, setActiveTab] = useState(0);


  const menuRenderer = (item, options) => {
    if (item?.roles?.some((role) => cookies?.roles?.includes(role))) {
      return (
        <div key={item.label} >
          <a
            className="flex px-3 py-2 align-items-center cursor-pointer side_heading"
            style={{ height: "40px", justifyContent: "flex-start", color: "#00488f" }}
            href={item.url ? item.url : ""}
            onClick={() => toggleNode(item?.key)} // Toggle menu on click
          >
            <i className={`mx-2 ${item.icon}`}></i>
            <span className={`mx-1 ${item.items && 'font-semibold'}`}>
              {item.label}
            </span>
          </a>
        </div>
      );
    }
  };

  const itemRenderer = (item, options) => {
    if (item?.roles?.some((role) => cookies?.roles?.includes(role))) {
      return (
        <div key={item.key}
          style={{
            // background: location?.pathname === item?.url ? "#ae9d81" : "#fff" 
          }}
        >
          <a
            className="px-2 py-2 m-1 align-items-center cursor-pointer side_items"
            // style={{ color: location?.pathname === item?.url ? "#fff" : "#ae9d81" }}
            href={item?.url}
            key={item.label}
          >
            {item?.icon}
            <span className={`mx-1 p-2 ${item.items && 'font-semibold'}`}>
              {item.label}
            </span>
          </a>
        </div>
      );
    } else {
      return null;
    }
  };




  const MenuRenderer = () => {
    const hasRequiredRole = (roles) => roles.some((role) => cookies?.roles?.includes(role));
    let filteredItem = menuItems
      .map(menu => {
        const filteredItems = (menu.items || []).filter(item => hasRequiredRole(item.roles));
        if (filteredItems.length > 0 || hasRequiredRole(menu.roles)) {
          return {
            ...menu,
            items: filteredItems
          };
        }
        return null;
      })
      .filter(menu => menu !== null);
    setFilteredMenuItems(filteredItem)
  };

  const [expandedKeys, setExpandedKeys] = useState({});

  const expandNode = (node) => {
    if (node.items && node.items.length) {
      setExpandedKeys((prev) => ({
        ...prev,
        [node.key]: true, // Mark this node as expanded
      }));

      // node.items.forEach(expandNode);  // Recursively expand child items
    }
  };

  const toggleNode = (node) => {
    setExpandedKeys((prev) => ({
      ...prev,
      [node.key]: !prev[node.key], // Toggle expansion state
    }));
  };


  const expandRoleBasedMenu = (role) => {
    filteredMenuItems.forEach((menu) => {
      if (menu.roles.includes(role)) {
        expandNode(menu);
      }
    });
  };




  const [filteredMenuItems, setFilteredMenuItems] = useState([]);
  const menuItems = [
    {
      key: 1,
      label: 'Configuration',
      icon: 'pi pi-align-justify',
      roles: ["ADMIN"],
      template: menuRenderer,
      items: [
        {
          label: 'Dashboard',
          icon: <CgUserList
            className="mx-2 defaultColor"
            style={{
              //color: location?.pathname === "/dashboard" ? "#ae9d81" : "#fff",
              fontSize: 24,
            }}></CgUserList>,
          url: '/dashboard',
          roles: ["ADMIN"],
          template: itemRenderer
        },
        {
          label: 'User Management',
          icon: <FaUserCog
            className="mx-2 defaultColor"
            style={{
              //color: location?.pathname === "/usermanagement" ? "#ae9d81" : "#fff",
              fontSize: 24,
            }}
          />,
          url: '/usermanagement',
          roles: ["ADMIN"],
          template: itemRenderer
        }
      ]
    },
    {
      key: 2,
      label: 'Feedback',
      icon: 'pi pi-comments',
      roles: ["ADMIN", "DEPARTMENTTEAM", "GUESTSTAFFSUPERVISOR", "GUESTSERVICESTAFF"],
      template: menuRenderer,
      items: [
        {
          label: 'Admin Feedback',
          icon: <FaComments
            className="mx-2 defaultColor"
            style={{
              //color: location?.pathname === "/feedbackdetails" ? "#ae9d81" : "#fff",
              fontSize: 24,
            }}
          />,
          roles: ["ADMIN"],
          url: '/feedbackdetails',
          template: itemRenderer
        },
        {
          label: 'Department Actions',
          icon: <FaClipboard
            className="mx-2 defaultColor"
            style={{
              //color: location?.pathname.includes("/departmentteam") ? "#ae9d81" : "#fff",
              fontSize: 24,
            }}
          />,
          url: '/departmentteam/1',
          roles: ["DEPARTMENTTEAM"],
          template: itemRenderer
        },
        {
          label: 'MG Care Completion',
          icon: <FaTasks
            className="mx-2 defaultColor"
            style={{
              //color: location?.pathname === "/guestservicesupervisor" ? "#ae9d81" : "#fff",
              fontSize: 24,
            }}
          />,
          url: '/guestservicesupervisor',
          roles: ["GUESTSTAFFSUPERVISOR"],
          template: itemRenderer
        },
        {
          label: 'MG Care Distribution',
          icon: <FaTasks
            className="mx-2 defaultColor"
            style={{
              //color: location?.pathname === "/guestservicestaff" ? "#ae9d81" : "#fff",
              fontSize: 24,
            }}
          />,
          url: '/guestservicestaff',
          roles: ["GUESTSERVICESTAFF"],
          template: itemRenderer
        },
      ]
    },
    {
      key: 3,
      label: 'Inspection',
      icon: 'pi pi-file',
      roles: ["ADMIN", "MGTENANTRELATIONSHIP"],
      template: menuRenderer,
      items: [
        {
          label: 'Admin Tenant Inspection List',
          icon: <FaClipboardList
            className="mx-2 defaultColor"
            style={{
              //color: location?.pathname === "/inspection" ? "#ae9d81" : "#fff",
              fontSize: 24,
            }}
          />,
          url: '/inspection',
          roles: ["ADMIN"],
          template: itemRenderer
        },
        {
          label: 'Tenant Inspection',
          icon: <FaUsers
            className="mx-2 defaultColor"
            style={{
              //color: location?.pathname === "/mgtenentrelationship" ? "#ae9d81" : "#fff",
              fontSize: 24,
            }}
          />,
          url: '/mgtenentrelationship',
          roles: ["MGTENANTRELATIONSHIP"],
          template: itemRenderer
        },
        {
          label: 'Guest Staff Inspection',
          icon: <FaUserCheck
            className="mx-2 defaultColor"
            style={{
              //color: location?.pathname === "/gueststaffinspection" ? "#ae9d81" : "#fff",
              fontSize: 24,
            }}
          />,
          url: '/gueststaffinspection',
          roles: ["ADMIN"],
          template: itemRenderer
        },
      ]
    },
    {
      key: 4,
      label: 'Hire Details',
      icon: 'pi pi-shopping-cart',
      roles: ["ADMIN", "GUESTSTAFFSUPERVISOR"],
      template: menuRenderer,
      items: [
        {
          label: 'Hire Details',
          icon: <FaClipboard
            className="mx-2 defaultColor"
            style={{
              //color: location?.pathname === "/hire-details" ? "#ae9d81" : "#fff",
              fontSize: 24,
            }}
          />,
          url: '/hire-details',
          roles: ["ADMIN", "GUESTSTAFFSUPERVISOR"],
          template: itemRenderer
        }
      ]
    },
    {
      key: 5,
      label: 'Hands Free',
      icon: 'pi pi-shopping-cart',
      roles: ["GUESTSERVICESTAFF", "HF_STORE", "HF_STORE_KEEPER", "SAD_DELIVERY_PARTNER", "HF_RUNNER", "SAD_DELIVERY_PARTNER"],
      template: menuRenderer,
      items: [
        {
          label: 'HF Check-in Request',
          icon: <FaClipboardCheck
            className="mx-2 defaultColor"
            style={{
              //color: location?.pathname === "/checkin-request" ? "#ae9d81" : "#fff",
              fontSize: 24,
            }}
          />,
          url: '/checkin-request',
          roles: ["GUESTSERVICESTAFF", "HF_STORE", "HF_STORE_KEEPER", "SAD_DELIVERY_PARTNER"],
          template: itemRenderer
        },
        {
          label: 'HF Delivery Service',
          icon: <FaTruckLoading
            className="mx-2 defaultColor"
            style={{
              //color: location?.pathname === "/shop-and-drop" ? "#ae9d81" : "#fff",
              fontSize: 24,
            }}
          />,
          url: '/shop-and-drop',
          roles: ["GUESTSERVICESTAFF", "SAD_DELIVERY_PARTNER", "HF_STORE", "HF_STORE_KEEPER"],
          template: itemRenderer
        },
        {
          label: 'HF Track Deliveries',
          icon: <FaBoxes
            className="mx-2 defaultColor"
            style={{
              //color: location?.pathname === "/shop-and-drop-track-deliveries" ? "#ae9d81" : "#fff",
              fontSize: 24,
            }}
          />,
          url: '/shop-and-drop-track-deliveries',
          roles: ["GUESTSERVICESTAFF", "SAD_DELIVERY_PARTNER", "HF_STORE", "HF_RUNNER", "HF_STORE_KEEPER"],
          template: itemRenderer
        },
      ]
    },
    {
      key: 6,
      label: 'Lost and Found',
      icon: 'pi pi-inbox',
      roles: ["ADMIN", "GSR", "SECURITYSTAFF"],
      template: menuRenderer,
      items: [
        {
          label: 'Admin - Lost And Found  ',
          icon: <FaBox
            className="mx-2 defaultColor"
            style={{
              //color: location?.pathname.includes("/admin-lost-found") ? "#ae9d81" : "#fff",
              fontSize: 24,
            }}
          />,
          url: '/admin-lost-found/lost',
          roles: ["ADMIN"],
          template: itemRenderer
        },
        {
          label: 'GSR - Lost and Found',
          icon: <FaBoxOpen
            className="mx-2 defaultColor"
            style={{
              //color: location?.pathname.includes("/lostandfound") ? "#ae9d81" : "#fff",
              fontSize: 24,
            }}
          />,
          url: '/lostandfound/lost',
          roles: ["GSR"],
          template: itemRenderer
        },
        {
          label: 'Security Staff',
          icon: <FaWarehouse
            className="mx-2 defaultColor"
            style={{
              //color: location?.pathname.includes("/security-staff") ? "#ae9d81" : "#fff",
              fontSize: 24,
            }}
          />,
          url: '/security-staff/1',
          roles: ["SECURITYSTAFF"],
          template: itemRenderer
        },

      ]
    },
    {
      key: 7,
      label: 'VIP Lounge',
      icon: 'pi pi-inbox',
      roles: ["PANTRY_STAFF", "VIP_SUPERVISOR", "LOUNGE_STAFF"],
      template: menuRenderer,
      items: [
        {
          label: 'Inventory',
          icon: <FaClipboardListAlt
            className="mx-2 defaultColor"
            style={{
              //color: location?.pathname === "/pantry-staff-inventory" ? "#ae9d81" : "#fff",
              fontSize: 24,
            }}
          />,
          url: '/pantry-staff-inventory',
          roles: ["PANTRY_STAFF"],
          template: itemRenderer
        },
        {
          label: 'Supervisor Invoices Details',
          icon: <FaTicketAlt
            className="mx-2 defaultColor"
            style={{
              //color: location?.pathname === "/lounge-supervisor-invoices" ? "#ae9d81" : "#fff",
              fontSize: 24,
            }}
          />,
          url: '/lounge-supervisor-invoices',
          roles: ["VIP_SUPERVISOR"],
          template: itemRenderer
        },
        {
          label: 'Staff Invoice Details',
          icon: <FaTicketAlt
            className="mx-2 defaultColor"
            style={{
              //color: location?.pathname === "/supervisor-subscription-config" ? "#ae9d81" : "#fff",
              fontSize: 24,
            }}
          />,
          url: '/lounge-staff-invoices',
          roles: ["LOUNGE_STAFF"],
          template: itemRenderer
        },
        {
          label: 'Purchase',
          icon: <FaClipboardCheckAlt
            className="mx-2 defaultColor"
            style={{
              //color: location?.pathname === "/pantry-purchase" ? "#ae9d81" : "#fff",
              fontSize: 24,
            }}
          />,
          url: '/pantry-purchase',
          roles: ["PANTRY_STAFF"],
          template: itemRenderer
        },
        {
          label: 'Purchase Details',
          icon: <FaMoneyBillWave
            className="mx-2 defaultColor"
            style={{
              //color: location?.pathname === "/supervisor-purchase-details" ? "#ae9d81" : "#fff",
              fontSize: 24,
            }}
          />,
          url: '/supervisor-purchase-details',
          roles: ["VIP_SUPERVISOR"],
          template: itemRenderer
        },
        {
          label: 'Search VIP User',
          icon: <FaRegListAlt
            className="mx-2 defaultColor"
            style={{
              //color: location?.pathname === "/search-vip-user" ? "#ae9d81" : "#fff",
              fontSize: 24,
            }}
          />,
          url: '/search-vip-user',
          roles: ["LOUNGE_STAFF", "VIP_SUPERVISOR"],
          template: itemRenderer
        },
        {
          label: 'User Approvals Details',
          icon: <FaClipboardUser
            className="mx-2 defaultColor"
            style={{
              //color: location?.pathname === "/supervisor-user-approval" ? "#ae9d81" : "#fff",
              fontSize: 24,
            }}
          />,
          url: '/supervisor-user-approval',
          roles: ["VIP_SUPERVISOR"],
          template: itemRenderer
        },
        {
          label: 'VIP User Details',
          icon: <FaUserTag
            className="mx-2 defaultColor"
            style={{
              //color: location?.pathname === "/vip-user-list" ? "#ae9d81" : "#fff",
              fontSize: 24,
            }}
          />,
          url: '/vip-user-list',
          roles: ["LOUNGE_STAFF", "VIP_SUPERVISOR"],
          template: itemRenderer
        },

      ]
    }
  ]

  // make the item menu open based on the cookies.user.role[0]





  const backToDashboard = () => {

    let roles = JSON.parse(JSON.stringify(cookies?.roles));
    if (roles?.filter((role) => role === "ADMIN")?.length !== 0) {
      navigate("/dashboard");
      return;
    }
    if (roles?.filter((role) => role === "GUESTSERVICESTAFF")?.length !== 0) {
      navigate("/guestservicestaff");
      return;
    }
    if (roles?.filter((role) => role === "DEPARTMENTTEAM")?.length !== 0) {
      navigate("/departmentteam/1");
      return;
    }
    if (
      roles?.filter((role) => role === "GUESTSTAFFSUPERVISOR")?.length !== 0
    ) {
      navigate("/guestservicesupervisor");
      return;
    }
    if (
      roles?.filter((role) => role === "MGTENANTRELATIONSHIP")?.length !== 0
    ) {
      navigate("/mgtenentrelationship");
      return;
    }
    if (roles?.filter((role) => role === "VIP_SUPERVISOR")?.length !== 0) {
      navigate("/vip-user-list");
      return;
    }
    if (roles?.filter((role) => role === "LOUNGE_STAFF")?.length !== 0) {
      navigate("/vip-user-list");
      return;
    }
    if (roles?.filter((role) => role === "PANTRY_STAFF")?.length !== 0) {
      navigate("/pantry-purchase");
      return;
    }
    if (roles?.filter((role) => role === "HF_STORE")?.length !== 0) {
      navigate("/shop-and-drop-track-deliveries");
      return;
    }
    if (roles?.filter((role) => role === "HF_RUNNER")?.length !== 0) {
      navigate("/shop-and-drop-track-deliveries");
      return;
    }
    if (roles?.filter((role) => role === "SAD_DELIVERY_PARTNER")?.length !== 0) {
      navigate("/shop-and-drop-track-deliveries");
      return;
    }
    if (roles?.filter((role) => role === "HF_STORE_KEEPER")?.length !== 0) {
      navigate("/shop-and-drop-track-deliveries");
      return;
    }
    if (roles?.filter((role) => role === "SECURITYSTAFF")?.length !== 0) {
      navigate("/security-staff/1");
      return;
    }
  };



  useEffect(() => {
    dispatch(setNavVisibilty({ sideNavVisibility: false }));
    // MenuRenderer();
  }, []);


  useEffect(() => {
    MenuRenderer();
    if (cookies?.roles?.length) {
      expandRoleBasedMenu(cookies?.roles[0]);
      let initialItemOpened = menuItems?.filter((item) => item?.roles?.includes(cookies?.roles[0]));
      console.log(initialItemOpened);
      if (initialItemOpened?.length) {
        toggleNode(initialItemOpened[0]?.key)
      }
    }
  }, [cookies?.roles])





  return (
    <>
      {location.pathname !== "/" && location.pathname !== "/feedbackform" ? (
        <div>
          <Sidebar
            visible={Context.sideNavVisibility}
            onHide={() =>
              dispatch(setNavVisibilty({ sideNavVisibility: false }))
            }
            header={() => {
              return (
                <>
                  <img src={logoIcon} alt="logo" style={{ width: "50%" }} />
                  <Button
                    icon="pi pi-home"
                    style={{
                      backgroundColor: "white",
                      color: "#ae9d81",
                      border: "none",
                      marginLeft: "10px",
                      marginBottom: "5px",
                    }}
                    onClick={() => {
                      backToDashboard();
                      dispatch(setNavVisibilty({ sideNavVisibility: false }));
                    }}
                  />
                </>
              );
            }}
            style={{ background: "#fff" }}
            showCloseIcon={false}
          >
            <div className="card flex justify-content-center">
              < PanelMenu model={filteredMenuItems} expandedKeys={expandedKeys} onExpandedKeysChange={setExpandedKeys} className="w-full md:w-20rem" multiple />
              {/* < PanelMenu model={filteredMenuItems} className="w-full md:w-20rem" multiple /> */}
            </div>

            {/* {menuItems?.map((menuItem, index) => {
                if (menuItem?.roles?.some((role) => cookies?.roles?.includes(role))) {
                  return (
                    <div key={index}
                      class="flex align-items-center border-round-sm disableJustifyContent"
                      style={{
                        background:
                          location?.pathname === menuItem?.moduleUrl ? "#ae9d81" : "",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        navigate(menuItem?.path);
                        dispatch(setNavVisibilty({ sideNavVisibility: false }));
                      }}
                    >


                      {menuItem?.icon}
                      <p
                        className="defaultColor"
                        style={{
                          color: location?.pathname === menuItem?.moduleUrl ? "#ae9d81" : "#fff",
                        }}
                      >
                        {menuItem?.label}
                      </p>
                    </div>
                  )
                }
              })
              } */}

          </Sidebar>
        </div >

      ) : (
        ""
      )}
    </>
  );
};

export default React.memo(SideNav);
