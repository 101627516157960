import React, { useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';



const AuthGuard = ({ component, role, authorized, ismultiple, invalid }) => {
    const [cookies, setCookie] = useCookies();
    const navigate = useNavigate();

    const checkMultipleRoles = (loggedInRoles = [], RouteRoles = []) => {
        let authorized = false;
        RouteRoles.forEach(element => {
            if (loggedInRoles.filter((r) => r === element).length > 0) {
                authorized = true;
                return;
            }
        });
        // console.log(authorized);
        return authorized;
    }




    useEffect(() => {
        }, []);

    if (authorized) {
        if (cookies?.bearerToken && cookies?.bearerToken != null) {

            if (ismultiple && checkMultipleRoles(cookies?.roles, role)) {
                return <React.Fragment>{component}</React.Fragment>
            }

            if (cookies?.roles?.includes(role)) {
                return <React.Fragment>{component}</React.Fragment>
            }
            else if (cookies?.roles?.includes("ADMIN")) {
                window.location.href = ("/dashboard")
                return;
            }
            else if (cookies?.roles?.includes("GUESTSERVICESTAFF")) {
                window.location.href = "/guestservicestaff";
                return;
            }
            else if (cookies?.roles?.includes("DEPARTMENTTEAM")) {
                window.location.href = "/departmentteam/1";
                return;
            }
            else if (cookies?.roles?.includes("MGTENANTRELATIONSHIP")) {
                window.location.href = "/mgtenentrelationship";
                return;
            }
            else if (cookies?.roles?.includes("GUESTSTAFFSUPERVISOR")) {
                window.location.href = "/guestservicesupervisor";
                return;
            }
            else if (cookies?.roles?.includes("GSR")) {
                window.location.href = "/lostandfound/lost";
                return;
            }
            else if (cookies?.roles?.includes("SECURITYSTAFF")) {
                window.location.href = "/security-staff/1";
                return;
            }
            else if (cookies?.roles?.includes("VIP_SUPERVISOR")) {
                window.location.href = "/vip-user-list";
                return;
            }
            else if (cookies?.roles?.includes("LOUNGE_STAFF")) {
                window.location.href = "/vip-user-list";
                return;
            }
            else if (cookies?.roles?.includes("PANTRY_STAFF")) {
                window.location.href = "/pantry-staff-inventory";
                return;
            }
            else if (cookies?.roles?.includes("SAD_DELIVERY_PARTNER")) {
                window.location.href = "/pantry-staff-inventory";
                return;
            }
            else if (cookies?.roles?.includes("HF_STORE")) {
                window.location.href = "/checkin-request";
                return;
            }
            else if (cookies?.roles?.includes("HF_STORE_KEEPER")) {
                window.location.href = "/checkin-request";
                return;
            }
        }
        else {
            if (window.location.pathname !== "/" || "/login" || "/forgotpassword") {
                localStorage.setItem("redirectUrl", window.location.pathname);
            }
            window.location.href = "/";
        }
    }
    else if (invalid) {
        window.location.href = "/";
    }
    else {
        return <React.Fragment>{component}</React.Fragment>;
    }

}


export default AuthGuard;