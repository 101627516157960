import { useCookies } from 'react-cookie';
import { useParams, Link, useNavigate } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import './style.scss'



// PrimeReact Components
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { FilterMatchMode } from 'primereact/api';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Paginator } from 'primereact/paginator';
import { TabView, TabPanel } from 'primereact/tabview';
import { Calendar } from 'primereact/calendar';
import { Galleria } from 'primereact/galleria';
// -------------------------------------------------------------



// Context
import { useDispatch, useSelector } from 'react-redux'
import { setNavVisibilty } from '../../Redux/AppSlice'
// -----------------------------------------------


// Services
import { exportDataInLandD, exportExcel, exportFilterdExcel } from '../../Services/ExportServices';
import { AdminLostItemList } from '../../Services/AdminLostService';
import { AdminFoundItemList } from '../../Services/AdminFoundService';
import { AdminReturnItemList } from '../../Services/AdminReturnService';
import { GetAllImageFiles } from '../../Services/Files';
import { Dialog } from 'primereact/dialog';
// -----------------------------------------


export default function AdminLostAndFound() {
    const dispatch = useDispatch();
    const { tab } = useParams();
    const navigate = useNavigate();
    const [cookies, setCookie, removeCookie] = useCookies();

    const [activeIndex, setActiveIndex] = useState(1); // variable of the currennt tab index

    // Data list
    const [lostList, setLostlist] = useState([]);
    const [foundList, setFoundlist] = useState([]);
    const [returnList, setReturnlist] = useState([]);
    // ---------------------------


    // Data list Count
    const [lostListCount, setLostListCount] = useState([]);
    const [foundListCount, setFoundListCount] = useState([]);
    const [returnListCount, setReturnListCount] = useState([]);
    // ---------------------------


    //Search
    const [lostFilterValue, setLostFilterValue] = useState('');
    const [foundFilterValue, setFoundFilterValue] = useState('');
    const [returnFilterValue, setReturnFilterValue] = useState('');

 //export states
 const exportObj={
    fromDate:"",
    toDate:"",
    userId:"",
    searchTerms:"",
    isModalVisible:false   
  }
   const [expotLostObj,setExpotLostObj]=useState(exportObj)
   const [exportFoundObj,setExpotFoundObj]=useState(exportObj);
  const [exportModelVisible,setExportModelVisible]=useState(false);


    const onLostFilterChange = (e) => {
        if (!e) {
            setLostFilterValue('');
            getLostItemList(10, 0, '',expotLostObj?.fromDate,expotLostObj?.toDate);
        }
        else {
            setLostFilterValue(e.target.value);
            getLostItemList(10, 0, e.target.value,expotLostObj?.fromDate,expotLostObj?.toDate);
        }
    };
    const onFoundFilterChange = (e) => {
        if (!e) {
            setFoundFilterValue('');
            getFoundItemList(10, 0, '',exportFoundObj?.fromDate,exportFoundObj?.toDate);
        }
        else {
            setFoundFilterValue(e.target.value);
            getFoundItemList(10, 0, e.target.value,exportFoundObj?.fromDate,exportFoundObj?.toDate);
        }
    }
    const onReturnFilterChange = (e) => {
        if (!e) {
            setReturnFilterValue('');
            getReturnItemList(10, 0, '');
        }
        else {
            setReturnFilterValue(e.target.value);
            getReturnItemList(10, 0, e.target.value);
        }
    }
    //-----------------


    // Pagination
    const [lostFirst, setLostFirst] = useState(0);
    const [foundFirst, setFoundFirst] = useState(0);
    const [returnFirst, setReturnFirst] = useState(0);
    const onLostPageChange = (e, index) => {
        setLostFirst(e.first);
        getLostItemList(10,e.first, lostFilterValue,expotLostObj?.fromDate,expotLostObj?.toDate);
    };
    const onFoundPageChange = (e, index) => {
        setFoundFirst(e.first);
        getFoundItemList(10,e.first, foundFilterValue,exportFoundObj?.fromDate,exportFoundObj?.toDate);
    };
    const onReturnPageChange = (e, index) => {
        setReturnFirst(e.first);
        // getReturnItemList(e.first, 10, returnFilterValue);
    }

    // -------------------------------


    // Loaders
    const [lostLoading, setLostLoading] = useState(false);
    const [foundLoading, setFoundLoading] = useState(false);
    const [returnLoading, setReturnLoading] = useState(false);



    // Export
    console.log(cookies,"cookies");
    const OnExportLostClick = () => {
        // let route = "Lost"
        // let param = `strUserId=${cookies?.userId}&searchTerm=${lostFilterValue}`
        // exportDataInLandD(route, param)

        const fromDate =!expotLostObj?.fromDate?"":`${new Date(expotLostObj?.fromDate)?.getFullYear()}-${(new Date(expotLostObj?.fromDate)?.getMonth()+1)?.toString()?.padStart(2,"0")}-${new Date(expotLostObj?.fromDate)?.getDate()?.toString()?.padStart(2,"0")}`;
        const toDate =!expotLostObj?.toDate?"":`${new Date(expotLostObj?.toDate)?.getFullYear()}-${(new Date(expotLostObj?.toDate)?.getMonth()+1)?.toString()?.padStart(2,"0")}-${new Date(expotLostObj?.toDate)?.getDate()?.toString()?.padStart(2,"0")}`;
        let urlRoute=`LostAndFound/Lost/All?skip=0&take=${lostListCount}&searchTerm=${lostFilterValue}&from=${fromDate}&to=${toDate}&forExport=true`;
        exportFilterdExcel(urlRoute,cookies?.bearerToken);
        
    }
    const OnExportFoundClick = () => {
        // let route = "Found"
        // let param = `strUserId=${cookies?.userId}&searchTerm=${foundFilterValue}`
        // exportDataInLandD(route, param)
         const fromDate =!exportFoundObj?.fromDate?"":`${new Date(exportFoundObj?.fromDate)?.getFullYear()}-${(new Date(exportFoundObj?.fromDate)?.getMonth()+1)?.toString()?.padStart(2,"0")}-${new Date(exportFoundObj?.fromDate)?.getDate()?.toString()?.padStart(2,"0")}`;
        const toDate =!exportFoundObj?.toDate?"":`${new Date(exportFoundObj?.toDate)?.getFullYear()}-${(new Date(exportFoundObj?.toDate)?.getMonth()+1)?.toString()?.padStart(2,"0")}-${new Date(exportFoundObj?.toDate)?.getDate()?.toString()?.padStart(2,"0")}`;
            
        let urlRoute=`LostAndFound/Found/All?skip=0&take=${foundListCount}&searchTerm=${foundFilterValue}&from=${fromDate}&to=${toDate}&forExport=true`;
        exportFilterdExcel(urlRoute,cookies?.bearerToken);
    }

    const OnExportReturnClick = () => {
        // let route = "Return"
        // let param = `strUserId=${cookies?.userId}&searchTerm=${returnFilterValue}`
        // exportDataInLandD(route, param)
        
        let urlRoute=`Return/All?skip=0&take=${returnListCount}&searchTerm=${returnFilterValue}&forExport=true`
        exportFilterdExcel(urlRoute,cookies?.bearerToken);
    }
    // ------------------

    const isValidateDate = (fromDate,toDate) => {
        return new Date(fromDate) <= new Date(toDate);
      };

const exportlostDetails=(label)=>{
    console.log(label,"label");
    if(label=="Lost"){
        // if(!isValidateDate(expotLostObj?.fromDate,expotLostObj?.toDate)&&(expotLostObj?.fromDate||expotLostObj?.toDate)){
        //     return
        // }
    const fromDate =!expotLostObj?.fromDate?"":`${new Date(expotLostObj?.fromDate)?.getFullYear()}-${(new Date(expotLostObj?.fromDate)?.getMonth()+1)?.toString()?.padStart(2,"0")}-${new Date(expotLostObj?.fromDate)?.getDate()?.toString()?.padStart(2,"0")}`;
    const toDate =!expotLostObj?.toDate?"":`${new Date(expotLostObj?.toDate)?.getFullYear()}-${(new Date(expotLostObj?.toDate)?.getMonth()+1)?.toString()?.padStart(2,"0")}-${new Date(expotLostObj?.toDate)?.getDate()?.toString()?.padStart(2,"0")}`;
    let urlRoute=`LostDetails?searchTerm=${expotLostObj?.searchTerms}&from=${fromDate}&to=${toDate}`
    console.log(urlRoute,"urlroute");
        exportExcel(urlRoute);
    }
    if(label=="Found"){
        const fromDate =!exportFoundObj?.fromDate?"":`${new Date(exportFoundObj?.fromDate)?.getFullYear()}-${(new Date(exportFoundObj?.fromDate)?.getMonth()+1)?.toString()?.padStart(2,"0")}-${new Date(exportFoundObj?.fromDate)?.getDate()?.toString()?.padStart(2,"0")}`;
        const toDate =!exportFoundObj?.toDate?"":`${new Date(exportFoundObj?.toDate)?.getFullYear()}-${(new Date(exportFoundObj?.toDate)?.getMonth()+1)?.toString()?.padStart(2,"0")}-${new Date(exportFoundObj?.toDate)?.getDate()?.toString()?.padStart(2,"0")}`;
        let urlRoute=`FoundDetails?searchTerm=${exportFoundObj?.searchTerms}&from=${fromDate}&to=${toDate}`
       
        console.log(urlRoute,"urlroute");
        exportExcel(urlRoute);
    }

    // setExportModelVisible(false);

}
    // Table action templates
    const lostDateBodytemplate = (row) => {
        const dateTime = new Date(row?.lostDateTime);
        const year = dateTime.getFullYear();
        const month = dateTime.getMonth() + 1;
        const day = dateTime.getDate();
        const hour = dateTime.getHours();
        const minutes = dateTime.getMinutes();
        return (
            <div>
                <p style={{ fontSize: '1rem', fontWeight: 400 }}>{day + "/" + month + "/" + year + " " + hour + ":" + minutes}</p>
            </div>
        );
    };
    const createdDateBodytemplate = (row) => {
        const [date, month, year] = row?.createdDate.split("/");
        return (
            <div>
                <p style={{ fontSize: '1rem', fontWeight: 400 }}>{date + "/" + month + "/" + year}</p>
            </div>
        );
    };
    const lostActionctionBodyTemplate = (row) => {
        return (
            <div className='actionBtn' >
                <Link to={'/adminlostview/' + row?.id}>
                    <i className="pi pi-eye" style={{
                        fontSize: '1rem', cursor: "pointer"
                    }} />
                </Link>
            </div>
        );
    };
    const foundDateBodytemplate = (row) => {
        const dateTime = new Date(row?.foundDateTime);
        const year = dateTime.getFullYear();
        const month = dateTime.getMonth() + 1;
        const day = dateTime.getDate();
        const hour = dateTime.getHours();
        const minutes = dateTime.getMinutes();
        return (
            <div>
                <p style={{ fontSize: '1rem', fontWeight: 400 }}>{day + "/" + month + "/" + year + " " + hour + ":" + minutes}</p>
            </div>
        );
    };
    const foundActionctionBodyTemplate = (row) => {
        return (
            <div className='actionBtn' >
                <Link to={'/adminfoundview/' + row?.id}>
                    <i className="pi pi-eye" style={{
                        fontSize: '1rem', cursor: "pointer"
                    }} />
                </Link>
            </div>
        );
    };
    const [isFilemodelView, setISFileModelView] = useState(false);

    const imageBodyTemplate = (row) => {
        return (
            <>
                <div style={{ display: 'flex', cursor: 'pointer' }} className='col-6' onClick={() => { getImage(row?.id); }}>
                    <img src={"data:image/jpeg;base64," + row?.base64} alt="Founded item image" style={{ width: "100px", height: "100px" }} />
                    {/* <img src={editIcon} style={{ cursor: 'pointer' }} /> */}
                    {/* <i className='pi pi-eye' style={{ cursor: 'pointer', fontSize: "1rem", display: 'flex', alignItems: 'center', margin: "0px 5px" }}></i> */}
                </div>

            </>
        );
    };

    const [files, setFileList] = useState([]);
    const [isFilePreview, setisFilePreview] = useState(false);
    const [isFilePreviewImage, setisFilePreviewImage] = useState(false);
    const getImage = async (id) => {
        let data = {
            id: id,
            module: "Found",
            isForInspection: "",
            inspectionId: ""
        }
        await GetAllImageFiles(data, cookies.bearerToken)
            .then((res) => {
                if (res.status === 200) {
                    setFileList(res?.data);
                    setISFileModelView(true);
                    //console.log(res?.data?.data);
                }
            })
            .catch((err) => {
                //console.log(err);
            })

    };
    const returnDateBodytemplate = (row) => {
        const dateTime = new Date(row?.returnDateTime);
        const year = dateTime.getFullYear();
        const month = dateTime.getMonth() + 1;
        const day = dateTime.getDate();
        const hour = dateTime.getHours();
        const minutes = dateTime.getMinutes();
        return (
            <div>
                <p style={{ fontSize: '1rem', fontWeight: 400 }}>{day + "/" + month + "/" + year + " " + hour + ":" + minutes}</p>
            </div>
        );
    }
    const returnActionctionBodyTemplate = (row) => {
        return (
            <div className='actionBtn' style={{ background: 'transparent !important' }}>
                <Link to={'/adminreturnview/' + row?.foundId}>
                    <i className="pi pi-eye" style={{
                        fontSize: '1rem', cursor: "pointer"
                    }} />
                </Link>
            </div>
        );
    };
    const itemTemplate = (item) => {
        // return <img src={item.itemImageSrc} onError={(e) => e.target.src = 'https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'} alt={item.alt} style={{ width: '100%', display: 'block' }} />
        return <img src={"data:image/jpeg;base64," + item?.base64} alt="Founded item image" style={{ width: "100px", height: "100px" }} />

    }
    // ------------------------



    // Fetch Data 
    const getLostItemList = ((take, skip, searchTerm = '',startDate,endDate) => {
        console.log(startDate,endDate);
        
        setLostLoading(true);
        const formattedStartDate = startDate
        ? startDate.getFullYear() + '/' +
        (startDate.getMonth() + 1).toString().padStart(2, '0') + '/' +
        startDate.getDate().toString().padStart(2, '0')
        : "";
  
        const formatedEndDate = endDate
        ? endDate.getFullYear() + '/' +
        (endDate.getMonth() + 1).toString().padStart(2, '0') + '/' +
        endDate.getDate().toString().padStart(2, '0')
        : "";
        AdminLostItemList(take, skip, cookies.bearerToken, searchTerm,formattedStartDate,formatedEndDate)
            .then((res) => {
                //console.(res);
                if (res?.status === 200) {
                    setLostlist(res?.data?.data);
                    setLostListCount(res?.data?.count);
                }

            })
            .catch((error) => {
                console.warn(error);
            })
            .finally(() => {
                setLostLoading(false);
            })
    });
    const getFoundItemList = (async (take=10, skip="", searchTerm = '',startDate,endDate) => {
        setFoundLoading(true);
        const formattedStartDate = startDate
        ? startDate.getFullYear() + '/' +
        (startDate.getMonth() + 1).toString().padStart(2, '0') + '/' +
        startDate.getDate().toString().padStart(2, '0')
        : "";
  
        const formatedEndDate = endDate
        ? endDate.getFullYear() + '/' +
        (endDate.getMonth() + 1).toString().padStart(2, '0') + '/' +
        endDate.getDate().toString().padStart(2, '0')
        : "";
        await AdminFoundItemList(take, skip, cookies.bearerToken, searchTerm,formattedStartDate,formatedEndDate)
            .then((res) => {
                if (res?.status === 200) {
                    console.log("Found Date", res?.data?.data);
                    setFoundlist(res?.data?.data);
                    setFoundListCount(res?.data?.count)
                }

            })
            .catch((error) => {
                console.warn(error);
            })
            .finally(() => {
                setFoundLoading(false);
            })
    });
    const getReturnItemList = (async (take, skip, searchTerm = '') => {
        setReturnLoading(true);
        await AdminReturnItemList(take, skip, cookies.bearerToken, searchTerm)
            .then((res) => {
                //console.log(res);
                if (res?.status === 200) {
                    setReturnlist(res?.data?.data);
                    setReturnListCount(res?.data?.count)
                }

            })
            .catch((error) => {
                console.warn(error);
            })
            .finally(() => {
                setReturnLoading(false);
            })
    })
    // -------------------------------------








    // Tab change funvtionality
    const handleTabChange = (index) => {
        setActiveIndex(index);
        setLostFilterValue("");
        setFoundFilterValue("");
        setReturnFilterValue("");
        if (index == 0) {
            navigate("/admin-lost-found/lost");
        }
        else if (index == 1) {
            navigate("/admin-lost-found/found");
        }
        else if (index == 2) {
            navigate("/admin-lost-found/return");
        }
    }

    useEffect(() => {
        if (tab == 'lost') {
            setActiveIndex(0);
            getLostItemList(10, 0,lostFilterValue,expotLostObj?.fromDate,expotLostObj?.toDate);
        }
        else if (tab == 'found') {
            setActiveIndex(1);
            getFoundItemList(10, 0,foundFilterValue,exportFoundObj?.fromDate,exportFoundObj?.toDate);
        }
        else if (tab == 'return') {
            setActiveIndex(2);
            getReturnItemList(10, 0);
        }
        else {
            setActiveIndex(0);
            getLostItemList(10, 0,lostFilterValue,expotLostObj?.fromDate,expotLostObj?.toDate);
        }
    }, [tab])






    return (
        <>
            <Dialog
                header={"Attachments"}
                visible={isFilemodelView}
                // modal={false}
                style={{ width: "30vw" }}
                onHide={() => setISFileModelView(false)}
            >
                <>
                    {files.length > 0 &&
                        <div className="file-list">
                            {files.map((file, index) => {
                                return (
                                    <>
                                        <span style={{ cursor: "pointer" }} onClick={() => { setisFilePreview(true); setisFilePreviewImage(file?.base64) }}>{index + 1}.{file?.fileName}
                                            {/* <div className="file-item" key={index}> */}
                                            {/* <img src={"data:image/jpeg;base64," + file?.base64} alt="Founded item image" style={{ width: "100px", height: "100px" }} /> */}
                                            {/* <div className="file-name" style={{ cursor: "pointer" }} onClick={() => { setisFilePreview(true); setisFilePreviewImage(file?.base64) }}>{file?.fileModule + file?.fileName}</div> */}
                                            {/* </div> */}
                                        </span>
                                        <br></br>

                                    </>
                                )
                            })}
                        </div>
                    }
                    {/* <img src={"data:image/jpeg;base64," + files[0]?.base64} alt="" srcset="" /> */}
                    {/* <div className="card">
                        <Galleria value={files} numVisible={5} style={{}} showItemNavigators showThumbnails={false} item={itemTemplate} />
                    </div> */}
                </>
            </Dialog>

            <Dialog visible={exportModelVisible} header={`Export ${expotLostObj?.isModalVisible?"Lost":"Found"} Details`} headerStyle={{color:"#ae9d81",textAlign:"center"}} onHide={()=>{setExportModelVisible(false)}}>
              <div className='flex align-items-center flex-wrap'>
                <div className='mx-2'>
                 <h4 className='mb-2'>From Date</h4>
                 <Calendar value={expotLostObj?.isModalVisible?expotLostObj?.fromDate:exportFoundObj?.fromDate} placeholder='Select from date' onChange={(e)=>{
                    expotLostObj?.isModalVisible?
                    setExpotLostObj((prev)=>({...prev,fromDate:e.value})):
                    setExpotFoundObj((prev)=>({...prev,fromDate:e.value}))
                    ;
                    }}/>
                </div>
                <div className='mx-2'>
                    <h4 className='mb-2'>To Date</h4>
                    <Calendar value={expotLostObj?.isModalVisible?expotLostObj.toDate:exportFoundObj?.toDate} placeholder='Select to date' onChange={(e)=>{
                         expotLostObj?.isModalVisible?
                         setExpotLostObj((prev)=>({...prev,toDate:e.value})):
                         setExpotFoundObj((prev)=>({...prev,toDate:e.value}))
                        
                        }}/>
                </div>
              </div>
                <div className='mx-2'>
                    <h4 className='mb-2'>Search</h4>
                    <InputText value={expotLostObj?.isModalVisible?expotLostObj?.searchTerms:exportFoundObj?.searchTerms} placeholder='Search' onChange={(e)=>{
                        expotLostObj?.isModalVisible?
                        setExpotLostObj((prev)=>({...prev,searchTerms:e.target.value})):
                        setExpotFoundObj((prev)=>({...prev,searchTerms:e.target.value}))
                        }}/>
                </div>
              <div className='flex justify-content-center my-4'>
                  <Button className='border-0' 
                          style={{backgroundColor:"#ae9d81",width:180,height:40,display:"flex",justifyContent:"center"}}
                          onClick={()=> expotLostObj?.isModalVisible?exportlostDetails("Lost"):exportlostDetails("Found")}>
                      Submit
                  </Button>
              </div>
            </Dialog>

            <Dialog
                // header={<div><p style={{ textAlign: "center" }}>Image Preview</p></div>}
                visible={isFilePreview}
                style={{ width: "80vw", height: "90vh" }}
                onHide={() => setisFilePreview(false)}
            >
                <div style={{ display: "flex" }}>
                    <img style={{ margin: 'auto' }} src={"data:image/jpeg;base64," + isFilePreviewImage} alt="" srcset="" />
                </div>
            </Dialog>
            <div className="adminlostandfound">
                <div className="header h-auto">
                    <div style={{ display: "flex", flexDirection: "row", flexGrow: "3", justifyContent: "flex-start" }} className='flex align-items-center org'>
                        <div className="menubtn" style={{ padding: '10px 10px 5px 20px' }}>
                            <i className="pi pi-align-justify" style={{ fontSize: '1rem', color: "#ae9d81", cursor: "pointer" }}
                                onClick={() => dispatch(setNavVisibilty({ sideNavVisibility: true }))}></i>
                        </div>

                        <div className="heading" style={{ paddingLeft: "15px", marginLeft: '40px' }}>
                            <p style={{
                                color: "#2d6c8b", fontSize: "18px", fontStyle: "normal",
                                fontWeight: "700", lineHeight: "normal",
                                letterSpacing: "-0.3px"
                            }}>Lost and Found Dashboard</p>
                        </div>
                    </div>
                </div>
                <div className="card" style={{ padding: "0 10px" }}>
                    <TabView activeIndex={activeIndex} style={{ marginLeft: "20px" }} onTabChange={(e) => { handleTabChange(e.index); }} >
                        <TabPanel header="Lost" className={activeIndex === 0 ? 'active_tab tab_view' : "tab_view"}>
                            <div className='header' style={{ justifyContent: 'flex-end', width: "96%" }}>
                               <div className='flex align-items-center' style={{ marginTop: '-110px'}}>
                                    <div className='mx-2'>
                                            <h4 className='mb-1 mt-0 relative' style={{color:"#ae9d81"}}>From Date</h4>
                                            <Calendar className='customeCalender' style={{width:160}} value={expotLostObj?.fromDate} placeholder='Select' onChange={(e)=>{
                                                setExpotLostObj((prev)=>({...prev,fromDate:e.value}));
                                                getLostItemList(10,0,lostFilterValue,e.value,expotLostObj?.toDate);
                                                }}/>
                                        </div>
                                        <div className='mx-2' >
                                        <h4 className='mb-1 mt-0 relative' style={{color:"#ae9d81"}}>To Date</h4>
                                            <Calendar className='customeCalender' style={{width:160}}  value={expotLostObj?.toDate} placeholder='Select' onChange={(e)=>{
                                                    setExpotLostObj((prev)=>({...prev,toDate:e.value}));
                                                    getLostItemList(10,0,lostFilterValue,expotLostObj?.fromDate,e.value);
                                                    }}/>
                                        </div>

                                        <div className="mx-2" >
                                        <h4 className='mb-1 mt-0 relative' style={{color:"#ae9d81"}}>Search</h4>
                                            <div className='flex align-items-center' style={{border:"solid 1px #AE9D81",borderRadius:"4px",position:"relative",zIndex:30}}>
                                                <i className="pi pi-search pl-2 pr-1" style={{ color: 'black', fontWeight: '700', fontSize: '1rem' }} />
                                                <InputText value={lostFilterValue} onChange={onLostFilterChange} placeholder="Search" className='border-0' style={{height:40,width:160}}/>
                                                <i className="pi pi-times pl-1 pr-2" style={{ color: 'black', fontWeight: '400', fontSize: '1rem', cursor: 'pointer' }} onClick={() => {  setLostFilterValue(); onLostFilterChange(null) }} />
                                            </div>
                                        </div>   
                                        <div className='mx-2 align-self-end'>
                                        <Button style={{backgroundColor:"#ae9d81",border:0}} onClick={()=>{
                                            setExpotLostObj((prev)=>({...prev,fromDate:"",toDate:"",searchTerms:""}));
                                            setLostFilterValue("");
                                            getLostItemList(10,0,"","","");
                                            }}>Clear All</Button>
                                        </div>
                                        <div className='export mx-2 align-self-end' >
                                            <Button label='Export' onClick={OnExportLostClick} icon={"pi pi-file-export"} style={{ backgroundColor: 'transparent', color: '#ae9d81', border: '1px #ae9d81 solid', borderRadius: '20px',height:"46px" ,cursor: 'pointer' }} />
                                        </div>
                               </div>
                                
                            </div>
                            <div className="datatable" style={{ margin: "0px 48px" }}>
                                {lostLoading ?
                                    <div className='spinner spinner-center' style={{ marginLeft: "700px" }}></div>
                                    :
                                    <DataTable
                                        stripedRows
                                        value={lostList}
                                        tableStyle={{ width: '85rem' }}
                                        style={{ boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}
                                        emptyMessage={<><p style={{ textAlign: "center" }}>Lost list is empty</p></>}>
                                        <Column field='lostCode' header=' Lost Code'></Column>
                                        <Column field='category' header='Category'></Column>
                                        <Column field='whatDidYouLose' header='Lost Item'></Column>
                                        <Column field='lostDateTime' header='Lost Date and Time' body={lostDateBodytemplate}></Column>
                                        <Column field='createdDate' header='Created Date' body={createdDateBodytemplate}></Column>
                                        <Column field='whereDidYouLose' header='Lost Location'></Column>
                                        <Column header='Action' body={lostActionctionBodyTemplate}></Column>
                                    </DataTable>}
                            </div>
                            {lostLoading || lostList?.length == 0 ? ""
                                :
                                <div style={{ display: 'flex', justifyContent: 'center' }} >
                                    <Paginator first={lostFirst} rows={10} totalRecords={lostListCount} onPageChange={(e) => onLostPageChange(e, 1)} template={{ layout: 'RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink  LastPageLink CurrentPageReport' }} className="justify-content-start" />
                                </div>
                            }
                        </TabPanel>
                        <TabPanel header="Found" className={activeIndex === 1 ? 'active_tab tab_view' : "tab_view"}>
                            <div className='flex' style={{ justifyContent: 'flex-end',width:"96%",marginTop:"-80px" }}>
                                <div className='mx-2'>
                                    <h4 className='mb-1 mt-0 relative' style={{color:"#ae9d81"}}>From Date</h4>
                                    <Calendar  className='customeCalender' style={{width:160}} value={exportFoundObj?.fromDate} placeholder='Select' onChange={(e)=>{
                                        setExpotFoundObj((prev)=>({...prev,fromDate:e.value}));
                                        getFoundItemList(10, 0,foundFilterValue,e.value,exportFoundObj?.toDate);
                                        }}/>
                                </div>
                                <div className='mx-2'>
                                     <h4 className='mb-1 mt-0 relative' style={{color:"#ae9d81"}}>To Date</h4>
                                    <Calendar className='customeCalender'  style={{width:160}}  value={exportFoundObj?.toDate} placeholder='Select' onChange={(e)=>{
                                            setExpotFoundObj((prev)=>({...prev,toDate:e.value}));
                                            getFoundItemList(10, 0,foundFilterValue,exportFoundObj?.fromDate,e.value);
                                            }}/>
                                </div>
                                <div className="mx-2" >
                                    <h4 className='mb-1 mt-0 relative' style={{color:"#ae9d81"}}>Search</h4>
                                    <div className='flex align-items-center' style={{border:"solid 1px #AE9D81",borderRadius:"4px",position:"relative"}}>
                                        <i className="pi pi-search pl-2 pr-1" style={{ color: 'black', fontWeight: '700', fontSize: '1rem' }} />
                                        <InputText value={foundFilterValue} onChange={onFoundFilterChange} placeholder="Search" className='border-0' style={{width:"150px",height:"42px"}}/>
                                        <i className="pi pi-times pl-1 pr-2" style={{ color: 'black', fontWeight: '400', fontSize: '1rem', cursor: 'pointer' }} onClick={() => { setFoundFilterValue(""); onFoundFilterChange() }} />
                                    </div>
                                </div>  
                                <div className='mx-2 align-self-end'>
                                    <Button style={{backgroundColor:"#ae9d81",border:0}} onClick={()=>{
                                        setExpotFoundObj((prev)=>({...prev,fromDate:"",toDate:""}));
                                        setFoundFilterValue("");
                                        getFoundItemList(10,0,"","","");
                                        }}>Clear All</Button>
                                </div>
                                <div className='export mx-2  align-self-end'>
                                    <Button label='Export' onClick={OnExportFoundClick} icon={"pi pi-file-export"} style={{ backgroundColor: 'transparent', color: '#ae9d81', border: '1px #ae9d81 solid', borderRadius: '20px',height:"46px", marginRight: '5px', cursor: 'pointer' }} />
                                </div>
                            </div>
                            <div className="datatable" style={{ margin: "0px 48px" }}>
                                {foundLoading ?
                                    <div className='spinner spinner-center' style={{ marginLeft: "700px" }}></div>
                                    :
                                    <DataTable
                                        stripedRows
                                        value={foundList}
                                        tableStyle={{ width: '85rem' }}
                                        style={{ boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}
                                        // rows={10}
                                        emptyMessage={<><p style={{ textAlign: "center" }}>Found item list is empty</p></>}>
                                        <Column field='foundCode' header='Found Code'></Column>
                                        <Column field='category' header='Category'></Column>
                                        <Column field='whatDidYouFind' header='Item Found'></Column>
                                        <Column field='foundDateTime' header='Lost Date and Time' body={foundDateBodytemplate}></Column>
                                        <Column field='createdDate' header='Created Date' body={createdDateBodytemplate}></Column>
                                        <Column field='whereDidYouFind' header='Lost Location'></Column>
                                        <Column header='Image' body={imageBodyTemplate}></Column>
                                        <Column header='Action' body={foundActionctionBodyTemplate}></Column>
                                    </DataTable>}
                            </div>
                            {foundLoading || foundList?.length == 0 ? ""
                                :
                                <div style={{ display: 'flex', justifyContent: 'center' }} >
                                    <Paginator first={foundFirst} rows={10} totalRecords={foundListCount} onPageChange={(e) => onFoundPageChange(e, 1)} template={{ layout: 'RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink  LastPageLink CurrentPageReport' }} className="justify-content-start" />
                                </div>
                            }


                        </TabPanel>
                        <TabPanel header="Return" className={activeIndex === 2 ? 'active_tab tab_view' : "tab_view"}>
                            <div className='header h-auto' style={{ justifyContent: 'flex-end',marginTop: '-80px'  }}>
                                <div className="mx-2" >
                                     <h4 className='mb-1 mt-0 relative' style={{color:"#ae9d81"}}>Search</h4>
                                    <div className='flex align-items-center' style={{border:"solid 1px #AE9D81",borderRadius:"4px",position:"relative"}}>
                                        <i className="pi pi-search pl-2 pr-1" style={{ color: 'black', fontWeight: '700', fontSize: '1rem' }} />
                                        <InputText value={returnFilterValue} onChange={onReturnFilterChange} placeholder="Search" className='border-0' style={{width:160}}/>
                                        <i className="pi pi-times pl-1 pr-2" style={{ color: 'black', fontWeight: '400', fontSize: '1rem', cursor: 'pointer' }} onClick={() => { setReturnFilterValue(""); onReturnFilterChange()  }} />
                                    </div>
                                </div> 
                                <div className='export mx-2 align-self-end' >
                                    <Button label='Export' onClick={()=>{returnListCount>0&&OnExportReturnClick()}} icon={"pi pi-file-export"} style={{ backgroundColor: 'transparent', color: '#ae9d81', border: '1px #ae9d81 solid', borderRadius: '20px', marginRight: '5px', cursor: 'pointer' }} />
                                </div>
                            </div>
                            <div className="datatable" style={{ margin: "0px 48px" }}>
                                {returnLoading ?
                                    <div className='spinner spinner-center' style={{ marginLeft: "700px" }}></div>
                                    :
                                    <DataTable
                                        stripedRows
                                        value={returnList}
                                        tableStyle={{ width: '85rem' }}
                                        style={{ boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}
                                        rows={10}
                                        emptyMessage={<><p style={{ textAlign: "center" }}>Return item list is empty</p></>}>
                                        <Column field='foundCode' header='Found Code' ></Column>
                                        <Column field='firstName' header='Name'></Column>
                                        <Column field='email' header='Email' ></Column>
                                        <Column field='witnessPersonName' header='Witness Person Name' ></Column>
                                        <Column field='createdDate' header='Created Date' body={createdDateBodytemplate}></Column>
                                        <Column header='Action' body={returnActionctionBodyTemplate} ></Column>
                                    </DataTable>}
                            </div>
                            {returnLoading || returnList?.length == 0 ? ""
                                :
                                <div style={{ display: 'flex', justifyContent: 'center' }} >
                                    <Paginator first={returnFirst} rows={10} totalRecords={returnListCount} onPageChange={(e) => onReturnPageChange(e, 1)} template={{ layout: 'RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink  LastPageLink CurrentPageReport' }} className="justify-content-start" />
                                </div>
                            }

                        </TabPanel>
                    </TabView>
                </div>

            </div >
        </>
    )
}