import React from "react";
import "./style.scss";
import tickimg from "../../assets/images/tick.png";
import Localization from "../../assets/JSON/Localization.json";
import { useParams } from "react-router-dom";
function Modal({ setIsvisible, languageValue }) {
  // const { localizationValue } = useParams();
  const localizationValue = languageValue;
  return (
    <>
      <div className="overlay"></div>
      <div className="modal">
        <label
          className="close"
          for="modal-toggle"
          onClick={() => {
            window.location.href = "/feedback";
          }}
        >
          &times;
        </label>
        <div className="modal-content">
          <img src={tickimg} width="40" height="40" alt="not found" />
          <h2 style={{ padding: "10px 0" }}>
            {Localization[localizationValue]?.translation?.thankYouLabel}
          </h2>
          <p>{Localization[localizationValue]?.translation?.submissionAlert}</p>
        </div>
      </div>
    </>
  );
}

export default Modal;
