import Axios from '../Helpers/Axios'


export function GetFeedbackDepartmentByFeedbackId(feedbackId, token) {
    return Axios(`GuestServiceStaff/GetFeedbackDepartmentByFeedbackId?feedbackId=${feedbackId}`, 'get', null, token)
}
export function GetStatusForFeedback(feedbackId, token) {
    return Axios(`GuestServiceStaff/GetStatusForFeedback?feedbackId=${feedbackId}`, 'get', null, token)
}
//GuestServiceStaff/GetStatusForFeedback?feedbackId=25
