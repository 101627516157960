import React, { useEffect, useState } from 'react'
import { useCookies } from 'react-cookie'
import './style.scss'
// -------------Imports---------------
// import { Button } from 'primereact/button'
import { FilterMatchMode } from 'primereact/api';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import { Dialog } from 'primereact/dialog'
import { Dropdown } from 'primereact/dropdown'
import { InputText } from 'primereact/inputtext'
import { Link, useNavigate } from 'react-router-dom';
import { Paginator } from 'primereact/paginator';







// -----------------------------------

// ----------Assets Import------------
import editIcon from '../../../src/assets/images/editIcon.jpg'

// -----------------------------------

//------Imports from other Components-----
import { GetChatList, GetFeedbackList, NewChat, getCategories, getSubCategories } from '../../Services/FeedbackService'
//----------------------------------------

//-----------Context ---------------
import { useDispatch, useSelector } from 'react-redux'
import { on } from 'events';
import { setFeedbackList, setNavVisibilty } from '../../Redux/AppSlice';
import { Button } from 'primereact/button';
// import { addChatList } from '../../Redux/AppSlice'
import { exportDataInExcel, exportExcel, exportFilterdExcel } from '../../Services/ExportServices';
import { Calendar } from 'primereact/calendar';
import { GetActiveOrganizationList } from '../../Services/OrganizationService';

//----------------------------------------

function Feedback() {
    const navigate = useNavigate();
    const Context = useSelector((state) => state.AppContext)
    const dispatch = useDispatch()
    const [cookies, setCookie, removeCookie] = useCookies();
    const [buttonStatusMap, setButtonStatusMap] = useState([]);
    const [feedbackList, setFeedbackLists] = useState([])
    const [chat, setChat] = useState('')
    const [chatError, setChatError] = useState('')
    const [selectedFeedback, setSelectedFeedback] = useState({
        id: "",
        firstName: "",
        lastName: "",
        mobileNumber: "",
        email: "",
        category: "",
        subCategory: "",
        location: "",
        nationality: ""
    });
    const [isAddOrganization, setIsAddOrganization] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [chatList, setChatList] = useState([])

    const [globalFilterValue, setGlobalFilterValue] = useState('');
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        name: { value: null, matchMode: FilterMatchMode.CONTAINS }
    });
    // Pagination
    const [first, setFirst] = useState(0)
    const [totalCount, setTotalRecords] = useState(0)
    const [dropdownObj, setDropdownObj] = useState({
        categoryList: [],
        subCategoryList: [],
        organizationList: []
    })
    const [exportObj, setExportObj] = useState({
        fromDate: "",
        toDate: "",
        searchTerm: "",
        category: "",
        subcategory: "",
        organization: "",
        isModalVisible: false
    });

    const onEportFeedback = () => {
        const fromDate = !exportObj?.fromDate ? "" : `${new Date(exportObj?.fromDate)?.getFullYear()}-${(new Date(exportObj?.fromDate)?.getMonth() + 1)?.toString()?.padStart(2, "0")}-${new Date(exportObj?.fromDate)?.getDate()?.toString()?.padStart(2, "0")}`;
        const toDate = !exportObj?.toDate ? "" : `${new Date(exportObj?.toDate)?.getFullYear()}-${(new Date(exportObj?.toDate)?.getMonth() + 1)?.toString()?.padStart(2, "0")}-${new Date(exportObj?.toDate)?.getDate()?.toString()?.padStart(2, "0")}`;

        // let urlRoute=`FeedbacksForAdmin?categoryId=${exportObj?.category||"0"}&subCategoryId=${exportObj?.subcategory||"0"}&startDate=${fromDate}&endDate=${toDate}&organizationId=${exportObj?.organization}`;
        // console.log(urlRoute);
        // exportExcel(urlRoute);

        let urlRoute = `Feedback/List?categoryId=${exportObj?.category || ""}&subCategoryId=${exportObj?.subcategory || ""}&orgId=${exportObj?.organization || ""}&fromDate=${fromDate}&toDate=${toDate}&forExport=true`;
        let data = {
            take: totalCount,
            skip: 0,
            searchTerm: globalFilterValue
        }
        exportFilterdExcel(urlRoute, cookies?.bearerToken, data, "post");
    }
    const handleRowClick = (event) => {

        setSelectedFeedback(event.data);
    };


    const handleClick = (feedbackId) => {
        const newButtonStatusMap = { ...buttonStatusMap };
        newButtonStatusMap[feedbackId] = 'Acknowledged';
        setButtonStatusMap(newButtonStatusMap);
    };

    const getFeedbackList = (skip, take, searchValue, categoryId, subCategoryId, orgId, startDate, endDate) => {
        setIsLoading(true);
        let data = {
            take: 10,
            skip: skip
        }
        if (searchValue) {
            data["searchTerm"] = searchValue;
        }
        const fromDate = !startDate ? "" : `${new Date(startDate)?.getFullYear()}-${(new Date(startDate)?.getMonth() + 1)?.toString()?.padStart(2, "0")}-${new Date(startDate)?.getDate()?.toString()?.padStart(2, "0")}`;
        const toDate = !endDate ? "" : `${new Date(endDate)?.getFullYear()}-${(new Date(endDate)?.getMonth() + 1)?.toString()?.padStart(2, "0")}-${new Date(endDate)?.getDate()?.toString()?.padStart(2, "0")}`;
        GetFeedbackList(data, cookies.bearerToken, categoryId, subCategoryId, orgId, fromDate, toDate)
            .then((res) => {

                if (res?.status == 200) {
                    // if (res?.status === 200) {
                    setFeedbackLists(res?.data?.feedbacks);
                    setTotalRecords(res?.data?.totalRecords)
                    // }
                    dispatch(setFeedbackList({ feedbackList: res?.data?.feedbacks }))
                }
                else {
                    dispatch(setFeedbackLists([]))
                }
            })
            .catch((error) => {
                //console.log(error);
            })
            .finally(() => {
                setIsLoading(false);
            })
    };

    const closePopup = () => {
        setIsAddOrganization(false);
    };



    const nameBodyTemplate = ((row) => {

        return <p style={{ fontWeight: 400 }} >{row?.firstName} {row?.lastName}</p>

    })

    const statusBodyTemplate = (row) => {
        const buttonClass =
            buttonStatusMap[row.id] === 'Yet to ACK'
                ? 'yet-to-ack-button'
                : 'acknowledged-button';

        return (
            <button
                className={buttonClass}
                onClick={() => handleClick(row.id)}
            >
                {buttonStatusMap[row.id]}
            </button>
        );
    };

    const actionButtonTemplate = (row) => {
        return (
            <Link to={`/feedbackview/${row?.id}`}>
                {/* <img src={editIcon} style={{ cursor: 'pointer' }} /> */}
                <i className='pi pi-eye' style={{ cursor: 'pointer' }}></i>
            </Link>
            // ""
        );
    };

    const statuDropdown = [
        { label: 'Close', value: 'Close' },
        { label: 'Reassign', value: 'Reassign' },
    ];

    const departmentDropdown = [
        { label: 'Guest Service Department', value: 'Guest Service Department' },
        { label: 'Tenent Relation Department', value: 'Tenent Relation Department' },
        { label: 'Security Department', value: 'Security Department' }
    ]

    const formatDate = (timestamp) => {
        const timestampDate = new Date(timestamp);

        return timestampDate.toLocaleString('en-US', {
            month: 'short',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            hour12: true,
        });
    };




    const header = () => {
        return <div className="heading" style={{ fontWeight: "700", fontSize: "25px", color: "#ae9d81" }}>

        </div>
    }


    const getChatList = (id) => {

        GetChatList(cookies.bearerToken, id)
            .then((res) => {

                setChatList(res?.data);
                // dispatch(
                //     addChatList({
                //         chatList: res?.data
                //     }))
            })
            .catch((error) => {
                //console.log(error);
            })
            .finally(() => {
                // //console.log(chatList);
            })

    }



    const newChat = () => {
        if (!chat.trim()) {
            setChatError("No messages found")
            return
        }
        const data = {
            feedbackId: selectedFeedback.id,
            message: chat.trim()
        }
        NewChat(data, cookies.bearerToken)
            .then((res) => {

                setChatList(prev => {
                    return [...prev, {
                        id: -1,
                        messageFrom: localStorage.getItem("userName"),
                        message: chat.trim(),
                        messageDate: new Date().toString()
                    }]
                })

                setChat('')

                // dispatch(
                //     addChatList({
                //         chatList: res?.data
                //     }))
            })
            .catch((error) => {
                //console.log(error);
            })
            .finally(() => {
                // //console.log(chatList);
            })

    }



    const popup = (() => {
        return (
            <div>
                <Dialog header={header} visible={true} style={{}} onHide={() => { setIsAddOrganization(false) }}>
                    <div className="head">
                        <FontAwesomeIcon icon={faArrowLeft} className='arrow-icon' onClick={closePopup} style={{ cursor: "pointer" }} />Edit Details</div>
                    <div className="flex">
                        <div className="flex-1 p-4 flexbox-1">
                            <div className='defaultWidth'>
                                <p className='defaultStyle'>Name</p>
                                <InputText className="border" value={`${selectedFeedback?.firstName} ${selectedFeedback?.lastName}`} readOnly />
                            </div>
                            <div className='defaultWidth'>
                                <p className='defaultStyle'>Mobile Number</p>
                                <InputText className="border" value={selectedFeedback?.mobileNumber} readOnly />
                            </div>
                            <div className='defaultWidth'>
                                <p className='defaultStyle'>Email</p>
                                <InputText className="border" value={selectedFeedback?.email} readOnly />
                            </div>
                            <div className='defaultWidth'>
                                <p className='defaultStyle'>Nationality</p>
                                <InputText className="border" value={selectedFeedback?.nationality} />
                            </div>
                            <div className='defaultWidth'>
                                <p className='defaultStyle'>Attachments</p>
                                <InputText className="border attachment" />
                            </div>
                            <p className=" heading">Guest Service Details</p>
                            <div className='defaultWidth'>
                                <p className='defaultStyle'>Department</p>
                                <Dropdown placeholder='Select Department' options={departmentDropdown} className="border" />
                            </div>
                        </div>
                        <div className="flex-1  p-4 flexbox-2">
                            <div className='defaultWidth'>
                                <p className='defaultStyle'>Category</p>
                                <InputText className="border" value={selectedFeedback?.category} readOnly />
                            </div>
                            <div className='defaultWidth'>
                                <p className='defaultStyle'>Sub Category</p>
                                <InputText className="border" value={selectedFeedback?.subCategory} readOnly />
                            </div>
                            <div className='defaultWidth'>
                                <p className='defaultStyle'>Location</p>
                                <InputText className="border" />
                            </div>
                            <div className='defaultWidth'>
                                <p className='defaultStyle'>Feedback</p>
                                <InputText className="border feedback" />
                            </div>
                            <div className='defaultWidth status'>
                                <p className='defaultStyle'>Status</p>
                                <Dropdown placeholder='Acknowledge' options={statuDropdown} className="border" />
                            </div>
                            <div className=" flex justify-content-center main-btn ">
                                <button className='cancel-btn' style={{ cursor: "pointer" }} onClick={closePopup} >Cancel</button>
                                <button className='submit-btn' style={{ cursor: "pointer" }} >Submit</button>
                            </div>
                        </div>
                        <div className="vl"></div>
                        <div>
                            <div className="flex-1 p-4 flexbox-3">
                                <div style={{ height: 400, overflow: "auto" }}>
                                    {chatList.map((chat, index) => (
                                        <div key={chat.id} className={localStorage.getItem("userName") === chat.messageFrom ? 'user2' : 'user'}>
                                            <p>{chat.message}</p>
                                            <span className={'time'}>{formatDate(chat.messageDate)}</span>
                                        </div>
                                    ))}
                                </div>
                                {/* {chatList?.map((chat, index) => (
                                    <div key={chat.id} className={localStorage.getItem("userName") === chat.messageFrom ? 'user2' : 'user'}>
                                        <p>{chat.message}</p>
                                        <span className='time'>{formatDate(chat.messageDate)}</span>
                                    </div>
                                ))} */}
                                <InputText className='user3' value={chat} onChange={(e) => setChat(e.target.value)} placeholder='write your command here' />
                                <button className='send' onClick={newChat}>Send</button>
                            </div>
                        </div>
                        {chatError}

                    </div>

                </Dialog>

            </div>
        )
    })

    const onGlobalFilterChange = (e) => {
        if (!e) {
            getFeedbackList(0, 10, "", exportObj?.category, exportObj?.subcategory, exportObj?.organization, exportObj?.fromDate, exportObj?.toDate);
            return
        }
        setGlobalFilterValue(e.target.value);
        getFeedbackList(0, 10, e?.target?.value, exportObj?.category, exportObj?.subcategory, exportObj?.organization, exportObj?.fromDate, exportObj?.toDate);
        return
        const value = e ? e.target.value : "";
        let _filters = { ...filters };
        _filters['global'].value = value;
        setFilters(_filters);
        setGlobalFilterValue(value);
    };
    const back = () => {
        navigate('/dashboard');
    };
    const onPageChange = (e, index) => {
        setFirst(e.first);
        getFeedbackList(e.first, 10, globalFilterValue, exportObj?.category, exportObj?.subcategory, exportObj?.organization, exportObj?.fromDate, exportObj?.toDate);

    };
    const exportFeedbackInfo = () => {
        if (globalFilterValue) {
            exportDataInExcel(`FeedbacksForAdmin?searchTerm=${globalFilterValue}`, cookies.bearerToken);
            return
        }
        exportDataInExcel('FeedbacksForAdmin', cookies.bearerToken);
    }

    const getOrganizationList = () => {
        GetActiveOrganizationList(cookies?.bearerToken).then(res => {
            if (res?.status == 200) {
                setDropdownObj((prev) => ({ ...prev, organizationList: res?.data }));
            }
        })
    }
    const getCategoryList = () => {
        getCategories().then(res => {
            if (res?.status == 200) {
                setDropdownObj((prev) => ({ ...prev, categoryList: res?.data }));
            }
        })
    }
    const getSubcategoryList = () => {
        getSubCategories().then(res => {
            if (res?.status == 200) {
                setDropdownObj((prev) => ({ ...prev, subCategoryList: res?.data?.data }));
            }
        })
    }
    useEffect(() => {
        getOrganizationList();
        getCategoryList();
        getSubcategoryList();

        // setOrganizationList(Context.organizationList);
        getFeedbackList(0, 10);
    }, [])

    return (
        <>
            <div className='Feedback'>
                <div style={{
                    // display: "flex",
                    // flexDirection: "row",
                    // height: " 50px",
                    width: "100%",
                    // alignItems: "center",
                    // justifyContent: 'space-between'
                }} >

                    <div style={{ display: "flex" }} className='align-items-center org'>
                        {/* <i className="pi pi-arrow-circle-left nav-back-btn" onClick={back} style={{ marginLeft: '30px', cursor: 'pointer', fontSize: '1.5rem' }} /> */}
                        <div className="menubtn" style={{ padding: '10px 10px 5px 20px' }}>
                            <i className="pi pi-align-justify" style={{ fontSize: '1rem', color: "#ae9d81", cursor: "pointer" }}
                                onClick={() => dispatch(setNavVisibilty({ sideNavVisibility: true }))}></i>
                        </div>
                        <div className='heading'>
                            <p style={{ fontWeight: 700 }}>Feedback </p>
                        </div>
                    </div>

                    <div style={{ display: 'flex', }}>
                        <div className='mx-2'>
                            <h4 className='mb-1 mt-0' style={{ color: "#ae9d81" }}>Category</h4>
                            <Dropdown style={{ border: "solid 1px #ae9d81", width: 170 }} value={exportObj?.category} options={dropdownObj?.categoryList} optionLabel='categoryName' optionValue='id' onChange={(e) => { setExportObj((prev) => ({ ...prev, category: e.value })); getFeedbackList(0, 10, globalFilterValue, e.value, exportObj?.subcategory, exportObj?.organization, exportObj?.fromDate, exportObj?.toDate) }} placeholder='Select' />
                        </div>
                        <div className='mx-2'>
                            <h4 className='mb-1 mt-0' style={{ color: "#ae9d81" }}>Subcategory</h4>
                            <Dropdown style={{ border: "solid 1px #ae9d81", width: 170 }} value={exportObj?.subcategory} options={dropdownObj?.subCategoryList} optionLabel='subCategoryName' optionValue='subCategoryId' onChange={(e) => { setExportObj((prev) => ({ ...prev, subcategory: e.value })); getFeedbackList(0, 10, globalFilterValue, exportObj?.category, e.value, exportObj?.organization, exportObj?.fromDate, exportObj?.toDate) }} placeholder='Select' />
                        </div>
                        <div className='mx-2'>
                            <h4 className='mb-1 mt-0' style={{ color: "#ae9d81" }}>Organization</h4>
                            <Dropdown style={{ border: "solid 1px #ae9d81", width: 170 }} value={exportObj?.organization} options={dropdownObj?.organizationList} optionLabel='name' optionValue='id' onChange={(e) => { setExportObj((prev) => ({ ...prev, organization: e.value })); getFeedbackList(0, 10, globalFilterValue, exportObj?.category, exportObj?.subcategory, e.value, exportObj?.fromDate, exportObj?.toDate) }} placeholder='Select' />
                        </div>
                        <div className='mx-2'>
                            <h4 className='mb-1 mt-0' style={{ color: "#ae9d81" }}>From Date</h4>
                            <Calendar className='customeCalender' style={{ width: 170, }} value={exportObj?.fromDate} placeholder='Select' onChange={(e) => {
                                setExportObj((prev) => ({ ...prev, fromDate: e.value }));
                                getFeedbackList(0, 10, globalFilterValue, exportObj?.category, exportObj?.subcategory, exportObj?.organization, e.value, exportObj?.toDate)
                            }} />
                        </div>
                        <div className='mx-2'>
                            <h4 className='mb-1 mt-0' style={{ color: "#ae9d81" }}>To Date</h4>
                            <Calendar className="customeCalender" style={{ width: 170 }} value={exportObj?.toDate} placeholder='Select ' onChange={(e) => {
                                setExportObj((prev) => ({ ...prev, toDate: e.value }));
                                getFeedbackList(0, 10, globalFilterValue, exportObj?.category, exportObj?.subcategory, exportObj?.organization, exportObj?.fromDate, e.value)
                            }} />
                        </div>
                        <div className='mx-2'>
                            <h4 className='mb-1 mt-0' style={{ color: "#ae9d81" }}>Search</h4>
                            <div className='search flex align-items-center' style={{ border: "solid 1px #ae9d81", borderRadius: "4px", height: "42px" }}>
                                <i className='pi pi-search px-2' style={{ color: 'black', fontWeight: '700', fontSize: '1rem' }} />
                                <InputText style={{ width: 160, border: 0, height: "38px" }} value={globalFilterValue} onChange={onGlobalFilterChange} placeholder='Search' />
                                <i className="pi pi-times px-2" style={{ color: 'black', fontWeight: '400', fontSize: '1rem' }} onClick={() => { setGlobalFilterValue(""); onGlobalFilterChange(null) }} />
                            </div>
                        </div>
                        <div className='mx-2 align-self-end'>
                            <Button style={{ backgroundColor: "#ae9d81", border: 0, width: 100 }} onClick={() => {
                                setExportObj((prev) => ({ ...prev, isModalVisible: false, category: "", fromDate: "", organization: "", subcategory: "", toDate: "" }));
                                setGlobalFilterValue("");
                                getFeedbackList(0, 10)
                            }}>
                                Clear All
                            </Button>
                        </div>
                        <div className='mx-2 align-self-end'>
                            <Button label='Export'
                                onClick={() => totalCount > 0 ? onEportFeedback() : null} icon={"pi pi-file-export"} style={{ height: "46px", backgroundColor: 'transparent', color: '#ae9d81', border: '1px #ae9d81 solid', borderRadius: '20px', cursor: totalCount > 0 ? 'pointer' : 'not-allowed' }} />
                        </div>
                    </div>
                </div>


                <div className='datatable'>
                    {isLoading ?
                        <div className='spinner spinner-center'></div>
                        :
                        <DataTable
                            value={feedbackList}
                            tableStyle={{
                                width: '90rem'
                            }}
                            style={{ boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}
                            stripedRows
                            filters={filters}
                            emptyMessage={<><p style={{ textAlign: "center" }}>Feedback list is empty</p></>}
                            selectionMode="single"
                            selection={selectedFeedback}
                            rows={10}
                            globalFilterFields={['code', 'firstName', 'email', 'category', 'subCategory', 'location', 'name']}
                        // onSelectionChange={handleRowClick}
                        >
                            <Column field='code' header='Feedback Id'></Column>
                            <Column field='firstName' body={nameBodyTemplate} header='Customer Name'></Column>
                            <Column field='email' header='Email'></Column>
                            <Column field='createdDate' header='Created Date'></Column>
                            <Column field='category' header='Category'></Column>
                            <Column field='subCategory' header='Sub Category'></Column>
                            <Column field='location' header='Location'></Column>
                            <Column field='action' header='View' body={actionButtonTemplate}></Column>
                        </DataTable>}
                </div>
                {isLoading ? ""
                    :
                    <div style={{ justifyContent: "center", display: 'flex' }} >
                        <Paginator first={first} rows={10} totalRecords={totalCount} onPageChange={(e) => onPageChange(e, 1)} template={{ layout: 'RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink  LastPageLink CurrentPageReport' }} className="justify-content-start" />
                    </div>
                }
                <div>
                    {isAddOrganization == true ? popup() : ""}
                </div>

            </div >
            <Dialog visible={exportObj?.isModalVisible} header={`Export Feedback Details`} headerStyle={{ color: "#ae9d81", textAlign: "center" }} onHide={() => { setExportObj((prev) => ({ ...prev, isModalVisible: false })) }}>
                <div className='flex align-items-center flex-wrap'>

                    <div className='mx-0 w-5'>
                        <h4 className='mb-2'>Category</h4>
                        <Dropdown className='w-full' value={exportObj?.category} options={dropdownObj?.categoryList} optionLabel='categoryName' optionValue='id' onChange={(e) => { setExportObj((prev) => ({ ...prev, category: e.value })) }} placeholder='Select category' />
                    </div>
                    <div className='mx-0 w-5'>
                        <h4 className='mb-2'>Sub Category</h4>
                        <Dropdown className='w-full' value={exportObj?.subcategory} options={dropdownObj?.subCategoryList} optionLabel='subCategoryName' optionValue='subCategoryId' onChange={(e) => { setExportObj((prev) => ({ ...prev, subcategory: e.value })) }} placeholder='Select subcategory' />
                    </div>
                </div>
                <div className='flex align-items-center flex-wrap'>

                    <div className='mx-0 w-5'>
                        <h4 className='mb-2'>From Date</h4>
                        <Calendar value={exportObj?.fromDate} placeholder='Select from date' onChange={(e) => {
                            setExportObj((prev) => ({ ...prev, fromDate: e.value }));
                        }} />
                    </div>

                    <div className='mx-0 w-5'>
                        <h4 className='mb-2'>To Date</h4>
                        <Calendar value={exportObj?.toDate} placeholder='Select to date' onChange={(e) => {
                            setExportObj((prev) => ({ ...prev, toDate: e.value }));
                        }} />
                    </div>
                </div>
                <div className='mx-4 w-5'>
                    <h4 className='mb-2'>Organization</h4>
                    <Dropdown className='w-full' value={exportObj?.organization} options={dropdownObj?.organizationList} optionLabel='name' optionValue='id' onChange={(e) => { setExportObj((prev) => ({ ...prev, organization: e.value })) }} placeholder='Select organization' />
                </div>
                <div className='flex justify-content-center my-4'>
                    <Button className='border-0'
                        style={{ backgroundColor: "#ae9d81", width: 180, height: 40, display: "flex", justifyContent: "center" }}
                        onClick={() => { onEportFeedback() }}>
                        Submit
                    </Button>
                </div>
            </Dialog>
        </>
    )
}

export default Feedback
