import React, { useState } from "react";
import "./SubscriptionConfig.scss";
import { useDispatch } from "react-redux";
import { setNavVisibilty } from "../../../../Redux/AppSlice";
import { useEffect } from "react";

// Prime React Components
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Paginator } from "primereact/paginator";
import { Dialog } from "primereact/dialog";
import { useCookies } from "react-cookie";
import { AutoComplete } from "primereact/autocomplete";
import { Calendar } from "primereact/calendar";
import { InputSwitch } from "primereact/inputswitch";
import { TabMenu } from "primereact/tabmenu";
import { toast } from "react-toastify";
import { Badge } from "primereact/badge";

import editIcon from "../../../../assets/images/editIcon.jpg";

import {
  AddSubcription,
  EditSubcription,
  GetSubcription,
} from "../../../../Services/VIPLoungeMaster";
import { useNavigate, useNavigation } from "react-router-dom";
import { GetActiveOrganizationList } from "../../../../Services/OrganizationService";
import { Dropdown } from "primereact/dropdown";

const SubscriptionConfig = () => {
  // local variables
  const inintialSubscriptionDetails = {
    subscriptionName: "",
    invoiceAmount: null,
    invoiceExpiryDays: null,
    creditExpiryDays: null,
    subcriptionExpiringLimitDays: null,
    isActive: true,
    isEdit: false,
    id: null,
    selectedOrganization: null
  };
  const [organizationList, setOrganizationList] = useState([]);

  const subcriptionTypes = [
    {
      label: "Invoice",
      index: 0,
      value: 1,
    },
    {
      label: "Expiry date",
      value: 2,
      index: 1,
    },
  ];
  // states
  const [isLoading, setIsLoading] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const [isErrMsgView, setIsErrMsgView] = useState(false);
  const [searchFilterValue, setSearchFilterValue] = useState("");
  const dispatch = useDispatch();

  // Pagination
  const [first, setFirst] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [cookies, setCookie, removeCookie] = useCookies();

  //data
  const [subscriptionMasterData, setSubscriptionMasterData] = useState([]);
  const [data, setData] = useState([]);
  const [subscriptionData, setSubscriptionData] = useState([]);

  // modal
  const [isModalOpen, setIsModalOpen] = useState(false);

  //details
  const [subscriptionDetails, setSubscriptionDetails] = useState({
    ...inintialSubscriptionDetails,
  });

  //tabmenu
  const [tabDetails, setTabDetails] = useState({
    currIndex: 0,
    selectedTabValue: 1,
  });
  const isInvoice = tabDetails?.selectedTabValue === 1;
  //navigation
  const navigate = useNavigate();


  const onPageChange = (e, index) => {
    console.log("e", e);
    let tempData = [...subscriptionMasterData].filter(
      (val) => val?.subscriptionTypeID == tabDetails?.selectedTabValue
    );

    if (e.first == 0) {
      tempData = tempData?.slice(0, 10);
    } else {
      tempData = tempData?.slice(e.first - 1, 10);
    }
    setFirst(e.first);
    setSubscriptionData([...tempData]);
  };

  const getStatusSeverity = (row) => {
    let statusSeverity = "";
    if (row.status === "Inactive") {
      statusSeverity = "warning";
    } else if (row.status === "Active") {
      statusSeverity = "success";
    }
    return statusSeverity;
  };

  const actionButtonTemplate = (row) => {
    return (
      <>
        <div style={{ display: "flex", gap: "15px", justifyContent: "center" }}>
          <div
            onClick={(e) => {
              console.log("row", row);
            }}
          >
            <img
              style={{ cursor: "pointer" }}
              onClick={() => {
                setSubscriptionDetails({
                  subscriptionName: row?.name,
                  invoiceAmount: row?.invoiceAmount,
                  invoiceExpiryDays: row?.invoiceExpiryInDays,
                  creditExpiryDays: row?.creditExpiryDays,
                  subcriptionExpiringLimitDays: row?.subscriptionExpiryInDays,
                  isActive: row?.status === "Active",
                  isEdit: true,
                  id: row?.id,
                  selectedOrganization: row?.organizationId || 0
                });
                setIsModalOpen(true);
              }}
              src={editIcon}
            />
          </div>
        </div>
      </>
    );
  };

  const statusTemplate = (row) => {
    return (
      <div className="delivery-status">
        <Badge value={row.status} severity={getStatusSeverity(row)} />
      </div>
    );
  };

  const handleInputValue = (key, value) => {
    setSubscriptionDetails({ ...subscriptionDetails, [key]: value });
  };

  //tab menu

  const handleTabMenuValue = (value) => {
    setTabDetails({ currIndex: value?.index, selectedTabValue: value?.value });
    let tempData = [...subscriptionMasterData].filter(
      (val) => val?.subscriptionTypeID == value?.value
    );
    console.log("tempData", tempData);
    setSubscriptionData([...tempData].slice(0, 10));
    setTotalCount(tempData.length);
  };

  const handleAdd = async () => {
    setIsLoading(true);

    let data = {
      amount: subscriptionDetails?.invoiceAmount
        ? subscriptionDetails?.invoiceAmount
        : 0,
      expiringLimitInDays: subscriptionDetails?.invoiceExpiryDays
        ? subscriptionDetails?.invoiceExpiryDays
        : 0,
      creditExpiringLimitInDays: subscriptionDetails?.creditExpiryDays
        ? subscriptionDetails?.creditExpiryDays
        : 0,
      name: subscriptionDetails?.subscriptionName,
      subscriptionTypeId: tabDetails?.selectedTabValue,
      isInvoiceBasedSubscription: isInvoice,
      subscriptionExpiringLimitInDays:
        subscriptionDetails?.subcriptionExpiringLimitDays
          ? subscriptionDetails?.subcriptionExpiringLimitDays
          : 0,
      isActive: subscriptionDetails?.isActive,
      organizationId: subscriptionDetails?.selectedOrganization || 0
    };
    // let data = {
    //   amount: subscriptionDetails?.invoiceAmount
    //     ? subscriptionDetails?.invoiceAmount
    //     : 0,
    //   expiringLimitInDays: subscriptionDetails?.invoiceExpiryDays
    //     ? subscriptionDetails?.invoiceExpiryDays
    //     : 0,
    //   creditExpiringLimitInDays: subscriptionDetails?.creditExpiryDays
    //     ? subscriptionDetails?.creditExpiryDays
    //     : 0,
    //   name: subscriptionDetails?.subscriptionName,
    //   subscriptionTypeId: tabDetails?.selectedTabValue,
    //   isInvoiceBasedSubscription: isInvoice,
    //   subscriptionExpiringLimitInDays:
    //     subscriptionDetails?.subcriptionExpiringLimitDays
    //       ? subscriptionDetails?.subcriptionExpiringLimitDays
    //       : 0,
    //   isActive: subscriptionDetails?.isActive,
    // };

    await AddSubcription(data, cookies?.bearerToken).then(async (res) => {
      console.log("res", res);
      if (res?.status == 200) {
        setBtnLoading(false);
        setIsLoading(false);
        toast.success("Subscription added successfully", {
          position: "top-right",
        });
        setSearchFilterValue("");
        getAllSubscription(0, 1000, "");
      } else {
        console.log(res?.data?.message, res?.data, res);
        setBtnLoading(false);
        setIsLoading(false);
        toast.error(res?.response.data?.message || "There is issue occured in add subscription", {
          position: "top-right",
        });
        if (res?.response.data?.message == 'Already Exists')
          toast.info("The subscription name is already exists in  subscription list , please try with different name!", {
            position: "top-right",
          })
      }
    });
  };

  const getAllSubscription = async (skip, take, searchTerm) => {
    await GetSubcription(cookies?.bearerToken, searchTerm, skip, take).then(
      async (res) => {
        console.log("res", res);
        let tempData = [];
        if (res?.status === 200) {
          if (res?.data?.data) {
            setSubscriptionMasterData(res?.data?.data);
            setData(res?.data?.data);

            tempData = [...res?.data?.data]?.filter(
              (val) => val?.subscriptionTypeID == tabDetails?.selectedTabValue
            );
            setSubscriptionData([...tempData]);
            setData([...tempData]);

            setTotalCount(tempData.length);
            setIsLoading(false);
          } else {
            setSubscriptionData([...tempData]);
            setData([...tempData]);

            setTotalCount(tempData.length);
            setIsLoading(false);
          }
        }
      }
    );
  };

  const handleUpdate = async () => {
    setIsLoading(true);
    let data = {
      amount: subscriptionDetails?.invoiceAmount
        ? subscriptionDetails?.invoiceAmount
        : 0,
      expiringLimitInDays: subscriptionDetails?.invoiceExpiryDays
        ? subscriptionDetails?.invoiceExpiryDays
        : 0,
      creditExpiringLimitInDays: subscriptionDetails?.creditExpiryDays
        ? subscriptionDetails?.creditExpiryDays
        : 0,
      name: subscriptionDetails?.subscriptionName,
      subscriptionTypeId: tabDetails?.selectedTabValue,
      isInvoiceBasedSubscription: isInvoice,
      subscriptionExpiringLimitInDays:
        subscriptionDetails?.subcriptionExpiringLimitDays
          ? subscriptionDetails?.subcriptionExpiringLimitDays
          : 0,
      isActive: subscriptionDetails?.isActive,
      id: subscriptionDetails?.id,
      organizationId: subscriptionDetails?.selectedOrganization || 0
    };

    await EditSubcription(data, cookies?.bearerToken).then(async (res) => {
      console.log("res", res);
      if (res?.status == 200) {
        setBtnLoading(false);
        toast.success("Subscription updated successfully", {
          position: "top-right",
        });
        setSearchFilterValue("");
        getAllSubscription(0, 1000, "");
      } else {
        setBtnLoading(false);
        setIsLoading(false);
        toast.error(res?.response.data?.message || "There is issue occured in update subscription", {
          position: "top-right",
        });
        if (res?.response.data?.message == 'Already Exists')
          toast.info("The subscription name is already exists in  subscription list , please try with different name!", {
            position: "top-right",
          })
      }
    });
  };
  const handleSearch = (value) => {
    if (value) {
      debugger
      const searchedRecords = data?.filter((record) => {
        for (const key in record) {
          if (
            record[key] &&
            String(record[key]).toLowerCase().includes(value.toLowerCase())
          ) {
            return record;
          }
        }
      });
      setSubscriptionData([...searchedRecords]?.slice(0, 10));
      setTotalCount(searchedRecords?.length)
      return;
    }
    setSubscriptionData([...data]?.slice(0, 10));
    setTotalCount(data?.length)
  };
  const getOrganization = () => {
    GetActiveOrganizationList(cookies?.bearerToken).then(res => {
      if (res?.status == 200) {
        setOrganizationList(res?.data || []);
      }
    })
  }
  useEffect(() => {
    setIsLoading(true);
    getAllSubscription(0, 1000, "");
    getOrganization();
  }, []);
  const onsubmittingConfiguration = () => {
    let isInvalid = false;
    if (isInvoice) {
      isInvalid =
        !subscriptionDetails?.subscriptionName ||
        !subscriptionDetails?.invoiceAmount ||
        !subscriptionDetails?.invoiceExpiryDays ||
        !subscriptionDetails?.creditExpiryDays ||
        !subscriptionDetails?.selectedOrganization
    }
    else {
      isInvalid =
        !subscriptionDetails?.subscriptionName ||
        !subscriptionDetails?.subcriptionExpiringLimitDays ||
        !subscriptionDetails?.selectedOrganization;
    }
    setIsErrMsgView(isInvalid);
    if (!isInvalid) {
      setBtnLoading(true);
      setIsModalOpen(false);
      if (subscriptionDetails?.isEdit) {
        handleUpdate();
      } else {
        handleAdd();
      }
    }
  }
  return (
    <>
      <div className="subscription">
        <div className="header">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              paddingLeft: "20px",
              gap: "15px",
            }}
          >
            <div className="menubtn">
              <i className="pi pi-arrow-circle-left nav-back-btn" onClick={() => navigate('/dashboard')} style={{ marginLeft: '30px', cursor: 'pointer', fontSize: '1.5rem' }} />
            </div>
            <div className="heading">
              <h2>Subscription Configuration</h2>
            </div>
          </div>
        </div>
        <div>
          <div
            className="right"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              paddingRight: "10px",
            }}
          >
            <div className="subscription-tab">
              <TabMenu
                model={subcriptionTypes}
                activeIndex={tabDetails?.currIndex}
                optionLabel="type"
                onTabChange={(e) => {
                  handleTabMenuValue(e.value);
                }}
              />
            </div>
              
            <div className="flex align-items-center">
              <div>
                <h4 className='mb-1 mt-0' style={{color:"#ae9d81"}}>Search</h4>
                <span className="p-input-icon-left p-input-icon-right">
                  <i
                    className="pi pi-search"
                    style={{
                      color: "black",
                      fontWeight: "700",
                      fontSize: "1rem",
                    }}
                  />
                  <InputText
                    placeholder="Search"
                    style={{ padding: 0 }}
                    value={searchFilterValue}
                    onChange={(e) => {
                      setSearchFilterValue(e.target.value);
                      // getAllSubscription(0, 1000, e.target.value);
                      handleSearch(e.target.value);
                    }}
                  />
                  {searchFilterValue !== "" && (
                    <i
                      className="pi pi-times"
                      style={{
                        color: "black",
                        fontWeight: "400",
                        fontSize: "1rem",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setSearchFilterValue("");
                        getAllSubscription(0, 1000, "");
                      }}
                    />
                  )}
                </span>
              </div>
              {(!isInvoice ? true : !subscriptionData.length) && (
                <div className="mx-2 align-self-end">
                <Button
                  label={"Add new"}
                  className="p-button"
                  style={{
                    height: "40px",
                    color: "white",
                    padding: "5px 0px",
                    border: "none",
                    backgroundColor: "#ae9d81",
                    border: "none",
                    marginLeft: "10px",
                    width: "100px",
                    background: "#ae9d81",
                  }}
                  onClick={() => {
                    setSubscriptionDetails({ ...inintialSubscriptionDetails });
                    setIsModalOpen(true);
                  }}
                />
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="datatable">
          {isLoading ? (
            <div
              className="spinner spinner-center"
              style={{ display: "flex", margin: "auto", marginTop: "200px" }}
            ></div>
          ) : (
            <>
              <DataTable
                value={subscriptionData}
                style={{ boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)" }}
                tableStyle={{ width: "90rem", margin: "10px 39px" }}
                stripedRows
                rows={10}
                emptyMessage={
                  <>
                    <p style={{ textAlign: "center" }}>List is empty</p>
                  </>
                }
              >
                <Column
                  field="name"
                  header="Subscription Name"
                  style={{ width: "23%" }}
                ></Column>
                <Column
                  field="code"
                  header="Code"
                  style={{ width: "23%" }}
                ></Column>
                <Column
                  style={{ width: "23%" }}
                  field={
                    isInvoice ? "creditExpiryDays" : "subscriptionExpiryInDays"
                  }
                  header={
                    isInvoice
                      ? "Credit Expiry Days"
                      : "Subscription Expiring Limit Days"
                  }
                ></Column>
                <Column
                  style={{ width: "23%" }}
                  field="status"
                  header="Status"
                  body={statusTemplate}
                ></Column>
                <Column
                  field="nationality"
                  header="Action"
                  body={actionButtonTemplate}
                  style={{ width: "8%" }}
                ></Column>
              </DataTable>
              <Dialog
                header={
                  <h3
                    style={{ textAlign: "center", color: "#ae9d81", margin: 0 }}
                  >
                    {subscriptionDetails?.isEdit ? "Update" : "Add"}{" "}
                    Subscription
                  </h3>
                }
                visible={isModalOpen}
                draggable={false}
                blockScroll={false}
                style={{ width: "45%" }}
                onHide={() => {
                  setIsErrMsgView(false);
                  setIsModalOpen(false);
                }}
              >
                <div className="subcription-modal">
                  <div className="subcription-modal-content">
                    <div className="input">
                      <div className="input-box">
                        <p>
                          Subscription name
                          <span style={{ color: "red" }}>*</span>
                        </p>
                        <InputText
                          placeholder="Enter here"
                          value={subscriptionDetails?.subscriptionName}
                          onChange={(e) => {
                            handleInputValue(
                              "subscriptionName",
                              e?.target?.value
                            );
                          }}
                        />
                        {isErrMsgView &&
                          !subscriptionDetails?.subscriptionName && (
                            <p className="err-msg">
                              Subscription name is required
                            </p>
                          )}
                      </div>
                    </div>
                    {/*  jeeva */}
                    <div className="input">
                      <div className="input-box">
                        <p>
                          Organization
                          <span style={{ color: "red" }}>*</span>
                        </p>
                        <Dropdown style={{ border: "solid 1px #ae9d81" }} options={organizationList} placeholder="Select organization" value={subscriptionDetails?.selectedOrganization} optionLabel="name" optionValue="id" onChange={(e) => {
                          handleInputValue(
                            "selectedOrganization",
                            e?.value
                          );
                        }} />
                        {isErrMsgView &&
                          !subscriptionDetails?.selectedOrganization && (
                            <p className="err-msg">
                              Organization is required
                            </p>
                          )}
                      </div>
                    </div>
                    {/*  jeeva */}
                    {isInvoice && (
                      <>
                        <div className="input">
                          <div className="input-box">
                            <p>
                              Invoice amount
                              <span style={{ color: "red" }}>*</span>
                            </p>
                            <InputText
                              placeholder="Enter here"
                              type="number"
                              value={subscriptionDetails?.invoiceAmount}
                              onChange={(e) => {
                                handleInputValue(
                                  "invoiceAmount",
                                  Number(e?.target?.value)
                                );
                              }}
                            />
                            {isErrMsgView &&
                              !subscriptionDetails?.invoiceAmount && (
                                <p className="err-msg">
                                  Invoice amount is required
                                </p>
                              )}
                          </div>
                        </div>
                        <div className="input">
                          <div className="input-box">
                            <p>
                              Invoice expiry days
                              <span style={{ color: "red" }}>*</span>
                            </p>
                            <InputText
                              placeholder="Enter here"
                              type="number"
                              value={subscriptionDetails?.invoiceExpiryDays}
                              onChange={(e) => {
                                handleInputValue(
                                  "invoiceExpiryDays",
                                  Number(e?.target?.value)
                                );
                              }}
                            />
                            {isErrMsgView &&
                              !subscriptionDetails?.invoiceExpiryDays && (
                                <p className="err-msg">
                                  Invoice expiry days is required
                                </p>
                              )}
                          </div>
                        </div>
                        <div className="input">
                          <div className="input-box">
                            <p>
                              Credit expiry days
                              <span style={{ color: "red" }}>*</span>
                            </p>
                            <InputText
                              placeholder="Enter here"
                              type="number"
                              value={subscriptionDetails?.creditExpiryDays}
                              onChange={(e) => {
                                handleInputValue(
                                  "creditExpiryDays",
                                  Number(e?.target?.value)
                                );
                              }}
                            />
                            {isErrMsgView &&
                              !subscriptionDetails?.creditExpiryDays && (
                                <p className="err-msg">
                                  Credit expiry days is required
                                </p>
                              )}
                          </div>
                        </div>
                      </>
                    )}

                    {!isInvoice && (
                      <>
                        <div className="input">
                          <div className="input-box">
                            <p>
                              Subscription Expiring Limit Days
                              <span style={{ color: "red" }}>*</span>
                            </p>
                            <InputText
                              placeholder="Enter here"
                              type="number"
                              value={
                                subscriptionDetails?.subcriptionExpiringLimitDays
                              }
                              onChange={(e) => {
                                handleInputValue(
                                  "subcriptionExpiringLimitDays",
                                  Number(e?.target?.value)
                                );
                              }}
                            />
                            {isErrMsgView &&
                              !subscriptionDetails?.subcriptionExpiringLimitDays && (
                                <p className="err-msg">
                                  Subscription Expiring Limit Days is required
                                </p>
                              )}
                          </div>
                        </div>
                      </>
                    )}

                    {!isInvoice && (
                      <div className="input">
                        <div className="input-box">
                          <p>
                            Active
                            {/* <span style={{ color: "red" }}>*</span> */}
                          </p>
                          <InputSwitch
                            checked={subscriptionDetails?.isActive}
                            onChange={(e) => {
                              handleInputValue("isActive", e?.target?.value);
                            }}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="modal-footer">
                    <Button
                      label={"Cancel"}
                      className="p-button"
                      style={{
                        padding: "5px 0px",
                        border: "none",
                        width: "100px",
                        background: "#8E8E8E",
                      }}
                      onClick={() => {
                        setIsErrMsgView(false);
                        setIsModalOpen(false);
                      }}
                    />
                    <Button
                      label={subscriptionDetails?.isEdit ? "Update" : "Submit"}
                      loading={btnLoading}
                      className="p-button"
                      style={{
                        padding: "5px 0px",
                        border: "none",
                        width: "100px",
                        background: "#ae9d81",
                      }}
                      onClick={() => {
                        onsubmittingConfiguration()
                      }}
                    />
                  </div>
                </div>
              </Dialog>
            </>
          )}
        </div>
        {isLoading
          ? ""
          : !isInvoice && (
            <div style={{ justifyContent: "center", display: "flex" }}>
              <Paginator
                first={first}
                rows={10}
                totalRecords={totalCount}
                onPageChange={(e) => onPageChange(e, 1)}
                template={{
                  layout:
                    "RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink  LastPageLink CurrentPageReport",
                }}
                className="justify-content-start"
              />
            </div>
          )}
      </div>
    </>
  );
};

export default SubscriptionConfig;
