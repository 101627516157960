import React from "react";

export const delivery = {
  insideMall: "Deliver Inside Mall",
  homeDelivery: "Deliver To Home",
};

export const stepperNames = {
  guestInformation: "Guest information",
  bagInformation: "Bag information",
  checkIn: "Check-in information",
};

export const status = {
  delivered: "Delivered",
  inDesk: "In Desk",
  outOfDelivery: "Out For Delivery",
  collected: "Collected",
  instore: "In Store",
};

// export const deliveryTypeNames = {
//   insideMall: "INSIDE_MALL",
//   homeDelivery: "TO_HOME",
// };
