import Axios from '../Helpers/Axios'

export function GetHireList(data, token) {
    return Axios('Hire/AdminList', 'post', data, token)
}
export function GetHiredItemDetails(hireId, token) {
    return Axios('Hire/Details?hireId=' + hireId, 'get', null, token)
}
export function GetHiredItemReturnDetails(data, token) {
    return Axios('Hire/CheckIn', 'post', data, token)
}