import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  parsePath,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { setStepperDetails } from "../../../Redux/AppSlice";
import { useDispatch } from "react-redux";
import { delivery } from "../../Common/Config";
//components
import ShopAndDropHeader from "../ShopAndDropHeader/ShopAndDropHeader";

//styles
import "./ShopAndDropMainComponent.scss";
import { useCookies } from "react-cookie";
import GuestInforMation from "../GuestInforMation/GuestInforMation";
import BagInformation from "../BagInformation/BagInformation";
import CheckIn from "../CheckIn/CheckIn";
import ShopAndDropSideNav from "../ShopAndDropSidNav/ShopAndDropSidNav";
import { stepperNames } from "../../Common/Config";
import {
  GetDeliveryCharge,
  GetDeliveryTypes,
  GetGuestInformation,
} from "../../../Services/ShopAndDropService";

const ShopAndDropMainComponent = () => {
  const dispatch = useDispatch();
  let [params, setSearchParams] = useSearchParams();
  const stepperDetails = useSelector(
    (state) => state.AppContext.stepperDetails
  );

  const [selectedCountry,setSelectedCountry]=useState("bh");

  const inintialSAndDInitialDeliveryDetails = {
    proof: null,
    proofImageUrl: "",
    phoneNumber: "",
    phoneNumberCode: null,
    firstName: "",
    lastName: "",
    emailId: "",
    receiveEmaarEmails: false,
    isTermsAndConditions: false,
    baggageTagNumber: null,
    store: null,
    reciptAmount: null,
    numberOfBags: 0,
    totalAmount: 0,
    totalBags: 0,
    bagInformations: [],
    deliveryType: {
      "id": 1,
      "label": "Deliver Inside Mall",
      "type": "INSIDE_MALL",
      "code": "DT000001"
    },
    isInsideMall: false,
    deskLocation: null,
    tenantLocation: null,
    pickupLocation: null,
    pickupTime: new Date(),
    deliveryTab: "Desk",
    deliveryTime: "",
    isSameAsGuestInfo: false,
    signatureImageIrl: "",
    amount: null,
    name: "",
    mobile: "",
    street: "",
    flat: "",
    city: "",
    remarks: "",
    checkInID: 0,
    paymentReferenceNumber: ""
  };

  const [shopAndDropDeliveryDetails, setShopAndDropDeliveryDetails] = useState({
    ...inintialSAndDInitialDeliveryDetails,
  });
  const [isLoader, setIsLoader] = useState(false);
  const [cookies, setCookie, removeCookie] = useCookies();
  const [isExisitingUser, setIsExistingUser] = useState(false);
  const [deliveryTypes, setDeliveryTypes] = useState([]);
  const [isCheckedIn, setIsCheckedIn] = useState(false);
  const [isCheckinRequest, setIsCheckinRequest] = useState(false);
  const [isExisitingGuestUser, setIsExisitingGuestUser] = useState(false);
  // Deepak
  const [deliveryCharges, setDeliveryCharges] = useState([]);
  // 

  const handleInputValue = (key, value) => {
    console.log("key", key, value);
    setShopAndDropDeliveryDetails({
      ...shopAndDropDeliveryDetails,
      [key]: value,
    });
  };

  const handleStepChange = (e) => {
    let newSteppArr = JSON.parse(
      JSON.stringify(stepperDetails.stepperData)
    ).map((val, index) => {
      val.completed = index < e.index;
      val.isActive = index === e.index;
      return val;
    });

    let newData = {
      selecetdStepperIndex: e.index,
      selectedStepperValue: e.stepLabel,
      stepperData: [...newSteppArr],
    };
    dispatch(setStepperDetails(newData));
  };

  const handleReset = () => {
    setShopAndDropDeliveryDetails({ ...inintialSAndDInitialDeliveryDetails });
    setIsExistingUser(false);
  };

  const getAllDeliveryTypes = async () => {
    await GetDeliveryTypes(cookies?.bearerToken)?.then((res) => {
      if (res?.status == 200) {
        let temoDeliveryData = [];

        res?.data.forEach((val) => {
          temoDeliveryData.push({
            id: val.id,
            label: val.type,
            type: val.label,
            code: val.code,

          });
        });

        setShopAndDropDeliveryDetails((prev) => ({
          ...prev,
          deliveryType: temoDeliveryData[0],
        }));

        setDeliveryTypes([...temoDeliveryData]);
        setIsCheckedIn(false);
      }
    });
  };

  const getAllDeliveryCharges = async () => {
    await GetDeliveryCharge(cookies?.bearerToken)?.then((res) => {
      if (res?.status == 200) {
        console.log(res);
        setDeliveryCharges(res?.data)
      }
    });
  };

  const GetGuestInfoFunction = async () => {
    await GetGuestInformation(
      cookies?.bearerToken,
      parseFloat(params.get("id"))
    )
      .then((res) => {
        console.log(res);
        if (res?.status === 200) {
          setShopAndDropDeliveryDetails((prev) => ({
            ...prev,
            phoneNumber: res?.data?.phoneNumber,
            firstName: res?.data?.firstName,
            lastName: res?.data?.lastName,
            emailId: res?.data?.emailId||"",
            phoneNumberCode: res?.data?.countryCode,
            checkInID: parseFloat(params.get("id")),
          }));
          setIsExisitingGuestUser(true);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsLoader(false);
      });
  };

  useEffect(() => {
    handleStepChange({
      stepLabel: stepperNames.guestInformation,
      index: 0,
    });
    getAllDeliveryTypes();
    getAllDeliveryCharges();
    if (params.get("id")) {
      setIsLoader(true);
      setIsCheckinRequest(true);
      GetGuestInfoFunction();
    }
  }, []);
  return (
    <>
      {isLoader ? (
        <div
          className="spinner spinner-center"
          style={{ marginLeft: "750px" }}
        ></div>
      ) : (
        <div className="shop-and-drop">
          <div className="shop-and-drop-nav">
            <ShopAndDropSideNav handleStepChange={handleStepChange} />
          </div>
          <div className="shop-and-drop-content">
            <ShopAndDropHeader isCheckIn={true} />
            <div className="shop-and-drop-data">
              {stepperDetails.selectedStepperValue ===
                stepperNames?.guestInformation ? (
                <GuestInforMation
                  selectedCountry={selectedCountry}
                  setSelectedCountry={setSelectedCountry}
                  isBackActive={parseFloat(params.get("id"))>0?true:false}
                  handleStepChange={handleStepChange}
                  setIsCheckedIn={setIsCheckedIn}
                  isCheckedIn={isCheckedIn}
                  shopAndDropDeliveryDetails={shopAndDropDeliveryDetails}
                  isExisitingUser={isExisitingUser}
                  handleInputValue={handleInputValue}
                  setIsExistingUser={setIsExistingUser}
                  isExisitingGuestUser={isExisitingGuestUser}
                  deliveryTypes={deliveryTypes}
                  setShopAndDropDeliveryDetails={setShopAndDropDeliveryDetails}
                />
              ) : stepperDetails.selectedStepperValue ===
                stepperNames?.bagInformation ? (
                <BagInformation
                  deliveryTypes={deliveryTypes}
                  deliveryCharges={deliveryCharges}
                  handleStepChange={handleStepChange}
                  shopAndDropDeliveryDetails={shopAndDropDeliveryDetails}
                  handleInputValue={handleInputValue}
                  setShopAndDropDeliveryDetails={setShopAndDropDeliveryDetails}
                />
              ) : stepperDetails.selectedStepperValue ===
                stepperNames?.checkIn ? (
                <CheckIn
                isBackActive={parseFloat(params.get("id"))>0?true:false}
                  handleStepChange={handleStepChange}
                  deliveryCharges={deliveryCharges}
                  shopAndDropDeliveryDetails={shopAndDropDeliveryDetails}
                  handleInputValue={handleInputValue}
                  handleReset={handleReset}
                  setIsCheckedIn={setIsCheckedIn}
                  isCheckinRequest={isCheckinRequest}
                />
              ) : null}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ShopAndDropMainComponent;
