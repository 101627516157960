import Axios from "../Helpers/Axios";

export function GetQuestions(take, skip, token) {
    return Axios(`TenantQuestion/GetQuestions?skip=${skip}&take=${take}`, "get", null, token)
}
export function EditQuestion(data, token) {
    return Axios(`TenantQuestion/EditQuestion`, "patch", data, token)
}
export function AddQuestion(data, token) {
    return Axios(`TenantQuestion/AddQuestion`, "post", data, token)
}