import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import "./style.scss";
// -------------Imports---------------
import { Button } from "primereact/button";
import { FilterMatchMode } from "primereact/api";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { useNavigate, Link } from "react-router-dom";
import { Paginator } from "primereact/paginator";
// -----------------------------------

// ----------Assets Import------------
import editIcon from "../../../src/assets/images/editIcon.jpg";

// -----------------------------------

//------Imports from other Components-----
import {
  GetChatList,
  GetFeedbackList,
  NewChat,
} from "../../Services/FeedbackService";
//----------------------------------------

//-----------Context ---------------
import { useDispatch, useSelector } from "react-redux";
import { setFeedbackList, setNavVisibilty } from "../../Redux/AppSlice";
import { exportDataInExcel } from "../../Services/ExportServices";

//----------------------------------------

function GuestServiceStaff() {
  const navigate = useNavigate();
  const Context = useSelector((state) => state.AppContext);
  const dispatch = useDispatch();
  const [cookies, setCookie, removeCookie] = useCookies();
  const [buttonStatusMap, setButtonStatusMap] = useState([]);
  const [feedbackList, setFeedbackLists] = useState([]);
  const [chat, setChat] = useState("");
  const [chatError, setChatError] = useState("");
  const [selectedFeedback, setSelectedFeedback] = useState({
    id: "",
    firstName: "",
    lastName: "",
    mobileNumber: "",
    email: "",
    category: "",
    subCategory: "",
    location: "",
    nationality: "",
  });
  const [isAddOrganization, setIsAddOrganization] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [chatList, setChatList] = useState([]);
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    // name: { value: null, matchMode: FilterMatchMode.CONTAINS }
  });

  // Pagination
  const [first, setFirst] = useState(0);
  const [totalCount, setTotalRecords] = useState(0);
  const getFeedbackList = (skip, take, searchValue) => {
    setIsLoading(true);
    let data = {
      take: take,
      skip: skip,
    };
    if (searchValue) {
      data["searchTerm"] = searchValue;
    }
    GetFeedbackList(data, cookies.bearerToken)
      .then((res) => {
        // //console.log(res?.data);
        if (res?.status == 200) {
          setFeedbackLists(res?.data?.feedbacks);
          setTotalRecords(res?.data?.totalRecords);
          dispatch(setFeedbackList({ feedbackList: res?.data?.feedbacks }));
          return;
        }
      })
      .catch((error) => {
        //console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  const nameBodyTemplate = (row) => {
    return (
      <p style={{ fontWeight: 400 }}>
        {row?.firstName} {row?.lastName}
      </p>
    );
  };
  const actionButtonTemplate = (row) => {
    return (
      <Link to={`/guestservicestaffview/${row?.id}`}>
        <img src={editIcon} style={{ cursor: "pointer" }} />
      </Link>
    );
  };
  const onGlobalFilterChange = (e) => {
    if (!e) {
      getFeedbackList(0, 10);
      return;
    }
    setGlobalFilterValue(e.target.value);
    getFeedbackList(0, 10, e?.target.value);
  };

  const onPageChange = (e, index) => {
    setFirst(e.first);
    getFeedbackList(e.first, 10);
    //console.log(e);
  };

  const statusBodyTemplate = (row) => {
    //console.log(row);
    if (row.status == "Completed") {
      return <p style={{ color: "#3ee63e" }}>Completed</p>;
    } else if (row.status == "Rejected") {
      return <p style={{ color: "red" }}>Rejected</p>;
    } else if (row.status == "Resolved") {
      return <p style={{ color: "#f553e7" }}>Resolved</p>;
    } else if (row.status == "Out of control") {
      return <p style={{ color: "#6ecbfa" }}>Out of control</p>;
    } else if (row.status == "Yet to Ackowledge") {
      return <p style={{ color: "#964B00" }}>Yet to Acknowledge</p>;
    } else if (row.status == "Assigned") {
      return <p style={{ color: "#bd16f5" }}>Assigned</p>;
    } else if (row?.status == "Reassign") {
      return <p style={{ color: "#1512cc" }}>Reassign</p>;
    } else if (row?.status == "Closed") {
      return <p style={{ color: "#3ee63e" }}>Closed</p>;
    }
  };
  const exportFeedbacksForGSStaff = () => {
    if (globalFilterValue) {
      exportDataInExcel(
        `FeedbacksForGSStaff?searchTerm=${globalFilterValue}`,
        cookies.bearerToken
      );
      return;
    }
    exportDataInExcel("FeedbacksForGSStaff", cookies.bearerToken);
  };
  useEffect(() => {
    // //console.log("Cookies", cookies);
    //console.log('Context', Context)
    // setOrganizationList(Context.organizationList);
    getFeedbackList(0, 10);
  }, []);

  return (
    <>
      <div className="Feedback">
        <div className="header h-auto">
          <div
            tyle={{
              display: "flex",
              flexDirection: "row",
              flexGrow: "3",
              justifyContent: "flex-start",
            }}
            className="flex align-items-center org"
          >
            <div className="menubtn" style={{ padding: "10px 10px 5px 20px" }}>
              <i
                className="pi pi-align-justify"
                style={{
                  fontSize: "1rem",
                  color: "#ae9d81",
                  cursor: "pointer",
                }}
                onClick={() =>
                  dispatch(setNavVisibilty({ sideNavVisibility: true }))
                }
              ></i>
            </div>
            <div className="heading" style={{ marginLeft: "30px" }}>
              <span></span>
              <p>MG Care Distribution</p>
            </div>
          </div>
          <div className="flex align-items-center">
            <div className="mr-4">
              <h4 className='mb-1 mt-0 relative' style={{color:"#ae9d81"}}>Search</h4>
                <div className="search">
                  <span className="p-input-icon-left">
                    <i
                      className="pi pi-search"
                      style={{
                        color: "black",
                        fontWeight: "700",
                        fontSize: "1rem",
                      }}
                    />
                    <InputText
                      value={globalFilterValue}
                      onChange={onGlobalFilterChange}
                      placeholder="Search"
                    />
                    <i
                      className="pi pi-times"
                      style={{ color: "black", fontWeight: "400" }}
                      onClick={() => {
                        setGlobalFilterValue("");
                        onGlobalFilterChange(null);
                      }}
                    />
                  </span>
              </div>
            </div>          
              <div className="mx-2 align-self-end">
                <Button
                  label="Add Feedback"
                  onClick={() => {
                    navigate("/feedback-form");
                  }}
                  style={{
                    backgroundColor: "#ae9d81",
                    color: "#fff",
                    border: "1px #ae9d81 solid",
                    cursor: "pointer",
                    right: 10,
                    width: "150px",
                  }}
                />
              </div>
              <div className="mr-2 align-self-end">
                <Button
                  label="Export"
                  onClick={totalCount > 0 ? exportFeedbacksForGSStaff : null}
                  icon={"pi pi-file-export"}
                  style={{
                    backgroundColor: "transparent",
                    color: "#ae9d81",
                    border: "1px #ae9d81 solid",
                    borderRadius: "20px",
                    height:"46px",
                    cursor: totalCount > 0 ? "pointer" : "not-allowed",
                  }}
                />
              </div>
          </div>
          
        </div>
        <div className="datatable" style={{ margin: "0px 48px" }}>
          {isLoading ? (
            <div className="spinner spinner-center"></div>
          ) : (
            <DataTable
              value={feedbackList}
              tableStyle={{ width: "90rem" }}
              style={{ boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)" }}
              stripedRows
              rows={10}
              emptyMessage={
                <>
                  <p style={{ textAlign: "center" }}>Feedback list is empty</p>
                </>
              }
              selectionMode="single"
              selection={selectedFeedback}
              filters={filters}
              globalFilterFields={[
                "code,firstName",
                "mobileNumber",
                "email",
                "category",
                "subCategory",
                "location",
                "status",
              ]}
            >
              <Column field="code" header="Feedback Id"></Column>
              <Column
                field="firstName"
                body={nameBodyTemplate}
                header="Name"
              ></Column>
              <Column field="mobileNumber" header="Mobile Number"></Column>
              <Column field="email" header="Email"></Column>
              <Column field="category" header="Category"></Column>
              <Column field="subCategory" header="Sub Category"></Column>
              <Column field="createdBy" header="Created By"></Column>
              <Column field="createdDate" header="Created Date"></Column>
              {/* <Column field="location" header="Location"></Column> */}
              <Column
                field="status"
                header="Status"
                body={statusBodyTemplate}
              />
              <Column
                field="action"
                header="Action"
                body={actionButtonTemplate}
              ></Column>
            </DataTable>
          )}
        </div>
        {isLoading || feedbackList?.length == 0 ? (
          ""
        ) : (
          <div style={{ justifyContent: "center", display: "flex" }}>
            <Paginator
              first={first}
              rows={10}
              totalRecords={totalCount}
              onPageChange={(e) => onPageChange(e, 1)}
              template={{
                layout:
                  "RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink  LastPageLink CurrentPageReport",
              }}
              className="justify-content-start"
            />
          </div>
        )}
      </div>
    </>
  );
}


export default GuestServiceStaff;

