import style from "./style.module.scss";
import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
// -------------Imports---------------
// import { Button } from 'primereact/button'
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { useNavigate } from "react-router-dom";
import classNames from "classnames";

// -----------------------------------
import { toast } from 'react-toastify';


// -----------------------------------

//------Imports from other Components-----
import { GetChatList, GetFeedbackById, GetFeedbackList } from "../../Services/FeedbackService";
import { NewChat } from "../../Services/FeedbackService";
import {
    GetFeedbackDepartmentByFeedbackId,
    GetStatusForFeedback,
} from "../../Services/GuestServiceStaffViewService";
import {
    setFeedbackList,
    addDepartmentListContext,
} from "../../Redux/AppSlice";
import { GetDepartmentList } from "../../Services/DepartmentService";

//----------------------------------------

//-----------Context ---------------
import { useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { BulkUploadBase64Files, GetAllFiles } from "../../Services/Files";
import { CiImageOn } from "react-icons/ci";
import { GetStatusList } from "../../Services/MGTenentRelationshipViewService";
import {
    UpdateFeedback,
    getDepartmentslist,
} from "../../Services/GuestSatffInspection";

import { DownloadFile } from "../../Services/Files";
import { InputTextarea } from "primereact/inputtextarea";
import { Button } from "primereact/button";
import CustomWebcam from "../Camera/Camera";

function GuestServiceStaffView() {
    const { id, status } = useParams();
    //console.log("GSS", id, status);
    const dispatch = useDispatch();
    const Context = useSelector((state) => state.AppContext);
    const [isAddOrganization, setIsAddOrganization] = useState(false);
    const [selectedFeedback, setSelectedFeedback] = useState([]);
    const [selectedStatus, setSelectedStatus] = useState([]);
    const [chatList, setChatList] = useState([]);
    const [chat, setChat] = useState("");
    const [statusValue, setStatusValue] = useState({
        id: 0,
        name: "",
    });
    const [statusList, setStatusList] = useState([]);
    const [cookies, setCookie, removeCookie] = useCookies();
    const [chatError, setChatError] = useState("");
    const navigate = useNavigate();
    const [feedbackData, setFeedbackData] = useState([]);
    const [departmentValue, setDepartmentValue] = useState({
        departmentId: 0,
        name: "",
    });
    const [departmentList, setDepartmentList] = useState();
    const [fileList, setFileList] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [departmentLists, setDepartmentLists] = useState([
        { id: 0, name: "", isDeleted: false },
    ]);
    const [isInvalid, setIsInvalid] = useState(false);

    
    const [dialogVisible, setDialogVisible] = useState(false);
    const [isVisible, setIsVisible] = useState(false);
    const [isCommentsValid, setIsCommentsValid] = useState(false);
    const [value, setValue] = useState({ comments: "", attachments: [] });
    const [imgSrc, setImgSrc] = useState("");
    // const department = Context.departmentList?.find(d => d.feedbackId?.toString() === id); //
    // //console.log(Context);

    const [feedbackLoading, setFeedbackLoading] = useState(false);

    const formatDate = (timestamp) => {
        const timestampDate = new Date(timestamp);

        return timestampDate.toLocaleString("en-US", {
            month: "short",
            day: "numeric",
            hour: "numeric",
            minute: "numeric",
            hour12: true,
        });
    };
    const getChatList = () => {
        setIsLoading(true);
        GetChatList(cookies.bearerToken, id)
            .then((res) => {
                if (res?.status == 200) {
                    setChatList(res?.data);
                }
            })
            .catch((error) => {
                //console.log(error);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };
  


    const getDepartmentList = () => {
        let take = 50;
        let skip = 0;
        GetDepartmentList(take, skip, cookies.bearerToken)
            .then((res) => {
                // //console.log("Get Department List", res);
                let departments = res?.data?.data.map((dep) => {
                    return { departmentId: dep.id, departmentName: dep.name, isDeleted: dep.isDeleted }
                });
                console.log(departments);
                setDepartmentList(departments.filter((_) => _?.isDeleted == false))


            })
            .catch((err) => {
                //console.log(err);
            });
    };

    const getStatusList = () => {
        GetStatusList(cookies.bearerToken)
            .then((res) => {
                setStatusList(res.data);
            })
            .catch((err) => {
                //console.log(err);
            });
    };

    const getStatusForFeedback = async () => {

        await GetStatusForFeedback(id, cookies?.bearerToken)
            .then((res) => {
                if (res?.status == 200) {
                    setStatusList(res?.data);
                    if (res?.data?.length == 1) {
                        setStatusValue(res?.data);
                    }
                    // //console.log(res?.data, "statsussss");
                }
            })
            .catch((error) => console.log(error));
    };

    const getFeedbackDepartmentByFeedbackId = (inspectionId) => {
        GetFeedbackDepartmentByFeedbackId(inspectionId, cookies.bearerToken)
            .then((res) => {
                //console.log("result", res);
                if (res?.status == 200) {
                    //console.log(res?.data, "data from guest satff");
                    setDepartmentList([res?.data]);
                    if ([res?.data?.length == 1]) {
                        setDepartmentValue(res?.data);
                    }
                }
                if (
                    res?.response?.status === 404 &&
                    res.response?.data === "No department found"
                ) {
                    getDepartmentList();
                }
            })
            .catch((error) => {
                //console.log(error);
            })
            .finally(() => {
                // //console.log(organizationList);
            });
    };
    const toUpdateFeedback = async () => {
        setFeedbackLoading(true);
        console.log("Deparment Value ", departmentValue);
        console.log("Status Value ", statusValue);
        console.log("Feedback Id ", id);
        if (
            departmentValue?.departmentId == 0 ||
            id == "" ||
            statusValue?.id == 0
        ) {
            setIsInvalid(true);
            setFeedbackLoading(false);
            return;
        }
        setIsInvalid(false);
        //console.log(        "GSS Changing Feedback status",            departmentValue?.id,            statusValue        );
        await UpdateFeedback(
            {
                departmentId: departmentValue?.departmentId,
                feedbackId: id,
                statusId: statusValue?.id,
            },
            cookies?.bearerToken
        )
            .then(async (res) => {
                // //console.log(res);
                if (res?.status == 200) {
                    //console.log(res?.data);
                    let attachment = [...value?.attachments]
                    if (imgSrc) {
                        attachment.push({
                            name: imgSrc.split(";base64,")[0].split("/")[1],
                            base64: imgSrc?.split(',')[1],
                            type: "image"
                        })
                    }
                    await AddMultipleAttachment(id, attachment);
                    await sendChat(value?.comments);
                    toast.success("Feedback status updated successfully", { position: "top-right" });
                    navigate("/guestservicestaff");
                }
            })
            .catch((error) => console.log(error))
            .finally(() => {
                setFeedbackLoading(false);
            })
    };
    const sendChat = async (value) => {
        if (!value.trim()) {
            // setChatError("No messages found")
            return;
        }
        const data = {
            feedbackId: id,
            message: value,
        };
        NewChat(data, cookies.bearerToken)
            .then((res) => {
                setChatList((prev) => {
                    return [
                        ...prev,
                        {
                            id: -1,
                            messageFrom: localStorage.getItem("userName"),
                            message: chat.trim(),
                            messageDate: new Date()?.toString(),
                        },
                    ];
                });
                setChat("");
                // dispatch(
                //     addChatList({
                //         chatList: res?.data
                //     }))
            })
            .catch((error) => {
                //console.log(error);
            })
            .finally(() => {
                // //console.log(chatList);
            });
    };
    const AddMultipleAttachment = async (id, files = []) => {
        let fileArray = [];
        files?.forEach((file, index) => {
            
            let data = {
                "id": id,
                "module": "Feedback",
                "inspectionId": 0,
                "files": [
                    {
                        "fileName": file?.name,
                        "base64": file?.base64
                    }
                ]
            }
            //console.log("Data", data);
            BulkUploadBase64Files(data,
                cookies.bearerToken
            )
            // }

        })
       
        setValue({ comments: "", attachments: [] })
        
    };
    const closePopup = () => {
        // setIsAddOrganization(false);
        navigate("/guestservicestaff");
    };

    const header = () => {
        return (
            <div
                className="heading"
                style={{ fontWeight: "700", fontSize: "25px", color: "#ae9d81" }}
            ></div>
        );
    };
    const getAllFiles = async () => {
        await GetAllFiles(
            { id: id, inspectionId: "0", isForInspection: false, module: "Feedback" },
            cookies?.bearerToken
        ).then((res) => {
            if (res?.status === 200) {
                setFileList(res?.data);
            }
        });
    };
    useEffect(() => {
        getChatList();
        getAllFiles();
        getFeedbackDepartmentByFeedbackId(id);
        getStatusForFeedback();
    }, []);

    // //console.log(Context, "feedbackData");
    const checkStatusForGSS = () => {
        if (
            feedbackData[0]?.status === "Yet to Ackowledge" ||
            feedbackData[0]?.status === "Out of control" ||
            feedbackData[0]?.status === "Rejected"
        ) {
            return false;
        } else {
            return true;
        }
    };

    const downloadFile = async (filename, filePath) => {
        DownloadFile(filename, filePath, cookies?.bearerToken);
    };

    const getFeedbackById = async (feedbackId) => {
        await GetFeedbackById(cookies.bearerToken, feedbackId)
            .then((res) => {
                if (res?.status === 200) {
                    setFeedbackData(res?.data);
                    // setFeedbackData(Context.feedbackList?.filter((_) => _?.id == id));
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }
    const onRemoveImage = (label, fileName) => {
        if (label == "file") {
            setValue((prev) => ({ ...prev, attachments: value?.attachments?.filter(_ => _?.name !== fileName) }))
        } else {
            setImgSrc("");
        }

    }
    useEffect(() => {
        getFeedbackById(id);
    }, []);


    return (
        <>
        {/* <Dialog
                header="Department User Review "
                visible={dialogVisible}
                style={{ width: "50vw" }}
                onHide={() => {
                    setDialogVisible(false);
                }}
            >
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                    <div style={{ width: "100%" }}>
                        <label htmlFor="comments" className="font-bold block mb-2">
                            Comments
                            <span style={{ color: 'red' }}>*</span>
                        </label>
                        <InputTextarea
                            style={{ width: "100%" }}
                            id="comments"
                            autoResize
                            value={value.comments}
                            onChange={(e) => setValue({ ...value, comments: e.target.value })}
                            rows={3}
                        //   cols={30}
                        />
                        {isCommentsValid && value?.comments == "" &&
                            <p style={{ color: 'red' }}>Comments is required</p>}
                    </div>
                    <div className="departmentComments" style={{ width: "100%" }}>
                        <label htmlFor="comments" className="font-bold block mb-2">
                            Attachments
                        </label>
                        <div style={{ display: "flex" }}>
                            <i
                                className="pi pi-camera"
                                style={{ color: "#ae9d81" }}
                                onClick={() => {
                                    setIsVisible(true);
                                }}
                            ></i>
                            <input type="file" onChange={handleFileChange} className="px-4" />
                        </div>
                        {isVisible && (
                            <CustomWebcam
                                setIsVisible={setIsVisible}
                                setImage={setImgSrc}
                                setAttachments={setValue}
                                files={value}
                                isMultiplePhotos={true}

                            />
                        )}
                    </div>
                    <div style={{ width: "100%" }}>
                        {value.attachments.length > 0
                            ? value.attachments.map((file, index) => {
                                return (
                                    <div style={{ display: "flex", alignItems: "center" }}>
                                        <i className="pi pi-file px-1" style={{ fontSize: 14 }}></i>
                                        <span style={{ width: "50%" }} key={index}>
                                            {file.name}
                                        </span>
                                        <i className="pi pi-times px-1" style={{ fontSize: 14 }} onClick={() => onRemoveImage("file", file?.name)}></i>
                                    </div>
                                );
                            })
                            : null}
                        {imgSrc ? (
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <i className="pi pi-file px-1" style={{ fontSize: 14 }}></i>
                                <span style={{ width: "50%" }}>
                                    Image{value.attachments.length + 1}.
                                    {imgSrc.split(";base64,")[0].split("/")[1]}
                                </span>
                                <i className="pi pi-times px-1" style={{ fontSize: 14 }} onClick={() => onRemoveImage("camera", "")}></i>
                            </div>
                        ) : null}
                    </div>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            marginTop: "25px",
                        }}
                    >
                        <Button
                            style={{
                                cursor: "pointer", backgroundColor: "#8C8C8C",
                                color: "white",
                                margin: "0px 6px",
                                width: "100px",
                                // height: "30px",
                                border: "none",
                                borderRadius: "5px",
                                fontSize: "15px"
                            }}
                            disabled={isLoading}
                            onClick={() => {
                                setValue({ comments: "", attachments: [] });
                                setDialogVisible(false);
                                setIsCommentsValid(false);
                            }}
                        >
                            Cancel
                        </Button>

                        <Button
                            loading={isLoading}
                            style={{
                                backgroundColor: "#AE9D81",
                                color: "white",
                                margin: "0px 6px",
                                width: "100px",
                                // height: "30px",
                                border: "none",
                                borderRadius: "5px",
                                fontSize: "15px", cursor: "pointer"
                            }}
                            onClick={() => { }}
                        >  Submit
                        </Button>
                    </div>
                </div>
            </Dialog > */}
            {feedbackData?.length == 0 ? <div className="spinner spinner-center" style={{ display: "flex", margin: '150px auto' }}></div> :
                <div>
                    <div visible={true} style={{}}>
                        <div className={style.head}>
                            <FontAwesomeIcon
                                icon={faArrowLeft}
                                className={style.arrowIcon}
                                onClick={closePopup} style={{ cursor: "pointer" }}
                            />
                            Edit Feedback Details
                        </div>
                        <div className={classNames(style.flex, style.forMargin)}>
                            <div className="flex-1 p-4 flexbox-1">
                                <div className="defaultWidth">
                                    <p className={style.defaultStyle}>Name{Context.name}</p>
                                    <InputText
                                        className={style.border}
                                        value={`${feedbackData[0]?.firstName} ${feedbackData[0]?.lastName}`}
                                        // value={`${selectedFeedback?.firstName} ${selectedFeedback?.lastName}`}
                                        readOnly
                                    />
                                </div>
                                <div className="defaultWidth">
                                    <p className={style.defaultStyle}>Mobile number</p>
                                    <InputText
                                        className={style.border}
                                        value={feedbackData[0]?.mobileNumber}
                                        readOnly
                                    />
                                </div>
                                <div className="defaultWidth">
                                    <p className={style.defaultStyle}>Email</p>
                                    <InputText
                                        className={style.border}
                                        value={feedbackData[0]?.email}
                                        readOnly
                                    />
                                </div>
                                <div className="defaultWidth">
                                    <p className={style.defaultStyle}>Nationality</p>
                                    <InputText
                                        className={style.border}
                                        value={feedbackData[0]?.nationality}
                                        readOnly
                                    />
                                </div>
                                <div className="defaultWidth">
                                    <p className={style.defaultStyle}>Attachments</p>
                                    <div
                                        style={{
                                            height: "100px",
                                            overflow: "auto",
                                            width: "350px",
                                            border: "solid 1px #AE9D81",
                                            borderRadius: "4px",
                                            padding: "10px",
                                        }}
                                    >
                                        {fileList?.map((_) => {
                                            return (
                                                <div style={{ display: "flex", alignItems: "center" }}>
                                                    <CiImageOn style={{ fontSize: 20 }} />
                                                    <p
                                                        style={{
                                                            fontSize: "12px",
                                                            padding: "0 10px",
                                                            cursor: "pointer",
                                                        }}
                                                        onClick={() => downloadFile(_?.fileName, _?.blobUrl)}
                                                    >
                                                        {_.fileName}
                                                    </p>
                                                </div>
                                            );
                                        })}
                                    </div>
                                    {/* <InputText className={classNames(style.border, style.attachment)} /> */}
                                </div>
                                {/* <p className={style.p}>Guest Service Details</p> */}
                                <div className="defaultWidth">
                                    <p className={style.defaultStyle}>Department</p>
                                    {checkStatusForGSS() ? (
                                        <InputText
                                            className={style.border}
                                            value={departmentValue?.departmentName}
                                            readOnly
                                        />
                                    ) : (
                                        <Dropdown
                                            className={classNames(style.border)}
                                            options={departmentList}
                                            optionLabel={"departmentName"}
                                            placeholder="Choose Departments"
                                            onChange={(e) => {
                                                setDepartmentValue(e.target.value);
                                            }}
                                            value={departmentValue}
                                            disabled={checkStatusForGSS()}
                                        />
                                    )}
                                    {
                                        isInvalid && !departmentValue?.departmentId && (
                                            // <div>
                                            <p style={{ color: "red" }}>Department is required.</p>
                                        )
                                        // </div>
                                    }

                                    {/* <p>{feedbackData[0].status == "Completed" ? true : false}</p> */}
                                    {/* <p>{checkStatusForGSS() ? "true" : "false"}</p> */}
                                </div>
                            </div>
                            <div className="flex-1  p-4 flexbox-2">
                                <div className="defaultWidth">
                                    <p className={style.defaultStyle}>Category</p>
                                    <InputText
                                        className={style.border}
                                        value={feedbackData[0]?.category}
                                        readOnly
                                    />
                                </div>
                                <div className="defaultWidth">
                                    <p className={style.defaultStyle}>Sub Category</p>
                                    <InputText
                                        className={style.border}
                                        value={feedbackData[0]?.subCategory}
                                        readOnly
                                    />
                                </div>
                                <div className="defaultWidth">
                                    <p className={style.defaultStyle}>Location</p>
                                    <InputText
                                        className={style.border}
                                        value={feedbackData[0]?.location}
                                        readOnly
                                    />
                                </div>
                                <div className={style.feedbackBox}>
                                    <p className={style.defaultStyle}>Feedback Comments</p>
                                    <p
                                        className={classNames(style.border, style.feedback)}
                                        style={{
                                            fontWeight: "100",
                                            border: "1px solid #ae9d81",
                                            width: "350px",
                                            padding: "10px",
                                            borderRadius: "5px",
                                            wordWrap: 'break-word',
                                            overflow: 'scroll',
                                            overflowX: 'hidden',
                                            marginTop: '0px'
                                        }}
                                    >
                                        {feedbackData[0]?.comment}

                                    </p>
                                </div>
                                <div className={classNames(style.defaultWidth, style.status)} style={{ marginTop: "21px" }}>
                                    <p className={style.defaultStyle} >Status</p>
                                    {checkStatusForGSS() ? (
                                        <InputText
                                            style={{
                                                wordWrap: 'break-word',
                                                overflow: 'scroll',
                                                overflowX: 'hidden',
                                            }}
                                            className={style.border}
                                            value={feedbackData[0]?.status}
                                            readOnly
                                        />
                                    ) : (
                                        <Dropdown
                                            className={classNames(style.border)}
                                            options={statusList}
                                            optionLabel="name"
                                            placeholder="Choose Feedback Status"
                                            onChange={(e) => {
                                                setStatusValue(e?.target?.value);
                                                //console.log(e);
                                            }}
                                            value={statusValue}
                                            disabled={checkStatusForGSS()}
                                        />
                                    )}
                                    {
                                        isInvalid && !statusValue?.id && (
                                            // <div>
                                            <p style={{ color: "red" }}>Status is required.</p>
                                        )
                                        // </div>
                                    }
                                    {/* <Dropdown className={classNames(style.border)} options={statusList} optionLabel='name' placeholder='Choose Feedback Status'
                                onChange={(e) => setStatusValue(e?.value)} value={statusValue} disabled={checkStatusForGSS()} /> */}
                                </div>
                            </div>
                            <div className="vl"></div>
                            <div
                                style={{
                                    width: "30%",
                                    margin: "5px",
                                    overflowY: "auto",
                                }}
                            >
                                <div
                                    style={{
                                        display: "flex",
                                        padding: "6px 10px",
                                        backgroundColor: "#ae9d81",
                                        margin: "10px 10px",
                                        justifyContent: "center",
                                    }}
                                >
                                    <p style={{ color: "#fff", margin: 0 }}>Chat Box</p>
                                </div>
                                <div style={{ margin: "5px " }}>
                                    <InputTextarea
                                        // type="text"
                                        value={chat}
                                        className="p-inputtext-lg"
                                        rows={2}
                                        style={{
                                            width: "88%", height: "40px", fontSize: "15px", marginLeft: '5px', overflowWrap: 'break-word', overflow: 'scroll', overflowX: 'hidden', overflowY: 'hidden'
                                        }}
                                        placeholder="Comments"
                                        onChange={(e) => {
                                            setChat(e.target.value);
                                        }}
                                    />
                                    <i
                                        className="pi pi-send"
                                        style={{
                                            fontSize: "1.5rem",
                                            color: "#AE9D81",
                                            margin: "10px",
                                            transform: "rotateZ(45deg)",
                                            cursor: "pointer",
                                        }}
                                        onClick={() => {
                                            sendChat();
                                        }}
                                    ></i>
                                </div>
                                {isLoading ? (
                                    <div
                                        className="spinner spinner-center"
                                        style={{ marginLeft: "200px", width: "30px", height: "30px" }}
                                    ></div>
                                ) : (
                                    <div style={{ overflow: "scroll", height: "600px", overflowX: "hidden" }}>
                                        {chatList?.length !== 0 ? (
                                            <div div className="flex-1 p-4 flexbox-3">
                                                <div style={{ overflow: "auto" }}>
                                                    {chatList.map((chat, index) => (
                                                        <div
                                                            key={chat.id}
                                                            className={
                                                                localStorage.getItem("userName") === chat.messageFrom
                                                                    ? "user2"
                                                                    : "user"
                                                            }
                                                        >
                                                            <div
                                                                style={{
                                                                    fontWeight: 200,
                                                                    fontSize: "15px",
                                                                    margin: "0px 0px 5px 0px",
                                                                }}
                                                            >
                                                                {chat?.messageFrom}
                                                            </div>
                                                            <p
                                                                style={{
                                                                    fontWeight: 400,
                                                                    margin: "0px",
                                                                    wordWrap: "break-word",
                                                                    width: "250px",
                                                                }}
                                                            >
                                                                {chat?.message}
                                                            </p>
                                                            {/* <span style={{ fontWeight: 200, fontSize: '15px', margin: '0px 0px -5px 0px' }}>{chat?.messageFrom}</span> */}
                                                            <span
                                                                style={{
                                                                    fontWeight: 200,
                                                                    fontSize: "15px",
                                                                    margin: "0px 0px -5px 0px",
                                                                }}
                                                            ></span>
                                                            <span
                                                                style={{
                                                                    fontWeight: 200,
                                                                    fontSize: "15px",
                                                                    margin: "0px 0px -5px 0px",
                                                                    float: "right",
                                                                }}
                                                            >
                                                                {formatDate(chat.messageDate)}
                                                            </span>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        ) : (
                                            <div>
                                                <div style={{ padding: "100px 0" }}>
                                                    <p style={{ textAlign: "center" }}>No Chats Found</p>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                )}
                            </div>
                            {/* {chatError} */}
                        </div>
                        <div
                            className="flex justify-content-center"
                            style={{ margin: "0px 0px 30px" }}
                        >
                            <Button
                                className={style.cancelBtn}
                                style={{ cursor: "pointer" }}
                                onClick={closePopup}
                            >
                                Cancel
                            </Button    >
                            {checkStatusForGSS() ? (
                                ""
                            ) : (
                                <Button
                                    loading={feedbackLoading}
                                    className={style.submitBtn}
                                    style={{ cursor: "pointer", width: "auto" }}
                                    onClick={toUpdateFeedback}
                                >
                                    Submit
                                </Button>
                            )}
                        </div>
                    </div>
                </div>
            }
        </>
    );
}
export default GuestServiceStaffView;
