import React, { useEffect, useState, useRef } from "react";
import "./TrackDelieveryView.scss";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { faSlash } from "@fortawesome/free-solid-svg-icons";
import { useNavigate, useParams } from "react-router-dom";
import { useReactToPrint } from 'react-to-print';
import { InputOtp } from "primereact/inputotp";
import {
  GetCheckOutStatusData,
  GetStatus,
  UpdateStatusInsideMall,
  UpdateStatusToOutOfDelivered,
  UpdateStatusToDelivered,
  GetDeliveryCharge,
  GetDeliveryStatuses,
  UpdateStatus,
  UpdateShipmentNumber,
  GetRunners,
  UpdateRunnerInformation,
  UpdatePaymentReference,
} from "../../../Services/ShopAndDropService";
import { useCookies } from "react-cookie";
import { GetAllImageFiles, DownloadBase64File } from "../../../Services/Files";
import { status } from "../../Common/Config";
import { toast } from "react-toastify";
import CustomWebcam from "../../Camera/Camera";
import otpIcon from "../../../assets/images/OTP new.jpg";
import proofIcon from "../../../assets/images/id-card.png";
import { UploadBase64Files } from "../../../Services/Files";
import { Dropdown } from "primereact/dropdown";
import Print from "../../Print/Print";
const TrackDeliveryView = () => {
  const inintialDelievryDetails = { isOpen: false, otp: null, imageUrl: "" };
  const [isLoader, setIsLoader] = useState(false);
  const [isSuceesModalOpen, setIsSuccessModalOpen] = useState(false);
  const [deliveryModal, setDeliveryModal] = useState({
    ...inintialDelievryDetails,
  });
  const [isConformationOpen, setIsConformationOpen] = useState(false);
  const [isDelieveredConformationOpen, setIsDelieveredConformationOpen] =
    useState(false);
  const [shipmentModal, setShipmentModal] = useState({
    isOpen: false,
    shipmentId: null,
  });
  const [checkoutDetails, setCheckoutDetails] = useState(null);
  const [cookies, setCookie, removeCookie] = useCookies();
  const [imageUrl, setImageUrl] = useState("");
  const [proofImage, setProofImage] = useState("");
  const [isShpmentErrMsgView, setIsShpmentErrMsgView] = useState(false);
  const [isOtpErrMsgView, setIsOtpErrMsgView] = useState(false);
  const [isValidOtp, setIsValidOtp] = useState(false);
  const [isProofModal, setIsProofModal] = useState(false);
  const [btnLoading, setIsBtnLoading] = useState(false);
  const [isCameraVisible, setIsCameraVisible] = useState(false);
  const [imgSrc, setImgSrc] = useState("");
  const [isProofOtp, setIsProfOtp] = useState(true);
  const [proofImageUrl, setProofImageUrl] = useState("");
  const [previewModal, setPreviewModal] = useState({
    isOpen: false,
    imageUrl: "",
  });
  const [updateProofrl, setUpdateProofUrl] = useState();
  //currStatus
  const currStatus = checkoutDetails?.statusInformation
    ? checkoutDetails?.statusInformation[0]?.name
    : "";

  // const isOutOfDelievry = checkoutDetails?.statusInformation?[0].?

  const params = useParams();
  let ID = Number(params.id.split(" ").pop());
  let isInsideMall = params.isInsidemall.split(" ").pop() == "true";

  const [deliveryStatus, setDeliveryStatus] = useState([]);
  const [insideMallDeliveryStatus, setInsideMallUpdatedDeliveryStatus] = useState([]);
  const [outsideMallDeliveryStatus, setOutsideMallUpdatedDeliveryStatus] = useState([]);
  const [paymentReferenceNo, setPaymentReferenceNo] = useState("");
  const [runners, setRunners] = useState([]);
  const [shipmentNumber, setShipmentNumber] = useState("");
  const [runner, setRunner] = useState({ runnerId: "", runnerName: "", id: "" });
  const [updateDeliveryStatus, setUpdateDeliveryStatus] = useState(null);
  const [isInValid, setIsInvalid] = useState(false);
  const [isRunnerErr, setIsRunnerErr] = useState(false);
  const [isExistObjValue, setIsExistObjValue] = useState({
    shipmentNumber: "",
    runner: null,
    paymentReferenceNumber: "",
    status: null
  });
  // Deepak-Print
  const initialPrintDetails = {
    refrenceNo: "",
    deliveryType: "",
    name: "",
    mobileNumber: "",
    nobags: "",
    checkin: "",
    deliveryDate: "",
    deliveryTime: "",
    deliveryStatus: ""
  }
  const [printDetail, setPrintDetails] = useState(initialPrintDetails);
  const contentToPrint = useRef(null);
  // ----------------------

  const navigate = useNavigate();


  const customInput = ({ events, props }) => (
    <input
      key={props?.key}
      {...events}
      {...props}
      type="text"
      className="custom-otp-input"
    />
  );
  const getCheckOutData = async (value = []) => {
    await GetCheckOutStatusData(cookies?.bearerToken, ID, isInsideMall)
      ?.then((res) => {
        if (res.status == 200) {
          setCheckoutDetails({ ...res?.data?.response });
          // console.log(res.data.response);
          setUpdateDeliveryStatus({ name: res?.data?.response?.statusInformation[0]?.name, id: res?.data?.response?.statusInformation[0]?.id.toString() })

          getDeliveryStatuses(0, 10, res?.data?.response?.statusInformation[0]?.id.toString());


          if (res?.data?.response?.paymentReferenceNumber) {
            setPaymentReferenceNo(res?.data?.response?.paymentReferenceNumber);
          }
          if (res?.data?.response?.deliveryInformation?.shipmentNumber) {
            setShipmentNumber(res?.data?.response?.deliveryInformation?.shipmentNumber);
          }
          var filteredRunner = null;
          if (res?.data?.response?.runnerId && res?.data?.response?.runnerName && value?.length > 0) {
            filteredRunner = value?.filter((_) => { return _?.id == res?.data?.response?.runnerId })[0];
            // console.log(filteredRunner,"filteredRunner",value);
            // filteredRunner.id = filteredRunner?.runnerId?.toString();//old
            // console.log(filteredRunner,"filteredRunner");
            if (filteredRunner) {
              setRunner(filteredRunner);
              setIsExistObjValue((prev) => ({
                ...prev,
                runner: filteredRunner
              }));
            }

          }
          // console.log(filteredRunner?filteredRunner:runner,filteredRunner,"runner set up");
          // isExist store for unwanted updates;
          setIsExistObjValue((prev) => ({
            ...prev,
            status: res?.data?.response?.statusInformation || null,
            shipmentNumber: res?.data?.response?.deliveryInformation?.shipmentNumber || "",
            paymentReferenceNumber: res?.data?.response?.paymentReferenceNumber || ""
          }));

          if (res?.data?.response) {
            setPrintDetails(() => ({
              ...printDetail,
              refrenceNo: res?.data?.response?.code,
              name: res?.data?.response?.firstName + res?.data?.response?.lastName,
              mobileNumber: res?.data?.response?.phoneNumber,
              nobags: res?.data?.response?.totalBags,
              checkin: res?.data?.response?.deliveryInformation?.desk ? res?.data?.response?.deliveryInformation?.desk : res?.data?.response?.deliveryInformation?.tenant,
              deliveryStatus: res?.data?.response?.statusInformation[0]?.name,
              deliveryTime: res?.data?.response?.deliveryInformation?.pickupTime,
              deliveryDate: res?.data?.response?.deliveryInformation?.deliveryDate,
              pickupLocation: res?.data?.response?.deliveryInformation?.pickupLocation,
              tagNo: res?.data?.response?.baggageTag
            }))
          }
          // if (res?.data?.response) {
          //   let isInsideMall = true;
          //   let deliveryType = "InMall";
          // }

        }
      })
      .catch((err) => console.log("err", err))
      .finally(() => {
        setIsLoader(false);

      });
  };

  const getSignature = async () => {
    let data = {
      id: ID,
      module: "VIPSignature",
    };

    await GetAllImageFiles(data, cookies?.bearerToken)?.then(async (res) => {
      if (res.status == 200) {
        let base64Url = res?.data[0]?.blobUrl;
        if (base64Url) {
          await DownloadBase64File(base64Url, cookies?.bearerToken)
            ?.then((response) => {
              if (response.status == 200) {
                setImageUrl(
                  "data:" + res?.data[0]?.fileName + ";base64," + response?.data
                );
                // console.log(
                //   "img url",
                //   "data:" + res?.data[0]?.fileName + ";base64," + response?.data
                // );
              }
            })
            .finally(() => {
              getCheckOutData();
            });
        } else {
          getCheckOutData();
        }
      } else {
        getCheckOutData();
      }
    });
  };

  const handleUpdateStatusInsideMall = async () => {
    setIsLoader(true);
    await UpdateStatusInsideMall(cookies?.bearerToken, ID, deliveryModal.otp)
      ?.then((res) => {
        if (res.status == 200) {
          setDeliveryModal({ ...inintialDelievryDetails });
          setIsValidOtp(false);
          navigate("/shop-and-drop-track-deliveries");
          toast.success("Delivered successfully", { position: "top-right" });
        } else {
          if (res?.response?.data?.message == "Incorrect OTP") {
            setIsValidOtp(true);
          } else {
            toast.error("There is issue occured in add subscription", {
              position: "top-right",
            });
          }

          if (isProofOtp) {
            setIsCameraVisible(true);
          } else {
            setDeliveryModal({ ...deliveryModal, isOpen: true });
          }
        }
      })
      .finally(() => {
        setIsBtnLoading(false);
        setIsLoader(false);
      });
  };

  const handleUpdateStatusOutsideMall = async (updateFunction) => {
    setIsLoader(true);
    await updateFunction(cookies?.bearerToken, ID, shipmentModal.shipmentId)
      ?.then((res) => {
        if (res.status == 200) {
          if (currStatus === status.inDesk) {
            setShipmentModal({
              isOpen: false,
              shipmentId: "",
            });
            navigate("/shop-and-drop-track-deliveries");
            toast.success("Out for delivered successfully", {
              position: "top-right",
            });
          } else if (currStatus === status.outOfDelivery) {
            setIsDelieveredConformationOpen(false);
            navigate("/shop-and-drop-track-deliveries");
            toast.success("Delivered successfully", { position: "top-right" });
          }
        } else {
          if (currStatus === status.inDesk) {
            setShipmentModal({
              ...shipmentModal,
              isOpen: true,
            });
          } else if (currStatus === status.outOfDelivery) {
            setIsDelieveredConformationOpen(true);
          }
        }
      })
      .catch((err) => console.log("err", err))
      .finally(() => {
        setIsBtnLoading(false);
        setIsLoader(false);
      });
  };

  const handleDownload = async (moduleName) => {
    let data = {
      id: ID,
      module: moduleName,
    };

    await GetAllImageFiles(data, cookies?.bearerToken)?.then(async (res) => {
      if (res.status == 200) {
        let base64Url = res?.data[0]?.blobUrl;
        if (base64Url) {
          await DownloadBase64File(base64Url, cookies?.bearerToken)?.then(
            (response) => {
              if (res.status == 200) {
                const link = document.createElement("a");
                link.href =
                  "data:" +
                  res?.data[0]?.fileName +
                  ";base64," +
                  response?.data;
                link.setAttribute("download", res?.data[0]?.fileName);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
              }
            }
          );
        }
      }
    });
  };

  const getImage = (imageUrl) => {
    setProofImageUrl(imageUrl);
    setIsDelieveredConformationOpen(true);
  };

  const handleAddProof = async () => {
    setIsBtnLoading(true);
    setIsLoader(true);

    let fileNameExtension = imgSrc.split(";base64,")[0].split("/")[1];
    let fileModule = "ShopandDrop";
    let filedate =
      new Date().getFullYear() + new Date().getMonth() + new Date().getDate();
    let fileName = fileModule + filedate + "." + fileNameExtension;
    let data = [
      {
        id: ID,
        module: "VIPInsideMallDeliveryProof",
        inspectionId: 0,
        files: [
          {
            fileName: fileName,
            base64: imgSrc.split(";base64,")[1],
          },
        ],
      },
    ];
    await UploadBase64Files(data, cookies.bearerToken)
      .then((res) => {
        if (res.status == 200) {
          handleUpdateStatusInsideMall();
        } else {
          setIsCameraVisible(true);
          setIsBtnLoading(false);
          setIsLoader(false);
        }
      })
      .finally((err) => { });
  };

  const handleViewPdf = async (moduleName) => {
    let data = {
      id: ID,
      module: moduleName,
    };
    setIsLoader(true);
    await GetAllImageFiles(data, cookies?.bearerToken)?.then(async (res) => {
      if (res.status == 200) {
        let base64Url = res?.data[0]?.blobUrl;
        if (base64Url) {
          await DownloadBase64File(base64Url, cookies?.bearerToken)?.then(
            (response) => {
              if (res.status == 200) {
                setPreviewModal({
                  isOpen: true,
                  imageUrl:
                    "data:" +
                    res?.data[0]?.fileName +
                    ";base64," +
                    response?.data,
                });
                setIsLoader(false);
              } else {
                setIsLoader(false);
              }
            }
          );
        } else {
          setIsLoader(false);
        }
      } else {
        setIsLoader(false);
      }
    });
  };
  // Deepak
  // console.log({ name: checkoutDetails?.statusInformation[0]?.name, id: checkoutDetails?.statusInformation[0]?.id.toString() });

  // const roleBaseStatus = (statuslist, key, currentStatus) => {
  //   switch (key) {
  //     case "HF_STORE":
  //       return currentStatus == "5" ?
  //         statuslist?.filter(_ => _?.id == "5" || _?.id == "6")
  //         :
  //         currentStatus == "6" ?
  //           statuslist?.filter(_ => _?.id == "3" || _?.id == "6") :
  //           statuslist

  //     case "SAD_DELIVERY_PARTNER":
  //       return (currentStatus == "5" || currentStatus == "2") ?
  //         statuslist?.filter(_ => _?.id == "5" || _?.id == "2")
  //         :
  //         currentStatus == "3" ?
  //           statuslist?.filter(_ => _?.id == "3" || _?.id == "4") :
  //           statuslist
  //   }
  // }
  const isCheckDualRoleProvison = () => {
    return cookies?.roles?.some(_ => _ == "SAD_DELIVERY_PARTNER") && cookies?.roles?.some(_ => _ == "HF_STORE");
  }
  const roleBaseStatus = (statuslist, key, currentStatus) => {
    //   if(isCheckDualRoleProvison()){
    //     return statuslist;
    //  }
    switch (key) {

      case "HF_STORE":
        return currentStatus == "5" ?
          statuslist?.filter(_ => _?.id == "5" || _?.id == "6")
          :
          currentStatus == "6" ?
            statuslist?.filter(_ => _?.id == "3" || _?.id == "6") :
            statuslist

      case "SAD_DELIVERY_PARTNER":
        return (currentStatus == "5" || currentStatus == "2") ?
          statuslist?.filter(_ => _?.id == "5" || _?.id == "2")
          :
          (currentStatus == "3" ?
            statuslist?.filter(_ => _?.id == "3" || _?.id == "4") :
            statuslist)
      case "HF_STORE_KEEPER":
        return statuslist;

    }
  }
  const roleBaseStatusAccess = (key, statusNAme) => {
    // if(isCheckDualRoleProvison()){
    //   return true
    // }
    switch (key) {
      case "HF_STORE":
        let hfStoreStatusValue = ["Collected", "In Store"];
        return hfStoreStatusValue?.some(_ => _ == statusNAme);
        break;

      case "SAD_DELIVERY_PARTNER":
        let hfRunnerStatusValue = ["Collected", "In Desk", "Out For Delivery", "Delivered"]
        return hfRunnerStatusValue?.some(_ => _ == statusNAme);
        break;
      case "HF_STORE_KEEPER":
        return true;
        break;
    }
  }



  const getDeliveryStatuses = async (skip = 0, take = 10, currentStatus) => {
    // console.log(currentStatus, "currentStatus");
    await GetDeliveryStatuses(skip, take, cookies.bearerToken)
      .then((res) => {
        if (res.status === 200) {
          // console.log(cookies?.roles, "roles");
          // console.log(res?.data?.data, "delivery status");
          setDeliveryStatus(res?.data?.data);
          setInsideMallUpdatedDeliveryStatus(
            roleBaseStatus(res?.data?.data, cookies?.roles[0], currentStatus)
            // currentStatus == "5" ?
            //   res?.data?.data.filter((_) => { return _.id !== "3" }) :
            //   res?.data?.data.filter((_) => { return _.id !== "3" && _.id !== "5" })
          );

          setOutsideMallUpdatedDeliveryStatus(
            roleBaseStatus(res?.data?.data, cookies?.roles[0], currentStatus))
          // cookies?.roles?.some(_ => _ == "HF_STORE") ? currentStatus == "5" ? res?.data?.data.filter((_) => { return _.id !== "4" }) : res?.data?.data.filter((_) => { return _.id !== "4" && _.id !== "5" })
          //   : (cookies?.roles?.some(_ => _ == "SAD_DELIVERY_PARTNER") ? currentStatus == "5" ? res?.data?.data.filter((_) => { return _.id !== "3" }) :
          //     currentStatus == "3" ? res?.data?.data.filter((_) => { return _.id !== "5" }) : res?.data?.data.filter((_) => { return _.id !== "3" && _.id !== "5" })
          //     : res?.data?.data.filter((_) => { return _.id !== "3" && _.id !== "5" })));
        }
      })
      .catch((err) => {
        console.warn(err);
      })
  };

  const updateShipmentNumber = async () => {
    // if (shipmentNumber == "") {
    //   return
    // }
    if (shipmentNumber === isExistObjValue?.shipmentNumber) {
      return
    }
    await UpdateShipmentNumber(ID, shipmentNumber || "", cookies.bearerToken)
      .then((res) => {
        if (res.status === 200) {
          // console.log(res.data);
          toast.success("Shipment details updated successfully", { position: "top-right" });

        }
      })
      .catch((err) => {
        console.warn(err);
      })
  };


  const updateRunnerInformation = async () => {

    if (!runner?.id) {
      return;
    }
    //
    // console.log(runner?.id,isExistObjValue?.runner?.id,"objId");
    if (runner?.id == isExistObjValue?.runner?.id) {
      return
    }
    // console.log(runner,isExistObjValue?.runner,"status runner");
    //
    setIsLoader(true);
    await UpdateRunnerInformation(ID, runner, cookies.bearerToken)
      .then((res) => {
        if (res.status === 200) {
          // console.log(res.data);
          toast.success("Runner information updated successfully", { position: "top-right" });
        }
      })
      .catch((err) => {
        console.warn(err);
      })
      .finally(() =>
        setIsLoader(false)
      )
  };
  const updateStatus = async (statusId) => {
    if (statusId == 4 && shipmentNumber == "") {
      setIsInvalid(true);
      return;
    }
    setIsInvalid(false);
    if (statusId == 4 && checkoutDetails?.payableAmount > 0 && paymentReferenceNo == "") {
      setIsInvalid(true);
      return;
    }
    setIsInvalid(false);
    if (statusId == isExistObjValue?.status[0]?.id) {
      navigate("/shop-and-drop-track-deliveries");
      return
    }
    setIsLoader(true)
    await UpdateStatus(ID, statusId, cookies.bearerToken)
      .then((res) => {
        if (res.status === 200) {
          // console.log(res.data);
          // setIsLoader(false);
          // updateRunnerInformation();
          // updateShipmentNumber();
          // updatePaymentReferrenceNo();
          toast.success("Status updated successfully", { position: "top-right" });
        }
      })
      .catch((err) => {
        console.warn(err);
      })
      .finally(() => {
        setIsLoader(false);
        // updateRunnerInformation();
        // updateShipmentNumber();
        // updatePaymentReferrenceNo();
        // getSignature();
        navigate("/shop-and-drop-track-deliveries");
      })
  };

  const getRunners = async (searchTerm = "", skip = 0, take = 10) => {
    await GetRunners(searchTerm, skip, take, cookies.bearerToken)
      .then((res) => {
        if (res.status === 200) {
          // console.log(res?.data?.data);
          setRunners(res?.data?.data)
          getCheckOutData(res?.data?.data);

        }
      })
      .catch((err) => {
        console.warn(err);
      })
      .finally(() => {
      })
  }
  const updatePaymentReferrenceNo = async () => {
    if (paymentReferenceNo == "") {
      return;
    }
    if (paymentReferenceNo == isExistObjValue?.paymentReferenceNumber) {
      //
      // console.log(paymentReferenceNo,isExistObjValue?.paymentReferenceNumber,"status paymentReferenceNumber");
      //
      return
    }
    await UpdatePaymentReference(ID, paymentReferenceNo, cookies.bearerToken)
      .then((res) => {
        if (res.status === 200) {
          // console.log(res?.data?.data);
          toast.success("Payment information updated successfully", { position: "top-right" });
        }
      })
      .catch((err) => {
        console.warn(err);
      })
  }

  const handleInsideMall = () => {
    console.log("inside mall");
    // if (checkoutDetails?.statusInformation[0]?.id == updateDeliveryStatus?.id) {
    //   toast.info("Please change the status before updation", { position: "top-right" });
    //   return;
    // }

    if (cookies?.roles?.some(_ => _ == "HF_STORE") && updateDeliveryStatus?.name == "Out For Delivery" && !runner?.id) {
      setIsRunnerErr(true);
      return
    }
    setIsRunnerErr(false);

    if (updateDeliveryStatus?.id == 4 && checkoutDetails.payableAmount > 0 && paymentReferenceNo == "") {
      setIsInvalid(true);
      return;
    }
    setIsInvalid(false);
    // if (checkoutDetails?.statusInformation[0]?.name == updateDeliveryStatus?.name) {
    //   updateRunnerInformation();
    //   updatePaymentReferrenceNo();
    //   return;
    // }
    //test//
      updateRunnerInformation();
      updatePaymentReferrenceNo();
      //
    setDeliveryModal({
      ...deliveryModal,
      otp: "",
    });
    if (updateDeliveryStatus?.name == "Delivered") { //jeeva
      setIsProofModal(true);
      updateRunnerInformation();
      updatePaymentReferrenceNo()
    } else {
      updateStatus(updateDeliveryStatus?.id);
    }


  };

  const handleOutsideMall = async () => {
    // if (checkoutDetails?.statusInformation[0]?.id == updateDeliveryStatus?.id) {
    //   toast.info("Please change the status before updation", { position: "top-right" });
    //   return;
    // }
    if (cookies?.roles?.some(_ => _ == "HF_STORE") && updateDeliveryStatus?.name == "Out For Delivery" && !runner?.id) {
      setIsRunnerErr(true);
      return
    }
    setIsRunnerErr(false);
    updateStatus(updateDeliveryStatus?.id);

    updateRunnerInformation();
    updateShipmentNumber();
    updatePaymentReferrenceNo();
  };

  const handleUpdate = () => {
    // check the delivery type
    if (isInsideMall) {
      handleInsideMall();
    }
    else {
      handleOutsideMall();
    }
  };
  // Deepak-Print
  const handlePrint = useReactToPrint({
    documentTitle: "Print This Document",
    onBeforePrint: () => console.log("before printing..."),
    onAfterPrint: () => console.log("after printing..."),
    removeAfterPrint: true,
  });
  // -------------------------
  const checkRoleAndAccess = () => {
    let roles = cookies?.roles;
    if (roles?.length > 0) {
      if (roles.includes("HF_STORE")) {
        return true;
      }
      if (roles.includes("")) {
        return false;
      }
    }
  }
  useEffect(() => {
    setIsLoader(true);
    // getDeliveryStatuses();
    getRunners();
    getSignature();
  }, []);

  return (
    <>
      {isLoader ? (
        <div
          className="spinner spinner-center"
          style={{ marginLeft: "750px" }}
        ></div>
      ) : (
        <>
          <div className="track-delivery-view">
            <div className="track-delivery-view-heading"></div>
            <div className="track-delivery-content">
              <div className="track-delivery-user-details">
                <div
                  className="header"
                  style={{ height: "auto", paddingBottom: "10px" }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      flexGrow: "3",
                      justifyContent: "flex-start",
                      color: "#ae9d81",
                    }}
                    className="flex align-items-center org"
                  >
                    <i
                      className="pi pi-arrow-circle-left nav-back-btn"
                      onClick={() => {
                        navigate("/shop-and-drop-track-deliveries");
                      }}
                      style={{
                        cursor: "pointer",
                        fontSize: "1.5rem",
                      }}
                    />
                    <div className="heading" style={{ paddingLeft: "10px" }}>
                      <h2 className="text-center">
                        {currStatus === status.delivered && "View"}{" "}
                        {currStatus === status.delivered ? "d" : "D"}elivery
                        details
                      </h2>
                    </div>
                  </div>
                </div>
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                  <div className="input">
                    <div className="input-label">
                      <p>Name</p>
                    </div>
                    <div className="input-text">
                      <p>: {checkoutDetails?.firstName}{" "}{checkoutDetails?.lastName}</p>
                    </div>
                  </div>
                  <div className="input">
                    <div className="input-label">
                      <p>Mobile number</p>
                    </div>
                    <div className="input-text">
                      <p>: {checkoutDetails?.phoneNumber}</p>
                    </div>
                  </div>
                  <div className="input">
                    <div className="input-label">
                      <p>Email id</p>
                    </div>
                    <div className="input-text">
                      <p>: {checkoutDetails?.emailId || "-"}</p>
                    </div>
                  </div>
                  <div className="input">
                    <div className="input-label">
                      <p>Pickup type</p>
                    </div>
                    <div className="input-text">
                      <p>: {checkoutDetails?.deliveryInformation?.pickupType}</p>
                    </div>
                  </div>
                  {/* <div className="input">
                    <div className="input-label">
                      <p>Delivery type</p>
                    </div>
                    <div className="input-text">
                      <p>: {checkoutDetails?.deliveryInformation?.deliveryTimeLabel}</p>
                    </div>
                  </div> */}
                  <div className="input">
                    <div className="input-label">
                      <p>
                        {checkoutDetails?.deliveryInformation?.pickupType ===
                          "Desk"
                          ? "Desk location"
                          : "Tenant location"}
                      </p>
                    </div>
                    <div className="input-text">
                      <p>
                        :{" "}
                        {checkoutDetails?.deliveryInformation?.pickupType ===
                          "Desk"
                          ? checkoutDetails?.deliveryInformation?.desk
                          : checkoutDetails?.deliveryInformation?.tenant}
                      </p>
                    </div>
                  </div>
                  {isInsideMall && (
                    <div className="input">
                      <>
                        <div className="input-label">
                          <p>Pickup location</p>
                        </div>
                        <div className="input-text">
                          <p>
                            : {checkoutDetails?.deliveryInformation?.pickupLocation}
                          </p>
                        </div>
                      </>
                    </div>
                  )}
                  {isInsideMall && (
                    <div className="input">
                      <><div className="input-label">
                        <p>Pickup time</p>
                      </div>
                        <div className="input-text">
                          <p>
                            : {`${checkoutDetails?.deliveryInformation?.pickupTime?.toString().padStart(5, "0")}`}
                          </p>
                        </div>
                      </>
                    </div>
                  )}
                  <div className="input">
                    <div className="input-label">
                      <p>Baggage tag {isInsideMall}</p>
                    </div>
                    <div className="input-text">
                      <p>: {checkoutDetails?.baggageTag}</p>
                    </div>
                  </div>

                  {!isInsideMall && (
                    <div className="input">
                      <div className="input-label">
                        <p>Shipment id </p>
                      </div>
                      <div className="input-text">
                        { }
                        {checkoutDetails?.statusInformation[0]?.name === "Delivered" || cookies?.roles.includes("GUESTSERVICESTAFF") ?
                          <p>
                            : {shipmentNumber}
                          </p>
                          :
                          <>
                            <span>:</span> < InputText value={shipmentNumber} style={{ width: "182px" }} onChange={(e) => { setShipmentNumber(e.target.value) }} />
                            {isInValid && shipmentNumber == "" && <p style={{ color: "red" }}>Shipment id is required</p>}
                          </>
                        }

                      </div>
                    </div>
                  )}
                  <div className="input">
                    <div className="input-label">
                      <p>Payment amount</p>
                    </div>
                    <div className="input-text">
                      <p>
                        : {checkoutDetails?.payableAmount}
                      </p>

                    </div>
                  </div>
                  {checkoutDetails?.payableAmount > 0 && <div className="input">
                    <div className="input-label">
                      <p>Payment reference no</p>
                    </div>
                    <div className="input-text">
                      {checkoutDetails?.statusInformation[0]?.name === "Delivered" || cookies?.roles.includes("GUESTSERVICESTAFF") ?
                        <p>
                          : {paymentReferenceNo}
                        </p>
                        :
                        <>
                          <span>:</span> < InputText value={paymentReferenceNo} style={{ width: "182px" }} onChange={(e) => { setPaymentReferenceNo(e.target.value) }} />
                          {isInValid && paymentReferenceNo == "" && <p style={{ color: "red" }}>Payment reference no is required</p>}
                        </>
                      }
                    </div>
                  </div>}
                  <div className="input">
                    <div className="input-label">
                      <p>Runner</p>
                    </div>
                    <div className="input-text">
                      {checkoutDetails?.statusInformation[0]?.name === "Delivered" || cookies?.roles.includes("GUESTSERVICESTAFF") ?
                        <p>
                          : {checkoutDetails?.runnerName ? checkoutDetails?.runnerName : "-"}
                        </p>
                        :
                        <>
                          <span>: </span>
                          < Dropdown value={runner} style={{ width: "182px", padding: "8px", border: "1px solid #ae9d81", fontFamily: "Segoe UI" }} placeholder="Select" options={runners} optionLabel="firstName" onChange={(e) => { console.log(e.value, "value"); setRunner(e.value) }} />
                          {isRunnerErr && <p style={{ color: "red" }}>Runner is required</p>}
                          {/* {isInValid && (!runner?.id || !runner?.firstName) && <p style={{ color: "red" }}>Runner is required</p>} */}
                        </>
                      }

                    </div>
                  </div>
                  <div className="input">
                    <div className="input-label">
                      <p>Status </p>
                    </div>
                    <div className="input-text">
                      {checkoutDetails?.statusInformation[0]?.name === "Delivered" || cookies?.roles.includes("GUESTSERVICESTAFF") ?
                        <p>: {checkoutDetails?.statusInformation[0]?.name}</p>
                        :
                        <>
                          <span>: </span><Dropdown
                            disabled={!roleBaseStatusAccess(cookies?.roles[0], currStatus)}
                            options={isInsideMall ? insideMallDeliveryStatus : outsideMallDeliveryStatus}
                            style={{ width: "182px", padding: "8px", border: "1px solid #ae9d81", fontFamily: "Segoe UI" }}
                            placeholder="Select" value={updateDeliveryStatus} optionLabel="name"
                            onChange={(e) => { console.log(e.value); setUpdateDeliveryStatus(e.value) }}></Dropdown>
                        </>
                      }
                    </div>
                  </div>
                  <div className="input">
                    <div className="input-label">
                      <p>Proof type</p>
                    </div>
                    <div className="input-text">
                      <p
                        onClick={() => { }}
                        style={{ display: "flex", alignItems: "flex-start" }}
                      >
                        :{" "}
                        {checkoutDetails?.checkInProofs
                          ? checkoutDetails?.checkInProofs[0]?.proofType
                          : ""}{" "}
                        <i
                          className="pi pi-eye"
                          onClick={() => {
                            // handleDownload();
                            handleViewPdf("VIPCheckInProof");
                          }}
                          style={{
                            cursor: "pointer",
                            fontSize: "1.5rem",
                            paddingLeft: "5px",
                          }}
                        />
                        <i
                          className="pi pi-download"
                          onClick={() => {
                            handleDownload("VIPCheckInProof");
                          }}
                          style={{
                            cursor: "pointer",
                            fontSize: "1.5rem",
                            paddingLeft: "5px",
                          }}
                        />
                      </p>
                    </div>
                  </div>
                  {checkoutDetails?.deliveryInformation?.isDeliveredByOTP ==
                    false && (
                      <div className="input">
                        <div className="input-label">
                          <p>Delivery attachment </p>
                        </div>
                        <div className="input-text">
                          <p
                            onClick={() => { }}
                            style={{ display: "flex", alignItems: "flex-start" }}
                          >
                            :
                            <i
                              className="pi pi-eye"
                              onClick={() => {
                                // handleDownload();
                                handleViewPdf("VIPInsideMallDeliveryProof");
                              }}
                              style={{
                                cursor: "pointer",
                                fontSize: "1.5rem",
                                paddingLeft: "5px",
                              }}
                            />
                            <i
                              className="pi pi-download"
                              onClick={() => {
                                // handleDownload();
                                handleDownload("VIPInsideMallDeliveryProof");
                              }}
                              style={{
                                cursor: "pointer",
                                fontSize: "1.5rem",
                                paddingLeft: "5px",
                              }}
                            />
                          </p>
                        </div>
                      </div>
                    )}
                </div>
              </div>
              <div className="track-delivery-view-bag-list-table">
                <div
                  className="header"
                  style={{
                    height: "auto",
                    marginBottom: "10px",
                    color: "#ae9d81",
                  }}
                >
                  <h2 className="text-center">Bag list</h2>
                </div>
                <div className="track-delivery-view-bag-list-table-data">
                  <div
                    className="bag-list-table"
                    style={{ height: isInsideMall ? "310px" : "320px" }}
                  >
                    <DataTable
                      value={checkoutDetails?.bagInformation}
                      tableStyle={{ width: "100%" }}
                      stripedRows
                      rows={10}
                      emptyMessage={
                        <>
                          <p style={{ textAlign: "center" }}></p>
                        </>
                      }
                    >
                      <Column
                        field="name"
                        header="Tenant name"
                        style={{ width: "35%" }}
                      ></Column>
                      <Column
                        field="receiptAmount"
                        header="Receipt amount"
                        style={{ width: "35%" }}
                      ></Column>
                      <Column
                        field="numberOfBags"
                        header="Number of bags"
                        style={{ width: "30%" }}
                      ></Column>
                    </DataTable>
                  </div>
                  <div className="bag-list-amount">
                    <div>
                      <span style={{ color: "#ae9d81", fontWeight: 700 }}>
                        Total amount :{" "}
                      </span>
                      <span>{checkoutDetails?.totalAmount}</span>
                    </div>
                    <div>
                      <span style={{ color: "#ae9d81", fontWeight: 700 }}>
                        Total bags :{" "}
                      </span>
                      <span>{checkoutDetails?.totalBags}</span>
                    </div>
                  </div>
                </div>
                <div className="track-delivery-view-bag-list">
                  <div className="track-delivery-view-bag-list-delievry">
                    <div className="track-delivery-view-bag-list-delievry-content">
                      {!isInsideMall && (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          <p>
                            <span style={{ color: "#ae9d81", fontWeight: 700 }}>
                              Paid amount as :
                            </span>{" "}
                            {
                              checkoutDetails?.deliveryInformation
                                ?.payableAmount
                            }{" "}
                            <span style={{ color: "#ae9d81" }}>
                              BHD
                            </span>
                          </p>
                        </div>
                      )}
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "flex-end",
                          margin: "10px 20px 10px 0px",
                        }}
                      >
                        <div style={{ width: "100px", height: "50px", marginRight: '50px' }}>
                          <img
                            style={{ width: "100%", height: "100%" }}
                            src={imageUrl}
                          ></img>
                        </div>
                      </div>
                      <div className="track-delivery-view-bag-list-delievryBtn">
                        {checkoutDetails?.statusInformation[0]?.name != "Delivered" && !cookies?.roles.includes("GUESTSERVICESTAFF") ?

                          <>
                            {checkoutDetails?.statusInformation[0]?.name !== "Delivered" && <Button
                              label={"Update"}
                              style={{
                                backgroundColor: "#ae9d81",
                                border: "none",
                                borderRadius: "3px",
                                width: "170px",
                                height: "40px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                              onClick={() => {
                                handleUpdate()
                                // updateStatus(ID, updateDeliveryStatus?.id)
                              }}
                            />}
                          </> : ""}
                        {true ? (
                          <>
                            {/* {currStatus === status.inDesk &&
                              (
                                <Button
                                  label={"Mark as delivered"}
                                  style={{
                                    backgroundColor: "#ae9d81",
                                    border: "none",
                                    borderRadius: "3px",
                                    width: "170px",
                                    height: "40px",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                  }}
                                  onClick={() => {
                                    setDeliveryModal({
                                      ...deliveryModal,
                                      otp: "",
                                    });
                                    setIsProofModal(true);
                                  }}
                                />
                              )} */}
                            <Button
                              label={"Print Receipt"}
                              style={{
                                backgroundColor: "#ae9d81",
                                border: "none",
                                borderRadius: "3px",
                                width: "170px",
                                height: "40px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                              onClick={() => {
                                handlePrint(null, () => contentToPrint.current);
                              }}
                            />
                          </>
                        ) : ""

                          // (
                          //   <>
                          //     {currStatus === status.inDesk && (
                          //       <Button
                          //         label={"Out for delivery"}
                          //         style={{
                          //           backgroundColor: "#ae9d81",
                          //           border: "none",
                          //           borderRadius: "3px",
                          //           width: "150px",
                          //           height: "40px",
                          //           display: "flex",
                          //           justifyContent: "center",
                          //           alignItems: "center",
                          //         }}
                          //         onClick={() => {
                          //           setShipmentModal({
                          //             isOpen: true,
                          //             shipmentId: "",
                          //           });
                          //         }}
                          //       />
                          //     )}
                          //     {currStatus === status.outOfDelivery && (
                          //       <Button
                          //         label={"Mark as delivered"}
                          //         style={{
                          //           backgroundColor: "#ae9d81",
                          //           border: "none",
                          //           borderRadius: "3px",
                          //           width: "170px",
                          //           height: "40px",
                          //           display: "flex",
                          //           justifyContent: "center",
                          //           alignItems: "center",
                          //         }}
                          //         onClick={() => {
                          //           setIsDelieveredConformationOpen(true);
                          //         }}
                          //       />
                          //     )}
                          //   </>
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Dialog
            header={
              <h3 style={{ textAlign: "center", color: "#ae9d81", margin: 0 }}>
                Enter OTP
              </h3>
            }
            visible={deliveryModal.isOpen}
            style={{ width: "30%" }}
            onHide={() => {
              setDeliveryModal({ ...deliveryModal, isOpen: false });
            }}
          >
            <div className="track-delivery-view-delivery-modal">
              <div className="track-delivery-view-delivery-modal-content">
                <div className="otp">
                  <InputOtp
                    value={deliveryModal.otp}
                    onChange={(e) =>
                      setDeliveryModal({ ...deliveryModal, otp: e.value })
                    }
                    inputTemplate={customInput}
                    integerOnly
                  />
                  {
                    <p
                      style={{
                        margin: 0,
                        padding: "5px 5px 5px 0px",
                        color: "red",
                      }}
                      className="err-msg"
                    >
                      {isOtpErrMsgView &&
                        (!deliveryModal?.otp || deliveryModal?.otp?.length != 4)
                        ? "OTP is required"
                        : isValidOtp
                          ? "Incorrect OTP"
                          : ""}
                    </p>
                  }
                </div>
              </div>
              <div className="track-delivery-view-delivery-modal-btn">
                <Button
                  label={"Submit"}
                  style={{
                    backgroundColor: "#ae9d81",
                    border: "none",
                    borderRadius: "3px",
                    width: "120px",
                    height: "40px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  onClick={() => {
                    setIsOtpErrMsgView(
                      !deliveryModal?.otp || deliveryModal.otp.length != 4
                    );
                    if (deliveryModal?.otp && deliveryModal.otp.length === 4) {
                      setDeliveryModal({ ...deliveryModal, isOpen: false });
                      setIsDelieveredConformationOpen(true);
                    }
                  }}
                />
              </div>
            </div>
          </Dialog>
          <Dialog
            header={
              <h3 style={{ textAlign: "center", color: "#ae9d81", margin: 0 }}>
                Success
              </h3>
            }
            visible={isSuceesModalOpen}
            style={{ width: "30%" }}
            onHide={() => {
              setIsSuccessModalOpen(false);
              navigate("/shop-and-drop-track-deliveries");
            }}
          >
            <div className="track-delivery-view-success-modal">
              <div className="success-modal-content">
                <p>Updated Successfully</p>
              </div>
              <div className="success-modal-btn">
                <Button
                  label={"Ok"}
                  style={{
                    backgroundColor: "#ae9d81",
                    border: "none",
                    borderRadius: "3px",
                    width: "120px",
                    height: "40px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  onClick={() => {
                    setIsSuccessModalOpen(false);
                    navigate("/shop-and-drop-track-deliveries");
                  }}
                />
              </div>
            </div>
          </Dialog>
          <Dialog
            header={
              <h3 style={{ textAlign: "center", color: "#ae9d81", margin: 0 }}>
                Enter shipment id
              </h3>
            }
            visible={shipmentModal.isOpen}
            style={{ width: "30%" }}
            onHide={() => {
              setShipmentModal({
                ...shipmentModal,
                isOpen: false,
              });
            }}
          >
            <div className="track-delivery-view-success-modal">
              <div
                className="success-modal-content"
                style={{ marginBottom: "20px" }}
              >
                <div>
                  <InputText
                    value={shipmentModal.shipmentId}
                    onChange={(e) =>
                      setShipmentModal({
                        ...shipmentModal,
                        shipmentId: e.target.value,
                      })
                    }
                  />
                  {isShpmentErrMsgView && !shipmentModal?.shipmentId && (
                    <p
                      className="err-msg"
                      style={{ margin: 0, paddingLeft: "5px", color: "red" }}
                    >
                      shipment id is required
                    </p>
                  )}
                </div>
              </div>
              <div className="success-modal-btn">
                <Button
                  label={"Submit"}
                  style={{
                    backgroundColor: "#ae9d81",
                    border: "none",
                    borderRadius: "3px",
                    width: "120px",
                    height: "40px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  onClick={() => {
                    setIsShpmentErrMsgView(!shipmentModal.shipmentId);
                    if (shipmentModal.shipmentId) {
                      if (currStatus === status.inDesk) {
                        setIsConformationOpen(true);
                        setShipmentModal({
                          ...shipmentModal,
                          isOpen: false,
                        });
                      } else if (currStatus === status.outOfDelivery) {
                        setIsDelieveredConformationOpen(true);
                        setShipmentModal({
                          ...shipmentModal,
                          isOpen: false,
                        });
                      }
                    }
                  }}
                />
              </div>
            </div>
          </Dialog>
          <Dialog
            header={
              <h3 style={{ textAlign: "center", color: "#ae9d81", margin: 0 }}>
                Confirmation
              </h3>
            }
            visible={isDelieveredConformationOpen}
            style={{ width: "30%" }}
            onHide={() => {
              setIsDelieveredConformationOpen(false);
              navigate("/shop-and-drop-track-deliveries");
              if (isInsideMall) {
                if (isProofOtp) {
                  setIsCameraVisible(true);
                } else {
                  setDeliveryModal({ ...deliveryModal, isOpen: true });
                }
              }
            }}
          >
            <div className="track-delivery-view-success-modal">
              <div className="success-modal-content">
                <p style={{ fontSize: "18px" }}>
                  Are you sure want to mark as delivered?
                </p>
              </div>
              <div className="success-modal-btn" style={{ gap: "10px" }}>
                <Button
                  label={"No"}
                  style={{
                    backgroundColor: "#8e8e8e",
                    border: "none",
                    borderRadius: "3px",
                    width: "120px",
                    height: "40px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  onClick={() => {
                    setIsDelieveredConformationOpen(false);
                    if (isInsideMall) {
                      if (isProofOtp) {
                        setIsCameraVisible(true);
                      } else {
                        setDeliveryModal({ ...deliveryModal, isOpen: true });
                      }
                    }
                  }}
                />
                <Button
                  label={"Yes"}
                  loading={btnLoading}
                  style={{
                    backgroundColor: "#ae9d81",
                    border: "none",
                    borderRadius: "3px",
                    width: "120px",
                    height: "40px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  onClick={() => {
                    setIsBtnLoading(true);
                    setIsDelieveredConformationOpen(false);
                    if (isInsideMall) {
                      if (isProofOtp) {
                        handleAddProof();
                      } else {
                        handleUpdateStatusInsideMall();
                      }
                    } else {
                      handleUpdateStatusOutsideMall(UpdateStatusToDelivered);
                    }
                  }}
                />
              </div>
            </div>
          </Dialog>
          <Dialog
            header={
              <h3 style={{ textAlign: "center", color: "#ae9d81", margin: 0 }}>
                Confirmation
              </h3>
            }
            visible={isConformationOpen}
            style={{ width: "30%" }}
            onHide={() => {
              setIsConformationOpen(false);
              setShipmentModal({
                ...shipmentModal,
                isOpen: true,
              });
            }}
          >
            <div className="track-delivery-view-success-modal">
              <div className="success-modal-content">
                <p style={{ fontSize: "18px" }}>
                  Are you sure want to mark as out for delivery?
                </p>
              </div>
              <div className="success-modal-btn" style={{ gap: "10px" }}>
                <Button
                  label={"No"}
                  style={{
                    backgroundColor: "#8e8e8e",
                    border: "none",
                    borderRadius: "3px",
                    width: "120px",
                    height: "40px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  onClick={() => {
                    setIsConformationOpen(false);
                    setShipmentModal({
                      ...shipmentModal,
                      isOpen: true,
                    });
                  }}
                />
                <Button
                  label={"Yes"}
                  loading={btnLoading}
                  style={{
                    backgroundColor: "#ae9d81",
                    border: "none",
                    borderRadius: "3px",
                    width: "120px",
                    height: "40px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  onClick={() => {
                    setIsBtnLoading(true);
                    setIsConformationOpen(false);
                    handleUpdateStatusOutsideMall(UpdateStatusToOutOfDelivered);
                  }}
                />
              </div>
            </div>
          </Dialog>

          <Dialog
            draggable={false}
            visible={isProofModal}
            style={{ width: "40%" }}
            onHide={() => {
              setIsProofModal(false);
            }}
          >
            <div className="proof-modal">
              <div className="proof-modal-content">
                <div className="proof-box">
                  <div
                    className="proof-box-data"
                    onClick={() => {
                      setIsProfOtp(true);
                      setIsProofModal(false);
                      setIsCameraVisible(true);
                    }}
                  >
                    <div className="proof-img" style={{ width: "80%" }}>
                      <img src={proofIcon} />
                    </div>
                    <h3>Proof ID</h3>
                  </div>
                </div>

                <div className="proof-box">
                  <div
                    className="proof-box-data"
                    onClick={() => {
                      setIsProfOtp(false);
                      setIsProofModal(false);
                      setDeliveryModal({
                        ...deliveryModal,
                        isOpen: true,
                      });
                    }}
                  >
                    <div className="proof-img">
                      <img src={otpIcon} />
                    </div>
                    <h3>OTP</h3>
                  </div>
                </div>
              </div>
              {/* <div className="success-modal-btn" style={{ gap: "10px" }}>
                <Button
                  label={"No"}
                  style={{
                    backgroundColor: "#8e8e8e",
                    border: "none",
                    borderRadius: "3px",
                    width: "120px",
                    height: "40px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  onClick={() => {
                    setIsConformationOpen(false);
                    setShipmentModal({
                      ...shipmentModal,
                      isOpen: true,
                    });
                  }}
                />
                <Button
                  label={"Yes"}
                  loading={btnLoading}
                  style={{
                    backgroundColor: "#ae9d81",
                    border: "none",
                    borderRadius: "3px",
                    width: "120px",
                    height: "40px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  onClick={() => {
                    setIsBtnLoading(true);
                    setIsConformationOpen(false);
                    handleUpdateStatusOutsideMall(UpdateStatusToOutOfDelivered);
                  }}
                />
              </div> */}
            </div>
          </Dialog>
          <Dialog
            header={
              <h3 style={{ textAlign: "center", color: "#ae9d81", margin: 0 }}>
                Preview
              </h3>
            }
            visible={previewModal?.isOpen}
            style={{ width: "50%" }}
            onHide={() => {
              setPreviewModal({
                isOpen: false,
                imageUrl: "",
              });
            }}
          >
            <div className="success-modal">
              <div className="success-modal-content">
                <div
                  style={{
                    width: "85%",
                    margin: "auto",
                    height: "410px",
                    overflowY: "auto",
                  }}
                >
                  <img
                    style={{ width: "100%", height: "auto" }}
                    src={previewModal?.imageUrl}
                  ></img>
                </div>
              </div>
            </div>
          </Dialog>

          {isCameraVisible && (
            <CustomWebcam
              setIsVisible={setIsCameraVisible}
              setImage={setImgSrc}
              getImage={getImage}
            />
          )}
        </>
      )
      }

      <div className="print">
        <div ref={contentToPrint} >
          {/* <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
            <div style={{ paddingLeft: "4.5rem", paddingRight: "20px", width: "50%" }}>
              <div style={{ margin: 10 }}>
                <span style={{ display: "block" }}>Marassi Galleria</span>
                <span style={{ margin: "0px", padding: "0px" }}>Hands Free</span>
              </div>
              <br />
              <div style={{ margin: 10 }}>
                <span style={{ display: "block" }}>Delivery Service</span>
                <span style={{ margin: "0px", padding: "0px" }}>Ref No : {printDetail.refrenceNo || "-"}</span>
              </div>
            </div>

            <div style={{ paddingLeft: "20px", borderLeft: "solid", width: "50%" }} >
              <div style={{ paddingTop: "2px", paddingBottom: "2px" }}>
                <span>Delivery Type: {isInsideMall ? "Inside Mall" : "Out Side Mall" || "-"}</span>
              </div>
              <div style={{ paddingTop: "2px", paddingBottom: "2px" }}>
                <span>Delivery Status: {printDetail.deliveryStatus || "-"}</span>
              </div>
              <div style={{ paddingTop: "2px", paddingBottom: "2px" }}>
                <span>Name: {printDetail.name || "-"}</span>
              </div>
              <div style={{ paddingTop: "2px", paddingBottom: "2px" }}>
                <span>Tel: {printDetail.mobileNumber || "-"}</span>
              </div>
              <div style={{ paddingTop: "2px", paddingBottom: "2px" }}>
                <span>No Bags: {printDetail.nobags || "-"}</span>
              </div>
              <div style={{ paddingTop: "2px", paddingBottom: "2px" }}>
                <span>CheckIn: {printDetail.checkin || "-"}</span>
              </div>
              <div style={{ paddingTop: "2px", paddingBottom: "2px" }}>
                <span>Delivery Date: {printDetail.deliveryDate || "-"}</span>
              </div>
              <div style={{ paddingTop: "2px", paddingBottom: "2px" }}>
                <span>Delivery Time: {printDetail.deliveryTime || "-"}</span>
              </div>
            </div>
          </div> */}
          <div style={{ width: '100%', margin: 'auto' }} >
            <div style={{ display: 'flex', aligntems: 'center', padding: '8px', border: 'solid 2px #5f3e3bff', margin: '6px', outline: 'solid 1px #5f3e3bff', outlineOffset: '2px' }}>
              <div style={{ width: '27%', borderRight: 'solid 1px #000' }}>
                <div style={{ textAlign: "center" }}>
                  <img src="https://marassigalleria.bh/wp-content/uploads/2023/05/logomarassi.svg" style={{ textAlign: "center" }} />
                </div>
                <div>
                  <span style={{ display: 'block', fontWeight: 700, textAlign: 'center', paddingTop: '0.4rem', paddingBottom: '0.4rem', fontSize: '1.3rem', color: '#5f3e3bff' }}>Hands-Free</span>
                </div>
                <div>
                  <span style={{ display: 'block', textDecoration: 'underline', paddingTop: '0.4rem', paddingBottom: '0.4rem', fontWeight: 700, textAlign: 'center', fontSize: '1.3rem', color: '#130b0aff' }}>{printDetail?.deliveryType}</span>
                </div>
                <div style={{ paddingTop: '0.4rem', paddingBottom: '0.4rem' }}>
                  <span style={{ display: 'block', fontWeight: 600, textAlign: 'center', color: '#130b0aff' }}>Tag No : {printDetail?.tagNo || "-"}</span>
                  <span style={{ display: 'block', fontWeight: 600, textAlign: 'center', color: '#130b0aff' }}>Ref : {printDetail?.refrenceNo}</span>
                </div>
              </div>
              <div style={{ width: '70%', paddingLeft: '1rem' }}>
                <div>
                  <span style={{ display: 'block', fontWeight: 600, paddingTop: '0.1rem', paddingBottom: '0.1rem', color: '#130b0aff' }}>Name: {printDetail?.name}</span>
                  <span style={{ display: 'block', fontWeight: 600, paddingTop: '0.1rem', paddingBottom: '0.1rem', color: '#130b0aff' }}>Tel: {printDetail?.mobileNumber}</span>
                  <span style={{ display: 'block', fontWeight: 600, paddingTop: '0.1rem', paddingBottom: '0.1rem', color: '#130b0aff' }}>No Of Bags: {printDetail?.nobags}</span>
                </div>
                <div style={{ paddingTop: '1rem', paddingBottom: '1rem', fontWeight: 600 }}>
                  <span style={{ color: '#130b0aff' }}  >Pickup Location: {printDetail?.pickupLocation || "-"}</span>
                </div>
                <div>
                  <span style={{ display: 'block', fontWeight: 600, paddingTop: '0.1rem', paddingBottom: '0.1rem', color: '#130b0aff' }}>Delivery Date: {printDetail?.deliveryDate || "-"}</span>
                  <span style={{ display: 'block', fontWeight: 600, paddingTop: '0.1rem', paddingBottom: '0.1rem', color: '#130b0aff' }}>Delivery Time: {printDetail?.deliveryTime || "-"}</span>
                </div>
              </div>
            </div >
          </div>
        </div >
      </div>
    </>
  );
};

export default TrackDeliveryView;
