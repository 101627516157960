import Axios from "../Helpers/Axios";
import { isShow } from "./ExportServices";

export function GetItemList(skip, take, token, searchValue,orgId,status,showAll) {
    
    return Axios(`Master/AdminItemList?orgId=${orgId}${status?`&showAll=${showAll}&status=${status=="In Active"}`:""}&skip=${skip}&take=${take}&searchTerm=${searchValue}&forExport=false`, "get", null, token)
}
export function AddItemList(data, token) {
    return Axios("Item/Add", "post", data, token)
}
export function EditItemList(data, token) {
    return Axios("Item/Edit", "post", data, token)
}
export function GetSubItemsForItems(orgId, itemId, deskId, token) {
    return Axios(`Master/AdminSubItem?orgnaizationId=${orgId}&itemId=${itemId}&deskId=${deskId}`, "get", null, token)
}
export function AddPriceItem(data, token) {
    return Axios("Item/AddPriceItem", "post", data, token)
}

export function EditPriceItem(data, token) {
    return Axios("Item/EditPriceItem", "patch", data, token)
}
export function EditSubItem(id, token) {
    return Axios(`Item/DeleteSubItem?subItemId=${id}`, "post", null, token)
}
export function ImportItems(data, token) {
    return Axios(`Excel/BulkImportItem`, "post", data, token, true)
}
export function ImportDesk(data, token) {
    return Axios(`Excel/BulkImportDesk`, "post", data, token, true)
}

export function GetItemPriceList(skip, take, token, searchValue) {
    return Axios(`Item/GetPriceItems?skip=${skip}&take=${take}&searchTerm=${searchValue}`, "get", null, token)
}
export function GetActiveItems(token) {
    return Axios('Item/ActiveItems', "get", null, token)
}