import React, { useState, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { Link, useLocation } from 'react-router-dom';
import './style.scss'

//------Imports from other Components-----

import Header from '../Header/Header'

//----------------------------------------
// -------------Imports---------------
import { FilterMatchMode } from 'primereact/api';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { Tag } from 'primereact/tag';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

import { useNavigate } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify'
import { Paginator } from 'primereact/paginator';

import DepartmentCsv from '../../assets/files/Department.csv';

// -----------------------------------


// ----------Assets Import------------
import editIcon from '../../../src/assets/images/editIcon.jpg';
// -----------------------------------

//------Service imports-----
import { GetDepartmentList, AddDepartment, EditDepartment, GetDepartmentSearchedList, ImportDepartment } from '../../Services/DepartmentService';

//----------------------------------------

//-----------Context ---------------
import { useDispatch, useSelector } from "react-redux";
import { addOrganizationList, addDeskList, setNavVisibilty } from '../../Redux/AppSlice';
import { exportDataInExcel, exportFilterdExcel, isShow } from '../../Services/ExportServices';
import { FileUpload } from 'primereact/fileupload';
import Modalinfo from '../utili/Modalinfo';
import { Calendar } from 'primereact/calendar';

//----------------------------------------




function Department() {
    const Context = useSelector((state) => state.AppContext);
    const dispatch = useDispatch();
    const [cookies, setCookie, removeCookie] = useCookies();
    const [departmentList, setDepartmentList] = useState([]);
    const [isAdd, setIsAdd] = useState(false);
    const [department, setDepartment] = useState({
        "name": "",
        "isDeleted": false,
        "id": 0

    });
    const [departmentExists, setDepartmentExists] = useState(false);
    const [isInvalid, setIsInvalid] = useState('');
    const [isLoading, setIsLoading] = useState(true);

    const [isaddPopUp, setIsAddPopUp] = useState(false);
    const navigate = useNavigate();
    const [globalFilterValue, setGlobalFilterValue] = useState('');
    const [showConfirmation, setShowConfirmation] = useState(false);

    // Pagination
    const [first, setFirst] = useState(0)
    const [totalCount, setTotalRecords] = useState(0)

    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        name: { value: null, matchMode: FilterMatchMode.CONTAINS }
    });

    const [isImportInvalid, setIsImportInvalid] = useState(false);
    const [isImport, setIsImport] = useState(false);
    const [isImportInvalidFile, setIsImportInvalidFile] = useState(false);
    const [isFileValidating, setFileValidating] = useState(false);
    const onCancelClick = () => {
        setShowConfirmation(true);
    };

    const onHide = () => {
        setShowConfirmation(false);
        setIsAddPopUp(false);
        setDepartment({
            "name": "string",
            "isDeleted": false,
            "id": 0
        });
    };

    const handleConfirmation = (confirmed) => {
        if (confirmed) {
            onHide();
        } else {
            setShowConfirmation(false);
        }
    };


    const onGlobalFilterChange = (e) => {
        setGlobalFilterValue(e ? e?.target?.value : "");
        if (!e) {
            getDepartmentList(0, 10, "", filterObj?.isActiveDepartment, filterObj?.status);
            return
        }
        getDepartmentList(0, 10, e?.target?.value, filterObj?.isActiveDepartment, filterObj?.status);
        return

        const value = e ? e.target.value : "";
        let _filters = { ...filters };
        _filters['global'].value = value;
        setFilters(_filters);
        setGlobalFilterValue(value);
    };
    const statusBodyTemplate = ((row) => {
        // //console.log(row);
        if (row.isDeleted === false) {
            return <p style={{ color: "green" }}  >Active</p>
        }
        else {
            return <p style={{ color: "red" }} >Inactive</p>
        }
    });
    const actionButtonTemplate = (row) => {
        return (
            <div onClick={(e) => {
                setDepartment((prev) => ({
                    ...prev,
                    name: row?.name,
                    isDeleted: !row?.isDeleted,
                    id: row?.id
                }))
                setIsAdd(false);
                setIsAddPopUp(true);
            }}>
                <img src={editIcon} style={{ cursor: 'pointer' }} />
            </div>
        );
    };
    const header = () => {
        return <div className="heading" style={{ fontWeight: "700", fontSize: "25px", color: "#ae9d81", textAlign: 'center' }}>
            <p style={{ margin: '0px' }}>{isAdd ? "Add" : "Edit"} Department Details</p>
        </div>
    }
    const popup = (() => {
        return (
            <>
                <Dialog header={header} visible={isaddPopUp} closable={false} style={{ width: '30vw' }} onHide={() => {
                    setIsAdd(false);
                    setIsAddPopUp(false); setDepartment({
                        name: "", isDeleted: true, id: 0
                    });
                }}>
                    <div className="popup " >
                        {/* <div className="heading">
                            <p>Add New Details</p>
                        </div> */}
                        <div className="body">
                            <div className="label grid">
                                <div className='col'>Department Name<span style={{ color: 'red' }}>*</span></div>
                                <div className='col' style={{ marginLeft: '20px' }}>Status</div>
                            </div>
                            <div className="input grid ">
                                <div className="col">
                                    <InputText placeholder="Enter Department Name" value={department?.name} onChange={(e) => { setDepartment((prev) => ({ ...prev, name: e.target.value })) }} />
                                    {departmentExists &&
                                        // <div>
                                        <p style={{ color: "red" }}>Department name is already exists.</p>
                                        // </div>
                                    }
                                    {isInvalid && department?.name == "" &&
                                        // <div>
                                        <p style={{ color: "red" }}>Department is required.</p>
                                        // </div>
                                    }
                                </div>

                                <div className="col ">

                                    <label className="switch ">
                                        <input type="checkbox" checked={department?.isDeleted} onChange={(e) => { setDepartment((prev) => ({ ...prev, isDeleted: e.target.checked })); }} />
                                        <span className="slider round"></span>
                                    </label>
                                </div>

                            </div>
                        </div>
                        <div className="footer flex  grid">
                            <div className="col flex justify-content-center ">
                                <Button label="Cancel" onClick={() => {
                                    setIsAddPopUp(false);
                                    setIsInvalid(false);
                                    setDepartmentExists(false);
                                    setDepartment({
                                        "name": "string",
                                        "isDeleted": false,
                                        "id": 0
                                    });
                                }} style={{ backgroundColor: '#8E8E8E', border: "none", margin: "10px", height: '40px' }} />
                                <Button label={isAdd ? "Submit" : "Update"} onClick={() => { isAdd ? addDepartment() : editDepartment() }} style={{ backgroundColor: '#ae9d81', border: "none", margin: "10px", height: '40px' }} />
                            </div>
                        </div>
                    </div>
                </Dialog>

            </>

        )
    });
    const [filterObj, setFilterObj] = useState({
        status: "",
        isActiveDepartment: "",

    })
    const getDepartmentList = ((skip, take, searchValue, isActiveDepartment, status) => {
        setIsLoading(true);
        // if (!searchValue) {
        let obj = {
            isActiveDepartment: isActiveDepartment || "",
            searchValue: searchValue || "",
            status: status || "",
            skip: skip,
            take: take
        }
        GetDepartmentList(take, skip, cookies.bearerToken, searchValue, isActiveDepartment ? (isActiveDepartment == "Active" ? "true" : "false") : "", isShow(obj), status ? (status == "In Active" ? "true" : "false") : "")
            .then((res) => {
                if (res.status === 200) {
                    setDepartmentList(res?.data?.data);
                    setTotalRecords(res?.data?.count);
                }
            })
            .catch((err) => {
                //console.log(err);
            })
            .finally(() => {
                setIsLoading(false);
                // //console.log(organizationList);
            })
        // }
        // else {
        //     GetDepartmentSearchedList(take, skip, searchValue, cookies.bearerToken)
        //         .then((res) => {
        //             if (res.status === 200) {
        //                 setDepartmentList(res?.data?.data);
        //                 setTotalRecords(res?.data?.count);
        //             }
        //         })
        //         .catch((err) => {
        //             //console.log(err);
        //         })
        //         .finally(() => {
        //             setIsLoading(false);
        //             // //console.log(organizationList);
        //         })
        // }

    });

    const back = () => {
        navigate('/dashboard');
    };

    //Add Department
    const addDepartment = (() => {
        let data = {
            "name": department?.name,
            "isDeleted": !department?.isDeleted
        };

        if (department?.name == "") {
            setIsInvalid(true);
            return;
        }
        setIsInvalid(false);
        let isExists = departmentList.filter((item) => item.name.toLowerCase() === department?.name.toLowerCase());
        if (isExists.length > 0) {
            setDepartmentExists(true);
            return;
        }
        setDepartmentExists(false);
        AddDepartment(data, cookies.bearerToken)
            .then((res) => {

                getDepartmentList(first, 10, globalFilterValue, filterObj?.isActiveDepartment, filterObj?.status);
                setIsAdd(false);
                setIsAddPopUp(false);
                setDepartment({
                    "name": "",
                    "isDeleted": true,
                    "id": 0
                });
                toast.success("New Department is added successfully", { position: "top-right" });
            })
            .catch((err) => {
                //console.log(err);
            })
    });

    //Edit Department
    const editDepartment = (() => {
        let data = {
            "name": department?.name,
            "isDeleted": !department?.isDeleted,
            "id": department?.id
        };

        if (department?.name == "") {
            setIsInvalid(true);
            return;
        }
        setIsInvalid(false);

        let isExists = departmentList.filter((item) => (item.name.toLowerCase() === department?.name.toLowerCase() && item.id !== department?.id));
        if (isExists.length > 0) {
            setDepartmentExists(true);
            return;
        }
        setDepartmentExists(false);
        EditDepartment(data, cookies.bearerToken)
            .then((res) => {

                getDepartmentList(first, 10, globalFilterValue, filterObj?.isActiveDepartment, filterObj?.status);
                setIsAdd(false);
                setIsAddPopUp(false);
                setDepartment({
                    "name": "",
                    "isDeleted": true,
                    "id": 0
                });
                toast.success("Department is updated successfully", { position: "top-right" });
            })
            .catch((err) => {
                //console.log(err);
            })
    });

    const onPageChange = (e, index) => {

        setFirst(e.first);
        getDepartmentList(e.first, 10, globalFilterValue, filterObj?.isActiveDepartment, filterObj?.status);

    };
    const exportDepartmentInfo = () => {
        // if (globalFilterValue) {
        //     exportDataInExcel(`Department?searchTerm=${globalFilterValue}`, cookies.bearerToken);
        //     return
        // }
        // exportDataInExcel('Department', cookies.bearerToken);
        let obj = {
            isActiveDepartment: filterObj?.isActiveDepartment || "",
            searchValue: globalFilterValue || "",
            status: filterObj?.status || "",
        }

        let urlRoute = `Department/GetDepartments?skip=0&take=${totalCount}&searchTerm=${globalFilterValue || ""}${filterObj?.isActiveDepartment ? `&isActiveDepartments=${filterObj?.isActiveDepartment ? "true" : "false"}` : ""}${(filterObj?.status || filterObj?.isActiveDepartment || globalFilterValue) ? `&showAll=${isShow(obj)}` : ""}${filterObj?.status ? `&status=${filterObj?.status == "In Active" ? "true" : "false"}` : ""}&forExport=true`;

        exportFilterdExcel(urlRoute, cookies.bearerToken);
    }
    const onUpload = (e) => {
        //console.log(e?.files[0]);
        setFileValidating(true);
        if (e.files.length == 0) {
            setIsImportInvalid(true);
        }
        setIsImportInvalid(false);
        // if (e.files[0].name !== "Item.csv") {
        //     // alert("Please upload Tenantcsv file")
        //     setIsImportInvalidFile(true);
        //     e.files.pop()
        // }
        // else {
        setIsImportInvalidFile(false);
        const formData = new FormData();
        formData.append('file', e.files[0]);
        ImportDepartment(formData, cookies.bearerToken)
            .then((res) => {
                //console.log(res);
                if (res?.response?.status === 400) {
                    toast.info(res?.response?.data);
                    return
                }
                if (res.status === 200) {
                    toast.success("Department imported successfully");
                    setIsImport(false);
                    setFileValidating(false);
                    getDepartmentList(0, 10, globalFilterValue, filterObj?.isActiveDepartment, filterObj?.status);
                    setGlobalFilterValue("");
                }
            })
            .catch((err) => {
                //console.log(err);
                setFileValidating(false);
                if (err.response.status === 400) {
                    toast.error(err.response.data.message);
                } else {
                    toast.error("Something went wrong");
                }
            });
        // }
    };
    const importHeader = () => {
        return <div className="heading" style={{ fontWeight: "700", fontSize: "23px", color: "#ae9d81", textAlign: 'center' }}>
            <p style={{ margin: '0px' }}>Import Department Details</p>
        </div>
    }
    const importPopup = (() => {
        return (<>
            <Dialog header={importHeader} visible={true} style={{ width: '50vw' }} closable={true} onHide={() => { setIsImport(false) }}>
                <div className="popup " >
                    <div className="body">
                        <div className="col">
                            <div className="label grid">
                                <div className='col'>
                                    Sample Template {" "}
                                    <i className="pi pi-download" style={{ color: 'slateblue', marginLeft: "7px", cursor: "pointer" }}
                                        // on click i need to download a file 
                                        onClick={() => {
                                            // window.open(Itemcsv)
                                            var downloadLink = document.createElement("a");
                                            downloadLink.href = DepartmentCsv;
                                            downloadLink.download = "DepartmentCsv.csv";
                                            document.body.appendChild(downloadLink);
                                            downloadLink.click();
                                            document.body.removeChild(downloadLink);
                                        }}
                                    ></i>
                                </div>
                            </div>
                        </div>
                        <div className="col">
                            <div className="label grid">
                                <div className='col' style={{ display: "flex", alignItems: "center" }}>Select File<span style={{ color: 'red' }}>*</span> <Modalinfo /></div>
                            </div>
                            <div className="input grid ">
                                <div className="col">
                                    {/* <FileUpload mode="basic" name="demo[]" accept="*.csv" maxFileSize={1000000} onUpload={onUpload} chooseLabel="Browse" /> */}
                                    <FileUpload name="demo[]" accept=".csv" mode='basic' multiple={false} maxFileSize={1000000} customUpload uploadHandler={onUpload} emptyTemplate={<p className="m-0">Drag and drop files to here to upload.</p>} />
                                    {/* {isFileValidating && <i className="pi pi-spin pi-spinner" style={{ fontSize: '2rem' }}></i>} */}
                                    {isImportInvalid &&
                                        // <div>
                                        <p style={{ color: "red" }}>Please choose a file</p>
                                        // </div>
                                    }
                                    {isImportInvalidFile &&
                                        // <div>
                                        <p style={{ color: "red" }}>Name of the uploaded file should be same as the sample file.</p>
                                        // </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Dialog>
        </>)
    })
    // Export Filter
    const [exportFilterDialog, setExportFilterDialog] = useState(false);
    const [exportDetails, setExportDetails] = useState({
        startDate: "",
        endDate: "",
        organization: "",
        desk: "",
        status: true
    });
    useEffect(() => {
        getDepartmentList(0, 10);
        setExportDetails({
            startDate: "",
            endDate: "",
            organization: "",
            desk: "",
            status: true
        });
        setExportFilterDialog(false);
        setDepartmentList([]);
        setIsAdd(false);
        setGlobalFilterValue("");
    }, [])


    return (
        <>
        <Dialog header={<h3>Export Filter</h3>} draggable={false} style={{ width: '50vw', height: "max-content" }} closable={true} onHide={() => setExportFilterDialog(false)} visible={exportFilterDialog} >
            <div style={{ display: "flex", flexWrap: "wrap" }}>
                <div style={{ width: "50%" }}>
                    <label htmlFor="StartDate" className="font-bold block mb-2">Start Date
                    </label>
                    <Calendar
                        id="StartDate"
                        style={{ width: "95%" }}
                        value={exportDetails?.startDate}
                        placeholder="Choose start end"
                        onChange={((value) => setExportDetails(() => ({ ...exportDetails, startDate: value })))}
                    ></Calendar>
                </div>
                <div style={{ width: "50%" }}>
                    <label htmlFor="EndDate" className="font-bold block mb-2">End Date
                    </label>
                    <Calendar
                        id="EndDate"
                        style={{ width: "95%" }}
                        value={exportDetails?.endDate}
                        placeholder="Choose end date"
                        onChange={((value) => setExportDetails(() => ({ ...exportDetails, endDate: value })))}
                    ></Calendar>
                </div>
                <div style={{ width: "50%" }}>
                    <label htmlFor="organization" className="font-bold block mb-2">Organization
                    </label>
                    <InputText
                        id="organization"
                        style={{ width: "95%" }}
                        value={exportDetails?.organization}
                        onChange={((value) => setExportDetails(() => ({ ...exportDetails, organization: value })))}
                        placeholder="Enter organization" ></InputText>
                </div>
                <div style={{ width: "50%" }}>
                    <label htmlFor="Status" className="font-bold block mb-2">Status
                    </label>
                    {/* <InputText
                        id="Status"
                        style={{ width: "95%" }}
                        value={exportDetails?.status}

                        placeholder="Enter organization" ></InputText> */}
                    <div className="input grid ">
                        <div className="col ">
                            <label className="switch ">
                                <input type="checkbox" value={exportDetails?.status} checked={exportDetails?.status} onChange={((e) => setExportDetails(() => ({ ...exportDetails, status: e.target.checked })))} />
                                <span className="slider round"></span>
                            </label>
                        </div>
                    </div>
                </div>

            </div>
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "25px",
                }}
            >
                <Button
                    label="Submit"
                    onClick={''}
                    // icon={"pi pi-file-export"}
                    style={{
                        backgroundColor: " #ae9d81",
                        color: "#fff",
                        border: "none",
                        // borderRadius: "20px",
                        marginRight: "0",
                        cursor: totalCount > 0 ? "pointer" : "not-allowed",
                        padding: "0.6rem",
                    }}
                />
            </div>


        </Dialog>
            <div className="deskMaster">
                <div className="header">
                    <div style={{ display: "flex", flexDirection: "row", flexGrow: "3", justifyContent: "flex-start" }} className='flex align-items-center org'>
                        <i className="pi pi-arrow-circle-left nav-back-btn" onClick={back} style={{ marginLeft: '30px', cursor: 'pointer', fontSize: '1.5rem' }} />
                        {/* <div className="menubtn" style={{ padding: '10px 0px 5px 20px' }}>
                            <i className="pi pi-align-justify" style={{ fontSize: '1rem', cursor: "pointer" }} onClick={() => dispatch(
                                setNavVisibilty({ sideNavVisibility: true }))}></i>
                        </div> */}
                        <div className="heading">
                            <p>Department</p>
                        </div>

                    </div>
                    <div className='flex align-items-center'>

                        <div className='mx-2'>
                             <h4 className='mb-1 mt-0' style={{color:"#ae9d81"}}>Status</h4>
                            <Dropdown value={filterObj?.status} options={[{ name: "Active" }, { name: "In Active" }]} optionLabel='name' optionValue='name' onChange={(e) => { setFilterObj((prev) => ({ ...prev, status: e.value })); getDepartmentList(0, 10, globalFilterValue, filterObj?.isActiveDepartment, e.value) }} placeholder='Select' style={{ border: "solid 1px #ae9d81", width: 180 }} showClear={filterObj?.status ? true : false} />
                        </div>
                        {/* <div className='mr-4'>
                            <Dropdown value={filterObj?.isActiveDepartment} options={[{name:"Active"},{name:"In Active"}]} optionLabel='name' optionValue='name' onChange={(e)=>{setFilterObj((prev)=>({...prev,isActiveDepartment:e.value}));getDepartmentList(0,10,globalFilterValue,e.value,filterObj?.status) }} placeholder='Department' style={{border:"solid 1px #ae9d81",width:180}} showClear={filterObj?.isActiveDepartment?true:false}/>
                        </div> */}
                        <div className='mx-2'>
                            <h4 className='mb-1 mt-0' style={{color:"#ae9d81"}}>Status</h4>
                            <div className="search">
                                <span className="p-input-icon-left">
                                    <i className="pi pi-search" style={{ color: 'black', fontWeight: '700', fontSize: '1rem' }} />
                                    <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Search" style={{ width: 180 }} />
                                    <i className="pi pi-times" style={{ color: 'black', fontWeight: '400' }} onClick={() => { setGlobalFilterValue(""); onGlobalFilterChange() }} />

                                </span>
                            </div>
                        </div>
                       
                        <div className="mx-2 align-self-end">
                            <Button label="Add New" onClick={() => {
                                setIsAddPopUp(true);
                                setDepartment({
                                    "name": "",
                                    "isDeleted": true,
                                    "id": 0

                                });
                                setIsAdd(true);
                            }} style={{ backgroundColor: '#ae9d81', border: 'none' }} />
                        </div>

                        <div className="mx-2 align-self-end">
                            <Button label='Export' onClick={totalCount > 0 ? exportDepartmentInfo : null} icon={"pi pi-file-export"} style={{ backgroundColor: 'transparent', color: '#ae9d81', border: '1px #ae9d81 solid', borderRadius: '20px', cursor: totalCount > 0 ? 'pointer' : 'not-allowed' }} />
                        </div>
                        <div className="mx-2 align-self-end">
                            <Button label='Import' icon={"pi pi-file-import"} style={{ backgroundColor: 'transparent', color: '#ae9d81', border: '1px #ae9d81 solid', borderRadius: '20px' }} onClick={() => { setIsImport(true) }} />
                        </div>

                    </div>
                   
                </div>


                <div className="datatable">
                    {isLoading ?
                        <div className='spinner spinner-center'></div>
                        :
                        <DataTable value={departmentList} rows={10} filters={filters} tableStyle={{ width: '90rem' }} globalFilterFields={['name']} style={{ boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }} stripedRows
                            emptyMessage={<><p style={{ textAlign: "center" }}>Department list is empty</p></>}>
                            <Column field="name" header="Department Name" style={{ width: '33.33%' }}></Column>
                            <Column field="isDeleted" header="Status" body={statusBodyTemplate} style={{ width: '33.33%' }}></Column>
                            <Column header="Action" body={actionButtonTemplate} style={{ width: '33.33%' }}></Column>
                        </DataTable>}
                </div>
                {isLoading ? ""
                    :
                    <div style={{ justifyContent: "center", display: 'flex' }} >
                        <Paginator first={first} rows={10} totalRecords={totalCount} onPageChange={(e) => onPageChange(e, 1)} template={{ layout: 'RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink  LastPageLink CurrentPageReport' }} className="justify-content-start" />
                    </div>
                }

            </div>
            <div>
                {isaddPopUp == true ? popup() : ""}
                {isImport == true ? importPopup() : ""}
            </div>

        </>
    )
}

export default Department