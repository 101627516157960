import Axios from "../Helpers/Axios";

export function GetGuestStaffInspectedInformations(id, take, skip, token) {
    return Axios(`DepartmentTeam/GetGuestStaffInspectedInformations?guestInspectionId=${id}&take=${take}&skip=${skip}`, 'get', null, token)
}
export function GetSearchedGuestStaffInspectedInformations(id, take, skip, searchValue, token) {
    return Axios(`DepartmentTeam/GetGuestStaffInspectedInformations?guestInspectionId=${id}&searchTerm=${searchValue}&take=${take}&skip=${skip}`, 'get', null, token)
}
export function EditGuestStaffInspectionStatus(guestInspectionId, statusId, isDeleted, token) {
    return Axios(`DepartmentTeam/EditGuesStaffInspectionStatus?guestInspectedInformationId=${guestInspectionId}&statusId=${statusId}&isDeleted=${isDeleted}`, 'patch', null, token)
}