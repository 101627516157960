import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

// Prime React Components

import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Paginator } from "primereact/paginator";
import { Dialog } from "primereact/dialog";
import { InputTextarea } from "primereact/inputtextarea";
import { useCookies } from "react-cookie";
import { toast } from "react-toastify";
import { Badge } from "primereact/badge";

// ----------Assets Import------------

import editIcon from "../../../../assets/images/editIcon.jpg";
import {
  supervisorGetVIPInvoices,
  ApproveInvoice,
  RejectInvoice,
} from "../../../../Services/VIPLoungeSupervisor";
import { DownloadFile, getAttachmentFile } from "../../../../Services/Files";
import "./InvoiceDetails.scss";

function InvoiceDetails() {
  const referenceNumber = useParams().id;
  const isSupervisor = useParams().isApproveScreen;
  // states
  const [cookies, setCookie, removeCookie] = useCookies();
  const [isLoading, setIsLoading] = useState(false);
  const [submitLoader, setSubmitLoader] = useState(false);
  const [previewLoader, setPreviewLoader] = useState({
    id: "",
    isLoad: false,
  });
  const [invoiceLists, setInvoiceLists] = useState([]);
  // modal
  const [modal, setModal] = useState({
    isOPen: false,
    attachments: [],
  });

  const [rejectModal, setRejectModal] = useState({
    isOPen: false,
    comment: "",
    invoiceDetailId: null,
    isUpload: false,
  });
  const [previewAttachment, setPreviewAttachment] = useState({
    isOpen: false,
    attachmentUrl: "",
  });
  const [isErrMsgView, setIsErrMsgView] = useState(false);

  // Userlist

  // Pagination
  const [first, setFirst] = useState(0);
  const [totalCount, setTotalCount] = useState(0);

  console.log(
    invoiceLists,
    modal,
    rejectModal,
    previewAttachment,
    previewLoader
  );

  const navigate = useNavigate();

  // amount for view

  const previewFileFunction = (url) => {
    setPreviewLoader({ id: url.moduleId, isLoad: true });
    let data = [
      {
        fileName: url.fileName,
        azureBlobUrl: url.blobUrl.replaceAll("\\", "/"),
      },
    ];
    getAttachmentFile(data, cookies?.bearerToken)
      .then((res) => {
        console.log(res);
        setPreviewAttachment({
          isOpen: true,
          attachmentUrl: res?.data?.[0].base64,
        });
        setPreviewLoader({ id: "", isLoad: false });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const amountTemplate = (amount) => {
    return (
      <>
        <div>
          <div
            onClick={(e) => {
              // viewInvoiceDetails(row?.id);
            }}
          >
            <p style={{ fontSize: "15px", fontWeight: 400 }}>BHD {amount}</p>
          </div>
        </div>
      </>
    );
  };

  const getStatusSeverity = (row) => {
    let statusSeverity = "";
    if (row.invoiceStatus === "Submitted") {
      statusSeverity = "info";
    } else if (row.invoiceStatus === "Rejected") {
      statusSeverity = "danger";
    } else if (row.invoiceStatus === "Approved") {
      statusSeverity = "success";
    } else if (row.invoiceStatus === "ReSubmitted") {
      statusSeverity = "secondary";
    }
    return statusSeverity;
  };

  const statusBodyTemplate = (row) => {
    return (
      <div className="delivery-status">
        <Badge value={row.invoiceStatus} severity={getStatusSeverity(row)} />
      </div>
    );
  };
  // Action buttons for view and edit
  const actionButtonTemplate = (row) => {
    console.log("row", row);
    return (
      <>
        <div>
          {
            (row.invoiceStatus == "Approved") && (
              <div>
                -
              </div>
            )
          }
          {(row.invoiceStatus == "Submitted" || row.invoiceStatus == "ReSubmitted") ? (
            <div className="btn-section">
              <Button
                label="Reject"
                onClick={() => {
                  // setIsVisible(true);
                  setRejectModal({
                    isOPen: true,
                    comment: "",
                    invoiceDetailId: parseFloat(row.id),
                    isUpload: true,
                  });
                }}
                style={{
                  backgroundColor: "#d11818",
                  color: "white",
                  border: "none",
                  width: "80px",
                  padding: "5px 0px",
                }}
              />
              <Button
                label="Approve"
                onClick={() => {
                  // setIsVisible(true);
                  invoioceApproveFunction(parseFloat(row.id));
                }}
                style={{
                  backgroundColor: "#1bd118",
                  color: "white",
                  border: "none",
                  width: "80px",
                  padding: "5px 0px",
                }}
              />
            </div>
          ) : (
            <div>
              {row.invoiceStatus == "Rejected" && (
                <i
                  className="pi pi-info-circle"
                  style={{
                    fontSize: "1rem",
                    cursor: "pointer",
                    color: "red",
                  }}
                  onClick={() => {
                    setRejectModal({
                      isOPen: true,
                      comment: row.commentsForRejection,
                      invoiceDetailId: parseFloat(row.id),
                      isUpload: false,
                    });
                  }}
                ></i>
              )}
              {/* <Button
                label="Reject"
                onClick={() => {
                  // setIsVisible(true);
                  setRejectModal({
                    isOPen: true,
                    comment: "",
                    invoiceDetailId: parseFloat(row.id),
                  });
                }}
                style={{
                  backgroundColor: "#d11818",
                  color: "white",
                  border: "none",
                  width: "80px",
                  padding: "5px 0px",
                }}
              /> */}
            </div>
          )}
        </div>
      </>
    );
  };

  const fileNameBodyTemp = (row) => {
    return (
      <p
        style={{ cursor: "pointer" }}
        onClick={() => {
          downloadFile(row);
        }}
      >
        {row.fileName}
      </p>
    );
  };
  const attachmentsActionBodyTemp = (row) => {
    return (
      <div>
        {previewLoader.id == row.moduleId && previewLoader.isLoad ? (
          <i className="pi pi-spin pi-spinner" style={{ fontSize: "1rem" }}></i>
        ) : (
          <i
            className="pi pi-eye"
            style={{ fontSize: "1rem", cursor: "pointer" }}
            onClick={() => previewFileFunction(row)}
            loading={true}
          />
        )}
      </div>
    );
  };

  // Action view
  const attachmentTemplate = (row) => {
    return (
      <>
        <div>
          <i
            className="pi pi-paperclip"
            style={{
              fontSize: "1rem",
              cursor: "pointer",
            }}
            onClick={() => {
              setModal({ isOPen: true, attachments: row.fileDetails });
            }}
          ></i>
        </div>
      </>
    );
  };

  // download a file
  const handleDownload = (url, name) => {
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", name);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const getPageDetailsList = async (
    searchFilterValue = referenceNumber ? referenceNumber : ""
  ) => {
    setIsLoading(true);
    await supervisorGetVIPInvoices(searchFilterValue, cookies.bearerToken)
      .then((res) => {
        console.log("Response", res?.data?.data);
        if (res?.status === 200) {
          let tempArr = [];
          if (res?.data?.httpStatusCode == 404) {
            toast.error("No data found for the given reference number", { position: "top-right" });
            return;
          }
          else {
            res?.data?.uploadedInvoices.forEach((list) => {
              tempArr.push({
                vipnumber: list.vipnumber,
                invoiceNumber: list.invoiceNumber,
                dateOfPurchase: list.dateOfPurchase,
                purchaseAmount: list.purchaseAmount,
                storeName: list.tenantName,
                fileDetails: list.fileDetails,
                vipcode: res?.data?.vipCode,
                invoiceStatus: list.invoiceStatus,
                commentsForRejection: list.commentsForRejection,
                id: list.id,
              });
            });
            setTotalCount(res?.data?.uploadedInvoices.length);
            setInvoiceLists((prev) => [...tempArr]);
          }
          setIsLoading(false);
        }
      })
      .catch((error) => {
        toast.error(
          "There is issue occured in fetching user, could you please try again",
          { position: "top-right" }
        );
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getPageDetailsList();
  }, []);

  const downloadFile = (_) => {
    DownloadFile(_.fileName, _.blobUrl, cookies?.bearerToken);
  };

  // rejection comment

  const handleReject = async () => {
    setSubmitLoader(true);
    await RejectInvoice(
      rejectModal.invoiceDetailId,
      rejectModal.comment,
      cookies.bearerToken
    )
      .then((res) => {
        console.log(res);
        if (res?.response?.status === 400) {
          setIsLoading(false);
          toast.warning(res?.response?.data?.message, {
            position: "top-right",
          });
          return;
        }
        if (res?.status === 200) {
          if (res?.data?.httpStatusCode === 400) {
            toast.warning(res?.data?.message, { position: "top-right" });
            return;
          }
          if (res?.data?.httpStatusCode === 500) {
            toast.error(res?.data?.message, { position: "top-right" });
            return;
          } else {
            toast.success("Invoice Reject Succesfully", {
              position: "top-right",
            });
            setRejectModal({
              isOPen: false,
              comment: "",
              invoiceDetailId: null,
              isUpload: false,
            });
            setIsLoading(false);
            setSubmitLoader(false);
            getPageDetailsList();
          }
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsLoading(false);
        setSubmitLoader(false);
      });
  };

  const invoioceApproveFunction = async (invoiceDetailId) => {
    setIsLoading(true);
    await ApproveInvoice(invoiceDetailId, cookies.bearerToken)
      .then((res) => {
        console.log(res);
        if (res?.response?.status === 400) {
          setIsLoading(false);
          toast.warning(res?.response?.data?.message, {
            position: "top-right",
          });
          return;
        }
        if (res?.status === 200) {
          if (res?.data?.httpStatusCode === 400) {
            toast.warning(res?.data?.message, { position: "top-right" });
            return;
          }
          if (res?.data?.httpStatusCode === 500) {
            toast.error(res?.data?.message, { position: "top-right" });
            return;
          } else {
            toast.success("Invoice Approved Succesfully", {
              position: "top-right",
            });
            setIsLoading(false);
            getPageDetailsList();
          }
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setIsLoading(false));
  };

  // Get page details List

  return (
    <div className="InvoiceDetails">
      <div className="header">
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            flexGrow: "3",
            justifyContent: "flex-start",
          }}
          className="flex align-items-center org"
        >
          <i
            className="pi pi-arrow-circle-left nav-back-btn"
            onClick={() => {
              navigate("/lounge-supervisor-invoices");
            }}
            style={{
              marginLeft: "30px",
              cursor: "pointer",
              fontSize: "1.5rem",
            }}
          />
          <div className="heading" style={{ paddingLeft: "10px" }}>
            <p className="text-center">Invoice Details</p>
          </div>
        </div>
      </div>
      {/* <div className="header">
        <div className="back-arrow">
          <i
            className="pi pi-arrow-left"
            style={{
              fontSize: "1rem",
              color: "white",
              cursor: "pointer",
            }}
            onClick={() => {
              navigate("/lounge-supervisor-invoices");
            }}
          ></i>
        </div>
        <h3>Invoice details</h3>
      </div> */}

      <div className="datatable">
        {isLoading ? (
          <div
            className="spinner spinner-center"
            style={{ display: "flex", margin: "auto", marginTop: "200px" }}
          ></div>
        ) : (
          <DataTable
            value={invoiceLists}
            style={{ boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)" }}
            tableStyle={{ width: "90%", margin: "auto" }}
            stripedRows
            rows={10}
            emptyMessage={
              <>
                <p style={{ textAlign: "center" }}>List is empty</p>
              </>
            }
          >
            <Column
              field="vipcode"
              header="VIP Code"
              style={{ width: "15%" }}
            ></Column>
            <Column
              field="invoiceNumber"
              header="Invoice Number"
              style={{ width: "15%" }}
            ></Column>
            <Column
              field="storeName"
              header="Tenant Name"
              style={{ width: "15%" }}
            ></Column>
            <Column
              field="dateOfPurchase"
              header="Date Of invoice"
              style={{ width: "15%" }}
            ></Column>
            <Column
              field="purchaseAmount"
              header="Purchase amount"
              body={(row) => amountTemplate(row?.purchaseAmount)}
              style={{ width: "15%" }}
            ></Column>
            <Column
              field="fileDetails"
              header="Attachment"
              body={(row) => attachmentTemplate(row)}
              style={{ width: "15%" }}
            ></Column>
            <Column
              field="invoiceStatus"
              header="InvoiceStatus"
              style={{ width: "15%" }}
              body={statusBodyTemplate}
            ></Column>
            <Column
              field="nationality"
              header="Action"
              body={actionButtonTemplate}
              style={{ width: "10%" }}
            ></Column>
          </DataTable>
        )}
      </div>
      {isLoading ? (
        ""
      ) : (
        <div>
          <div
            style={{
              justifyContent: "center",
              display: "flex",
              padding: "0px 10px",
            }}
          >
            <Paginator
              first={first}
              rows={10}
              totalRecords={totalCount}
              // onPageChange={(e) => onPageChange(e, 1)}
              template={{
                layout:
                  "RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink  LastPageLink CurrentPageReport",
              }}
              className="justify-content-start"
            />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              padding: "0px 10px",
            }}
          >
            <span style={{ color: "#005f87", fontWeight: "bold" }}>
              Total Amount :{" "}
            </span>
            <span>
              {invoiceLists.reduce(
                (n, { purchaseAmount }) => n + purchaseAmount,
                0
              )}
            </span>
          </div>
        </div>
      )}

      {/* <div className="btn-section">
        <Button
          label="Reject"
          onClick={() => {
            // setIsVisible(true);
            setRejectModal({ isOPen: true, comment: "" });
          }}
          style={{
            backgroundColor: "#d11818",
            color: "white",
            border: "none",
            width: "100px",
            padding: "5px 0px",
          }}
        />
        <Button
          label="Approve"
          onClick={() => {
            // setIsVisible(true);
          }}
          style={{
            backgroundColor: "#1bd118",
            color: "white",
            border: "none",
            width: "100px",
            padding: "5px 0px",
          }}
        />
      </div> */}
      {/* <Dialog
        visible={modal.isOPen}
        // modal={false}
        showHeader={false}
        style={{ width: "30%" }}
      >
        <div className="attachment-modal">
          <div className="attachment-modal-heading">
            <h3>Attachments</h3>
          </div>
          <div className="attachment-modal-content">
            <div className="attachmnets-box">
              <div className="attachmnets">
                {modal.attachments.map((val, index) => {
                  return (
                    <p
                      key={index}
                      onClick={() => handleDownload(val.url, val.name)}
                    >
                      {val.name}
                    </p>
                  );
                })}
              </div>
            </div>
          </div>
          <div className="attachment-modal-btn"></div>
        </div>
      </Dialog> */}

      {/* <Dialog
        header={
          <h3 style={{ textAlign: "center", color: "#ae9d81", margin: 0 }}>
            Attachments
          </h3>
        }
        visible={modal.isOPen}
        style={{ width: "30%" }}
        onHide={() => {
          setModal({ isOPen: false, attachments: [] });
        }}
      >
        <div className="p-grid grid-modal attachment-modal">
          <div className="attachment-modal-content">
            <div className="attachmnets-box">
              <div className="attachmnets">
                {modal?.attachments.map((val, index) => {
                  return (
                    <p
                      key={index}
                      onClick={() => handleDownload(val.url, val.name)}
                    >
                      {val.fileName}
                    </p>
                  );
                })}
                <DataTable value={modal?.attachments}>
                  <Column
                    field="fileName"
                    header="File Name"
                    body={fileNameBodyTemp}
                  />
                </DataTable>
              </div>
            </div>
          </div>
          <div
            className="p-col-12"
            style={{
              margin: "20px 0px 10px 0px",
              display: "flex",
              justifyContent: "center",
              width: "100%",
              gap: "10px",
            }}
          >
            <Button
              label="Close"
              onClick={() => {
                setModal({ isOPen: false, attachments: [] });
              }}
              style={{
                backgroundColor: "#b9b9b9",
                color: "white",
                border: "none",
                width: "100px",
                padding: "5px 0px",
              }}
            />
          </div>
        </div>
      </Dialog> */}
      <Dialog
        className="preview-Attachment-Popup"
        visible={previewAttachment.isOpen}
        onHide={() => {
          setPreviewAttachment({ isOpen: false, attachmentUrl: "" });
        }}
        style={{ width: "50%" }}
        header={
          <h3 style={{ textAlign: "center", color: "#ae9d81", margin: 0 }}>
            Preview
          </h3>
        }
      >
        <img
          src={"data:image/jpeg;base64," + previewAttachment.attachmentUrl}
          style={{ height: 400, width: 600 }}
        ></img>
      </Dialog>
      <Dialog
        className="add-invoice-popup"
        visible={modal.isOPen}
        onHide={() => {
          setModal({ isOPen: false, attachments: [] });
        }}
        style={{ width: "50%" }}
        header={
          <h3 style={{ textAlign: "center", color: "#ae9d81", margin: 0 }}>
            Attachments
          </h3>
        }
      >
        {/* {fileModal?.attachments.map((obj) => {
            return (
              <p
                style={{ cursor: "pointer" }}
                onClick={() => {
                  downloadFile(obj);
                }}
              >
                {obj.fileName}
              </p> */}
        <DataTable value={modal?.attachments}>
          <Column field="fileName" header="File Name" body={fileNameBodyTemp} />
          <Column
            field="fileName"
            header="Action"
            body={attachmentsActionBodyTemp}
          />
        </DataTable>
        {/* //   );
          // })} */}
      </Dialog>
      <Dialog
        header={
          <h3 style={{ textAlign: "center", color: "#ae9d81", margin: 0 }}>
            Comments
          </h3>
        }
        visible={rejectModal.isOPen}
        style={{ width: "30%" }}
        onHide={() => {
          setRejectModal({
            isOPen: false,
            comment: "",
            invoiceDetailId: null,
            isUpload: false,
          });
          setIsErrMsgView(false);
        }}
      >
        <div className="p-grid grid-modal attachment-modal">
          <div className="reject-modal-content">
            <InputTextarea
              style={{
                width: "100%",
                height: "100px",
                borderColor: "#ae9d81",
                padding: "10px",
              }}
              disabled={!rejectModal.isUpload}
              value={rejectModal.comment}
              onChange={(e) => {
                setRejectModal({
                  ...rejectModal,
                  comment: e.target.value.trimStart(),
                });
              }}
            />
          </div>
          {isErrMsgView && !rejectModal?.comment && (
            <p className="err-msg" style={{ color: "red", marginLeft: "20px" }}>
              Comments is required
            </p>
          )}
          <div
            className="p-col-12"
            style={{
              margin: "20px 0px 10px 0px",
              display: "flex",
              justifyContent: "center",
              width: "100%",
              gap: "10px",
            }}
          >
            <Button
              label="Cancel"
              disabled={submitLoader}
              onClick={() => {
                setRejectModal({
                  isOPen: false,
                  comment: "",
                  invoiceDetailId: null,
                  isUpload: false,
                });
                setIsErrMsgView(false);
              }}
              style={{
                background: "rgb(142, 142, 142)",
                color: "white",
                border: "none",
                width: "100px",
                padding: "5px 0px",
              }}
            />
            {rejectModal.isUpload && (
              <Button
                label={submitLoader ? "" : "Submit"}
                loading={submitLoader}
                onClick={() => {
                  setIsErrMsgView(!rejectModal?.comment);
                  if (rejectModal?.comment) {
                    handleReject();
                  }
                }}
                style={{
                  background: "#ae9d81",
                  color: "white",
                  border: "none",
                  width: "100px",
                  padding: "5px 0px",
                }}
              />
            )}
          </div>
        </div>
      </Dialog>

      {/* <Dialog
        visible={rejectModal.isOPen}
        // modal={false}
        showHeader={false}
        style={{ width: "25%" }}
      >
        <div className="rejection-modal">
          <i
            className="pi pi-times"
            style={{
              fontSize: "1rem",
              cursor: "pointer",
            }}
            onClick={() => {
              setRejectModal({
                isOPen: false,
                comment: "",
                invoiceNumber: null,
              });
            }}
          ></i>
          <div className="rejection-modal-content">
            <h3>Comments</h3>
            <div className="rejection-modal-box">
              <InputTextarea
                style={{
                  width: "100%",
                  height: "100px",
                  borderColor: "#ae9d81",
                  padding: "10px",
                }}
                value={rejectModal.comment}
                onChange={(e) => {
                  setRejectModal({
                    ...rejectModal,
                    comment: e.target.value.trimStart(),
                  });
                }}
              />
            </div>
          </div>

          <div className="rejection-modal-btn">
            <Button
              label="Submit"
              onClick={() => {
                handleReject();
              }}
              style={{
                color: "white",
                border: "none",
                width: "100px",
                padding: "5px 0px",
              }}
            />
          </div>
        </div>
      </Dialog> */}
    </div>
  );
}

export default InvoiceDetails;
