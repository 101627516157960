import Axios from "../Helpers/Axios";

export function GetOrganizationList(token,params="") {
  return Axios(`Master/Organization`+params, "get", null, token);
}
export function GetOrganizationQR(id, token) {
  return Axios(
    `Organization/ShopandDropCheckInQR?organizationId=${id}`,
    "get",
    null,
    token
  );
}
export function GetActiveOrganizationList(token) {
  return Axios("Master/ActiveOrganization", "get", null, token);
}
export function AddOrganization(data, token) {
  return Axios("Organization/Add", "post", data, token);
}
export function EditOrganization(data, token) {
  return Axios("Organization/Edit", "post", data, token);
}
export function DeleteOrganization(data, token) {
  return Axios("Organization/Delete", "post", data, token);
}
export function exportOrganization() {
  return Axios("Export/Organization", "get", null, false);
}
