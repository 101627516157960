import Axios from '../Helpers/Axios';



export function GetOrganizationList(email) {
    return Axios(`User/Organization?email=${email}`, "get")
}
export function AdminLogin(data) {
    return Axios("Authentication/Signin", "post", data)
}
export function GetUserSignInDetails(data) {
    return Axios("Authentication/UserSignIn", "post", data)
}
export function GetIdentity(data, token) {
    return Axios("Authentication/GetIdentity", "post", data, token)
}
export function InspectionSignIn(data) {
    return Axios("Authentication/InspectionSignIn", "post", data)
}