import Axios from "../Helpers/Axios";

export const GetInvoicesCountOfVIPUser = (vipNumber, token) => {
    if (vipNumber == "") {
        return Axios(`User/GetInvoicesCountOfVIPUser`, "get", null, token);
    }
    else {
        return Axios(`User/GetInvoicesCountOfVIPUser?vipNumber=${vipNumber}`, "get", null, token);
    }
};
export const GetPurchasedPantryCountOfVIPUser = (vipNumber, token) => {
    if (vipNumber == "") {
        return Axios(`User/GetPurchasedPantryCountOfVIPUser`, "get", null, token);
    }
    else {
        return Axios(`User/GetPurchasedPantryCountOfVIPUser?vipNumber=${vipNumber}`, "get", null, token);
    }
};
export const GetInvoicesReportOfVIPUser = (skip, take, role) => {
    return Axios(`Export/GetInvoicesReportOfVIPUser?skip=${skip}&take=${take}?role=${role}`, "get", null, null);
};
export const GetPurchasedPantryReportOfVIPUser = (skip, take, token) => {
    return Axios(`Export/GetPurchasedPantryReportOfVIPUser?skip=${skip}&take=${take}`, "get", null, token);
};  