import { useNavigate, useParams } from 'react-router-dom';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import { FileUpload } from 'primereact/fileupload';
import './style.scss';
import { Button } from 'primereact/button';
import { useEffect, useRef } from 'react';
import { useCookies } from 'react-cookie';
import { useState } from 'react';
import { AddLostItemForm, EditLostItemForm, GetLostItem } from '../../Services/LostandFoundService';
import { GetActiveCategory } from '../../Services/LostAndFoundCategoryService';
import { toast } from 'react-toastify';
import { set } from 'immutable';
import { BulkUploadBase64Files, DownloadFile, getAttachmentFile } from '../../Services/Files';
import { CiImageOn } from "react-icons/ci";
import { Dialog } from 'primereact/dialog';
import IntlTelInput from 'react-intl-tel-input';

import nationality from "../../assets/JSON/NationalityFlags.json"

function AddLostForm() {
    const { id, route } = useParams();
    const inputRef = useRef(null);
    const localizationValue = "en";
    const [cookies, setCookie, removeCookie] = useCookies();
    const navigate = useNavigate();
    const [addLostForm, setAddLostForm] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        category: '',
        lostItem: '',
        lostDateTime: new Date(),
        lostLocation: '',
        attachment: [],
        files: [
            // {
            //     fileName: '',
            //     base64: '',
            // }
        ],
        country: "",
        number: 0,
        countryCode: "+973"
    });
    const [isAdd, setIsAdd] = useState(false);
    const [isInvalid, setIsInvalid] = useState(false);
    const [activeCategories, setActiveCategories] = useState([]);

    // File Upload
    const [isImportInvalid, setIsImportInvalid] = useState(false);
    const [isImportInvalidFile, setIsImportInvalidFile] = useState(false);
    const [isFileValidating, setFileValidating] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    // File preview
    const [previewLoader, setPreviewLoader] = useState({
        id: "",
        isLoad: false,
    });
    const [previewAttachment, setPreviewAttachment] = useState({
        isOpen: false,
        attachmentUrl: "",
    });
    //   --------------------

    // Mobile number with conutry code - Deepak
    const getMobileNumber = () => {
        let mobileNumber = inputRef?.current?.selectedCountryData?.dialCode + inputRef?.current?.state?.value
        assignValue("phoneNumber", "+" + mobileNumber)
        return mobileNumber;
    };
    const assignValue = (key, value) => {
        setAddLostForm((prevState) => {
            return {
                ...prevState,
                [key]: value
            };
        });
    };
    const getSelectedCountryName = () => {
        let selectedCountry = "bh";
        if (inputRef?.current?.countries && addLostForm?.countryCode) {
            selectedCountry = [...inputRef?.current?.countries]?.find(
                (val) => val.dialCode === addLostForm?.countryCode
            )?.iso2;
        }
        console.log("selectedCountry", selectedCountry);
        return selectedCountry ? selectedCountry : "bh";
    };
    const handlePhoneChange = (value, countryData) => {
        console.log("countryData", countryData);
        if (
            value?.length <= 12 &&
            /^[0-9]+$|^$/.test(value) &&
            value !== addLostForm?.phoneNumber
        ) {
            setAddLostForm({
                ...addLostForm,
                phoneNumber: value,
                countryCode: countryData?.dialCode,
            });
        }
        // setPhoneNumber(value);
    };
    const handleInputValue = (key, value) => {
        setAddLostForm({ ...addLostForm, [key]: value });
    };
    // ------------------------------   
    const [selectedCountry, setSelectedCountry] = useState({
        "english_name": "Bahrain",
        "arabic_name": "البحرين",
        "alpha2_code": "BH",
        "alpha3_code": "BHR",
        "phone_code": "+973",
        "flagImage": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BH.svg"
    });
    const selectedCountryTemplate = (option, props) => {
        // console.log(option);
        if (option) {
            return (
                <div className="flex align-items-center">
                    <img alt={option.flagImage} src={option?.flagImage} style={{ width: '18px' }} />
                </div>
            );
        }

        return <span>{props.placeholder}</span>;
    };

    const countryOptionTemplate = (option) => {
        return (
            <div className="flex align-items-center">
                <img alt={option.name} src={option?.flagImage} style={{ width: '18px' }} />
                <div>{option?.english_name}({option?.arabic_name}){option?.phone_code}</div>
            </div>
        );
    };
    //--------


    const getLostItem = async (id) => {
        setIsLoading(true);
        await GetLostItem(id, cookies.bearerToken)
            .then(async (res) => {
                if (res.status == 200) {
                    setIsLoading(false);
                    let lostItem = res.data;
                    //console.log("Lost Item", lostItem);
                    let categoryList = []
                    if (activeCategories?.length == 0) {
                        categoryList = await getActiveCategories();
                        //console.log(categoryList);
                    }
                    let category = categoryList?.filter((_) => _.id == lostItem?.categoryId)[0];
                    //console.log("Category", category, activeCategories);
                    setAddLostForm((prev) => ({
                        ...prev,
                        firstName: lostItem?.firstName,
                        lastName: lostItem?.lastName,
                        email: lostItem?.email,
                        phoneNumber: lostItem?.phoneNumber,
                        category: category,
                        lostItem: lostItem?.whatDidYouLose,
                        lostDateTime: lostItem?.lostDateTime,
                        lostLocation: lostItem?.whereDidYouLose,
                        statusId: lostItem?.statusId,
                        attachment: lostItem?.attachments ? lostItem?.attachment : [],
                        files: lostItem?.files,
                        countryCode: lostItem?.countryCode
                    }));

                }
            })
            .catch((err) => {
                setIsLoading(false);
                //console.log(err);
            })


    };

    const addLostItemForm = () => {
        if (addLostForm?.firstName === "" ||
            addLostForm?.lastName === '' ||
            // addLostForm?.email === '' ||
            addLostForm?.phoneNumber === '' ||
            addLostForm?.countryCode === '' ||
            addLostForm?.lostItem === '' ||
            addLostForm?.lostDateTime === '' ||
            addLostForm?.lostLocation === '') {
            toast.warn("Please fill all the mandatory fields", { position: "top-right" })
            setIsInvalid(true);
            return;
        }
        if (addLostForm?.attachment?.length == 0) {
            toast.warn("Please fill all the mandatory fields", { position: "top-right" })
            setIsInvalid(true);
            return;
        }

        let data = {
            "firstName": addLostForm?.firstName,
            "lastName": addLostForm?.lastName,
            "email": addLostForm?.email,
            "phoneNumber": addLostForm?.phoneNumber,
            "countryCode": addLostForm?.countryCode||selectedCountry?.phone_code,
            "categoryId": addLostForm?.category?.id,
            "whatDidYouLose": addLostForm?.lostItem,
            "lostDateTime": addLostForm?.lostDateTime,
            "whereDidYouLose": addLostForm?.lostLocation,
        }
        //console.log(data);
        AddLostItemForm(data, cookies.bearerToken)
            .then((res) => {
                if (res.status == 200) {
                    //console.log("Add Lost Form response", res);
                    uploadAttachments(res?.data);
                    toast.success("Lost Item added successfully", { position: "top-right" })
                }
            })
            .catch((err) => {
                //console.log(err);
            })
            .finally(() => {
                if (route === "g1") {
                    navigate('/lostandfound/lost');
                }
            })

    };


    const imageToBase64 = async () => {
        let imageList = [];
        if (addLostForm?.attachment?.length > 0) {
            for (const image of addLostForm?.attachment) {
                const imageData = await new Promise((resolve) => {
                    let reader = new FileReader();
                    reader.readAsDataURL(image);
                    reader.onload = () => {
                        resolve(reader.result);
                    };
                });

                imageList.push({
                    fileName: image?.name,
                    base64: imageData.split(";base64,")[1]
                });
            }
        }

        return imageList;
    }

    const uploadAttachments = async (lostId) => {
        let files = await imageToBase64();
        let data = {
            "id": lostId,
            "module": "Lost",
            "inspectionId": 0,
            "files": files
        }

        //console.log("Data", data);
        await BulkUploadBase64Files(data, cookies.bearerToken)
            .then((res) => {
                if (res.status == 200) {
                    //console.log(res);
                    // toast.success("Attachments uploaded successfully", { position: "top-right" });
                }
            })
            .catch((err) => {
                //console.log(err);
            })


    };
    const downloadFile = (_) => {
        DownloadFile(_.fileName, _.blobUrl, cookies?.bearerToken);
    };
    const previewImage = (url) => {
        console.log(url);
        setPreviewLoader({ id: url.moduleId, isLoad: true });
        let data = [
            {
                fileName: url.fileName,
                azureBlobUrl: url.blobUrl.replaceAll("\\", "/"),
            },
        ]
        getAttachmentFile(data, cookies?.bearerToken)
            .then((res) => {
                console.log(res);
                setPreviewAttachment({
                    isOpen: true,
                    attachmentUrl: res?.data?.[0].base64,
                });
                setPreviewLoader({ id: "", isLoad: false });
            })
            .catch((err) => {
                console.log(err);
            });
    };



    const ediLostItemForm = () => {
        if (addLostForm?.firstName === "" ||
            addLostForm?.lastName === '' ||
            // addLostForm?.email === '' ||
            addLostForm?.phoneNumber === '' ||
            addLostForm?.countryCode === '' ||
            addLostForm?.lostItem === '' ||
            addLostForm?.lostDateTime === '' ||
            addLostForm?.lostLocation === '') {
            toast.warn("Please fill all the mandatory fields", { position: "top-right" })
            setIsInvalid(true);
            return;
        }

        let data = {
            'id': id,
            "firstName": addLostForm?.firstName,
            "lastName": addLostForm?.lastName,
            "email": addLostForm?.email,
            "phoneNumber": addLostForm?.phoneNumber,
            "countryCode": addLostForm?.countryCode||selectedCountry?.phone_code||"+973",
            "categoryId": addLostForm?.category?.id,
            "whatDidYouLose": addLostForm?.lostItem,
            "lostDateTime": addLostForm?.lostDateTime,
            "whereDidYouLose": addLostForm?.lostLocation,
            "statusId": addLostForm?.statusId
        }
        //console.log("Edit data", data, addLostForm);
        EditLostItemForm(data, cookies.bearerToken)
            .then((res) => {
                if (res.status == 200) {
                    uploadAttachments(res?.data);
                    toast.success("Lost Item updated successfully", { position: "top-right" })
                }
            })
            .catch((err) => {
                //console.log(err);
            })
            .finally(() => {
                if (route === "g1") {
                    navigate('/lostandfound/lost');
                }
            })
    };


    const getActiveCategories = async () => {
        let list = []
        await GetActiveCategory(cookies.bearerToken)
            .then((res) => {
                if (res.status === 200) {
                    //console.log("Active Categories", res);
                    setActiveCategories(res.data);
                    list = res.data;
                }
            })
            .catch((err) => {
                //console.log(err);
            })
        return list;
    };


    const onUpload = (e) => {
        if (e.files.length == 0) {
            return
        }
        setAddLostForm((prev) => ({ ...prev, attachment: [...prev?.attachment, e.files[0]] }))
        e.files.pop();
    };


    const handleCountryChange = (value) => {
        console.log("Change", value);
        setAddLostForm((prev)=>({
            ...prev,
            countryCode: value?.phone_code.includes("+") ? value.phone_code : "+" + value.phone_code
        }));
    }

    useEffect(() => {
        getActiveCategories();
        if (id !== undefined) {
            getLostItem(id);
            setIsAdd(false);
            return;
        }
        setIsAdd(true);
    }, [])


    return (

        <>
            {isLoading ?
                <div className='spinner spinner-center' style={{ marginLeft: "700px" }}></div>
                :
                <div className="AddLostForm">
                    <Dialog
                        className="preview-Attachment-Popup"
                        visible={previewAttachment.isOpen}
                        onHide={() => {
                            setPreviewAttachment({ isOpen: false, attachmentUrl: "" });
                        }}
                        style={{ width: "50%" }}
                        header={
                            <h3 style={{ textAlign: "center", color: "#ae9d81", margin: 0 }}>
                                Preview
                            </h3>
                        }
                    >
                        <img
                            src={"data:image/jpeg;base64," + previewAttachment.attachmentUrl}
                            style={{ height: 400, width: 600 }}
                        ></img>
                    </Dialog>
                    <div div className="header" style={{ backgroundColor: '#ae9d81', color: 'white', height: '50px' }
                    }>
                        <i className="pi pi-arrow-left" style={{ cursor: 'pointer' }} onClick={() => { if (route === 'g1') { navigate('/lostandfound/lost') } }} ></i>
                        <div style={{ margin: 'auto', fontWeight: '600', fontSize: '18px' }}>{isAdd ? "Add" : "Edit"} Lost Form</div>
                    </div >
                    <div style={{ display: 'flex', justifyContent: 'space-between', margin: '0px 50px' }}>
                        <div>
                            <p>First Name<span style={{ color: 'red' }}>*</span></p>
                            <div>
                                <InputText placeholder='Enter First Name' style={{ width: '350px' }} value={addLostForm?.firstName} onChange={(e) => { setAddLostForm((prev) => ({ ...prev, firstName: e.target.value })) }} />
                            </div>
                            {isInvalid && addLostForm?.firstName == "" &&
                                <p style={{ color: "red" }}>First name is required</p>
                            }
                        </div>
                        <div>
                            <p>Last Name<span style={{ color: 'red' }}>*</span></p>
                            <div>
                                <InputText placeholder='Enter Last Name' style={{ width: '350px' }} value={addLostForm?.lastName} onChange={(e) => { setAddLostForm((prev) => ({ ...prev, lastName: e.target.value })) }} />
                            </div>
                            {isInvalid && addLostForm?.lastName == "" &&
                                <p style={{ color: "red" }}>Last name is required</p>
                            }
                        </div>
                        <div>
                            <p>Email<span style={{ color: 'red' }}>*</span></p>
                            <div>
                                <InputText placeholder='Enter Email' style={{ width: '350px' }} value={addLostForm?.email} onChange={(e) => { setAddLostForm((prev) => ({ ...prev, email: e.target.value })) }} />
                            </div>
                            {isInvalid && addLostForm?.email == "" &&
                                <p style={{ color: "red" }}>Email is required</p>
                            }
                        </div>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between', margin: '20px 50px 0px 50px' }}>
                        <div>
                            <p>Mobile Number<span style={{ color: 'red' }}>*</span></p>
                            <div>

                                {/* <IntlTelInput
                                    // className='mg_care__input'
                                    style={{ width: "350px", }}
                                    value={addLostForm?.phoneNumber}
                                    containerClassName="intl-tel-input phoneInputContainerStyle"
                                    noCountryDataHandler={(e) => console.log(e)}
                                    inputClassName={localizationValue == "en" ? "phoneInputStyle" : "phoneInputStyle directionRtl"}
                                    // onSelectFlag={(value, countryData) => {
                                    //     setAddLostForm({ ...addLostForm, countryCode: countryData?.dialCode })
                                    // }}
                                    onPhoneNumberChange={(
                                        valid,
                                        value,
                                        countryData,
                                        number,
                                        id
                                    ) => {
                                        handlePhoneChange(value, countryData);
                                    }}
                                    defaultCountry={getSelectedCountryName()}
                                    preferredCountries={["bh", "ae"]}
                                    ref={inputRef}

                                /> */}
                                <div style={{width: "350px",borderRadius:"6px"}} className='p-inputtext p-0'>
                                    <Dropdown value={selectedCountry} style={{ width: "100px !important", border: "none" }} onChange={(e) => { setSelectedCountry(e.value); handleCountryChange(e.value)}} options={nationality} optionLabel="english_name"
                                        valueTemplate={selectedCountryTemplate} itemTemplate={countryOptionTemplate}
                                    />
                                    <InputText style={{ width: '50%', border: "none" }} value={addLostForm?.phoneNumber} type='tel' min={0} placeholder='Enter here' onChange={(e) => { 
                                           setAddLostForm({
                                            ...addLostForm,
                                            phoneNumber: e?.target?.value
                                            // countryCode: countryData?.dialCode,
                                        });
                                           }}></InputText>
                                </div>
                            </div>
                            {isInvalid && addLostForm?.phoneNumber == "" &&
                                <p style={{ color: "red" }}>Mobile number is required</p>
                            }
                        </div>
                        <div>
                            <p>Category<span style={{ color: 'red' }}>*</span></p>
                            <div>
                                <Dropdown options={activeCategories} placeholder='Select Category' optionLabel='name' style={{ width: '350px', borderColor: '#ae9d81' }} value={addLostForm?.category} onChange={(e) => { setAddLostForm((prev) => ({ ...prev, category: e.target.value })) }} />
                            </div>
                            {isInvalid && addLostForm?.category == "" &&
                                <p style={{ color: "red" }}>Category is required</p>
                            }
                        </div>
                        <div>
                            <p>What did you lost?<span style={{ color: 'red' }}>*</span></p>
                            <div>
                                <InputText placeholder='Enter what did you lost' style={{ width: '350px' }} value={addLostForm?.lostItem} onChange={(e) => { setAddLostForm((prev) => ({ ...prev, lostItem: e.target.value })) }} />
                            </div>
                            {isInvalid && addLostForm?.lostItem == "" &&
                                <p style={{ color: "red" }}>Lost item  is required</p>
                            }
                        </div>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between', margin: '20px 50px 0px 50px' }}>
                        <div>
                            <p> When did you lost?(Date)<span style={{ color: 'red' }}>*</span></p>
                            <div>
                                <Calendar placeholder='Select date' className="calendar" style={{ width: '350px' }} showIcon value={new Date(addLostForm?.lostDateTime)} maxDate={new Date()} onChange={(e) => { setAddLostForm((prev) => ({ ...prev, lostDateTime: e.target.value })) }} />
                            </div>
                            {isInvalid && addLostForm?.lostDateTime == "" &&
                                <p style={{ color: "red" }}>Date  of lost item is required</p>
                            }
                        </div>
                        <div>
                            <p>Where did you lost?<span style={{ color: 'red' }}>*</span></p>
                            <div>
                                <InputText placeholder='Enter where did you lost' style={{ width: '350px', borderColor: '#ae9d81' }} value={addLostForm?.lostLocation} onChange={(e) => { setAddLostForm((prev) => ({ ...prev, lostLocation: e.target.value })) }} />
                            </div>
                            {isInvalid && addLostForm?.lostLocation == "" &&
                                <p style={{ color: "red" }}>The location where the item was lost is required</p>
                            }
                        </div>
                        <div>
                            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                <p>Attachment{isAdd && <span style={{ color: 'red' }}>*</span>}</p>

                            </div>
                            <div>
                                {isAdd ? (
                                    <div style={{ width: '350px', height: '150px', border: '1px solid #ae9d81', borderRadius: '10px' }}>
                                        <div style={{ padding: "10px" }}>
                                            <span style={{ cursor: 'pointer', color: '#ae9d81' }}>
                                                <FileUpload mode='basic' accept="image/*" multiple={true} maxFileSize={1000000} customUpload uploadHandler={onUpload} />
                                            </span>
                                        </div>
                                        {isInvalid && addLostForm?.attachment?.length == 0 &&
                                            <div>
                                                <p style={{ color: "red", margin: '5px' }}>Attachments is required</p>
                                            </div>
                                        }
                                        <div style={{ display: 'flex', flexWrap: 'wrap', overflow: "scroll", height: "50px", overflowX: 'hidden' }}>
                                            {addLostForm?.attachment?.length > 0 &&
                                                <>
                                                    {addLostForm?.attachment.map((image, index) => {
                                                        return (
                                                            <div style={{ margin: '5px', padding: 0, flexDirection: 'row', display: 'flex', height: '15px' }} key={index} >
                                                                {/* <img src={image?.fileName} alt="image" style={{ width: '50%', height: '50%', objectFit: 'contain', margin: '5px', padding: 0 }} /> */}
                                                                <p style={{ fontWeight: 400 }}>{index + 1}.  </p>
                                                                <p style={{ fontWeight: 400, textOverflow: "ellipsis", whiteSpace: "nowrap" }} > {image?.name?.slice(0, 30)}</p>
                                                                <i className="pi pi-times" style={{ cursor: 'pointer', color: '#ae9d81', margin: 0, padding: 0, fontSize: '1rem', display: 'flex', margin: '25px' }} onClick={() => {
                                                                    setAddLostForm((prev) => ({
                                                                        ...prev,
                                                                        attachment: prev?.attachment?.filter((item, i) => i !== index)
                                                                    }))
                                                                }} />
                                                            </div>)
                                                    })}
                                                </>

                                            }
                                        </div>


                                    </div>) : (
                                    <div style={{ width: '350px', height: '150px', border: '1px solid #ae9d81', borderRadius: '10px', overflow: 'scroll', overflowX: 'hidden' }}>
                                        <div style={{ display: 'flex', flexWrap: 'nowrap', flexDirection: 'column' }}>
                                            {
                                                addLostForm?.files?.length > 0 ?
                                                    <>
                                                        {addLostForm?.files.map((image, index) => {
                                                            return (
                                                                <div style={{ display: "flex", alignItems: "center", marginLeft: '5px' }}>
                                                                    <CiImageOn style={{ fontSize: 20 }} />
                                                                    <p style={{ fontSize: '12px', padding: "0 10px", cursor: "pointer" }} onClick={() => {
                                                                        // downloadFile(image);
                                                                        previewImage(image);
                                                                    }}>{image.fileName}</p>
                                                                </div>)
                                                        })}
                                                    </> :
                                                    <>
                                                        <div style={{ padding: "10px" }}>
                                                            <span style={{ cursor: 'pointer', color: '#ae9d81' }}>
                                                                <p>No attachments found</p>
                                                            </span>
                                                        </div>
                                                        {/* <div style={{ padding: "10px" }}>
                                                    <span style={{ cursor: 'pointer', color: '#ae9d81' }}>
                                                        <FileUpload mode='basic' accept="image/*" multiple={true} maxFileSize={1000000} customUpload uploadHandler={onUpload} />
                                                    </span>
                                                </div>
                                                {addLostForm?.attachment.map((image, index) => {
                                                    return (<div style={{ margin: '5px', padding: 0, flexDirection: 'row', display: 'flex', height: '15px' }} key={index} >
                                                        {/* <img src={image?.fileName} alt="image" style={{ width: '50%', height: '50%', objectFit: 'contain', margin: '5px', padding: 0 }} />
                                                        <p style={{ fontWeight: 400 }}>{index + 1}.  </p>
                                                        <p style={{ fontWeight: 400, textOverflow: "ellipsis", whiteSpace: "nowrap" }} >{image.name.slice(0, 30)}</p>
                                                        <i className="pi pi-times" style={{ cursor: 'pointer', color: '#ae9d81', margin: 0, padding: 0, fontSize: '1rem', display: 'flex', margin: '25px' }} onClick={() => {
                                                            setAddLostForm((prev) => ({
                                                                ...prev,
                                                                attachment: prev?.attachment?.filter((item, i) => i !== index)
                                                            }))
                                                        }} />
                                                    </div>)
                                                })}
                                                {isInvalid && addLostForm?.attachment?.length == 0 &&
                                                    <p style={{ color: "red", margin: '5px' }}>Attachments is required</p>
                                                } */}
                                                    </>
                                            }
                                        </div>

                                    </div>)}
                            </div>
                        </div>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'end', gap: '20px', margin: "10px 50px 50px 0px" }}>
                        <Button label='Cancel' className='cnl-btn' onClick={() => { navigate('/lostandfound/lost') }} />
                        <Button label={isAdd ? "Submit" : "Update"} className='smt-btn' onClick={isAdd ? addLostItemForm : ediLostItemForm} />

                    </div>

                </div >}
        </>
    )
}

export default AddLostForm