import React, { useState } from "react";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { CiImageOn } from "react-icons/ci";
import { DownloadFile } from "../../Services/Files";
import { useCookies } from "react-cookie";

function FileView({ visible, setVisible, imagelist, header }) {
  const [cookies, setCookie, removeCookie] = useCookies();
  const truncateString = (str, maxLength) => {
    if (str.length > maxLength) {
      return str.slice(0, maxLength) + "...";
    } else {
      return str;
    }
  };

  const downloadFile = async (filename, filePath) => {
    DownloadFile(filename, filePath, cookies?.bearerToken);
  };

  return (
    <div className="card flex justify-content-center">
      <Dialog
        header={header}
        visible={visible}
        modal={false}
        // style={{ width: "20vw" }}
        className='md:col-6 lg:col-4 sm:col-6'
        onHide={() => setVisible(false)}
      >
        <>
          {imagelist?.length !== 0 ? (
            <div style={{ overflow: "auto", maxHeight: 100 }}>
              {imagelist?.map((_, index) => {
                console.log(_);
                return (
                  <div
                    key={index}
                    style={{
                      display: "flex",
                      alignItems: "center ",
                      cursor: "pointer",
                    }}
                    onClick={() => downloadFile(_?.fileName, _?.blobUrl)}
                  >
                    <CiImageOn style={{ fontSize: "26px" }} />
                    <p
                      style={{ padding: "0 10px", fontSize: "14px" }}
                      title={_?.fileName}
                    >
                      {`${truncateString(_?.fileName?.split(".")[0], 14)}.${_?.fileName?.split(".")[1]
                        }`}
                    </p>
                  </div>
                );
              })}
            </div>
          ) : (
            <div>
              <p style={{ textAlign: "center" }}>No Attachments Found</p>
            </div>
          )}
        </>
      </Dialog>
    </div>
  );
}

export default FileView;
