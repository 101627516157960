import React, { useRef, useState } from "react";
// import Stepper from "@keyvaluesystems/react-stepper";
import { useDispatch, useSelector } from "react-redux";
import { stepsArrayData } from "../../../Redux/AppSlice";
import { handleStepChange } from "../../Common/Config";
import { setNavVisibilty } from "../../../Redux/AppSlice";

//styles
import "./ShopAndDropSidNav.scss";

//redux
import { setStepperDetails } from "../../../Redux/AppSlice";

const ShopAndDropSideNav = (props) => {
  const stepperDetails = useSelector(
    (state) => state.AppContext.stepperDetails
  );

  const dispatch = useDispatch();

  return (
    <>
      <div className="shop-and-drop-stepper">
        <div className="menubtn" style={{ padding: "20px" }}>
          <i
            className="pi pi-align-justify"
            style={{ fontSize: "1rem", color: "#ae9d81", cursor: "pointer" }}
            onClick={() =>
              dispatch(setNavVisibilty({ sideNavVisibility: true }))
            }
          ></i>
        </div>

        {/* <Stepper
          completedLabelColor="green" // Completed step label color
          incompleteLabelColor="red"
          styles={styles}
          orientation="vertical"
          steps={stepperDetails.stepperData}
          currentStepIndex={stepperDetails.selecetdStepperIndex}
          renderNode={(step, stepIndex) => (
            <div key={stepIndex}>
              {stepIndex <= stepperDetails.selecetdStepperIndex ? (
                <i className="pi pi-check" style={{}} onClick={() => {}}></i>
              ) : (
                step.LabelTitle
              )}
            </div>
          )}
          onStepClick={(e) => handleStepChange(e)}
        /> */}

        <div className="stepper-content">
          {stepperDetails.stepperData.map((val, index) => {
            return (
              <div className="stepper-box">
                <div className="stepper-header">
                  <div
                    // onClick={() => props.handleStepChange(val)}
                    className={`stepper-ball ${
                      val.isActive
                        ? "active-stepper-ball"
                        : val.completed
                        ? "completed-stepper-ball"
                        : ""
                    }`}
                  >
                    {val.completed && !val.isActive ? (
                      <i className="pi pi-check"></i>
                    ) : (
                      val.LabelTitle
                    )}
                  </div>
                  <p
                    // onClick={() => props.handleStepChange(val)}
                    className={`${
                      val.isActive
                        ? ""
                        : val.completed
                        ? "completed-stepper-text"
                        : ""
                    }`}
                  >
                    {val.stepLabel}
                  </p>
                </div>
                {index !== stepperDetails.stepperData.length - 1 && (
                  <div className="stepper-line-box">
                    <div
                      className={`stepper-line ${
                        val.completed ? "completed-stepper-line" : ""
                      }`}
                    ></div>
                  </div>
                )}
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default ShopAndDropSideNav;
