import { createSlice } from "@reduxjs/toolkit";
import { delivery, stepperNames } from "../Components/Common/Config";

export const stepsArrayData = [
  {
    stepLabel: stepperNames.guestInformation,
    completed: false,
    isActive: true,
    index: 0,
    LabelTitle: "01",
  },
  {
    stepLabel: stepperNames.bagInformation,
    completed: false,
    isActive: false,
    index: 1,
    LabelTitle: "02",
  },
  {
    stepLabel: stepperNames.checkIn,
    completed: false,
    isActive: false,
    index: 2,
    LabelTitle: "03",
  },
];

const initialState = {
  organizationList: [],
  deskList: [],
  userData: {
    email: "",
    name: "",
    roles: [],
  },
  feedbackList: [],
  chatList: [],
  feedbackCategoryList: [],
  feedbackSubCategoryList: [],
  sideNavVisibility: false,
  hireList: [],
  GuestInspectionsInfo: [],
  departmentList: [],
  roleList: [],
  lostList: [],
  stepperDetails: {
    selecetdStepperIndex: 0,
    selectedStepperValue: "Guest information",
    stepperData: [...stepsArrayData],
  },
  isStepperChange: false,
  isReceivedNotification:false,
  isConnctedToSocketHub: false,
};

const AppSlice = createSlice({
  name: "AppContext",
  initialState,
  reducers: {
    addOrganizationList(state, action) {
      state.organizationList = action.payload.organizationList;
    },
    clearState(state, action) {
      state.organizationList = [];
      state.deskList = [];
      state.organizationList = [];
      state.deskList = [];
      state.userData = {
        email: "",
        name: "",
        roles: [],
      };
      state.feedbackList = [];
      state.chatList = [];
      state.feedbackCategoryList = [];
      state.feedbackSubCategoryList = [];
      state.sideNavVisibility = false;
      state.hireList = [];
      state.GuestInspectionsInfo = [];
      state.departmentList = [];
      state.roleList = [];
    },
    setUserdata(state, action) {
      state.userData.email = action.payload.email;
      state.userData.name = action.payload.name;
      state.userData.roles = action.payload.roles;
    },
    setFeedbackList(state, action) {
      state.feedbackList = action.payload.feedbackList;
    },
    addDeskList(state, action) {
      state.deskList = action.payload.deskList;
    },
    setNavVisibilty(state, action) {
      state.sideNavVisibility = action.payload.sideNavVisibility;
    },
    addFeedbackCategoryList(state, action) {
      state.feedbackCategoryList = action.payload.feedbackCategoryList;
    },
    addFeedbackSubCategoryList(state, action) {
      state.feedbackSubCategoryList = action.payload.feedbackSubCategoryList;
    },
    setHireDetailsList(state, action) {
      state.hireList = action.payload.hireList;
    },
    setGuestInspectionsInfo(state, action) {
      state.GuestInspectionsInfo = action.payload.GuestInspectionsInfo;
    },
    addDepartmentListContext(state, action) {
      state.departmentList = action.payload.departmentList;
    },
    addRoleList(state, action) {
      state.roleList = action.payload.roleList;
    },
    // addChatList(state,action){
    //     state.chatList = action.payload.chatList
    // }
    setLostList(state, action) {
      state.lostList = action.payload.lostList;
    },
    setStepperDetails(state, action) {
      state.stepperDetails = { ...action.payload };
    },
    updateIsReceivedNotificationState(state,action){
      state.isReceivedNotification=action.payload;
    },
    updateConnectedSocketHubFlag(state, action) {
      state.isConnctedToSocketHub = action.payload;
    }
  },
});

export const {
  updateConnectedSocketHubFlag,
  addOrganizationList,
  clearState,
  setUserdata,
  setFeedbackList,
  addChatList,
  setNavVisibilty,
  addDeskList,
  addFeedbackSubCategoryList,
  setHireDetailsList,
  setGuestInspectionsInfo,
  addFeedbackCategoryList,
  addDepartmentListContext,
  addRoleList,
  setLostList,
  setStepperDetails,
  updateIsReceivedNotificationState,
} = AppSlice.actions;

export default AppSlice.reducer;
