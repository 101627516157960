import React, { useEffect, useState } from 'react';
import nationality from '../../assets/JSON/NationalityFlags.json';
import './CountryPicker.scss';
import { Dropdown } from 'primereact/dropdown';

function CountryPicker({ mobile, countryCode, handleChange, handleCountryChange }) {
    const [selectedCountry, setSelectedCountry] = useState({
        "english_name": "Bahrain",
        "arabic_name": "البحرين",
        "alpha2_code": "BH",
        "alpha3_code": "BHR",
        "phone_code": "+973",
        "flagImage": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BH.svg"
    });
    console.log(mobile, countryCode);
    const selectedCountryTemplate = (option, props) => {
        console.log(option);
        if (option) {
            return (
                <div className="flex align-items-center">
                    <img alt={option.flagImage} src={option?.flagImage} style={{ width: '18px' }} />
                </div>
            );
        }

        return <span>{props.placeholder}</span>;
    };

    const countryOptionTemplate = (option) => {
        return (
            <div className="flex align-items-center">
                <img alt={option.name} src={option?.flagImage} style={{ width: '18px' }} />
                <div>{option?.english_name}({option?.arabic_name}){option?.phone_code}</div>
            </div>
        );
    };
    useEffect(() => {
        console.log(countryCode);
        setSelectedCountry(nationality.filter(country => country.phone_code == countryCode)[0]);
    }, [])


    return (
        <div className='CountryPicker'>
            <Dropdown value={selectedCountry} style={{ width: "100px !important" }} onChange={(e) => { console.log(e.value); setSelectedCountry(e.value); handleCountryChange(e.value) }} options={nationality} optionLabel="english_name"
                valueTemplate={selectedCountryTemplate} itemTemplate={countryOptionTemplate}
            />
            <div style={{ width: "100%" }}>
                <input value={mobile} type='tel' min={0} placeholder='Enter here' onChange={(e) => { handleChange(e.target.value) }} ></input>
            </div>
        </div >
    )
}

export default CountryPicker
