import React from "react";
import { Route } from "react-router-dom";
import Dashboard from "../Components/Dashboard/Dashboard";
import GuestServiceSupervisorView from "../Components/GuestServiceSupervisorView/GuestServiceSupervisorView";
import AddLostForm from "../Components/AddLostForm/AddLostForm";
import ReturnForm from "../Components/ReturnForm/ReturnForm";
import EditLostItem from "../Components/EditLostItem/EditLostItem";
import GuestServiceStaffView from "../Components/GuestServiceStaffView/GuestServiceStaffView";
import EditFoundItem from "../Components/EditFoundItem/EditFoundItem";
import AddFoundForm from "../Components/AddFoundForm/AddFoundForm";
import AdminLostView from "../Components/AdminLostView/AdminLostView";
import AdminFoundView from "../Components/AdminFoundView/AdminFoundView";
import AdminReturnView from "../Components/AdminReturnView/AdminReturnView";
import OrganizationMaster from "../Components/Organization/OrganizationMaster";
import Desk from "../Components/Desk/Desk";
import Items from "../Components/Items/Items";
import GuestServiceStaff from "../Components/GuestServiceStaff/GuestServiceStaff";
import Feedback from "../Components/Feedback/Feedback";
import HireDetails from "../Components/HireDetails/HireDetails";
import Inspection from "../Components/Inspection/Inspection";
import InspectionViewDetails from "../Components/InspectionViewDetail/InspectionViewDetails";
import HireDetailsView from "../Components/HireDetailsView/HireDetailsView";
import UserManagementView from "../Components/UserManagementView/UserManagementView";
import FeedbackView from "../Components/FeedbackView/FeedbackView";
import DepartmentTeam from "../Components/DepartmentTeam/DepartmentTeam";
import DepartmentStaffView from "../Components/DepartmentStaffView/DepartmentStaffView";
import GuestStaffInspection from "../Components/GuestStaffInspection/GuestStaffInspection";
import MGTenentRelationship from "../Components/MGTenentRelationship/MGTenentRelationship";
import MGTenentRelationshipView from "../Components/MGTenentRelationshipView/MGTenentRelationshipView";
import UserManagement from "../Components/UserManagement/UserManagement";
import Role from "../Components/Role/Role";
import FeedbackCategory from "../Components/FeedbackCategory/FeedbackCategory";
import FeedbackSubCategory from "../Components/FeedbackSubCategory/FeedbackSubCategory";
import Tenant from "../Components/Tenant/Tenant";
import TenantCategory from "../Components/TenantCategory/TenantCategory";
import EmailConfiguration from "../Components/EmailConfiguration/EmailConfiguration";
import Nationality from "../Components/Nationality/Nationality";
import MallInspection from "../Components/MallInspection/MallInspection";
import CategoryConfiguration from "../Components/CategoryConfiguration/CategoryConfiguration";
import GuestServiceSupervisor from "../Components/GuestServiceSupervisor/GuestServiceSupervisor";
import GuessStaffInspectionView from "../Components/GuessStaffInspectionView/GuessStaffInspectionView";
import GuestStaffInspectionAdminView from "../Components/GuestStaffInspectionAdminView/GuestStaffInspectionAdminView";
import TenantDescription from "../Components/TenantDescription/TenantDescription";
import TenantQuestions from "../Components/TenantQuestions/TenantQuestions";
import LostAndFound from "../Components/LostAndFound/LostAndFound";
import AdminLost from "../Components/AdminLost/AdminLost";
import AdminFound from "../Components/AdminFound/AdminFound";
import AdminReturn from "../Components/AdminReturn/AdminReturn";
import StorageLocation from "../Components/StorageLocation/StorageLocation";
import LostAndFoundCategory from "../Components/LostAndFoundCategory/LostAndFoundCategory";
import SecurityStaff from "../Components/SecurityStaff/SecurityStaff";
import AdminLostAndFound from "../Components/AdminLostAndFound/AdminLostAndFound";
import FeedbackDepartmentCategory from "../Components/FeedbackDepartmentCategory/FeedbackDepartmentCategory";
import FeedbackForm from "../Components/FeedbackForm/FeedbackForm";
import Department from "../Components/Department/Department";
import ChangePassword from "../Components/ChangePassword/ChangePassword";

// VIP Supervisor
import UserList from "../Components/VIP Lounge/Supervisor/UserList/UserList";
import SupervisorInvoiceList from "../Components/VIP Lounge/Supervisor/InvoiceList/SupervisorInvoiceList";
import Reports from "../Components/VIP Lounge/Supervisor/Report/Reports";
// VIP Lounge Staff
import LoungeStaffInvoiceList from "../Components/VIP Lounge/LoungeStaff/LoungeStaffInvoiceList/LoungeStaffInvoiceList";
// Comman for Supervisor and Lounge Staff
import VIPSearch from "../Components/VIP Lounge/LoungeStaff/VipSearch/VIPSearch";
// Pantry staff
import Inventory from "../Components/VIP Lounge/PantryStaff/Inventory/Inventory";
import Purchase from "../Components/VIP Lounge/PantryStaff/Purchase/Purchase";
import UserRegistration from "../Components/VIP Lounge/Supervisor/UserRegistration/UserRegistration";
import PurchaseDetail from "../Components/VIP Lounge/Supervisor/PurchaseDetail/PurchaseDetail";
import InvoiceDetails from "../Components/VIP Lounge/Supervisor/InvoiceList/InvoiceDetails";
import Signature from "../Components/utili/signature";
import UserApproval from "../Components/VIP Lounge/Supervisor/UserApproval/UserApproval";
import ShopAndDropMainComponent from "../Components/ShopAndDrop/ShopAndDropMainComponent/ShopAndDropMainComponent";
import ShopAndDropMasterScreen from "../Components/ShopAndDrop/ShopAndDropMaster/ShopAndDropMasterScreen";
import TrackDeliveries from "../Components/ShopAndDrop/TrackDeliveries/TrackDeliveries";
import TrackDeliveryView from "../Components/ShopAndDrop/TrackDeliveries/TrackDelieveryView";
import AuthGuard from "./AuthGuard";
import Login from "../Components/Login/Login";
import ForgotPassword from "../Components/ResetPassword/ResetPassword";
import SubscriptionConfig from "../Components/VIP Lounge/Supervisor/SubscriptionConfig/SubscriptionConfig";
import InventoryBulkEdit from "../Components/VIP Lounge/PantryStaff/Inventory/InventoryBulkEdit";
import Guest from "../Components/ShopAndDrop/Guest/Guest";
import ChangePickup from "../Components/ShopAndDrop/ChangePickup/ChangePickup";
import GuestLogin from "../Components/ShopAndDrop/GuestLogin/GuestLogin";
import CheckIn from "../Components/ShopAndDrop/CheckIn/CheckIn";
import GuestInfoList from "../Components/ShopAndDrop/GuestInforMation/GuestInfoList";
import QRGeneration from "../Components/QRGeneration/QRGenerationMaster";
import SystemUser from "../Components/SystemUsers/SystemUser";
import VipMasterConfig from "../Components/VIPMasterConfig/VIPMasterConfig";
import CategoryMasterForPantry from "../Components/CategoryMasterForPantry/CategoryMasterForPantry";

const UrlRoutes = [
  // Common URL routes
  <Route
    key="ChangePassword"
    path="/changepassword"
    element={
      <AuthGuard
        role={[
          "ADMIN",
          "GUESTSERVICESTAFF",
          "DEPARTMENTTEAM",
          "MGTENANTRELATIONSHIP",
          "GUESTSTAFFSUPERVISOR",
          "GSR",
          "SECURITYSTAFF",
          "VIP_SUPERVISOR",
          "LOUNGE_STAFF",
          "PANTRY_STAFF",
          "SAD_DELIVERY_PARTNER",
          "HF_STORE",
          "HF_STORE_KEEPER"
        ]}
        authorized={true}
        ismultiple={true}
        component={<ChangePassword />}
      >
        {" "}
      </AuthGuard>
    }
  ></Route>,
  // Common URL routes

  // UnAuthorized URL Routes
  <Route
    key="Login"
    path="/"
    element={<AuthGuard component={<Login />} authorized={false} />}
  ></Route>,
  <Route
    key="Login"
    path="/login"
    element={<AuthGuard component={<Login />} authorized={false} />}
  ></Route>,
  <Route
    key="ForgetPassword"
    path="/forgotpassword"
    element={<AuthGuard component={<ForgotPassword />} authorized={false} />}
  >
    {" "}
  </Route>,
  <Route
    key="Not Found"
    path="*"
    element={
      <AuthGuard component={<Login />} authorized={false} invalid={true} />
    }
    replace
  />,
  // UnAuthorized URL Routes end

  // Admin routes start
  <Route
    key="Dashboard"
    path="/dashboard"
    element={
      <AuthGuard component={<Dashboard />} role="ADMIN" authorized={true} />
    }
  />,
  <Route
    key="Organization"
    path="/organization"
    element={
      <AuthGuard
        component={<OrganizationMaster />}
        role="ADMIN"
        authorized={true}
      ></AuthGuard>
    }
  ></Route>,
  <Route
    key="Organization"
    path="/QRGeneration"
    element={
      <AuthGuard
        component={<QRGeneration />}
        role="ADMIN"
        authorized={true}
      ></AuthGuard>
    }
  ></Route>,
  <Route
    key="Desk"
    path="/desk"
    element={
      <AuthGuard
        component={<Desk />}
        role="ADMIN"
        authorized={true}
      ></AuthGuard>
    }
  ></Route>,
  <Route
    key="Items"
    path="/items"
    element={
      <AuthGuard
        component={<Items />}
        role="ADMIN"
        authorized={true}
      ></AuthGuard>
    }
  ></Route>,
  <Route
    key="role"
    path="/role"
    element={<AuthGuard component={<Role />} role="ADMIN" authorized={true} />}
  ></Route>,
  <Route
    key="feedbackcategory"
    path="/feedbackcategory"
    element={
      <AuthGuard
        component={<FeedbackCategory />}
        role="ADMIN"
        authorized={true}
      />
    }
  ></Route>,
  <Route
    key="feedbacksubcategory"
    path="/feedbacksubcategory"
    element={
      <AuthGuard
        component={<FeedbackSubCategory />}
        role="ADMIN"
        authorized={true}
      />
    }
  ></Route>,
  <Route
    key="tenant"
    path="/tenant"
    element={
      <AuthGuard component={<Tenant />} role="ADMIN" authorized={true} />
    }
  ></Route>,
  <Route
    key="tenant-category"
    path="/tenant-category"
    element={
      <AuthGuard
        component={<TenantCategory />}
        role="ADMIN"
        authorized={true}
      />
    }
  ></Route>,
  <Route
    key="department"
    path="/department"
    element={
      <AuthGuard component={<Department />} role="ADMIN" authorized={true} />
    }
  ></Route>,
  <Route
    key="categoryconfiguration"
    path="/categoryconfiguration"
    element={
      <AuthGuard
        component={<CategoryConfiguration />}
        role="ADMIN"
        authorized={true}
      />
    }
  ></Route>,
  <Route
    key="storage"
    path="/storage"
    element={
      <AuthGuard
        role="ADMIN"
        authorized={true}
        component={<StorageLocation />}
      />
    }
  ></Route>,
  <Route
    key="lostandfoundcategory"
    path="/lostandfoundcategory"
    element={
      <AuthGuard
        role="ADMIN"
        authorized={true}
        component={<LostAndFoundCategory />}
      />
    }
  ></Route>,
  <Route
    key="tenantdescription"
    path="/tenantdescription"
    element={
      <AuthGuard
        role="ADMIN"
        authorized={true}
        component={<TenantDescription />}
      />
    }
  ></Route>,
  <Route
    key="tenantquestions"
    path="/tenantquestions"
    element={
      <AuthGuard
        role="ADMIN"
        authorized={true}
        component={<TenantQuestions />}
      />
    }
  ></Route>,
  <Route
    key="email-configuration"
    path="/email-configuration"
    element={
      <AuthGuard
        role="ADMIN"
        authorized={true}
        component={<EmailConfiguration />}
      />
    }
  ></Route>,
  <Route
    key="feedback-department-category"
    path="/feedback-department-category"
    element={
      <AuthGuard
        role="ADMIN"
        authorized={true}
        component={<FeedbackDepartmentCategory />}
      />
    }
  ></Route>,
  <Route
    key="FeedbackView"
    path="/feedbackview/:id"
    element={
      <AuthGuard role="ADMIN" authorized={true} component={<FeedbackView />} />
    }
  ></Route>,
  <Route
    key="usermanagement"
    path="/usermanagement"
    element={
      <AuthGuard
        role="ADMIN"
        authorized={true}
        component={<UserManagement />}
      />
    }
  ></Route>,
  <Route
    key="UserManagementView"
    path="/usermanagementview"
    element={
      <AuthGuard
        role="ADMIN"
        authorized={true}
        component={<UserManagementView />}
      />
    }
  ></Route>,
  <Route
    key="FeedbackDetails"
    path="/feedbackdetails"
    element={
      <AuthGuard role="ADMIN" authorized={true} component={<Feedback />} />
    }
  ></Route>,
  <Route
    key="Inspection"
    path="/inspection"
    element={
      <AuthGuard role="ADMIN" authorized={true} component={<Inspection />} />
    }
  ></Route>,
  <Route
    key="GuestStaffInspection"
    path="/gueststaffinspection"
    element={
      <AuthGuard
        role="ADMIN"
        authorized={true}
        component={<GuestStaffInspection />}
      />
    }
  ></Route>,
  <Route
    key="admin-lost-found"
    path="/admin-lost-found/:tab"
    element={
      <AuthGuard
        role="ADMIN"
        authorized={true}
        component={<AdminLostAndFound />}
      />
    }
  ></Route>,
  <Route
    key="AdminLostView"
    path="/adminlostview/:id"
    element={
      <AuthGuard
        role="ADMIN"
        authorized={true}
        component={<AdminLostView />}
      ></AuthGuard>
    }
  ></Route>,
  <Route
    key="AdminFoundView"
    path="/adminfoundview/:id"
    element={
      <AuthGuard
        role="ADMIN"
        authorized={true}
        component={<AdminFoundView />}
      ></AuthGuard>
    }
  ></Route>,
  <Route
    key="AdminReturnView"
    path="/adminreturnview/:id"
    element={
      <AuthGuard
        role="ADMIN"
        authorized={true}
        component={<AdminReturnView />}
      ></AuthGuard>
    }
  ></Route>,
  // <Route
  //   key="HireDetails"
  //   path="/hire-details"
  //   element={
  //     <AuthGuard role="ADMIN" authorized={true} component={<HireDetails />} />
  //   }
  // ></Route>,
  <Route
    key="gueststaffinspectionadminview"
    path="/gueststaffinspectionadminview/:id"
    element={
      <AuthGuard
        role="ADMIN"
        authorized={true}
        component={<GuestStaffInspectionAdminView />}
      />
    }
  ></Route>,
  <Route
    key="InspectionViewDetails"
    path="/inspectionviewdetails/:inspectionId"
    element={
      <AuthGuard
        role="ADMIN"
        authorized={true}
        component={<InspectionViewDetails />}
      />
    }
  ></Route>,
  <Route
    key="HireDetailsView"
    path="/admin-hire-details-view/:id"
    element={
      <AuthGuard
        role={["ADMIN"]}
        authorized={true}
        ismultiple={true}
        component={<HireDetailsView />}
      />
    }
  ></Route>,
  // Admin routes end

  // GuestServiceStaff
  <Route
    key="GuestServiceStaff"
    path="/guestservicestaff"
    element={
      <AuthGuard
        role="GUESTSERVICESTAFF"
        authorized={true}
        component={<GuestServiceStaff />}
      />
    }
  ></Route>,
  <Route
    key="guestservicestaffview"
    path="/guestservicestaffview/:id"
    element={
      <AuthGuard
        role="GUESTSERVICESTAFF"
        authorized={true}
        component={<GuestServiceStaffView />}
      ></AuthGuard>
    }
  ></Route>,
  <Route
    key="feedback-form"
    path="/feedback-form"
    element={
      <AuthGuard
        role="GUESTSERVICESTAFF"
        authorized={true}
        component={<FeedbackForm />}
      />
    }
  ></Route>,

  // GuestServiceStaff routes end

  // DepartmentTeam routes start
  <Route
    key="DepartmentTeam"
    path="/departmentteam/:tab"
    element={
      <AuthGuard
        role="DEPARTMENTTEAM"
        authorized={true}
        component={<DepartmentTeam />}
      />
    }
  ></Route>,
  <Route
    key="DepartmentStaffView"
    path="/departmentstaffview/:id"
    element={
      <AuthGuard
        role="DEPARTMENTTEAM"
        authorized={true}
        component={<DepartmentStaffView />}
      />
    }
  ></Route>,
  <Route
    key="gueststaffinspectionview"
    path="/gueststaffinspectionview/:id"
    element={
      <AuthGuard
        role="DEPARTMENTTEAM"
        authorized={true}
        component={<GuessStaffInspectionView />}
      />
    }
  ></Route>,
  // DepartmentTeam routes end

  // MGTenantRelationship routes end
  <Route
    key="MGTenantRelationship"
    path="/mgtenentrelationship"
    element={
      <AuthGuard
        role="MGTENANTRELATIONSHIP"
        authorized={true}
        component={<MGTenentRelationship />}
      />
    }
  ></Route>,
  <Route
    key="MGTenantRelationshipView"
    path="/mgtenentrelationshipview/:id"
    element={
      <AuthGuard
        role="MGTENANTRELATIONSHIP"
        authorized={true}
        component={<MGTenentRelationshipView />}
      />
    }
  ></Route>,
  // MGTenantRelationship routes end

  // MGTenantRelationship routes end
  <Route
    key="guestservicesupervisor"
    path="/guestservicesupervisor"
    element={
      <AuthGuard
        role="GUESTSTAFFSUPERVISOR"
        authorized={true}
        component={<GuestServiceSupervisor />}
      />
    }
  ></Route>,
  <Route
    key="guestservicesupervisorview"
    path="/guestservicesupervisorview/:id"
    element={
      <AuthGuard
        role="GUESTSTAFFSUPERVISOR"
        authorized={true}
        component={<GuestServiceSupervisorView />}
      ></AuthGuard>
    }
  ></Route>,
  <Route
    key="HireDetails"
    path="/hire-details"
    element={
      <AuthGuard
        role={["GUESTSTAFFSUPERVISOR", "ADMIN"]}
        authorized={true}
        ismultiple={true}
        component={<HireDetails />}
      />
    }
  ></Route>,
  <Route
    key="HireDetailsView"
    path="/hire-details-view/:id"
    element={
      <AuthGuard
        role="GUESTSTAFFSUPERVISOR"
        authorized={true}
        component={<HireDetailsView />}
      />
    }
  ></Route>,
  // MGTenantRelationship routes end

  // GSR routes end
  <Route
    key="lostandfound"
    path="/lostandfound/:tab"
    element={
      <AuthGuard role="GSR" authorized={true} component={<LostAndFound />} />
    }
  ></Route>,
  <Route
    key="EditLostForm"
    path="/editlostform/:id/:route"
    element={
      <AuthGuard
        role="GSR"
        authorized={true}
        component={<AddLostForm />}
      ></AuthGuard>
    }
  ></Route>,
  <Route
    key="AddLostForm"
    path="/addlostform/:route"
    element={
      <AuthGuard
        role="GSR"
        authorized={true}
        component={<AddLostForm />}
      ></AuthGuard>
    }
  ></Route>,
  <Route
    key="EditLostItem"
    path="/editlostitem/:id/:route"
    element={
      <AuthGuard
        role={["GSR", "SECURITYSTAFF"]}
        authorized={true}
        ismultiple={true}
        component={<EditLostItem />}
      ></AuthGuard>
    }
  ></Route>,
  <Route
    key="ReturnForm"
    path="/returnform/:id/:route"
    element={
      <AuthGuard
        role="SECURITYSTAFF"
        authorized={true}
        component={<ReturnForm />}
      ></AuthGuard>
    }
  ></Route>,
  // GSR routes end

  // Security  routes end
  <Route
    key="security-staff"
    path="/security-staff/:tab"
    element={
      <AuthGuard
        role="SECURITYSTAFF"
        authorized={true}
        component={<SecurityStaff />}
      />
    }
  ></Route>,
  <Route
    key="AddFoundItem"
    path="/addfoundform/:route"
    element={
      <AuthGuard
        role="SECURITYSTAFF"
        authorized={true}
        component={<AddFoundForm />}
      ></AuthGuard>
    }
  ></Route>,
  <Route
    key="EditFoundItem"
    path="/editfounditem/:id/:route"
    element={
      <AuthGuard
        role="SECURITYSTAFF"
        authorized={true}
        component={<EditFoundItem />}
      ></AuthGuard>
    }
  ></Route>,
  <Route
    key="lounge-supervisor-invoices"
    path="/lounge-supervisor-invoices"
    element={
      <AuthGuard
        role="VIP_SUPERVISOR"
        authorized={true}
        component={<SupervisorInvoiceList />}
      />
    }
  ></Route>,
  <Route
    key="view-vip-user-details"
    path="/view-vip-user-details/:id/:isApproveScreen"
    element={
      <AuthGuard
        role={["VIP_SUPERVISOR", "LOUNGE_STAFF"]}
        authorized={true}
        ismultiple={true}
        component={<UserRegistration />}
      />
    }
  ></Route>,
  <Route
    key="supervisor-invoices-report"
    path="/supervisor-invoices-report"
    element={
      <AuthGuard
        role="VIP_SUPERVISOR"
        authorized={true}
        component={<Reports />}
      />
    }
  ></Route>,
  <Route
    key="supervisor-pantry-report"
    path="/supervisor-pantry-report"
    element={
      <AuthGuard
        role="VIP_SUPERVISOR"
        authorized={true}
        component={<Reports />}
      />
    }
  ></Route>,
  <Route
    key="supervisor-purchase-details"
    path="/supervisor-purchase-details"
    element={
      <AuthGuard
        role="VIP_SUPERVISOR"
        authorized={true}
        component={<PurchaseDetail />}
      />
    }
  ></Route>,
  <Route
    key="supervisor-subscription-config"
    path="/supervisor-subscription-config"
    element={
      <AuthGuard
        // role="VIP_SUPERVISOR"
        role="ADMIN"
        authorized={true}
        component={<SubscriptionConfig />}
      />
    }
  ></Route>,
  <Route
  key="vip-master-config"
  path="/vip-master-config"
  element={
    <AuthGuard
      // role="VIP_SUPERVISOR"
      role="ADMIN"
      authorized={true}
      component={<VipMasterConfig />}
    />
  }
></Route>,
 <Route
 key="category-master-config"
 path="/category-master-config"
 element={
   <AuthGuard
     // role="VIP_SUPERVISOR"
     role="ADMIN"
     authorized={true}
     component={< CategoryMasterForPantry/>}
   />
 }
></Route>,
  // Lounge Staff
  <Route
    key="lounge-staff-invoices"
    path="/lounge-staff-invoices"
    element={
      <AuthGuard
        role="LOUNGE_STAFF"
        authorized={true}
        component={<LoungeStaffInvoiceList />}
      />
    }
  ></Route>,
  <Route
    key="pantry-staff-inventory"
    path="/pantry-staff-inventory"
    element={
      <AuthGuard
        role="PANTRY_STAFF"
        authorized={true}
        component={<Inventory />}
      />
    }
  ></Route>,
  <Route
    key="pantry-staff-inventory-bulk-edit"
    path="/pantry-staff-inventory-bulk-edit"
    element={
      <AuthGuard
        role="PANTRY_STAFF"
        authorized={true}
        component={<InventoryBulkEdit />}
      />
    }
  ></Route>,
  <Route
    key="pantry-purchase"
    path="/pantry-purchase"
    element={
      <AuthGuard
        role="PANTRY_STAFF"
        authorized={true}
        component={<Purchase />}
      />
    }
  ></Route>,

  // Lounge Supervisor
  <Route
    key="user-list"
    path="/vip-user-list"
    element={
      <AuthGuard
        role={["VIP_SUPERVISOR", "LOUNGE_STAFF"]}
        authorized={true}
        ismultiple={true}
        component={<UserList />}
      />
    }
  ></Route>,
  <Route
    key="search-vip-user"
    path="/search-vip-user"
    element={
      <AuthGuard
        role={["VIP_SUPERVISOR", "LOUNGE_STAFF"]}
        authorized={true}
        ismultiple={true}
        component={<VIPSearch />}
      />
    }
  ></Route>,
  <Route
    key="vip-user-registration"
    path="/vip-user-registration"
    element={
      <AuthGuard
        role={["VIP_SUPERVISOR", "LOUNGE_STAFF"]}
        authorized={true}
        ismultiple={true}
        component={<UserRegistration />}
      />
    }
  ></Route>,
  <Route
    key="shop-and-drop-master"
    path="/shop-and-drop-master"
    element={
      <AuthGuard
        role="ADMIN"
        authorized={true}
        component={<ShopAndDropMasterScreen />}
      />
    }
  ></Route>,
  <Route
    key="invoice-details"
    path="/invoice-details/:id/:isSupervisor"
    element={
      <AuthGuard
        role={["VIP_SUPERVISOR"]}
        authorized={true}
        ismultiple={true}
        component={<InvoiceDetails />}
      />
    }
  ></Route>,
  <Route
    key="Signature-pad"
    path="/Signature-pad"
    element={
      <AuthGuard
        role="SECURITYSTAFF"
        authorized={true}
        component={<Signature />}
      />
    }
  ></Route>,
  <Route
    key="supervisor-user-approval"
    path="/supervisor-user-approval"
    element={
      <AuthGuard
        role="VIP_SUPERVISOR"
        authorized={true}
        component={<UserApproval />}
      />
    }
  ></Route>,
  <Route
    key="shop-and-drop"
    path="/shop-and-drop"
    element={
      <AuthGuard
        role={["GUESTSERVICESTAFF", "SAD_DELIVERY_PARTNER", "HF_STORE", "HF_STORE_KEEPER"]}
        authorized={true}
        ismultiple={true}
        component={<ShopAndDropMainComponent />}
      />
    }
  ></Route>,
  <Route
    key="shop-and-drop-track-deliveries"
    path="/shop-and-drop-track-deliveries"
    element={
      <AuthGuard
        role={["GUESTSERVICESTAFF", "SAD_DELIVERY_PARTNER", "HF_STORE", "HF_STORE_KEEPER"]}
        authorized={true}
        ismultiple={true}
        component={<TrackDeliveries />}
      />
    }
  ></Route>,

  <Route
    key="invoice-details"
    path="/invoice-details"
    element={
      <AuthGuard
        role={["VIP_SUPERVISOR"]}
        authorized={true}
        ismultiple={true}
        component={<InvoiceDetails />}
      />
    }
  ></Route>,
  <Route
    key="shop-and-drop-track-delivery-view"
    path="/shop-and-drop-track-delivery-view/:id/:isInsidemall"
    element={
      <AuthGuard
        role={["SAD_DELIVERY_PARTNER", "HF_STORE", "GUESTSERVICESTAFF", "HF_STORE_KEEPER"]}
        authorized={true}
        ismultiple={true}
        component={<TrackDeliveryView />}
      />
    }
  ></Route>,
  <Route
    key="guest-info"
    path="/guest-info"
    element={
      <AuthGuard
        role={[""]}
        authorized={false}
        ismultiple={false}
        component={<Guest />}
      />
    }
  ></Route>,
  <Route
    key="change-pickup"
    path="/change-pickup"
    element={
      <AuthGuard
        role={[""]}
        authorized={false}
        ismultiple={false}
        component={<ChangePickup />}
      />
    }
  ></Route>,
  <Route
    key="guest-login"
    path="/guest-login"
    element={
      <AuthGuard
        role={[""]}
        authorized={false}
        ismultiple={false}
        component={<GuestLogin />}
      />
    }
  ></Route>,
  <Route
    key="Check-In"
    path="/checkin-request"
    element={
      <AuthGuard
        role={["GUESTSERVICESTAFF", "DESKUSER", "HF_STORE", "SAD_DELIVERY_PARTNER", "HF_STORE_KEEPER"]}
        authorized={true}
        ismultiple={true}
        component={<GuestInfoList />}
      />
    }
  ></Route>,
  <Route
    key="system-user"
    path="/system-user"
    element={
      <AuthGuard
        role={["ADMIN"]}
        authorized={true}
        ismultiple={true}
        component={<SystemUser />}
      />
    }
  ></Route>
  , <Route
  key="vip-master-config"
  path="/vip-master-config"
  element={
    <AuthGuard
      role={["ADMIN"]}
      authorized={true}
      ismultiple={true}
      component={<VipMasterConfig />}
    />
  }
></Route>,
 <Route
 key="category-master-config"
 path="/category-master-config"
 element={
   <AuthGuard
     role={["ADMIN"]}
     authorized={true}
     ismultiple={true}
     component={<CategoryMasterForPantry />}
   />
 }
></Route>
  
];
export default UrlRoutes;
