import Axios from "../Helpers/Axios";

export function GetAllDescriptions(take, skip, token) {
    return Axios(`TenantDescription/GetAllDescriptions?skip=${skip}&take=${take}`, "get", null, token)
}
export function EditDescription(data, token) {
    return Axios(`TenantDescription/EditDescription`, "patch", data, token)
}
export function AddDescription(data, token) {
    return Axios(`TenantDescription/AddDescription`, "post", data, token)
}