import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Paginator } from "primereact/paginator";
import { useEffect, useState } from "react";
import Axios from "../../Helpers/Axios";
import { GetAllSystemUsers } from "../../Services/UserManagementService";
import { useCookies } from "react-cookie";
import { useDispatch } from "react-redux";
import { setNavVisibilty } from "../../Redux/AppSlice";
import { MultiSelect } from "primereact/multiselect";
import { asyncThunkCreator } from "@reduxjs/toolkit";
import { GetRolesList } from "../../Services/RoleService";
import { Button } from "primereact/button";
import { exportDataInExcel } from "../../Services/ExportServices";


const SystemUser = () => {

  const dispatch = useDispatch();
  const externalRoles = [
    { isDeleted: false, id: 0, roleName: 'SAD Guest', roleLabel: 'SAD Guest' },
    { isDeleted: false, id: 0, roleName: 'Anonymous feedback user', roleLabel: 'Anonymous feedback user' },
    { isDeleted: false, id: 0, roleName: 'Hired User', roleLabel: 'Hired User' },
    { isDeleted: false, id: 0, roleName: 'VIP user', roleLabel: 'VIP user' },
  ];
  const [systemUserObj, setSystemUserObj] = useState({
    selectedRoles: null,
    rolesList: [],
    tableData: [],
    data: [],
  });
  const [cookies, setCookie, removeCookie] = useCookies();
  const cities = [
    { name: 'New York', code: 'NY' },
    { name: 'Rome', code: 'RM' },
    { name: 'London', code: 'LDN' },
    { name: 'Istanbul', code: 'IST' },
    { name: 'Paris', code: 'PRS' }
  ];

  const assignValue = (key, value) => {
    {
      setSystemUserObj((prev) => ({ ...prev, [key]: value }));
    }
  }

  const getAllUser = async () => {
    await GetAllSystemUsers(cookies?.bearerToken).then(res => {
      if (res?.status == 200) {
        assignValue("data", res?.data);
        assignValue("tableData", res?.data);
      }
    })
  }
  const getAllRoles = async () => {
    await GetRolesList(cookies?.bearerToken).then((res) => {
      if (res?.status == 200 && res?.data?.length > 0) {
        let activeRoles = res?.data?.filter(_ => _?.isDeleted == false);

        setSystemUserObj(prev => ({ ...prev, rolesList: [...activeRoles, ...externalRoles] }))
      }
    })
  }

  const filterRecords = (selectedRoles) => {
    const filteredRecords = systemUserObj?.data?.filter(userObj =>
      userObj?.roles?.split(',').map(role => role.trim()).some(role =>
        selectedRoles.some(roleObj => role === roleObj.roleName)
      )
    );
    assignValue("tableData", filteredRecords?.length ? filteredRecords : systemUserObj?.data);
  }

  useEffect(() => {
    getAllUser();
    getAllRoles();
  }, [])
  return (
    <div>
      <div className="header  flex align-items-center justify-content-between" >
        <div style={{ display: "flex" }} className="align-items-center">
          <div className="menubtn" style={{ padding: "10px 10px 5px 20px" }}>
            <i
              className="pi pi-arrow-circle-left nav-back-btn"
              style={{
                cursor: "pointer",
              }}
              onClick={() =>
                dispatch(setNavVisibilty({ sideNavVisibility: true }))
              }
            ></i>
          </div>
          <div className="heading">
            <p className="configuration">System User</p>
          </div>
        </div>
        <div className="card flex justify-content-end mr-2">
        <div className="mx-2">
           <h4 className='mb-1 mt-0 relative' style={{color:"#ae9d81"}}>Roles</h4>
          <MultiSelect value={systemUserObj?.selectedRoles} onChange={(e) => {
              filterRecords(e.value)
              assignValue("selectedRoles", e.value)
            }
            } options={systemUserObj?.rolesList} optionLabel="roleName"
              placeholder="Select" maxSelectedLabels={30} className="w-full md:w-20rem" selectAllLabel="Select all roles" selectedItemsLabel={`${systemUserObj?.selectedRoles?.length} roles selected`} />
         </div>
         <div className="mx-2 align-self-end">
           <Button label='Export' icon={"pi pi-file-export"} style={{ backgroundColor: 'transparent', color: '#ae9d81', border: '1px #ae9d81 solid', borderRadius: '20px', marginRight: '20px' }} onClick={exportAllSystemUsers} />
         </div>
        
        </div>

      </div>

      <div className="col-12 px-2">
        <DataTable
          value={systemUserObj?.tableData}
          tableStyle={{ minWidth: "50rem" }}
          emptyMessage="No data found"
          paginator rows={10}
        >
          <Column
            style={{ width: "10%" }}
            field="firstName"
            header="Name"
          ></Column>
          <Column
            style={{ width: "10%" }}
            field="email"
            header="Email"
          ></Column>
          <Column
            style={{ width: "10%" }}
            field="mobileNumber"
            header="Mobile Number"
          ></Column>
          <Column
            style={{ width: "10%" }}
            field="birthDate"
            body={DateBodytemplate}
            header="Date of Birth"
          ></Column>
          <Column
            style={{ width: "20%" }}
            field="nationality"
            header="Nationality"
          ></Column>
          <Column
            style={{ width: "40%" }}
            field="roles"
            header="Role"
          ></Column>
        </DataTable>
      </div>
    </div>
  )
}
export default SystemUser;


const DateBodytemplate = (row) => {
  if (row?.birthDate == null) {
    return (
      <div>
        <p style={{ fontSize: '1rem', fontWeight: 400 }}>-</p>
      </div>
    )

  };
  return (
    <div>
      <p style={{ fontSize: '1rem', fontWeight: 400 }}>{new Date(row.birthDate).toLocaleDateString()}</p>
    </div>
  );
};


const exportAllSystemUsers = () => {
  exportDataInExcel('ExportAllSystemUsers');
};