import Axios from "../Helpers/Axios";

export function GetSubcription(token, searchTerm, skip, take) {
  return Axios(
    `VIPLounge/VIPLoungeMaster/GetSubscriptions?searchTerm=${searchTerm}&skip=${skip}&take=${take}
    `,
    "get",
    null,
    token
  );
}

export function GetActiveOrganizations(token) {
  return Axios(
    `Master/ActiveOrganization
    `,
    "get",
    null,
    token
  );
}

export function AddSubcription(data, token) {
  return Axios(
    `VIPLounge/VIPLoungeMaster/AddSubscription`,
    "post",
    data,
    token
  );
}

export function EditSubcription(data, token) {
  return Axios(
    `VIPLounge/VIPLoungeMaster/EditSubscription`,
    "patch",
    data,
    token
  );
}

export function GetPlateCountry(token) {
  return Axios(
    `VIPLounge/VIPLoungeMaster/GetPlateCountry`,
    "get",
    null,
    token
  );
}
export function AddPlateCountry(data, token) {
  return Axios(
    `VIPLounge/VIPLoungeMaster/AddPlateCountry`,
    "post",
    data,
    token
  );
}
export function EditPlateCountry(data, token) {
  return Axios(
    `VIPLounge/VIPLoungeMaster/EditPlateCountry`,
    "post",
    data,
    token
  );
}
export function GetVehicleBrands(token) {
  return Axios(
    `VIPLounge/VIPLoungeMaster/GetVehicleBrand`,
    "get",
    null,
    token
  );
}
export function AddVehicleBrands(data, token) {
  return Axios(
    `VIPLounge/VIPLoungeMaster/AddVehicleBrand`,
    "post",
    data,
    token
  );
}
export function EditVehicleBrand(data, token) {
  return Axios(
    `VIPLounge/VIPLoungeMaster/EditVehicleBrand`,
    "post",
    data,
    token
  );
}