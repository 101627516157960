import Axios from "../Helpers/Axios";

export function GetTenantList(skip, take, token,searchTerm,status,showAll) {
    return Axios(`Tenant/GetTenantInformations?skip=${skip}&take=${take}&searchTerm=${searchTerm}${status?`&status=${status}&showAll=${showAll}`:""}&forExport=false`, "get", null, token)
}
export function GetTenantListBySearch(skip, take, searchValue, token) {
    return Axios(`Tenant/GetTenantInformations?skip=${skip}&take=${take}&searchTerm=${searchValue}`, "get", null, token)
}
export function AddTenant(data, token) {
    return Axios(`Tenant/CreateTenant`, "post", data, token)
}
export function EditTenant(data, token) {
    return Axios(`Tenant/EditTenant`, "patch", data, token)
}
export function ImportTenant(data, token) {
    return Axios(`Excel/BulkImportTenant`, "post", data, token, true)
}
export function GetTenantsList(token){
    return Axios(`Master/Tenant`,"get",null,token)      
}
