import React, { useState, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import './style.scss';

// -------------Imports---------------
import { FilterMatchMode } from 'primereact/api';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Tag } from 'primereact/tag';
import { Dropdown } from 'primereact/dropdown';
import { Dialog } from 'primereact/dialog';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

import { useNavigate } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify'
import { Paginator } from 'primereact/paginator';
import deskcsv from '../../assets/files/Desk.csv';


// -----------------------------------


// ----------Assets Import------------
import editIcon from '../../../src/assets/images/editIcon.jpg';
// -----------------------------------


//------Service imports-----
import { GetActiveOrganizationList, GetOrganizationList } from '../../Services/OrganizationService';
import { AddDesk, GetDeskList, EditDesk, DeleteDesk } from '../../Services/DeskService';

//----------------------------------------


//-----------Context ---------------
import { useDispatch, useSelector } from "react-redux";
import { addDeskList, addOrganizationList, setNavVisibilty } from '../../Redux/AppSlice';
import { exportDataInExcel, exportExcel, exportFilterdExcel, isShow } from '../../Services/ExportServices';
import { FileUpload } from 'primereact/fileupload';
import { ImportDesk, ImportItems } from '../../Services/ItemsService';
import Modalinfo from '../utili/Modalinfo';
import ExportFilter from '../ExportFilter/ExportFilter';
import { Calendar } from 'primereact/calendar';
import { MultiSelect } from 'primereact/multiselect';
//----------------------------------------

function Desk() {
    const [cookies, setCookie, removeCookie] = useCookies();
    const Context = useSelector((state) => state.AppContext);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [deskList, setDeskList] = useState([]);
    const [organizationList, setOrganizationList] = useState("");
    const [isAddDesk, setIsAddDesk] = useState(false);
    const [isAdd, setIsAdd] = useState(false);
    const [desk, setDesk] = useState({
        deskName: "", deskStatus: true, orgId: "", deskId: ""
    });
    const [isImportInvalid, setIsImportInvalid] = useState(false);
    const [exportFilterDialog, setExportFilterDialog] = useState(false);
    const [deskNameExists, setDeskNameExists] = useState(false);
    const [isInvalid, setIsInvalid] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [isImport, setIsImport] = useState(false);
    const [isImportInvalidFile, setIsImportInvalidFile] = useState(false);
    const [globalFilterValue, setGlobalFilterValue] = useState('');
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [isFileValidating, setFileValidating] = useState(false);
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        status: { value: null, matchMode: FilterMatchMode.EQUALS }
    });
    const [filterObj, setFilterObj] = useState({
        status: "",
        organization: ""
    });

    // Pagination
    const [first, setFirst] = useState(0)
    const [totalCount, setTotalRecords] = useState(0)

    const onGlobalFilterChange = (e) => {
        const value = e ? e.target.value : "";
        setGlobalFilterValue(value);
        setFirst(0);
        getDeskList(0, 10, value, filterObj?.organization, filterObj?.status);
    }
    const onCancelClick = () => {
        setShowConfirmation(true);
    };

    const onHide = () => {
        setShowConfirmation(false);
        setIsAddDesk(false);
        setDesk({
            deskName: "", deskStatus: true, orgId: "", deskId: ""
        });
    };

    const handleConfirmation = (confirmed) => {
        if (confirmed) {
            onHide();
        } else {
            setShowConfirmation(false);
        }
    };

    const statusBodyTemplate = ((row) => {
        // //console.log(row);
        if (row.isDeleted === false) {
            return <p style={{ color: "green" }}  >Active</p>
        }
        else {
            return <p style={{ color: "red" }} >Inactive</p>
        }
    })

    const actionButtonTemplate = (row) => {
        // //console.log("Desk Value", row);
        // //console.log("FIltered Org", filteredOrg);
        return (
            <div onClick={(e) => {
                setIsAddDesk(true);
                setIsAdd(false);
                let filteredOrg = organizationList.filter((org) => row.organizationId == org.id)
                setDesk((prev) => ({
                    ...prev,
                    deskName: row?.deskName,
                    deskStatus: !row?.isDeleted,
                    orgId: filteredOrg[0],
                    deskId: row?.id
                }))
            }}>
                <img src={editIcon} style={{ cursor: 'pointer' }} />
            </div>
        );
    };

    const getDeskList = ((skip = 0, take = 10, searchValue = "", orgId = "", status = "") => {
        setIsLoading(true);
        console.log(skip, take, searchValue, orgId, status, "statusstatusstatus");

        GetDeskList(take, skip, cookies.bearerToken, searchValue, orgId, status)
            .then((res) => {
                // //console.log(res);
                if (res.status === 200) {
                    setDeskList(res?.data?.data);
                    setTotalRecords(res?.data?.count);
                }
            })
            .catch((error) => {
                //console.log(error);
            })
            .finally(() => {
                setIsLoading(false);
            })
    })
    const onUpload = async (e) => {
        //console.log(e?.files[0]);
        setFileValidating(true);
        if (e.files.length == 0) {
            setIsImportInvalid(true);
        }
        setIsImportInvalid(false);
        // if (e.files[0].name !== "Item.csv") {
        //     // alert("Please upload Tenantcsv file")
        //     setIsImportInvalidFile(true);
        //     e.files.pop()
        // }
        // else {
        setIsImportInvalidFile(false);
        const formData = new FormData();
        formData.append('file', e.files[0]);
        await ImportDesk(formData, cookies.bearerToken)
            .then((res) => {
                //console.log(res);
                if (res?.response?.status === 400) {
                    toast.info(res?.response?.data);
                    return
                }
                if (res.status === 200) {
                    toast.success("desk imported successfully");
                    setIsImport(false);
                    setFileValidating(false);
                    getDeskList(0, 10);
                    setGlobalFilterValue("");
                }
            })
            .catch((err) => {
                //console.log(err);
                setFileValidating(false);
                if (err.response.status === 400) {
                    toast.error(err.response.data.message);
                } else {
                    toast.error("Something went wrong");
                }
            });
        // }
    };
    const importHeader = () => {
        return <div className="heading" style={{ fontWeight: "700", fontSize: "23px", color: "#ae9d81", textAlign: 'center' }}>
            <p style={{ margin: '0px' }}>Import Desk Details</p>
        </div>
    }
    const importPopup = (() => {
        return (<>
            <Dialog header={importHeader} visible={true} style={{ width: '50vw' }} closable={true} onHide={() => { setIsImport(false) }}>
                <div className="popup " >
                    <div className="body">
                        <div className="col">
                            <div className="label grid">
                                <div className='col'>
                                    Sample Template {" "}
                                    <i className="pi pi-download" style={{ color: 'slateblue', marginLeft: "7px", cursor: "pointer" }}
                                        // on click i need to download a file 
                                        onClick={() => {
                                            // window.open(Itemcsv)
                                            var downloadLink = document.createElement("a");
                                            downloadLink.href = deskcsv;
                                            downloadLink.download = "deskcsv.csv";
                                            document.body.appendChild(downloadLink);
                                            downloadLink.click();
                                            document.body.removeChild(downloadLink);
                                        }}
                                    ></i>
                                </div>
                            </div>
                        </div>
                        <div className="col">
                            <div className="label grid">
                                <div className='col' style={{ display: "flex", alignItems: "center" }}>Select File<span style={{ color: 'red' }}>*</span> <Modalinfo /></div>
                            </div>
                            <div className="input grid ">
                                <div className="col">
                                    {/* <FileUpload mode="basic" name="demo[]" accept="*.csv" maxFileSize={1000000} onUpload={onUpload} chooseLabel="Browse" /> */}
                                    <FileUpload name="demo[]" accept=".csv" mode='basic' multiple={false} maxFileSize={1000000} customUpload uploadHandler={onUpload} emptyTemplate={<p className="m-0">Drag and drop files to here to upload.</p>} />
                                    {/* {isFileValidating && <i className="pi pi-spin pi-spinner" style={{ fontSize: '2rem' }}></i>} */}
                                    {isImportInvalid &&
                                        // <div>
                                        <p style={{ color: "red" }}>Please choose a file</p>
                                        // </div>
                                    }
                                    {isImportInvalidFile &&
                                        // <div>
                                        <p style={{ color: "red" }}>Name of the uploaded file should be same as the sample file.</p>
                                        // </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Dialog>
        </>)
    })
    const back = () => {
        navigate('/dashboard');
    };



    const editDesk = (() => {
        let data = {
            "name": desk?.deskName,
            "id": desk?.deskId,
            "organizationId": desk?.orgId?.id,
            "isDeleted": !desk?.deskStatus,
        }
        let deskNameExist = deskList.filter((des) => des.deskName == desk?.deskName.trim() && des.organizationId == desk?.orgId?.id && des?.id != desk?.deskId)


        setIsInvalid(false);
        if (deskNameExist.length > 0 && deskList.length != 1) {
            setDeskNameExists(true);
            return;
        }
        if (desk?.deskName == "" || desk?.orgId == "") {
            setIsInvalid(true);
            return;
        }
        EditDesk(data, cookies.bearerToken)
            .then((res) => {

                setDesk({
                    deskName: "", deskStatus: true, orgId: ""
                })
                setIsAddDesk(false);
                setDeskNameExists(false);
                getDeskList(first, 10);
                toast.success("Desk is updated successfully", { position: "top-right" });

            })
            .catch((error) => {
                //console.log(error);
            })
    })

    const header = () => {
        return <div className="heading" style={{ fontWeight: "700", fontSize: "23px", color: "#ae9d81", textAlign: 'center' }}>
            <p style={{ margin: '0px' }}> {isAdd ? "Add" : "Edit"} Desk Details</p>
        </div>
    }
    const popup = (() => {
        return (
            <>

                <Dialog header={header} visible={true} closable={false} style={{ width: '30vw' }} onHide={() => {
                    setDeskNameExists(false);
                    setIsAddDesk(false); setIsInvalid(false); setDesk({
                        deskName: "", deskStatus: true, orgId: ""
                    })
                }}>
                    <div className="popup " >
                        {/* <div className="heading">
                        <p>Add New Details</p>
                    </div> */}
                        <div className="body">
                            <div className="label grid">
                                <div className='col'>Desk Name<span style={{ color: 'red' }}>*</span></div>
                                <div className='col'>Status</div>
                            </div>
                            <div className="input grid ">
                                <div className="col" >
                                    <InputText placeholder="Enter Name" style={{ width: "100%", border: "1px solid #ae9d81", boxShadow: 'none' }} value={desk?.deskName} onChange={(e) => { setDesk((prev) => ({ ...prev, deskName: e.target.value })); setDeskNameExists(false); }} />
                                    {deskNameExists &&
                                        // <div>
                                        <p style={{ color: "red" }}>Desk name is already exists</p>
                                        // </div>
                                    }
                                    {isInvalid && desk?.deskName == "" &&
                                        // <div>
                                        <p style={{ color: "red" }}>Desk name is required</p>
                                        // </div>
                                    }
                                </div>
                                <div className="col ">
                                    <label className="switch ">
                                        <input type="checkbox" value={desk?.deskId} checked={desk?.deskStatus} onChange={(e) => { setDesk((prev) => ({ ...prev, deskStatus: e.target.checked })); }} />
                                        <span className="slider round"></span>
                                    </label>
                                </div>
                            </div>
                            <div className="label grid">
                                <div className='col' style={{ marginTop: '5px' }}>Organization Name<span style={{ color: 'red' }}>*</span></div>
                            </div>
                            <div className="input grid ">
                                <div className="col">
                                    {/* <Dropdown style={{ width: "100%", border: "1px solid #ae9d81", boxShadow: 'none' }} value={organization} options={organizationList} optionLabel="name" placeholder="Please Select" onChange={(e) => { setDesk((prev) => ({ ...prev, orgId: e?.target?.value })) }} /> */}
                                    <Dropdown value={desk?.orgId} style={{ border: "1px solid #AE9D81", width: "100%", height: "50px", borderRadius: "5px", boxShadow: "none" }}
                                        onChange={(e) => {
                                            setDesk((prev) => ({ ...prev, orgId: e?.target?.value }))

                                        }} options={organizationList} optionLabel="name"
                                        placeholder="Choose Organization" />
                                    {isInvalid && desk?.orgId == "" &&
                                        // <div>
                                        <p style={{ color: "red" }}>Organization is required</p>
                                        // </div>
                                    }
                                </div>
                                <div className="col"></div>
                            </div>
                        </div>
                        <div className="footer flex  grid">
                            <div className="col flex justify-content-center ">
                                <Button label="Cancel" onClick={() => {
                                    setDeskNameExists(false);
                                    setIsAddDesk(false);
                                    setIsInvalid(false);
                                    getDeskList();
                                    setDesk({
                                        deskName: "", deskStatus: true, orgId: "", deskId: ""
                                    });
                                }} style={{ backgroundColor: '#8E8E8E', border: "none", margin: "10px", height: '40px' }} />
                                <Button label={isAdd ? "Submit" : "Update"} onClick={() => { isAdd ? addDesk() : editDesk() }} style={{ backgroundColor: '#ae9d81', border: "none", margin: "10px", height: '40px' }} />
                            </div>
                        </div>
                    </div>
                </Dialog >

            </>
        )
    })

    const addDesk = (() => {

        let data = {
            "name": desk?.deskName,
            "organizationId": desk?.orgId?.id,
            "isDeleted": !desk?.deskStatus
        }

        let deskNameExist = deskList.filter((des) => des.deskName == desk?.deskName.trim() && des.organizationId == desk?.orgId?.id)

        if (desk?.deskName == "" || desk?.orgId == "") {
            setIsInvalid(true);
            return;
        }
        setIsInvalid(false);
        if (deskNameExist.length > 0) {
            setDeskNameExists(true);
            return;
        }
        setIsAddDesk(false);
        AddDesk(data, cookies.bearerToken)
            .then((res) => {
                // //console.log(res);
                setDesk({
                    deskName: "", deskStatus: true, orgId: ""
                })
                setDeskNameExists(false);
                setIsInvalid(false);
                getDeskList(first, 10);
                toast.success("New desk is added successfully", { position: "top-right" });
            })
            .catch((error) => {
                //console.log(error);
            })
    })
    const getActiveOrganizationList = (() => {
        GetActiveOrganizationList(cookies.bearerToken)
            .then((res) => {
                if (res.status == 200) {
                    setOrganizationList(res?.data)
                }
            })
            .catch((error) => {
                //console.log(error);
            })


    })

    const onPageChange = (e, index) => {
        setFirst(e.first);
        getDeskList(e.first, 10, globalFilterValue);

    };
    const exportDesk = () => {
        //without filter
        // exportDataInExcel('Desk', cookies.bearerToken);
        // const urlRoute = `Desk?organizationId=${exportDetails?.organization}&status=${!exportDetails?.status}`;
        // exportExcel(urlRoute);
        // setExportFilterDialog(false);
        // setExportDetails({ ...initialExportDetails });
        /// new 

        const urlRoute = `Master/AdminDeskList?orgId=${filterObj?.organization || ""}${filterObj?.status ? `&showAll=${isShow({ ...filterObj, search: globalFilterValue })}&status=${filterObj?.status == "In Active"}` : ""}&searchTerm=${globalFilterValue || ""}&forExport=true`;
        exportFilterdExcel(urlRoute, cookies?.bearerToken);
    }
    const getOrganizationList = (async () => {
        setIsLoading(true);
        await GetOrganizationList(cookies.bearerToken)
            .then((res) => {
                //console.log("Res", res);
                //console.log("status", res.status);
                //console.log("status", res?.data);
                if (res?.status === 200) {
                    //console.log("Fetching Organization List", res?.data);
                    setOrganizationList(res?.data?.data.map((_) => {
                        return {
                            "id": _?.id,
                            "name": _?.name,
                            "code": _?.code,
                            "createdOn": _?.createdOn,
                            "userName": _?.userName,
                            "status": _?.status,
                            "orgStatus": _?.status ? "Inactive" : "Active",

                        }
                    }
                    ));
                    setTotalRecords(res?.data?.count);
                }

            })
            .catch((error) => {
                //console.log(error);
            })
            .finally(() => {
                setIsLoading(false);
                // //console.log(organizationList);
            })
    });

    useEffect(() => {
        getDeskList(0, 10);
        // getActiveOrganizationList();
        getOrganizationList();
        // //console.log(deskList);

    }, [])

    const initialExportDetails = {
        startDate: "",
        endDate: "",
        organization: "",
        status: true
    }
    const [exportDetails, setExportDetails] = useState({ ...initialExportDetails })


    return (
        <>
            <div className="deskMaster">
                <Dialog header={<h3>Export Filter</h3>} draggable={false} style={{ width: '40vw', height: "max-content" }} closable={true} onHide={() => { setExportFilterDialog(false); setExportDetails({ ...initialExportDetails }); }} visible={exportFilterDialog} >
                    <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "space-between" }}>
                        <div style={{ width: "48%" }}>
                            <label htmlFor="organization" className="font-bold block mb-2">Organization
                            </label>
                            <Dropdown options={organizationList} value={exportDetails?.organization}
                                optionLabel='name' optionValue='id'
                                onChange={(e) => setExportDetails((prev) => ({ ...prev, organization: e.value }))}
                                placeholder='Select organization' className='w-full' />
                        </div>
                        <div style={{ width: "48%" }}>
                            <label htmlFor="Status" className="font-bold block mb-2">Status
                            </label>
                            {/* <InputText
                                id="Status"
                                style={{ width: "95%" }}
                                value={exportDetails?.status}

                                placeholder="Enter organization" ></InputText> */}
                            <div className="input grid ">
                                <div className="col ">
                                    <label className="switch ">
                                        <input type="checkbox" value={exportDetails?.status} checked={exportDetails?.status} onChange={((e) => setExportDetails(() => ({ ...exportDetails, status: e.target.checked })))} />
                                        <span className="slider round"></span>
                                    </label>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            marginTop: "25px",
                        }}
                    >
                        <Button
                            label="Submit"
                            onClick={exportDesk}
                            // icon={"pi pi-file-export"}
                            style={{
                                backgroundColor: " #ae9d81",
                                color: "#fff",
                                border: "none",
                                // borderRadius: "20px",
                                marginRight: "0",
                                cursor: totalCount > 0 ? "pointer" : "not-allowed",
                                padding: "0.6rem",
                            }}
                        />
                    </div>


                </Dialog>
                <div className="header">
                    <div style={{ flexDirection: "row", flexGrow: "3", justifyContent: "flex-start" }} className='flex align-items-center org'>
                        <i className="pi pi-arrow-circle-left nav-back-btn" onClick={back} style={{ marginLeft: '30px', cursor: 'pointer', fontSize: '1.5rem' }} />
                        <div className="heading">
                            <p>Desk</p>
                        </div>
                    </div>
                    <div className='mr-4'>
                        <h4 className='mb-1 mt-0' style={{color:"#ae9d81"}}>Status</h4>
                        <Dropdown  value={filterObj?.status}  options={[{ name: "Active" }, { name: "In Active" }]} optionLabel='name' optionValue='name' onChange={(e) => { setFilterObj((prev) => ({ ...prev, status: e.value })); getDeskList(0, 10, globalFilterValue, filterObj?.organization, e.value) }} placeholder='Select' style={{ border: "solid 1px #ae9d81", width: 180 }} showClear={filterObj?.status ? true : false} />
                    </div>  
                    <div className='mr-4'>
                         <h4 className='mb-1 mt-0' style={{color:"#ae9d81"}}>Organization</h4>
                        <Dropdown value={filterObj?.organization} options={organizationList} optionLabel='name' optionValue='id' onChange={(e) => { setFilterObj((prev) => ({ ...prev, organization: e.value })); getDeskList(0, 10, globalFilterValue, e.value, filterObj?.status) }} placeholder='Select' style={{ border: "solid 1px #ae9d81", width: 180 }} showClear={filterObj?.organization ? true : false} />
                    </div>
                    <div className='mx-2'>
                        <h4 className='mb-1 mt-0' style={{color:"#ae9d81"}}>Search</h4>
                        <div className="search" style={{ width: 180 }}>
                            <span className="p-input-icon-left">
                                <i className="pi pi-search" style={{ color: 'black', fontWeight: '700', fontSize: '1rem' }} />
                                <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Search" style={{ width: 180 }} />
                                <i className="pi pi-times" style={{ color: 'black', fontWeight: '400', fontSize: '1rem' }} onClick={() => { setGlobalFilterValue(""); onGlobalFilterChange(); getDeskList(0, 10, "", filterObj?.organization, filterObj?.status) }} />
                            </span>
                        </div>
                    </div>
                   
                    <div className="mx-2 align-self-start mt-1">
                        <Button label="Add New" onClick={() => { setIsAddDesk(true); setIsAdd(true) }} style={{ backgroundColor: '#ae9d81', border: 'none' }} />
                    </div>
                    <div className="mx-2 align-self-start">
                        <Button onClick={() => totalCount > 0 ? exportDesk() : null} label='Export' icon={"pi pi-file-export"} style={{ backgroundColor: 'transparent', color: '#ae9d81', border: '1px #ae9d81 solid', borderRadius: '20px', cursor: totalCount > 0 ? 'pointer' : 'not-allowed' }} />
                    </div>
                    <div className="mx-2 align-self-start">
                        <Button label='Import' icon={"pi pi-file-import"} style={{ backgroundColor: 'transparent', color: '#ae9d81', border: '1px #ae9d81 solid', borderRadius: '20px',  }} onClick={() => { setIsImport(true) }} />
                    </div>
                </div>


                <div className="datatable">
                    {isLoading ?
                        <div className='spinner spinner-center'></div>
                        : <DataTable value={deskList} rows={10} tableStyle={{ width: '90rem' }}
                            emptyMessage={<><p style={{ textAlign: "center" }}>Desk list is empty</p></>}>
                            <Column field="code" header="Desk Code" style={{ width: '25%' }}></Column>
                            <Column field="deskName" header="Desk Name" style={{ width: '25%' }}></Column>
                            <Column field="organizationName" header="Organization" style={{ width: '25%' }}></Column>
                            <Column header="Status" body={statusBodyTemplate} style={{ width: '25%' }} ></Column>
                            <Column header="Action" body={actionButtonTemplate} style={{ width: '25%' }}></Column>
                        </DataTable>}
                </div>
                {isLoading || deskList.length == 0 ? ""
                    :
                    <div style={{ justifyContent: "center", display: 'flex' }} >
                        <Paginator first={first} rows={10} totalRecords={totalCount} onPageChange={(e) => onPageChange(e, 1)} template={{ layout: 'RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink  LastPageLink CurrentPageReport' }} className="justify-content-start" />
                    </div>
                }

            </div >
            <div>
                {isAddDesk == true ? popup() : ""}
                {isImport == true ? importPopup() : ""}
            </div>

        </>
    )
}

export default Desk