import Axios from '../Helpers/Axios';
import { DownloadFile } from './Files';

export function GetInspections(take, skip, token,searchQuery,inspectionId,tenantId,startDate,endDate) {
    return Axios(`TenantInspection/GetInspectionsForAdmin?skip=${skip}&take=${take}&searchTerm=${searchQuery||""}&inspectorId=${inspectionId||""}&tenantId=${tenantId||""}&startDate=${startDate}&endDate=${endDate}&forExport=false`, 'get', null, token)
}
export function GetSearchedInspections(take, skip, searchValue, token) {
    return Axios(`TenantInspection/GetInspectionsForAdmin?skip=${skip}&take=${take}&searchTerm=${searchValue}`, 'get', null, token)
}
export function GetInspectionDescriptionMaster(token) {
    return Axios(`TenantInspection/GetInspectionDescriptionMaster`, 'get', null, token)
}
export function GetInspectionDetailForAdmin(id, token) {
    return Axios(`TenantInspection/GetInspectionDetailForAdmin?inspectionId=${id}`, 'get', null, token)
}
export function GuestInspectionStatuses(token) {
    return Axios(`GuestInspection/GuestInspectionStatuses`, 'get', null, token)
}

export function EditGuesStaffInspectionStatus(guestInspectionId, statusId, isDeleted, token) {
    return Axios(`DepartmentTeam/EditGuesStaffInspectionStatus?guestInspectedInformationId=${guestInspectionId}&statusId=${statusId}&isDeleted=${isDeleted}`, 'patch', null, token)
}
export function AddChatToTenantInspection(message, tenantInspectionId, token) {
    return Axios(`TenantInspection/AddChatToTenantInspection?message=${message}&tenantInspectionId=${tenantInspectionId}`, 'post', null, token)
}
export function GetTenantInspectionChats(tenantInspectionId, token) {
    return Axios(`TenantInspection/GetTenantInspectionChats?tenantInspectionId=${tenantInspectionId}`, 'get', null, token)
}
export function toDownloadFile(filename, blobUrl) {
    return Axios(`/File/DownloadFile?fileName=${filename}&azureBlobUrl=${blobUrl}`)
}
export function GetTenantInspectors(roleId, token) {
    return Axios(`User/GetUsersByRole?roleId=${roleId}`, 'get', null, token)
}

