import "./App.scss";
import "../node_modules/primeflex/primeflex.css";
import "primereact/resources/themes/lara-light-cyan/theme.css";
import { Provider } from 'react-redux';
import Store from './Redux/Store';
import { PrimeReactProvider } from 'primereact/api';
import Router from './Router';
import SideNav from './Components/SideNavBar/SideNav';
import 'react-toastify/dist/ReactToastify.css';
import { CookiesProvider } from 'react-cookie';
import "react-toastify/dist/ReactToastify.css";
import "primeicons/primeicons.css";
import { useEffect, useState } from "react";
import { WebNotification } from "./Components/utili/WebNotification";
function App() {
  const [ws, setWs] = useState(null);

  useEffect(() => {
    return () => {
    };
  }, []);

  

  return (
    <>
      <CookiesProvider>
        <PrimeReactProvider>
          <Provider store={Store}>
            <div className='app'>
              <Router>
                <SideNav />
              </Router>
            </div >
          </Provider>
        </PrimeReactProvider>
      </CookiesProvider>
    </>
  );
}

export default App;
