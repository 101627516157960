import { useCookies } from 'react-cookie';
import { useNavigate, useParams } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import './style.scss';
import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { InputText } from 'primereact/inputtext';
import { FilterMatchMode } from 'primereact/api';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import FileView from '../utili/FileView';
import { Dropdown } from 'primereact/dropdown';
import { Paginator } from 'primereact/paginator';


// ----------Assets Import------------
import editIcon from '../../../src/assets/images/editIcon.jpg';
// -----------------------------------

//-----------Context ---------------
import { useDispatch, useSelector } from "react-redux";
import { setGuestInspectionsInfo, setFeedbackList, setNavVisibilty } from '../../Redux/AppSlice';
// -------------------------------------

// --------------Service Imports------------
import { GetGuestStaffInspectedInformations, GetSearchedGuestStaffInspectedInformations } from '../../Services/GuestStaffInspectionViewService';
import { EditGuestStaffInspectionStatus } from '../../Services/GuestStaffInspectionViewService';
// -------------------------------------------------


function GuessStaffInspectionView() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { id } = useParams();
    const [cookies, setCookie, removeCookie] = useCookies();
    const [inspectionRecordList, setInspectionRecordList] = useState([]);
    const [editPopUp, setEditPopUp] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [statusList, setStatusList] = useState([{ "id": 1, "status": "Need Action" }, { "id": 2, "status": "Ok" }]);
    const [selectedRecord, setSelectedRecord] = useState({
        "id": 0,
        "status": "",
    });
    const [seletecdInspectionId, setSeletecdInspectionId] = useState("");

    // Pagination
    const [first, setFirst] = useState(0)
    const [totalCount, setTotalRecords] = useState(0)


    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        name: { value: null, matchMode: FilterMatchMode.CONTAINS }
    });
    const [globalFilterValue, setGlobalFilterValue] = useState('');
    const [isFilemodelView, setISFileModelView] = useState(false);
    const [imageList, setImageList] = useState([]);

    const onGlobalFilterChange = (e) => {
        setGlobalFilterValue(e ? e?.target?.value : "");
        if (!e) {
            getInspectionDetailForAdmin(0, 10)
            return
        }
        getInspectionDetailForAdmin(0, 10, e?.target?.value);
        return
        // const value = e ? e.target.value : "";
        // let _filters = { ...filters };
        // _filters['global'].value = value;
        // setFilters(_filters);
        // setGlobalFilterValue(value);
    };
    const statusBodyTemplateTab = ((row) => {
        return <p style={{ padding: "0 20px" }} onClick={() => { setISFileModelView(true); setImageList(row?.files) }}>
            <i className="pi pi-paperclip" style={{ color: '#708090' }}></i>
        </p>
    });
    const actionBodyTemplateTab = ((row) => {
        return <p style={{ padding: "0 20px" }}>
            {row.status !== "Need Action" ?
                <img src={editIcon} style={{ cursor: 'not-allowed' }} />
                :
                <img src={editIcon} onClick={() => { setSelectedRecord((prev) => ({ ...prev, id: row.inspectionStatusId, status: row.status })); setSeletecdInspectionId(row.id); setEditPopUp(true); }} style={{ cursor: 'pointer' }} />

            }
        </p>
    });

    const header = () => {
        return <div className="heading" style={{ fontWeight: "700", fontSize: "23px", color: "#ae9d81", textAlign: 'center' }}>
            <p style={{ margin: '0px' }}>Edit Inspection details</p>
        </div>
    }


    const getInspectionDetailForAdmin = (async (skip, take, searchValue) => {
        setIsLoading(true);
        if (!searchValue) {
            await GetGuestStaffInspectedInformations(id, take, skip, cookies.bearerToken)
                .then((response) => {
                    if (response?.status === 200) {
                        //console.log("getInspectionDetailForAdmin", response);
                        setInspectionRecordList(response?.data?.data);
                        setTotalRecords(response?.data?.count);
                    }
                })
                .catch((error) => {
                    //console.log(error);
                })
                .finally(() => {
                    setIsLoading(false);
                })
        } else {
            GetSearchedGuestStaffInspectedInformations(id, take, skip, searchValue, cookies.bearerToken).then((response) => {
                if (response?.status === 200) {
                    //console.log("getInspectionDetailForAdmin", response);
                    setInspectionRecordList(response?.data?.data);
                    setTotalRecords(response?.data?.count);
                }
            })
                .catch((error) => {
                    //console.log(error);
                })
                .finally(() => {
                    setIsLoading(false);
                })
        }
    })

    const editGuesStaffInspectionStatus = (async () => {
        await EditGuestStaffInspectionStatus(seletecdInspectionId, selectedRecord.id, true, cookies.bearerToken)
            .then(async res => {
                if (res?.status == 200) {
                    navigate("/departmentteam/2")
                }
            })
            .catch((error) => {
                //console.log(error);
            })

    })

    const onPageChange = (e, index) => {
        setFirst(e.first);
        getInspectionDetailForAdmin(e.first, 10, globalFilterValue);
        //console.log(e);
    };

    useEffect(() => {
        getInspectionDetailForAdmin(0, 10);
    }, [])



    return (
        <>
            <FileView visible={isFilemodelView} imagelist={imageList} setVisible={setISFileModelView} header={"Attachments"} />
            <Dialog header={header} visible={editPopUp}
                className='md:col-12 lg:col-4 sm:col-12'
                // style={{ width: '30vw' }}
                closable={false} >
                <div className="popup " >
                    {/* <div className="heading">
                            <p>Add New details</p>
                        </div> */}
                    <div className="body"  >
                        <div className="label grid" >
                            <div className='col' >Inspection Status<span style={{ color: 'red' }}>*</span></div>
                        </div>
                        <div className="input grid ">
                            <div className="col-6">
                                {/* <InputText placeholder="Enter Name" value={""} onChange={(e) => { }} /> */}

                                <Dropdown value={selectedRecord} style={{ border: "1px solid #AE9D81", width: "100%", height: "50px", borderRadius: "5px", boxShadow: "none" }}
                                    onChange={(e) => {
                                        //console.log("selectedRecord", selectedRecord);
                                        //console.log(e.target.value);
                                        setSelectedRecord((prev) => ({
                                            ...prev, id: e.target
                                                .value?.id, status: e.target.value.status
                                        }))
                                    }}
                                    options={statusList} optionLabel="status"
                                    placeholder="Select Inspection Status" />
                            </div>
                        </div>
                    </div>
                    <div className="footer flex  grid">
                        <div className="col flex justify-content-center ">
                            <Button label="Cancel" onClick={() => {
                                setEditPopUp(false); setSelectedRecord({
                                    "id": 0,
                                    "status": ""
                                });
                            }} style={{ backgroundColor: '#8E8E8E', border: "none", margin: "10px", height: '40px' }} />
                            <Button label="Update" onClick={editGuesStaffInspectionStatus} style={{ backgroundColor: '#ae9d81', border: "none", margin: "10px", height: '40px' }} />
                        </div>
                    </div>
                </div>
            </Dialog>
            <div className="GuestStaffInspectionView">
                {/* <div className="header" style={{ display: "flex", alignItems: "center" }}> */}
                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                    <div style={{ display: "flex", flexDirection: "row", flexGrow: "3", justifyContent: "flex-start", margin: "0 10px" }} className='flex align-items-center org'>
                        <div>
                            <FontAwesomeIcon icon={faArrowLeft} onClick={() => { navigate("/departmentteam/2") }} style={{ width: '100%', cursor: "pointer" }} />
                        </div>
                        {/* <div className="menubtn" style={{ padding: '10px 0px 5px 20px' }}>
                            <i className="pi pi-align-justify" style={{ fontSize: '1rem', cursor: "pointer" }} onClick={() => {
                                dispatch(
                                    setNavVisibilty({ sideNavVisibility: true }))
                            }}></i>
                        </div> */}
                        <div className="heading">
                            <p>Guest Staff Inspection View</p>
                        </div>
                    </div>
                    {/* <div className="search "
                        style={{ border: "solid 1px #ae9d81", borderRadius: '5px', marginRight: "50px" }}
                    >
                        <span className="p-input-icon-left">
                            <i
                                className="pi pi-search"
                                style={{ color: 'black', fontWeight: '700', fontSize: '1rem' }} />
                            <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Search" style={{ border: "unset" }} />
                            <i className="pi pi-times" style={{
                                color: 'black', fontWeight: '400', fontSize: '16px', position: 'absolute', margin: "-4px 15px "
                            }} onClick={() => { setGlobalFilterValue(""); onGlobalFilterChange(null) }} />
                        </span>
                    </div> */}
                    <span
                        className='sm:m-0 md:mr-2 lg:mr-2'
                        style={{ display: "flex", alignItems: "center", border: "solid 1px #ae9d81", borderRadius: '5px' }}>
                        <i className="pi pi-search"
                            style={{ color: 'black', fontWeight: '700', fontSize: '1rem', padding: "0.5rem" }} />
                        <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Search" style={{ border: "unset", padding: 10, paddingLeft: 10, width: "100%" }} />
                        <i className="pi pi-times" style={{
                            color: 'black', fontWeight: '400', fontSize: '16px', padding: "0.5rem"
                        }} onClick={() => { setGlobalFilterValue(""); onGlobalFilterChange(null) }} />
                    </span>
                </div>
                {/* <div className="search">
                        <span className="p-input-icon-left">
                            <i className="pi pi-search" style={{ color: 'black', fontWeight: '700' }} />
                            <InputText placeholder="Search" value={globalFilterValue} onChange={onGlobalFilterChange} />
                        </span>
                    </div> */}
                {/* <div className="addbtn">
                        <Button label="Add New" onClick={() => { }} style={{ backgroundColor: '#ae9d81', border: 'none' }} />
                    </div> */}
                {/* </div> */}

                <div
                    style={{ padding: "0 20px" }}
                // className="datatable"
                >
                    {isLoading ?
                        <div style={{ display: "flex", justifyContent: "center" }}>
                            <div className='spinner spinner-center' ></div>
                        </div>
                        :
                        <DataTable value={inspectionRecordList}
                            // tableStyle={{ width: '90rem' }}
                            stripedRows rows={10} style={{ boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}
                            emptyMessage={<><p style={{ textAlign: "center" }}> Inspection list is empty.</p></>}>
                            <Column field='id' header=' Id'></Column>
                            <Column field='category' header=' Category'></Column>
                            <Column field='departmentName' header='Department Name'></Column>
                            <Column field='inspectedLocation' header='Location'></Column>
                            {/* <Column field='comment' header='Comment'></Column> */}
                            <Column field='status' header='Status' />
                            <Column header='Attachments' body={statusBodyTemplateTab} />
                            <Column header='Actions' body={actionBodyTemplateTab} />
                        </DataTable>}
                </div>
                {isLoading ? ""
                    :
                    <div style={{ justifyContent: "center", display: 'flex' }} >
                        <Paginator first={first} rows={10} totalRecords={totalCount} onPageChange={(e) => onPageChange(e, 1)} template={{ layout: 'RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink  LastPageLink CurrentPageReport' }} className="justify-content-start" />
                    </div>
                }
            </div>
        </>
    )
}

export default GuessStaffInspectionView