import React, { useState } from "react";
import "./PurchaseDetail.scss";
import { useDispatch } from "react-redux";
import { setNavVisibilty } from "../../../../Redux/AppSlice";
import { useEffect } from "react";

// Prime React Components
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Paginator } from "primereact/paginator";
import { Dialog } from "primereact/dialog";
import { useCookies } from "react-cookie";
import { AutoComplete } from "primereact/autocomplete";
import { Calendar } from "primereact/calendar";

// ----------Assets Import------------
import editIcon from "../../../../assets/images/editIcon.jpg";
import {
  GetActiveVipCode,
  GetAllPurchaseDetails,
  GetPurchaseItemDetails,
} from "../../../../Services/VIPLoungeSupervisor";
import { exportDataInExcel, exportExcel, exportFilterdExcel } from "../../../../Services/ExportServices";
import { GetAllPantryItems, GetPantryItems } from "../../../../Services/VIPLoungePantryStaff";

function PurchaseDetail() {
  const inintialFilterDetails = {
    vipCode: null,
    fromDate: null,
    toDate: null,
    itemId: null,
  };
  // states
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  // modal
  const [isModalOpen, setIsModalOpen] = useState(false);

  // Userlist
  const [purchaseLIstData, setPurchaseListData] = useState([]);
  const [purchaseItemDetails, setPurchaseItemDetails] = useState([]);
  const [inventoryList, setInventoryList] = useState([]);

  // Pagination
  const [first, setFirst] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [cookies, setCookie, removeCookie] = useCookies();
  const [allVipCodes, setAllVipCodes] = useState([]);
  const [filterDetails, setFilterDetails] = useState({
    ...inintialFilterDetails,
  });


  // Search && Filter
  const [searchFilterValue, setSearchFilterValue] = useState("");

  // Action buttons for view and edit
  const actionButtonTemplate = (row) => {
    return (
      <>
        <div style={{ display: "flex", gap: "15px", justifyContent: "center" }}>
          <div
            onClick={(e) => {
              // setDetails{}
              setIsModalOpen(true);
              getPurchaseItemDetails(row?.id);
            }}
          >
            <i
              className="pi pi-eye"
              style={{ fontSize: "1rem", cursor: "pointer" }}
            />
          </div>
        </div>
      </>
    );
  };

  // amount for view
  const amountTemplate = (amount) => {
    return (
      <>
        <div>
          <div
            onClick={(e) => {
              // viewInvoiceDetails(row?.id);
            }}
          >
            <p style={{ fontSize: "15px", fontWeight: 400 }}>BHD {amount}</p>
          </div>
        </div>
      </>
    );
  };

  // Get page details List
  const getPageDetailsList = async (skip, take, vipcode, from, to, itemId = 0, forExport, orgId = cookies?.organizationID) => {
    await GetAllPurchaseDetails(
      cookies?.bearerToken,
      skip,
      take,
      vipcode,
      from,
      to,
      forExport,
      orgId,
      itemId
    )?.then((res) => {
      if (res?.status == 200) {
        console.log("new res", res);
        setPurchaseListData(res?.data?.data);
        setTotalCount(res?.data?.totalRecords);
      }
    });
  };

  const getPurchaseItemDetails = async (id) => {
    await GetPurchaseItemDetails(cookies?.bearerToken, id)?.then((res) => {
      if (res?.status == 200) {
        console.log("item res", res);
        setPurchaseItemDetails(res?.data?.response);
      }
    });
  };

  const getVipCodes = async (searchTerm) => {
    await GetActiveVipCode(cookies?.bearerToken, searchTerm)?.then((res) => {
      if (res?.status == 200) {
        if (res?.data?.length) {
          let tempAllVipCodes = [];
          res?.data?.forEach((val) => {
            tempAllVipCodes.push({ vipCode: val });
          });
          setAllVipCodes([...tempAllVipCodes]);
        }
        else
          setAllVipCodes([]);
      }
    });
  };

  const searchVIPCodes = (event, getFunction, key) => {
    let query = event.query;
    getVipCodes(query);
    // setDropdownValues({ ...dropdownValues });
    // getAllStores(0, 100, query);
  };
  const searchItems = (event, getFunction, key) => {
    let query = event.query;
    getAllInventoryList(query);
    // setDropdownValues({ ...dropdownValues });
    // getAllStores(0, 100, query);
  };


  const handleInputValue = (key, value) => {
    console.log(key, value);
    setFilterDetails({ ...filterDetails, [key]: value });
  };

  const getIsFilterNotEmpty = () => {
    let isNotEmpty =
      filterDetails?.vipCode ||
      filterDetails?.itemId;

    return isNotEmpty;
  };

  const handleSearch = (skip, take) => {
    let fromDate = filterDetails?.fromDate
      ? filterDetails?.fromDate?.getFullYear() +
      "/" +
      (filterDetails?.fromDate?.getMonth() + 1) +
      "/" +
      filterDetails?.fromDate?.getDate()
      : "";
    let toDate = filterDetails?.toDate
      ? filterDetails?.toDate?.getFullYear() +
      "/" +
      (filterDetails?.toDate?.getMonth() + 1) +
      "/" +
      filterDetails?.toDate?.getDate()
      : "";

    getPageDetailsList(
      skip,
      take,
      filterDetails?.vipCode ? filterDetails?.vipCode : "",
      fromDate,
      toDate,
      filterDetails?.itemId?.id ? filterDetails?.itemId?.id : 0,
      false,
      cookies.organizationID
    );
  };

  const onPageChange = (e, index) => {
    console.log("e", e);
    setFirst(e.first);
    handleSearch(e.first, 10);
  };

  const exportPurchaseDetails = () => {
    let fromDate = filterDetails?.fromDate
      ? filterDetails?.fromDate?.getFullYear() +
      "/" +
      (filterDetails?.fromDate?.getMonth() + 1) +
      "/" +
      filterDetails?.fromDate?.getDate()
      : "";
    let toDate = filterDetails?.toDate
      ? filterDetails?.toDate?.getFullYear() +
      "/" +
      (filterDetails?.toDate?.getMonth() + 1) +
      "/" +
      filterDetails?.toDate?.getDate()
      : "";
    console.log("Export", filterDetails);
    let urlRoute = `User/GetPurcasedPantryDetail?vipCode=${filterDetails?.vipCode || ""}&fromDate=${fromDate}&toDate=${toDate}&orgId=${cookies.organizationID}&skip=${0}&take=${totalCount}&forExport=true`
    exportFilterdExcel(urlRoute, cookies.bearerToken);
    // getPageDetailsList(0, totalCount, filterDetails?.vipCode, fromDate, toDate, true, cookies.organizationID);
    // exportDataInExcel("ExportPurchasePantryDetail", cookies.bearerToken);
  };
  // const exportPurchaseDetails = () => {
  //   let fromDate = filterDetails?.fromDate
  //   ? filterDetails?.fromDate?.getFullYear() +
  //   "/" +
  //   (filterDetails?.fromDate?.getMonth() + 1) +
  //   "/" +
  //   filterDetails?.fromDate?.getDate()
  //   : "";
  // let toDate = filterDetails?.toDate
  //   ? filterDetails?.toDate?.getFullYear() +
  //   "/" +
  //   (filterDetails?.toDate?.getMonth() + 1) +
  //   "/" +
  //   filterDetails?.toDate?.getDate()
  //   : "";
  //   let urlRoute=`ExportPurchasePantryDetail?vipCode=${filterDetails?.vipCode?.vipCode||""}&fromDate=${fromDate}&toDate=${toDate}&orgId=0`
  //   exportExcel(urlRoute);
  //   // exportDataInExcel("ExportPurchasePantryDetail", cookies.bearerToken);
  // };
  const getAllInventoryList = async (searchTerm = "") => {
    await GetAllPantryItems(searchTerm, cookies?.bearerToken)
      .then((res) => {
        console.log("new res", res);
        if (res?.status === 200) {
          if (res?.data?.length) {
            setInventoryList(res?.data);
          }
          else
            setInventoryList([]);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
      });
  };
  useEffect(() => {
    getVipCodes("");
    getPageDetailsList(0, 10, "", "", "", 0, false, cookies.organizationID);
    getAllInventoryList();
  }, []);

  return (
    <div className="SupervisorPageDetails">
      <div className="header">
        <div style={{ display: "flex" }}>
          <div className="menubtn" style={{ padding: "20px" }}>
            <i
              className="pi pi-align-justify"
              style={{ fontSize: "1rem", color: "#ae9d81", cursor: "pointer" }}
              onClick={() =>
                dispatch(setNavVisibilty({ sideNavVisibility: true }))
              }
            ></i>
          </div>
          <div className="heading">
            <p>Purchase Details</p>
          </div>
        </div>
        <div className="right" style={{ paddingRight: "10px" }}>
          <Button
            label="Export"
            icon={"pi pi-file-export"}
            style={{
              backgroundColor: "transparent",
              color: "#ae9d81",
              border: "1px #ae9d81 solid",
              borderRadius: "20px",
              marginRight: "5px",
              cursor: "pointer",
            }}
            onClick={exportPurchaseDetails}
          />
        </div>
      </div>

      <div className="purchase-details-filters">
        <div className="input">
          <div className="input-box">
            <h4 className='mb-1 mt-0 relative' style={{ color: "#ae9d81" }}>Vip code</h4>
            <AutoComplete
              placeholder="Select here"
              className="new-test customeCalenderIcons"
              value={filterDetails?.vipCode}
              suggestions={allVipCodes}
              completeMethod={(e) => searchVIPCodes(e)}
              virtualScrollerOptions={{ itemSize: 38 }}
              field="vipCode"
              dropdown
              onChange={(e) => {
                console.log("Response:", e);
                handleInputValue("vipCode", e.target.value.vipCode);
              }}
            />
          </div>
        </div>
        <div className="input">
          <div className="input-box">
            <h4 className='mb-1 mt-0 relative' style={{ color: "#ae9d81" }}>Item Name</h4>
            <AutoComplete
              placeholder="Select here"
              className="new-test customeCalenderIcons"
              value={filterDetails?.itemId}
              suggestions={inventoryList}
              completeMethod={(e) => searchItems(e)}
              virtualScrollerOptions={{ itemSize: 38 }}
              field="name"
              dropdown
              onChange={(e) => {
                console.log("Response:", e.target.value);
                handleInputValue("itemId", e.target.value);
              }}
            />
          </div>
        </div>
        <div className="input">
          <div className="input-box">
            <h4 className='mb-1 mt-0 relative' style={{ color: "#ae9d81" }}>From</h4>
            <Calendar
              className="customeCalenderIcons"
              placeholder="Select here"
              value={filterDetails?.fromDate}
              onChange={(e) => {
                handleInputValue("fromDate", e.target.value);
              }}
              showIcon
            />
          </div>
        </div>
        <div className="input">
          <div className="input-box">
            <h4 className='mb-1 mt-0 relative' style={{ color: "#ae9d81" }}>To</h4>
            <Calendar
              className="customeCalenderIcons"
              placeholder="Select here"
              value={filterDetails?.toDate}
              onChange={(e) => {
                handleInputValue("toDate", e.target.value);
              }}
              showIcon
            />
          </div>
        </div>
        <div className="input-btn">
          <Button
            label={"Search"}
            style={{
              backgroundColor: "#ae9d81",
              border: "none",
              borderRadius: "3px",
              width: "120px",
              height: "40px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            onClick={() => {
              if (getIsFilterNotEmpty()) {
                handleSearch(0, 10, filterDetails.vipCode);
              }
            }}
          />
        </div>
        <div className="input-btn">
          <Button
            label={"Clear"}
            style={{
              backgroundColor: "#8e8e8e",
              border: "none",
              borderRadius: "3px",
              width: "120px",
              height: "40px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            onClick={() => {
              if (getIsFilterNotEmpty()) {
                setFilterDetails({ ...inintialFilterDetails });
                getPageDetailsList(0, 10, "", "", "", 0, false, cookies?.organizationID);
              }
            }}
          />
        </div>
      </div>

      <div className="datatable">
        {isLoading ? (
          <div
            className="spinner spinner-center"
            style={{ display: "flex", margin: "auto", marginTop: "200px" }}
          ></div>
        ) : (
          <DataTable
            value={purchaseLIstData}
            style={{ boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)" }}
            tableStyle={{ width: "90rem", margin: "10px 39px" }}
            stripedRows
            rows={10}
            emptyMessage={
              <>
                <p style={{ textAlign: "center" }}>List is empty</p>
              </>
            }
          >
            <Column
              field="vipCode"
              header="Vip Code"
              style={{ width: "18%" }}
            ></Column>
            <Column
              field="customerName"
              header="Customer Name"
              style={{ width: "18%" }}
            ></Column>
            <Column
              field="createdDate"
              header="Date Of Purchase"
              style={{ width: "18%" }}
            ></Column>
            <Column
              style={{ width: "18%" }}
              field="totalAmountPaid"
              header="Amount"
              body={(row) => amountTemplate(row?.totalAmountPaid)}
            ></Column>
            <Column
              style={{ width: "18%" }}
              field="totalPurchasedItemsCount"
              header="Total purchased Items"
            ></Column>
            <Column
              field="nationality"
              header="Action"
              body={actionButtonTemplate}
              style={{ width: "10%" }}
            ></Column>
          </DataTable>
        )}
      </div>
      {isLoading ? (
        ""
      ) : (
        <div style={{ justifyContent: "center", display: "flex" }}>
          <Paginator
            first={first}
            rows={10}
            totalRecords={totalCount}
            onPageChange={(e) => onPageChange(e, 1)}
            template={{
              layout:
                "RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink  LastPageLink CurrentPageReport",
            }}
            className="justify-content-start"
          />
        </div>
      )}

      <Dialog
        header={
          <h3 style={{ textAlign: "center", color: "#ae9d81", margin: 0 }}>
            Purchase Details
          </h3>
        }
        visible={isModalOpen}
        draggable={false}
        blockScroll={false}
        style={{ width: "60%" }}
        onHide={() => {
          setIsModalOpen(false);
        }}
      >
        <div className="purchase-modal">
          <div className="modal-content">
            <div className="customer-info">
              <div className="customer-info-header">
                <h4>Customer info</h4>
              </div>
              <div className="customer-info-content">
                <div className="box" style={{ width: "25%" }}>
                  <div className="box-content">
                    <p className="box-content-label">
                      <span>Name</span> :{" "}
                      {purchaseItemDetails?.customerInformation?.firstName +
                        " " +
                        purchaseItemDetails?.customerInformation?.lastName}
                    </p>
                  </div>
                </div>
                <div className="box" style={{ width: "40%" }}>
                  <div className="box-content">
                    <p className="box-content-label">
                      <span>Email id</span> :{" "}
                      {purchaseItemDetails?.customerInformation?.emailAddress}
                    </p>
                  </div>
                </div>
                <div className="box" style={{ width: "35%" }}>
                  <div className="box-content">
                    <p className="box-content-label">
                      <span>Mobile number</span> :{" "}
                      {purchaseItemDetails?.customerInformation?.mobileNumber}{" "}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="item-details">
              <div className="item-details-header">
                <h4>Item details</h4>
              </div>
              <DataTable
                value={purchaseItemDetails?.purchasedItemsData}
                style={{ boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)" }}
                tableStyle={{ width: "100%" }}
                stripedRows
                rows={10}
                emptyMessage={
                  <>
                    <p style={{ textAlign: "center" }}>
                      Purchase List is empty
                    </p>
                  </>
                }
              >
                <Column
                  field="purchasedItemName"
                  header="Item Name"
                  style={{ width: "33.3%" }}
                ></Column>
                <Column
                  field="purchasedItemQuantity"
                  header="Quantity"
                  style={{ width: "33.3%" }}
                ></Column>
                <Column
                  style={{ width: "33.3%" }}
                  field="amount"
                  header="Amount"
                  body={(row) => amountTemplate(row?.totalAmount)}
                ></Column>
                {/* body={dateBodytemplate} */}
              </DataTable>
            </div>
          </div>
          <div className="modal-footer">
            <Button
              label={"Close"}
              className="p-button"
              style={{
                padding: "5px 0px",
                border: "none",
                width: "100px",
                background: "#8E8E8E",
              }}
              onClick={() => {
                setIsModalOpen(false);
              }}
            />
          </div>
        </div>
      </Dialog>
    </div>
  );
}

export default PurchaseDetail;
