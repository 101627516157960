import Axios from '../Helpers/Axios';

export function GetTenantCategoryList(token) {
    return Axios('TenantCategory/List', 'get', null, token)
}
export function GetTenantCategory(id, token) {
    return Axios(`TenantCategory/Get?id=${id}`, 'get', null, token)
}
export function GetActiveTenantCategory(token) {
    return Axios(`TenantCategory/ActiveData`, 'get', null, token)
}

export function AddTenantCategory(data,token) {
    return Axios('TenantCategory/Add', 'post', data, token)
}
export function EditTenantCategory(data,token) {
    return Axios('TenantCategory/Edit', 'put', data, token)
}