import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import React, { useState, useEffect } from "react";
import "./ShopAndDropMasterScreen.scss";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import {
  AddDropLocation,
  AddPickupLocation,
  AddStore,
  AddTag,
  DeleteDropLocation,
  DeletePickupLocation,
  DeleteStore,
  DeleteTag,
  EditDropLocation,
  EditPickupLocation,
  EditStore,
  EditTag,
  GetDropLocation,
  GetPickupLocation,
  GetStores,
  GetTag,
  GetDeliveryCharge,
  AddDeliveryCharge,
  UpdateDeliveryCharge,
  BulkEditDeliveryCharge,
  GetProofList,
  AddProofList,
  DeleteProofList,
  GetPickupLocationsForAdmin,
  GetTagsForAdmin,
  GetDeliveryChargesForAdmin,
} from "../../../Services/ShopAndDropService";
import { useCookies } from "react-cookie";
import { Dialog } from "primereact/dialog";
import { MdTurnedIn } from "react-icons/md";
import { InputText } from "primereact/inputtext";
import { InputSwitch } from "primereact/inputswitch";

import editIcon from "../../../../src/assets/images/editIcon.jpg";
import { Paginator } from "primereact/paginator";
import DeleteConfirmationModel from "../../utili/DeleteConfirmationModel";
import { setNavVisibilty } from "../../../Redux/AppSlice";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { GetActiveOrganizationList } from "../../../Services/OrganizationService";
import { useNavigate } from "react-router-dom";
import { FileUpload } from "primereact/fileupload";
import Modalinfo from "../../utili/Modalinfo";
import Itemcsv from '../../../assets/files/TagsAndBaggage.csv'
import { ImportItems } from "../../../Services/ItemsService";
import { ImportBaggageTag } from "../../../Services/ImportService";

const ShopAndDropMasterScreen = () => {
  const dispatch = useDispatch();

  const [activeMaster, setActiveMaster] = useState({
    nameValue: "",
    statusValue: "Active",
    activeMasterValue: { name: "Pickup Location" },
    activeMasterDashboardValues: [],
    id: 0,
    modalVisible: false,
    rowValue: 10,
    pagePositionValue: 0,
    totalrecord: 0,
    deleteConfirmationVisible: false,
    selectedOrganization: null
  });
  const [pagination, setpagination] = useState({
    take: 10,
    skip: 0,
    searchTerm: "",
  });
  const [cookies, setCookie, removeCookie] = useCookies();
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [amountDetails, setAmountDetails] = useState({
    amount: null,
    id: null,
    name: "",
  });
  const [deliveryCharges, setDeliveryCharges] = useState([]);
  const [isLoader, setIsLoader] = useState(false);
  const [isErrMsgView, setIsErrMsgView] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [organizationList, setOrganizationList] = useState([]);

  const isAmountScreen =
    activeMaster?.activeMasterValue?.name == "Delivery charge";
  console.log("amountDetails", amountDetails);
  const masterOptions = [
    { name: "Pickup Location" },
    // { name: "Store" },
    // { name: "Check-in Location" },
    { name: "Baggage Tags" },
    { name: "Delivery charge" },
    { name: "Proof List" },
  ];
  const navigate = useNavigate();
  //proofList
  const getProofList = async () => {

    await GetProofList(cookies?.bearerToken)?.then((res) => {
      if (res?.status == 200) {
        assginValue("activeMasterDashboardValues", res?.data);
        assginValue("totalrecord", res?.data?.length);
        console.log(res?.data?.data);
        setIsLoader(false);
      } else {
        setIsLoader(false);
      }
    });
  };
  const addProflist = async (id = 0) => {
    setIsLoader(true);
    let data = {
      id: id,
      name: activeMaster?.nameValue,
      isActive: activeMaster?.statusValue === "Active" ? true : false,
    };
    await AddProofList(data, cookies?.bearerToken).then((res) => {
      console.log(res?.status);

      if (res?.status === 200) {
        assginValue("modalVisible", false);
        toast.success("Proof list added successfully", {
          position: "top-right",
        });
        getProofList();
      } else {
        setIsLoader(false);
        toast.error(
          "There is issue occured in logging in, could you please try again",
          { position: "top-right" }
        );
      }
    });
  };

  const deleteProofList = async (id) => {
    setIsLoader(true);
    let data = {
      id: id,
      isDeleted: true
    }
    await DeleteProofList(data, cookies?.bearerToken).then((res) => {
      if (res?.status === 200) {
        assginValue("deleteConfirmationVisible", false);
        assginValue("id", 0);
        toast.success("Proof list deleted successfully", {
          position: "top-right",
        });
        getProofList();
      } else {
        setIsLoader(false);
        toast.error(
          "There is issue occured in logging in, could you please try again",
          { position: "top-right" }
        );
      }
    });
  };
  /// tags functionalities
  const getTag = async (skip, take, searchTerm, isSearch = false) => {
    if (!isSearch) {
      setIsLoader(true);
    }
    await GetTagsForAdmin(skip, take, searchTerm, cookies?.bearerToken)?.then((res) => {
      if (res?.status == 200) {
        assginValue("activeMasterDashboardValues", res?.data?.data);
        assginValue("totalrecord", res?.data?.count);
        console.log(res?.data?.data);
        setIsLoader(false);
      } else {
        setIsLoader(false);
      }
    });
  };

  const addTag = async () => {
    setIsLoader(true);
    let data = {
      name: activeMaster?.nameValue,
      isActive: activeMaster?.statusValue === "Active" ? true : false,
    };
    await AddTag(data, cookies?.bearerToken, activeMaster?.selectedOrganization).then((res) => {
      console.log(res?.status);

      if (res?.status === 200) {
        assginValue("modalVisible", false);
        toast.success("Baggage tag added successfully", {
          position: "top-right",
        });
        getTag(pagination?.skip, pagination?.take, pagination?.searchTerm);
      } else {
        setIsLoader(false);
        toast.error(
          "There is issue occured in logging in, could you please try again",
          { position: "top-right" }
        );
      }
    });
  };

  const editTag = async () => {
    setIsLoader(true);
    let data = {
      name: activeMaster?.nameValue,
      isActive: activeMaster?.statusValue === "Active" ? true : false,
      id: activeMaster?.id,
    };
    await EditTag(data, cookies?.bearerToken, activeMaster?.selectedOrganization).then((res) => {
      console.log(res?.status);

      if (res?.status === 200) {
        assginValue("modalVisible", false);
        toast.success("Baggage tag updated successfully", {
          position: "top-right",
        });
        getTag(pagination?.skip, pagination?.take, pagination?.searchTerm);
      } else {
        setIsLoader(false);
        toast.error(
          "There is issue occured in logging in, could you please try again",
          { position: "top-right" }
        );
      }
    });
  };

  const deleteTag = async (pickupLocationId) => {
    setIsLoader(true);
    await DeleteTag(pickupLocationId, cookies?.bearerToken).then((res) => {
      if (res?.status === 200) {
        assginValue("deleteConfirmationVisible", false);
        assginValue("id", 0);
        toast.success("Baggage tag deleted successfully", {
          position: "top-right",
        });
        getTag(pagination?.skip, pagination?.take, pagination?.searchTerm);
      } else {
        setIsLoader(false);
        toast.error(
          "There is issue occured in logging in, could you please try again",
          { position: "top-right" }
        );
      }
    });
  };

  /// pickup location functionalities

  const getpickupLocation = async (
    skip,
    take,
    searchTerm,
    isSearch = false
  ) => {
    if (!isSearch) {
      setIsLoader(true);
    }
    await GetPickupLocationsForAdmin(skip, take, searchTerm, cookies?.bearerToken)?.then(
      (res) => {
        if (res?.status == 200) {
          assginValue("activeMasterDashboardValues", res?.data?.data);
          assginValue("totalrecord", res?.data?.count);
          setIsLoader(false);
        } else {
          setIsLoader(false);
        }
      }
    );
  };

  const addPickupLocation = async () => {
    setIsLoader(true);
    let data = {
      name: activeMaster?.nameValue,
      isActive: activeMaster?.statusValue === "Active" ? true : false,
    };
    await AddPickupLocation(data, cookies?.bearerToken, activeMaster?.selectedOrganization).then((res) => {
      console.log(res?.status);
      if (res?.status === 200) {
        assginValue("modalVisible", false);
        toast.success("Pickup location added successfully", {
          position: "top-right",
        });
        getpickupLocation(
          pagination?.skip,
          pagination?.take,
          pagination?.searchTerm
        );
      } else {
        toast.error(
          "There is issue occured in logging in, could you please try again",
          { position: "top-right" }
        );
        setIsLoader(false);
      }
    });
  };

  const editPickupLocation = async () => {
    setIsLoader(true);
    let data = {
      name: activeMaster?.nameValue,
      isActive: activeMaster?.statusValue === "Active" ? true : false,
      id: activeMaster?.id,
    };
    await EditPickupLocation(data, cookies?.bearerToken, activeMaster?.selectedOrganization).then((res) => {
      if (res?.status === 200) {
        assginValue("modalVisible", false);
        toast.success("Pickup location updated successfully", {
          position: "top-right",
        });
        getpickupLocation(
          pagination?.skip,
          pagination?.take,
          pagination?.searchTerm
        );
      } else {
        setIsLoader(false);
        toast.error(
          "There is issue occured in logging in, could you please try again",
          { position: "top-right" }
        );
      }
    });
  };

  const deletePickupLocation = async (pickupLocationId) => {
    await DeletePickupLocation(pickupLocationId, cookies?.bearerToken).then(
      (res) => {
        if (res?.status === 200) {
          assginValue("modalVisible", false);

          assginValue("deleteConfirmationVisible", false);
          assginValue("id", 0);
          toast.success("Pickup location deleted successfully", {
            position: "top-right",
          });
          getpickupLocation(
            pagination?.skip,
            pagination?.take,
            pagination?.searchTerm
          );
        } else {
          setIsLoader(false);
          toast.error(
            "There is issue occured in logging in, could you please try again",
            { position: "top-right" }
          );
        }
      }
    );
  };

  const assginValue = (key, value) => {
    setActiveMaster((prev) => ({ ...prev, [key]: value }));
  };

  const openDialogScreen = (name, status, id) => {
    if (!name || !status) {

      setActiveMaster((prev) => ({
        ...prev,
        modalVisible: true,
        nameValue: "",
        statusValue: "Active",
        id: 0,
        selectedOrganization: null
      }));
      return;
    }
    assginValue("id", parseInt(id));
    assginValue("modalVisible", true);
    assginValue("nameValue", name);
    assginValue("statusValue", status);
    setIsErrMsgView(false);
  };
  console.log(isErrMsgView, "ssss");
  const actionBodyTemplate = (row) => {
    return (
      <div
        className="flex align-items-center"
        style={{ justifyContent: "unset" }}
      >
        <div className="px-2">
          {/* <img
            src={editIcon}
            alt="not found"
            onClick={() => openDialogScreen(row?.name, row?.status, row?.id)}
          /> */}
        </div>
        {!(
          activeMaster?.activeMasterValue?.name == "Baggage Tags" &&
          row?.status === "Inactive"
        ) && (
            <div className="px-2">
              <i
                className="pi pi-trash"
                style={{ fontSize: "1rem", cursor: "pointer", color: "red" }}
                onClick={() => {
                  assginValue("deleteConfirmationVisible", true);
                  assginValue("id", row.id);
                }}
              />
            </div>
          )}
      </div>
    );
  };

  // Deepak
  const handleDeliveryChargeChange = (row, value) => {
    let tempList = deliveryCharges
    let filteredIndex = tempList?.findIndex((item) => item.id === row?.id);
    // console.log(row, value, filteredIndex, deliveryCharges[filteredIndex]);
    tempList[filteredIndex].deliveryCharge = value;
    setDeliveryCharges([...tempList]);
  };

  const inputBodyTemplate = (row) => {
    return (
      <div
        className="flex align-items-center"
        style={{ justifyContent: "unset" }}
      >
        <InputText type="number" min={0} value={row?.deliveryCharge} onChange={(e) => { handleDeliveryChargeChange(row, e.target.value) }}></InputText>
      </div>
    );
  };
  // 


  const statusBodyTemplate = (row) => {
    return (
      <div
        className="flex align-items-center"
        style={{ justifyContent: "unset" }}
      >
        <div className="px-2">
          <span
            style={{
              color: row?.status == "Active" ? "green" : "red",
              fontWeight: "500",
            }}
          >
            {row?.status}
          </span>
        </div>
      </div>
    );
  };


  const activeStorageValuePicker = (key, searchValue, isSearch = false) => {
    console.log(key);
    switch (key) {
      case "Pickup Location":
        getpickupLocation(0, 10, searchValue ? searchValue : "", isSearch);
        break;
      case "Baggage Tags":
        getTag(0, 10, searchValue ? searchValue : "", isSearch);
        break;
      case "Delivery charge":
        getAmount();
        break;
      case "Proof List":
        getProofList();
        break;
      default:
        console.log("Invalid key");
        break;
    }
  };


  const onPaginating = (key, skip) => {
    switch (key) {
      case "Pickup Location":
        getpickupLocation(skip * 10, 10, pagination?.searchTerm || "");
        break;
      case "Baggage Tags":
        getTag(skip * 10, 10, pagination?.searchTerm || "");
        break;
      default:
        console.log("Invalid key");
        break;
    }
  };

  const deletionAction = async (label, id) => {
    switch (label) {
      case "Pickup Location":
        deletePickupLocation(id);
        break;
      case "Baggage Tags":
        deleteTag(id);
        break;
      case "Proof List":
        deleteProofList(id);
        break;
      default:
        console.log("Invalid key");
        break;
    }
  };

  const submitAction = async (label, id) => {
    switch (label) {
      case "Pickup Location":
        activeMaster?.id ? editPickupLocation() : addPickupLocation();
        break;
      case "Baggage Tags":
        activeMaster?.id ? editTag() : addTag();
        break;
      case "Proof List":
        activeMaster?.id ? addProflist(id) : addProflist();
        break;
      default:
        console.log("Invalid key");
        break;
    }

  };
  const onCanceling = (value) => {
    assginValue("deleteConfirmationVisible", value);
  };
  const onRemoving = () => {
    deletionAction(activeMaster?.activeMasterValue?.name, activeMaster?.id);
  };

  // ampunt
  const getAmount = async (skip = 0, take = 10) => {
    setIsLoader(true);
    await GetDeliveryChargesForAdmin(skip, take, cookies?.bearerToken)?.then((res) => {
      console.log("amount res", res);
      if (res?.status == 200) {
        setDeliveryCharges(res?.data?.data)
        setIsLoader(false);
      } else {
        setIsLoader(false);
      }
    });
  };

  const handleAddAmount = async () => {
    setIsLoader(true);
    let data = {
      deliveryName: amountDetails?.name,
      deliveryCharge: amountDetails?.amount,
      isActive: true,
    };

    await AddDeliveryCharge(cookies?.bearerToken, data)
      ?.then((res) => {
        if (res?.status == 200) {
          setIsConfirmationOpen(false);
          toast.success("Delivery charge added successfully");
          setIsLoader(false);
        } else {
          toast.error("There is issue occured in add subscription");
          setIsLoader(false);
        }
      })
      .finally((res) => {
        setIsConfirmationOpen(false);
      });
  };

  const handleUpdateAmount = async () => {
    setIsLoader(true);
    console.log(deliveryCharges);
    let data = [...deliveryCharges.map((item) => {
      return {
        deliveryCharge: item?.deliveryCharge,
        id: item?.id,
        organizationId: cookies?.organizationID
      }
    })];

    await BulkEditDeliveryCharge(cookies?.bearerToken, data)
      ?.then((res) => {
        console.log("res", res);
        if (res?.status == 200) {
          setIsConfirmationOpen(false);
          toast.success("Delivery charge updated successfully");
          setIsLoader(false);
          getAmount();
        } else {
          toast.error("There is issue occured in add subscription");
          setIsLoader(false);
        }
      })
      .finally((res) => {
        setIsConfirmationOpen(false);
      });
  };

  const getActiveOrganizationList = (() => {
    GetActiveOrganizationList(cookies.bearerToken)
      .then((res) => {
        if (res.status == 200) {
          setOrganizationList(res?.data)
        }
      })
      .catch((error) => {
        //console.log(error);
      })


  })

  const onSubmitting = () => {
    setIsErrMsgView(true);
    if (activeMaster?.nameValue && ((activeMaster.activeMasterValue?.name == "Pickup Location" || activeMaster?.activeMasterValue?.name == "Baggage Tags") ? activeMaster?.selectedOrganization : true)) {
      setIsErrMsgView(false);
      submitAction(activeMaster?.activeMasterValue?.name, activeMaster?.id ? activeMaster?.id : 0);
    }
  }

  // Deepak - Back funtionality
  const back = () => {
    navigate("/dashboard");
  };
  const getTitleValue=(name)=>{
    return name=="Pickup Location"?
    "Location Name":name=="Baggage Tags"?"Tag Name":
        name=="Delivery charge"?"Delivery Type-Time":name=="Proof List"?"Proof Type":""
  }
  // import
  const importHeader = () => {
    return <div className="heading" style={{ fontWeight: "700", fontSize: "23px", color: "#ae9d81", textAlign: 'center' }}>
        <p style={{ margin: '0px' }}>Import Baggage Tags</p>
    </div>
    }
    const [isImport,setIsImport]=useState(false);
     const [isImportInvalid,setIsImportInvalid]=useState(false);
     const [isImportInvalidFile,setIsImportInvalidFile]=useState(false);
    const onUpload = (e) => {
      //console.log(e?.files[0]); 
      if (e.files.length == 0) {
          setIsImportInvalid(true);
      }
      setIsImportInvalid(false);
      setIsImportInvalidFile(false);
      const formData = new FormData();
      formData.append('file', e.files[0]);
      ImportBaggageTag(formData, cookies.bearerToken)
          .then((res) => {
              if (res?.response?.status === 400) {
                  toast.info(res?.response?.data);
                  return
              }
              if (res.status === 200) {
                  toast.success("Tags imported successfully");
                  setIsImport(false);
                  getTag(0, 10, "");
              }
          })
          .catch((err) => {
              if (err.response.status === 400) {
                  toast.error(err.response.data.message);
              } else {
                  toast.error("Something went wrong");
              }
          });
      // }
  };
  // ------------------------------
  useEffect(() => {
    getpickupLocation(0, 10, "");
    getActiveOrganizationList()
  }, []);

  return (
    <>
      {isLoader ? (
        <div
          className="spinner spinner-center"
          style={{ marginLeft: "750px" }}
        ></div>
      ) : (
        <div className="shop-and-drop-master">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "15px",
              paddingLeft: "10px",
            }}
          >
            <div className="menubtn">
              {/* <i
                className="pi pi-align-justify"
                style={{
                  fontSize: "1rem",
                  color: "#ae9d81",
                  cursor: "pointer",
                }}
                onClick={() =>
                  dispatch(setNavVisibilty({ sideNavVisibility: true }))
                }
              ></i> */}
              <i
                className="pi pi-arrow-circle-left nav-back-btn"
                onClick={back}
                style={{
                  marginLeft: "30px",
                  cursor: "pointer",
                  fontSize: "1.5rem",
                }}
              />
            </div>
            <h2 style={{ color: "#00488F" }} className="heading">Hands Free Configuration</h2>
          </div>
          <div className="flex justify-content-center py-2">
            <div
              className=" flex justify-content-between"
              style={{ width: "95%" }}
            >
              <div
                style={{ display: "flex", gap: "5px", alignItems: "center" }}
                className="master-dropdown"
              >
                <div className="mx-2">
                    <h4 className='mb-1 mt-0' style={{color:"#ae9d81",fontSize:18}}>Configuration List</h4>
                    <Dropdown
                      options={masterOptions}
                      placeholder="Select here"
                      value={activeMaster.activeMasterValue}
                      className="dropDownStyle"
                      onChange={(e) => {
                        assginValue("activeMasterValue", e.value);
                        activeStorageValuePicker(e?.value?.name);
                      }}
                      optionLabel="name"
                    />
                </div>
              </div>
              {!isAmountScreen && (
                <div className="flex align-self-end">
                  {/* <div className="flex align-items-center searchFieldInputField mx-3">
                    <i
                      className="pi pi-search"
                      style={{
                        fontSize: 14,
                        textAlign: "center",
                        padding: "0px 5px 0px 10px",
                      }}
                    ></i>
                    <InputText
                      placeholder="Search"
                      className="customeInputField border-0"
                      value={searchText}
                      onChange={(e) => {
                        activeStorageValuePicker(
                          activeMaster?.activeMasterValue?.name,
                          e?.target?.value?.trimStart(),
                          true
                        );
                        setSearchText(e?.target?.value?.trimStart());
                      }}
                    />
                  </div> */}
                  {activeMaster.activeMasterValue?.name=="Baggage Tags"&&<div className="mx-5 ">
                        <Button label='Import' icon={"pi pi-file-import"} style={{ backgroundColor: 'transparent', color: '#ae9d81', border: '1px #ae9d81 solid', borderRadius: '20px', cursor: 'pointer' ,padding:"8px 14px 8px 14px"}} onClick={() => { setIsImport(true) }} />
                    </div>}
                  <Button
                    label="Add"
                    className="defaultButton"
                    onClick={openDialogScreen}
                  ></Button>
                </div>
              )}
            </div>
          </div>
          <div className="flex justify-content-center py-2">
            {!isAmountScreen && (
              <div className="col-11">
                <DataTable
                  value={activeMaster?.activeMasterDashboardValues}
                  tableStyle={{ minWidth: "50rem" }}
                  emptyMessage="No data found"
                >
                  <Column
                    field="name"
                    header={getTitleValue(activeMaster?.activeMasterValue?.name)}
                    className="customeColumn"
                  ></Column>
                  <Column
                    field="status"
                    header="Status"
                    className="customeColumn"
                    body={statusBodyTemplate}
                  ></Column>
                  <Column
                    field="action"
                    header="Action"
                    className="customeColumn"
                    body={actionBodyTemplate}
                  ></Column>
                </DataTable>
                <div className="card">
                  <Paginator
                    first={activeMaster?.pagePositionValue}
                    rows={activeMaster?.rowValue}
                    totalRecords={activeMaster?.totalrecord}
                    onPageChange={(e) => {
                      console.log(e);
                      assginValue("pagePositionValue", e?.first);
                      onPaginating(
                        activeMaster?.activeMasterValue?.name,
                        e?.page
                      );
                    }}
                  />
                </div>
              </div>
            )}
          </div>
          <div className="flex justify-content-center py-2">

            {isAmountScreen && (
              <>
                <div className="col-11">
                  <DataTable
                    value={deliveryCharges}
                    tableStyle={{ minWidth: "50rem" }}
                    emptyMessage="No data found"
                  >
                    <Column
                      field="name"
                      header="Delivery Type-Time"
                      className="customeColumn"
                    ></Column>

                    <Column
                      field="action"
                      header="Action"
                      className="customeColumn"
                      body={inputBodyTemplate}
                    ></Column>
                  </DataTable>
                  {/* <div className="card">
                  <Paginator
                    first={activeMaster?.pagePositionValue}
                    rows={activeMaster?.rowValue}
                    totalRecords={activeMaster?.totalrecord}
                    onPageChange={(e) => {
                      console.log(e);
                      assginValue("pagePositionValue", e?.first);
                      onPaginating(
                        activeMaster?.activeMasterValue?.name,
                        e?.page
                      );
                    }}
                  />
                </div> */}
                </div>

              </>

            )}


          </div>
          <div className="flex justify-content-center py-2">

            {isAmountScreen && deliveryCharges?.length ?
              <Button
                className="defaultButton"
                label={"Submit"}
                onClick={() => setIsConfirmationOpen(true)}
              ></Button>
              :
              ""
            }
          </div>
          <Dialog
            className="customedialogBox"
            header={`${activeMaster?.id ? "Update" : "Add"} ${activeMaster?.activeMasterValue?.name
              }`}
            visible={activeMaster?.modalVisible}
            style={{ width: "30vw" }}
            onHide={() => {
              setIsErrMsgView(false);
            }}
          >
            <div className="text-center">
              <div className="col-11">
                <div className="pb-2 flex align-items-center pr-2">
                  <span className="col-4">Name<span style={{ color: "red" }}>*</span>:</span>
                  <InputText
                    className="col-8"
                    placeholder="Enter name"
                    value={activeMaster?.nameValue}
                    onChange={(e) => {
                      assginValue("nameValue", e.target.value);
                    }}
                  />
                </div>

                <div className="py-2 flex align-items-center">
                  <span className="col-4">Status : </span>
                  <div className="col-8 text-left">
                    <InputSwitch
                      checked={
                        activeMaster?.statusValue == "Active" ? true : false
                      }
                      onChange={(e) => {
                        console.log(e.value);
                        assginValue(
                          "statusValue",
                          e.value ? "Active" : "InActive"
                        );
                      }}
                      style={{ bakgroundColor: "red" }}
                    />
                  </div>
                </div>
                {(activeMaster.activeMasterValue?.name == "Pickup Location" || activeMaster?.activeMasterValue?.name == "Baggage Tags") && (<div className="pb-2 flex align-items-center pr-2">
                  <span className="col-4">Organization<span style={{ color: "red" }}>*</span>:</span>
                  <div className="col-8 text-left">
                    <Dropdown options={organizationList || []} value={activeMaster?.selectedOrganization} optionLabel="name"
                      onChange={(e) => assginValue('selectedOrganization', e?.value)}
                      optionValue="id" placeholder="Select organization" style={{ width: "100%", height: "40px", lineHeight: "14px" }} />
                  </div>
                </div>)}
              </div>
            </div>
            <div className="flex justify-content-center my-3 shop-and-drop-master-modal">
              <div className="mx-2">
                <Button
                  label="Cancel"
                  className="defaultCancelButton"
                  onClick={() => {
                    setIsErrMsgView(false);
                    assginValue("modalVisible", false);
                  }}
                ></Button>
              </div>
              <div className="mx-2">
                <Button
                  className="defaultButton"
                  label={activeMaster?.id ? "Update" : "Submit"}
                  onClick={() => onSubmitting()}
                ></Button>
              </div>
            </div>
            {isErrMsgView && (!activeMaster?.nameValue || ((activeMaster.activeMasterValue?.name == "Pickup Location" || activeMaster?.activeMasterValue?.name == "Baggage Tags") ? !activeMaster?.selectedOrganization : true)) && (
              <div style={{ justifyContent: "center", display: "flex" }}>
                <p style={{ color: "red", margin: 0 }}>Please fill the required fields</p>
              </div>
            )}
          </Dialog>
          <Dialog
            className="customedialogBox"
            header={`Confirmation`}
            visible={isConfirmationOpen}
            style={{ width: "30vw" }}
            onHide={() => {
              setIsConfirmationOpen(false);
            }}
          >
            <div className="text-center">
              <p>Are you sure want to submit the delivery charge?</p>
            </div>
            <div className="flex justify-content-center my-3 shop-and-drop-master-modal">
              <div className="mx-2">
                <Button
                  label="Cancel"
                  className="defaultCancelButton"
                  onClick={() => setIsConfirmationOpen(false)}
                ></Button>
              </div>
              <div className="mx-2">
                <Button
                  className="defaultButton"
                  label="Submit"
                  onClick={() =>
                    handleUpdateAmount()
                  }
                ></Button>
              </div>
            </div>
          </Dialog>
          <DeleteConfirmationModel
            isVisible={activeMaster?.deleteConfirmationVisible}
            setIsVisiblity={onCanceling}
            onClick={onRemoving}
          />
        </div >
      )}

<Dialog header={importHeader} visible={isImport} style={{ width: '50vw' }} closable={true} onHide={() => {setIsImport(false)  }}>
              <div className="popup " >
                  <div className="body">
                      <div className="col">
                          <div className="label grid">
                              <div className='col'>
                                  Sample Template {" "}
                                  <i className="pi pi-download" style={{ color: 'slateblue', marginLeft: "7px", cursor: 'pointer' }}
                                      // on click i need to download a file 
                                      onClick={() => {
                                          var downloadLink = document.createElement("a");
                                          downloadLink.href = Itemcsv;
                                          downloadLink.download = "Itemcsv.csv";
                                          document.body.appendChild(downloadLink);
                                          downloadLink.click();
                                          document.body.removeChild(downloadLink);
                                      }}
                                  ></i>
                              </div>
                          </div>
                      </div>
                      <div className="col">
                          <div className="label grid">
                              <div className='col' style={{ display: "flex", alignItems: "center" }}>Select File<span style={{ color: 'red' }}>*</span> <Modalinfo /></div>
                          </div>
                          <div className="input grid ">
                              <div className="col">
                                  <FileUpload name="demo[]" accept=".csv" mode='basic' multiple={false} maxFileSize={1000000} customUpload uploadHandler={onUpload} emptyTemplate={<p className="m-0">Drag and drop files to here to upload.</p>} />
                                  {isImportInvalid &&
                                      <p style={{ color: "red" }}>Please choose a file</p>
                                  }
                                  {isImportInvalidFile &&
                                      <p style={{ color: "red" }}>Name of the uploaded file should be same as the sample file.</p>
                                  }
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </Dialog>
    </>
  );
};

export default ShopAndDropMasterScreen;
