import React from "react";
import { useEffect, useState } from "react";
import { TabView, TabPanel } from "primereact/tabview";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import "./style.scss";
import { useCookies } from "react-cookie";
import {
  GetFeedbackList,
  GuestStaffSupervisorFeedbacks,
  GuestStaffSupervisorSearchedFeedbacks,
} from "../../Services/FeedbackService";
import { FilterMatchMode } from "primereact/api";
import { Link } from "react-router-dom";
import { InputText } from "primereact/inputtext";
import { Paginator } from "primereact/paginator";

// ----------Assets Import------------
import editIcon from "../../../src/assets/images/editIcon.jpg";

//-----------Context ---------------
import { useDispatch, useSelector } from "react-redux";
import {
  setGuestInspectionsInfo,
  setFeedbackList,
  setNavVisibilty,
} from "../../Redux/AppSlice";
import { Button } from "primereact/button";
import { exportDataInExcel } from "../../Services/ExportServices";

function GuestServiceSupervisor() {
  const Context = useSelector((state) => state.AppContext);
  const dispatch = useDispatch();
  const [cookies, setCookie, removeCookie] = useCookies();
  const [feedbackList, setFeedbackLists] = useState([]);
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    name: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });

  // Pagination
  const [first, setFirst] = useState(0);
  const [totalCount, setTotalRecords] = useState(0);
  const onGlobalFilterChange = (e) => {
    setGlobalFilterValue(e ? e?.target?.value : "");
    if (!e) {
      getFeedbackList(0, 10);
      return;
    }
    getFeedbackList(0, 10, e?.target?.value);
    return;
    const value = e ? e.target.value : "";
    let _filters = { ...filters };
    _filters["global"].value = value;
    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const actionButtonTemplate = (row) => {
    return (
      <Link to={`/guestservicesupervisorview/${row?.feedbackId}`}>
        <img src={editIcon} style={{ cursor: "pointer" }} />
      </Link>
    );
  };

  const statusBodyTemplate = (row) => {
    if (row.status == "Completed") {
      return <p style={{ color: "#3ee63e" }}>Completed</p>;
    } else if (row.status == "Rejected") {
      return <p style={{ color: "red" }}>Rejected</p>;
    } else if (row.status == "Resolved") {
      return <p style={{ color: "#f553e7" }}>Resolved</p>;
    } else if (row.status == "Out of control") {
      return <p style={{ color: "#6ecbfa" }}>Out of control</p>;
    } else if (row.status == "Yet to Ackowledge") {
      return <p style={{ color: "#964B00" }}>Yet to Acknowledge</p>;
    } else if (row.status == "Assigned") {
      return <p style={{ color: "#bd16f5" }}>Assigned</p>;
    } else if (row?.status == "Reassign") {
      return <p style={{ color: "#1512cc" }}>Reassign</p>;
    } else if (row?.status == "Closed") {
      return <p style={{ color: "#3ee63e" }}>Closed</p>;
    }
  };
  const dateBodyTemplateTwo = (row) => {
    let date = new Date(row?.createdDate);
    return `${date.getDate()}/${date.getMonth()}/${date.getFullYear()}`;
  }

  const getFeedbackList = (skip, take, searchValue) => {
    setIsLoading(true);
    let data = {
      take: take,
      skip: skip,
    };
    if (!searchValue) {
      GuestStaffSupervisorFeedbacks(
        { skip: data.skip, take: data.take },
        cookies.bearerToken
      )
        .then((res) => {
          if (res?.status == 200) {
            //console.log('Fetching Feedback  List for GSSupervisor', res?.data);
            setFeedbackLists(res?.data?.guestStaffSupervisorFeedbacks);
            dispatch(
              setFeedbackList({
                feedbackList: res?.data?.guestStaffSupervisorFeedbacks,
              })
            );
            setTotalRecords(res?.data?.totalRecords);
          }
        })
        .catch((error) => {
          //console.log(error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      if (searchValue) {
        data["searchValue"] = searchValue;
      }
      GuestStaffSupervisorSearchedFeedbacks(
        { skip: data.skip, take: data.take, searchValue: data?.searchValue },
        cookies.bearerToken
      )
        .then((res) => {
          if (res?.status == 200) {
            //console.log('Fetching Feedback  List for GSSupervisor', res?.data);
            //console.log(res?.data);
            setFeedbackLists(res?.data?.guestStaffSupervisorFeedbacks);
            dispatch(
              setFeedbackList({
                feedbackList: res?.data?.guestStaffSupervisorFeedbacks,
              })
            );
            setTotalRecords(res?.data?.totalRecords);
          }
        })
        .catch((error) => {
          //console.log(error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  const onPageChange = (e, index) => {
    setFirst(e.first);
    getFeedbackList(e.first, 10);
    //console.log(e);
  };

  const exportFeedbacksForGSupervisor = () => {
    if (globalFilterValue) {
      exportDataInExcel(
        `FeedbacksGSupervisor?searchTerm=${globalFilterValue}`,
        cookies.bearerToken
      );
      return;
    }
    exportDataInExcel("FeedbacksGSupervisor", cookies.bearerToken);
  };

  useEffect(() => {
    getFeedbackList(0, 10);
  }, []);

  return (
    <>
      <div className="guestservicesupervisor">
        <div
          className="header"
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "0 10px",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              flexGrow: "3",
              justifyContent: "flex-start",
            }}
            className="flex align-items-center org"
          >
            <div className="menubtn" style={{ padding: "10px 10px 5px 20px" }}>
              <i
                className="pi pi-align-justify"
                style={{
                  fontSize: "1rem",
                  color: "#ae9d81",
                  cursor: "pointer",
                }}
                onClick={() =>
                  dispatch(setNavVisibilty({ sideNavVisibility: true }))
                }
              ></i>
            </div>
            <div className="heading">
              <p>MG Care Completion</p>
            </div>
          </div>
          <div>
            <Button
              label="Export"
              onClick={totalCount > 0 ? exportFeedbacksForGSupervisor : null}
              icon={"pi pi-file-export"}
              style={{
                backgroundColor: "transparent",
                color: "#ae9d81",
                border: "1px #ae9d81 solid",
                borderRadius: "20px",
                marginRight: "20px",
                cursor: totalCount > 0 ? "pointer" : "not-allowed",
              }}
            />
          </div>
          {/* <div>
                        <Button label='Import' icon={"pi pi-file-import"} style={{ backgroundColor: 'transparent', color: '#ae9d81', border: '1px #ae9d81 solid', borderRadius: '20px', marginRight: '20px' }} />
                    </div> */}
          <div className="search">
            <span className="p-input-icon-left">
              <i
                className="pi pi-search"
                style={{
                  color: "black",
                  fontWeight: "700",
                  fontSize: "1rem",
                }}
              />
              <InputText
                value={globalFilterValue}
                onChange={onGlobalFilterChange}
                placeholder="Search"
              />
              <i
                className="pi pi-times"
                style={{ color: "black", fontWeight: "400" }}
                onClick={() => {
                  setGlobalFilterValue("");
                  onGlobalFilterChange(null);
                }}
              />
            </span>
          </div>
        </div>
        <div className="datatable">
          {isLoading ? (
            <div className="spinner spinner-center"></div>
          ) : (
            <DataTable
              value={feedbackList}
              tableStyle={{ width: "90rem" }}
              style={{ boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)" }}
              stripedRows
              emptyMessage={
                <>
                  <p style={{ textAlign: "center" }}>Feedback list is empty</p>
                </>
              }
              selectionMode="single"
              rows={10}
              filters={filters}
              // selection={selectedFeedback}
              // onSelectionChange={handleRowClick}
              globalFilterFields={[
                "userName",
                "email",
                "category",
                "subCategory",
                "location",
                "mobileNo",
              ]}
            >
              <Column field="code" header="Feedback Id"></Column>
              <Column field="createdDate" header="Created Date" body={dateBodyTemplateTwo}></Column>
              <Column field="userName" header="Name"></Column>
              <Column field="mobileNo" header="Mobile Number"></Column>
              <Column field="email" header="Email"></Column>
              <Column field="category" header="Category"></Column>
              <Column field="subCategory" header="Sub Category"></Column>
              <Column field="location" header="Location"></Column>
              <Column
                field="status"
                header="Status"
                body={statusBodyTemplate}
              />
              <Column
                field="action"
                header="Action"
                body={actionButtonTemplate}
              ></Column>
            </DataTable>
          )}
        </div>
        {isLoading || feedbackList.length == 0 ? (
          ""
        ) : (
          <div style={{ justifyContent: "center", display: "flex" }}>
            <Paginator
              first={first}
              rows={10}
              totalRecords={totalCount}
              onPageChange={(e) => onPageChange(e, 1)}
              template={{
                layout:
                  "RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink  LastPageLink CurrentPageReport",
              }}
              className="justify-content-start"
            />
          </div>
        )}
      </div>
    </>
  );
}


export default GuestServiceSupervisor;

