import Axios from '../Helpers/Axios';
import { isShow } from './ExportServices';

export function GetSubCategory(token,categoryId=0,showAll="false",status="",searchTerm="",skip="0",take="10") {
	return status?
	Axios(`FeedbackSubCategory/GetSubCategories?categoryId=${categoryId}&showAll=${showAll}&status=${status||""}&searchTerm=${searchTerm}&skip=${skip}&take=${take}&forExport=false`, 'get', null, token):
	 Axios(`FeedbackSubCategory/GetSubCategories?categoryId=${categoryId}&searchTerm=${searchTerm}&skip=${skip}&take=${take}&forExport=false`, 'get', null, token);
}
export function AddSubCategory(data, token) {
	return Axios('FeedbackSubCategory/AddSubCategory', 'post', data, token)
}
export function EditSubCategory(data, token) {
	return Axios('FeedbackSubCategory/EditSubCategory', 'patch', data, token)
}