import React, { useEffect, useMemo, useState } from 'react'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { InputText } from 'primereact/inputtext'
import './style.scss'
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { useCookies } from 'react-cookie'
import { Link } from 'react-router-dom';
import { useNavigate } from "react-router";




//------Imports from other Components-----
import { GetHiredItemDetails, GetHiredItemReturnDetails } from '../../Services/HireDetailsViewService';
import { GetHireList } from '../../Services/HireDetailsService';

//-----------Context ---------------
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'
import { setHireDetailsList } from '../../Redux/AppSlice'
import { Button } from 'primereact/button'



function HireDetailsView() {
    // Get id from URL. Passed from hire-details
    const { id } = useParams()
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const Context = useSelector((state) => state.AppContext)
    const [cookies, setCookie, removeCookie] = useCookies()
    const [hiredItems, setHiredItems] = useState([])
    const [hiredItemsReturn, setHiredItemsReturn] = useState([])
    const [isLoading, setIsLoading] = useState(true);
    const [hiredDetails, setHiredDetails] = useState({});



    // const hiredDetails = Context.hireList.hiredItems?.find(h => h.hireId.toString() === id); // 


    //console.log("hiredDetails", hiredDetails);

    const actionButtonTemplate = (row) => {
        return (
            <Link to={'/hire-details'}>
            </Link>
        );
    };
    const isDamagedActionTemplate = (row) => {
        if (row.isDamaged) {
            return <p style={{ color: "red" }}>Yes</p>
        }
        else {
            return <p style={{ color: "green" }}>No</p>
        }
    }


    useEffect(() => {
        const getHireList = () => {
            let data = {
                "take": 5,
                "skip": 0
            }
            GetHireList(data, cookies.bearerToken)
                .then((res) => {
                    // //console.log('res', res)
                    dispatch(
                        setHireDetailsList({
                            hireList: res?.data
                        })
                    )
                })
                .catch((error) => {
                    //console.log(error);
                })
                .finally(() => {
                    // //console.log(hireList)
                });
        }

        getHireList();
    }, [])

    useEffect(() => {
        const getHiredItemDetails = () => {
            setIsLoading(true);
            GetHiredItemDetails(id, cookies.bearerToken)
                .then((res) => {
                    //console.log("GetHiredItemDetails", res?.data);
                    setHiredItems(res?.data);
                })
                .catch((error) => {
                    //console.log(error);
                })
                .finally(() => {
                    setIsLoading(false);

                })
        }

        getHiredItemDetails()
    }, [])
    // useEffect(() => {
    //     const getHiredItemReturnDetails = () => {
    //         GetHiredItemReturnDetails(id, cookies.bearerToken)
    //             .then((res) => {
    //                 // //console.log("Fetching Chat list", res?.data);
    //                 setHiredItemsReturn(res?.data);
    //             })
    //             .catch((error) => {
    //                 //console.log(error);
    //             })
    //     }
    //     getHiredItemReturnDetails()
    // }, [])

    useEffect(() => {
        //console.log(Context.hireList.hiredItems, "hireItems");
        setHiredDetails(Context.hireList.hiredItems?.find(h => h.hireId.toString() === id));
    }, [])
    return (
        <>
            <div className="head">
                <FontAwesomeIcon icon={faArrowLeft} className='arrow-icon' style={{ cursor: "pointer" }} onClick={() => { navigate("/hire-details") }} />View Details
            </div>
            <div className='user-Details-box'>
                <div className='flex'>
                    <div className='flex-text'>Organization:<span style={{ color: 'black', fontWeight: 'normal', fontSize: '15px', paddingLeft: '10px' }}>{hiredDetails?.organization}</span>
                    </div>
                    <div className='flex-text'>Mobile Number:<span style={{ color: 'black', fontWeight: 'normal', fontSize: '15px', paddingLeft: '10px' }}>{hiredDetails?.mobileNumber}</span></div>
                    <div className='flex-text'>Nationality:<span style={{ color: 'black', fontWeight: 'normal', fontSize: '15px', paddingLeft: '10px' }}>{hiredDetails?.nationality}</span></div>
                </div>
                <div className='flex'>
                    <div className='flex-text'>Desk:<span style={{ color: 'black', fontWeight: 'normal', fontSize: '15px', paddingLeft: '10px' }}>{hiredDetails?.deskName}</span></div>
                    <div className='flex-text'>Customer Name:<span style={{ color: 'black', fontWeight: 'normal', fontSize: '15px', paddingLeft: '10px' }}>{hiredDetails?.customerName}</span></div>
                    <div className='flex-text'>Retainer Type:<span style={{ color: 'black', fontWeight: 'normal', fontSize: '15px', paddingLeft: '10px' }}>{hiredDetails?.depositType}</span></div>
                </div>
                <div className='flex'>
                    <div className='flex-text'>Help Desk User:<span style={{ color: 'black', fontWeight: 'normal', fontSize: '15px', paddingLeft: '10px' }}>{hiredDetails?.helpDeskUserNumber}</span></div>
                    <div className='flex-text'>Email Id:<span style={{ color: 'black', fontWeight: 'normal', fontSize: '15px', paddingLeft: '10px' }}>{hiredDetails?.emailID}</span></div>
                    <div className='flex-text'>Amount:<span style={{ color: 'black', fontWeight: 'normal', fontSize: '15px', paddingLeft: '10px' }}>{hiredDetails?.paidAmount} BHD</span></div>
                </div>
            </div>
            <div className='item-Details-box'>
                <div className='left-side-box'>
                    <h3 className='first-h3'>Return Details</h3>
                    <div style={{ padding: '5px' }}>
                        {/* <div>
                            <h4>Is-Damaged</h4>
                          <p>{hiredItems.moh ? "true" : "false"}</p> 
                            <InputText className='text-box' disabled value={hiredItems.filter((item) => item?.moh == true).length > 0 ? "Yes" : "No"} />
                        </div> */}
                        <div>
                            <h4>Return Amount</h4>
                            <InputText className='text-box' disabled
                                //  value={hiredItems.map((item) => item?.returnAmount).reduce((accumulator, currentvalue) => {
                                //     return accumulator + currentvalue
                                // }, 0)} 
                                value={hiredDetails?.paidAmount + " BHD"}
                            />
                        </div>
                    </div>
                </div>
                <div className="vl" style={{ marginTop: "15px" }}></div>
                <div className='right-side-box'>
                    <h3 className='second-h3'>Check In Details</h3>
                    <div >
                        {isLoading ?
                            <div className='spinner spinner-center' style={{ marginLeft: '100px' }}></div>
                            :
                            <DataTable className="custom-datatable" value={hiredItems}>
                                <Column field="itemName" header="Product"></Column>
                                <Column field="checkOutQuantity" header="Check Out Items"></Column>
                                <Column field="returnQuantity" header="Return Items"></Column>
                                <Column header="Is Damaged" body={isDamagedActionTemplate}></Column>
                            </DataTable>}
                        <Button className='cancel-btn' style={{ margin: "0 30px" }} onClick={() =>
                            navigate("/hire-details")
                        }
                        >Close</Button>

                    </div>

                </div>
            </div>

        </>
    )
}

export default HireDetailsView