import { Column } from "primereact/column"
import { DataTable } from "primereact/datatable"
import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { setNavVisibilty } from "../../Redux/AppSlice"
import { useCookies } from "react-cookie"
import { Button } from "primereact/button"
import { Dialog } from "primereact/dialog"
import { InputText } from "primereact/inputtext"
import { AddPantryCategory, DeletePantryCategory, EditPantryCategory, GetPantryCategoryList } from "../../Services/PantryCategoryService"
import { toast } from "react-toastify"
import { useNavigate } from "react-router-dom"
import { exportFilterdExcel } from "../../Services/ExportServices"


 const CategoryMasterForPantry=()=>{
  const navigate=useNavigate();
   const [cookies,setCookies,removeCookies]=useCookies();
   const initialCategoryState={
    categoriesList:[],
    isModalVsible:false,
    categoryValue:"",
    isTrigger:false,
    id:0,
    isActiveAdd:true,
    isDeletedModel:false
   }
    const [categoryObj,setCategoryObj]=useState(initialCategoryState)
    const dispatch=useDispatch();

    const statusBodyTemplate = (row) => {
        return (
          <div
            className="flex align-items-center"
            style={{ justifyContent: "unset" }}
          >
            <div className="px-2">
              <span
                style={{
                  color: !row?.isDeleted? "green" : "red",
                  fontWeight: "500",
                }}
              >
                {!row?.isDeleted? "Active" :"InActive"}
              </span>
            </div>
          </div>
        );
      };
      const actionBodyTemplate = (row) => {
        return (
        <div className="d-flex align-items-center justify-content-center">
       
                <span className="px-2">
                  <i onClick={()=>{setCategoryObj((prev)=>({...prev,isModalVsible:true,isTrigger:false,id:row?.id,isActiveAdd:false,categoryValue:row?.name}))}}
                    className="pi pi-pencil"
                    style={{ fontSize: "1rem", cursor: "pointer", color: "blue" }}
                  />
                </span>
                <span className="px-2">
                  <i onClick={()=>setCategoryObj(prev=>({...prev,isDeletedModel:true,id:row?.id}))}
                    className="pi pi-trash"
                    style={{ fontSize: "1rem", cursor: "pointer", color: "red" }}
                  />
                </span>
        </div>
         
        );
      };

      const getPantryCategoryList=async()=>{
        await GetPantryCategoryList(cookies?.bearerToken).then(res=>{
            console.log(res?.data);
            if(res?.status==200){
                setCategoryObj((prev)=>({...prev,categoriesList:res?.data}));
            }
        })
      }
      const addPantryCategory=async()=>{
        let data={
            name:categoryObj?.categoryValue||""
        }
        await AddPantryCategory(data,cookies?.bearerToken).then(res=>{
            if(res?.status==200){
                toast.success("Category successully added");
                getPantryCategoryList();
                setCategoryObj((prev)=>({...prev,isModalVsible:false}));
            }
        })
      }
      const upadtePantryCategory=async()=>{
        let data={
            name:categoryObj?.categoryValue||"",
            id:categoryObj?.id||0
        }
        await EditPantryCategory(data,cookies?.bearerToken).then(res=>{
            if(res?.status==200){
                toast.success("Category successully updated");
                getPantryCategoryList();
                setCategoryObj((prev)=>({...prev,isModalVsible:false}));
            }
        })
      }
     const deletePantryCategory=async(id)=>{
        let data={
            id:id
        }
       await DeletePantryCategory(data,cookies?.bearerToken).then(res=>{
        if(res?.status==200){
            getPantryCategoryList();
            toast.success("Category successully removed");
            setCategoryObj(prev=>({...prev,isDeletedModel:false,id:0}));
        }
       })
     }
     const checkCategoryIsExisit=()=>{
       return categoryObj?.categoriesList?.some(_=>_?.name==categoryObj?.categoryValue);
     }
     const onSubmit=(key)=>{

        setCategoryObj((prev)=>({...prev,isTrigger:true}));

        if(!categoryObj?.categoryValue) return
      if(checkCategoryIsExisit()){
        toast.info("Duplicate founded");
          return
      }
      switch (key) {
        case "Add":
            console.log("Add");
            addPantryCategory()
            break;
        case "Edit":
            upadtePantryCategory()
            break;
          
        default:
            break;
      }
     }
     const back = () => {
      navigate('/dashboard');
     };
     

     useEffect(()=>{
        getPantryCategoryList();
     },[])
    return(
        <div className="container">
            <div className="header" style={{justifyContent:"space-between",display:"flex"}}>
                    <div style={{ display: "flex" }} className="align-items-center">
                        <div className="menubtn" style={{ padding: "10px 10px 5px 20px" }}>
                           <i className="pi pi-arrow-circle-left nav-back-btn" onClick={back} style={{ marginLeft: '30px', cursor: 'pointer', fontSize: '1.5rem' }} />
                        </div>
                        <div className="heading">
                        <p className="configuration">Category Master</p>
                        </div>
                    </div>
                    <div className="flex align-items-center">
                      {/* <div className="mx-2 align-self-end">
                          <Button label='Export' icon={"pi pi-file-export"} style={{ backgroundColor: 'transparent', color: '#ae9d81', border: '1px #ae9d81 solid', borderRadius: '20px',height:"44px" }} onClick={onExportCategoryMaster} />
                      </div> */}
                      <div className="mx-2 align-self-end">
                        <Button onClick={()=>{setCategoryObj((prev)=>({...prev,isModalVsible:true,isTrigger:false,id:0,isActiveAdd:true,categoryValue:""}))}} className="w-25" style={{backgroundColor:"#ae9d81",border:0,width:180,height:40,textAlign:"center",display:"flex",justifyContent:"center"}}>
                            Add category
                        </Button>
                      </div>
                    </div>
            </div>
          <div className="col-12 px-4">
                    <DataTable
                    value={categoryObj?.categoriesList}
                    tableStyle={{ minWidth: "50rem" }}
                    emptyMessage="No data found"
                    paginator={categoryObj?.categoriesList?.length>0}
                     rows={10}
                    >
                    <Column
                        field="name"
                        header="Name"
                        className="customeColumn"
                    ></Column>
                    <Column
                        field="status"
                        header="Status"
                        className="customeColumn"
                        body={statusBodyTemplate}
                    ></Column>
                    <Column
                        field="action"
                        header="Action"
                        className="customeColumn"
                        body={actionBodyTemplate}
                    ></Column>
                    </DataTable>
                    
            </div>
            <Dialog style={{width:"30%"}} header={`${categoryObj?.isActiveAdd?"Add":"Edit"} category`} headerStyle={{color:"#ae9d81"}}  visible={categoryObj?.isModalVsible} onHide={()=>setCategoryObj((prev)=>({...prev,isModalVsible:false}))}>
                <div className="px-5">
                    <h4>Category Name <span style={{color:"red"}}>*</span></h4>
                     <InputText className="w-full" value={categoryObj?.categoryValue} placeholder="Enter category Name" onChange={(e)=>setCategoryObj((prev)=>({...prev,categoryValue:e.target.value||""}))}/>
                    {
                        categoryObj?.isTrigger&&!categoryObj?.categoryValue&&<p style={{color:"red"}}>Category name is required</p>
                    }
                </div>
                <div className="text-center my-4">
                    
                    <Button style={{backgroundColor:"#ccc",border:0,width:100,height:40}} className="mx-2" onClick={()=>{
                    setCategoryObj((prev)=>({...prev,categoryValue:"",id:0,isActiveAdd:true,isModalVsible:false,isTrigger:false}))
                    }}>
                        Cancel
                    </Button>

                    <Button style={{backgroundColor:"#ae9d81",border:0,width:100,height:40}}  className="mx-2" onClick={()=>{
                       categoryObj?.isActiveAdd?onSubmit("Add"):onSubmit("Edit");
                    }}>
                        {categoryObj?.isActiveAdd?"Submit":"Update"}
                    </Button>
                </div>
            </Dialog>
            <Dialog header={"Confirmation"} headerStyle={{color:"#ae9d81"}}  visible={categoryObj?.isDeletedModel} onHide={()=>setCategoryObj((prev)=>({...prev,isDeletedModel:false}))}>
                <div>
                    <h4>Are you sure want to remove category</h4>
                </div>
                <div className="text-center my-4">
                    
                <Button style={{backgroundColor:"#ccc",border:0,width:100,height:40}} className="mx-2" onClick={()=>{
                    setCategoryObj((prev)=>({...prev,isDeletedModel:false}))
                    }}>
                        Cancel
                    </Button>

                    <Button style={{backgroundColor:"#ae9d81",border:0,width:100,height:40}}  className="mx-2" onClick={()=>{
                       deletePantryCategory(categoryObj?.id)
                    }}>
                        Confirm
                    </Button>
                </div>
            </Dialog>
        </div>
    )

}

export default CategoryMasterForPantry;