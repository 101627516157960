import { useRef, useState } from 'react';
import { useCookies } from 'react-cookie';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import './style.scss';
import { Button } from 'primereact/button';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { GetActiveStorageLocation, GetFoundItem, GetStatusForFoundItem, EditFoundItemForGSR } from '../../Services/LostandFoundService';
import { CiImageOn } from 'react-icons/ci';
import { DownloadFile, getAttachmentFile } from '../../Services/Files';
import { toast } from 'react-toastify';
import IntlTelInput from 'react-intl-tel-input';
import { Dialog } from 'primereact/dialog';
import nationalityLists from "../../assets/JSON/Nationality.json";
import nationalityListJSON from "../../assets/JSON/NationalityFlags.json";


function EditFoundItem() {
    const inputRef = useRef(null);
    const localizationValue = "en";
    const { id, route } = useParams();
    const navigate = useNavigate();
    const [cookies, setCookie, removeCookie] = useCookies();
    const [foundItem, setFoundItem] = useState({

        "id": 6,
        "firstName": "",
        "lastName": "",
        "email": "",
        "phoneNumber": "",
        "countryCode": "",
        "categoryId": "",
        "whatDidYouFind": "",
        "foundDateTime": "",
        "whereDidYouFind": "strng",
        "statusId": "",
        "storageLocationId": "",
        "isDeleted": false,
        "files": []
    });
    const [status, setStatus] = useState([]);
    const [storageLocation, setStorageLocation] = useState([]);
    const [previousStatusId, setPreviousStatusId] = useState(0);
    const [isFormSubmitting, setIsFormSubmitting] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    /**
     * @author deepakraj
     * @description the state for selected country
     */
    const [selectedCountry, setSelectedCountry] = useState({
        "english_name": "Bahrain",
        "arabic_name": "البحرين",
        "alpha2_code": "BH",
        "alpha3_code": "BHR",
        "phone_code": "+973",
        "flagImage": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BH.svg"
    });
    //   ----------------------------------------------------------------


    // File preview-Deepak
    const [previewLoader, setPreviewLoader] = useState({
        id: "",
        isLoad: false,
    });
    const [previewAttachment, setPreviewAttachment] = useState({
        isOpen: false,
        attachmentUrl: "",
    });
    //   --------------------


    // Mobile number with conutry code - Deepak
    const getMobileNumber = () => {
        let mobileNumber = inputRef?.current?.selectedCountryData?.dialCode + inputRef?.current?.state?.value
        assignValue("phoneNumber", "+" + mobileNumber)
        return mobileNumber;
    };
    const assignValue = (key, value) => {
        setFoundItem((prevState) => {
            return {
                ...prevState,
                [key]: value
            };
        });
    };
    const handlePhoneChange = (value, countryData) => {
        if (
            value?.length <= 12 &&
            /^[0-9]+$|^$/.test(value) &&
            value !== foundItem?.phoneNumber
        ) {
            setFoundItem({
                ...foundItem,
                phoneNumber: value,
                countryCode: countryData?.dialCode,
            });
        }
        // setPhoneNumber(value);
    };

    const getSelectedCountryName = () => {
        let selectedCountry = "bh";
        if (nationalityLists && foundItem?.countryCode) {
            selectedCountry = nationalityLists?.filter(
                (val) => val.phone_code === foundItem?.countryCode
            )[0]?.alpha2_code?.toLowerCase();
        }
        console.log("selectedCountry", selectedCountry);
        return selectedCountry ? selectedCountry : "bh";
    };
    // ------------------------------


    const handleSubmit = async () => {
        setIsFormSubmitting(true)
        let data = {
            "id": foundItem.id,
            "firstName": foundItem.firstName,
            "lastName": foundItem.lastName,
            "email": foundItem.email,
            "phoneNumber": foundItem.phoneNumber,
            "countryCode": foundItem.countryCode,
            "categoryId": foundItem.categoryId,
            "whatDidYouFind": foundItem.whatDidYouFind,
            "foundDateTime": foundItem.foundDateTime,
            "whereDidYouFind": foundItem.whereDidYouFind,
            "statusId": foundItem.statusId,
            "storageLocationId": foundItem.storageLocationId,
            "statusId": foundItem.statusId,
        };
        await EditFoundItemForGSR(data, cookies.bearerToken)
            .then((res) => {
                if (res.status === 200) {
                    //console.log("Found Item", res?.data);
                    route == 1 ? navigate('/lostandfound/3') : navigate('/security-staff/2')
                    toast.success("Found item updated successfully", { position: "top-right" })
                }
            })
            .catch((err) => {
                //console.log(err);
            })
            .finally(() => {
                if (route === "g2") {
                    navigate('/lostandfound/2')
                }
                if (route === "g4") { navigate('/lostandfound/4') }
                if (route == "s1") { navigate('/security-staff/1') }
                if (route == "s3") { navigate('/security-staff/3') }
            })

    }





    const getFoundItem = async (foundId) => {
        setIsLoading(true);
        await GetFoundItem(foundId, cookies.bearerToken)
            .then((res) => {
                if (res.status === 200) {
                    // console.log("Found Item", res?.data);
                    setFoundItem(res?.data);
                    setPreviousStatusId(res?.data?.statusId);
                    let countryCode = res?.data?.countryCode.includes("+") ? res?.data?.countryCode : "+" + res?.data?.countryCode;
                    let tempSelectedCountry = nationalityListJSON?.filter((country) => country?.phone_code === countryCode)[0];
                    // console.log("Filtered country", tempSelectedCountry);
                    setSelectedCountry(tempSelectedCountry);
                    // getSelectedCountryName();

                }
            })
            .catch((err) => {
                //console.log(err);
            })
            .finally(() => {
                setIsLoading(false);
            })
    };
    const downloadFile = (_) => {
        DownloadFile(_.fileName, _.blobUrl, cookies?.bearerToken);
    };
    const previewImage = async (url) => {
        console.log(url);
        setPreviewLoader({ id: url.moduleId, isLoad: true });
        let data = [
            {
                fileName: url.fileName,
                azureBlobUrl: url.blobUrl.replaceAll("\\", "/"),
            },
        ]
        await getAttachmentFile(data, cookies?.bearerToken)
            .then((res) => {
                console.log(res);
                setPreviewAttachment({
                    isOpen: true,
                    attachmentUrl: res?.data?.[0].base64,
                });
                setPreviewLoader({ id: "", isLoad: false });
            })
            .catch((err) => {
                console.log(err);
            });
    };




    const getStatusForFoundItem = async () => {
        await GetStatusForFoundItem(cookies.bearerToken)
            .then((res) => {
                if (res.status === 200) {
                    //console.log("Found Item Status", res?.data);
                    // Set a status list in react state
                    setStatus(res?.data)
                }
            })
            .catch((err) => {
                //console.log(err);
            })
            .finally(() => {
            })
    };

    // Get Storage Location
    const getStorageLocation = async () => {
        await GetActiveStorageLocation(cookies.bearerToken)
            .then((res) => {
                if (res.status === 200) {
                    //console.log("Storage Location", res?.data);
                    setStorageLocation(res?.data);
                }
            })
            .catch((err) => {
                //console.log(err);
            })
            .finally(() => {
            })
    }


    /**
     * @author deepakraj
     * @description country picker handle function
     * @date 12/7/24
     */
    const handleChange = (value) => {
        console.log("Change", value);
        setFoundItem({
            ...foundItem,
            phoneNumber: value
        });
    }
    const handleCountryChange = (value) => {
        console.log("Change", value);
        setFoundItem({
            ...foundItem,
            countryCode: value?.phone_code.includes("+") ? value.phone_code : "+" + value.phone_code
        });
    }
    const selectedCountryTemplate = (option, props) => {
        // console.log(option);
        if (option) {
            return (
                <div className="flex align-items-center">
                    <img alt={option.flagImage} src={option?.flagImage} style={{ width: '18px' }} />
                </div>
            );
        }
        return <span>{props.placeholder}</span>;
    };
    const countryOptionTemplate = (option) => {
        return (
            <div className="flex align-items-center">
                <img alt={option.name} src={option?.flagImage} style={{ width: '18px' }} />
                <div>{option?.english_name}({option?.arabic_name}){option?.phone_code}</div>
            </div>
        );
    };
    // ----------------------------------------------------------------


    useEffect(() => {
        getStatusForFoundItem();
        getStorageLocation();
        getFoundItem(id);
    }, [])

    return (
        <>
            {isLoading ?
                <div className='spinner spinner-center' style={{ marginLeft: "700px" }}></div>
                :
                <div className="EditFoundItem ">
                    <Dialog
                        className="preview-Attachment-Popup"
                        visible={previewAttachment.isOpen}
                        onHide={() => {
                            setPreviewAttachment({ isOpen: false, attachmentUrl: "" });
                        }}
                        style={{ width: "50%" }}
                        header={
                            <h3 style={{ textAlign: "center", color: "#ae9d81", margin: 0 }}>
                                Preview
                            </h3>
                        }
                    >
                        <img
                            src={"data:image/jpeg;base64," + previewAttachment.attachmentUrl}
                            style={{ height: 400, width: 600 }}
                        ></img>
                    </Dialog>
                    <div className="header" style={{ backgroundColor: '#ae9d81', color: 'white' }}>
                        <i className="pi pi-arrow-left" style={{ cursor: "pointer" }} onClick={() => { if (route === "g2") { navigate('/lostandfound/2') } if (route === "g4") { navigate('/lostandfound/4') } if (route == "s1") { navigate('/security-staff/1') } if (route == "s3") { navigate('/security-staff/3') } }}></i>
                        <div style={{ margin: 'auto', fontWeight: '600', fontSize: '18px' }}>Edit Found Item</div>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between', margin: '0px 50px' }}>
                        <div>
                            <p>First Name</p>
                            <div>
                                <InputText disabled placeholder='Enter First Name' style={{ width: '350px' }} value={foundItem?.firstName} />
                            </div>
                        </div>
                        <div>
                            <p>Last Name</p>
                            <div>
                                <InputText disabled placeholder='Enter Last Name' style={{ width: '350px' }} value={foundItem?.lastName} />
                            </div>
                        </div>
                        <div>
                            <p>Email</p>
                            <div>
                                <InputText disabled placeholder='Enter Email' style={{ width: '350px' }} value={foundItem?.email} />
                            </div>
                        </div>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between', margin: '20px 50px 0px 50px' }}>
                        <div>
                            <p>Mobile Number</p>
                            <div>
                                {/* <InputText disabled placeholder='Enter Mobile Number' style={{ width: '350px' }} value={foundItem?.phoneNumber} />
                                <IntlTelInput
                                    value={foundItem?.phoneNumber}
                                    style={{ width: "350px", color: "#a6a5a5" }}
                                    noCountryDataHandler={(e) => console.log(e)}
                                    disabled={true}
                                    containerClassName="intl-tel-input phoneInputContainerStyle"
                                    inputClassName={"phoneInputStyle"}
                                    onSelectFlag={(value, countryData) => {
                                        setFoundItem({ ...foundItem, countryCode: countryData?.dialCode })
                                    }}
                                    onPhoneNumberChange={(
                                        valid,
                                        value,
                                        countryData,
                                        number,
                                        id
                                    ) => {
                                        handlePhoneChange(value, countryData);

                                    }}
                                    // countryData={getSelectedCountryData()}
                                    // initialCountry={userInformations?.phoneNumberCode}
                                    defaultCountry={getSelectedCountryName()}
                                    // tempCountry="bh"
                                    preferredCountries={["bh", "ae"]}
                                    ref={inputRef}
                                /> */}
                                <div>
                                    <Dropdown disabled value={selectedCountry} style={{ width: "100px !important", border: "none" }} onChange={(e) => { console.log(e.value); setSelectedCountry(e.value); handleCountryChange(e.value) }} options={nationalityListJSON} optionLabel="english_name"
                                        valueTemplate={selectedCountryTemplate} itemTemplate={countryOptionTemplate}
                                    />
                                    <InputText disabled style={{ width: '50%', border: "none" }} value={foundItem?.phoneNumber} type='tel' min={0} placeholder='Enter here' onChange={(e) => { handleChange(e.target.value) }}></InputText>
                                </div>
                            </div>
                        </div>
                        <div>
                            <p>Category</p>
                            <div>
                                <InputText disabled placeholder='Select Category' style={{ width: '350px', borderColor: '#ae9d81' }} value={foundItem?.category} />
                            </div>
                        </div>
                        <div>
                            <p>What did you Found?</p>
                            <div>
                                <InputText disabled placeholder='Enter what did you found' style={{ width: '350px' }} value={foundItem?.whatDidYouFind} />
                            </div>
                        </div>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between', margin: '20px 50px 0px 50px' }}>
                        <div>
                            <p> When did you Found?(Date)</p>
                            <div>
                                <Calendar disabled placeholder='Select date' className="calendar" style={{ width: '350px' }} showIcon value={new Date(foundItem?.foundDateTime)} maxDate={new Date()} />
                            </div>
                        </div>
                        <div>
                            <p>Where did you Found?</p>
                            <div>
                                <InputText disabled placeholder='Enter where did you found' style={{ width: '350px', }} value={foundItem?.whereDidYouFind} />
                            </div>
                        </div>
                        <div>
                            <p>Attachment</p>
                            {/* <div>
                        <InputText disabled style={{ width: '350px', height: '150px' }} />
                    </div> */}
                            <div className='defaultWidth'>
                                <div style={{
                                    height: "100px",
                                    overflow: "auto",
                                    width: '350px',
                                    border: "solid 1px rgba(201,194,181,1)",
                                    borderRadius: '4px',
                                    padding: "10px",
                                }}>
                                    {
                                        foundItem?.files?.map((_, index) => {
                                            return (
                                                <div key={index} style={{ display: "flex", alignItems: "center" }}>
                                                    <CiImageOn style={{ fontSize: 20 }} />
                                                    <p style={{ fontSize: '12px', padding: "0 10px", cursor: "pointer" }} onClick={() => {
                                                        // downloadFile(_);
                                                        previewImage(_);
                                                    }}>{_.fileName}</p>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                                {/* <InputText className={classNames(style.border, style.attachment)} /> */}
                            </div>
                        </div>
                    </div>

                    <div style={{ display: 'flex', justifyContent: 'space-between', margin: '20px 50px 0px 50px' }}>
                        <div style={{ width: "250px", height: "100px" }}  >
                            <p> Storage Location</p>
                            <div>
                                <Dropdown options={storageLocation} placeholder='Select Storage Location' className="calendar" style={{ width: '350px', borderColor: '#ae9d81' }}
                                    disabled={previousStatusId !== 1 ? true : false}
                                    optionLabel='name' optionValue='id' value={foundItem?.storageLocationId} onChange={(e) => { setFoundItem({ ...foundItem, storageLocationId: e.value }) }} />
                            </div>
                        </div>
                        <div style={{ width: "300px", height: "100px" }}  >
                            <p> Status</p>
                            <div>
                                <Dropdown options={status} placeholder='Select status' className="calendar" style={{ width: '350px', borderColor: '#ae9d81' }}
                                    disabled={previousStatusId !== 1 ? true : false}
                                    value={foundItem?.statusId} optionLabel='name' optionValue='id' onChange={(e) => { setFoundItem({ ...foundItem, statusId: e.value }) }} />
                            </div>
                        </div>
                        <div style={{ width: "300px", height: "100px" }} >
                            {/* <p> Status</p>
                    <div>
                        <Dropdown options={status} placeholder='Select status' className="calendar" style={{ width: '350px', borderColor: '#ae9d81' }}
                            value={foundItem?.statusId} optionLabel='name' optionValue='id' onChange={(e) => { setFoundItem({ ...foundItem, statusId: e.value }) }} />
                    </div> */}
                        </div>

                    </div>

                    <div style={{ display: 'flex', justifyContent: 'end', gap: '20px', margin: "10px 50px 50px 0px" }}>
                        <Button label='Cancel' className='cnl-btn' onClick={() => { if (route === "g2") { navigate('/lostandfound/2') } if (route === "g4") { navigate('/lostandfound/4') } if (route == "s1") { navigate('/security-staff/1') } if (route == "s3") { navigate('/security-staff/3') } }} />
                        {previousStatusId == 1 ? (
                            <Button label='Update' className='smt-btn' loading={isFormSubmitting} onClick={() => { handleSubmit() }} />
                        ) : ""}

                    </div>

                </div>
            }
        </>
    )
}

export default EditFoundItem 
