import React, { useState } from "react";
import { useSelector } from "react-redux";
//styles
import "./ShopAndDropHeader.scss";

const ShopAndDropHeader = (props) => {
  const stepperDetails = useSelector(
    (state) => state.AppContext.stepperDetails
  );

  return (
    <>
      <div className="shop-and-drop-header">
        <div className="shop-and-drop-header-left">
          <i className="pi pi-truck" style={{}} onClick={() => { }}></i>
          {props.isCheckIn ? (
            <h2 style={{ color: "#ae9d81" }}>
             HF Delivery service{" "}
              {stepperDetails.stepperData.map((val, index) => {
                if (index <= stepperDetails.selecetdStepperIndex) {
                  return (
                    <>
                      {" "}
                      &gt;{" "}
                      <span
                        key={index}
                        style={
                          index === stepperDetails.selecetdStepperIndex
                            ? {
                              color: "#000",
                              // color: "#ae9d81",
                            }
                            : null
                        }
                      >
                        {val.stepLabel}
                      </span>
                    </>
                  );
                }
              })}
            </h2>
          ) : (
            <h2>Track Delievries</h2>
          )}
        </div>
      </div>
    </>
  );
};

export default ShopAndDropHeader;
