import Axios from "../Helpers/Axios";

export function ImportBaggageTag(data, token) {
    return Axios(`Excel/BulkImportTags`, "post", data, token, true)
}
export function ImportVehichleCountry(data, token) {
    return Axios(`Excel/BulkImportVehicleCountry`, "post", data, token, true)
}
export function ImportVehicleBrand(data, token) {
    return Axios(`Excel/BulkImportVehicleBrand`, "post", data, token, true)
}