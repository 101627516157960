import Axios from "../Helpers/Axios";

export function AddStore(data, token) {
  return Axios("ShopAndDrop/SADMaster/AddStore", "post", data, token);
}
export function EditStore(data, token) {
  return Axios("ShopAndDrop/SADMaster/EditStore", "patch", data, token);
}
export function DeleteStore(storeId, token) {
  return Axios(
    `ShopAndDrop/SADMaster/DeleteStore?storeId=${storeId}`,
    "delete",
    null,
    token
  );
}
export function GetActiveStores(skip, take, searchTerm, token) {
  return Axios(
    `ShopAndDrop/SADMaster/GetActiveTenants?searchTerm=${searchTerm}&skip=${skip}&take=${take}
        `,
    "get",
    null,
    token
  );
}
export function GetDeskLocation(searchTerm, token) {
  return Axios(
    `Master/Desk?organizationId=${searchTerm}
        `,
    "get",
    null,
    token
  );
}
export function GetActiveDeskLocation(searchTerm, token) {
  return Axios(
    `Master/ActiveDesk?organizationId=${searchTerm}
        `,
    "get",
    null,
    token
  );
}
export function GetStores(skip, take, searchTerm, token) {
  return searchTerm
    ? Axios(
      `ShopAndDrop/SADMaster/GetStores?searchTerm=${searchTerm}&skip=${skip}&take=${take}`,
      "get",
      null,
      token
    )
    : Axios(
      `ShopAndDrop/SADMaster/GetStores?skip=${skip}&take=${take}`,
      "get",
      null,
      token
    );
}
export function AddPickupLocation(data, token, orgId) {
  return Axios(`ShopAndDrop/SADMaster/AddPickupLocation?orgId=${orgId}`, "post", data, token);
}
export function EditPickupLocation(data, token, orgId) {
  return Axios(
    `ShopAndDrop/SADMaster/EditPickupLocation?orgId=${orgId}`,
    "patch",
    data,
    token
  );
}
export function DeletePickupLocation(pickupLocationId, token) {
  return Axios(
    `ShopAndDrop/SADMaster/DeletePickupLocation?pickupLocationId=${pickupLocationId}`,
    "delete",
    null,
    token
  );
}
export function GetActivePickupLocation(skip, take, searchTerm, token) {
  return searchTerm
    ? Axios(
      `ShopAndDrop/SADMaster/GetActivePickupLocations?searchTerm=${searchTerm}&skip=${skip}&take=${take}`,
      "get",
      null,
      token
    )
    : Axios(
      `ShopAndDrop/SADMaster/GetActivePickupLocations?skip=${skip}&take=${take}`,
      "get",
      null,
      token
    );
}
export function GetPickupLocation(skip, take, searchTerm, token) {
  return searchTerm
    ? Axios(
      `ShopAndDrop/SADMaster/GetPickupLocations?searchTerm=${searchTerm}&skip=${skip}&take=${take}`,
      "get",
      null,
      token
    )
    : Axios(
      `ShopAndDrop/SADMaster/GetPickupLocations?skip=${skip}&take=${take}`,
      "get",
      null,
      token
    );
}
export function GetPickupLocationsForAdmin(skip, take, searchTerm, token) {
  return searchTerm
    ? Axios(
      `ShopAndDrop/SADMaster/GetPickupLocationsForAdmin?searchTerm=${searchTerm}&skip=${skip}&take=${take}`,
      "get",
      null,
      token
    )
    : Axios(
      `ShopAndDrop/SADMaster/GetPickupLocationsForAdmin?skip=${skip}&take=${take}`,
      "get",
      null,
      token
    );
}

export function AddDropLocation(data, token) {
  return Axios("ShopAndDrop/SADMaster/AddDropLocation", "post", data, token);
}
export function EditDropLocation(data, token) {
  return Axios("ShopAndDrop/SADMaster/EditDropLocation", "patch", data, token);
}
export function DeleteDropLocation(dropLocationId, token) {
  return Axios(
    `ShopAndDrop/SADMaster/DeleteDropLocation?dropLocationId=${dropLocationId}`,
    "delete",
    null,
    token
  );
}
export function GetActiveDropLocation(skip, take, searchTerm, token) {
  return searchTerm
    ? Axios(
      `ShopAndDrop/SADMaster/GetActiveDropLocations?searchTerm=${searchTerm}&skip=${skip}&take=${take}`,
      "get",
      null,
      token
    )
    : Axios(
      `ShopAndDrop/SADMaster/GetActiveDropLocations?skip=${skip}&take=${take}`,
      "get",
      null,
      token
    );
}
export function GetDropLocation(skip, take, searchTerm, token) {
  return searchTerm
    ? Axios(
      `ShopAndDrop/SADMaster/GetDropLocations?searchTerm=${searchTerm}&skip=${skip}&take=${take}`,
      "get",
      null,
      token
    )
    : Axios(
      `ShopAndDrop/SADMaster/GetDropLocations?skip=${skip}&take=${take}`,
      "get",
      null,
      token
    );
}

export function AddTag(data, token, orgId) {
  return Axios(`ShopAndDrop/SADMaster/AddTag?orgId=${orgId}`, "post", data, token);
}
export function EditTag(data, token, orgId) {
  return Axios(`ShopAndDrop/SADMaster/EditTag?orgId=${orgId}`, "patch", data, token);
}
export function DeleteTag(tagId, token) {
  return Axios(
    `ShopAndDrop/SADMaster/DeleteTag?tagId=${tagId}`,
    "delete",
    null,
    token
  );
}

export function GetActiveTag(skip, take, searchTerm, token) {
  return searchTerm
    ? Axios(
      `ShopAndDrop/SADMaster/GetActiveTags?searchTerm=${searchTerm}&skip=${skip}&take=${take}`,
      "get",
      null,
      token
    )
    : Axios(
      `ShopAndDrop/SADMaster/GetActiveTags?skip=${skip}&take=${take}`,
      "get",
      null,
      token
    );
}
export function GetTag(skip, take, searchTerm, token) {
  return searchTerm
    ? Axios(
      `ShopAndDrop/SADMaster/GetTags?searchTerm=${searchTerm}&skip=${skip}&take=${take}`,
      "get",
      null,
      token
    )
    : Axios(
      `ShopAndDrop/SADMaster/GetTags?skip=${skip}&take=${take}`,
      "get",
      null,
      token
    );
}
export function GetTagsForAdmin(skip, take, searchTerm, token) {
  return searchTerm
    ? Axios(
      `ShopAndDrop/SADMaster/GetTagsForAdmin?searchTerm=${searchTerm}&skip=${skip}&take=${take}`,
      "get",
      null,
      token
    )
    : Axios(
      `ShopAndDrop/SADMaster/GetTagsForAdmin?skip=${skip}&take=${take}`,
      "get",
      null,
      token
    );
}

export function GetDeliveryTypes(token) {
  return Axios(`ShopAndDrop/SADMaster/GetDeliveryTypes`, "get", null, token);
}
export function GetCheckInRequests(skip, take, searchTerm, token) {
  return Axios(
    `ShopAndDrop/SADRequestForPickup/GetCheckInRequests?searchTerm=${searchTerm}&skip=${skip}&take=${take}`,
    "get",
    null,
    token
  );
}

export function GetProofTypes(token) {
  return Axios(`ShopAndDrop/SADMaster/GetProofTypes`, "get", null, token);
}
export function AddGuestCheckinInfo(id, data) {
  return Axios("SADAnonymous/AddGuest/" + id, "post", data);
}

export function GetStatus(skip, take, searchTerm, token) {
  return Axios(
    `ShopAndDrop/SADMaster/GetDeliveryStatuses?searchTerm=${searchTerm}&skip=${skip}&take=${take}`,
    "get",
    null,
    token
  );
}

export function GetCheckInIds(skip, take, searchTerm, token) {
  return Axios(
    `ShopAndDrop/SADMaster/GetCheckInNumbers?searchTerm=${searchTerm}&skip=${skip}&take=${take}`,
    "get",
    null,
    token
  );
}

export function AddShopAndDropDeliveryDetails(data, id, token) {
  return Axios("ShopAndDrop/SADCheckIn/Add/" + id, "post", data, token);
}

export function GetCheckOutDeliveryData(
  token,
  skip,
  take,
  checkInNumber,
  phoneNumber,
  tenantLocation,
  deskLocation,
  pickupLocation,
  status,
  startDate,
  endDate,
  deliveryType
) {
  return Axios(
    `ShopAndDrop/SADCheckOut/GetDeliveries?checkInNumber=${checkInNumber}&phoneNumber=${phoneNumber}&tenantId=${tenantLocation}&deskId=${deskLocation}&pickupLocation=${pickupLocation}&status=${status}&startDate=${startDate}&endDate=${endDate}&deliveryType=${deliveryType}&skip=${skip}&take=${take}
    `,
    "get",
    null,
    token
  );
}

export function GetCheckOutStatusData(token, id, isIndieMall) {
  return Axios(
    `ShopAndDrop/SADCheckOut/GetAdditionalInformation?checkInDetailId=${id}&isInsideMall=${isIndieMall}`,
    "get",
    null,
    token
  );
}


export function GetCheckInAdditionalInfo(token, id, isIndieMall) {
  return Axios(
    `ShopAndDrop/SADCheckOut/GetAdditionalInformation?checkInDetailId=${id}&isInsideMall=${isIndieMall}`,
    "get",
    null,
    token
  );
}
// Deepak
export function GetDeliveryStatuses(skip, take, token) {
  return Axios(
    `ShopAndDrop/SADMaster/GetDeliveryStatuses?skip=${skip}&take=${take}`,
    "get",
    null,
    token
  );
}
export function UpdateShipmentNumber(checkInDetailId = "", shipmentNumber = "", token) {
  return Axios(
    `ShopAndDrop/SADCheckIn/UpdateShipmentNumber?checkInDetailId=${checkInDetailId}&shipmentNumber=${shipmentNumber}`,
    "patch",
    null,
    token
  );
}
export function UpdateStatus(checkInDetailId = "", statusId = "", token) {
  return Axios(
    `ShopAndDrop/SADCheckIn/UpdateDeliveryStatus?checkInDetailId=${checkInDetailId}&statusId=${statusId}`,
    "patch",
    null,
    token
  );
}
export function GetRunners(searchTerm = "", skip, take, token) {
  return Axios(
    `User/GetRunners?skip=${skip}&take=${take}&searchTerm=${searchTerm}`,
    "get",
    null,
    token
  );
}
export function UpdatePaymentReference(checkInDetailId = "", paymentReferenceNo = "", token) {
  return Axios(
    `ShopAndDrop/SADCheckIn/UpdatePaymentReference?checkInDetailId=${checkInDetailId}&paymentReferenceNo=${paymentReferenceNo}`,
    "patch",
    null,
    token
  );
}
export function UpdateRunnerInformation(checkInDetailId = "", runner, token) {
  return Axios(
    `ShopAndDrop/SADCheckIn/UpdateRunnerInformation?checkInDetailId=${checkInDetailId}&runnerId=${runner?.id}&runnerName=${runner?.firstName}`,
    "patch",
    null,
    token
  );
}
// end


export function UpdateStatusInsideMall(token, id, otp) {
  return Axios(
    `ShopAndDrop/SADCheckIn/UpdateStatusInsideMall?checkInDetailId=${id}&otp=${otp}`,
    "post",
    null,
    token
  );
}
export function UpdateStatusToOutOfDelivered(token, id, shipmentId) {
  return Axios(
    `ShopAndDrop/SADCheckIn/UpdateStatusToOutOfDelivered?checkInDetailId=${id}&shipmentNumber=${shipmentId}`,
    "post",
    null,
    token
  );
}
export function UpdateStatusToDelivered(token, id, shipmentId) {
  return Axios(
    `ShopAndDrop/SADCheckIn/UpdateStatusToDelivered?checkInDetailId=${id}`,
    "post",
    null,
    token
  );
}

export function GetGuestInformation(token, id) {
  return Axios(
    `ShopAndDrop/SADRequestForPickup/GetGuestInformation?checkInRequestId=${id}`,
    "get",
    null,
    token
  );
}

export function GetGuestInfo(token, mobileNumber) {
  return Axios(
    `ShopAndDrop/SADCheckIn/GetGuestInformation?mobileNumber=${mobileNumber}
    `,
    "get",
    null,
    token
  );
}

export function GetDeliveryCharge(token) {
  return Axios(`ShopAndDrop/SADMaster/GetDeliveryCharges`, "get", null, token);
}
export function GetDeliveryChargesForAdmin(skip, take, token) {
  return Axios(`ShopAndDrop/SADMaster/GetDeliveryChargesForAdmin?skip=${skip}&take=${take}`, "get", null, token);
}
export function AddDeliveryCharge(token, data) {
  return Axios(`ShopAndDrop/SADMaster/AddDeliveryCharge`, "post", data, token);
}
export function UpdateDeliveryCharge(token, data) {
  return Axios(
    `ShopAndDrop/SADMaster/EditDeliveryCharge`,
    "patch",
    data,
    token
  );
}
export function BulkEditDeliveryCharge(token, data) {
  return Axios(
    `ShopAndDrop/SADMaster/BulkEditDeliveryCharge`,
    "patch",
    data,
    token
  );
}

export function SendLoginOtp(data, checkInDetailId, orgId) {
  return Axios(
    `SADAnonymous/ValidateGuest?encCheckInId=${checkInDetailId}&encOrgId=${orgId}`,
    "post",
    data,
    null
  );
}

export function ValidateOtp(otp, checkInDetailId, guestId) {
  return Axios(
    `SADAnonymous/VerifyOTP?encCheckInId=${checkInDetailId}&encGuestId=${guestId}&otp=${otp}`,
    "post",
    null
  );
}

export function GetUpdateUserData(id, orgId) {
  return Axios(
    `SADAnonymous/GetAdditionalInformation?encCheckInNumber=${id}&encorgId=${orgId}`,
    "get",
    null,
    null
  );
}

export function GetActiveAnmsPickupLocation(skip, take, searchTerm, orgId) {
  return Axios(
    `SADAnonymous/GetPickupLocations?encOrgId=${orgId}&searchTerm=${searchTerm}&skip=${skip}&take=${take}
        `,
    "get",
    null,
    null
  );
}

export function UpdatePickupDetails(data) {
  return Axios(`SADAnonymous/UpdatePickupInformation`, "patch", data, null);
}

export function GetAnmsFiles(checkInId, moduleName) {
  return Axios(
    `SADAnonymous/GetFiles?encId=${checkInId}&module=${moduleName}
        `,
    "get",
    null,
    null
  );
}
export function DownloadAnmsFiles(fileName, url) {
  return Axios(
    `SADAnonymous/DownloadFile?fileName=${fileName}&azureBlobUrl=${url}

        `,
    "get",
    null,
    null
  );
}

export function GetUserInformation(mobileNumber) {
  return Axios(
    `SADAnonymous/GetGuestInformation?mobileNumber=${mobileNumber}

        `,
    "get",
    null,
    null
  );
}

export function GetProofList(token) {
  return Axios(
    `ShopAndDrop/SADMaster/ListProof`,
    "get",
    null,
    token
  );
}
export function AddProofList(data, token) {
  return Axios(
    `ShopAndDrop/SADMaster/InsertOrUpdateProof`,
    "post",
    data,
    token
  );
}
export function DeleteProofList(data, token) {
  return Axios(
    `ShopAndDrop/SADMaster/DeleteProof`,
    "post",
    data,
    token
  );
}
export function CancelCheckInRequest(checkInRequestId, token) {
  return Axios(
    `ShopAndDrop/SADRequestForPickup/CancelCheckInRequest?checkInRequestId=${checkInRequestId}`,
    "get",
    null,
    token
  );
}
