import React from 'react'

function Modalinfo() {
    const content = "Please don't change the Excel format, and enter valid data."
    return (
        <div style={{ margin: "0 10px" }}>
            <i className='pi pi-info-circle' style={{ fontSize: "1rem" }} title={content}></i>
        </div>
    )
}

export default Modalinfo