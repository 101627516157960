import Axios from "../Helpers/Axios";

// Invoice
export function GetLoungeStaffInvoices(skip, take, searchTerm, token,startDate,endDate) {
  return Axios(
    `VIPLounge/VIPLoungeStaff/ViewInvoiceReferences?searchTerm=${searchTerm||""}&skip=${skip}&take=${take}&startDate=${startDate}&toDate=${endDate}&forExport=false`,
    "get",
    null,
    token
  );
}
export function AddInvoice(vipCode, data, token) {
  return Axios(
    `VIPLounge/VIPLoungeStaff/AddInvoice?vipCode=${vipCode}`,
    "post",
    data,
    token
  );
}

export function ViewInvoice(invoiceDetailId, token) {
  return Axios(
    `VIPLounge/VIPLoungeStaff/ViewInvoice?invoiceReferenceId=${invoiceDetailId}`,
    "get",
    null,
    token
  );
}
export function DeleteInvoice(invoiceDetailId, token) {
  return Axios(
    `VIPLounge/VIPLoungeStaff/DeleteInvoice?invoiceDetailId=${invoiceDetailId}`,
    "patch",
    null,
    token
  );
}
export function EditInvoice(vipCode, data, token) {
  return Axios(
    `VIPLounge/VIPLoungeStaff/EditInvoice?vipCode=${vipCode}`,
    "patch",
    data,
    token
  );
}

// VIP search
export function GetVIPUserDetail(vipCodeOrMobileNumber, token) {
  return Axios(
    `VIPLounge/VIPLoungeStaff/ViewMemberDetail?vipCodeOrCardNumberOrMobileNuber=${vipCodeOrMobileNumber}
    `,
    "get",
    null,
    token
  );
}

export function GetVipIds(searchTerm, token) {
  return Axios(
    `VIPLounge/VIPLoungeStaff/ActiveVipCodes?searchTerm=${searchTerm}`,
    "get",
    null,
    token
  );
}
