import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import style from "./style.module.scss";

// -------------Imports---------------
import { FilterMatchMode } from "primereact/api";
// import { faEye } from '@fortawesome/free-regular-svg-icons';
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import viewIcon from "../../../src/assets/images/viewIcon.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";

import { useNavigate } from "react-router-dom";
import { Paginator } from "primereact/paginator";

//------Imports from other Components-----
import { GetHireList } from "../../Services/HireDetailsService";

//-----------Context ---------------
import { useDispatch, useSelector } from "react-redux";
import { setHireDetailsList } from "../../Redux/AppSlice";
import { Link } from "react-router-dom";
import { setNavVisibilty } from "../../Redux/AppSlice";
import { Button } from "primereact/button";
import { exportDataInExcel, exportExcel, exportFilterdExcel } from "../../Services/ExportServices";
import { Calendar } from "primereact/calendar";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";

//----------------------------------------

function HireDetails() {
  const Context = useSelector((state) => state.AppContext);
  //console.log(Context)
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [cookies, setCookie, removeCookie] = useCookies();
  const [hireList, setHireList] = useState([]);
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    name: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });
  const [exportDetails, setExportDetails] = useState({
    retainerType: "",
    status: "",
    startDate: "",
    endDate: "",
    exportDailogOpen: false
  });
  // Pagination
  const [first, setFirst] = useState(0);
  const [totalCount, setTotalRecords] = useState(0);

  const getHireList = (skip, take, searchValue, status, startDate, endDate) => {
    let data = {
      take: take,
      skip: skip,
    };
    if (searchValue) {
      data["searchTerm"] = searchValue;
    }
    const start = startDate
      ? startDate?.getFullYear() + '/' +
      (startDate?.getMonth() + 1)?.toString()?.padStart(2, '0') + '/' +
      startDate?.getDate()?.toString()?.padStart(2, '0')
      : "";

    const end = endDate
      ? endDate?.getFullYear() + '/' +
      (endDate?.getMonth() + 1)?.toString()?.padStart(2, '0') + '/' +
      endDate.getDate()?.toString()?.padStart(2, '0')
      : "";
    GetHireList(data, cookies.bearerToken, status, start, end)
      .then((res) => {
        //console.log('res', res)
        setHireList(res?.data?.hiredItems);
        setTotalRecords(res?.data?.totalRecords);
        dispatch(setHireDetailsList({ hireList: res?.data }));
      })
      .catch((error) => {
        //console.log(error);
      })
      .finally(() => {
        // //console.log(hireList)
      });
  };

  const onGlobalFilterChange = (e) => {
    setGlobalFilterValue(e ? e?.target?.value : "");
    if (!e) {
      getHireList(0, 10, "", exportDetails?.status, exportDetails?.startDate, exportDetails?.endDate);
      return;
    }
    getHireList(0, 10, e?.target.value, exportDetails?.status, exportDetails?.startDate, exportDetails?.endDate);
    return;
    const value = e ? e.target.value : "";
    let _filters = { ...filters };
    _filters["global"].value = value;
    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const statusBodyTemplate = (row) => {
    if (row.status === "Checked Out") {
      return <p style={{ color: "red" }}>Check Out</p>;
    } else {
      return <p style={{ color: "#62E25E" }}>Check In</p>;
    }
  };

  const actionButtonTemplate = (row) => {
    console.log(row);
    return (
      <i className="pi pi-eye" onClick={() => {
        cookies.roles.includes("ADMIN") ? navigate(`/admin-hire-details-view/${row.hireId}`) : navigate(`/hire-details-view/${row.hireId}`)
      }} />

    );
  };
  const dateBodyTemplate = (row) => {
    const dateTime = new Date(row?.createdDate);
    const year = dateTime.getFullYear();
    const month = dateTime.getMonth() + 1;
    const day = dateTime.getDate();
    const hour = dateTime.getHours();
    const minutes = dateTime.getMinutes();

    return (
      <div>
        <p style={{ fontSize: "1rem", fontWeight: 400 }}>
          {day + "/" + month + "/" + year}
        </p>
      </div>
    );
  };

  const back = () => {
    navigate("/dashboard");
  };
  const onPageChange = (e, index) => {
    setFirst(e.first);
    getHireList(e.first, 10, globalFilterValue, exportDetails?.status, exportDetails?.startDate, exportDetails?.endDate);
    //console.log(e);
  };
  const exportHireInfo = () => {
    // if (globalFilterValue) {
    exportDataInExcel(
      `HiredDetails?startDate=${exportDetails?.startDate}&endDate${exportDetails?.endDate}&status=${exportDetails?.status}`,
      cookies.bearerToken
    );
    // return;
    // }
    // exportDataInExcel("HiredDetails", cookies.bearerToken);
  };
  const onExportHireDetails = () => {
    const fromDate = !exportDetails?.startDate ? "" : `${(new Date(exportDetails?.startDate)?.getMonth() + 1)?.toString()?.padStart(2, "0")}/${new Date(exportDetails?.startDate)?.getDate()?.toString()?.padStart(2, "0")}/${new Date(exportDetails?.startDate)?.getFullYear()}`;
    const toDate = !exportDetails?.endDate ? "" : `${(new Date(exportDetails?.endDate)?.getMonth() + 1)?.toString()?.padStart(2, "0")}/${new Date(exportDetails?.endDate)?.getDate()?.toString()?.padStart(2, "0")}/${new Date(exportDetails?.endDate)?.getFullYear()}`;

    let urlRoute = `Hire/AdminList?startDate=${fromDate}&endDate=${toDate}&forExport=true`; //new
    let data = {
      take: totalCount,
      skip: 0,
      searchTerm: globalFilterValue || ""
    }
    exportFilterdExcel(urlRoute, cookies?.bearerToken, data, "post");
  }



  useEffect(() => {
    setIsLoading(true);
    const getHireList = (skip, take) => {
      let data = {
        take: take,
        skip: skip,
        searchTerm: "",
      };
      GetHireList(data, cookies.bearerToken)
        .then((res) => {
          //console.log('res', res)
          setHireList(res?.data?.hiredItems);
          setTotalRecords(res?.data?.totalRecords);

          dispatch(setHireDetailsList({ hireList: res?.data }));
        })
        .catch((error) => {
          //console.log(error);
        })
        .finally(() => {
          setIsLoading(false);
          // //console.log(hireList)
        });
    };

    getHireList(0, 10);
  }, []);

  return (
    <>
      <Dialog
        header={<h3>Export Hire Details</h3>}
        draggable={false}
        style={{ width: "50vw", height: "max-content" }}
        closable={true}
        onHide={() => setExportDetails(prev => ({ ...prev, exportDailogOpen: false, startDate: null, endDate: null, status: null }))}
        visible={exportDetails?.exportDailogOpen}
      >
        <div style={{ display: "flex", flexWrap: "wrap" }}>
          <div style={{ width: "50%" }} className="mb-2">
            <label htmlFor="StartDate" className="font-bold block mb-2">Start Date
            </label>
            <Calendar
              id="StartDate"
              style={{ width: "95%" }}
              value={exportDetails?.startDate}
              placeholder="Choose start end"
              onChange={((e) => setExportDetails((prev) => ({ ...prev, startDate: e?.value })))}
            ></Calendar>
          </div>
          <div style={{ width: "50%" }} className="mb-2">
            <label htmlFor="EndDate" className="font-bold block mb-2">End Date
            </label>
            <Calendar
              id="EndDate"
              style={{ width: "95%" }}
              value={exportDetails?.endDate}
              placeholder="Choose end date"
              onChange={((e) => setExportDetails((prev) => ({ ...prev, endDate: e?.value })))}
            ></Calendar>
          </div>
          <div style={{ width: "50%" }} className='mb-2'>
            <label htmlFor="organization" className="font-bold block mb-2">Status
            </label>
            <Dropdown
              options={[
                { name: "Checked In", value: 2 },
                { name: "Checked Out", value: 1 }
              ]}
              optionLabel='name'
              optionValue='value'
              style={{ width: "95%" }}
              value={exportDetails?.status}
              onChange={(e) => {
                setExportDetails((prev) => ({ ...prev, status: e?.value }));

              }}
              placeholder='Select status type' />
            {/* <div className="input grid ">
              <div className="col ">
                <label className="switch ">
                  <input type="checkbox" checked={exportDetails?.status} onChange={(e) => { setExportDetails((prev) => ({ ...prev, status: e?.value })) }} />
                  <span className="slider round"></span>
                </label>
              </div> 
          </div>*/}
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "25px",
          }}
        >
          <Button
            label="Submit"
            onClick={() => {
              // onExportHireDetails()
            }}
            style={{
              width: "120px",
              height: "40px",
              backgroundColor: " #ae9d81",
              color: "#fff",
              border: "none",
              marginRight: "0",
              cursor: "pointer",
              padding: "0.6rem",
            }}
          />
        </div>
      </Dialog >
      <div className="feedbackMaster">
        <div className="header">
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              flexGrow: "3",
              justifyContent: "flex-start",
            }}
            className="flex align-items-center org"
          >
            {/* <i className="pi pi-arrow-circle-left nav-back-btn" onClick={back} style={{ marginLeft: '30px', cursor: 'pointer', fontSize: '1.5rem' }} /> */}
            <div className="menubtn" style={{ padding: "10px 10px 5px 20px" }}>
              <i
                className="pi pi-align-justify"
                style={{
                  fontSize: "1rem",
                  color: "#ae9d81",
                  cursor: "pointer",
                }}
                onClick={() =>
                  dispatch(setNavVisibilty({ sideNavVisibility: true }))
                }
              ></i>
            </div>
            <div className={style.heading}>
              {/* <span></span> */}
              <p
                style={{
                  color: "#2d6c8b",
                  fontSize: "18px",
                  fontStyle: "normal",
                  fontWeight: "700",
                  lineHeight: "normal",
                  letterSpacing: "-0.3px",
                }}
              >
                Hire Details
              </p>
            </div>
          </div>

          <div className="flex align-items-center">
            <div className="mx-2">
                <h4 className='mb-1 mt-0 relative' style={{color:"#ae9d81"}}>From Date</h4>
                <Calendar
                id="StartDate"
                className="customeCalender"
                style={{ width: 180 }}
                value={exportDetails?.startDate}
                placeholder="Choose start end"
                onChange={(e) => {
                  setExportDetails((prev) => ({ ...prev, startDate: e?.value }));
                  getHireList(0, 10, globalFilterValue, exportDetails?.status, e?.value, exportDetails?.endDate);
                }}
              ></Calendar>
            </div>
            <div className="mx-2">
              <h4 className='mb-1 mt-0 relative' style={{color:"#ae9d81"}}>To Date</h4>
              <Calendar
                id="EndDate"
                className="customeCalender"
                style={{ width: 180 }}
                value={exportDetails?.endDate}
                placeholder="Choose end date"
                onChange={(e) => {
                  setExportDetails((prev) => ({ ...prev, endDate: e?.value }));
                  getHireList(0, 10, globalFilterValue, exportDetails?.status, exportDetails?.startDate, e?.value);
                }}
              ></Calendar>
            </div>
            <div className="mx-2">
              <h4 className='mb-1 mt-0 relative' style={{color:"#ae9d81"}}>Search</h4>
              <div className="search mx-2">
                <span className="p-input-icon-left">
                  <i
                    className="pi pi-search"
                    style={{ color: "black", fontWeight: "700", fontSize: "1rem" }}
                  />
                  <InputText
                    value={globalFilterValue}
                    onChange={onGlobalFilterChange}
                    placeholder="Search"
                    style={{ width: 180 }}
                  />
                  <i
                    className="pi pi-times"
                    style={{ color: "black", fontWeight: "400" }}
                    onClick={() => {
                      setGlobalFilterValue("");
                      onGlobalFilterChange(null);
                    }}
                  />
                </span>
              </div>
            </div>
            <div className="mx-2 align-self-end">
              <Button style={{ backgroundColor: "#ae9d81", border: 0 }} onClick={() => {
                setExportDetails((prev) => ({ ...prev, endDate: "", status: "", startDate: "" }));
                setGlobalFilterValue("");
              }}>
                Clear All
              </Button>
            </div>
            <div className="mx-2 align-self-end">
              <Button
                label="Export"
                onClick={() => totalCount > 0 ? onExportHireDetails() : null}
                icon={"pi pi-file-export"}
                style={{
                  backgroundColor: "transparent",
                  color: "#ae9d81",
                  border: "1px #ae9d81 solid",
                  borderRadius: "20px",
                  height:"46px",
                  cursor: totalCount > 0 ? "pointer" : "not-allowed",
                }}
              />
            </div>
          </div>
          
        </div>

        <div className={style.datatable}>
          {isLoading ? (
            <div className="spinner spinner-center"></div>
          ) : (
            <DataTable
              value={hireList?.length > 0 ? hireList : []}
              style={{ boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)" }}
              tableStyle={{ width: "90rem" }}
              stripedRows
              emptyMessage={
                <>
                  <p style={{ textAlign: "center" }}>
                    Hire items list is empty
                  </p>
                </>
              }
              selectionMode="single"
              filters={filters}
              globalFilterFields={[
                "hireId",
                "totalPurchasedItems",
                "customerName",
                "customerId",
                "organization",
                "depositType",
                "mobileNumber",
              ]}
            >
              {/* <Column field='hireId' header='Request Id'></Column> */}
              {/* <Column field='deskName' header='Desk Name'></Column> */}
              {/* <Column field='customerId' header='Customer Id'></Column> */}
              <Column field="customerName" header="Customer Name"></Column>
              <Column field="depositType" header="Retainer Type"></Column>
              {/* <Column field='organization' header='Organization'></Column> */}
              {/* <Column field='mobileNumber' header='Customer Mobile Number'></Column> */}
              <Column field="totalPurchasedItems" header="No of Items"></Column>
              <Column
                field="createdDate"
                header="Created Date"
                body={dateBodyTemplate}
              ></Column>
              <Column
                field="status"
                header="Status"
                body={statusBodyTemplate}
              ></Column>
              <Column
                rowEditor
                header="Action"
                body={actionButtonTemplate}
              ></Column>
            </DataTable>
          )}
        </div>
        {isLoading || hireList?.length == 0 ? (
          ""
        ) : (
          <div style={{ justifyContent: "center", display: "flex" }}>
            <Paginator
              first={first}
              rows={10}
              totalRecords={totalCount}
              onPageChange={(e) => onPageChange(e, 1)}
              template={{
                layout:
                  "RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink  LastPageLink CurrentPageReport",
              }}
              className="justify-content-start"
            />
          </div>
        )}
        {/* <div>
                    {isAddHireDetails == true ? popup() : ""}
                </div> */}
        <div>
          {/* {isAddHireDetails && <HireDetailsView selectedHireDetail={selectedHireDetail} />} */}
        </div>
        {/* </div> */}
      </div>
    </>
  );
}
export default HireDetails;
