import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import "./style.scss";

// -------------Imports---------------
import { FilterMatchMode } from "primereact/api";
// import { faEye } from '@fortawesome/free-regular-svg-icons';
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import editIcon from "../../../src/assets/images/editIcon.jpg";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
//------Service imports-----
import {
  GetInspections,
  GetSearchedInspections,
  GetTenantInspectors,
} from "../../Services/InspectionService";
import { Paginator } from "primereact/paginator";

//-----------Context ---------------
import { useDispatch, useSelector } from "react-redux";
import {
  addOrganizationList,
  addDeskList,
  setNavVisibilty,
} from "../../Redux/AppSlice";
import { Link, useNavigate } from "react-router-dom";
import { exportDataInExcel, exportExcel, exportFilterdExcel } from "../../Services/ExportServices";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { Dialog } from "primereact/dialog";
import { GetTenantList, GetTenantsList } from "../../Services/TenantService";
import { GetActiveOrganizationList, GetOrganizationList } from "../../Services/OrganizationService";

function Inspection() {
  const Context = useSelector((state) => state.AppContext);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [cookies, setCookie, removeCookie] = useCookies();
  const [inspectionList, setInspectionList] = useState("");
  const [inspectorList, setInspectorList] = useState([])
  const [isLoading, setIsLoading] = useState(true);

  // Pagination
  const [first, setFirst] = useState(0);
  const [totalCount, setTotalRecords] = useState(0);
  // export 
  const [tenantList, setTenantList] = useState([]);
  const [organizationList, setOrganizationList] = useState([]);
  const [exportObj, setExportObj] = useState({
    modelVisible: false,
    organizationId: 0,
    roleId: 0,
    startDate: null,
    endDate: null,
    tenantId: null,
    inspectorId: null
  });

  const [globalFilterValue, setGlobalFilterValue] = useState("");

  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    name: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });
  const onGlobalFilterChange = (e) => {
    setGlobalFilterValue(e ? e?.target?.value : "");
    if (!e) {
      getInspections(0, 10, "", globalFilterValue, exportObj?.inspectorId, exportObj?.tenantId, exportObj?.startDate, exportObj?.endDate);
      return;
    }
    getInspections(0, 10, e?.target?.value ? e?.target?.value : "", exportObj?.inspectorId, exportObj?.tenantId, exportObj?.startDate, exportObj?.endDate);
  };

  const getInspections = (skip, take, searchQuery = "", inspectionId, tenantId, startDate = "", endDate = "") => {
    setIsLoading(true);
    // if (!searchQuery) {
    const formattedStartDate = startDate
      ? startDate.getFullYear() + '/' +
      (startDate.getMonth() + 1).toString().padStart(2, '0') + '/' +
      startDate.getDate().toString().padStart(2, '0')
      : "";

    const formatedEndDate = endDate
      ? endDate.getFullYear() + '/' +
      (endDate.getMonth() + 1).toString().padStart(2, '0') + '/' +
      endDate.getDate().toString().padStart(2, '0')
      : "";
    GetInspections(take, skip, cookies.bearerToken, searchQuery, inspectionId, tenantId, formattedStartDate, formatedEndDate)
      .then((res) => {
        //console.log(res?.data?.tenantInspections, "get");
        // setInspectionList(res?.data?.tenantInspections);
        setInspectionList([...res?.data?.tenantInspections]);
        setTotalRecords(res?.data?.totalRecords);
      })
      .catch((err) => {
        //console.log(err);
      })
      .finally(() => {
        setIsLoading(false);
        // //console.log(organizationList);
      });
    // } else {
    //   GetSearchedInspections(take, skip, searchQuery, cookies.bearerToken)
    //     .then((res) => {
    //       //console.log(res?.data?.tenantInspections, "get");
    //       // setInspectionList(res?.data?.tenantInspections);
    //       setInspectionList([...res?.data?.tenantInspections]);
    //       setTotalRecords(res?.data?.totalRecords);
    //     })
    //     .catch((err) => {
    //       //console.log(err);
    //     })
    //     .finally(() => {
    //       setIsLoading(false);
    //       // //console.log(organizationList);
    //     });
    // }
  };

  const getTenantInspections = async () => {
    let roleId = 3;
    await GetTenantInspectors(roleId, cookies.bearerToken)
      .then((res) => {
        setInspectorList(res?.data);
      })
      .catch((err) => {
        console.log(err);
      })
  }



  const back = () => {
    navigate("/dashboard");
  };
  // //console.log(inspectionList);
  const statusBodyTemplate = (row) => {
    //console.log(row, "row");
    // return (
    //     <div>
    //         <p
    //             style={{ color: "#D17118", display: "inline-block", width: "60px", textAlign: 'center', borderRadius: '3px' }}
    //         >
    //             {row?.tenantInspection?.status}</p>
    //     </div>
    // )

    if (row.tenantInspection.status?.toLowerCase() == "open") {
      return <p style={{ color: "#fa6334" }}>{row.tenantInspection.status}</p>;
    } else if (row.tenantInspection.status?.toLowerCase() == "inprogress") {
      return <p style={{ color: "#578bfa" }}>{row.tenantInspection.status}</p>;
    } else if (row.tenantInspection.status?.toLowerCase() == "pending") {
      return <p style={{ color: "#faf066" }}>{row.tenantInspection.status}</p>;
    } else if (row.tenantInspection.status?.toLowerCase() == "noresponse") {
      return <p style={{ color: "#a3a3a0" }}>{row.tenantInspection.status}</p>;
    } else {
      return <p style={{ color: "#41fa53" }}>{row.tenantInspection.status}</p>;
    }
  };
  const onViewInspectionDetail = (item) => {
    // //console.log("Inspection Id", item?.tenantInspection?.id);
    return (
      <i
        className="pi pi-eye"
        onClick={() => {
          navigate(`/inspectionviewdetails/${item?.tenantInspection?.id}`);
        }}
        style={{ fontSize: "1rem", cursor: "pointer" }}
      />
    );
  };
  const onPageChange = (e, index) => {
    setFirst(e.first);
    getInspections(e.first, 10, globalFilterValue, exportObj?.inspectorId, exportObj?.tenantId, exportObj?.startDate, exportObj?.endDate);
    //console.log(e);
  };

  const exportTenantInfoForAdmin = () => {
    if (globalFilterValue) {
      exportDataInExcel(
        `TenantInspectionListForAdmin?searchTerm=${globalFilterValue}`,
        cookies.bearerToken
      );
      return;
    }
    const start = exportObj?.startDate
      ? exportObj.startDate.getFullYear() + '/' +
      (exportObj.startDate.getMonth() + 1).toString().padStart(2, '0') + '/' +
      exportObj.startDate.getDate().toString().padStart(2, '0')
      : "";

    const end = exportObj?.endDate
      ? exportObj.endDate.getFullYear() + '/' +
      (exportObj.endDate.getMonth() + 1).toString().padStart(2, '0') + '/' +
      exportObj.endDate.getDate().toString().padStart(2, '0')
      : "";
    console.log(start, end, "date");
    exportExcel(`TenantInspectionListForAdmin?inspectorID=${exportObj?.inspectorId || "0"}&startDate=${start}&endDate=${end}&tenantID=${exportObj?.tenantId || "0"}`);
  };
  const getTeanatList = async () => {
    await GetTenantsList(cookies?.bearerToken).then(res => {
      if (res?.status == 200) {
        setTenantList(res?.data);
      }
    })
  }
  const getOrganizationList = async () => {
    await GetActiveOrganizationList(cookies?.bearerToken).then(res => {
      if (res?.status == 200) {
        setOrganizationList(res?.data);
      }
    })
  }
  const onExportTenaantInspection = () => {
    const start = exportObj?.startDate
      ? exportObj.startDate.getFullYear() + '/' +
      (exportObj.startDate.getMonth() + 1).toString().padStart(2, '0') + '/' +
      exportObj.startDate.getDate().toString().padStart(2, '0')
      : "";

    const end = exportObj?.endDate
      ? exportObj.endDate.getFullYear() + '/' +
      (exportObj.endDate.getMonth() + 1).toString().padStart(2, '0') + '/' +
      exportObj.endDate.getDate().toString().padStart(2, '0')
      : "";
    let urlROute = `TenantInspection/GetInspectionsForAdmin?skip=0&take=${totalCount}&searchTerm=${globalFilterValue || ""}&inspectorId=${exportObj?.inspectorId || "0"}&tenantId=${exportObj?.tenantId || "0"}&startDate=${start}&endDate=${end}&forExport=true`;
    exportFilterdExcel(urlROute, cookies?.bearerToken);
  }
  const assignExportValue = (key, value) => {
    setExportObj((prev) => ({ ...prev, [key]: value }));
  }
  useEffect(() => {
    getTeanatList();
    getInspections(0, 10);
    getOrganizationList();
    getTenantInspections();
  }, []);

  return (
    <>
      <Dialog
        header={<h3>Export Filter</h3>}
        draggable={false}
        style={{ width: "50vw", height: "max-content" }}
        closable={true}
        onHide={() => {
          assignExportValue("modelVisible", false);
          setExportObj(() => ({
            modelVisible: false,
            organizationId: 0,
            roleId: 0,
            startDate: null,
            endDate: null,
            tenantId: null,
            inspectorId: null
          }));
        }}
        visible={exportObj?.modelVisible}
      >
        <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "space-between" }}>
          <div style={{ width: "48%" }} className="mb-2">
            <label htmlFor="StartDate" className="font-bold block mb-2">
              Inspector
            </label>
            <Dropdown options={inspectorList?.length ? inspectorList : []} value={exportObj?.inspectorId}
              onChange={(e) => assignExportValue("inspectorId", e.value)}
              optionLabel="userName" optionValue="id" placeholder="Select Inspector" className="w-full" />
          </div>
          {/* <div style={{ width: "48%" }} className="mb-2">
            <label htmlFor="StartDate" className="font-bold block mb-2">
              Organization
            </label>
            <Dropdown options={organizationList} value={exportObj?.organizationId}
              onChange={(e) => assignExportValue("organizationId", e.value)}
              optionLabel="name" optionValue="id" placeholder="Select organization" className="w-full" />
          </div> */}
          <div style={{ width: "48%" }} className="mb-2">
            <label htmlFor="StartDate" className="font-bold block mb-2">
              Tenant
            </label>
            <Dropdown options={tenantList} value={exportObj?.tenantId}
              onChange={(e) => assignExportValue("tenantId", e.value)}
              optionLabel="name" optionValue="id" placeholder="Select Tenant" className="w-full" />
          </div>
          {/* calendar */}
          <div style={{ width: "48%" }} className="mb-2">
            <label htmlFor="StartDate" className="font-bold block mb-2">
              Start date
            </label>
            <Calendar value={exportObj?.startDate} placeholder="Select Start Date" dateFormat="yy-mm-dd" onChange={(e) => { assignExportValue("startDate", e.value) }} className="w-full" />
          </div>
          <div style={{ width: "48%" }} className="mb-2">
            <label htmlFor="StartDate" className="font-bold block mb-2">
              End date
            </label>
            <Calendar value={exportObj?.endDate} placeholder="Select End Date" dateFormat="yy-mm-dd" onChange={(e) => { assignExportValue("endDate", e.value) }} className="w-full" />
          </div>

          {/* calendar */}
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "25px",
          }}
        >
          <Button
            label="Submit"
            onClick={() => {
              exportTenantInfoForAdmin()
            }}
            style={{
              width: "120px",
              height: "40px",
              backgroundColor: " #ae9d81",
              color: "#fff",
              border: "none",
              marginRight: "0",
              cursor: "pointer",
              padding: "0.6rem",
            }}
          />
        </div>
      </Dialog>

      <div className="deskMaster">
        <div className="header h-auto">
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              flexGrow: "3",
              justifyContent: "flex-start",
            }}
            className="flex align-items-center org"
          >
            {/* <i className="pi pi-arrow-circle-left nav-back-btn" onClick={back} style={{ marginLeft: '30px', cursor: 'pointer', fontSize: '1.5rem' }} /> */}
            <div className="menubtn" style={{ padding: "10px 10px 5px 20px" }}>
              <i
                className="pi pi-align-justify"
                style={{
                  fontSize: "1rem",
                  color: "#ae9d81",
                  cursor: "pointer",
                }}
                onClick={() =>
                  dispatch(setNavVisibilty({ sideNavVisibility: true }))
                }
              ></i>
            </div>
            <div className="heading">
              <p>Tenant Inspection List</p>
            </div>
          </div>

          <div className="flex align-items-center">
            <div className="mx-2">
              <h4 className='mb-1 mt-0' style={{ color: "#ae9d81" }}>Inspection</h4>
              <Dropdown filter options={inspectorList?.length ? inspectorList : []} value={exportObj?.inspectorId}
                onChange={(e) => { assignExportValue("inspectorId", e.value); getInspections(0, 10, globalFilterValue, e.value, exportObj?.tenantId, exportObj?.startDate, exportObj?.endDate) }}
                optionLabel="userName" optionValue="id" placeholder="Select" style={{ border: "1px solid #ae9d81", width: 160 }} showClear={exportObj?.inspectorId ? true : false} />
            </div>
            <div className="mx-2">
              <h4 className='mb-1 mt-0' style={{ color: "#ae9d81" }}>Tenant</h4>
              <Dropdown filter options={tenantList} value={exportObj?.tenantId}
                onChange={(e) => { assignExportValue("tenantId", e.value); getInspections(0, 10, globalFilterValue, exportObj?.inspectorId, e.value, exportObj?.startDate, exportObj?.endDate) }}
                optionLabel="name" optionValue="id" placeholder="Select" style={{ border: "1px solid #ae9d81", width: 160 }} showClear={exportObj?.tenantId ? true : false} />
            </div>
            <div className="mx-2">
              <h4 className='mb-1 mt-0' style={{ color: "#ae9d81" }}>From Date</h4>
              <Calendar className="customeCalender" value={exportObj?.startDate} placeholder="Select" dateFormat="yy-mm-dd" onChange={(e) => { assignExportValue("startDate", e.value); getInspections(0, 10, globalFilterValue, exportObj?.inspectorId, exportObj?.tenantId, e.value, exportObj?.endDate) }} style={{ width: 160 }} />
            </div>
            <div className="mx-2">
              <h4 className='mb-1 mt-0' style={{ color: "#ae9d81" }}>To Date</h4>
              <Calendar className="customeCalender" value={exportObj?.endDate} placeholder="Select" dateFormat="yy-mm-dd" onChange={(e) => { assignExportValue("endDate", e.value); getInspections(0, 10, globalFilterValue, exportObj?.inspectorId, exportObj?.tenantId, exportObj?.startDate, e.value) }} style={{ width: 160 }} />
            </div>
            <div className="mx-2">
              <h4 className='mb-1 mt-0' style={{ color: "#ae9d81" }}>Search</h4>
              <div className="search" >
                <span className="p-input-icon-left">
                  <i
                    className="pi pi-search"
                    style={{
                      color: "black",
                      fontWeight: "700",
                      fontSize: "1rem",
                    }}
                  />
                  <InputText
                    value={globalFilterValue}
                    onChange={onGlobalFilterChange}
                    placeholder="Search"
                    style={{ width: 160 }}
                  />
                  <i
                    className="pi pi-times"
                    style={{ color: "black", fontWeight: "400" }}
                    onClick={() => {
                      setGlobalFilterValue("");
                      onGlobalFilterChange(null);
                    }}
                  />
                </span>
              </div>
            </div>
            <div className="mx-2 align-self-end">
              <Button style={{ backgroundColor: "#ae9d81", border: 0 }} onClick={() => {
                setExportObj((prev) => ({ ...prev, endDate: "", inspectorId: "", organizationId: "", roleId: "", startDate: "", tenantId: "" }));
                setGlobalFilterValue("");
                getInspections(0, 10, "")
              }}>Clear All</Button>
            </div>
            <div className="mx-2 align-self-end">
              <Button
                label="Export"
                onClick={() => totalCount > 0 ? onExportTenaantInspection() : null}
                icon={"pi pi-file-export"}
                style={{
                  backgroundColor: "transparent",
                  color: "#ae9d81",
                  border: "1px #ae9d81 solid",
                  borderRadius: "20px",
                  height: "46px",
                  cursor: totalCount > 0 ? "pointer" : "not-allowed",
                }}
              />
            </div>
          </div>



        </div>

        <div className="px-4">
          {isLoading ? (
            <div
              className="spinner spinner-center"
              style={{ marginLeft: "750px" }}
            ></div>
          ) : (
            <DataTable
              value={inspectionList}
              rows={10}
              filters={filters}
              tableStyle={{ width: "90rem" }}
              style={{ boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)" }}
              globalFilterFields={[
                "tenantInspection.tenantName",
                "tenantInspection.inspectorName",
                "tenantInspection.createdOn",
              ]}
              stripedRows
              emptyMessage={
                <>
                  <p style={{ textAlign: "center" }}>
                    Inspection list is empty
                  </p>
                </>
              }
            >
              <Column
                field="tenantInspection.code"
                header="Inspection Id"
              ></Column>
              <Column
                field="tenantInspection.inspectorName"
                header="Inspected By"
              ></Column>
              <Column
                field="tenantInspection.tenantName"
                header="Tenant Name"
              ></Column>
              <Column
                field="tenantInspection.createdOn"
                header="Created Date"
              ></Column>
              <Column
                field="status"
                header="Status"
                body={statusBodyTemplate}
              ></Column>
              <Column header="Action" body={onViewInspectionDetail}></Column>
            </DataTable>
          )}
        </div>
        {isLoading || inspectionList.length == 0 ? (
          ""
        ) : (
          <div style={{ justifyContent: "center", display: "flex" }}>
            <Paginator
              first={first}
              rows={10}
              totalRecords={totalCount}
              onPageChange={(e) => onPageChange(e, 1)}
              template={{
                layout:
                  "RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink  LastPageLink CurrentPageReport",
              }}
              className="justify-content-start"
            />
          </div>
        )}
      </div>
      {/* <div>
                {isAddDesk == true ? popup() : ""}
            </div> */}
    </>
  );
}


export default Inspection;

