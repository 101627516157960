import Axios from "../Helpers/Axios";
import { isShow } from "./ExportServices";


export function GetDeskList(take, skip, token, searchValue,orgId="",status="") {
    let obj={
        take:take, 
        skip:skip, 
        searchValue:searchValue,
        orgId:orgId,
        status:status
    }
    return Axios(`Master/AdminDeskList?orgId=${orgId}${status?`&showAll=${isShow(obj)}&status=${status=="In Active"}`:""}&skip=${skip}&take=${take}&searchTerm=${searchValue}&forExport=false`, "get", null, token)
}
export function AddDesk(data, token) {
    return Axios(`Desk/Add`, "post", data, token)
}
export function EditDesk(data, token) {
    return Axios(`Desk/Edit`, "post", data, token)
}
export function DeleteDesk(data, token) {
    return Axios(`Desk/Delete`, "post", data, token)
}
export function GetDeskBasedOnOrganization(id, token) {
    return Axios(`Master/Desk?organizationId=${id}`, "get", null, token)
}