import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import React, { useEffect, useState } from 'react'
import { GetGuestStaffInspections, GetGuestStaffInspectionsForAdmin } from '../../Services/DepartmentTeamService';
import { useCookies } from 'react-cookie';
import FileView from '../utili/FileView';
import { useNavigate } from 'react-router-dom';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import './style.scss'
import { Paginator } from 'primereact/paginator';


//-----------Context ---------------
import { useDispatch, useSelector } from "react-redux";
import { setGuestInspectionsInfo, setFeedbackList, setNavVisibilty } from '../../Redux/AppSlice';
import { exportDataInExcel, exportExcel, exportFilterdExcel } from '../../Services/ExportServices';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';

// -----------------------------------

function GuestStaffInspection() {
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const [cookies, setCookie, removeCookie] = useCookies();
    const [guestInspectionsList, setGuestInspectionsList] = useState([]);
    const [isFilemodelView, setISFileModelView] = useState(false);
    const [imageList, setImageList] = useState([]);
    const [globalFilterValue, setGlobalFilterValue] = useState('');

    const [isLoading, setIsLoading] = useState(false);
    // Pagination
    const [first, setFirst] = useState(0)
    const [totalCount, setTotalRecords] = useState(0)
//export 
const [exportObj,setExportObj]=useState({
    fromDate:"",
    toDate:"",
    searchTerm:"",
    category:"",
    subcategory:"",
    organization:"",
    isModalVisible:false
  });
    const onGlobalFilterChange = (e) => {
        if (!e) {
            // getUserList(0, 10);
            getGuestInspectionsInfo(0, 10,"",exportObj?.fromDate,exportObj?.toDate)
            return
        }
        setGlobalFilterValue(e.target.value);
        getGuestInspectionsInfo(0, 10, e.target.value,exportObj?.fromDate,exportObj?.toDate);
    };
    const getGuestInspectionsInfo = ((skip, take, searchValue = "",startDate,endDate) => {
        setIsLoading(true);
        const formattedStartDate = startDate
      ? startDate.getFullYear() + '/' +
      (startDate.getMonth() + 1).toString().padStart(2, '0') + '/' +
      startDate.getDate().toString().padStart(2, '0')
      : "";

      const formatedEndDate = endDate
      ? endDate.getFullYear() + '/' +
      (endDate.getMonth() + 1).toString().padStart(2, '0') + '/' +
      endDate.getDate().toString().padStart(2, '0')
      : "";
        GetGuestStaffInspectionsForAdmin(take, skip, cookies.bearerToken, searchValue,formattedStartDate,formatedEndDate)
            .then((res) => {
                if (res?.status == 200) {
                    setGuestInspectionsList(res?.data?.data);
                    setTotalRecords(res?.data?.count)
                    dispatch(
                        setGuestInspectionsInfo({
                            guestInspectionsList: res?.data?.data
                        })
                    )
                }
                setIsLoading(false);
            })
            .catch((error) => {
                //console.log(error);
                setIsLoading(false);
            })
    })
    const getFiles = async (id) => {
        // //console.log(id);
        let foundeData = guestInspectionsList?.find(_ => _?.guestInspectionId == id);
        // //console.log(foundeData, "founded");
        if (foundeData?.files?.length > 0) {
            setImageList(foundeData?.files)
        } else {
            setImageList([]);
        }
    }
    const statusBodyTemplateTab3 = ((row) => {
        return <p style={{ padding: "0 20px" }} onClick={() => { getFiles(row?.guestInspectionId); setISFileModelView(true) }}>{row?.files?.length}</p>
    });
    const dateConverter = (date) => {
        console.log(date, "date");
        if (!date) {
            return "";
        }

        let inputDateString = date;
        inputDateString = inputDateString.includes("/") ? inputDateString.split('/') : inputDateString.split('-');


        const months = [
            'jan',
            'feb',
            'mar',
            'apr',
            'may',
            'jun',
            'jul',
            'aug',
            'sep',
            'oct',
            'nov',
            'dec'
        ];

        const monthIndex = months?.findIndex(_ => _ === inputDateString[1]?.toLowerCase());
        if (monthIndex === -1) {
            return "";
        }
        const formattedMonth = (monthIndex + 1).toString().padStart(2, '0');
        return inputDateString[0] + '/' + formattedMonth + '/' + inputDateString[2];
    };





    const onViewInspectionDetail = (item) => {
        // //console.log(item, "itemitemitem");
        // //console.log("Inspection Id", item?.tenantInspection?.id);
        return <i className="pi pi-eye" onClick={() => { navigate(`/gueststaffinspectionadminview/${item?.guestInspectionId}`) }} style={{ fontSize: '1rem', cursor: "pointer" }} />
    }


    const onPageChange = (e, index) => {
        setFirst(e.first);
        getGuestInspectionsInfo(e.first, 10, globalFilterValue,exportObj?.fromDate,exportObj?.toDate);
        //console.log(e);
    };
    const exportGuestStaffInspectionForAdmin = () => {
        if (globalFilterValue) {
            exportDataInExcel(`GuestStaffInspectionForAdmin?searchTerm=${globalFilterValue}`, cookies.bearerToken);
            return
        }
        exportExcel('GuestStaffInspectionForAdmin', cookies.bearerToken);
    }
    const onExportGuestStaffInspection=()=>{
        const fromDate =!exportObj?.fromDate?"":`${new Date(exportObj?.fromDate)?.getFullYear()}-${(new Date(exportObj?.fromDate)?.getMonth()+1)?.toString()?.padStart(2,"0")}-${new Date(exportObj?.fromDate)?.getDate()?.toString()?.padStart(2,"0")}`;
        const toDate =!exportObj?.toDate?"":`${new Date(exportObj?.toDate)?.getFullYear()}-${(new Date(exportObj?.toDate)?.getMonth()+1)?.toString()?.padStart(2,"0")}-${new Date(exportObj?.toDate)?.getDate()?.toString()?.padStart(2,"0")}`;
        let inspectorId=atob(decodeURIComponent(cookies?.userId));
        let urlRoute=`GuestStaffInspectionForAdmin?inspectorId=&startDate=${fromDate}&endDate=${toDate}`;
        // console.log(urlRoute);
        exportExcel(urlRoute);
      }
 const onExportGuestInspection=()=>{
    const start = exportObj?.fromDate
      ? exportObj.fromDate.getFullYear() + '/' +
      (exportObj.fromDate.getMonth() + 1).toString().padStart(2, '0') + '/' +
      exportObj.fromDate.getDate().toString().padStart(2, '0')
      : "";

      const end = exportObj?.toDate
      ? exportObj.toDate.getFullYear() + '/' +
      (exportObj.toDate.getMonth() + 1).toString().padStart(2, '0') + '/' +
      exportObj.toDate.getDate().toString().padStart(2, '0')
      : "";
    let urlRoute=`GuestInspection/GetGuestInspectionsForAdmin?fromDate=${start}&toDate=${end}&forExport=true&searchTerm=${globalFilterValue||""}&take=${totalCount}&skip=0`;
    exportFilterdExcel(urlRoute,cookies?.bearerToken);
 }
    useEffect(() => {
        getGuestInspectionsInfo(0, 10, globalFilterValue);
    }, [])
    return (
        <div className='px-3'>
            <div className="gueststaffinspectionadmin">
                <div className="header h-auto">
                    <div tyle={{ display: "flex", flexDirection: "row", flexGrow: "3", justifyContent: "flex-start" }} className='flex align-items-center org'>
                        {/* <i className="pi pi-arrow-circle-left nav-back-btn" onClick={back} style={{ marginLeft: '30px', cursor: 'pointer', fontSize: '1.5rem' }} /> */}
                        <div className="menubtn" style={{ padding: '10px 10px 5px 20px' }}>
                            <i className="pi pi-align-justify" style={{ fontSize: '1rem', color: "#ae9d81", cursor: "pointer" }}
                                onClick={() => dispatch(setNavVisibilty({ sideNavVisibility: true }))}></i>
                        </div>
                        <div className="heading">
                            <p>Guest Staff Inspection</p>
                        </div>
                    </div>
                    <div className='flex align-items-center'>
                            <div className='mx-2'>
                            <h4 className='mb-1 mt-0' style={{color:"#ae9d81"}}>From Date</h4>
                                <Calendar  className={"customeCalender"} style={{width:160}} value={exportObj?.fromDate} placeholder='Select from date' onChange={(e)=>{
                                    setExportObj((prev)=>({...prev,fromDate:e.value}));
                                    getGuestInspectionsInfo(0,10,globalFilterValue,e.value,exportObj?.toDate);
                                    }}/>
                            </div>
                            <div className='mx-2'>
                            <h4 className='mb-1 mt-0' style={{color:"#ae9d81"}}>To Date</h4>
                                <Calendar className='customeCalender' style={{width:160}}  value={exportObj?.toDate} placeholder='Select to date' onChange={(e)=>{
                                        setExportObj((prev)=>({...prev,toDate:e.value}));
                                        getGuestInspectionsInfo(0,10,globalFilterValue,exportObj?.fromDate,e.value);
                                        }}/>
                            </div>
                            <div className='mx-2'>
                                <h4 className='mb-1 mt-0' style={{color:"#ae9d81"}}>Search</h4>
                                <div className="search ">
                                    <span className="p-input-icon-left">
                                        <i className="pi pi-search" style={{ color: 'black', fontWeight: '700', fontSize: '1rem' }} />
                                        <InputText placeholder="Search" value={globalFilterValue} onChange={onGlobalFilterChange} style={{width:160}}  />
                                        <i className="pi pi-times" style={{ color: 'black', fontWeight: '400' }} onClick={() => { setGlobalFilterValue(""); onGlobalFilterChange(null) }} />

                                    </span>
                                </div>
                            </div>
                            <div className='mx-2 align-self-end'>
                                    <Button style={{backgroundColor:"#ae9d81",border:0}} onClick={()=>{
                                        getGuestInspectionsInfo(0,10,"");
                                        setExportObj((prev)=>({...prev,category:"",fromDate:"",isModalVisible:false, organization:"",searchTerm:"",subcategory:"",toDate:""}))
                                    }}>
                                            Clear All
                                    </Button>
                            </div>
                            <div className='mx-2 align-self-end'>
                                <Button label='Export' onClick={()=>totalCount > 0 ?  onExportGuestInspection() : null} icon={"pi pi-file-export"} style={{ backgroundColor: 'transparent', color: '#ae9d81', border: '1px #ae9d81 solid', borderRadius: '20px',height:"46px", cursor: totalCount > 0 ? 'pointer' : 'not-allowed' }} />
                            </div>
                    </div>
                   
                </div>
            </div>
            <div>
                <FileView visible={isFilemodelView} imagelist={imageList} setVisible={setISFileModelView} header={"Attachments"} />
                <div className="datatable">
                    {isLoading ?
                        <div className='spinner spinner-center' style={{
                            display: "flex", margin
                                : "auto", alignItems: "center", justifyContent: "center", marginTop: "25vh"
                        }} ></div>
                        :
                        <DataTable
                            value={guestInspectionsList}
                            tableStyle={{ minWidth: '50rem' }}
                            stripedRows
                            emptyMessage={<><p style={{ textAlign: "center" }}>Inspection list is empty.</p></>}
                            selectionMode="single"
                        >
                            <Column field='guestInspectionCode' header=' Inspection Code' ></Column>
                            <Column field='guestInspectorName' header='Inspector Name'></Column>
                            <Column field='guestInspectedOn' header='Created Date' body={(rowData) => dateConverter(rowData.guestInspectedOn)}></Column>
                            <Column field='action' header='Action' body={onViewInspectionDetail}></Column>
                        </DataTable>}
                    {isLoading || guestInspectionsList.length == 0 ? ""
                        :
                        <div style={{ justifyContent: "center", display: 'flex' }} >
                            <Paginator first={first} rows={10} totalRecords={totalCount} onPageChange={(e) => onPageChange(e, 1)} template={{ layout: 'RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink  LastPageLink CurrentPageReport' }} className="justify-content-start" />
                        </div>
                    }
                </div>
            </div>
            <Dialog visible={exportObj?.isModalVisible} header={`Export Guest Staff Inspected Details`} headerStyle={{color:"#ae9d81",textAlign:"center"}} onHide={()=>{setExportObj((prev)=>({...prev,isModalVisible:false}))}}>
                   
                    <div className='flex align-items-center flex-wrap'>
                        
                        <div className='mx-0 w-5'>
                        <h4 className='mb-2'>From Date</h4>
                        <Calendar value={exportObj?.fromDate} placeholder='Select from date' onChange={(e)=>{
                            setExportObj((prev)=>({...prev,fromDate:e.value}));
                            }}/>
                        </div>
                    
                        <div className='mx-0 w-5'>
                            <h4 className='mb-2'>To Date</h4>
                            <Calendar value={exportObj?.toDate} placeholder='Select to date' onChange={(e)=>{
                                setExportObj((prev)=>({...prev,toDate:e.value}));
                                }}/>
                        </div>  
                    </div>
                    <div className='flex justify-content-center my-4'>
                        <Button className='border-0' 
                                style={{backgroundColor:"#ae9d81",width:180,height:40,display:"flex",justifyContent:"center"}}
                                onClick={()=> {onExportGuestStaffInspection()}}>
                            Submit
                        </Button>
                    </div>
            </Dialog>
        </div>
    )
}

export default GuestStaffInspection