import React, { useState, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import './style.scss'
// -------------Imports---------------
import { FilterMatchMode } from 'primereact/api';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { Tag } from 'primereact/tag';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

import { useNavigate } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify'



// -----------------------------------


// ----------Assets Import------------
import editIcon from '../../../src/assets/images/editIcon.jpg';
// -----------------------------------


//------Imports from other Components-----
import { AddSubCategory, GetSubCategory, EditSubCategory } from '../../Services/FeedbackSubCategoryService';
import { GetFeedbackCategoryList } from '../../Services/FeedbackCategoryServices';

//----------------------------------------

//-----------Context ---------------
import { useDispatch, useSelector } from "react-redux";
import { addFeedbackSubCategoryList, setNavVisibilty, addFeedbackCategoryList } from '../../Redux/AppSlice';
import { exportDataInExcel, exportExcel, exportFilterdExcel, isShow } from '../../Services/ExportServices';
import { Calendar } from 'primereact/calendar';
import { Paginator } from 'primereact/paginator';
//----------------------------------------

function FeedbackSubCategory() {
    const Context = useSelector((state) => state.AppContext);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [cookies, setCookie, removeCookie] = useCookies();
    const [feedbackCategoryList, setFeedbackCategoryList] = useState();
    const [feedbackSubCategoryList, setFeedbackSubCategoryList] = useState([]);
    const [feedbackSubCategory, setFeedbackSubCategory] = useState({
        "category": "",
        "categoryId": "",
        "categoryName": "",
        "categoryNameInArabic": "",
        "feedbackSubCategoryModels": [
            {
                "subCategoryName": "",
                "subCategoryNameInArabic": "",
                "isDeleted": true,
                "subCategoryId": 0
            }
        ]
    }); const [first, setFirst] = useState(0);
    const [isAdd, setIsAdd] = useState(false);
    const [isAddFeedbackSubCategoryPopupOpen, setIsAddFeedbackSubCategoryPopupOpen] = useState(false)
    const [orgNameExists, setOrgNameExists] = useState(false);
    const [globalFilterValue, setGlobalFilterValue] = useState('');
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [isInvalid, setInvalid] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [totalCount, setTotalRecords] = useState(0)

    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        name: { value: null, matchMode: FilterMatchMode.CONTAINS }
    });

    const onCancelClick = () => {
        setShowConfirmation(true);
    };

    const onHide = () => {
        setShowConfirmation(false);
        setIsAddFeedbackSubCategoryPopupOpen(false);
        setFeedbackSubCategory({
            "category": "",
            "feedbackSubCategoryModels": [
                {
                    "subCategoryName": "",
                    "subCategoryNameInArabic": "",
                    "isDeleted": true,
                    "subCategoryId": 0
                }
            ]
        });
    };

    const handleConfirmation = (confirmed) => {
        if (confirmed) {
            onHide();
        } else {
            setShowConfirmation(false);
        }
    };

    const removeCode = (index) => {
        setFeedbackSubCategory((prev) => ({
            ...prev,
            feedbackSubCategoryModels: prev.feedbackSubCategoryModels.filter((code, i) => i !== index)
        }));
    };



    const header = () => {
        return <div className="heading" style={{ fontWeight: "700", fontSize: "23px", color: "#ae9d81", textAlign: 'center' }}>
            <p tyle={{ margin: '0px' }}>{isAdd ? "Add" : "Edit"} Feedback Sub Category Details</p>
        </div>
    }
    const popup = (() => {
        return (
            <>
                <Dialog header={header} visible={true} style={{ width: '30vw' }} closable={false} onHide={() => {
                    setIsAddFeedbackSubCategoryPopupOpen(false); setFeedbackSubCategory({
                        "categoryId": 0,
                        "categoryName": "",
                        "categoryNameInArabic": "",
                        "feedbackSubCategoryModels": [
                            {
                                "subCategoryName": "",
                                "subCategoryNameInArabic": "",
                                "isDeleted": true
                            }
                        ]
                    });
                }}>
                    <div className="popup " >
                        <div className="body">
                            <div className="label grid">
                                <div className='col'>Feedback Category Name<span style={{ color: 'red' }}>*</span></div>
                                {/* <div className='col'>Status</div> */}
                            </div>
                            <div className="input grid ">
                                <div className="col">
                                    <Dropdown
                                        placeholder='Select Category'
                                        options={feedbackCategoryList.filter((feedback) => feedback?.isDeleted == false)}
                                        className="category"
                                        value={feedbackSubCategory?.category}
                                        optionLabel="categoryName"
                                        onChange={(e) => {
                                            setFeedbackSubCategory((prev) => ({
                                                ...prev, category: e.target.value,
                                                categoryId: e.target.value.id,
                                                categoryName: e.target.value.categoryName
                                            }))
                                        }}
                                        style={{ border: "1px solid #AE9D81", width: "100%", height: "50px", borderRadius: "5px", boxShadow: "none" }}
                                    />
                                    {/* {isCategoryAlreadyExist &&
                                        // <div>
                                        <p style={{ color: "red" }}>Feedback category name is already exists.</p>
                                        // </div>
                                    } */}
                                    {isInvalid && feedbackSubCategory?.category == "" &&
                                        // <div>
                                        <p style={{ color: "red" }}>Feedback category  is required.</p>
                                        // </div>
                                    }

                                </div>

                                <div className="col ">
                                    {/* <label className="switch ">
                                        <input type="checkbox" checked={feedbackSubCategory?.isDeleted} onChange={(e) => { setFeedbackSubCategory((prev) => ({ ...prev, feedbackSubCategoryModels: { ...prev.feedbackSubCategoryModels, isDeleted: e.target.checked } })) }} />
                                        <span className="slider round"></span>
                                    </label> */}
                                </div>

                            </div>
                            {feedbackSubCategory?.feedbackSubCategoryModels.map((item, index, array) => {
                                return (
                                    <>
                                        <div className="label grid" key={index}>
                                            <div className='col' style={{ marginTop: '10px' }}> Sub Category Name<span style={{ color: 'red' }}>*</span></div>
                                            <div className='col' style={{ marginLeft: '2rem', marginTop: '10px' }}>Status</div>
                                        </div>
                                        <div className="input grid ">
                                            <div className="col">
                                                <InputText placeholder="Enter Name" style={{ border: "1px solid #AE9D81", width: "200px", height: "50px", borderRadius: "5px", boxShadow: "none" }} value={item?.subCategoryName}
                                                    onChange={
                                                        (e) => {
                                                            setFeedbackSubCategory((prev) => ({
                                                                ...prev, feedbackSubCategoryModels: prev.feedbackSubCategoryModels.map((innerItem, innerIndex) => {
                                                                    if (index == innerIndex) {
                                                                        return { ...item, subCategoryName: e.target.value }
                                                                    }
                                                                    else {
                                                                        return innerItem;
                                                                    }
                                                                })
                                                            }))
                                                        }
                                                    } />
                                                {isInvalid && (item?.subCategoryName == "" || !item?.subCategoryName) &&
                                                    // <div>
                                                    <p style={{ color: "red" }}>Feedback sub category name is required.</p>
                                                    // </div>
                                                }
                                            </div>
                                            <div className="col" style={{ marginLeft: '10px' }}>
                                                <label className="switch ">
                                                    <input type="checkbox" checked={item?.isDeleted}
                                                        onChange={(e) => {
                                                            setFeedbackSubCategory((prev) => ({
                                                                ...prev, feedbackSubCategoryModels: prev.feedbackSubCategoryModels.map((innerItem, innerIndex) => {
                                                                    if (index == innerIndex) {
                                                                        return { ...innerItem, isDeleted: e.target.checked }
                                                                    }
                                                                    else {
                                                                        return innerItem;
                                                                    }
                                                                })
                                                            }))
                                                        }} />
                                                    <span className="slider round"></span>
                                                </label>
                                            </div>
                                            {isAdd && array.length == index + 1 &&
                                                <div className="col" >
                                                    <div className="additembtn">
                                                        <i className="pi pi-plus" style={{ fontSize: '1rem' }}
                                                            onClick={() => {
                                                                setFeedbackSubCategory((prev) => ({
                                                                    ...prev, feedbackSubCategoryModels: [...prev.feedbackSubCategoryModels, {
                                                                        "subCategoryName": "",
                                                                        "subCategoryNameInArabic": "",
                                                                        "isDeleted": true
                                                                    }]
                                                                }))
                                                            }}
                                                        ></i>
                                                    </div>
                                                </div>}
                                            {array.length > 1 && (
                                                <div className="col" >
                                                    <div className="removeitembtn"
                                                        style={{
                                                            margin: 'auto'
                                                        }}
                                                    >
                                                        <i
                                                            className="pi pi-trash"
                                                            style={{ fontSize: '1rem' }}
                                                            onClick={() => {
                                                                removeCode(index);
                                                            }}
                                                        ></i>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                        <div style={{ marginBottom: '10px' }}>Sub Category Name AR<span style={{ color: 'red' }}>*</span></div>
                                        <div>
                                            <InputText placeholder="Enter Name AR" style={{ border: "1px solid #AE9D81", width: "200px", height: "50px", borderRadius: "5px", boxShadow: "none", direction: 'rtl', textAlign: 'right' }} value={item?.subCategoryNameInArabic}
                                                onChange={
                                                    (e) => {
                                                        setFeedbackSubCategory((prev) => ({
                                                            ...prev, feedbackSubCategoryModels: prev.feedbackSubCategoryModels.map((innerItem, innerIndex) => {
                                                                if (index == innerIndex) {
                                                                    return { ...item, subCategoryNameInArabic: e.target.value }
                                                                }
                                                                else {
                                                                    return innerItem;
                                                                }
                                                            })
                                                        }))
                                                    }
                                                } />
                                            {isInvalid && (item?.subCategoryNameInArabic == "" || !item?.subCategoryNameInArabic) &&
                                                // <div>
                                                <p style={{ color: "red" }}>Feedback sub category Name (AR) is required.</p>
                                                // </div>
                                            }
                                        </div>
                                    </>
                                )
                            })}

                        </div>
                        <div className="footer flex  grid">
                            <div className="col flex justify-content-center ">
                                <Button label="Cancel" onClick={() => {
                                    setIsAddFeedbackSubCategoryPopupOpen(false);
                                    setInvalid(false);
                                    setFeedbackSubCategory({
                                        "category": "",
                                        "categoryNameInArabic": "",
                                        "feedbackSubCategoryModels": [
                                            {
                                                "subCategoryName": "",
                                                "subCategoryNameInArabic": "",
                                                "isDeleted": true,
                                                "subCategoryId": 0
                                            }
                                        ]
                                    });
                                    setFilterObj({
                                        categoryId: "",
                                        status: "",
                                        skip: 0,
                                        take: 10
                                    });
                                }} style={{ backgroundColor: '#8E8E8E', border: "none", margin: "10px", height: '40px' }} />
                                <Button label={isAdd ? "Submit" : "Update"} onClick={() => { isAdd ? addSubCategory() : editSubCategory() }} style={{ backgroundColor: '#ae9d81', border: "none", margin: "10px", height: '40px' }} />
                            </div>
                        </div>
                    </div>
                </Dialog>
            </>
        )
    })
    const [filterObj, setFilterObj] = useState({
        categoryId: "",
        status: "",
        skip: 0,
        take: 10
    });
    const getSubCategory = ((skip = 0, take = 10, searchTerm = "", categoryId = "0", status) => {
        setIsLoading(true);
        let obj = {
            categoryId: categoryId,
            status: status,
            searchTerm: searchTerm,
            skip: skip,
            take: take
        }
        console.log(obj, "obj");
        GetSubCategory(cookies.bearerToken, categoryId, isShow(obj), (status ? status == "In Active" ? "true" : "false" : ""), searchTerm, skip, take)
            .then((res) => {
                if (res.status === 200) {
                    setFeedbackSubCategoryList(res?.data?.data);
                    setTotalRecords(res?.data?.count);
                    // dispatch(
                    //     addFeedbackSubCategoryList({
                    //         feedbackSubCategoryList: res?.data?.data
                    //     }))   -- jeeva
                }
                else if (res.response.status === 400) {
                    toast.error(res.response.data, { position: "top-right" });
                }
            })
            .catch((error) => {
                //console.log(error);
            })
            .finally(() => {
                setIsLoading(false);
                // //console.log(organizationList);
            })

    })
    const back = () => {
        navigate('/dashboard');
    };

    const addSubCategory = (() => {
        let data = {
            "categoryId": feedbackSubCategory?.categoryId,
            "categoryName": feedbackSubCategory?.categoryName,
            "categoryNameInArabic": feedbackSubCategory?.categoryNameInArabic,
            "feedbackSubCategoryModels":
                feedbackSubCategory?.feedbackSubCategoryModels.map(
                    (_) => {
                        return {
                            "subCategoryName": _.subCategoryName,
                            "subCategoryNameInArabic": _.subCategoryNameInArabic,
                            "isDeleted": !_.isDeleted,
                            "subCategoryId": _.subCategoryId
                        }
                    }
                )
        };
        if (feedbackSubCategory?.categoryId == "" ||
            feedbackSubCategory?.categoryName == "" ||
            feedbackSubCategory?.feedbackSubCategoryModels.length == 0 ||
            feedbackSubCategory?.feedbackSubCategoryModels.filter((item) =>
                item.subCategoryName == "" ||
                item.subCategoryNameInArabic == "",
            ).length !== 0
        ) {
            //console.log("Invalid");
            setInvalid(true);
            return;

        }
        let filteredData = feedbackSubCategoryList?.feedbackSubCategoryModels?.filter((item) => {
            data.feedbackSubCategoryModels.forEach((dataItem) => {
                return item.subCategoryName === dataItem.subCategoryName || item.subCategoryNameInArabic === dataItem.subCategoryNameInArabic
            })
        });
        console.log(filteredData);
        setInvalid(false);
        AddSubCategory(data, cookies?.bearerToken)
            .then((res) => {
                //console.log(res);
                getSubCategory();
                setIsAdd(false);
                setIsAddFeedbackSubCategoryPopupOpen(false);
                setFeedbackSubCategory({
                    category: 0, feedbackSubCategoryModels: [{
                        "subCategoryName": "",
                        "subCategoryNameInArabic": "",
                        "isDeleted": false
                    }]
                })
                toast.success("New Feedback Subcategory is added Successfully", { position: "top-right" });
            })
            .catch((error) => {
                //console.log(error);
            })
            .finally(() => {
                setFilterObj({
                    categoryId: "",
                    status: "",
                    skip: 0,
                    take: 10
                });
            })
    });



    const editSubCategory = (() => {

        let data = {
            "categoryId": feedbackSubCategory?.category?.id,
            "categoryName": feedbackSubCategory?.category?.categoryName,
            "categoryNameInArabic": feedbackSubCategory?.category?.categoryNameInArabic,
            "editFeedbackSubCategoryModels": [
                ...feedbackSubCategory?.feedbackSubCategoryModels.map((subCategory) => {
                    return {
                        "subCategoryName": subCategory?.subCategoryName,
                        "subCategoryNameInArabic": subCategory?.subCategoryNameInArabic,
                        "isDeleted": !subCategory?.isDeleted ? true : false,
                        "subCategoryId": subCategory?.subCategoryId
                    }
                })

            ]
        }
        if (feedbackSubCategory?.category?.id == "" ||
            feedbackSubCategory?.category?.categoryName == "" ||
            feedbackSubCategory?.feedbackSubCategoryModels.length == 0 ||
            feedbackSubCategory?.feedbackSubCategoryModels.filter((item) =>
                item.subCategoryName == "" ||
                item.subCategoryNameInArabic == ""
            ).length !== 0 ||
            feedbackSubCategory?.feedbackSubCategoryModels.filter((item) =>
                !item.subCategoryName ||
                !item.subCategoryNameInArabic
            ).length !== 0
        ) {
            //console.log("Edit Invalid", data);
            setInvalid(true);
            return;

        }
        setInvalid(false);
        EditSubCategory(data, cookies?.bearerToken)
            .then((res) => {
                //console.log(res);
                getSubCategory();
                setIsAddFeedbackSubCategoryPopupOpen(false);
                setFeedbackSubCategory({
                    category: 0, editFeedbackSubCategoryModels: [{
                        "subCategoryName": "",
                        "subCategoryNameInArabic": "",
                        "isDeleted": false
                    }]
                })
                toast.success("Feedback Subcategory is updated successfully", { position: "top-right" });
            })
            .catch((error) => {
                //console.log(error);
            })
            .finally(() => {
                setFilterObj({
                    categoryId: "",
                    status: "",
                    skip: 0,
                    take: 10
                });
            })
    });

    const statusBodyTemplate = ((row) => {
        // //console.log(row);
        if (row.isDeleted === false) {
            return <p style={{ color: "green" }}  >Active</p>
        }
        else {
            return <p style={{ color: "red" }} >Inactive</p>
        }
    });
    const actionButtonTemplate = (row) => {
        let filteredCategory = feedbackCategoryList?.filter((item) => item.categoryName == row.categoryName);
        // //console.log(filteredCategory);
        return (
            <div onClick={(e) => {
                //console.log("ROw", row, feedbackCategoryList);
                setIsAdd(false)
                setIsAddFeedbackSubCategoryPopupOpen(true);
                setFeedbackSubCategory((prev) => ({
                    ...prev,
                    category: filteredCategory[0],
                    feedbackSubCategoryModels: [
                        {
                            "subCategoryName": row.subCategoryName,
                            "subCategoryNameInArabic": row.subCategoryNameInArabic,
                            "isDeleted": !row.isDeleted,
                            "subCategoryId": row.subCategoryId
                        }
                    ]
                }))
                //console.log(feedbackSubCategory);
            }}>
                <img src={editIcon} style={{ cursor: 'pointer' }} />
            </div>
        );
    };

    const onGlobalFilterChange = (e) => {

        const value = e ? e.target.value : "";
        // let _filters = { ...filters };
        // _filters['global'].value = value;
        // setFilters(_filters);
        getSubCategory(0, 10, value, filterObj?.categoryId, filterObj?.status);
        setGlobalFilterValue(value);
    };
    const getFeedbackCategoryList = () => {
        GetFeedbackCategoryList(cookies.token)
            .then(res => {
                // //console.log(res);
                setFeedbackCategoryList(res?.data);
                dispatch(
                    addFeedbackCategoryList({
                        feedbackCategoryList: res?.data
                    })
                )
            })
            .catch((err) => {
                console.warn(err);
                return;

            })
    };

    const formatDate = (timestamp) => {
        const timestampDate = new Date(timestamp);
        return timestampDate.toLocaleString('en-US', {
            day: 'numeric',
            month: '2-digit',
            year: 'numeric',
        },);
    }
    const dateFormatTemplate = (row) => {
        // //console.log(("Date", row));
        return <p style={{ fontWeight: "400" }}>{formatDate(row?.createdOn)}</p>
    }
    const exportSubcategory = () => {
        exportDataInExcel('FeedbackSubCategory', cookies.bearerToken);
    }
    useEffect(() => {
        // if (Context?.feedbackCategoryList?.length == 0) {
        getFeedbackCategoryList();
        // }
        // else {
        //     setFeedbackCategoryList(Context?.feedbackCategoryList)
        // }
        // if (Context?.feedbackSubCategoryList?.length == 0) {
        getSubCategory();
        // } else {
        //     setFeedbackSubCategoryList(Context?.feedbackSubCategoryList)
        // }
    }, [])

    // Export Filter
    const [exportFilterDialog, setExportFilterDialog] = useState(false);
    const [exportDetails, setExportDetails] = useState({
        startDate: "",
        endDate: "",
        organization: "",
        desk: "",
        status: true
    });
    const onPageChange = (e) => {

        setFirst(e.first);
        getSubCategory(e.first, 10, globalFilterValue);
    }
    const onExportFeedbackSubCategory = () => {
        // setExportFilterDialog(true);
        // let urlRoute=`FeedbackSubCategory?searchTerm=${globalFilterValue}`;
        // exportExcel(urlRoute);
        let obj = {
            categoryId: filterObj?.categoryId,
            status: filterObj?.status,
            searchTerm: globalFilterValue
        }
        let urlRoute = `FeedbackSubCategory/GetSubCategories?categoryId=${filterObj?.categoryId}${filterObj?.status ? `&showAll=${isShow(obj)}&status=${filterObj?.status == "In Active" ? "true" : "false"}` : ""}&searchTerm=${globalFilterValue}&forExport=true`;
        exportFilterdExcel(urlRoute);
    }
    return (
        <>
            <Dialog header={<h3>Export Filter</h3>} draggable={false} style={{ width: '50vw', height: "max-content" }} closable={true} onHide={() => setExportFilterDialog(false)} visible={exportFilterDialog} >
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                    <div style={{ width: "50%" }}>
                        <label htmlFor="StartDate" className="font-bold block mb-2">Start Date
                        </label>
                        <Calendar
                            id="StartDate"
                            style={{ width: "95%" }}
                            value={exportDetails?.startDate}
                            placeholder="Choose start end"
                            onChange={((value) => setExportDetails(() => ({ ...exportDetails, startDate: value })))}
                        ></Calendar>
                    </div>
                    <div style={{ width: "50%" }}>
                        <label htmlFor="EndDate" className="font-bold block mb-2">End Date
                        </label>
                        <Calendar
                            id="EndDate"
                            style={{ width: "95%" }}
                            value={exportDetails?.endDate}
                            placeholder="Choose end date"
                            onChange={((value) => setExportDetails(() => ({ ...exportDetails, endDate: value })))}
                        ></Calendar>
                    </div>
                    <div style={{ width: "50%" }}>
                        <label htmlFor="organization" className="font-bold block mb-2">Organization
                        </label>
                        <InputText
                            id="organization"
                            style={{ width: "95%" }}
                            value={exportDetails?.organization}
                            onChange={((value) => setExportDetails(() => ({ ...exportDetails, organization: value })))}
                            placeholder="Enter organization" ></InputText>
                    </div>
                    <div style={{ width: "50%" }}>
                        <label htmlFor="Status" className="font-bold block mb-2">Status
                        </label>
                        {/* <InputText
                                id="Status"
                                style={{ width: "95%" }}
                                value={exportDetails?.status}

                                placeholder="Enter organization" ></InputText> */}
                        <div className="input grid ">
                            <div className="col ">
                                <label className="switch ">
                                    <input type="checkbox" value={exportDetails?.status} checked={exportDetails?.status} onChange={((e) => setExportDetails(() => ({ ...exportDetails, status: e.target.checked })))} />
                                    <span className="slider round"></span>
                                </label>
                            </div>
                        </div>
                    </div>

                </div>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "25px",
                    }}
                >
                    <Button
                        label="Submit"
                        onClick={''}
                        // icon={"pi pi-file-export"}
                        style={{
                            backgroundColor: " #ae9d81",
                            color: "#fff",
                            border: "none",
                            // borderRadius: "20px",
                            marginRight: "0",
                            cursor: totalCount > 0 ? "pointer" : "not-allowed",
                            padding: "0.6rem",
                        }}
                    />
                </div>


            </Dialog>
            <div className="feedbackCategoryMaster">
                <div className="header">
                    <div style={{ display: "flex", flexDirection: "row", flexGrow: "3", justifyContent: "flex-start" }} className='flex align-items-center org'>
                        <i className="pi pi-arrow-circle-left nav-back-btn" onClick={back} style={{ marginLeft: '30px', cursor: 'pointer', fontSize: '1.5rem' }} />
                        {/* <div className="menubtn" style={{ padding: '10px 0px 5px 20px' }}>
                            <i className="pi pi-align-justify" style={{ fontSize: '1rem', cursor: "pointer" }} onClick={() => dispatch(
                                setNavVisibilty({ sideNavVisibility: true }))}></i>
                        </div> */}
                        <div className="heading">
                            <p>Feedback Sub Category</p>
                        </div>

                    </div>
                    <div className='mx-2'>
                        <h4 className='mb-1 mt-0' style={{ color: "#ae9d81" }}>Status</h4>
                        <Dropdown value={filterObj?.status} options={[{ name: "Active" }, { name: "In Active" }]} optionLabel='name' optionValue='name' onChange={(e) => { setFilterObj((prev) => ({ ...prev, status: e?.value })); getSubCategory(0, 10, globalFilterValue, filterObj?.categoryId, e.value); }} placeholder='Select' style={{ border: "solid 1px #ae9d81", width: 180 }} showClear={filterObj?.status ? true : false} />
                    </div>
                    <div className='mx-2'>
                        <h4 className='mb-1 mt-0' style={{ color: "#ae9d81" }}>Category</h4>
                        <Dropdown
                            placeholder='Select'
                            options={feedbackCategoryList?.filter((feedback) => feedback?.isDeleted == false)}
                            className="category"
                            value={filterObj?.categoryId}
                            optionLabel="categoryName"
                            optionValue='id'
                            onChange={(e) => {
                                setFilterObj((prev) => ({ ...prev, categoryId: e.value }));
                                getSubCategory(0, 10, globalFilterValue, e.value, filterObj?.status);
                            }}
                            showClear={filterObj?.categoryId ? true : false}
                            style={{ border: "1px solid #AE9D81", width: "180px", borderRadius: "5px", boxShadow: "none" }}
                        />
                    </div>
                    <div className='mx-2'>
                        <h4 className='mb-1 mt-0' style={{ color: "#ae9d81" }}>Status</h4>
                        <div className="search">
                            <span className="p-input-icon-left">
                                <i className="pi pi-search" style={{ color: 'black', fontWeight: '700', fontSize: '1rem' }} />
                                <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Search" style={{ width: "180px" }} />
                                <i className="pi pi-times" style={{ color: 'black', fontWeight: '400' }} onClick={() => { setGlobalFilterValue(""); onGlobalFilterChange() }} />
                            </span>
                        </div>
                    </div>

                    <div className="align-self-start mt-1 mx-2">
                        <Button label="Add New" onClick={() => {
                            setIsAddFeedbackSubCategoryPopupOpen(true);
                            setFeedbackSubCategory((prev) => ({
                                ...prev,
                                category: "",
                                categoryName: "",
                                categoryId: "",
                                feedbackSubCategoryModels: [{
                                    "subCategoryName": "",
                                    "subCategoryNameInArabic": "",
                                    "isDeleted": true,
                                    "subCategoryId": 0
                                }]
                            }));
                            setIsAdd(true);
                            setFilterObj({
                                categoryId: "",
                                status: "",
                                skip: 0,
                                take: 10
                            });
                        }} style={{ backgroundColor: '#ae9d81', border: 'none' }} />
                    </div>

                    <div className='mx-2 align-self-start'>
                        <Button label='Export' onClick={feedbackSubCategoryList && feedbackSubCategoryList.length > 0 ? onExportFeedbackSubCategory : null} icon={"pi pi-file-export"} style={{ backgroundColor: 'transparent', color: '#ae9d81', border: '1px #ae9d81 solid', borderRadius: '20px', marginRight: '20px', cursor: feedbackSubCategoryList.length > 0 ? 'pointer' : 'not-allowed' }} />
                    </div>
                </div>

                <div className="datatable">
                    {isLoading ?
                        <div className='spinner spinner-center'></div>
                        :
                        <>
                            <DataTable value={feedbackSubCategoryList?.length > 0 ? feedbackSubCategoryList : []}
                                filters={filters} tableStyle={{ width: '90rem' }} style={{ boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }} stripedRows
                                emptyMessage={<><p style={{ textAlign: "center" }}>Feedback sub category list is empty</p></>} globalFilterFields={['categoryName', 'subCategoryName', 'createdOn', 'createdBy']}>

                                <Column field="categoryName" header="Category" style={{ width: '14.3%' }}></Column>
                                <Column field="subCategoryName" header="Feedback Sub Category" style={{ width: '14.3%' }}></Column>
                                <Column field="subCategoryNameInArabic" header="Feedback Sub Category AR" style={{ width: '14.3%' }}></Column>
                                <Column field="createdOn" header="Created Date" body={dateFormatTemplate} style={{ width: '14.3%' }}></Column>
                                <Column field="createdBy" header="Created By" style={{ width: '14.3%' }}></Column>
                                <Column field="status" header="Status" body={statusBodyTemplate} style={{ width: '14.3%' }}></Column>
                                <Column rowEditor header="Action" body={actionButtonTemplate} style={{ width: '14.3%' }}></Column>
                            </DataTable>
                        </>}
                </div>
                <div style={{ justifyContent: "center", display: "flex" }}>
                    <Paginator
                        first={first}
                        rows={10}
                        totalRecords={totalCount}
                        onPageChange={(e) => { onPageChange(e) }}
                        template={{
                            layout:
                                "RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink  LastPageLink CurrentPageReport",
                        }}
                        className="justify-content-start"
                    />
                </div>
            </div>

            <div>
                {isAddFeedbackSubCategoryPopupOpen == true ? popup() : ""}
            </div>
        </>
    )
}

export default FeedbackSubCategory