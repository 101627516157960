import Axios from '../Helpers/Axios';

export function GetFeedbackDepartmentCategoryList(token) {
    return Axios('DepartmentHead/List', 'get', null, token)
}
export function GetActiveDepartments(token) {
    return Axios('DepartmentHead/ActiveDepartments', 'get', null, token)
}
export function AddFeedbackDepartmentCategory(data, token) {
    return Axios('DepartmentHead/Add', 'post', data, token)
}
export function EditFeedbackDepartmentCategory(data, token) {
    return Axios('DepartmentHead/Edit', 'post', data, token)
}