import Axios from "../Helpers/Axios";


export const AddLostItemForm = (payLoad, token) => {
    return Axios('LostAndFound/Lost/Add', 'post', payLoad, token)
}
export const EditLostItemForm = (payLoad, token) => {
    return Axios('LostAndFound/Lost/Edit', 'post', payLoad, token)
}
export const GetLostItem = (id, token) => {
    return Axios(`LostAndFound/Lost/Get?id=${id}`, 'get', null, token)
}
export const GetLostItemListForGSR = (skip, take, searchTerm, token) => {
    return Axios(`LostAndFound/Lost/GetAll?skip=${skip}&take=${take}&searchTerm=${searchTerm}`, 'get', null, token)
}
export const GetLostItemList = (skip, take, searchTerm, token,fromDate,toDate) => {
    return Axios(`LostAndFound/Lost/All?skip=${skip}&take=${take}&searchTerm=${searchTerm}&from=${fromDate}&to=${toDate}&forExport=false`, 'get', null, token)
}
export const GetFoundItemList = (skip, take, searchTerm, token) => {
    return Axios(`LostAndFound/Found/All?skip=${skip}&take=${take}&searchTerm=${searchTerm}`, 'get', null, token)
}
export const GetFoundItem = (id, token) => {
    return Axios(`LostAndFound/Found/Get?id=${id}`, 'get', null, token)
}
export const GetStatusForFoundItem = (token) => {
    return Axios(`LostAndFound/Found/Status`, 'get', null, token)
}
export const GetActiveStorageLocation = (token) => {
    return Axios(`LostAndFound/StorageLocation/ActiveStorage`, 'get', null, token)
}
export const EditFoundItemForGSR = (payload, token) => {
    return Axios(`LostAndFound/Found/Edit`, 'post', payload, token)
}
export const AddFoundItem = (payload, token) => {
    return Axios(`LostAndFound/Found/Add`, 'post', payload, token)
}
export const AddReturnFormForm = (payload, token) => {
    return Axios(`LostAndFound/Return/Add`, 'post', payload, token)
}
export const GetAllLostItems = (take, skip, searchTerm = "", fromDate = "", toDate = "", token) => {
    return Axios(`LostAndFound/Lost/All?skip=${skip}&take=${take}&searchTerm=${searchTerm}&from=${fromDate}&to=${toDate}`, 'get', null, token)
}
export const GetAllFoundedItems = (take, skip, searchTerm = "", fromDate = "", toDate = "", token) => {
    return Axios(`LostAndFound/Found/All?skip=${skip}&take=${take}&searchTerm=${searchTerm}&from=${fromDate}&to=${toDate}`, 'get', null, token)
}
export const GetSpecificFoundItems = (take, skip, searchTerm = "", fromDate = "", toDate = "", token) => {
    return Axios(`LostAndFound/Found/GetAll?skip=${skip}&take=${take}&searchTerm=${searchTerm}&from=${fromDate}&to=${toDate}`, 'get', null, token)
}


// Export 


export const ExportFoundItems = (userId, searchTerm, fromDate, toDate, token) => {
    return Axios(`Export/FoundDetails?strUserId=${userId}&searchTerm=${searchTerm}&from=${fromDate}&to=${toDate}`, 'get', null, token)
}
export const ExportLostItems = (userId, searchTerm, token) => {
    return Axios(`Export/LostDetails?strUserId=${userId}}&searchTerm=${searchTerm}`, 'get', null, token)
}