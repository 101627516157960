import React, { useState, useEffect } from "react";
import { useCookies } from "react-cookie";
import { Link } from "react-router-dom";
import "./style.scss";
import { useNavigate } from "react-router-dom";

//------Imports from other Components-----

import Header from "../Header/Header";

//----------------------------------------

//------Service imports-----
import { GetOrganizationList } from "../../Services/OrganizationService";
import { GetDeskList } from "../../Services/DeskService";
import { GetRolesList } from "../../Services/RoleService";
//----------------------------------------

//-----------Context ---------------
import { useDispatch, useSelector } from "react-redux";
import {
  addOrganizationList,
  addDeskList,
  setNavVisibilty,
  addRoleList,
  setUserdata,
} from "../../Redux/AppSlice";
//----------------------------------------

function Dashboard() {
  const Context = useSelector((state) => state.AppContext);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [cookies, setCookie, removeCookie] = useCookies();
  const [isSideBarView, setIsSideBarView] = useState(false);

  const getOrganizationList = () => {
    GetOrganizationList(cookies.bearerToken)
      .then((res) => {
        // //console.log("Fetching Organization List");
        dispatch(
          addOrganizationList({
            organizationList: res?.data,
          })
        );
      })
      .catch((error) => {
        //console.log(error);
      });
  };
  const getDeskList = () => {
    let take = 50;
    let skip = "0";
    GetDeskList(take, skip, cookies.bearerToken)
      .then((res) => {
        dispatch(addDeskList({ deskList: res?.data }));
      })
      .catch((error) => {
        //console.log(error);
      });
  };
  const getRoleList = () => {
    GetRolesList(cookies.bearerToken)
      .then((res) => {
        // addRoleList(res?.data);
        dispatch(addRoleList({ roleList: [...res?.data] }));
      })
      .catch((error) => {
        console.warn(error);
      });
  };
  const organization = () => {
    navigate("/organization");
  };
  const QRGeneration = () => {
    navigate("/QRGeneration");
  };
  const ShopAndDropMaster = () => {
    navigate("/shop-and-drop-master");
  };

  const desk = () => {
    navigate("/desk");
  };

  const items = () => {
    navigate("/items");
  };

  const role = () => {
    navigate("/role");
  };

  const feedbackcategory = () => {
    navigate("/feedbackcategory");
  };

  const feedbacksubcategory = () => {
    navigate("/feedbacksubcategory");
  };

  const tenant = () => {
    navigate("/tenant");
  };
  const tenantCategory = () => {
    navigate("/tenant-category");
  };

  const department = () => {
    navigate("/department");
  };

  const categoryconfiguration = () => {
    navigate("/categoryconfiguration");
  };
  const storage = () => {
    navigate("/storage");
  };
  const lostAndFoundCategory = () => {
    navigate("/lostandfoundcategory");
  };
  const tenantDescription = () => {
    navigate("/tenantdescription");
  };
  const tenantQuestions = () => {
    navigate("/tenantquestions");
  };
  const emailConfiguration = () => {
    navigate("/email-configuration");
  };
  const feedbackDepartmentCategory = () => {
    navigate("/feedback-department-category");
  };
  const subscriptionConfiguration = () => {
    navigate('/supervisor-subscription-config');
  }
  const vipmaster = () => {
    navigate('/vip-master-config');
  }
  const categoryMaster = () => {
    navigate('/category-master-config');
  }
  const systemUser = () => {
    navigate('/system-user');
  }

  useEffect(() => {
    if (Context?.organizationList?.length === 0) {
      getOrganizationList();
    }
    if (Context?.deskList?.length === 0) {
      getDeskList();
    }
    getRoleList();
    // dispatch(setUserdata({ roles: ["ADMIN", "GUESTSTAFFINSPECTOR", "DEPARTMENTTEAM", "GUESTSTAFFSUPERVISOR", "MGTENANTRELATIONSHIP"] }))
  }, []);

  return (
    <>
      <div className="configurationPage container">
        <div className="header">
          <div style={{ display: "flex" }} className="align-items-center">
            <div className="menubtn" style={{ padding: "10px 10px 5px 20px" }}>
              <i
                className="pi pi-align-justify"
                style={{
                  fontSize: "1rem",
                  color: "#ae9d81",
                  cursor: "pointer",
                }}
                onClick={() =>
                  dispatch(setNavVisibilty({ sideNavVisibility: true }))
                }
              ></i>
            </div>
            <div className="heading">
              <p className="configuration">Configuration</p>
            </div>
          </div>
        </div>
        <div
          className="grid body"
          style={{
            width: "100%",
            padding: "20px 30px",
            marginBottom: "10px",
          }}
        >
          <div className="col-4">
            <div className="container" onClick={organization}>
              <p className="label"> Organization</p>
            </div>
          </div>
          <div className="col-4">
            <div className="container" onClick={desk}>
              <p className="label">Desk</p>
            </div>
          </div>
          <div className="col-4">
            <div className="container" onClick={items}>
              <p className="label">Items</p>
            </div>
          </div>

          <div className="col-4">
            <div className="container" onClick={role}>
              <p className="label">Role</p>
            </div>
          </div>
          <div className="col-4">
            <div className="container" onClick={feedbackcategory}>
              <p className="label">Feedback Category</p>
            </div>
          </div>
          <div className="col-4">
            <div className="container" onClick={feedbacksubcategory}>
              <p className="label">Feedback Sub Category</p>
            </div>
          </div>

          <div className="col-4">
            <div className="container" onClick={tenant}>
              <p className="label">Tenant</p>
            </div>
          </div>
          <div className="col-4">
            <div className="container" onClick={tenantCategory}>
              <p className="label">Tenant Category</p>
            </div>
          </div>
          <div className="col-4">
            <div className="container" onClick={department}>
              <p className="label">Department</p>
            </div>
          </div>
          <div className="col-4">
            <div className="container" onClick={categoryconfiguration}>
              <p className="label"> Department Category </p>
            </div>
          </div>
          <div className="col-4">
            <div className="container" onClick={storage}>
              <p className="label"> Storage Location</p>
            </div>
          </div>
          <div className="col-4">
            <div className="container" onClick={lostAndFoundCategory}>
              <p className="label"> Lost and Found Category</p>
            </div>
          </div>
          <div className="col-4">
            <div className="container" onClick={tenantDescription}>
              <p className="label"> Tenant Description</p>
            </div>
          </div>
          <div className="col-4">
            <div className="container" onClick={tenantQuestions}>
              <p className="label"> Tenant Questions</p>
            </div>
          </div>
          <div className="col-4">
            <div className="container" onClick={emailConfiguration}>
              <p className="label"> Email Configuration</p>
            </div>
          </div>
          <div className="col-4">
            <div className="container" onClick={feedbackDepartmentCategory}>
              <p className="label"> Feedback Department Category</p>
            </div>
          </div>
          <div className="col-4">
            <div className="container" onClick={QRGeneration}>
              <p className="label">Hands-Free Check-in QR Generation</p>
            </div>
          </div>
          <div className="col-4">
            <div className="container" onClick={ShopAndDropMaster}>
              <p className="label">Hands-Free Congifuration</p>
            </div>
          </div>
          <div className="col-4">
            <div className="container" onClick={subscriptionConfiguration}>
              <p className="label">Subscription Configuration</p>
            </div>
          </div>
          <div className="col-4">
            <div className="container" onClick={vipmaster}>
              <p className="label">VIP Master</p>
            </div>
          </div>
          <div className="col-4">
            <div className="container" onClick={categoryMaster}>
              <p className="label">Pantry Category Master</p>
            </div>
          </div>
          <div className="col-4">
            <div className="container" onClick={systemUser}>
              <p className="label">System User</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Dashboard;
