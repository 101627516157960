import React, { useRef, useState, useEffect } from "react";
import "./Guest.scss";
import { useSearchParams } from "react-router-dom";
import { useCookies } from "react-cookie";
import IntlTelInput from "react-intl-tel-input";
import "react-intl-tel-input/dist/main.css";
//prime react
import { InputText } from "primereact/inputtext";
import { Checkbox } from "primereact/checkbox";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import logo from "../../../assets/images/logo.jpg";
import localization from "../../../assets/JSON/Localization.json";
import nationalityLists from "../../../assets/JSON/Nationality.json";
import nationalityListJSON from "../../../assets/JSON/NationalityFlags.json"
import guestLocalization from "../../../assets/JSON/GuestInfo.json";
//camera
import CustomWebcam from "../../Camera/Camera";

//redux

import {
  GetProofTypes,
  AddGuestCheckinInfo,
  GetUserInformation,
} from "../../../Services/ShopAndDropService";
import { toast } from "react-toastify";
import { Dropdown } from "primereact/dropdown";
import termsandconditionpdf from '../../../assets/files/MG-VIP Loung T&C.pdf';

const Guest = (props) => {
  //states

  const [imgSrc, setImgSrc] = useState("");
  const [isCameraVisible, setIsCameraVisible] = useState(false);
  const [proofTypes, setProofTypes] = useState([]);
  const [cookies, setCookie, removeCookie] = useCookies();
  const [isErrMsgView, setIsErrMsgView] = useState(false);
  const [isModalOPen, setIsModalOpen] = useState(false);
  const inputRef = useRef(null);
  const [btnLoading, setBtnLoading] = useState(false);
  const [isLoader, setIsLoader] = useState(false);
  const [isConfirmationOpen, setIsConformationOpen] = useState(false);
  const [isTermsAndConditions, setIsTermAndCondition] = useState(false);
  const [isExistingUser, setIsExistingUser] = useState(false);
  const [activeLanguage, setActiveLanguage] = useState("English");

  const [params] = useSearchParams();
  let organizationID = parseFloat(params.get("organizationId"))


  const initialGuestInformation = {
    phoneNumber: "",
    firstName: "",
    lastName: "",
    emailId: "",
    proof: "",
    receiveEmaarEmails: false,
    isTermsAndConditions: false,
    phoneNumberCode: "",
    proofImageUrl: "",
  };

  const [userInformations, setUserInformations] = useState({
    ...initialGuestInformation,
  });
  const [selectedCountry, setSelectedCountry] = useState({
    "english_name": "Bahrain",
    "arabic_name": "البحرين",
    "alpha2_code": "BH",
    "alpha3_code": "BHR",
    "phone_code": "+973",
    "flagImage": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BH.svg"
  });
  // console.log("userInformations", userInformations);

  // mail validation

  const emailValidation = () => {
    var emailFormat = /^([^\s@]+)@([A-Za-z])+\.([A-Za-z])+$/;
    let isValidEmail = emailFormat.test(userInformations.emailId);
    return isValidEmail;
  };

  //continue

  const handleContinue = async () => {
    setIsLoader(true);
    let data = {
      countryCode: userInformations?.phoneNumberCode,
      phoneNumber: userInformations?.phoneNumber,
      firstName: userInformations?.firstName,
      lastName: userInformations?.lastName,
      emailId: userInformations?.emailId || "",
      receiveEmaarEmails: userInformations?.receiveEmaarEmails,
    };

    await AddGuestCheckinInfo(organizationID, data)
      .then((res) => {
        console.log(res);
        toast.success("Guest information added successfully", {
          position: "top-right",
        });
        setIsConformationOpen(false);
        setUserInformations({ ...initialGuestInformation });
        setBtnLoading(false);
        setIsLoader(false);
        setIsExistingUser(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // input values

  const handleInputValue = (key, value) => {
    setUserInformations({ ...userInformations, [key]: value });
  };

  const getAllProofTypes = async () => {
    await GetProofTypes(cookies?.bearerToken)?.then((res) => {
      if (res?.status == 200) {
        setProofTypes(res?.data);
      }
    });
  };
  const getImage = (imageSrc) => {
    handleInputValue("proofImageUrl", imageSrc);
  };

  const handlePhoneChange = (value, countryData) => {
    console.log("countryData", countryData);
    if (
      value?.length <= 12 &&
      /^[0-9]+$|^$/.test(value) &&
      value !== userInformations?.phoneNumber
    ) {
      setUserInformations({
        ...userInformations,
        phoneNumber: value,
        phoneNumberCode: countryData?.dialCode,
      });
    }
    // setPhoneNumber(value);
  };


  // Deepak - Country Picker
  const handleChange = (value) => {
    console.log("Change", value);
    setUserInformations({
      ...userInformations,
      phoneNumber: value
    });
  }
  const handleCountryChange = (value) => {
    console.log("Change", value);
    setUserInformations({
      ...userInformations,
      phoneNumberCode: value?.phone_code.includes("+") ? value.phone_code : "+" + value.phone_code
    });
  }
  const selectedCountryTemplate = (option, props) => {
    // console.log(option);
    if (option) {
      return (
        <div className="flex align-items-center">
          <img alt={option.flagImage} src={option?.flagImage} style={{ width: '18px' }} />
        </div>
      );
    }

    return <span>{props.placeholder}</span>;
  };

  const countryOptionTemplate = (option) => {
    return (
      <div className="flex align-items-center">
        <img alt={option.name} src={option?.flagImage} style={{ width: '18px' }} />
        <div>{option?.english_name}({option?.arabic_name}){option?.phone_code}</div>
      </div>
    );
  };
  // -------------------------------

  const getSelectedCountryName = () => {
    let selectedCountry = "bh";
    if (inputRef?.current?.countries && userInformations?.phoneNumberCode) {
      selectedCountry = [...inputRef?.current?.countries]?.find(
        (val) => val.dialCode === userInformations?.phoneNumberCode.split("+")[1]
      )?.iso2;
    }
    console.log("selectedCountry", selectedCountry);

    return selectedCountry ? selectedCountry : "bh";
  };

  const getGuestInfo = async () => {
    let isExsists = false;
    console.log("guestInfo called");
    if (userInformations?.phoneNumber == "") {
      return;
    }
    await GetUserInformation(userInformations?.phoneNumber)
      ?.then((res) => {
        if (res?.status == 200) {
          let guestInfo = res?.data?.response;
          console.log("guestInfo", guestInfo);
          if (guestInfo) {
            isExsists = true;
            setUserInformations({
              ...userInformations,
              firstName: guestInfo?.firstName,
              lastName: guestInfo?.lastName,
              emailId: guestInfo?.emailId,
              phoneNumberCode: guestInfo?.countryCode.includes("+") ? guestInfo?.countryCode : "+" + guestInfo?.countryCode,
            });
            let tempSelectedCountry = nationalityListJSON?.filter(country => country.phone_code == guestInfo?.countryCode.includes("+") ? guestInfo?.countryCode : "+" + guestInfo?.countryCode)[0]
            setSelectedCountry(tempSelectedCountry);
          } else {
            setUserInformations({
              ...userInformations,
              firstName: "",
              lastName: "",
              emailId: "",
            });
          }
        } else {
          setUserInformations({
            ...userInformations,
            firstName: "",
            lastName: "",
            emailId: "",
          });
        }
      })
      .finally(() => {
        setIsExistingUser(isExsists);
      });
  };

  useEffect(() => { }, []);

  return (
    <>
      <Dialog visible={isTermsAndConditions} style={{ width: "100%" }} onHide={() => setIsTermAndCondition(false)} >
        <iframe src={guestLocalization[activeLanguage]?.link} style={{ width: "100%", height: 500 }}></iframe>
      </Dialog>
      {isLoader ? (
        <div
          className="spinner spinner-center"
          style={{ marginLeft: "750px" }}
        ></div>
      ) : (
        <>
          <div
            className="sm:col-4 md:col-4 lg:col-2"
            style={{
              //  width: '14%',
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          // className="logo"
          >
            <div className="image-log-mg">
              <img src={logo} alt="logo" style={{ width: "100%" }} />
            </div>
          </div>
          <div className="guest" style={{ direction: activeLanguage == "English" ? "ltr" : "rtl" }}>
            <div className="flex justify-content-between">
              <div className="guest-information-heading">
                <h2>{guestLocalization[activeLanguage]?.titleNameLabel}</h2>
              </div>
              <div>
                <Dropdown
                  className="p-2"
                  value={activeLanguage}
                  options={[{ label: "English" }, { label: "عربي" }]}
                  optionValue="label" optionLabel="label"
                  onChange={(e) => setActiveLanguage(e?.value)} />
              </div>
            </div>

            <div className="guest-information-content">
              <div className="input">
                <div className="input-box mobile-number">
                  <p className="sm:w-full md:w-5 lg:w-5" style={{ direction: activeLanguage == "English" ? "ltr" : "rtl" }}>
                    {guestLocalization[activeLanguage]?.mobileNumberLabel}
                    <span style={{ color: "red" }}>*</span>
                  </p>
                  <div className="sm:w-full md:w-5 lg:w-5" onBlur={() => getGuestInfo()} style={{ display: 'flex' }} >
                    <Dropdown disabled={isExistingUser} value={selectedCountry} style={{ width: "100px !important", border: "none" }} onChange={(e) => { console.log(e.value); setSelectedCountry(e.value); handleCountryChange(e.value) }} options={nationalityListJSON} optionLabel="english_name"
                      valueTemplate={selectedCountryTemplate} itemTemplate={countryOptionTemplate}
                    />
                    <InputText disabled={isExistingUser} style={{ border: "none" }} value={userInformations?.phoneNumber} type='tel' min={0} placeholder='Enter here' onChange={(e) => { handleChange(e.target.value) }}></InputText>
                    {/* <CountryPicker mobile={userInformations.phoneNumber} countryCode={userInformations.phoneNumberCode} handleChange={handleChange} handleCountryChange={handleCountryChange}   ></CountryPicker> */}

                  </div>

                  {isErrMsgView && !userInformations?.phoneNumber && (
                    <p className="err-msg sm:w-full md:w-5 lg:w-5" style={{ direction: activeLanguage == "English" ? "ltr" : "rtl" }}>{guestLocalization[activeLanguage]?.mobileError}</p>
                  )}
                </div>
              </div>
              <div className="input">
                <div className="input-box">
                  <p className="sm:w-full md:w-5 lg:w-5" style={{ direction: activeLanguage == "English" ? "ltr" : "rtl" }}>
                    {guestLocalization[activeLanguage]?.firstNameLabel}
                    <span style={{ color: "red" }}>*</span>
                  </p>
                  <div className="sm:w-full md:w-5 lg:w-5">
                    <InputText
                      style={{ direction: activeLanguage == "English" ? "ltr" : "rtl" }}
                      placeholder={guestLocalization[activeLanguage]?.PlaceholderValueLabel}
                      value={userInformations?.firstName}
                      disabled={isExistingUser}
                      onChange={(e) => {
                        if (e?.target?.value?.length <= 30) {
                          handleInputValue("firstName", e.target.value);
                        }
                      }}
                    />
                  </div>

                  {isErrMsgView && !userInformations?.firstName && (
                    <p className="err-msg sm:w-full md:w-5 lg:w-5" style={{ direction: activeLanguage == "English" ? "ltr" : "rtl" }}>{guestLocalization[activeLanguage]?.firstNameError}</p>
                  )}
                </div>
              </div>
              <div className="input">
                <div className="input-box">
                  <p className="sm:w-full md:w-5 lg:w-5" style={{ direction: activeLanguage == "English" ? "ltr" : "rtl" }}>
                    {guestLocalization[activeLanguage]?.lastNameLabel}
                    <span style={{ color: "red" }}>*</span>
                  </p>
                  <div className="sm:w-full md:w-5 lg:w-5">
                    <InputText
                      style={{ direction: activeLanguage == "English" ? "ltr" : "rtl" }}
                      placeholder={guestLocalization[activeLanguage]?.PlaceholderValueLabel}
                      disabled={isExistingUser}
                      value={userInformations?.lastName}
                      onChange={(e) => {
                        if (e.target.value.length <= 30) {
                          handleInputValue("lastName", e.target.value);
                        }
                      }}
                    />
                  </div>
                  {isErrMsgView && !userInformations?.lastName && (
                    <p className="err-msg sm:w-full md:w-5 lg:w-5" style={{ direction: activeLanguage == "English" ? "ltr" : "rtl" }}>{guestLocalization[activeLanguage]?.lastNameError}</p>
                  )}
                </div>
              </div>
              <div className="input">
                <div className="input-box">
                  <p className="sm:w-full md:w-5 lg:w-5" style={{ direction: activeLanguage == "English" ? "ltr" : "rtl" }}>
                    {guestLocalization[activeLanguage]?.emailLabel}
                    {/* <span style={{ color: "red" }}>*</span> */}
                  </p>
                  <div className="sm:w-full md:w-5 lg:w-5">
                    <InputText
                      style={{ direction: activeLanguage == "English" ? "ltr" : "rtl" }}
                      placeholder={guestLocalization[activeLanguage]?.PlaceholderValueLabel}
                      isExistingUser={isExistingUser}
                      disabled={props.isExisitingUser}
                      value={userInformations?.emailId}
                      onChange={(e) => {
                        if (e.target.value.length <= 30) {
                          handleInputValue("emailId", e.target.value);
                        }
                      }}
                    />
                  </div>

                  {isErrMsgView && (
                    <p className="err-msg sm:w-full md:w-5 lg:w-5" style={{ direction: activeLanguage == "English" ? "ltr" : "rtl" }}>
                      {(userInformations?.emailId
                        && !emailValidation()) &&
                        guestLocalization[activeLanguage]?.emailvalidationError
                      }
                    </p>
                  )}
                </div>
              </div>

              {/* <div className="input">
                <div className="input-box">
                  <p>
                    Proof type
                    <span style={{ color: "red" }}>*</span>
                  </p>
                  <Dropdown
                    value={userInformations?.proof}
                    onChange={(e) => handleInputValue("proof", e.target.value)}
                    options={proofTypes}
                    optionLabel="name"
                    placeholder="Select here"
                  />
                  {isErrMsgView && !userInformations?.proof && (
                    <p className="err-msg">Proof type is required</p>
                  )}
                </div>
              </div> */}
              {userInformations?.proof && (
                <>
                  {userInformations.proofImageUrl ? (
                    <div className="input">
                      <div className="input-box">
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "10px",
                          }}
                        >
                          {/* {userInformations?.proof?.name} */}

                          <i
                            className="pi pi-paperclip"
                            style={{
                              color: "#ae9d81",
                              cursor: "pointer",
                              fontSize: "25px",
                            }}
                            onClick={() => {
                              setIsModalOpen(true);
                            }}
                          ></i>

                          <i
                            className="pi pi-times"
                            style={{
                              color: "#8e8e8e",
                              cursor: "pointer",
                              fontSize: "15px",
                            }}
                            onClick={() => {
                              handleInputValue("proofImageUrl", "");
                            }}
                          ></i>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="input">
                      <div className="input-box">
                        <i
                          className="pi pi-camera"
                          style={{
                            color: "#ae9d81",
                            cursor: "pointer",
                            fontSize: "30px",
                          }}
                          onClick={() => {
                            setIsCameraVisible(true);
                          }}
                        ></i>
                        {isErrMsgView && !userInformations?.proofImageUrl && (
                          <p className="err-msg sm:w-full md:w-5 lg:w-5" style={{ direction: activeLanguage == "English" ? "ltr" : "rtl" }} >Proof image is required</p>
                        )}
                      </div>
                    </div>
                  )}
                </>
              )}

              <div className="check">
                <div className="check-box sm:w-full md:w-5 lg:w-5" style={{ direction: activeLanguage == "English" ? "ltr" : "rtl" }}>
                  <Checkbox
                    inputId="EmmarEmails"
                    checked={userInformations?.receiveEmaarEmails}
                    onChange={(e) =>
                      handleInputValue("receiveEmaarEmails", e.target.checked)
                    }
                  />
                  <label style={{ cursor: "pointer" }} htmlFor="EmmarEmails">
                    {guestLocalization[activeLanguage]?.likeRecieveEmailLabel}
                  </label>
                </div>
              </div>
              <div className="check">
                <div className="check-box sm:w-full md:w-5 lg:w-5" style={{ direction: activeLanguage == "English" ? "ltr" : "rtl" }}>
                  <Checkbox
                    inputId="TermsAndConditions"
                    checked={userInformations?.isTermsAndConditions}
                    onChange={(e) =>
                      handleInputValue("isTermsAndConditions", e.target.checked)
                    }
                  />
                  <p
                  // className="sm:fs-2"
                  // htmlFor="TermsAndConditions"
                  >
                    {guestLocalization[activeLanguage]?.termsAndConditionLabel1}{" "}
                    <span style={{ color: "blue", cursor: "pointer" }} onClick={() => {
                      // window.open(Itemcsv)
                      var downloadLink = document.createElement("a");
                      downloadLink.href = termsandconditionpdf;
                      downloadLink.download = "MG-VIP Loung T&C.pdf";
                      document.body.appendChild(downloadLink);
                      downloadLink.click();
                      document.body.removeChild(downloadLink);
                    }}>{guestLocalization[activeLanguage]?.termsAndConditionLabel3}</span> {guestLocalization[activeLanguage]?.termsAndConditionLabel2}
                  </p>
                </div>
                {isErrMsgView && !userInformations?.isTermsAndConditions && (
                  <p className="err-msg sm:w-full md:w-5 lg:w-5" style={{ color: "red", paddingTop: "5px", direction: activeLanguage == "English" ? "ltr" : "rtl" }}>
                    {guestLocalization[activeLanguage]?.termsAndConditionError}
                  </p>
                )}
              </div>
            </div>
            <div className="bag-btn">
              <Button
                label={guestLocalization[activeLanguage]?.requestForPickupLabel}
                style={{
                  backgroundColor: "#ae9d81",
                  border: "none",
                  borderRadius: "3px",
                  width: "160px",
                  height: "40px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginBottom: "10px",
                }}
                onClick={() => {
                  let isValidation =
                    userInformations.firstName &&
                    userInformations.lastName &&
                    // userInformations.emailId &&
                    userInformations.phoneNumber &&
                    // userInformations.proof &&
                    // userInformations.proofImageUrl &&
                    userInformations.isTermsAndConditions &&
                    (userInformations.emailId ? emailValidation() : true);

                  setIsErrMsgView(!isValidation);
                  if (isValidation) {
                    setIsConformationOpen(true);
                  }
                }}
              />
            </div>
          </div>
          {isCameraVisible && (
            <CustomWebcam
              setIsVisible={setIsCameraVisible}
              setImage={setImgSrc}
              getImage={getImage}
            />
          )}
          <Dialog
            header={
              <h3 style={{ textAlign: "center", color: "#ae9d81", margin: 0 }}>
                Proof preview
              </h3>
            }
            visible={isModalOPen}
            style={{ width: "50%" }}
            onHide={() => {
              setIsModalOpen(false);
            }}
          >
            <div className="success-modal">
              <div className="success-modal-content">
                <div
                  style={{
                    width: "85%",
                    margin: "auto",
                    height: "410px",
                    overflowY: "auto",
                  }}
                >
                  <img
                    style={{ width: "100%", height: "auto" }}
                    src={userInformations.proofImageUrl}
                  ></img>
                </div>
              </div>
            </div>
          </Dialog>
          <Dialog
            header={
              <h3 style={{ textAlign: "center", color: "#ae9d81", margin: 0 }}>
                {guestLocalization[activeLanguage]?.confirmationTitle}
              </h3>
            }
            visible={isConfirmationOpen}
            className="sm:w-9 md:w-9 lg:w-4 xl:w-4"
            onHide={() => {
              setIsConformationOpen(false);
            }}
          >
            <div className="track-delivery-view-success-modal">
              <div className="success-modal-content">
                <p style={{ fontSize: "18px" }}>
                  {guestLocalization[activeLanguage]?.confirmationContent}
                </p>
              </div>
              <div className="success-modal-btn" style={{ gap: "10px" }}>
                <Button
                  label={guestLocalization[activeLanguage]?.noLabel}
                  style={{
                    backgroundColor: "#8e8e8e",
                    border: "none",
                    borderRadius: "3px",
                    width: "120px",
                    height: "40px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  onClick={() => {
                    setIsConformationOpen(false);
                  }}
                />
                <Button
                  label={guestLocalization[activeLanguage]?.yesLabel}
                  loading={btnLoading}
                  style={{
                    backgroundColor: "#ae9d81",
                    border: "none",
                    borderRadius: "3px",
                    width: "120px",
                    height: "40px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  onClick={() => {
                    setBtnLoading(true);
                    handleContinue();
                  }}
                />
              </div>
            </div>
          </Dialog>
        </>
      )}
    </>
  );
};

export default Guest;
