import Axios from "../Helpers/Axios";

export function GetEmailAllList(token) {
    return Axios(`EmailUser/List`, "get", null, token)
}
export function GetEmailUserType(token) {
    return Axios(`EmailUser/UserTypes`, "get", null, token)
}
export function CreateAnEmailWithUserType(data, token) {
    return Axios(`EmailUser/Add`, "post", data, token)
}
export function GetEmailUserDetails(id, token) {
    return Axios(`EmailUser/Get?id=${id}`, "get", null, token)
}
export function EditEmailUserData(data, token) {
    return Axios(`EmailUser/Edit`, "put", data, token)
}