import Axios from "../Helpers/Axios";

export function GetPantryItems(skip, take, searchTerm, token, categoryName, fromDate, toDate) {
  return Axios(
    `VIPLounge/VIPPantryStaff/GetPantryItems?skip=${skip}&take=${take}&searchTerm=${searchTerm || ""}&categoryName=${categoryName || ""}&fromDate=${fromDate || ""}&toDate=${toDate || ""}&forExport=false`,
    "get",
    null,
    token
  );
}
export function GetAllPantryItems(SearchTerm, token) {
  return Axios(
    `User/GetAllPantryItems?searchTerm=${SearchTerm}`,
    "get",
    null,
    token
  );
}
export function AddInventoryItem(data, token) {
  return Axios(`VIPLounge/VIPPantryStaff/AddItem`, "post", data, token);
}
export function UpdateInventoryItem(data, token) {
  return Axios(`VIPLounge/VIPPantryStaff/EditItem`, "patch", data, token);
}
export function BulkUpdateInventoryItem(data, token) {
  return Axios(`VIPLounge/VIPPantryStaff/BulkEditItems`, "patch", data, token);
}
export function GetPantryPurchaseItems(skip, take, searchTerm, category, token) {
  return Axios(
    `VIPLounge/VIPPantryStaff/GetPantryPurchaseItems?skip=${skip}&take=${take}&searchTerm=${searchTerm}&categoryName=${category}`,
    "get",
    null,
    token
  );
}
export function PantryItemsCheckout(data, token) {
  return Axios(
    `VIPLounge/VIPPantryStaff/PantryItemsCheckout`,
    "post",
    data,
    token
  );
}

export function BulkImportVIPLoungeInventory(data, token) {
  return Axios(`Excel/BulkImportVIPLoungeInventory`, "post", data, token, true);
}

export function GetVipUsers(token, search, skip, take) {
  return Axios(
    `VIPLounge/VIPPantryStaff/FindVIPUser?vipNumberOrCardNumber=${search}&skip=${skip}&take=${take}
    `,
    "get",
    null,
    token
  );
}

export function CheckOutVipUser(token, data) {
  return Axios(
    `VIPLounge/VIPPantryStaff/PantryItemsCheckout`,
    "post",
    data,
    token
  );
}

export function FindVipUser(token, skip, take, vipCodeOrMobileNumber) {
  return Axios(
    `VIPLounge/VIPPantryStaff/FindVIPUser?vipNumberOrCardNumberOrMobileNumber=${vipCodeOrMobileNumber}&skip=${skip}&take=${take}
    `,
    "get",
    null,
    token
  );
}
