import { toast } from "react-toastify";
import React, { useState, useEffect } from "react";
import "./style.scss";
import { useCookies } from "react-cookie";
import { useDispatch } from "react-redux";
import { setNavVisibilty } from "../../../../Redux/AppSlice";

// Prime React Components
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Paginator } from "primereact/paginator";
import { Dialog } from "primereact/dialog";
import { Checkbox } from "primereact/checkbox";
import { Calendar } from "primereact/calendar";
// ------------------------------

// ----------Assets Import------------
import editIcon from "../../../../assets/images/editIcon.jpg";
import {
  ApproveInvoice,
  Dummy,
  DummyPost,
  GetVIPUser,
  GetVIPUserList,
  RegisterVIPUser,
  RejectInvoice,
} from "../../../../Services/VIPLoungeSupervisor";
import { Dropdown } from "primereact/dropdown";
import nationality from "../../../../assets/JSON/Nationality.json";
import { useNavigate } from "react-router-dom";
import axios from "axios";
// -----------------------------------

function UserList() {
  const [cookies, setCookie, removeCookie] = useCookies();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [inValid, setInvalid] = useState(false);
  const navigate = useNavigate();

  // Pagination
  const [first, setFirst] = useState(0);
  const [totalCount, setTotalCount] = useState(0);

  // Action button
  const [isVisible, setIsVisible] = useState(false);
  const [isAdd, setIsAdd] = useState(false);
  const [view, setView] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  // Search && Filter
  const [searchFilterValue, setSearchFilterValue] = useState("");

  // Form Switch
  const [formPage, setFormPage] = useState("one");

  // User List
  const [vipUserList, setVIPUserList] = useState([]);
  console.log(vipUserList);

  // Initial state values
  const userInitialValue = {
    fullName: "",
    nationality: "",
    dateOfBirth: "",
    countryOfResidence: "",
    emailAddress: "",
    mobileNumber: "",
    companyName: "",
    designation: "",
    preferredModeOfContact: "",
    informedOfEvents: "",
    favouriteBrands: "",
    favouriteAttraction: "",
    favouriteRestaurant: "",
    visitFrequency: "",
    visitDays: "",
    visitWithWhom: "",
    participateInPromotions: "",
    interestedInPrizes: "",
    valetParkingFrequency: "",
    porterServiceFrequency: "",
    lockerRoomFrequency: "",
    inMallTaxiFrequency: "",
    otherServicesUsed: "",
    referredBy: "",
  };

  const [userDetails, setUserDetails] = useState({ ...userInitialValue });

  const onPageChange = (event) => {
    setFirst(event.first);
    getVIPUserList(event.first, 10, searchFilterValue);
  };

  const actionButtonTemplate = (row) => {
    // console.log("Row", row);
    return (
      <>
        <div style={{ display: "flex", gap: "15px" }}>
          <div
            onClick={(e) => {
              navigate(`/view-vip-user-details/${row?.vipCode}/${false}`);
              setIsVisible(true);
              setInvalid(false);
              setView(true);
              setIsAdd(false);
              setIsEdit(false);
            }}
          >
            <i
              className="pi pi-eye"
              style={{ fontSize: "1rem", cursor: "pointer" }}
            />
          </div>
          {/* <div onClick={(e) => {
                        getVIPUser(row?.vipCode)
                        setIsVisible(true);
                        setInvalid(false);
                        setIsAdd(false);
                        setIsEdit(true);
                        setView(false);
                    }}>
                        <img src={editIcon} style={{ cursor: 'pointer' }} />
                    </div> */}
        </div>
      </>
    );
  };
  const statusBodyTemplate = (row) => {
    // //console.log(row);
    if (row.status == "Active") {
      return <p style={{ color: "#45AA08" }}>Active</p>;
    } else {
      return <p style={{ color: "#FF4700" }}>Expired</p>;
    }
  };

  const header = () => {
    return (
      <div className="container" style={{ textAlign: "center" }}>
        <h2 className="m-0">
          {" "}
          {isAdd ? "Add" : ""}
          {isEdit ? "Edit" : ""} VIP User Details
        </h2>
      </div>
    );
  };
  const onHandleSearchValueChange = (searchTerm) => {
    //console.log(searchFilterValue);
    getVIPUserList(0, 10, searchTerm);
  };

  // Register new VIP user

  // Get VIP User List
  const getVIPUserList = async (
    skip = 0,
    take = 10,
    searchFilterValue = ""
  ) => {
    setIsLoading(true);
    await GetVIPUserList(skip, take, searchFilterValue, cookies.bearerToken)
      .then((res) => {
        console.log("Response", res);
        if (res?.status === 200) {
          setVIPUserList(res?.data?.data);
          setTotalCount(res?.data?.totalRecords);
        }
      })
      .catch((error) => {
        toast.error(
          "There is issue occured in fetching user, could you please try again",
          { position: "top-right" }
        );
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  // Get details of VIP user by vipCode
  const getVIPUser = async (vipUserCode) => {
    await GetVIPUser(vipUserCode, cookies.bearerToken)
      .then((res) => {
        //console.log("Response", res);
        if (res?.status === 200) {
          setUserDetails(res?.data);
        }
      })
      .catch((error) => {
        toast.error(
          "There is issue occured in fetching user, could you please try again",
          { position: "top-right" }
        );
      });
  };
  useEffect(() => {
    getVIPUserList();
  }, []);

  return (
    <>
      <div className="VIPUserList">
        {/* <Dialog header={header} visible={isVisible} style={{ width: '80vw' }} onHide={() => {
                    setIsVisible(false);
                    setIsAdd(false);
                    setIsEdit(false);
                    setView(false);
                    setUserDetails({ ...userInitialValue });
                }}>
                    {formPage == "one" ? "" : <p className='col text-center p-0'>We would like to know more about you, so that we may serve you even better</p>}
                    <div className="container" style={{ textAlign: "left", display: "flex", gap: "10px" }}>
                        {formPage == "one" ?
                            <div className="form-1" style={{ display: "flex", flexWrap: "wrap", gap: "25px 25px" }}>
                                <div className="input-box">
                                    <p style={{ fontWeight: 400 }} >Name<span style={{ color: 'red' }}>*</span></p>
                                    <InputText placeholder="Name" disabled={view} value={userDetails?.fullName} onChange={(e) => { setUserDetails((prev) => ({ ...prev, fullName: e.target.value })) }} />
                                    {inValid && userDetails?.fullName == "" &&
                                        <p style={{ color: 'red' }}>Name is required</p>
                                    }
                                </div>
                                <div className="input-box">
                                    <p style={{ fontWeight: 400 }}>Nationality<span style={{ color: 'red' }}>*</span></p>
                                    <Dropdown disabled={view} value={userDetails?.nationality} onChange={(e) => setUserDetails((prev) => ({ ...prev, nationality: e.value }))} options={nationality} optionLabel="english_name"
                                        placeholder="Select a nationality" className="w-full md:w-14rem" />
                                    {inValid && userDetails?.nationality == "" &&
                                        <p style={{ color: 'red' }}>Nationality is required</p>
                                    }
                                </div>
                                <div className="input-box">
                                    <p style={{ fontWeight: 400 }}>Date of Birth<span style={{ color: 'red' }}>*</span></p>
                                    <Calendar placeholder='Select DOB' disabled={view} className="calendar" style={{ width: '206px' }} showIcon dateFormat="dd/mm/yy" value={new Date(userDetails?.dateOfBirth)} maxDate={new Date()} onChange={(e) => { setUserDetails((prev) => ({ ...prev, dateOfBirth: e.value })) }} />
                                    {inValid && userDetails?.dateOfBirth == "" &&
                                        <p style={{ color: 'red' }}>Date of Birth is required</p>
                                    }
                                </div>
                                <div className="input-box">
                                    <p style={{ fontWeight: 400 }}>Country of Residence<span style={{ color: 'red' }}>*</span></p>
                                    <Dropdown disabled={view} value={userDetails?.countryOfResidence} onChange={(e) => setUserDetails((prev) => ({ ...prev, countryOfResidence: e.value }))} options={nationality} optionLabel="english_name"
                                        placeholder="Country of Residence" className="w-full md:w-14rem" />
                                    {inValid && userDetails?.countryOfResidence == "" &&
                                        <p style={{ color: 'red' }}>Country of Residence is required</p>
                                    }
                                </div>
                                <div className="input-box">
                                    <p style={{ fontWeight: 400 }}>Email Address<span style={{ color: 'red' }}>*</span></p>
                                    <InputText placeholder="Email Address" disabled={view} value={userDetails?.emailAddress} onChange={(e) => { setUserDetails((prev) => ({ ...prev, emailAddress: e.target.value })) }} />
                                    {inValid && userDetails?.emailAddress == "" &&
                                        <p style={{ color: 'red' }}>Email is required</p>
                                    }
                                </div>
                                <div className="input-box">
                                    <p style={{ fontWeight: 400 }}>Mobile Number<span style={{ color: 'red' }}>*</span></p>
                                    <InputText placeholder="Mobile Number" disabled={view} type='number' min={0} value={userDetails?.mobileNumber} onChange={(e) => { setUserDetails((prev) => ({ ...prev, mobileNumber: e.target.value })) }} />
                                    {inValid && userDetails?.mobileNumber == "" &&
                                        <p style={{ color: 'red' }}>Mobile Number is required</p>
                                    }
                                </div>
                                <div className="input-box">
                                    <p style={{ fontWeight: 400 }}>Company Name<span style={{ color: 'red' }}>*</span></p>
                                    <InputText placeholder="Company Name" disabled={view} value={userDetails?.companyName} onChange={(e) => { setUserDetails((prev) => ({ ...prev, companyName: e.target.value })) }} />
                                    {inValid && userDetails?.companyName == "" &&
                                        <p style={{ color: 'red' }}>Company Name is required</p>
                                    }
                                </div>
                                <div className="input-box">
                                    <p style={{ fontWeight: 400 }}>Designation<span style={{ color: 'red' }}>*</span></p>
                                    <InputText placeholder="Designation" disabled={view} value={userDetails?.designation} onChange={(e) => { setUserDetails((prev) => ({ ...prev, designation: e.target.value })) }} />
                                    {inValid && userDetails?.designation == "" &&
                                        <p style={{ color: 'red' }}>Designation is required</p>
                                    }
                                </div>
                                
                                <div className="input-box">
                                    <p style={{ fontWeight: 400 }}>Prefer Mode of Contact<span style={{ color: 'red' }}>*</span></p>
                                    <InputText placeholder="Prefer Mode of Contact" disabled={view} value={userDetails?.preferredModeOfContact} onChange={(e) => { setUserDetails((prev) => ({ ...prev, preferredModeOfContact: e.target.value })) }} />
                                    {inValid && userDetails?.preferredModeOfContact == "" &&
                                        <p style={{ color: 'red' }}>Prefer Mode of Contact is required</p>
                                    }
                                </div>
                                <div className="input-box">
                                    <p style={{ fontWeight: 400 }}>Would you like to be informed of the Marassi Galleria Mall events and other information<span style={{ color: 'red' }}>*</span></p>
                                    <div className="flex flex-wrap justify-content-start gap-3">
                                        <div className="flex align-items-center">
                                            <Checkbox inputId="ingredient1" disabled={view} name="pizza" value="Yes" onChange={(e) => { setUserDetails((prev) => ({ ...prev, informedOfEvents: e.target.value })) }} checked={(userDetails?.informedOfEvents == "Yes" || userDetails?.informedOfEvents == true) ? true : false} />
                                            <label htmlFor="ingredient1" className="ml-2">Yes</label>
                                        </div>
                                        <div className="flex align-items-center">
                                            <Checkbox inputId="ingredient2" disabled={view} name="pizza" value="No" onChange={(e) => { setUserDetails((prev) => ({ ...prev, informedOfEvents: e.target.value })) }} checked={(userDetails?.informedOfEvents == "No" || userDetails?.informedOfEvents == false) ? true : false} />
                                            <label htmlFor="ingredient2" className="ml-2">No</label>
                                        </div>
                                    </div>
                                    {inValid && userDetails?.informedOfEvents == "" &&
                                        <p style={{ color: 'red' }}>Would you like to be informed of the Marassi Galleria Mall events and other information is required</p>
                                    }
                                </div>
                            </div>
                            : ""}
                        {formPage == "two" ?
                            <div className="form-2 col-12" style={{ display: "flex", flexWrap: "wrap", gap: "25px 40px" }}>
                                <div className="input-box col-6 p-0" style={{ width: "45%" }}>
                                    <p style={{ fontWeight: 400 }}>1.What is the favourite brand(s) at the Marassi Galleria Mall?<span style={{ color: 'red' }}>*</span></p>
                                    <InputText style={{ width: "70%" }} placeholder="Name" disabled={view} value={userDetails?.favouriteBrands} onChange={(e) => { setUserDetails((prev) => ({ ...prev, favouriteBrands: e.target.value })) }} />
                                    {inValid && userDetails?.favouriteBrands == "" &&
                                        <p style={{ color: 'red' }}>Favourite Brand is required</p>
                                    }
                                </div>
                                <div className="input-box col-6 p-0" style={{ width: "45%" }}>
                                    <p style={{ fontWeight: 400 }}>2.What is the favourite attraction at the Marassi Galleria Mall?<span style={{ color: 'red' }}>*</span></p>
                                    <InputText style={{ width: "70%" }} placeholder="Name" disabled={view} value={userDetails?.favouriteAttraction} onChange={(e) => { setUserDetails((prev) => ({ ...prev, favouriteAttraction: e.target.value })) }} />
                                    {inValid && userDetails?.favouriteBrands == "" &&
                                        <p style={{ color: 'red' }}>Favourite Brand is required</p>
                                    }
                                </div>
                                <div className="input-box col-6 p-0" style={{ width: "45%" }}>
                                    <p style={{ fontWeight: 400 }}>3.What is the restraurant/cafe at the Marassi Galleria Mall?<span style={{ color: 'red' }}>*</span></p>
                                    <InputText style={{ width: "70%" }} placeholder="Name" disabled={view} value={userDetails?.favouriteRestaurant} onChange={(e) => { setUserDetails((prev) => ({ ...prev, favouriteRestaurant: e.target.value })) }} />
                                    {inValid && userDetails?.favouriteRestaurant == "" &&
                                        <p style={{ color: 'red' }}>Restaurant/Cafe is required</p>
                                    }
                                </div>
                                <div className="input-box col-6 p-0" style={{ width: "45%" }}>
                                    <p style={{ fontWeight: 400 }}>4.How often you visit the Marassi Galleria Mall?<span style={{ color: 'red' }}>*</span></p>
                                    <div className="flex flex-wrap justify-content-start gap-3">
                                        <div className="flex align-items-center">
                                            <Checkbox inputId="ingredient1" disabled={view} name="daily" value="Daily" onChange={(e) => { setUserDetails((prev) => ({ ...prev, visitFrequency: e.target.value })) }} checked={userDetails?.visitFrequency == "Daily" ? true : false} />
                                            <label htmlFor="ingredient1" className="ml-2">Daily</label>
                                        </div>
                                        <div className="flex align-items-center">
                                            <Checkbox inputId="ingredient2" disabled={view} name="weekly" value="Weekly" onChange={(e) => { setUserDetails((prev) => ({ ...prev, visitFrequency: e.target.value })) }} checked={userDetails?.visitFrequency == "Weekly" ? true : false} />
                                            <label htmlFor="ingredient2" className="ml-2">Weekly</label>
                                        </div>
                                        <div className="flex align-items-center">
                                            <Checkbox inputId="ingredient2" disabled={view} name="monthly" value="Monthly" onChange={(e) => { setUserDetails((prev) => ({ ...prev, visitFrequency: e.target.value })) }} checked={userDetails?.visitFrequency == "Monthly" ? true : false} />
                                            <label htmlFor="ingredient2" className="ml-2">Monthly</label>
                                        </div>
                                        <div className="flex align-items-center">
                                            <Checkbox inputId="ingredient2" disabled={view} name="others" value="Others" onChange={(e) => { setUserDetails((prev) => ({ ...prev, visitFrequency: e.target.value })) }} checked={userDetails?.visitFrequency == "Others" ? true : false} />
                                            <label htmlFor="ingredient2" className="ml-2">Others(Please Specify)</label>
                                        </div>
                                    </div>
                                    {userDetails?.visitFrequency == "Others" &&
                                        <InputText className="mt-2" placeholder="Name" disabled={view} value={userDetails?.visitFrequency} onChange={(e) => { setUserDetails((prev) => ({ ...prev, visitFrequency: e.target.value })) }} />
                                    }
                                    {inValid && userDetails?.visitFrequency == "" &&
                                        <p style={{ color: 'red' }}>Visit Frequency is required</p>
                                    }
                                </div>
                                <div className="input-box col-6 p-0" style={{ width: "45%" }}>
                                    <p style={{ fontWeight: 400 }}>5.What days of the week do you visit Marassi Galleria Mall?<span style={{ color: 'red' }}>*</span></p>
                                    <InputText style={{ width: "70%" }} placeholder="Name" disabled={view} value={userDetails?.visitDays} onChange={(e) => { setUserDetails((prev) => ({ ...prev, visitDays: e.target.value })) }} />
                                    {inValid && userDetails?.visitDays == "" &&
                                        <p style={{ color: 'red' }}>Visit Days is required</p>
                                    }
                                </div>
                                <div className="input-box col-6 p-0" style={{ width: "45%" }}>
                                    <p style={{ fontWeight: 400 }}>6.Who do you visit the Marassi Galleria mall with?<span style={{ color: 'red' }}>*</span></p>
                                    <div className="flex flex-wrap justify-content-start gap-3">
                                        <div className="flex align-items-center">
                                            <Checkbox inputId="ingredient1" disabled={view} name="pizza" value="Family" onChange={(e) => { setUserDetails((prev) => ({ ...prev, visitWithWhom: e.target.value })) }} checked={userDetails?.visitWithWhom == "Family" ? true : false} />
                                            <label htmlFor="ingredient1" className="ml-2">Family</label>
                                        </div>
                                        <div className="flex align-items-center">
                                            <Checkbox inputId="ingredient2" disabled={view} name="pizza" value="Friends" onChange={(e) => { setUserDetails((prev) => ({ ...prev, visitWithWhom: e.target.value })) }} checked={userDetails?.visitWithWhom == "Friends" ? true : false} />
                                            <label htmlFor="ingredient2" className="ml-2">Friends</label>
                                        </div>
                                        <div className="flex align-items-center">
                                            <Checkbox inputId="ingredient2" disabled={view} name="pizza" value="Alone" onChange={(e) => { setUserDetails((prev) => ({ ...prev, visitWithWhom: e.target.value })) }} checked={userDetails?.visitWithWhom == "Alone" ? true : false} />
                                            <label htmlFor="ingredient2" className="ml-2">Alone</label>
                                        </div>
                                        <div className="flex align-items-center">
                                            <Checkbox inputId="ingredient2" disabled={view} name="pizza" value="Other" onChange={(e) => { setUserDetails((prev) => ({ ...prev, visitWithWhom: e.target.value })) }} checked={userDetails?.visitWithWhom == "Other" ? true : false} />
                                            <label htmlFor="ingredient2" className="ml-2">Other(Please Specify)</label>
                                        </div>
                                    </div>
                                    {userDetails?.visitWithWhom == "Others" &&
                                        <InputText placeholder="Name" disabled={view} value={userDetails?.visitWithWhom} onChange={(e) => { setUserDetails((prev) => ({ ...prev, visitWithWhom: e.target.value })) }} />
                                    }
                                    {inValid && userDetails?.visitWithWhom == "" &&
                                        <p style={{ color: 'red' }}>Who do you visit the Marassi Galleria mall with is required</p>
                                    }
                                </div>
                                <div className="input-box col-6 p-0" style={{ width: "45%" }}>
                                    <p style={{ fontWeight: 400 }}>7.Do you participate in the Marassi Galleri Mall's Spend and Win promotions?<span style={{ color: 'red' }}>*</span></p>
                                    <div className="flex flex-wrap justify-content-start gap-3">
                                        <div className="flex align-items-center">
                                            <Checkbox inputId="ingredient1" disabled={view} name="pizza" value="Yes" onChange={() => {
                                                setUserDetails((prev) => ({ ...prev, participateInPromotions: "Yes" }));
                                            }}
                                                checked={userDetails?.participateInPromotions == "Yes" || userDetails?.participateInPromotions == true}
                                            />
                                            <label htmlFor="ingredient1" className="ml-2">Yes</label>
                                        </div>
                                        <div className="flex align-items-center">
                                            <Checkbox inputId="ingredient2" disabled={view} name="pizza" value="No" onChange={() => {
                                                setUserDetails((prev) => ({ ...prev, participateInPromotions: "No" }));
                                            }}
                                                checked={userDetails?.participateInPromotions == "No" || userDetails?.participateInPromotions == false}
                                            />
                                            <label htmlFor="ingredient2" className="ml-2">No</label>
                                        </div>
                                    </div>
                                    {inValid && userDetails?.participateInPromotions == "" &&
                                        <p style={{ color: 'red' }}>Participate in Promotions is required</p>
                                    }
                                </div>
                                <div className="input-box col-6 p-0" style={{ width: "45%" }}>
                                    <p style={{ fontWeight: 400 }}>8.What prices would attract you to participate in a promotion?<span style={{ color: 'red' }}>*</span></p>
                                    <InputText style={{ width: "70%" }} placeholder="Name" disabled={view} value={userDetails?.interestedInPrizes} onChange={(e) => { setUserDetails((prev) => ({ ...prev, interestedInPrizes: e.target.value })) }} />
                                    {inValid && userDetails?.interestedInPrizes == "" &&
                                        <p style={{ color: 'red' }}>Interested In Prizes is required</p>
                                    }
                                </div>
                                <div className="input-box col-6 p-0" style={{ width: "45%" }}>
                                    <p style={{ fontWeight: 400 }}>9.Amoung the following services available at the Marassi Galleri Mall, which do you use, and how ?<span style={{ color: 'red' }}>*</span></p>
                                    <div className="flex flex-wrap  gap-3">
                                        <p style={{ fontWeight: 400, width: "30%", margin: "8px 0" }}>a.Valet Parking<span style={{ color: 'red' }}>*</span></p>
                                        <div className="flex align-items-center">
                                            <Checkbox inputId="ingredient1" disabled={view} name="pizza" value="Often" onChange={(e) => { setUserDetails((prev) => ({ ...prev, valetParkingFrequency: e.target.value })) }} checked={userDetails?.valetParkingFrequency == "Often" ? true : false} />
                                            <label htmlFor="ingredient1" className="ml-2">Often</label>
                                        </div>
                                        <div className="flex align-items-center">
                                            <Checkbox inputId="ingredient2" disabled={view} name="pizza" value="Sometimes" onChange={(e) => { setUserDetails((prev) => ({ ...prev, valetParkingFrequency: e.target.value })) }} checked={userDetails?.valetParkingFrequency == "Sometimes" ? true : false} />
                                            <label htmlFor="ingredient2" className="ml-2">Sometimes</label>
                                        </div>
                                        <div className="flex align-items-center">
                                            <Checkbox inputId="ingredient2" disabled={view} name="pizza" value="Never" onChange={(e) => { setUserDetails((prev) => ({ ...prev, valetParkingFrequency: e.target.value })) }} checked={userDetails?.valetParkingFrequency == "Never" ? true : false} />
                                            <label htmlFor="ingredient2" className="ml-2">Never</label>
                                        </div>
                                    </div>
                                    <div className="flex flex-wrap  gap-3">
                                        <p style={{ fontWeight: 400, width: "30%", margin: "8px 0" }}>b.Porter Service <span style={{ color: 'red' }}>*</span></p>
                                        <div className="flex align-items-center">
                                            <Checkbox inputId="ingredient1" disabled={view} name="pizza" value="Often" onChange={(e) => { setUserDetails((prev) => ({ ...prev, porterServiceFrequency: e.target.value })) }} checked={userDetails?.porterServiceFrequency == "Often" ? true : false} />
                                            <label htmlFor="ingredient1" className="ml-2">Often</label>
                                        </div>
                                        <div className="flex align-items-center">
                                            <Checkbox inputId="ingredient2" disabled={view} name="pizza" value="Sometimes" onChange={(e) => { setUserDetails((prev) => ({ ...prev, porterServiceFrequency: e.target.value })) }} checked={userDetails?.porterServiceFrequency == "Sometimes" ? true : false} />
                                            <label htmlFor="ingredient2" className="ml-2">Sometimes</label>
                                        </div>
                                        <div className="flex align-items-center">
                                            <Checkbox inputId="ingredient2" disabled={view} name="pizza" value="Never" onChange={(e) => { setUserDetails((prev) => ({ ...prev, porterServiceFrequency: e.target.value })) }} checked={userDetails?.porterServiceFrequency == "Never" ? true : false} />
                                            <label htmlFor="ingredient2" className="ml-2">Never</label>
                                        </div>
                                    </div>
                                    <div className="flex flex-wrap  gap-3">
                                        <p style={{ fontWeight: 400, width: "30%", margin: "8px 0" }}>c.Locker Room<span style={{ color: 'red' }}>*</span></p>
                                        <div className="flex align-items-center">
                                            <Checkbox inputId="ingredient1" disabled={view} name="pizza" value="Often" onChange={(e) => { setUserDetails((prev) => ({ ...prev, lockerRoomFrequency: e.target.value })) }} checked={userDetails?.lockerRoomFrequency == "Often" ? true : false} />
                                            <label htmlFor="ingredient1" className="ml-2">Often</label>
                                        </div>
                                        <div className="flex align-items-center">
                                            <Checkbox inputId="ingredient2" disabled={view} name="pizza" value="Sometimes" onChange={(e) => { setUserDetails((prev) => ({ ...prev, lockerRoomFrequency: e.target.value })) }} checked={userDetails?.lockerRoomFrequency == "Sometimes" ? true : false} />
                                            <label htmlFor="ingredient2" className="ml-2">Sometimes</label>
                                        </div>
                                        <div className="flex align-items-center">
                                            <Checkbox inputId="ingredient2" disabled={view} name="pizza" value="Never" onChange={(e) => { setUserDetails((prev) => ({ ...prev, lockerRoomFrequency: e.target.value })) }} checked={userDetails?.lockerRoomFrequency == "Never" ? true : false} />
                                            <label htmlFor="ingredient2" className="ml-2">Never</label>
                                        </div>
                                    </div>
                                    <div className="flex flex-wrap  gap-3">
                                        <p style={{ fontWeight: 400, width: "30%", margin: "8px 0" }}>d.In-Mall Taxi<span style={{ color: 'red' }}>*</span></p>
                                        <div className="flex align-items-center">
                                            <Checkbox inputId="ingredient1" disabled={view} name="pizza" value="Often" onChange={(e) => { setUserDetails((prev) => ({ ...prev, inMallTaxiFrequency: e.target.value })) }} checked={userDetails?.inMallTaxiFrequency == "Often" ? true : false} />
                                            <label htmlFor="ingredient1" className="ml-2">Often</label>
                                        </div>
                                        <div className="flex align-items-center">
                                            <Checkbox inputId="ingredient2" disabled={view} name="pizza" value="Sometimes" onChange={(e) => { setUserDetails((prev) => ({ ...prev, inMallTaxiFrequency: e.target.value })) }} checked={userDetails?.inMallTaxiFrequency == "Sometimes" ? true : false} />
                                            <label htmlFor="ingredient2" className="ml-2">Sometimes</label>
                                        </div>
                                        <div className="flex align-items-center">
                                            <Checkbox inputId="ingredient2" disabled={view} name="pizza" value="Never" onChange={(e) => { setUserDetails((prev) => ({ ...prev, inMallTaxiFrequency: e.target.value })) }} checked={userDetails?.inMallTaxiFrequency == "Never" ? true : false} />
                                            <label htmlFor="ingredient2" className="ml-2">Never</label>
                                        </div>
                                    </div>
                                    <div className="flex flex-wrap  gap-3">
                                        <p style={{ fontWeight: 400, width: "50%", margin: "8px 0" }}>e.Others(Please Specify) <span style={{ color: 'red' }}>*</span></p>
                                        <InputText className='mt-1' placeholder="Name" disabled={view} value={userDetails?.otherServicesUsed} onChange={(e) => { setUserDetails((prev) => ({ ...prev, otherServicesUsed: e.target.value })) }} />

                                    </div>
                                </div>
                            </div>
                            : ""}
                    </div>
                    <div className="buttons">
                        <div className="flex justify-content-center gap-3">
                            {formPage == "two" ?
                                <>
                                    <Button label="Previous" style={{ backgroundColor: '#ae9d81', border: "none", margin: "10px", width: "105px" }} onClick={() => { setFormPage('one') }} />
                                    <Button label="Cancel" style={{ backgroundColor: '#adadad', border: "none", margin: "10px", width: "105px" }} onClick={() => {
                                        setInvalid(false);
                                        setIsVisible(false);
                                        setIsAdd(false);
                                        setIsEdit(false);
                                        setView(false);
                                        setUserDetails({ ...userInitialValue });
                                    }} />
                                    <Button label="Submit" disabled={view} style={{ backgroundColor: '#ae9d81', border: "none", margin: "10px", width: "105px" }} onClick={registerUser} />
                                </>
                                : ""}
                            {formPage == "one" ?
                                <>
                                    <Button label="Cancel" style={{ backgroundColor: '#adadad', border: "none", margin: "10px", width: "105px" }} onClick={() => {
                                        setInvalid(false);
                                        setIsVisible(false);
                                        setIsAdd(false);
                                        setIsEdit(false);
                                        setView(false);
                                        setUserDetails({ ...userInitialValue });
                                    }} />
                                    <Button label="Next" style={{ backgroundColor: '#ae9d81', border: "none", margin: "10px", width: "105px" }} onClick={() => { setFormPage("two") }} />
                                </>
                                : ""}
                        </div>
                    </div>
                </Dialog > */}
        <div className="vip-header">
          <div className="menubtn px-4">
            <i
              className="pi pi-align-justify"
              style={{ fontSize: "1rem", color: "#ae9d81", cursor: "pointer" }}
              onClick={() =>
                dispatch(setNavVisibilty({ sideNavVisibility: true }))
              }
            ></i>
            <div className="heading">
              <p>VIP User Details</p>
            </div>
          </div>
         <div className="flex">
             <div>
                 <h4 className='mb-1 mt-0 relative' style={{color:"#ae9d81"}}>Search</h4>
                 <span className="p-input-icon-left p-input-icon-right">
                    <i
                      className="pi pi-search"
                      style={{
                        color: "black",
                        fontWeight: "700",
                        fontSize: "1rem",
                      }}
                    />
                    <InputText
                      placeholder="Search"
                      value={searchFilterValue}
                      onChange={(e) => {
                        setSearchFilterValue(e.target.value);
                        onHandleSearchValueChange(e.target.value);
                      }}
                      style={{padding:"0 1rem"}}
                    />
                    {searchFilterValue !== "" && (
                      <i
                        className="pi pi-times"
                        style={{
                          color: "black",
                          fontWeight: "400",
                          fontSize: "1rem",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setSearchFilterValue("");
                          onHandleSearchValueChange("");
                        }}
                      />
                    )}
                  </span>

             </div>
           <div className="mx-2 align-self-end">
              <Button
                  label="Add New"
                  onClick={() => {
                    navigate("/vip-user-registration");
                    setInvalid(false);
                    setIsVisible(true);
                    setIsAdd(true);
                    setIsEdit(false);
                    setView(false);
                    setFormPage("one");
                  }}
                  style={{
                    backgroundColor: "#ae9d81",
                    border: "none",
                  }}
                />
           </div>
          
          </div>

        </div>
        <div className="datatable">
          {isLoading ? (
            <div
              className="spinner spinner-center"
              style={{ display: "flex", margin: "auto", marginTop: "200px" }}
            ></div>
          ) : (
            <DataTable
              value={vipUserList}
              style={{ boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)" }}
              tableStyle={{ width: "90rem", margin: "10px 39px" }}
              stripedRows
              rows={10}
              emptyMessage={
                <>
                  <p style={{ textAlign: "center" }}>List is empty</p>
                </>
              }
            >
              <Column field="vipCode" header="VIP Code"></Column>
              <Column field="fullName" header="Name"></Column>
              {/* <Column field="companyName" header="Company"></Column> */}
              {/* <Column field="designation" header="Designation"></Column> */}
              <Column field="dateOfRegistration" header="Created Date"></Column>
              {/* <Column
                field="status"
                header="Status"
                body={statusBodyTemplate}
              ></Column> */}
              <Column
                field="nationality"
                header="Action"
                body={actionButtonTemplate}
              ></Column>
            </DataTable>
          )}
        </div>
        {vipUserList.length === 0 || isLoading ? (
          ""
        ) : (
          <div style={{ justifyContent: "center", display: "flex" }}>
            <Paginator
              first={first}
              rows={10}
              totalRecords={totalCount}
              onPageChange={(e) => onPageChange(e, 1)}
              template={{
                layout:
                  "RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink  LastPageLink CurrentPageReport",
              }}
              className="justify-content-start"
            />
          </div>
        )}
      </div>
    </>
  );
}

export default UserList;
