import React, { useState, useEffect } from "react";
import { useCookies } from "react-cookie";
import "./style.scss";
// -------------Imports---------------
import { FilterMatchMode } from "primereact/api";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Tag } from "primereact/tag";
import { Paginator } from "primereact/paginator";
import { GetTenantsListForMGTenantRelationship } from "../../Services/MGTenentRelationshipService";
// -----------------------------------

// ----------Assets Import------------
import editIcon from "../../../src/assets/images/editIcon.jpg";
// -----------------------------------

//-----------Context ---------------
import { useDispatch, useSelector } from "react-redux";
import { addOrganizationList, setNavVisibilty } from "../../Redux/AppSlice";
import { Link } from "react-router-dom";
import { exportDataInExcel } from "../../Services/ExportServices";

//----------------------------------------

function MGTenentRelationship() {
  const Context = useSelector((state) => state.AppContext);
  const dispatch = useDispatch();
  const [cookies, setCookie, removeCookie] = useCookies();
  const [tenentList, setTenentList] = useState();
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    // name: { value: null, matchMode: FilterMatchMode.CONTAINS }
  });
  // Pagination
  const [first, setFirst] = useState(0);
  const [totalCount, setTotalRecords] = useState(0);

  const onGlobalFilterChange = (e) => {
    const value = e ? e.target.value : "";
    let _filters = { ...filters };
    _filters["global"].value = value;
    setFilters(_filters);
    setGlobalFilterValue(value);
    getTenentList(0, 10, value);
  };
  // const statusBodyTemplate = ((row) => {
  //     // //console.log(row);
  //     if (row.status === "active") {
  //         return <p style={{ color: "green" }}  >Active</p>
  //     }
  //     else {
  //         return <p style={{ color: "red" }} >Inactive</p>
  //     }
  // });
  // const actionButtonTemplate = (row) => {
  //     return (
  //         <div onClick={(e) => {

  //         }}>
  //             <img src={editIcon} style={{cursor:'pointer'}} />
  //         </div>
  //     );
  // };

  const getTenentList = (skip, take, searchTerm = "") => {
    setIsLoading(true);
    GetTenantsListForMGTenantRelationship(
      take,
      skip,
      cookies.bearerToken,
      searchTerm
    )
      .then((res) => {
        //console.log(res);
        if (res?.status === 200) {
          setTenentList(res?.data?.data);
          setTotalRecords(res?.data?.count);
        }
      })
      .catch((error) => {
        console.warn(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const actionButtonTemplate = (row) => {
    //console.log({ row })
    return (
      <Link to={`/mgtenentrelationshipview/${row?.tenantInspectionId}`}>
        <img src={editIcon} style={{ cursor: "pointer" }} />
      </Link>
    );
  };

  const statusBodyTemplate = (row) => {
    if (row.status === "Open") {
      return <p style={{ color: "#fa6334", width: "90px" }}>Open</p>;
    } else if (row.status === "Inprogress") {
      return <p style={{ color: "#578bfa", width: "90px" }}>In-Progress</p>;
    } else if (row.status === "Pending") {
      return <p style={{ color: "#faf066", width: "90px" }}>Pending</p>;
    } else if (row.status === "NoResponse") {
      return <p style={{ color: "brown", width: "90px" }}>No Response</p>;
    } else if (row.status === "Closed") {
      return <p style={{ color: "#41fa53", width: "90px" }}>Closed</p>;
    } else {
      return <p style={{ color: "#184CD1", width: "90px" }}>New</p>;
    }
  };

  const onPageChange = (e, index) => {
    setFirst(e.first);
    getTenentList(e.first, 10);
    //console.log(e);
  };
  const exportMGTenantInfo = () => {
    if (globalFilterValue) {
      exportDataInExcel(
        `TenantInspectionListForMGTenantRelationship?searchTerm=${globalFilterValue}`,
        cookies.bearerToken
      );
      return;
    }
    exportDataInExcel(
      "TenantInspectionListForMGTenantRelationship",
      cookies.bearerToken
    );
  };

  useEffect(() => {
    getTenentList(0, 10);
  }, []);

  return (
    <>
      <div className="roleMaster">
        <div className="header">
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              flexGrow: "3",
              justifyContent: "flex-start",
            }}
            className="flex align-items-center org"
          >
            <div className="menubtn" style={{ padding: "10px 10px 5px 20px" }}>
              <i
                className="pi pi-align-justify"
                style={{
                  fontSize: "1rem",
                  color: "#ae9d81",
                  cursor: "pointer",
                }}
                onClick={() =>
                  dispatch(setNavVisibilty({ sideNavVisibility: true }))
                }
              ></i>
            </div>

            <div className="heading">
              <p>Inspect Tenant</p>
            </div>
          </div>
        <div className="flex align-items-center">
                {/* <div>
                        <Button label='Import' icon={"pi pi-file-import"} style={{ backgroundColor: 'transparent', color: '#ae9d81', border: '1px #ae9d81 solid', borderRadius: '20px', marginRight: '20px' }} />
                    </div> */}
             <div className="mx-2">
                 <h4 className='mb-1 mt-0' style={{color:"#ae9d81"}}>Search</h4>
                 <div className='flex align-items-center' style={{border:"solid 1px #AE9D81",borderRadius:"4px"}}>
                      <i className="pi pi-search px-2" style={{ color: 'black', fontWeight: '700', fontSize: '1rem' }} />
                      <InputText value={globalFilterValue}
                        style={{width:"170px"}}
                        onChange={onGlobalFilterChange}
                        placeholder="Search" className='border-0 customeSearch'/>
                      <i className="pi pi-times px-2" style={{ color: 'black', fontWeight: '400', fontSize: '1rem', cursor: 'pointer',position:"block" }}  onClick={() => { 
                        setGlobalFilterValue("");
                        onGlobalFilterChange();
                        }} />
                </div>
                 {/* <div className="search">
                    <span className="p-input-icon-left">
                      <i
                        className="pi pi-search"
                        style={{
                          color: "black",
                          fontWeight: "700",
                          fontSize: "1rem",
                        }}
                      />
                      <InputText
                        placeholder="Search"
                        value={globalFilterValue}
                        onChange={onGlobalFilterChange}
                      />
                      <i
                        className="pi pi-times"
                        style={{ color: "black", fontWeight: "400" }}
                        onClick={() => {
                          setGlobalFilterValue("");
                          onGlobalFilterChange();
                        }}
                      />
                    </span>
                  </div> */}
             </div>       
             <div className="mx-2 align-self-end">
                  <Button
                    label="Export"
                    onClick={totalCount > 0 ? exportMGTenantInfo : null}
                    icon={"pi pi-file-export"}
                    style={{
                      backgroundColor: "transparent",
                      color: "#ae9d81",
                      border: "1px #ae9d81 solid",
                      borderRadius: "20px",
                      height:"46px",
                      cursor: totalCount > 0 ? "pointer" : "not-allowed",
                    }}
                  />
            </div>
              <div className="addbtn">
                {/* <Button label="Add New" onClick={() => { setIsAddOrganization(true) }} style={{ backgroundColor: '#ae9d81' }} /> */}
              </div>
            </div>
        </div>
         
          

        <div className="datatable">
          {isLoading ? (
            <div className="spinner spinner-center"></div>
          ) : (
            <DataTable
              value={tenentList}
              tableStyle={{ width: "90rem" }}
              style={{ boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)" }}
              stripedRows
              rows={10}
              emptyMessage={
                <>
                  <p style={{ textAlign: "center" }}>
                    Tenant inspection list is empty
                  </p>
                </>
              }
            >
              <Column field="code" header="Inspection Id"></Column>
              <Column field="inspectorName" header="Inspector Name"></Column>
              <Column field="tenantName" header="Tenant Name"></Column>
              <Column field="date" header="Created Date"></Column>
              <Column
                field="changedBy"
                header="Changed By"
                body={(row) => (row.changedBy ? row.changedBy : "-")}
              ></Column>
              <Column
                field="status"
                header="Status"
                body={statusBodyTemplate}
              ></Column>
              <Column header="Edit" body={actionButtonTemplate}></Column>
            </DataTable>
          )}
        </div>
        {isLoading || tenentList.length == 0 ? (
          ""
        ) : (
          <div style={{ justifyContent: "center", display: "flex" }}>
            <Paginator
              first={first}
              rows={10}
              totalRecords={totalCount}
              onPageChange={(e) => onPageChange(e, 1)}
              template={{
                layout:
                  "RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink  LastPageLink CurrentPageReport",
              }}
              className="justify-content-start"
            />
          </div>
        )}
      </div>

      <div>{/* {isAddOrganization == true ? popup() : ""} */}</div>
    </>
  );
}


export default MGTenentRelationship;

