import Axios from '../Helpers/Axios';
import axios from 'axios';

export function GetFeedbackList(data, token,categoryId,subCategoryId,orgId,fromDate,toDate) {
    return Axios(`Feedback/List?categoryId=${categoryId||"0"}&subCategoryId=${subCategoryId||"0"}&orgId=${orgId||"0"}&fromDate=${fromDate||""}&toDate=${toDate||""}&forExport=false`, 'post', data, token)
}
export function GetChatList(token, feedbackId) {
    return Axios('Feedback/ChatList?feedbackId=' + feedbackId, 'get', null, token)
}
export function GetFeedbackById(token, feedbackId) {
    return Axios('Feedback/GetFeedback?feedbackId=' + feedbackId, 'get', null, token)
}
export function NewChat(data, token) {
    return Axios('Feedback/NewChat', 'post', data, token)
}
export function GuestStaffSupervisorFeedbacks({ skip, take }, token) {
    return Axios(`GuestStaffSupervisor/GetFeedbacks?skip=${skip}&take=${take}`, 'get', null, token)
}
export function GuestStaffSupervisorSearchedFeedbacks({ skip, take, searchValue }, token) {
    return Axios(`GuestStaffSupervisor/GetFeedbacks?skip=${skip}&take=${take}&searchTerm=${searchValue}`, 'get', null, token)
}
export function GuestStaffSupervisorEditFeedbacks({ feedbackId, statusId }, token) {
    return Axios(`GuestStaffSupervisor/EditFeedback?feedbackId=${feedbackId}&statusId=${statusId}`, 'PATCH', null, token)
}



// Feedback Form
export function feedBackSubmission(data, token) {
    return Axios('Feedback/Add', 'post', data, token)
}
export const getCategories = async (token) => {
    return await Axios('FeedbackCategory/GetCategories', "get", null, token)
}
export const getSubCategories = async (token) => {
    return await Axios(`FeedbackSubCategory/GetSubCategories?showAll=true`, "get", null, token)
}
export const uploadFiles = async (data, token) => {
    return Axios('File/UploadBase64FileAnonymous', "post", data, token)
}
export const getNationality = async () => {
    return await axios.get("https://restcountries.com/v3.1/all?fields=name,capital,currencies").then(res => {
        if (res?.status == 200) {
            return { nationalityList: res?.data, status: "200" };
        }
    }).catch(error => console.log(error))
}