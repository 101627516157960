import Axios from '../Helpers/Axios';


export function GetTenantInspectionDetail(id, token) {
    return Axios(`TenantInspection/GetTenantInspectionDetail?inspectionId=${id}`, 'get', null, token)
}

export function GetStatusList(token) {
    return Axios(`TenantInspection/GetTenantInspectionStatus`, 'get', null, token)
}
export function UpdateStatus(tenantInspectionId, tenantInspectionStatusId, message, token) {
    return Axios(`TenantInspection/EditTenantInspectionByMGTenantUser?tenantInspectionId=${tenantInspectionId}&tenantInspectionStatusId=${tenantInspectionStatusId}&message=${message}`, 'patch', null, token)
}

export function GetChatList(id, token) {
    return Axios(`TenantInspection/GetTenantInspectionChats?tenantInspectionId=${id}`, 'get', null, token)
}

///TenantInspection/GetTenantInspectionDetail?inspectionId=25