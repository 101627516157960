import Axios from "../Helpers/Axios";


export function GetDepartmentCategoryConfigurationList(skip, take, token, searchValue,depId,status,showAll) {
    return Axios(`CategoryConfiguration/ShowCategoryConfigurations?skip=${skip}&take=${take}&searchTerm=${searchValue}&departmentId=${depId||"0"}${status?`&status=${status||""}&showAll=${showAll}`:""}&forExport=false`, 'get', null, token)
    
    
}
export function AddDepartmentCategoryConfiguration(data, token) {
    return Axios(`CategoryConfiguration/AddConfiguration`, 'post', data, token)
}
export function EditDepartmentCategoryConfiguration(data, token) {
    return Axios(`CategoryConfiguration/EditConfiguration`, 'patch', data, token)
}