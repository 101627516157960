import React, { useEffect, useRef } from "react";
import "./GuestLogin.scss";
import { useState } from "react";
// import logoCover from "../../assets/images/LoginCover.jpg";
import logo from "../../../assets/images/logo.jpg";
// import logo from '../../assets/images/ehd_logo2.png';

import { useNavigate, useLocation, useSearchParams } from "react-router-dom";
import { useCookies } from "react-cookie";
import { InputOtp } from "primereact/inputotp";

// import DropdownComponent from '../utilize/DropdownComponent';

import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import {
  SendLoginOtp,
  ValidateOtp,
} from "../../../Services/ShopAndDropService";

import { Dropdown } from "primereact/dropdown";

//Context
//-----------Context ---------------
import { toast } from "react-toastify";
import IntlTelInput from "react-intl-tel-input";
import CountryPicker from "../../CountryPicker/CountryPicker";
//----------------------------------------

function GuestLogin() {
  const [cookies, setCookie, removeCookie, updateCookie] = useCookies();

  // let data = //guestserviceapi.azurewebsites.net/guest-login/checkInDetailId=GD0RCprUvwGkoQqKXNZdeA==&orgId=eZwpdna4lvHTxR4BtYVFJw==

  const [loginData, setLoginData] = useState({
    mobileNumber: "",
    emailId: "",
    otp: "",
    countryCode: "+973",
  });
  const inputRef = useRef(null);
  const localizationValue = "en";
  const [isValid, setIsValid] = useState(false);
  const [invalidUser, setInvalidUser] = useState(false);
  const [logInLoading, setLogInLoading] = useState(false);
  const [isLogin, setIsLogin] = useState(true);
  const [isErrMsgView, setIsErrMsgView] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const [guestUserId, setGuestUserId] = useState(null);

  // const [params] = URLSearchParams();
  let checkInDetailId = window.location.search.split("checkInDetailId=")[1].split("&")[0];
  let orgId = window.location.search.split("checkInDetailId=")[1].split("&")[1].split("orgId=")[1];


  const navigate = useNavigate();

  const emailValidation = () => {
    var emailFormat = /^([^\s@]+)@([A-Za-z])+\.([A-Za-z])+$/;
    let isValidEmail = emailFormat.test(loginData?.emailId);
    return isValidEmail;
  };

  const handleSendOtp = async () => {
    let isValidation = loginData?.mobileNumber && true;
    setIsErrMsgView(!isValidation);
    if (isValidation) {
      let data = {
        email: "",
        mobileNumber: loginData?.mobileNumber,
        countryCode: loginData?.countryCode,
      };

      await SendLoginOtp(data, checkInDetailId, orgId)
        .then((res) => {
          console.log("res", res);
          if (res.status === 200) {
            setIsLogin(false);
            setGuestUserId(res?.data?.response);
            toast.success("OTP sented", { position: "top-right" });
          } else if (res?.response?.data?.httpStatusCode === 400) {
            toast.warning("Invalid user", { position: "top-right" });
          } else {
            toast.error(
              "There is issue occured in logging in, could you please try again",
              { position: "top-right" }
            );
          }
        })
        .finally((err) => {
          setBtnLoading(false);
        });
    } else {
      setBtnLoading(false);
    }
  };



  // Deepak - country flag added
  const handlePhoneChange = (value, countryData) => {
    if (
      value?.length <= 12 &&
      /^[0-9]+$|^$/.test(value) &&
      value !== loginData?.phoneNumber
    ) {
      setLoginData({
        ...loginData,
        mobileNumber: value,
        countryCode: countryData?.dialCode,
      });
    }
    // setPhoneNumber(value);
  };

  const getSelectedCountryName = () => {
    let selectedCountry = "bh";
    if (inputRef?.current?.countries && loginData?.countryCode) {
      selectedCountry = [...inputRef?.current?.countries]?.find(
        (val) => val.dialCode === loginData?.countryCode?.split("+")[1]
      )?.iso2;
    }
    console.log("selectedCountry", selectedCountry);
    return selectedCountry ? selectedCountry : "bh";
  };
  // -----------------------------------

  const handleLogin = async () => {
    let isValidation = loginData?.otp && loginData?.otp?.length === 4;

    setIsErrMsgView(!isValidation);

    if (isValidation) {
      await ValidateOtp(loginData?.otp, checkInDetailId, guestUserId)
        .then((res) => {
          if (res.status === 200) {
            setBtnLoading(false);
            localStorage?.setItem(
              "encCheckInCode",
              res?.data?.response?.encCheckInDetailCode
            );
            localStorage?.setItem(
              "encCheckIndetailsId",
              res?.data?.response?.encCheckInDetailId
            );
            localStorage?.setItem(
              "encOrgId",
              res?.data?.response?.encOrganizationId
            );
            toast.success("Logged in successfully", { position: "top-right" });
            navigate("/change-pickup");
          } else if (res?.response?.data?.httpStatusCode === 400) {
            toast.warning("Incorrect otp", { position: "top-right" });
          } else {
            toast.error(
              "There is issue occured in logging in, could you please try again",
              { position: "top-right" }
            );
          }
        })
        .finally((err) => {
          setBtnLoading(false);
        });
    } else {
      setBtnLoading(false);
    }
  };

  // Deepak - Country Picker
  const handleChange = (value) => {
    console.log("Change", value);
    setLoginData({
      ...loginData,
      mobileNumber: value
    });
  }
  const handleCountryChange = (value) => {
    console.log("Change", value);
    setLoginData({
      ...loginData,
      countryCode: value?.phone_code.includes("+") ? value.phone_code : "+" + value.phone_code
    });
  }
  // -------------------------------

  useEffect(() => {
    localStorage.removeItem("checkInDetailsId");
    localStorage.removeItem("orgId");
    localStorage.removeItem("encCheckIndetailsId");
    localStorage.removeItem("encOrgId");
    localStorage.removeItem("encCheckInCode");
    localStorage.setItem("checkInDetailsId", checkInDetailId);
    localStorage.setItem("orgId", orgId);
  }, []);

  return (
    <div className="loginpage">
      <div
        className="justify-responsive"
        style={{
          display: "flex",
          // justifyContent: "center"
          padding: "24px 20px",
        }}
      >
        <div className="lg:col-4 xl:col-4 sm:col-12 md:col-7">
          <div
            style={{
              // width: '500px',
              padding: "15px",
              color: "white",
              //  marginLeft: '900px',
              backgroundColor: "#ae9d81d0",
              borderRadius: "5px",
              // marginTop: '100px'
            }}
          >
            <div className="defaultWidth">
              <img
                alt="image"
                src={logo}
                style={{
                  width: "200px",
                  height: "64px",
                  display: "flex",
                  margin: "auto",
                }}
              />
              <p
                style={{
                  fontWeight: 700,
                  fontSize: 25,
                  color: "##2D6C8B",
                  textAlign: "center",
                  margin: "0px 0px 5px 0px",
                }}
              >
                {isLogin ? "Log In" : "Enter OTP"}
              </p>
            </div>

            {isLogin && (
              <div>
                <div className="defaultWidth">
                  <p className="defaultStyle">Mobile number</p>
                  <CountryPicker mobile={loginData?.mobileNumber} countryCode={loginData?.countryCode} handleChange={handleChange} handleCountryChange={handleCountryChange}></CountryPicker>
                </div>
                {isErrMsgView && !loginData?.mobileNumber && (
                  <div>
                    <p
                      style={{ color: "red", marginTop: 5, marginBottom: 0 }}
                    >
                      Please enter the mobile number
                    </p>
                  </div>
                )}
                {invalidUser && (
                  <div>
                    <p
                      style={{ color: "red", marginTop: 5, marginBottom: 0 }}
                    >
                      Invalid mobileNumber / Password
                    </p>
                  </div>
                )}
              </div>
            )}
            {!isLogin && (
              <div
                className="defaultWidth"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  marginTop: "20px",
                }}
              >
                {/* <p className="defaultStyle">Enter otp</p> */}
                <div className="textinputstyle" style={{ border: "none" }}>
                  <div
                    style={{
                      flex: 0.1,
                      alignItems: "center",
                      justifyContent: "center",
                      paddingHorizontal: 6,
                    }}
                  ></div>
                  <div style={{ flex: 0.9, flexGrow: 1 }} className="otp">
                    <InputOtp
                      value={loginData?.otp}
                      onChange={(e) => {
                        setLoginData({
                          ...loginData,
                          otp: e?.value,
                        });
                      }}
                      //   inputTemplate={customInput}
                      integerOnly
                    />
                  </div>
                </div>
                {isErrMsgView &&
                  (loginData?.otp || loginData?.otp?.length !== 4) && (
                    <div>
                      <p
                        style={{ color: "red", marginTop: 5, marginBottom: 0 }}
                      >
                        Please enter the otp
                      </p>
                    </div>
                  )}
                {invalidUser && (
                  <div>
                    <p style={{ color: "red", marginTop: 5, marginBottom: 0 }}>
                      Invalid Username / Password
                    </p>
                  </div>
                )}
              </div>
            )}
            <div className="defaultWidth" style={{ marginTop: "15px" }}>
              <Button
                style={{
                  border: "1px solid #AE9D81",
                  width: "100%",
                  height: "40px",
                  backgroundColor: "White",
                  color: "#ae9d81",
                  cursor: "pointer",
                  borderRadius: "5px",
                  boxShadow: "none",
                }}
                className="loginButton button"
                loading={btnLoading}
                onClick={() => {
                  setBtnLoading(true);
                  if (isLogin) {
                    handleSendOtp();
                  } else {
                    handleLogin();
                  }
                }}
              >
                {isLogin ? "Sent OTP" : "Submit"}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div >
  );
}
export default GuestLogin;
