import React from "react";
import { useEffect, useState } from "react";
import { TabView, TabPanel } from "primereact/tabview";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import style from "./style.module.scss";
import { useCookies } from "react-cookie";
import {
  getCategories,
  getSubCategories,
} from "../../Services/FeedbackService";

import {
  GetFeedbacks,
  GetGuestInspectionsInfo,
  GetGuestStaffInspections,
  GetSearchedFeedbacks,
  getSearchedFeedbacks,
  getSearchedInspectionForDashboard,
} from "../../Services/DepartmentTeamService";
import { GetFeedbackList } from "../../Services/FeedbackService";
import { FilterMatchMode } from "primereact/api";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Paginator } from "primereact/paginator";
import { exportDataInExcel } from "../../Services/ExportServices";
import { Dialog } from "primereact/dialog";
import { Calendar } from "primereact/calendar";

// ----------Assets Import------------
import editIcon from "../../../src/assets/images/editIcon.jpg";

//-----------Context ---------------
import { useDispatch, useSelector } from "react-redux";
import {
  setGuestInspectionsInfo,
  setFeedbackList,
  setNavVisibilty,
} from "../../Redux/AppSlice";
import { Dropdown } from "primereact/dropdown";
import {
  EditGuesStaffInspectionStatus,
  GuestInspectionStatuses,
} from "../../Services/InspectionService";
import { GetAllFiles } from "../../Services/Files";
import FileView from "../utili/FileView";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";

function DepartmentTeam() {
  const { tab } = useParams();

  const Context = useSelector((state) => state.AppContext);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [cookies, setCookie, removeCookie] = useCookies();
  const [activeIndex, setActiveIndex] = useState(tab - 1);
  const [guestInspectionsList, setGuestInspectionsList] = useState([]);
  const [feedbackList, setFeedbackLists] = useState("");
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [guestStaffInspectionFilterValue, setGuestStaffInspectionValue] =
    useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });
  const [statusList, setStatusList] = useState([{ id: "", name: "" }]);
  const [isFilemodelView, setISFileModelView] = useState(false);
  const [imageList, setImageList] = useState([]);
  // Pagination
  const [first, setFirst] = useState(0);
  const [totalCount, setTotalRecords] = useState(0);
  const [feedbackFirst, setFeedbackFirst] = useState(0);
  const [feedbackTotalCount, setFeebackTotalRecords] = useState(0);
  const [guestStaffInspection, setGuestStaffInspection] = useState("");
  const [dialogVisible, setDialogVisible] = useState(false);
  const [dropDownObj, setDropDownObj] = useState({
    startDate: "",
    endDate: "",
    category: {},
    subCategory: {},
    location: ""
  });
  const [categoriesList, setCategoriesList] = useState([
    {
      id: 0,
      categoryName: "",
      categoryNameInAr: "",
      isDeleted: false,
    },
  ]);
  const [recordSubCategoriesList, setRecordSubCategoriesList] = useState([
    {
      subCategoryId: 0,
      subCategoryName: "",
      categoryName: "",
      createdOn: "",
      createdBy: "",
      isDeleted: false,
    },
  ]);
  const onGlobalFilterChange = (e) => {
    setGlobalFilterValue(e ? e?.target?.value : "");
    if (!e) {
      getFeedbackList(0, 10);
      return;
    }
    getFeedbackList(0, 10, e?.target?.value);
  };
  const actionButtonTemplate1 = (row) => {
    return (
      <img
        src={editIcon}
        onClick={() => {
          navigate(`/departmentstaffview/${row?.feedbackId}`);
        }}
        style={{ cursor: "pointer" }}
      />
    );
  };
  const handleTabChange = (index) => {
    setActiveIndex(index);
    setGlobalFilterValue("");
    setGuestStaffInspection("");
  };
  const getFeedbackList = async (skip, take, searchValue) => {
    setIsLoading(true);
    let data = {
      take: take,
      skip: skip,
    };
    if (!searchValue) {
      await GetFeedbacks(data, cookies.bearerToken)
        .then((res) => {
          if (res?.status === 200) {
            if (res?.data?.data.length > 0) {
              setFeedbackLists(res?.data?.data);
              setFeebackTotalRecords(res?.data?.count);
              dispatch(setFeedbackList({ feedbackList: res?.data?.data }));
            }
          }
        })
        .catch((error) => {
          //console.log(error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      if (searchValue) {
        data["searchValue"] = searchValue;
      }
      await GetSearchedFeedbacks(data, cookies.bearerToken)
        .then((res) => {
          if (res?.status === 200) {
            setFeedbackLists(res?.data?.data);
            setFeebackTotalRecords(res?.data?.count);
            dispatch(setFeedbackList({ feedbackList: res?.data?.data }));
          }
        })
        .catch((error) => {
          //console.log(error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };
  const getFiles = async (id) => {
    let foundeData = guestInspectionsList?.find(
      (_) => _?.guestInspectionId == id
    );
    // //console.log(foundeData, "founded");
    if (foundeData?.files?.length > 0) {
      setImageList(foundeData?.files);
    } else {
      setImageList([]);
    }
  };

  const editGuesStaffInspectionStatus = async (guestInspectionId, statusId) => {
    await EditGuesStaffInspectionStatus(
      guestInspectionId,
      statusId,
      true,
      cookies.bearerToken
    ).then(async (res) => {
      if (res?.status == 200) {
        getGuestInspectionsInfo();
      }
    });
  };

  const getGuestInspectionsInfo = (skip, take, searchValue = "") => {
    setIsLoading(true);
    // if (!searchValue) {
    //     GetGuestStaffInspections(take, skip, cookies.bearerToken)
    //         .then((res) => {
    //             if (res?.status === 200) {
    //                 setGuestInspectionsList(res?.data?.data);
    //                 setTotalRecords(res?.data?.count);
    //             }
    //             // setGuestInspectionsList([{ "category": "hi", "location": "ji", "comment": "hi", "status": "hi", "Attachment": "hi" }]);
    //             // dispatch(
    //             //     setGuestInspectionsInfo({
    //             //         guestInspectionsList: res?.data
    //             //     })
    //             // )
    //         })
    //         .catch((error) => {
    //             //console.log(error);
    //         })
    //         .finally(() => {
    //             setIsLoading(false);
    //         })
    // } else {
    getSearchedInspectionForDashboard(
      take,
      skip,
      searchValue,
      cookies.bearerToken
    )
      .then((res) => {
        if (res?.status === 200) {
          setGuestInspectionsList(res?.data?.data);
          setTotalRecords(res?.data?.count);
        }
        // setGuestInspectionsList([{ "category": "hi", "location": "ji", "comment": "hi", "status": "hi", "Attachment": "hi" }]);
        // dispatch(
        //     setGuestInspectionsInfo({
        //         guestInspectionsList: res?.data
        //     })
        // )
      })
      .catch((error) => {
        //console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
    // }
  };

  const statusBodyTemplate = (row) => {
    if (row.status == "Completed") {
      return <p style={{ color: "#3ee63e" }}>Completed</p>;
    } else if (row.status == "Rejected") {
      return <p style={{ color: "red" }}>Rejected</p>;
    } else if (row.status == "Resolved") {
      return <p style={{ color: "#f553e7" }}>Resolved</p>;
    } else if (row.status == "Out of control") {
      return <p style={{ color: "#6ecbfa" }}>Out of control</p>;
    } else if (row.status == "Yet to Ackowledge") {
      return <p style={{ color: "#964B00" }}>Yet to Acknowledge</p>;
    } else if (row.status == "Assigned") {
      return <p style={{ color: "#bd16f5" }}>Assigned</p>;
    } else if (row?.status == "Reassign") {
      return <p style={{ color: "#1512cc" }}>Reassign</p>;
    } else if (row?.status == "Closed") {
      return <p style={{ color: "#3ee63e" }}>Closed</p>;
    }
  };
  const statusBodyTemplateTab3 = (row) => {
    return (
      <p
        style={{ padding: "0 20px" }}
        onClick={() => {
          navigate(`/gueststaffinspectionview/${row?.guestInspectionId}`);
        }}
      >
        {/* <img src={editIcon} alt="edit Icon" /> */}
        <i className="pi pi-eye" style={{ color: "#708090" }}></i>
      </p>
    );
  };
  const dateBodyTemplate = (row) => {
    const monthAbbreviations = {
      Jan: "01",
      Feb: "02",
      Mar: "03",
      Apr: "04",
      May: "05",
      Jun: "06",
      Jul: "07",
      Aug: "08",
      Sep: "09",
      Oct: "10",
      Nov: "11",
      Dec: "12",
    };
    const parts = row?.inspectedOn.split("/");
    const day = parts[0];
    const monthAbbreviation = parts[1];
    const year = parts[2];

    const month = monthAbbreviations[monthAbbreviation];

    const outputDate = `${day}/${month}/${year}`;

    return outputDate;
  };

  const dateBodyTemplateTwo = (row) => {
    let date = new Date(row?.createdDate);
    return `${date.getDate()}/${date.getMonth()}/${date.getFullYear()}`;
  }

  const onSearchGuestStaffInspection = (e) => {
    setGuestStaffInspection(e ? e?.target?.value : "");
    if (!e) {
      getGuestInspectionsInfo(0, 10);
      return;
    }
    getGuestInspectionsInfo(0, 10, e?.target?.value);
  };
  const onPageChange = (e, index) => {
    setFirst(e.first);
    getGuestInspectionsInfo(e.first, 10, globalFilterValue);
  };
  const onPageChangeGuestInspection = (e, index) => {
    setFirst(e.first);
    getGuestInspectionsInfo(e.first, 10, guestStaffInspection);
  };
  const onFeedbackListPageChange = (e, index) => {
    setFeedbackFirst(e.first);
    getFeedbackList(e.first, 10, globalFilterValue);
  };
  const DateConversion = (inputDateStr) => {
    const decodedDateStr = decodeURIComponent(inputDateStr);
    const dateObj = new Date(decodedDateStr);
    const year = dateObj.getFullYear();
    const month = String(dateObj.getMonth() + 1).padStart(2, '0');
    const day = String(dateObj.getDate()).padStart(2, '0');
    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate
  };
  const exportFeedbacksForDT = () => {
    // console.log(dropDownObj
    // );
    // if (dropDownObj?.category && dropDownObj?.startDate && dropDownObj?.endDate && dropDownObj?.subCategory) {
    exportDataInExcel(
      `FeedbacksForDT?searchTerm=${globalFilterValue}&from=${DateConversion(dropDownObj?.startDate)}&to=${DateConversion(dropDownObj?.endDate)}&feedbackCategory=${dropDownObj?.category?.id}&feedbackSubcategory=${dropDownObj?.subCategory?.subCategoryId}&location=${dropDownObj?.location}`,
      cookies.bearerToken
    );
    //   return;
    // }
    // exportDataInExcel(`FeedbacksForDT?searchTerm=${globalFilterValue}`, cookies.bearerToken);
  };
  const exportGuestStaffInspectionForDT = () => {
    if (globalFilterValue) {
      exportDataInExcel(
        `GuestStaffInspectionForDT?searchTerm=${globalFilterValue}`,
        cookies.bearerToken
      );
      return;
    }
    exportDataInExcel("GuestStaffInspectionForDT", cookies.bearerToken);
  };

  useEffect(() => {
    getGuestInspectionsInfo(0, 10);
    getFeedbackList(0, 10);
    getCategoriesList();
    getSubcategoriesList();
  }, []);

  const getCategoriesList = async () => {
    await getCategories()
      .then((res) => {
        // console.log(res?.data);
        if (res?.status === 200) {
          setCategoriesList(res?.data);
        }
      })

      .catch((err) => {
        // console.log(err);
      });
  };
  const getSubcategoriesList = async () => {
    await getSubCategories().then((list) => {
      // console.log(list?.data);
      if (list?.data) {
        // setSubCategoriesList(list?.data);
        setRecordSubCategoriesList(list?.data);
      }
    });
  };

  return (
    <>
      <Dialog
        header="Export Filter"
        visible={dialogVisible}
        style={{ width: "50vw" }}
        onHide={() => {
          setDialogVisible(false);
          setDropDownObj({
            ...dropDownObj,
            startDate: "",
            endDate: "",
            category: {},
            subCategory: {},
          });
        }}
      >
        <div style={{ display: "flex", flexWrap: "wrap" }}>
          <div style={{ width: "50%" }}>
            <label htmlFor="startDate" className="font-bold block mb-2">
              Start Date
            </label>
            <Calendar
              style={{ width: "95%" }}
              id="startDate"
              value={dropDownObj.startDate}
              placeholder="Choose start date"
              onChange={(e) =>
                setDropDownObj({ ...dropDownObj, startDate: e.value })
              }
              showIcon
            />
          </div>
          <div style={{ width: "50%" }}>
            <label htmlFor="endDate" className="font-bold block mb-2">
              End Date
            </label>
            <Calendar
              style={{ width: "100%" }}
              id="endDate"
              placeholder="Choose end date"
              value={dropDownObj.endDate}
              onChange={(e) =>
                setDropDownObj({ ...dropDownObj, endDate: e.value })
              }
              showIcon
            />
          </div>
          <div style={{ width: "50%", marginTop: "25px" }}>
            <label htmlFor="category" className="font-bold block mb-2">
              Category
            </label>
            <Dropdown
              id="category"
              style={{ width: "95%" }}
              value={dropDownObj.category}
              onChange={(e) =>
                setDropDownObj({ ...dropDownObj, category: e.value })
              }
              options={categoriesList}
              optionLabel="categoryName"
              placeholder="Select a Category"
            />
          </div>
          <div style={{ width: "50%", marginTop: "25px" }}>
            <label htmlFor="subCategory" className="font-bold block mb-2">
              Sub Category
            </label>
            <Dropdown
              id="subCategory"
              style={{ width: "100%" }}
              value={dropDownObj.subCategory}
              onChange={(e) =>
                setDropDownObj({ ...dropDownObj, subCategory: e.value })
              }
              options={recordSubCategoriesList}
              optionLabel="subCategoryName"
              placeholder="Select a Subcategory"
            />
          </div>
          <div style={{ width: "50%", marginTop: "25px" }}>
            <label htmlFor="location" className="font-bold block mb-2">
              Location
            </label>
            <InputText
              id="location"
              style={{ width: "100%" }}
              value={dropDownObj?.location}
              onChange={(e) =>
                setDropDownObj({ ...dropDownObj, location: e.value })
              }
              placeholder="Enter Location"
            />
          </div>
          <div style={{ width: "50%", marginTop: "25px" }}>

          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "25px",
            }}
          >
            <Button
              label="Submit"
              onClick={feedbackTotalCount > 0 ? exportFeedbacksForDT : null}
              // icon={"pi pi-file-export"}
              style={{
                backgroundColor: "transparent",
                color: "#ae9d81",
                border: "1px #ae9d81 solid",
                borderRadius: "20px",
                marginRight: "0",
                cursor: feedbackTotalCount > 0 ? "pointer" : "not-allowed",
                padding: "0.6rem",
              }}
            />
          </div>
        </div>
      </Dialog>
      <FileView
        visible={isFilemodelView}
        imagelist={imageList}
        setVisible={setISFileModelView}
        header={"Attachments"}
      />
      <div className="DepartmentTeam">
        <div className="header">
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              flexGrow: "3",
              justifyContent: "flex-start",
            }}
            className="flex align-items-center org"
          >
            <div className="menubtn" style={{ padding: "10px 10px 5px 20px" }}>
              <i
                className="pi pi-align-justify"
                style={{
                  fontSize: "1rem",
                  color: "#ae9d81",
                  cursor: "pointer",
                }}
                onClick={() =>
                  dispatch(setNavVisibilty({ sideNavVisibility: true }))
                }
              ></i>
            </div>

            <div
            // className="heading"
            //  style={{ paddingLeft: "15px", marginLeft: '40px' }}
            >
              <p
                style={{
                  color: "#2d6c8b",
                  fontSize: "18px",
                  fontStyle: "normal",
                  fontWeight: "700",
                  lineHeight: "normal",
                  letterSpacing: "-0.3px",
                  margin: 0,
                }}
              >
                Deparment Actions
              </p>
            </div>
          </div>
        </div>
      </div>
      <div
        className="card"
        style={{
          //  padding: "0 10px"
          position: "relative",
        }}
      >
        <TabView
          activeIndex={activeIndex}
          panelContainerStyle={{ padding: 10 }}
          style={{ padding: "0 10px" }}
          onTabChange={(e) => handleTabChange(e.index)}
        >
          <TabPanel
            header="Feedback"
            className={`${style.tab_view} ${activeIndex === 0 ? style.active_tab : ""
              }`}
          >
            <div
              className="header md:absolute sm:relative lg:absolute"
              style={{
                // position: "absolute",
                top: "-34px",
                right: "0",
                display: "flex",
                alignItems: "center",
              }}
            >
             <div className="flex align-items-center">
              <div className="mx-2 ">
                <h4 className='mb-1 mt-0 relative' style={{color:"#ae9d81"}}>Search</h4>
                  <div className='flex align-items-center mx-2' style={{border:"solid 1px #AE9D81",borderRadius:"4px",height:"42px"}}>
                    <i className="pi pi-search px-2" style={{ color: 'black', fontWeight: '700', fontSize: '1rem'}} />
                                  <InputText value={globalFilterValue}  onChange={onGlobalFilterChange} placeholder="Search" className='border-0 relative' style={{width:"180px",height:"40px"}}/>
                    <i className="pi pi-times px-2" style={{ color: 'black', fontWeight: '400', fontSize: '1rem', cursor: 'pointer' }}  onClick={() => { setGlobalFilterValue("");
                        onGlobalFilterChange(null); }} />
                  </div>
              </div>
                <div className="mx-2 align-self-end">
                    <Button
                      label="Export"
                      onClick={() => setDialogVisible(true)}
                      icon={"pi pi-file-export"}
                      style={{
                        backgroundColor: "transparent",
                        color: "#ae9d81",
                        border: "1px #ae9d81 solid",
                        borderRadius: "20px",
                        marginRight: "0",
                        cursor: feedbackTotalCount > 0 ? "pointer" : "not-allowed",
                        padding: "0.6rem",
                      }}
                    />
                  </div>
             </div>
              {/* <span
                style={{
                  display: "flex",
                  alignItems: "center",
                  border: "solid 1px #ae9d81",
                  borderRadius: "5px",
                }}
              >
                <i
                  className="pi pi-search"
                  style={{
                    color: "black",
                    fontWeight: "700",
                    fontSize: "1rem",
                    padding: "0.5rem",
                  }}
                />
                <InputText
                  value={globalFilterValue}
                  onChange={onGlobalFilterChange}
                  placeholder="Search"
                  style={{
                    border: "unset",
                    padding: 10,
                    paddingLeft: 10,
                    width: "100%",
                  }}
                />
                <i
                  className="pi pi-times"
                  style={{
                    color: "black",
                    fontWeight: "400",
                    fontSize: "16px",
                    padding: "0.5rem",
                  }}
                  onClick={() => {
                    setGlobalFilterValue("");
                    onGlobalFilterChange(null);
                  }}
                />
              </span> */}
              {/* <div
                                // className="search"
                                style={{
                                    border: "solid 1px #ae9d81",
                                    position: "relative",
                                    // display: "flex",
                                    borderRadius: '5px'
                                }}
                            >
                                <span
                                    style={{ display: "flex" }}
                                //  className="p-input-icon-left"
                                >
                                    <i
                                        className="pi pi-search"
                                        style={{ color: 'black', fontWeight: '700', fontSize: '1rem', padding: "10px" }} />
                                    <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Search" style={{ border: "unset", padding: 10, paddingLeft: 10 }} />
                                    <i className="pi pi-times" style={{
                                        color: 'black', fontWeight: '400', fontSize: '16px',
                                        position: 'absolute',
                                        right: 10
                                    }} onClick={() => { setGlobalFilterValue(""); onGlobalFilterChange(null) }} />
                                </span>

                            </div> */}

            </div>
            {isLoading ?
              <div className='spinner spinner-center' style={{ marginLeft: '750px' }}></div>
              :
              <DataTable
                value={feedbackList}
                // tableStyle={{ width: '90rem' }}
                style={{ boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}
                stripedRows rows={10} filters={filters}
                globalFilterFields={['userName', 'mobileNo', 'email', "category", 'subCategory', 'location']}
                emptyMessage={<><p style={{ textAlign: "center" }}>Feedback list is empty</p></>} >
                <Column field='code' header='Feedback Id' ></Column>
                <Column field='userName' header='Name' ></Column>
                <Column field='createdDate' header='Created Date' body={dateBodyTemplateTwo}></Column>
                <Column field='departmentName' header='Deparment Name'></Column>
                <Column field='category' header='Category'></Column>
                <Column field='subCategory' header='Sub Category'></Column>
                <Column field='location' header='Location'></Column>
                <Column
                  field='status'
                  header='Status'
                  body={statusBodyTemplate}
                />
                <Column field='action' header='Action' body={actionButtonTemplate1}></Column>
              </DataTable>}
            {isLoading || feedbackList?.length == 0 ? ""
              :
              <div style={{ justifyContent: "center", display: 'flex' }} >
                <Paginator first={feedbackFirst} rows={10} totalRecords={feedbackTotalCount} onPageChange={(e) => onFeedbackListPageChange(e, 1)} template={{ layout: 'RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink  LastPageLink CurrentPageReport' }} className="justify-content-start" />
              </div>
            }

          </TabPanel>
          <TabPanel header="Guest Staff Inspection" className={`${style.tab_view} ${activeIndex === 1 ? style.active_tab : ''}`}>
            {/* <div className='header' style={{
                            display: "flex", alignItems: "center",
                            //  position: "absolute", top: "20%", left: "70%", zIndex: 2 
                        }}>
                            <div>
                                <Button label='Export' onClick={totalCount > 0 ? exportGuestStaffInspectionForDT : null} icon={"pi pi-file-export"} style={{
                                    backgroundColor: 'transparent', color: '#ae9d81', border: '1px #ae9d81 solid', borderRadius: '20px', marginRight: '0px', cursor: totalCount > 0 ? 'pointer' : 'not-allowed',
                                    // position: 'absolute', right: 330, top: 90 
                                }} />
                            </div>
                            <div className="search"
                                style={{
                                    border: "solid 1px #ae9d81",
                                    // position: 'absolute', top: '90px', left: '1200px', 
                                    borderRadius: '5px'
                                }}
                            >
                                <span className="p-input-icon-left p-input-icon-right">
                                    <i
                                        className="pi pi-search"
                                        style={{ color: 'black', fontWeight: '700', fontSize: '1rem' }} />
                                    <InputText value={guestStaffInspection} onChange={onSearchGuestStaffInspection} placeholder="Search" style={{ border: "unset", padding: "10px -4px" }} />
                                    <i className="pi pi-times" style={{
                                        color: 'black', fontWeight: '400', fontSize: '16px', position: 'absolute',
                                        right: 10
                                    }} onClick={() => { setGuestStaffInspection(""); onSearchGuestStaffInspection(null) }} />
                                </span>
                            </div>
                        </div> */}
            <div
              className="header md:absolute sm:relative lg:absolute"
              style={{
                // position: "absolute",
                top: "-34px",
                right: "0",
                display: "flex",
                alignItems: "center",
              }}
            >
              <div className="mx-2">
                <h4 className='mb-1 mt-0' style={{color:"#ae9d81"}}>Search</h4>
                <span
                  style={{
                    display: "flex",
                    alignItems: "center",
                    border: "solid 1px #ae9d81",
                    borderRadius: "5px",
                    height:"40px"
                  }}
                >
                  <i
                    className="pi pi-search"
                    style={{
                      color: "black",
                      fontWeight: "700",
                      fontSize: "1rem",
                      padding: "1rem",
                    }}
                  />
                  <InputText
                    value={guestStaffInspection}
                    onChange={onSearchGuestStaffInspection}
                    placeholder="Search"
                    style={{
                      border: "unset",
                      padding:"0px",
                      paddingLeft: 10,
                      width: "100%",
                    }}
                  />
                  <i
                    className="pi pi-times"
                    style={{
                      color: "black",
                      fontWeight: "400",
                      fontSize: "16px",
                      padding: "1rem",
                    }}
                    onClick={() => {
                      setGuestStaffInspection("");
                      onSearchGuestStaffInspection(null);
                    }}
                  />
                </span>
              </div>
             
              <div className="mx-2 align-self-end mt-1">
                <Button
                  label="Export"
                  onClick={feedbackTotalCount > 0 ? exportFeedbacksForDT : null}
                  icon={"pi pi-file-export"}
                  style={{
                    backgroundColor: "transparent",
                    color: "#ae9d81",
                    border: "1px #ae9d81 solid",
                    borderRadius: "20px",
                    cursor: feedbackTotalCount > 0 ? "pointer" : "not-allowed",
                    padding: "0.6rem",
                  }}
                />
              </div>
            </div>

            {isLoading ? (
              <div
                className="spinner spinner-center"
                style={{ marginLeft: "750px" }}
              ></div>
            ) : (
              <DataTable
                value={guestInspectionsList}
                // tableStyle={{ width: '90rem' }}
                style={{ boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)" }}
                stripedRows
                rows={10}
                filters={filters}
                globalFilterFields={["guestInspectionId", "inspectorName"]}
                emptyMessage={
                  <>
                    <p style={{ textAlign: "center" }}>
                      Inspection list is empty
                    </p>
                  </>
                }
              >
                <Column field="code" header=" Inspection Id    "></Column>
                <Column field="inspectorName" header="Inspector Name"></Column>
                <Column
                  field="inspectedOn"
                  header="Inspected Date"
                  body={dateBodyTemplate}
                ></Column>
                {/* <Column field='status' header='Status' /> */}
                <Column header="Action" body={statusBodyTemplateTab3} />
              </DataTable>
            )}
            {isLoading || guestInspectionsList?.length == 0 ? (
              ""
            ) : (
              <div style={{ justifyContent: "center", display: "flex" }}>
                <Paginator
                  first={first}
                  rows={10}
                  totalRecords={totalCount}
                  onPageChange={(e) => onPageChangeGuestInspection(e, 1)}
                  template={{
                    layout:
                      "RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink  LastPageLink CurrentPageReport",
                  }}
                  className="justify-content-start"
                />
              </div>
            )}
          </TabPanel>
        </TabView>
      </div>
    </>
  );
}

export default DepartmentTeam;
