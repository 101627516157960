import { useCookies } from 'react-cookie';
import React, { useEffect, useRef, useState } from 'react';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import './style.scss';
import { Button } from 'primereact/button';
import { useNavigate, useParams } from 'react-router-dom';
import { AddReturnFormForm } from '../../Services/LostandFoundService';
import { InputTextarea } from 'primereact/inputtextarea';
import CustomWebcam from '../Camera/Camera';
import { BulkUploadBase64Files } from '../../Services/Files';
import { toast } from 'react-toastify';
import IntlTelInput from 'react-intl-tel-input';



function ReturnForm() {
    const { id, route } = useParams();
    const inputRef = useRef(null);
    const localizationValue = "en";
    const navigate = useNavigate();
    const [cookies, setCookie, removeCookie] = useCookies();
    const [camerActive, setCamerActive] = useState(false);
    const [returnFormdata, setReturnFormdata] = useState({
        "firstName": "",
        "lastName": "",
        "email": "",
        "phoneNumber": "",
        "witnessPersonName": "",
        "comment": "",
        "foundId": 0,
        "status": ""
    });
    const [isInvalid, setIsInvalid] = useState(false);
    const [imgSrc, setImgSrc] = useState("");
    const [filename, setFilename] = useState("");

    // Mobile number with conutry code - Deepak
    const getMobileNumber = () => {
        let mobileNumber = inputRef?.current?.selectedCountryData?.dialCode + inputRef?.current?.state?.value
        assignValue("phoneNumber", "+" + mobileNumber)
        return mobileNumber;
    };
    const assignValue = (key, value) => {
        setReturnFormdata((prevState) => {
            return {
                ...prevState,
                [key]: value
            };
        });
    };
    const getSelectedCountryName = () => {
        let selectedCountry = "bh";
        if (inputRef?.current?.countries && returnFormdata?.countryCode) {
            selectedCountry = [...inputRef?.current?.countries]?.find(
                (val) => val.dialCode === returnFormdata?.countryCode
            )?.iso2;
        }
        console.log("selectedCountry", selectedCountry);
        return selectedCountry ? selectedCountry : "bh";
    };
    const handlePhoneChange = (value, countryData) => {
        console.log("countryData", countryData);
        if (
            value?.length <= 12 &&
            /^[0-9]+$|^$/.test(value) &&
            value !== returnFormdata?.phoneNumber
        ) {
            setReturnFormdata({
                ...returnFormdata,
                phoneNumber: value,
                countryCode: countryData?.dialCode,
            });
        }
        // setPhoneNumber(value);
    };
    const handleInputValue = (key, value) => {
        setReturnFormdata({ ...returnFormdata, [key]: value });
    };
    // ------------------------------   
    const handleSubmit = async () => {
        if (returnFormdata?.firstName == "" ||
            returnFormdata?.lastName == "" ||
            // returnFormdata?.email == "" ||
            returnFormdata?.phoneNumber == "" ||
            returnFormdata?.countryCode == "" ||
            returnFormdata?.witnessPersonName == "" ||
            returnFormdata?.comment == "" ||
            imgSrc == ""
        ) {
            setIsInvalid(true);
            return;
        }
        setIsInvalid(false);
        let data = {
            "firstName": returnFormdata?.firstName,
            "lastName": returnFormdata?.lastName,
            "email": returnFormdata?.email,
            "phoneNumber": returnFormdata?.phoneNumber,
            "countryCode": returnFormdata?.countryCode,
            "witnessPersonName": returnFormdata?.witnessPersonName,
            "comment": returnFormdata?.comment,
            "foundId": id,
            "status": ""
        }
        if (imgSrc == "") {
            setIsInvalid(true);
            return;
        }
        //console.log(data);
        await AddReturnFormForm(data, cookies?.bearerToken)
            .then((res) => {
                if (res.status === 200) {
                    //console.log(res);
                    AddAttachment(res?.data);
                    toast.success("Return form submitted successfully", { position: "top-right" });
                }
            })
            .catch((err) => {
                //console.log(err);
            })
            .finally(() => {
                if (route === "g4") { navigate('/lostandfound/4') }
                if (route == "s3") { navigate('/security-staff/3') }

            })
    }


    const AddAttachment = async (returnId) => {
        let fileNameExtension = imgSrc.split(";base64,")[0].split("/")[1];
        let fileModule = "Found";
        let filedate = new Date().getFullYear() + new Date().getMonth() + new Date().getDate();
        let fileName = fileModule + filedate + "." + fileNameExtension;
        let data = {
            "id": returnId,
            "module": "Found",
            "inspectionId": 0,
            "files": [
                {
                    "fileName": fileName,
                    "base64": imgSrc.split(";base64,")[1]
                }
            ]
        }
        //console.log("Data", data);
        await BulkUploadBase64Files(data, cookies.bearerToken)
            .then((res) => {
                if (res.status == 200) {
                    //console.log(res);
                    // toast.success("Attachments uploaded successfully", { position: "top-right" });
                }
            })
            .catch((err) => {
                //console.log(err);
            })


    };










    useEffect(() => {

    }, [])



    return (
        <div className="ReturnForm">
            {camerActive && <CustomWebcam setIsVisible={setCamerActive} image={imgSrc} setImage={setImgSrc} setFilename={setFilename} />}
            <div className="header" style={{ backgroundColor: '#ae9d81', color: 'white' }}>
                <i className="pi pi-arrow-left" style={{ cursor: "pointer" }} onClick={() => { if (route === "g4") { navigate('/lostandfound/4') } if (route == "s3") { navigate('/security-staff/3') } }}></i>
                <div style={{ margin: 'auto', fontWeight: '600', fontSize: '18px' }}>Return Form</div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', margin: '0px 50px' }}>
                <div>
                    <p>First Name<span style={{ color: 'red' }}>*</span></p>
                    <div>
                        <InputText placeholder='Enter First Name' style={{ width: '350px' }}
                            value={returnFormdata?.firstName} onChange={(e) => {
                                setReturnFormdata({ ...returnFormdata, firstName: e.target.value })
                            }} />
                    </div>
                    {isInvalid && returnFormdata?.firstName == "" &&
                        <p style={{ color: "red" }}>First name  is required</p>
                    }
                </div>
                <div>
                    <p>Last Name<span style={{ color: 'red' }}>*</span></p>
                    <div>
                        <InputText placeholder='Enter Last Name' style={{ width: '350px' }}
                            value={returnFormdata?.lastName} onChange={(e) => {
                                setReturnFormdata({ ...returnFormdata, lastName: e.target.value })
                            }}
                        />
                    </div>
                    {isInvalid && returnFormdata?.lastName == "" &&
                        <p style={{ color: "red" }}>Last name  is required</p>
                    }
                </div>
                <div>
                    <p>Email</p>
                    <div>
                        <InputText placeholder='Enter Email' style={{ width: '350px' }}
                            value={returnFormdata?.email} onChange={(e) => {
                                setReturnFormdata({ ...returnFormdata, email: e.target.value })
                            }}
                        />
                    </div>
                    {/* {isInvalid && returnFormdata?.email == "" &&
                        <p style={{ color: "red" }}>Email  is required</p>
                    } */}
                </div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', margin: '20px 50px 0px 50px' }}>
                <div>
                    <p>Mobile Number<span style={{ color: 'red' }}>*</span></p>
                    <div>
                        {/* <InputText placeholder='Enter Mobile Number' type='number' style={{ width: '350px' }}
                            value={returnFormdata?.phoneNumber}
                            onChange={(e) => {
                                setReturnFormdata({ ...returnFormdata, phoneNumber: e.target.value })
                            }}
                        /> */}
                        <IntlTelInput
                            // className='mg_care__input'
                            style={{ width: "350px", }}
                            value={returnFormdata?.phoneNumber}
                            containerClassName="intl-tel-input phoneInputContainerStyle"
                            noCountryDataHandler={(e) => console.log(e)}
                            inputClassName={localizationValue == "en" ? "phoneInputStyle" : "phoneInputStyle directionRtl"}
                            // onSelectFlag={(value, countryData) => {
                            //     setReturnFormdata({ ...returnFormdata, countryCode: countryData?.dialCode })
                            // }}
                            onPhoneNumberChange={(
                                valid,
                                value,
                                countryData,
                                number,
                                id
                            ) => {
                                handlePhoneChange(value, countryData);
                            }}
                            defaultCountry={getSelectedCountryName()}
                            preferredCountries={["bh", "ae"]}
                            ref={inputRef}

                        />


                    </div>
                    {isInvalid && returnFormdata?.phoneNumber == "" &&
                        <p style={{ color: "red" }}>Mobile number  is required</p>
                    }
                </div>
                <div>
                    <p>Returned Item<span style={{ color: 'red' }}>*</span></p>
                    <div>
                        <InputText disabled placeholder='Found Id' style={{ width: '350px', borderColor: '#ae9d81' }} value={id} />
                    </div>
                    {isInvalid && id == "" &&
                        <p style={{ color: "red" }}>Found Id  is required</p>
                    }
                </div>
                <div>
                    <p></p>
                    <div style={{ width: '350px' }}>
                        {/* <Dropdown options={status} placeholder='Select Status' style={{ width: '350px', borderColor: '#ae9d81' }}
                            value={returnFormdata?.status}
                            onChange={(e) => {
                                setReturnFormdata({ ...returnFormdata, status: e.value })
                            }}
                        /> */}
                    </div>
                </div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', margin: '20px 50px 0px 50px' }}>
                <div>
                    <p> Person image<span style={{ color: 'red' }}>*</span>
                        <i className='pi pi-camera' style={{ float: 'right', margin: '10px', color: "#ae9d81", cursor: imgSrc == "" ? "pointer" : "not-allowed" }} onClick={() => { setCamerActive(true); }}></i>

                    </p>
                    <div>
                        <div className="calendar" style={{ width: '350px', height: imgSrc == "" ? "50px" : "auto", border: 'solid 1px #ae9d81', borderRadius: "5px" }}>
                            {imgSrc == "" ? "" : <img src={imgSrc} style={{ width: "50%", padding: "15px" }} />}
                        </div>
                    </div>
                    {isInvalid && imgSrc == "" &&
                        <p style={{ color: "red" }}>Person image  is required</p>
                    }
                </div>
                <div>
                    <p>Witness Person Name<span style={{ color: 'red' }}>*</span></p>
                    <div>
                        <InputText placeholder='Witness Person Name' style={{ width: '350px', borderColor: '#ae9d81' }}
                            value={returnFormdata?.witnessPersonName} onChange={(e) => {
                                setReturnFormdata({ ...returnFormdata, witnessPersonName: e.target.value })
                            }}
                        />
                    </div>
                    {isInvalid && returnFormdata?.witnessPersonName == "" &&
                        <p style={{ color: "red" }}>Witness Person Name  is required</p>
                    }
                </div>
                <div style={{ marginTop: '-103px' }}>
                    <p>Comment</p>
                    <div>
                        <InputTextarea style={{ width: '350px', height: '150px', borderColor: "#ae9d81" }} value={returnFormdata?.comment}
                            onChange={(e) => {
                                setReturnFormdata({ ...returnFormdata, comment: e.target.value })
                            }}
                        />
                    </div>
                    {isInvalid && returnFormdata?.comment == "" &&
                        <p style={{ color: "red" }}>Comment  is required</p>
                    }
                </div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'end', gap: '20px', margin: "10px 50px 50px 0px" }}>
                <Button label='Cancel' className='cnl-btn' onClick={() => { if (route === "g4") { navigate('/lostandfound/4') } if (route == "s3") { navigate('/security-staff/3') } }} />
                <Button label='Submit' className='smt-btn' onClick={handleSubmit} />

            </div>

        </div>
    )
}

export default ReturnForm