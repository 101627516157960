import { toast } from "react-toastify";
import React, { useState, useEffect, useRef } from "react";
import "./Inventory.scss";
import { useCookies } from "react-cookie";
import { useDispatch } from "react-redux";
import { setNavVisibilty } from "../../../../Redux/AppSlice";

// Prime React Components
import Modalinfo from "../../../utili/Modalinfo";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Paginator } from "primereact/paginator";
import { Dialog } from "primereact/dialog";
import {
  AddInventoryItem,
  GetPantryItems,
  BulkImportVIPLoungeInventory,
  UpdateInventoryItem,
  GetPantryClosingReport,
} from "../../../../Services/VIPLoungePantryStaff";
import { FileUpload } from "primereact/fileupload";

import localAsset from "../../../../assets/files/VIP_PatchPantryItems.csv";
import editIcon from "../../../../assets/images/editIcon.jpg";

// ------------------------------

// Assests
import nonFound from "../../../../assets/images/nonfound.png";
import { useNavigate } from "react-router-dom";
import { exportDataInExcel, exportExcel, exportFilterdExcel } from "../../../../Services/ExportServices";
import { apiUrl } from "../../../../Helpers/Axios";
import { Dropdown } from "primereact/dropdown";
import { GetPantryCategoryList } from "../../../../Services/PantryCategoryService";
import { Calendar } from "primereact/calendar";
import { GetActiveOrganizationList } from "../../../../Services/OrganizationService";
// ------------------------------

const rowper = 15;
function Inventory() {
  const inintialAddItemDetails = {
    name: "",
    price: "",
    quantity: "",
    id: null,
    isEdit: false,
    category: ""
  };
  const [cookies, setCookie, removeCookie] = useCookies();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [inValid, setInvalid] = useState(false);
  const [isImport, setIsImport] = useState(false);
  const [isImportInvalid, setIsImportInvalid] = useState(false);
  const [isImportInvalidFile, setIsImportInvalidFile] = useState(false);
  const [isFileValidating, setFileValidating] = useState(false);
  const fileRef = useRef(null);

  // Pagination
  const [first, setFirst] = useState(0);
  const [totalCount, setTotalCount] = useState(0);

  // Action button
  const [isVisible, setIsVisible] = useState(false);

  // Search && Filter
  const [searchFilterValue, setSearchFilterValue] = useState("");

  // Inventory List
  const [addItem, setAddItem] = useState({ ...inintialAddItemDetails });
  const [addLoading, setAddLoading] = useState(false);
  const [inventoryList, setInventoryList] = useState([]);
  const [importFile, setImportFile] = useState(null);
  const [importFileErrmsg, setImportFileErrmsg] = useState(null);
  const [exportObj, setExportObj] = useState({
    fromDate: "",
    toDate: "",
    searchTerm: "",
    category: "",
    subcategory: "",
    organization: "",
    isModalVisible: false
  });
  const [categoryList, setCategoryList] = useState([]);
  const [organizationList, setOrganizationList] = useState([]);

  const navigate = useNavigate();

  const handleAddItem = async () => {
    if (addItem.name == "" || addItem.price == "" || addItem.quantity == "" || addItem?.category == "") {
      // setIsVisible(false);
      setInvalid(true);
      return;
    }
    let data = {
      name: addItem?.name,
      amount: addItem?.price,
      currencyISOCode: "BHD",
      quantity: addItem?.quantity,
      categoryName: addItem?.category
    };
    setAddLoading(true);
    setInvalid(false);
    await AddInventoryItem(data, cookies?.bearerToken)
      .then((res) => {
        if (res?.status === 200) {
          setIsVisible(false);
          setAddItem({ ...inintialAddItemDetails });
          toast.success("Item added successfully", { position: "top-right" });
          getInventoryList();
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setAddLoading(false);
      });
  };

  const handleUpdate = async () => {
    if (addItem.name == "" || addItem.price == "" || addItem.quantity == "" || addItem?.category == "") {
      // setIsVisible(false);
      setInvalid(true);
      return;
    }
    let data = {
      name: addItem?.name,
      amount: addItem?.price,
      currencyISOCode: "BHD",
      quantity: addItem?.quantity,
      itemId: addItem?.id,
      categoryName: addItem?.category
    };
    setAddLoading(true);
    setInvalid(false);
    await UpdateInventoryItem(data, cookies?.bearerToken)
      .then((res) => {
        if (res?.status === 200) {
          setIsVisible(false);
          setAddItem({ ...inintialAddItemDetails });
          toast.success("Item Updated successfully", { position: "top-right" });
          getInventoryList();
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setAddLoading(false);
      });
  };

  const onPageChange = (event) => {
    setFirst(event.first);
    getInventoryList(event.first, rowper, searchFilterValue, exportObj?.category, exportObj?.fromDate, exportObj?.toDate);
  };

  const getInventoryList = async (skip = 0, take = rowper, searchTerm = "", categoryName = "", startDate = "", endDate = "") => {
    setIsLoading(true);
    const formattedStartDate = startDate
      ? startDate.getFullYear() + '/' +
      (startDate.getMonth() + 1).toString().padStart(2, '0') + '/' +
      startDate.getDate().toString().padStart(2, '0')
      : "";

    const formatedEndDate = endDate
      ? endDate.getFullYear() + '/' +
      (endDate.getMonth() + 1).toString().padStart(2, '0') + '/' +
      endDate.getDate().toString().padStart(2, '0')
      : "";
    await GetPantryItems(skip, take, searchTerm, cookies.bearerToken, categoryName, formattedStartDate, formatedEndDate)
      .then((res) => {
        console.log("new res", res);
        if (res?.status === 200) {
          setInventoryList(res?.data?.data);
          setTotalCount(res?.data?.totalRecords);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  // Import Items to Inventory
  const onUpload = async (e) => {
    console.log("e", e);
    setImportFile(e?.files[0]);
  };

  const handleImport = async () => {
    setImportFileErrmsg(!importFile);
    if (importFile) {
      console.log("importFile", importFile);
      setIsLoading(true);

      const formData = new FormData();
      console.log("new FormData()", new FormData());
      console.log("formData", formData);
      formData.append("file", importFile);
      console.log("formData", formData.getAll("file"));

      await BulkImportVIPLoungeInventory(formData, cookies?.bearerToken)
        .then((res) => {
          console.log(res);
          if (res?.response?.status === 400) {
            toast.info(res?.response?.data);
            return;
          }
          if (res.status === 200) {
            toast.success("Imported successfully");
            setIsImport(false);
            setFileValidating(false);
            setImportFile(null);
          }
        })
        .catch((err) => {
          console.log(err);
          setFileValidating(false);
          if (err.response.status === 400) {
            toast.error(err.response.data.message);
          } else {
            toast.error("Something went wrong");
          }
        })
        .finally(() => {
          setAddLoading(false);
          setIsLoading(false);
        });
    } else {
      setAddLoading(false);
    }
    setSearchFilterValue(null);
    getInventoryList();
    getCategoryList();

  };

  const importHeader = () => {
    return (
      <div
        className="heading"
        style={{
          fontWeight: "700",
          fontSize: "23px",
          color: "#ae9d81",
          textAlign: "center",
        }}
      >
        <p style={{ margin: "0px" }}>Import pantry Details</p>
      </div>
    );
  };


  const exportPantryItems = () => {
    // exportDataInExcel(`ExportPantryItems?searchTerm=${searchFilterValue}&organizationId=${cookies.organizationID}`, cookies.bearerToken);
    const fromDate = !exportObj?.fromDate ? "" : `${(new Date(exportObj?.fromDate)?.getMonth() + 1)?.toString()?.padStart(2, "0")}/${new Date(exportObj?.fromDate)?.getDate()?.toString()?.padStart(2, "0")}/${new Date(exportObj?.fromDate)?.getFullYear()}`;
    const toDate = !exportObj?.toDate ? "" : `${(new Date(exportObj?.toDate)?.getMonth() + 1)?.toString()?.padStart(2, "0")}/${new Date(exportObj?.toDate)?.getDate()?.toString()?.padStart(2, "0")}/${new Date(exportObj?.toDate)?.getFullYear()}`;
    // let urlRoute = `ExportPantryItems?organizationId=${cookies?.organizationID|| "0"}&from=${fromDate}&to=${toDate}&categoryName=${exportObj?.category}`
    // exportExcel(urlRoute);
    let urlRoute = `VIPLounge/VIPPantryStaff/GetPantryItems?skip=0&take=${totalCount}&searchTerm=${searchFilterValue}&categoryName=${exportObj?.category}&fromDate=${fromDate}&toDate=${toDate}&forExport=true`;
    exportFilterdExcel(urlRoute, cookies?.bearerToken);
  };

  const getCategoryList = () => {
    GetPantryCategoryList(cookies?.bearerToken).then(res => {
      if (res?.status == 200) {
        setCategoryList(res?.data);
      }
    })
  }
  const getOrganizationList = () => {
    GetActiveOrganizationList(cookies?.bearerToken).then(res => {
      if (res?.status == 200) {
        setOrganizationList(res?.data);
      }
    })
  }


  const getPantryClosingReports = async () => {
    let urlRoute = `VIPLounge/VIPPantryStaff/GetPantryReport`;
    exportFilterdExcel(urlRoute, cookies?.bearerToken);
  }
  useEffect(() => {
    getInventoryList();
    getCategoryList();
    getOrganizationList();
  }, []);

  return (
    <>
      {!isLoading && (
        <Dialog
          header={importHeader}
          visible={isImport}
          style={{ width: "50vw" }}
          onHide={() => {
            setIsImport(false);
            setImportFileErrmsg(false);
          }}
        >
          <div className="popup ">
            <div className="body">
              <div className="col">
                <div className="label grid">
                  <div className="col">
                    Sample Template
                    <a
                      href={localAsset}
                      download
                      target="_blank"
                      style={{ margin: "0 10px" }}
                    >
                      <i
                        className="pi pi-download"
                        style={{ color: "slateblue", cursor: "pointer" }}
                        // on click i need to download a file
                        onClick={() => {
                          // window.open(Tenantcsv);
                        }}
                      ></i>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="label grid">
                  <div
                    className="col"
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    Select File<span style={{ color: "red" }}>*</span>{" "}
                    <Modalinfo />
                  </div>
                </div>
                <div className="input grid ">
                  <div
                    className="col"
                    style={{
                      display: "flex",
                      gap: "10px",
                      alignItems: "center",
                    }}
                  >
                    {/* <FileUpload mode="basic" name="demo[]" accept="*.csv" maxFileSize={1000000} onUpload={onUpload} chooseLabel="Browse" /> */}
                    <FileUpload
                      name="demo[]"
                      accept=".csv"
                      mode="basic"
                      value={importFile}
                      multiple={false}
                      ref={fileRef}
                      maxFileSize={1000000}
                      customUpload
                      uploadHandler={onUpload}
                      onSelect={(e) => {
                        console.log("e", e);
                        setImportFile(e?.files[0]);
                      }}
                      emptyTemplate={
                        <p className="m-0">
                          Drag and drop files to here to upload.
                        </p>
                      }
                    />
                    {importFile && (
                      <i
                        className="pi pi-times"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          fileRef.current.clear();
                          setImportFile(null);
                        }}
                      ></i>
                    )}
                  </div>
                </div>
                {importFileErrmsg && !importFile && (
                  <p
                    className="err-msg"
                    style={{ color: "red", margin: 0, paddingLeft: "5px" }}
                  >
                    File is required
                  </p>
                )}
                <div
                  className="p-col-12"
                  style={{
                    margin: "20px 0px 10px 0px",
                    display: "flex",
                    justifyContent: "center",
                    width: "100%",
                    gap: "10px",
                  }}
                >
                  <Button
                    label={"Submit"}
                    loading={addLoading}
                    style={{
                      backgroundColor: "#ae9d81",
                      border: "none",
                      width: "150px",
                    }}
                    onClick={() => {
                      setAddLoading(true);
                      handleImport();
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </Dialog>
      )}

      {!isLoading && (
        <Dialog
          header={
            <h3 style={{ textAlign: "center", color: "#ae9d81", margin: 0 }}>
              {addItem?.isEdit ? "Update" : "Add New"} Inventory Item
            </h3>
          }
          visible={isVisible}
          style={{ width: "40%" }}
          onHide={() => {
            setAddItem({ ...inintialAddItemDetails });
            setIsVisible(false);
          }}
        >
          <div className="p-grid inventory-grid-modal">
            <div className="input-box">
              <p>
                Name <span style={{ color: "red" }}>*</span>
              </p>
              <div className="p-inputgroup">
                <InputText
                  placeholder="Name"
                  value={addItem?.name}
                  onChange={(e) => {
                    setAddItem({ ...addItem, name: e.target.value });
                  }}
                />
              </div>
              {inValid && addItem?.name == "" && (
                <p style={{ color: "red" }}>Item name is required</p>
              )}
            </div>
            <div className="input-box">
              <p>
                Price <span style={{ color: "red" }}>*</span>
              </p>
              <div className="p-inputgroup">
                <InputText
                  placeholder="Price"
                  type="number"
                  min={0}
                  value={addItem?.price}
                  onChange={(e) => {
                    setAddItem({ ...addItem, price: e.target.value });
                  }}
                />
              </div>
              {inValid &&
                (parseInt(addItem?.price) <= 0 || addItem?.price == "") && (
                  <p style={{ color: "red" }}>Item price is required</p>
                )}
            </div>
            <div className="input-box">
              <p>
                Quantity <span style={{ color: "red" }}>*</span>
              </p>
              <div className="p-inputgroup">
                <InputText
                  placeholder="Quantity"
                  type="number"
                  min={0}
                  value={addItem?.quantity}
                  onChange={(e) => {
                    setAddItem({ ...addItem, quantity: e.target.value });
                  }}
                />
              </div>
              {inValid &&
                (parseInt(addItem?.quantity) <= 0 ||
                  addItem?.quantity == "") && (
                  <p style={{ color: "red" }}>Item quantity is required</p>
                )}
            </div>
            <div className="input-box">
              <p>
                Category <span style={{ color: "red" }}>*</span>
              </p>
              <div className="p-inputgroup">
                <Dropdown
                  value={addItem?.category}
                  optionLabel="name"
                  optionValue="name"
                  options={categoryList}
                  placeholder="Select category"
                  onChange={(e) => {
                    setAddItem((prev) => ({ ...prev, category: e.value }));
                  }}
                />

              </div>
              {inValid &&
                !addItem?.category && (
                  <p style={{ color: "red" }}>category is required</p>
                )}
            </div>
            <div
              className="p-col-12"
              style={{
                margin: "20px 0px 10px 0px",
                display: "flex",
                justifyContent: "center",
                width: "100%",
                gap: "10px",
              }}
            >
              <Button
                label="Cancel"
                disabled={addLoading}
                style={{
                  backgroundColor: "#8e8e8e",
                  border: "none",
                  width: "150px",
                }}
                onClick={() => {
                  setIsVisible(false);
                  setInvalid(false);
                  setAddItem({ ...inintialAddItemDetails });
                }}
              />
              <Button
                label={addLoading ? "" : addItem?.isEdit ? "Update" : "Submit"}
                loading={addLoading}
                style={{
                  backgroundColor: "#ae9d81",
                  border: "none",
                  width: "150px",
                }}
                onClick={() => {
                  if (addItem?.isEdit) {
                    handleUpdate();
                  } else {
                    handleAddItem();
                  }
                }}
              />
            </div>
          </div>
        </Dialog>
      )}
      <div className="Inventory">
        <div className="header">
          <div style={{ display: "flex" }}>
            <div className="menubtn" style={{ padding: "20px" }}>
              <i
                className="pi pi-align-justify"
                style={{
                  fontSize: "1rem",
                  color: "#ae9d81",
                  cursor: "pointer",
                }}
                onClick={() =>
                  dispatch(setNavVisibilty({ sideNavVisibility: true }))
                }
              ></i>
            </div>
            <div className="heading">
              <p>Inventory</p>
            </div>
          </div>
          <div className="mx-2 align-self-end">
            <Button
              label="Closing Report"
              icon={"pi pi-file-export"}
              style={{
                backgroundColor: "transparent",
                color: "#ae9d81",
                border: "1px #ae9d81 solid",
                borderRadius: "20px",
                cursor: "pointer",
                height: "46px"
              }}
              onClick={getPantryClosingReports}
            />
          </div>



        </div>

        <div className="flex align-items-center mb-3" style={{}}>
          <div className="mx-2">
            <h4 className='mb-1 mt-0 relative' style={{ color: "#ae9d81" }}>Category</h4>
            <Dropdown style={{ border: "1px solid #ae9d81", width: "150px" }} showClear value={exportObj?.category} options={categoryList} optionLabel='name' optionValue='name' onChange={(e) => { setExportObj((prev) => ({ ...prev, category: e.value })); getInventoryList(0, 15, searchFilterValue, e.value?.trim(), exportObj?.fromDate, exportObj?.toDate) }} placeholder='Select' />
          </div>
          <div className="mx-2">
            <h4 className='mb-1 mt-0 relative' style={{ color: "#ae9d81" }}>From Date</h4>
            <Calendar className="customeCalender" style={{ width: "150px" }} value={exportObj?.fromDate} placeholder='Select' onChange={(e) => {
              setExportObj((prev) => ({ ...prev, fromDate: e.value }));
              getInventoryList(0, 15, searchFilterValue, exportObj?.category, e.value, exportObj?.toDate)
            }} />
          </div>
          <div className="mx-2">
            <h4 className='mb-1 mt-0 relative' style={{ color: "#ae9d81" }}>To Date</h4>
            <Calendar className="customeCalender" style={{ width: "150px" }} value={exportObj?.toDate} placeholder='Select' onChange={(e) => {
              setExportObj((prev) => ({ ...prev, toDate: e.value }));
              getInventoryList(0, 15, searchFilterValue, e.value, exportObj?.fromDate, e.value)
            }} />
          </div>
          <div className="mx-2">
            <h4 className='mb-1 mt-0' style={{ color: "#ae9d81" }}>Search</h4>
            <div className='flex align-items-center' style={{ border: "solid 1px #AE9D81", borderRadius: "4px", height: "42px" }}>
              <i className="pi pi-search px-2" style={{ color: 'black', fontWeight: '700', fontSize: '1rem' }} />
              <InputText value={searchFilterValue} onChange={(e) => {
                setSearchFilterValue(e.target.value);
                getInventoryList(0, 15, e.target.value, exportObj?.category, exportObj?.fromDate, exportObj?.toDate);
              }} placeholder="Search" className='border-0 p-0' style={{ width: "140px" }} />
            </div>
            {/* <span className="p-input-icon-left">
                <i className="pi pi-search" style={{ color: 'black', fontWeight: '700', fontSize: '1rem', margin: '-5px 10px' }} />
                <InputText style={{
                  width: "150px",
                  paddingLeft: '30px'
                }}
                  placeholder="Search"
                  value={searchFilterValue}
                  onChange={(e) => {
                    setSearchFilterValue(e.target.value);
                    getInventoryList(0, 15, e.target.value,exportObj?.category,exportObj?.fromDate,exportObj?.toDate);
                  }}
                />
              </span> */}
          </div>
          <div className="mx-2 align-self-end">
            <Button
              label="Bulk edit"
              onClick={() => {
                navigate("/pantry-staff-inventory-bulk-edit");
              }}
              style={{ backgroundColor: "#ae9d81", border: "none" }}
            />
          </div>
          <div className="mx-2 align-self-end">
            <Button
              label="Add New Item"
              onClick={() => {
                setInvalid(false);
                setIsVisible(true);
              }}
              style={{ backgroundColor: "#ae9d81", border: "none" }}
            />
          </div>
          <div className="mx-2 align-self-end">
            <Button
              label="Clear All"
              onClick={() => {
                setExportObj((prev) => ({ ...prev, fromDate: "", category: "", toDate: "" }));
                getInventoryList(0, 15);
              }}
              style={{ backgroundColor: "#ae9d81", border: "none" }}
            />
          </div>
          <div className="mx-2 align-self-end">
            <Button
              label="Export"
              icon={"pi pi-file-export"}
              style={{
                backgroundColor: "transparent",
                color: "#ae9d81",
                border: "1px #ae9d81 solid",
                borderRadius: "20px",
                cursor: "pointer",
                height: "46px"
              }}
              onClick={() => {
                exportPantryItems();
                // totalCount > 0 ? 
                // setExportObj((prev) => ({ ...prev, isModalVisible: true, category: "", fromDate: "", searchTerm: "", toDate: "", organization: "" }))
                // : null
              }}
            />
          </div>
          <div className="mx-2 align-self-end">
            <Button
              label="Import"
              icon={"pi pi-file-import"}
              onClick={() => setIsImport(true)}
              style={{
                backgroundColor: "transparent",
                color: "#ae9d81",
                border: "1px #ae9d81 solid",
                borderRadius: "20px",
                height: "46px"
              }}
            />
          </div>
        </div>
        <div className="body">
          {/* Inventory to show list of items in inventory in card view */}
          {/* <div className="container" style={{ padding: "35px" }}>
            <div className="grid" style={{ display: "flex", gap: "10px" }}>
              {!isLoading ? (
                inventoryList?.map((item, index) => {
                  return (
                    <div
                      className=""
                      style={{
                        width: "200px",
                        padding: "10px",
                        height: "115px",
                        borderRadius: "5px",
                        boxShadow:
                          "rgba(0, 0, 0, 0.2) 0px 12px 28px 0px, rgba(0, 0, 0, 0.1) 0px 2px 4px 0px, rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset",
                        border:
                          (index + 1) % 2 == 0 ? "1px solid #ae9d81" : "none",
                      }}
                    >
                      
                      <div
                        className=""
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <p
                          style={{
                            padding: 0,
                            margin: 0,
                            color: "#00488F",
                            fontSize: "16px",
                            fontWeight: "600",
                          }}
                        >
                          {item?.name}{" "}
                        </p>
                        <img
                          src={editIcon}
                          onClick={() => {
                            setAddItem({
                              id: item?.id,
                              name: item?.name,
                              price: item?.amount,
                              quantity: item?.quanti>ty,
                              isEdit: true,
                            });
                            setIsVisible(true);
                          }}
                          style={{ cursor: "pointer" }}
                        />
                      </div>
                      <div
                        className=""
                        style={{
                          display: "flex",
                          padding: 0,
                          justifyContent: "space-between",
                          margin: "25px 0px 0px 0px",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            gap: "5px",
                            flexDirection: "column",
                          }}
                        >
                          <p
                            style={{
                              padding: 0,
                              margin: 0,
                              fontWeight: 400,
                              color: "#ae9d81",
                            }}
                          >
                            Price
                          </p>
                          <p style={{ padding: 0, margin: 0, fontWeight: 400 }}>
                            {item?.currencyISOCode} {item?.amount}
                          </p>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            gap: "5px",
                            flexDirection: "column",
                          }}
                        >
                          <p
                            style={{
                              padding: 0,
                              margin: 0,
                              fontWeight: 400,
                              color: "#ae9d81",
                            }}
                          >
                            Quantity
                          </p>
                          <p style={{ padding: 0, margin: 0, fontWeight: 400 }}>
                            {item?.quantity}
                          </p>
                        </div>
                      </div>
                    </div>
                  );
                })
              ) : (
                <div
                  className="spinner spinner-center"
                  style={{
                    display: "flex",
                    margin: "auto",
                    marginTop: "200px",
                  }}
                ></div>
              )}
            </div>
          </div> */}

          <div className="inventory-container">
            <div className="inventory-content">
              {!isLoading ? (
                inventoryList?.map((item, index) => {
                  return (
                    <div className="inventory-box">
                      {/* <div className='stock-indicator' style={{ background: item?.quantity == 0 ? "red" : "green" }}>
                                            </div> */}
                      <div className="inventory-details">
                        <div
                          className=""
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <p
                            style={{
                              padding: 0,
                              margin: 0,
                              color: "#00488F",
                              fontSize: "16px",
                              fontWeight: "600",
                            }}
                          >
                            {item?.name}{" "}
                          </p>
                          <img
                            src={editIcon}
                            onClick={() => {
                              setAddItem({
                                id: item?.id,
                                name: item?.name,
                                price: item?.amount,
                                quantity: item?.quantity,
                                isEdit: true,
                                category: item?.categoryName
                              });
                              setIsVisible(true);
                            }}
                            style={{
                              cursor: "pointer",
                              width: "16px",
                              height: "16px",
                            }}
                          />
                        </div>
                        <div
                          className=""
                          style={{
                            display: "flex",
                            padding: 0,
                            justifyContent: "space-between",
                            margin: "25px 0px 0px 0px",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              gap: "5px",
                              flexDirection: "column",
                            }}
                          >
                            <p
                              style={{
                                padding: 0,
                                margin: 0,
                                fontWeight: 400,
                                color: "#ae9d81",
                              }}
                            >
                              Price
                            </p>
                            <p
                              style={{
                                padding: 0,
                                margin: 0,
                                fontWeight: 400,
                                fontSize: "14px",
                              }}
                            >
                              {item?.currencyISOCode} {item?.amount}
                            </p>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              gap: "5px",
                              flexDirection: "column",
                              alignItems: "center",
                            }}
                          >
                            <p
                              style={{
                                padding: 0,
                                margin: 0,
                                fontWeight: 400,
                                color: "#ae9d81",
                              }}
                            >
                              Quantity
                            </p>
                            <p
                              style={{
                                padding: 0,
                                margin: 0,
                                fontWeight: 400,
                                fontSize: "14px",
                              }}
                            >
                              {item?.quantity}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })
              ) : (
                <div
                  className="spinner spinner-center"
                  style={{
                    display: "flex",
                    margin: "auto",
                    marginTop: "200px",
                  }}
                ></div>
              )}
            </div>
          </div>
          {inventoryList?.length == 0 || isLoading ? (
            ""
          ) : (
            <div style={{ justifyContent: "center", display: "flex" }}>
              <Paginator
                first={first}
                rows={rowper}
                totalRecords={totalCount}
                onPageChange={(e) => onPageChange(e, 1)}
                template={{
                  layout:
                    "RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink  LastPageLink CurrentPageReport",
                }}
                className="justify-content-start"
              />
            </div>
          )}
          {inventoryList?.length == 0 && !isLoading && (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <img src={nonFound}></img>
            </div>
          )}
        </div>
      </div>
      <Dialog visible={exportObj?.isModalVisible} header={`Export Pantry Details`} headerStyle={{ color: "#ae9d81", textAlign: "center" }} onHide={() => { setExportObj((prev) => ({ ...prev, isModalVisible: false })) }}>
        <div className='flex align-items-center flex-wrap'>

          <div className='mx-0 w-5'>
            <h4 className='mb-2'>Category</h4>
            <Dropdown className='w-full' value={exportObj?.category} options={categoryList} optionLabel='name' optionValue='id' onChange={(e) => { setExportObj((prev) => ({ ...prev, category: e.value })) }} placeholder='Select category' />
          </div>
          <div className='mx-0 w-5'>
            <h4 className='mb-2'>From Date</h4>
            <Calendar value={exportObj?.fromDate} placeholder='Select from date' onChange={(e) => {
              setExportObj((prev) => ({ ...prev, fromDate: e.value }));
            }} />
          </div>
        </div>
        <div className='flex align-items-center flex-wrap'>
          <div className='mx-0 w-5'>
            <h4 className='mb-2'>To Date</h4>
            <Calendar value={exportObj?.toDate} placeholder='Select to date' onChange={(e) => {
              setExportObj((prev) => ({ ...prev, toDate: e.value }));
            }} />
          </div>
          <div className='mx-0 w-5'>
            {/* <h4 className='mb-2'>From Date</h4>
            <Calendar value={exportObj?.fromDate} placeholder='Select from date' onChange={(e) => {
              setExportObj((prev) => ({ ...prev, fromDate: e.value }));
            }} /> */}
          </div>
        </div>


        <div className='flex justify-content-center my-4'>
          <Button className='border-0'
            style={{ backgroundColor: "#ae9d81", width: 180, height: 40, display: "flex", justifyContent: "center" }}
            onClick={() => { exportPantryItems() }}>
            Submit
          </Button>
        </div>
      </Dialog>
    </>
  );
}

export default Inventory;
