import React, { useEffect, useState, useRef } from "react";
import "./BagInformation.scss";
import { useDispatch, useSelector } from "react-redux";
import { useCookies } from "react-cookie";
import { delivery, stepperNames } from "../../Common/Config";
//prime react

import { InputText } from "primereact/inputtext";
import { Checkbox } from "primereact/checkbox";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { TabMenu } from "primereact/tabmenu";
import { RadioButton } from "primereact/radiobutton";
import { AutoComplete } from "primereact/autocomplete";

// api
import {
  GetActiveStores,
  GetActivePickupLocation,
  GetActiveTag,
  GetActiveDropLocation,
  GetDeliveryTypes,
  GetDeskLocation,
} from "../../../Services/ShopAndDropService";
import { toast } from "react-toastify";

const BagInformation = (props) => {
  // tab details

  const initialDropValue = {
    store: [],
    storeLocation: [],
    deskLocation: [],
    pickupLoacation: [],
    dropLocation: [],
    tags: [],
    deliveryTime: [{ time: "Same day" }, { time: "Next day" }],
  };

  const inintialBagInformationDetails = {
    store: null,
    reciptAmount: null || "",
    numberOfBags: 0,
  };

  // states

  const deliveryDetails = props.shopAndDropDeliveryDetails;
  const [cookies, setCookie, removeCookie] = useCookies();
  const [dropdownValues, setDropdownValues] = useState({ ...initialDropValue });

  const [isErrMsgView, setIsErrMsgView] = useState(false);
  const [isBagInfoErrMsg, setIsBagInfoErrMsg] = useState(false);
  const [time, setTime] = useState(null);

  const [bagInformationDetails, setBagInformationDetails] = useState({
    ...inintialBagInformationDetails,
  });
  const panelRef = useRef(null);

  const deliveryTypes = props.deliveryTypes;

  const dispatch = useDispatch();
  const stepperDetails = useSelector(
    (state) => state.AppContext.stepperDetails
  );

  const isHomeDelivery =
    deliveryDetails?.deliveryType?.label === delivery.homeDelivery
      ? true
      : false;


  const onClearData = () => {
    setBagInformationDetails({
      ...inintialBagInformationDetails,
    })
  }
  //action template

  const actionButtonTemplate = (row) => {
    return (
      <>
        <div style={{ display: "flex", paddingLeft: "15px" }}>
          <div
            onClick={(e) => {
              // setDetails{}
            }}
          >
            <i
              className="pi pi-times"
              onClick={() => handleRemoveStore(row.storeId)}
              style={{ fontSize: "1rem", cursor: "pointer", color: "red" }}
            />
          </div>
        </div>
      </>
    );
  };

  // remove store

  const handleRemoveStore = (storeId) => {
    let allStoreData = JSON.parse(
      JSON.stringify(deliveryDetails.bagInformations)
    );
    let removeIndex = [...allStoreData].findIndex(
      (val) => val.storeId == storeId
    );

    if (removeIndex !== -1) {
      allStoreData.splice(removeIndex, 1);
    }
    handleInputValue("bagInformations", allStoreData);
  };

  // input values

  const handleInputValue = (key, value) => {
    props.handleInputValue(key, value);
  };

  const handleBagInformationInputValue = (key, value) => {
    console.log(key, value, "key");
    setBagInformationDetails({ ...bagInformationDetails, [key]: value });
  };

  const handleTabMenuValue = (value) => {

    console.log(value.id);
    let tempDeliveryDetails = {
      ...deliveryDetails,
      deliveryType: value,
      deliveryLabel: value.type === "INSIDE_MALL" ? "INSIDE_MALL" : "TO_HOME",
      deskLocation: null,
      tenantLocation: null,
      dropLocation: null,
      pickupLocation: null,
      pickupTime: new Date(),
      deliveryTime: "",
      isSameAsGuestInfo: false,
      name: "",
      mobile: "",
      street: "",
      flat: "",
      city: "",
    };
    props.setShopAndDropDeliveryDetails({ ...tempDeliveryDetails });
  };

  const handleRadioBtnValue = (value) => {
    console.log("Radio button", deliveryDetails, value);
    let tempDeliveryDetails = {
      ...deliveryDetails,
      deliveryTab: value,
      deskLocation: null,
      tenantLocation: null,
    };
    console.log("tempDeliveryDetails", tempDeliveryDetails);
    props.setShopAndDropDeliveryDetails({ ...tempDeliveryDetails });
  };

  // continue

  const getAllData = async (skip, take, searchTerm, getFunction, dropKey) => {
    await getFunction(skip, take, searchTerm, cookies?.bearerToken)?.then(
      (res) => {
        if (res?.status == 200) {
          setDropdownValues((prev) => ({
            ...prev,
            [dropKey]: res?.data?.data,
          }));
        }
      }
    );
  };

  const getTotalNumber = (key) => {
    let total = 0;
    deliveryDetails.bagInformations.forEach((val) => {
      if (val && val[key]) {
        total += Number(val[key]);
      }
    });

    return total;
  };

  const searchItems = (event, getFunction, key) => {
    let query = event.query;
    getAllData(0, 100, query, getFunction, key);
    // setDropdownValues({ ...dropdownValues });
    // getAllStores(0, 100, query);
  };

  const getSelectedItemIndex = () => {

    let index = [...deliveryTypes].findIndex(
      (val) => val.label === deliveryDetails?.deliveryType?.label
    );
    console.log("Tab Index", index);
    if (index !== -1) {
      return index;
    } else {
      return 0;
    }
  };

  const handleSameAsGuestInfo = (checked) => {
    let tempDeliveryDetails = { ...deliveryDetails };
    tempDeliveryDetails.isSameAsGuestInfo = checked;
    tempDeliveryDetails.name = checked
      ? deliveryDetails.firstName + " " + deliveryDetails.lastName
      : "";
    tempDeliveryDetails.mobile = checked ? deliveryDetails.phoneNumber : "";

    props.setShopAndDropDeliveryDetails({ ...tempDeliveryDetails });
  };

  const handleAddList = () => {
    if (isNaN(bagInformationDetails?.reciptAmount)) {
      return
    }
    let isValidation =
      bagInformationDetails.store?.name &&
      bagInformationDetails.reciptAmount &&
      bagInformationDetails.numberOfBags;
    setIsBagInfoErrMsg(!isValidation);
    if (isValidation) {
      let tempDeliveryDetails = { ...deliveryDetails };
      let allStoreData = JSON.parse(
        JSON.stringify(deliveryDetails.bagInformations)
      );
      allStoreData.push({
        storeName: bagInformationDetails?.store?.name,
        storeId: bagInformationDetails?.store?.id,
        reciptAmount: bagInformationDetails?.reciptAmount,
        numberOfBags: bagInformationDetails?.numberOfBags,
      });
      tempDeliveryDetails.bagInformations = [...allStoreData];
      props.setShopAndDropDeliveryDetails({ ...tempDeliveryDetails });
      setBagInformationDetails({ ...inintialBagInformationDetails });
    }
  };

  const handleContinue = () => {
    let isValidation = true;
    if (isHomeDelivery) {
      isValidation =
        !deliveryDetails?.bagInformations?.length ||
        !deliveryDetails?.baggageTagNumber ||
        !deliveryDetails?.deliveryTime ||
        !deliveryDetails?.name ||
        !deliveryDetails?.mobile ||
        !deliveryDetails?.street ||
        !deliveryDetails?.flat ||
        !deliveryDetails?.city ||
        (deliveryDetails?.deliveryTab === "Tenant"
          ? !deliveryDetails?.tenantLocation
          : !deliveryDetails?.deskLocation);
    } else {
      isValidation =
        !deliveryDetails?.bagInformations.length ||
        !deliveryDetails?.baggageTagNumber ||
        !deliveryDetails?.pickupTime ||
        !deliveryDetails?.pickupLocation ||
        (deliveryDetails?.deliveryTab === "Tenant"
          ? !deliveryDetails?.tenantLocation
          : !deliveryDetails?.deskLocation);
    }

    if (!bagInformationDetails?.store && !bagInformationDetails?.reciptAmount && !bagInformationDetails?.numberOfBags) {

    }
    else if (!bagInformationDetails?.store || !bagInformationDetails?.reciptAmount || !bagInformationDetails?.numberOfBags) {
      toast.info("Please fill bag information");
      return
    }
    setIsErrMsgView(isValidation);
    if (!isValidation) {
      props.handleStepChange({
        stepLabel: stepperNames.checkIn,
        index: 2,
      });
    }
  };

  const getDeskData = async (id) => {
    await GetDeskLocation(id, cookies?.bearerToken)
      .then((res) => {
        // console.log("desk res", res);
        if (res?.status == 200) {
          setDropdownValues((prev) => ({
            ...prev,
            deskLocation: res?.data?.data,
          }));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    const element = document.getElementById("top");
    element.scrollIntoView();
    getAllData(0, 100, "", GetActiveStores, "store");
    getAllData(0, 100, "", GetActiveStores, "storeLocation");
    getAllData(0, 100, "", GetActivePickupLocation, "pickupLoacation");
    getAllData(0, 100, "", GetActiveTag, "tags");
    getAllData(0, 100, "", GetActiveDropLocation, "dropLocation");
    getDeskData(cookies?.organizationID);
  }, []);

  useEffect(() => {
    // if (panelRef?.current) {
    //   panelRef?.current?.props?.style?.maxHeight = "300px"; // Change this value to your desired height
    // }
    let tempDeliveryDetails = { ...deliveryDetails };
    tempDeliveryDetails.totalAmount = getTotalNumber("reciptAmount");
    tempDeliveryDetails.totalBags = getTotalNumber("numberOfBags");
    props.setShopAndDropDeliveryDetails({ ...tempDeliveryDetails });
  }, [deliveryDetails.bagInformations]);

  // console.log(deliveryDetails?.pickupTime,"deliveryDetails?.pickupTime");
  return (
    <>
      <div className="bag-information" id="top">
        <div className="bag-information-heading">
          <h2>Bag Information</h2>
        </div>
        <div className="bag-information-content">
          <div className="bag-product-information">
            <div className="bag-product-information-input" style={{ width: "100%" }}>
              <div className="input" style={{ width: "25%" }}>
                <div className="input-box">
                  <p>
                    Tenant Name
                    <span style={{ color: "red" }}>*</span>
                  </p>

                  <div className="test">
                    <AutoComplete
                      placeholder="Select here"
                      className="new-test"
                      value={bagInformationDetails?.store}
                      suggestions={dropdownValues.store}
                      completeMethod={(e) =>
                        searchItems(e, GetActiveStores, "store")
                      }
                      virtualScrollerOptions={{ itemSize: 38 }}
                      field="name"
                      dropdown
                      onChange={(e) => {
                        console.log(e, "eeee");
                        handleBagInformationInputValue("store", e.target.value);
                      }}
                    />
                  </div>
                  {isBagInfoErrMsg && !bagInformationDetails?.store && (
                    <p className="err-msg">Tenant is required</p>
                  )}
                </div>
              </div>
              <div className="input" style={{ width: "25%" }}>
                <div className="input-box">
                  <p>
                    Receipt amount
                    <span style={{ color: "red" }}>*</span>
                  </p>
                  <InputText
                    // type="number"
                    // min={0}
                    accept="number"
                    placeholder="Enter here"
                    value={
                      bagInformationDetails?.reciptAmount
                      // ? bagInformationDetails?.reciptAmount
                      // : ""
                    }
                    onChange={(e) => {
                      console.log(e?.target?.value);
                      if (e?.target?.value?.length < 8) {
                        handleBagInformationInputValue(
                          "reciptAmount",
                          e.target.value
                        );
                      }
                    }}
                  />
                  {isBagInfoErrMsg && !bagInformationDetails?.reciptAmount && (
                    <p className="err-msg">Receipt amount is required</p>
                  )}
                  {bagInformationDetails?.reciptAmount && isNaN(bagInformationDetails?.reciptAmount) && (
                    <p className="err-msg">Receipt amount is invalid</p>
                  )}
                </div>
              </div>
              <div className="input" style={{ width: "25%" }}>
                <div className="input-box">
                  <p>
                    Number of bags
                    <span style={{ color: "red" }}>*</span>
                  </p>

                  <div className="input-btn">
                    <div
                      className="input-btn-action"
                      onClick={() => {
                        if (bagInformationDetails.numberOfBags >= 1) {
                          handleBagInformationInputValue(
                            "numberOfBags",
                            bagInformationDetails.numberOfBags - 1
                          );
                        }
                      }}
                    >
                      <span>-</span>
                    </div>
                    <div>{bagInformationDetails?.numberOfBags}</div>
                    <div
                      className="input-btn-action"
                      onClick={() =>
                        handleBagInformationInputValue(
                          "numberOfBags",
                          bagInformationDetails.numberOfBags + 1
                        )
                      }
                    >
                      <span>+</span>
                    </div>
                  </div>
                  {isBagInfoErrMsg && !bagInformationDetails?.numberOfBags && (
                    <p className="err-msg">Number of bags is required</p>
                  )}
                </div>
              </div>
              <div className="bag-product-information-btn" style={{ display: "flex", alignItems: "baseline", width: "25%" }}>
                <Button
                  label="Add to list"
                  style={{
                    backgroundColor: "#ae9d81",
                    border: "none",
                    borderRadius: "3px",
                    width: "120px",
                    marginLeft: "10px",
                    height: "40px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "35px",
                  }}
                  onClick={() => {
                    handleAddList();
                  }}
                />

                <Button
                  label="Clear"
                  style={{
                    backgroundColor: "#ccc",
                    border: "none",
                    borderRadius: "3px",
                    width: "120px",
                    marginLeft: "10px",
                    height: "40px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "35px",
                  }}
                  onClick={() => {
                    // handleAddList();
                    onClearData()
                  }}
                />
              </div>

              {/* <div className="">
                <Button
                  label="Clear"
                  style={{
                    backgroundColor: "#ccc",
                    border: "none",
                    borderRadius: "3px",
                    width: "120px",
                    marginLeft: "10px",
                    height: "40px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "35px",
                  }}
                  onClick={() => {
                    handleAddList();
                  }}
                />
              </div> */}

            </div>
          </div>
          <div className="bag-list">
            <div className="bag-list-table">
              <DataTable
                value={deliveryDetails?.bagInformations}
                tableStyle={{ width: "100%" }}
                stripedRows
                rows={10}
                emptyMessage={<></>}
              >
                <Column
                  field="storeName"
                  header="Tenant name"
                  style={{ width: "30%" }}
                ></Column>
                <Column
                  field="reciptAmount"
                  header="Receipt amount"
                  style={{ width: "30%" }}
                ></Column>
                <Column
                  field="numberOfBags"
                  header="Number of bags"
                  style={{ width: "30%" }}
                ></Column>
                <Column
                  field="nationality"
                  header="Action"
                  body={actionButtonTemplate}
                  style={{ width: "10%" }}
                ></Column>
              </DataTable>
              {isErrMsgView && !deliveryDetails?.bagInformations?.length && (
                <div
                  style={{
                    width: "100%",
                    height: "210px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  Bag is empty
                </div>
              )}
            </div>
            <div className="bag-list-amount">
              <div>
                <span style={{ color: "#ae9d81", fontWeight: 700 }}>
                  Total amount :{" "}
                </span>
                <span>{deliveryDetails?.totalAmount}</span>
              </div>
              <div>
                <span style={{ color: "#ae9d81", fontWeight: 700 }}>
                  Total bags :{" "}
                </span>
                <span>{deliveryDetails?.totalBags}</span>
              </div>
            </div>
          </div>
          <div className="bag-available">
            <div className="input">
              <div
                className="input-box"
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                  height: "100%",
                }}
              >
                <p>
                  Available tags :
                  {/* <span style={{ color: "red" }}>*</span> */}
                </p>
                <p style={{ color: "red" }}>{dropdownValues.tags.length}</p>
              </div>
            </div>
            <div className="input">
              <div className="input-box">
                <p>
                  Baggage tag
                  <span style={{ color: "red" }}>*</span>
                </p>

                <AutoComplete
                  placeholder="Select here"
                  className="bagg-tag-number"
                  value={deliveryDetails?.baggageTagNumber}
                  suggestions={dropdownValues.tags}
                  completeMethod={(e) => searchItems(e, GetActiveTag, "tags")}
                  virtualScrollerOptions={{ itemSize: 38 }}
                  field="name"
                  dropdown
                  onChange={(e) => {
                    handleInputValue("baggageTagNumber", e.target.value);
                  }}
                />
                {isErrMsgView && !deliveryDetails?.baggageTagNumber && (
                  <p className="err-msg">Baggage tag is required</p>
                )}
              </div>
            </div>
          </div>
          <div className="bag-tab">
            <TabMenu
              model={deliveryTypes}
              activeIndex={getSelectedItemIndex()}
              optionLabel="type"
              onTabChange={(e) => {
                console.log(e.value);
                handleTabMenuValue(e.value);
              }}
            />
          </div>
          <div className="card" style={{ margin: "10px" }}>
            <p>
              Check-in Type
              <span style={{ color: "red" }}>*</span>
            </p>
            <div
              className="gap-3"
              style={{ display: "flex", marginTop: "10px" }}
            >
              <div className="flex align-items-center">
                <RadioButton
                  inputId="selectDesk"
                  value="Desk"
                  onChange={(e) => {
                    handleRadioBtnValue(e.target.value);
                  }}
                  checked={deliveryDetails?.deliveryTab === "Desk"}
                />
                <label htmlFor="selectDesk" className="ml-2">
                  Desk
                </label>
              </div>
              <div className="flex align-items-center">
                <RadioButton
                  inputId="selectTenant"
                  value="Tenant"
                  onChange={(e) => {
                    handleRadioBtnValue(e.target.value);
                  }}
                  checked={deliveryDetails?.deliveryTab === "Tenant"}
                />
                <label htmlFor="selectTenant" className="ml-2">
                  Tenant
                </label>
              </div>
            </div>
          </div>
          <div className="bag-delivery-details">
            {isHomeDelivery && (
              <>
                {" "}
                <div className="input">
                  <div
                    className="input-box"
                    style={{
                      display: "flex",
                      gap: "10px",
                      height: "100%",
                      alignItems: "center",
                    }}
                  >
                    <Checkbox
                      inputId="guestInfo"
                      checked={deliveryDetails?.isSameAsGuestInfo}
                      onChange={(e) => {
                        handleSameAsGuestInfo(e.target.checked);
                      }}
                    />
                    <label style={{ cursor: "pointer" }} htmlFor="guestInfo">
                      Same as guest info
                    </label>
                  </div>
                </div>
              </>
            )}
            {deliveryDetails?.deliveryTab === "Desk" ? (
              <div className="input">
                <div className="input-box input-auto-complete">
                  <p>
                    Desk location
                    <span style={{ color: "red" }}>*</span>
                  </p>
                  <Dropdown
                    value={deliveryDetails?.deskLocation}
                    onChange={(e) =>
                      handleInputValue("deskLocation", e.target.value)
                    }
                    options={dropdownValues.deskLocation}
                    optionLabel="name"
                    placeholder="Select here"
                  />

                  {/* <AutoComplete
                  placeholder="Select here"
                  value={deliveryDetails?.deskLocation}
                  suggestions={dropdownValues.deskLocation}
                  completeMethod={(e) =>
                    searchItems(e, GetActiveDropLocation, "dropLocation")
                  }
                  virtualScrollerOptions={{ itemSize: 38 }}
                  field="name"
                  dropdown
                  ref={panelRef}
                  onChange={(e) => {
                    handleInputValue("dropLocation", e.target.value);
                  }}
                /> */}
                  {isErrMsgView && !deliveryDetails?.deskLocation && (
                    <p className="err-msg">Desk location is required</p>
                  )}
                </div>
              </div>
            ) : (
              <div className="input">
                <div className="input-box">
                  <p>
                    Tenant location
                    <span style={{ color: "red" }}>*</span>
                  </p>

                  <div className="test">
                    <AutoComplete
                      placeholder="Select here"
                      className="new-test"
                      value={deliveryDetails?.tenantLocation}
                      suggestions={dropdownValues.storeLocation}
                      completeMethod={(e) =>
                        searchItems(e, GetActiveStores, "storeLocation")
                      }
                      virtualScrollerOptions={{ itemSize: 38 }}
                      field="name"
                      dropdown
                      onChange={(e) => {
                        handleInputValue("tenantLocation", e.target.value);
                      }}
                    />
                  </div>
                  {isErrMsgView && !deliveryDetails?.tenantLocation && (
                    <p className="err-msg">Tenant location is required</p>
                  )}
                </div>
              </div>
            )}
            {isHomeDelivery && (
              <div className="input">
                <div className="input-box">
                  <p>
                    Delivery time<span style={{ color: "red" }}>*</span>
                  </p>
                  <Dropdown
                    value={deliveryDetails?.deliveryTime}
                    onChange={(e) =>
                      handleInputValue("deliveryTime", e.target.value)
                    }
                    options={dropdownValues.deliveryTime}
                    optionLabel="time"
                    placeholder="Select here"
                  />
                  {isErrMsgView &&
                    isHomeDelivery &&
                    !deliveryDetails?.deliveryTime && (
                      <p className="err-msg">Delivery time is required</p>
                    )}
                </div>
              </div>
            )}
            {!isHomeDelivery && (
              <div className="input">
                <div className="input-box">
                  <p>
                    Pickup location
                    <span style={{ color: "red" }}>*</span>
                  </p>

                  <AutoComplete
                    placeholder="Select here"
                    value={deliveryDetails?.pickupLocation}
                    suggestions={dropdownValues.pickupLoacation}
                    completeMethod={(e) =>
                      searchItems(e, GetActivePickupLocation, "pickupLoacation")
                    }
                    virtualScrollerOptions={{ itemSize: 38 }}
                    field="name"
                    dropdown
                    onChange={(e) => {
                      handleInputValue("pickupLocation", e.target.value);
                    }}
                  />
                  {isErrMsgView &&
                    !isHomeDelivery &&
                    !deliveryDetails?.pickupLocation && (
                      <p className="err-msg">Pickup location is required</p>
                    )}
                </div>
              </div>
            )}
            {!isHomeDelivery && (
              <div className="input">
                <div className="input-box">
                  <p>
                    Pickup Time
                    <span style={{ color: "red" }}>*</span>
                  </p>
                  <Calendar
                    value={deliveryDetails?.pickupTime}
                    onChange={(e) => {
                      handleInputValue("pickupTime", e.value);
                    }}
                    showIcon
                    timeOnly
                    showTime hourFormat="12"
                    minDate={new Date()}
                    icon={() => <i className="pi pi-clock" />}
                  />
                  {isErrMsgView &&
                    !isHomeDelivery &&
                    !deliveryDetails?.pickupTime && (
                      <p className="err-msg">Pickup time is required</p>
                    )}
                </div>
              </div>
            )}
            {isHomeDelivery && (
              <>
                <div className="input">
                  <div className="input-box">
                    <p>
                      Name
                      <span style={{ color: "red" }}>*</span>
                    </p>
                    <InputText
                      placeholder="Enter here"
                      disabled={deliveryDetails?.isSameAsGuestInfo}
                      value={deliveryDetails?.name}
                      onChange={(e) => {
                        if (e.target.value.length <= 30) {
                          handleInputValue("name", e.target.value);
                        }
                      }}
                    />
                    {isErrMsgView &&
                      isHomeDelivery &&
                      !deliveryDetails?.name && (
                        <p className="err-msg">Name is required</p>
                      )}
                  </div>
                </div>{" "}
                <div className="input">
                  <div className="input-box">
                    <p>
                      Mobile number
                      <span style={{ color: "red" }}>*</span>
                    </p>
                    <InputText
                      type="number"
                      placeholder="Enter here"
                      disabled={deliveryDetails?.isSameAsGuestInfo}
                      value={deliveryDetails?.mobile}
                      onChange={(e) => {
                        if (e.target.value.length <= 12) {
                          handleInputValue("mobile", e.target.value);
                        }
                      }}
                    />
                    {isErrMsgView &&
                      isHomeDelivery &&
                      !deliveryDetails?.mobile && (
                        <p className="err-msg">Mobile number is required</p>
                      )}
                  </div>
                </div>
                <div className="input">
                  <div className="input-box">
                    <p>
                      Street / Area
                      <span style={{ color: "red" }}>*</span>
                    </p>
                    <InputText
                      placeholder="Enter here"
                      value={deliveryDetails?.street}
                      onChange={(e) => {
                        if (e.target.value.length <= 30) {
                          handleInputValue("street", e.target.value);
                        }
                      }}
                    />
                    {isErrMsgView &&
                      isHomeDelivery &&
                      !deliveryDetails?.street && (
                        <p className="err-msg">Street / Area is required</p>
                      )}
                  </div>
                </div>
                <div className="input">
                  <div className="input-box">
                    <p>
                      Building / Flat
                      <span style={{ color: "red" }}>*</span>
                    </p>
                    <InputText
                      placeholder="Enter here"
                      value={deliveryDetails?.flat}
                      onChange={(e) => {
                        if (e.target.value.length <= 30) {
                          handleInputValue("flat", e.target.value);
                        }
                      }}
                    />
                    {isErrMsgView &&
                      isHomeDelivery &&
                      !deliveryDetails?.flat && (
                        <p className="err-msg">Building / Flat is required</p>
                      )}
                  </div>
                </div>
                <div className="input">
                  <div className="input-box">
                    <p>
                      City
                      <span style={{ color: "red" }}>*</span>
                    </p>
                    <InputText
                      placeholder="Enter here"
                      value={deliveryDetails?.city}
                      onChange={(e) => {
                        if (e.target.value.length <= 30) {
                          handleInputValue("city", e.target.value);
                        }
                      }}
                    />
                    {isErrMsgView &&
                      isHomeDelivery &&
                      !deliveryDetails?.city && (
                        <p className="err-msg">City is required</p>
                      )}
                  </div>
                </div>
              </>
            )}
          </div>
          <div className="bag-btn">
            <Button
              label="Previous"
              style={{
                backgroundColor: "#8e8e8e",
                border: "none",
                borderRadius: "3px",
                width: "120px",
                height: "40px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginBottom: "10px",
              }}
              onClick={() => {
                props.handleStepChange({
                  stepLabel: stepperNames.guestInformation,
                  index: 0,
                });
              }}
            />
            <Button
              label="Next"
              style={{
                backgroundColor: "#ae9d81",
                border: "none",
                borderRadius: "3px",
                width: "120px",
                height: "40px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginBottom: "10px",
              }}
              onClick={() => {
                handleContinue();
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default BagInformation;
