import React from 'react';
import { configureStore } from '@reduxjs/toolkit';
// import { persistStore, persistReducer } from "redux-persist";
import AppSlice from './AppSlice';


const Store = configureStore({
    reducer: {
        AppContext: AppSlice,
    },
})
// const Persistor = persistStore(Store);
export default Store