import React, { useState } from "react";
import "./UserApproval.scss";
import { useDispatch } from "react-redux";
import { useCookies } from "react-cookie";
import { setNavVisibilty } from "../../../../Redux/AppSlice";
import { useEffect } from "react";
import { toast } from "react-toastify";

// Prime React Components
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Paginator } from "primereact/paginator";
import { Dialog } from "primereact/dialog";
import { useNavigate } from "react-router-dom";
import { Badge } from "primereact/badge";
import editIcon from "../../../../assets/images/editIcon.jpg";
import {
  ApproveInvoice,
  Dummy,
  DummyPost,
  GetVIPUser,
  GetVIPUserList,
  RegisterVIPUser,
  RejectInvoice,
} from "../../../../Services/VIPLoungeSupervisor";

const userApproveData = [
  {
    name: "Deepak",
    vipCode: "VIP000008",
    email: "deepak18@gmail.com",
    status: "Active",
    mobileNo: 9629474374,
  },
  {
    name: "jeeva",
    vipCode: "VIP000007",
    email: "jeevatest@gmail.com",
    status: "Active",
    mobileNo: 9629474374,
  },
];

function UserApproval() {
  console.log("approval");
  const [cookies, setCookie, removeCookie] = useCookies();
  // states
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  // Pagination
  const [first, setFirst] = useState(0);
  const [totalCount, setTotalCount] = useState(0);

  // Search && Filter
  const [searchFilterValue, setSearchFilterValue] = useState("");
  const [vipUserList, setVIPUserList] = useState([]);

  //naviget
  const navigate = useNavigate();
  const actionButtonTemplate = (row) => {
    return (
      <>
        <div style={{ display: "flex", gap: "15px", justifyContent: "center" }}>
          {row.approvalStatus === "Approved" ? (
            <i
              className="pi pi-eye"
              style={{ fontSize: "1rem", cursor: "pointer", color: "blue" }}
              onClick={() => {
                navigate(`/view-vip-user-details/${row?.vipCode}/${true}`);
              }}
            />
          ) : (
            <i
              className="pi pi-pencil"
              style={{ fontSize: "1rem", cursor: "pointer" }}
              onClick={() => {
                navigate(`/view-vip-user-details/${row?.vipCode}/${true}`);
              }}
            />
            // <img
            //   src={editIcon}
            //   style={{ cursor: "pointer", marginLeft: "10px" }}
            //   onClick={() => {
            //     navigate(`/view-vip-user-details/${row?.vipCode}/${true}`);
            //   }}
            // />
          )}
        </div>
      </>
    );
  };

  const statusTemplate = (row) => {
    if (row.status == "Active") {
      return <p style={{ color: "#45AA08" }}>Active</p>;
    } else {
      return <p style={{ color: "#FF4700" }}>Expired</p>;
    }
  };

  const getVIPUserLists = async (
    skip = 0,
    take = 10,
    searchFilterValue = ""
  ) => {
    setIsLoading(true);
    await GetVIPUserList(skip, take, searchFilterValue, cookies.bearerToken)
      .then((res) => {
        console.log("Response", res);
        if (res?.status === 200) {
          setVIPUserList(res?.data?.data);
          setTotalCount(res?.data?.totalRecords);
        }
      })
      .catch((error) => {
        toast.error(
          "There is issue occured in fetching user, could you please try again",
          { position: "top-right" }
        );
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getVIPUserLists();
  }, []);

  const onPageChange = (event) => {
    setFirst(event.first);
    getVIPUserLists(event.first, 10, searchFilterValue);
  };

  const statusBodyTemplate = (row) => {
    // //console.log(row);
    if (row.status == "Active") {
      return <p style={{ color: "#45AA08" }}>Active</p>;
    } else {
      return <p style={{ color: "#FF4700" }}>Expired</p>;
    }
  };
  const getStatusSeverity = (row) => {
    let statusSeverity = "";
    if (row.approvalStatus === "Pending") {
      statusSeverity = "warning";
    } else if (row.approvalStatus === "Approved") {
      statusSeverity = "success";
    }
    return statusSeverity;
  };
  const onSearch=(value)=>{
    getVIPUserLists(0,10,value?value:"");
  }
  const approveStatusBodyTemplate = (row) => {
    // //console.log(row);
    return (
      <div className="delivery-status">
        <Badge value={row.approvalStatus} severity={getStatusSeverity(row)} />
      </div>
    );
    // if (row.approvalStatus == "Approved") {
    //   return <p style={{ color: "#45AA08" }}>Active</p>;
    // } else {
    //   return <p style={{ color: "#FF4700" }}>Expired</p>;
    // }
  };

  return (
    <div className="user-approval">
      <div className="header">
        <div style={{ display: "flex" }}>
          <div className="menubtn" style={{ padding: "20px" }}>
            <i
              className="pi pi-align-justify"
              style={{ fontSize: "1rem", color: "#ae9d81", cursor: "pointer" }}
              onClick={() =>
                dispatch(setNavVisibilty({ sideNavVisibility: true }))
              }
            ></i>
          </div>
          <div className="heading">
            <p>User Approvals Details</p>
          </div>
        </div>

        <div className="mx-2">
             <h4 className='mb-1 mt-0' style={{color:"#ae9d81"}}>Search</h4>
            <div className='flex align-items-center' style={{border:"solid 1px #AE9D81",borderRadius:"4px"}}>
                        <i className="pi pi-search px-2" style={{ color: 'black', fontWeight: '700', fontSize: '1rem' }} />
                        <InputText value={searchFilterValue}
                          style={{width:"170px"}}
                          onChange={(e)=>  {
                          setSearchFilterValue(e.target.value.trimStart());
                          onSearch(e.target.value.trimStart());
                        }}
                          placeholder="Search" className='border-0 customeSearch'/>
                        {searchFilterValue !== "" && (
                        <i className="pi pi-times px-2" style={{ color: 'black', fontWeight: '400', fontSize: '1rem', cursor: 'pointer',position:"block" }}  onClick={() => { 
                           setSearchFilterValue("");
                           onSearch("");
                          }} />
                        )}
            </div>
        </div>
        {/* <div className="right" style={{ paddingRight: "10px" }}>
          <div>
            <span className="p-input-icon-left">
              <i
                className="pi pi-search"
                style={{ color: "black", fontWeight: "700", fontSize: "1rem" }}
              />
              <InputText
                placeholder="Search"
                value={searchFilterValue}
                onChange={(e) => {
                  setSearchFilterValue(e.target.value.trimStart());
                 onSearch(e.target.value.trimStart())
                }}
              />
              {searchFilterValue !== "" && (
                <i
                  className="pi pi-times"
                  style={{
                    color: "black",
                    fontWeight: "400",
                    fontSize: "1rem",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setSearchFilterValue("");
                    onSearch("")
                  }}
                />
              )}
            </span>
          </div>
        </div> */}
      </div>

      <div className="datatable">
        {isLoading ? (
          <div
            className="spinner spinner-center"
            style={{ display: "flex", margin: "auto", marginTop: "200px" }}
          ></div>
        ) : (
          // <DataTable
          //   value={userApproveData}
          //   style={{ boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)" }}
          //   tableStyle={{ width: "90%", margin: "auto" }}
          //   stripedRows
          //   rows={10}
          //   emptyMessage={
          //     <>
          //       <p style={{ textAlign: "center" }}>List is empty</p>
          //     </>
          //   }
          // >
          //   <Column
          //     field="name"
          //     header="Name"
          //     style={{ width: "23.75%" }}
          //   ></Column>
          //   <Column
          //     field="email"
          //     header="Email"
          //     style={{ width: "23.75%" }}
          //   ></Column>
          //   <Column
          //     field="mobileNo"
          //     header="Mobile Number"
          //     style={{ width: "23.75%" }}
          //   ></Column>
          //   <Column
          //     field="status"
          //     header="Status"
          //     body={statusTemplate}
          //     style={{ width: "23.75%" }}
          //   ></Column>
          //   <Column
          //     field="nationality"
          //     header="Action"
          //     body={actionButtonTemplate}
          //     style={{ width: "5%" }}
          //   ></Column>
          // </DataTable>
          <DataTable
            value={vipUserList}
            style={{ boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)" }}
            tableStyle={{ width: "90rem", margin: "10px 39px" }}
            stripedRows
            rows={10}
            emptyMessage={
              <>
                <p style={{ textAlign: "center" }}>List is empty</p>
              </>
            }
          >
            <Column field="vipCode" header="VIP Code"></Column>
            <Column field="fullName" header="Name"></Column>
            <Column field="emailAddress" header="Email ID"></Column>
            {/* <Column field="companyName" header="Company"></Column> */}
            {/* <Column field="designation" header="Designation"></Column> */}
            <Column field="dateOfRegistration" header="Created Date"></Column>
            {/* <Column
              field="status"
              header="Status"
              body={statusBodyTemplate}
            ></Column> */}
            <Column
              field="approvalStatus"
              header="Approval Status"
              body={approveStatusBodyTemplate}
            ></Column>
            <Column
              field="nationality"
              header="Action"
              body={actionButtonTemplate}
            ></Column>
          </DataTable>
        )}
      </div>
      {isLoading ? (
        ""
      ) : (
        (totalCount>0&&<div style={{ justifyContent: "center", display: "flex" }}>
          <Paginator
            first={first}
            rows={10}
            totalRecords={totalCount}
            onPageChange={(e) => onPageChange(e, 1)}
            template={{
              layout:
                "RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink  LastPageLink CurrentPageReport",
            }}
            className="justify-content-start"
          />
        </div>)
      )}
    </div>
  );
}

export default UserApproval;
