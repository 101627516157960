import React, { useState, useEffect } from "react";
import "./Purchase.scss";
import { setNavVisibilty } from "../../../../Redux/AppSlice";
import { useCookies } from "react-cookie";
import { useDispatch } from "react-redux";
// Prime React Components
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Paginator } from "primereact/paginator";
import { Dialog } from "primereact/dialog";
import {
  AddInventoryItem,
  CheckOutVipUser,
  GetPantryItems,
  GetPantryPurchaseItems,
  GetVipUsers,
  PantryItemsCheckout,
  FindVipUser,
} from "../../../../Services/VIPLoungePantryStaff";
import { toast } from "react-toastify";
import { Dropdown } from "primereact/dropdown";
import { MultiSelect } from "primereact/multiselect";
import { Card } from "primereact/card";
import { IoTrashOutline } from "react-icons/io5";
import success from "../../../../assets/images/success.png";
import { useNavigate } from "react-router-dom";
import { AutoComplete } from "primereact/autocomplete";
import { GetPantryCategoryList } from "../../../../Services/PantryCategoryService";
import { InputTextarea } from "primereact/inputtextarea";

// ------------------------------
function Purchase() {
  // local variable

  const checkOutDataInitialValue = {
    name: "",
    emailAddress: "",
    mobileNo: null,
    vipCode: null,
    vipSearchValue: "",
  };

  // states
  const [cookies, setCookie, removeCookie] = useCookies();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  // Search && Filter
  const [searchFilterValue, setSearchFilterValue] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [inventoryList, setInventoryList] = useState([]);
  const [cartItems, setCartItems] = useState([]);
  const [cartItemsSelect, setCartItemsSelect] = useState([]);
  const [isLoader, setIsLoader] = useState(false);

  // Checkout
  const [isCheckout, setCheckout] = useState(false);
  const [invalid, setInvalid] = useState(false);
  const [isErrMsgView, setIsErrMsgView] = useState(false);
  const [isLengthErrMsgView, setIsLengthErrMsgView] = useState(false);
  const [vipUsers, setVipUsers] = useState([]);
  const [checkoutData, setCheckoutData] = useState({
    ...checkOutDataInitialValue,
  });
  const [isConfirmationModalOpen, setIsConformationModalOpen] = useState(false);
  const [isValidErrmsgView, setIsValidErrmsgView] = useState(false);
  const [categoryList, setCategoryList] = useState([]);
  const getPurchaseItems = async (skip = 0, take = 10, searchTerm = "", category = "") => {
    setIsLoader(true);
    setIsLoading(true);
    await GetPantryPurchaseItems(skip, take, searchTerm, category, cookies.bearerToken)
      .then((res) => {
        console.log("res", res);
        setInventoryList(res?.data?.data);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsLoader(false);
        setIsLoading(false);
      });
  };

  // Add to cart
  const addToCart = (value) => {
    console.log(value);
    // if (value.length == 0) {
    //   setCartItems([]);
    // }
    setCartItemsSelect([{ ...value }]);
    let cartItem = [...cartItems];
    // value?.forEach((element) => {
    let filteredItemIndex = cartItems?.findIndex((x) => x.id === value.id);
    if (filteredItemIndex !== -1) {
      if (
        cartItem[filteredItemIndex].availableQuantity >
        cartItem[filteredItemIndex].quantity
      ) {
        cartItem[filteredItemIndex].quantity += 1;
      }
    } else {
      cartItem.push({
        id: value.id,
        name: value.name,
        quantity: 1,
        amount: value.amount,
        availableQuantity: value?.quantity ? Number(value.quantity) : 0,
      });
    }
    // });
    setCartItems([...cartItem]);
  };

  // Quantity decrease
  const decreaseQuantity = (item) => {
    let tempCartItems = [...cartItems];
    let cartItemIndex = tempCartItems.findIndex((x) => x.id === item.id);

    // if (tempCartItems[cartItemIndex]?.quantity > 1) {
    //   tempCartItems[cartItemIndex].quantity--;
    // } else {
    //   tempCartItems.splice(cartItemIndex, 1);
    // }

    if (cartItemIndex !== -1) {
      tempCartItems[cartItemIndex].quantity--;
    }
    setCartItems([...tempCartItems]);
  };
  // Increase quantity
  const increaseQuantity = (item) => {
    let cartItem = cartItems.find((x) => x.id === item.id);
    cartItem.quantity++;
    setCartItems([...cartItems]);
  };
  // Remove from cart
  const removeFromCart = (item) => {
    let newCartItems = [...cartItems].filter((x) => x.id !== item.id);

    setCartItems(newCartItems);
    setCartItemsSelect(newCartItems);
  };
  // Checkout
  // const checkout = async () => {
  //   if (cartItems.length == 0) {
  //     toast.error("Cart is empty");
  //     return;
  //   }
  //   if (
  //     checkoutData?.name == "" ||
  //     checkoutData?.emailAddress == "" ||
  //     checkoutData?.mobileNo == ""
  //   ) {
  //     toast.error("Please enter name");
  //     setInvalid(true);
  //     return;
  //   }
  //   setInvalid(false);
  //   let data = {
  //     pantryItemsInformation: [
  //       ...cartItems.map((item) => {
  //         return {
  //           itemId: item.id,
  //           checkoutCount: item.quantity,
  //         };
  //       }),
  //     ],
  //     purchasedCustomerInformation: {
  //       name: checkoutData?.name,
  //       emailAddress: checkoutData?.emailAddress,
  //       mobileNo: checkoutData?.mobileNo,
  //     },
  //     totalItemsCount: cartItems?.length,
  //     totalAmount: cartItems.reduce(
  //       (acc, item) => acc + parseInt(item.amount) * parseInt(item.quantity),
  //       0
  //     ),
  //   };
  //   await PantryItemsCheckout(data, cookies.bearerToken)
  //     .then((res) => {
  //       if (res?.status === 200) {
  //         toast.success("Checkout Successfully");
  //         setCartItems([]);
  //         setCartItemsSelect([]);
  //         setCheckoutData(checkOutDataInitialValue);
  //         setCheckout(true);
  //       }
  //     })
  //     .catch(() => {
  //       toast.error("Checkout Failed");
  //     })
  //     .finally(() => {});
  // };
  // const successModal = () => {
  //   return (
  //     <Dialog
  //       visible={isCheckout}
  //       headerStyle={{ display: "none" }}
  //       closeIcon={false}
  //       icons={false}
  //       style={{ width: "30%" }}
  //     >
  //       <div className="text-center p-3 mt-3">
  //         <img src={success} alt="not found" width={"80px"} height={"80px"} />
  //         <h2 className="my-3">Successfully</h2>
  //         <p className="my-3">Your order has been placed</p>
  //         <div className="flex justify-content-center mt-4">
  //           <button
  //             className="buttonStyle text-white border-round-md"
  //             style={{ height: 30, lineHeight: 0, width: "30%" }}
  //             onClick={() => {
  //               navigate("/pantry-staff-inventory");
  //             }}
  //           >
  //             Close
  //           </button>
  //         </div>
  //       </div>
  //     </Dialog>
  //   );
  // };

  const getvipDetails = async (vipCodeOrMobileNumber) => {
    await FindVipUser(cookies?.bearerToken, 0, 1, vipCodeOrMobileNumber).then(
      (res) => {
        console.log("vip res", res);
        if (res?.status === 200 && res?.data?.response) {
          setCheckoutData({
            ...checkoutData,
            vipCode: res?.data?.response?.vipcode,
            vipSearchValue: vipCodeOrMobileNumber,
            name: res?.data?.response?.firstName
              ? res?.data?.response?.firstName +
              " " +
              res?.data?.response?.lastName
              : "",
            emailAddress: res?.data?.response?.emailAddress,
            mobileNo: res?.data?.response?.mobileNumber,
          });
          setIsValidErrmsgView(false);
        } else {
          toast.warning("User Not Found", {
            position: "top-right",
          });
          setCheckoutData({
            ...checkoutData,
            vipCode: null,
            vipSearchValue: vipCodeOrMobileNumber,
            name: "",
            emailAddress: "",
            mobileNo: null,
          });
          setIsValidErrmsgView(true);
        }
      }
    );
  };

  const handleCheckOut = async () => {
    setIsLoading(true);
    setIsLoader(true);
    let selectedItems = JSON.parse(JSON.stringify(cartItems)).map((value) => {
      return {
        itemId: value?.id,
        checkoutCount: value?.quantity,
      };
    });

    let data = {
      pantryItemsInformation: selectedItems,
      vipUserCodeOrMobileNumber: checkoutData?.vipCode,
      totalItemsCount: cartItems.length,
      totalAmount: cartItems.reduce(
        (acc, item) => acc + parseInt(item.amount) * parseInt(item.quantity),
        0
      ),
    };

    await CheckOutVipUser(cookies?.bearerToken, data)
      ?.then((res) => {
        if (res?.status == 200) {
          setCheckoutData({ ...checkOutDataInitialValue });
          setCartItems([]);
          setCheckout(false);
          setIsConformationModalOpen(false);
          toast.success("Checkout successfully", { position: "top-right" });
          setSearchFilterValue("");
          getPurchaseItems();
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  //auto complete filter

  const searchItems = (event) => {
    //in a real application, make a request to a remote url with the query and return filtered results, for demo purposes we filter at client side
    let query = event.query;
    getVipUsers(query, 0, 100);
  };

  const getVipUsers = async (search, skip, take) => {
    await GetVipUsers(cookies?.bearerToken, search, skip, take)?.then((res) => {
      if (res?.status == 200) {
        setVipUsers(res?.data?.data);
      }
    });
  };
  const getCategoryList = () => {
    GetPantryCategoryList(cookies?.bearerToken).then(res => {
      if (res?.status == 200) {
        setCategoryList(res?.data);
      }
    })
  }
  useEffect(() => {
    // getVipUsers("", 0, 100);
    getPurchaseItems();
    getCategoryList();
  }, []);

  return (
    <>

      <>
        <div className="Purchase">
          <div className="header">
            <div style={{ display: "flex" }}>
              <div className="menubtn" style={{ padding: "20px" }}>
                <i
                  className="pi pi-align-justify"
                  style={{
                    fontSize: "1rem",
                    color: "#ae9d81",
                    cursor: "pointer",
                  }}
                  onClick={() =>
                    dispatch(setNavVisibilty({ sideNavVisibility: true }))
                  }
                ></i>
              </div>
              <div className="heading">
                <p>Purchase</p>
              </div>
            </div>
          </div>
          <div className="header2" style={{ display: "flex" }}>
            <div style={{ width: "50%" }}>
              {!isCheckout ? (
                <div
                  className="leftbody"
                  style={{ width: "95%", margin: "auto" }}
                >
                  <div className="header purchase-search align-items-center">
                    <div>
                      <h4 className='mb-1 mt-0 relative' style={{ color: "#ae9d81" }}>Search</h4>
                      <div>
                        <div className="right" style={{ width: "10%" }}>
                          <span className="p-input-icon-left p-input-icon-right">
                            <i
                              className="pi pi-search"
                              style={{
                                color: "black",
                                fontWeight: "700",
                                fontSize: "0.9rem",
                              }}
                            />
                            <InputText
                              placeholder="Search items"
                              value={searchFilterValue}
                              onChange={(e) => {
                                setSearchFilterValue(e.target.value);
                                getPurchaseItems(0, 10, e.target.value, selectedCategory || "");
                              }
                              }
                            />
                            {searchFilterValue !== "" && (
                              <i
                                className="pi pi-times"
                                style={{
                                  color: "black",
                                  fontWeight: "400",
                                  fontSize: "1rem",
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  setSearchFilterValue("");
                                  getPurchaseItems(0, 10, "");
                                }}
                              />
                            )}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="w-50 flex align-items-center">
                      <div>
                        <h4 className='mb-1 mt-0 relative' style={{ color: "#ae9d81" }}>Category</h4>
                        {/* < span className="p-input-icon-left p-input-icon-right"> */}
                        <Dropdown options={categoryList} optionLabel="name" optionValue="name" value={selectedCategory} style={{ width: "180px" }} placeholder="Select" onChange={(e) => {
                          setSelectedCategory(e.value);
                          getPurchaseItems(0, 10, searchFilterValue, e.value);
                        }
                        } />
                      </div>
                      {/* </span> */}
                      <div className="ml-2 align-self-end">
                        <Button className="flex justify-content-center" style={{ width: "100px", backgroundColor: "#ccc", border: 0 }} onClick={() => {
                          setSelectedCategory("");
                          getPurchaseItems(0, 10, searchFilterValue, "");
                        }}>
                          Clear
                        </Button>
                      </div>
                    </div>
                  </div>
                  <div
                    className="items"
                    style={{
                      overflow: "auto",
                      height: "425px",
                      padding: "10px",
                      overflowX: "hidden",
                      // borderTop: "1px solid #ae9d81",
                      // borderBottom: "1px solid #ae9d81",
                    }}
                  >
                    {isLoader ? (
                      <div style={{ display: "flex", justifyContent: "center" }}>
                        <div className="spinner spinner-center"></div>
                      </div>
                    ) : (
                      <>
                        {inventoryList?.map((item, index) => {
                          return (
                            <div
                              key={index}
                              className="mb-3 shadow-1"
                              style={{
                                width: "86%",
                                padding: "15px",
                                height: "80px",
                                borderRadius: "5px",
                                background: "#faf6ef",
                                cursor: "pointer",
                                // boxShadow:
                                //   "rgba(0, 0, 0, 0.1) 0px 12px 28px 0px, rgba(0, 0, 0, 0.1) 0px 2px 4px 0px, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset",
                                // border: "1px solid #ae9d81",
                              }}
                              onClick={() => {
                                addToCart(item);
                              }}
                            >
                              <div
                                className=""
                                style={{
                                  display: "flex",
                                  padding: 0,
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                }}
                              >
                                <div>
                                  <p
                                    style={{
                                      padding: 0,
                                      margin: 0,
                                      // color: "#00488F",
                                      fontSize: "17px",
                                      fontWeight: 600,
                                    }}
                                  >
                                    {item?.name}
                                  </p>
                                  <div
                                    style={{
                                      display: "flex",
                                      gap: "5px",
                                      marginTop: "10px",
                                    }}
                                  >
                                    <p
                                      style={{
                                        padding: 0,
                                        margin: 0,
                                        fontWeight: 400,
                                        color: "#ae9d81",
                                      }}
                                    >
                                      Qty :
                                    </p>
                                    <p
                                      style={{
                                        padding: 0,
                                        margin: 0,
                                        fontWeight: 400,
                                      }}
                                    >
                                      {item?.quantity}
                                    </p>
                                  </div>
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    gap: "5px",
                                    width: "30%",
                                  }}
                                >
                                  <p
                                    style={{
                                      padding: 0,
                                      margin: 0,
                                      fontWeight: 400,
                                      color: "#ae9d81",
                                    }}
                                  >
                                    Price :
                                  </p>
                                  <p
                                    style={{
                                      padding: 0,
                                      margin: 0,
                                      fontWeight: 400,
                                    }}
                                  >
                                    {item?.currencyISOCode} {item?.amount}
                                  </p>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </>

                    )}

                  </div>
                </div>
              ) : (
                <div
                  className="leftbody"
                  style={{ width: "90%", margin: "auto" }}
                >
                  <div className="header">
                    <div style={{ display: "flex" }}>
                      <div className="heading">
                        <p>Customer info</p>
                      </div>
                    </div>
                  </div>

                  <div className="right" style={{}}>
                    <div style={{ paddingLeft: "10px" }}>
                      <div
                        className="input-box"
                        style={{ position: "relative" }}
                      >
                        <p style={{ fontWeight: 600, color: "#ae9d81" }} className="mt-0">
                          Vip id / Mobile number
                          <span style={{ color: "red" }}> *</span>
                        </p>

                        <InputText
                          value={checkoutData?.vipSearchValue}
                          onChange={(e) => {
                            setCheckoutData({
                              ...checkoutData,
                              vipSearchValue: e?.target?.value,
                            });
                          }}
                        />
                        <Button
                          icon="pi pi-search"
                          style={{
                            color: "black",
                            background: "transparent",
                            border: "none",
                            backgroundColor: "#ae9d81",
                            color: "white",
                            marginLeft: "10px",
                          }}
                          onClick={() =>
                            checkoutData?.vipSearchValue &&
                            getvipDetails(checkoutData?.vipSearchValue)
                          }
                        // loading={profileLoading}
                        />
                        {isErrMsgView && !checkoutData?.vipCode && (
                          <p style={{ color: "red" }}>
                            {!checkoutData?.vipSearchValue
                              ? "VIP id is required"
                              : isValidErrmsgView && !checkoutData?.vipCode
                                ? "Enter a valid id or mobile number"
                                : ""}
                          </p>
                        )}
                      </div>
                      <div className="input-box" style={{ display: "flex" }}>
                        <p style={{ fontWeight: 600, width: "20%" }}>
                          Vip id
                        </p>
                        <p style={{ width: "80%", color: "#8e8e8e" }}>
                          : {checkoutData?.vipCode}
                        </p>
                      </div>
                      <div className="input-box" style={{ display: "flex" }}>
                        <p style={{ fontWeight: 600, width: "20%" }}>Name</p>
                        <p style={{ width: "80%", color: "#8e8e8e" }}>
                          : {checkoutData?.name}
                        </p>
                      </div>
                      <div className="input-box" style={{ display: "flex" }}>
                        <p style={{ fontWeight: 600, width: "20%" }}>Email</p>
                        <p style={{ width: "80%", color: "#8e8e8e" }}>
                          : {checkoutData?.emailAddress}
                        </p>
                      </div>
                      <div className="input-box" style={{ display: "flex" }}>
                        <p style={{ fontWeight: 600, width: "20%" }}>
                          Mobile Number
                        </p>
                        <p style={{ width: "80%", color: "#8e8e8e" }}>
                          : {checkoutData?.mobileNo}
                        </p>
                      </div>
                      <div style={{ padding: "10px 0" }}>
                        <p style={{ fontWeight: 600, color: "#ae9d81" }} className="mt-0">
                          Remark
                          <span style={{ color: "#adadad" }}> (Optional)</span>
                        </p>
                        <div style={{ color: "#8e8e8e", fontWeight: 800 }}>
                          <InputTextarea style={{ height: "120px" }} className="mx-2 w-full" maxLength={1000} placeholder="Enter remark (eg: Add more spicy)" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <hr style={{ height: "75vh", margin: "auto" }} />
            <div className="middlebody" style={{ width: "50%" }}>
              <div style={{ width: "90%", margin: "auto" }}>
                <div className="header">
                  <div
                    className="heading"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                      alignItems: "center",
                    }}
                  >
                    <p>Your Items({cartItems?.length})</p>
                    {isCheckout && (
                      <div className="right" style={{ padding: "20px" }}>
                        <Button
                          label="Add Item"
                          style={{
                            background: "#ae9d81",
                            color: "white",
                            border: "none",
                          }}
                          onClick={() => {
                            setCheckout(false);
                          }}
                        />
                      </div>
                    )}
                  </div>
                </div>
                <div
                  className="items"
                  style={{
                    overflow: "auto",
                    height: "48vh",
                    // padding: "10px",
                    overflowX: "hidden",
                    width: "100%",
                  }}
                >
                  {cartItems.length ? (
                    cartItems?.map((item, index) => {
                      return (
                        <div
                          className="mb-3 shadow-1"
                          style={{
                            display: "flex",
                            width: "90%",
                            padding: "20px",
                            height: "80px",
                            borderRadius: "5px",
                            background: "#faf6ef",
                            // boxShadow:
                            //   "rgba(0, 0, 0, 0.2) 0px 12px 28px 0px, rgba(0, 0, 0, 0.1) 0px 2px 4px 0px, rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset",
                            // border: "1px solid #ae9d81",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              width: "100%",
                            }}
                          >
                            <div className="itemName" style={{}}>
                              <p
                                style={{
                                  padding: 0,
                                  margin: 0,
                                  // color: "#00488F",
                                  fontSize: "17px",
                                  fontWeight: 600,
                                }}
                              >
                                {item?.name}
                              </p>
                            </div>
                            <div className="itemName" style={{}}>
                              <p
                                style={{
                                  padding: 0,
                                  margin: 0,
                                  // color: "#00488F",
                                  fontSize: "17px",
                                  fontWeight: 400,
                                }}
                              >
                                {item?.amount}
                              </p>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "20px",
                                width: "200px",
                              }}
                            >
                              <div
                                className="quantity"
                                style={{
                                  background: "#ae9d81",
                                  display: "flex",
                                  gap: "10px",
                                  padding: "8px 15px",
                                  borderRadius: "5px",
                                }}
                              >
                                <div style={{ cursor: "pointer" }}>
                                  <i
                                    className="pi pi-minus"
                                    style={{
                                      fontSize: ".75rem",
                                      color: "#fff",
                                      margin: "5px",
                                      cursor: "pointer"
                                    }}
                                    onClick={() => {
                                      if (item?.quantity > 1) {
                                        decreaseQuantity(item);
                                      }
                                    }}
                                  ></i>
                                </div>
                                <div>
                                  <p
                                    style={{
                                      padding: 0,
                                      margin: 0,
                                      fontWeight: 400,
                                      color: "#fff",
                                    }}
                                  >
                                    {item?.quantity}
                                  </p>
                                </div>
                                <div style={{ cursor: "pointer" }}>
                                  <i
                                    className="pi pi-plus"
                                    style={{
                                      fontSize: ".75rem",
                                      color: "#fff",
                                      margin: "5px"
                                    }}
                                    onClick={() => {
                                      if (
                                        item.availableQuantity > item.quantity
                                      ) {
                                        increaseQuantity(item);
                                      }
                                    }}
                                  ></i>
                                </div>
                              </div>
                              <div className="delete" style={{}}>
                                <i
                                  className="pi pi-trash"
                                  style={{ color: "#bf4927" }}
                                  onClick={() => {
                                    removeFromCart(item);
                                  }}
                                ></i>
                              </div>
                              <div className="price">
                                <p>
                                  {" "}
                                  {item?.currencyISOCode}{" "}
                                  {item?.amount * item?.quantity}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })
                  ) : isLengthErrMsgView ? (
                    <p style={{ color: "red" }}>Items are empty</p>
                  ) : null}
                </div>

                <div className="body" style={{ paddingLeft: "5%" }}>
                  {cartItems?.length > 0 &&
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "80%",
                        margin: "20px 0px",
                      }}
                    >
                      <div>
                        <div>
                          <p
                            style={{
                              padding: 0,
                              margin: 0,
                              color: "#ae9d81",
                              fontSize: "17px",
                              fontWeight: "600",
                            }}
                          >
                            Total
                          </p>
                        </div>
                      </div>
                      <div
                        style={{
                          width: "200px",
                          display: "flex",
                          gap: "10px",
                          justifyContent: "space-around",
                        }}
                      >
                        <p style={{ padding: 0, margin: 0, fontWeight: 400 }}>
                          {cartItems.reduce(
                            (acc, item) =>
                              acc +
                              parseInt(item.quantity),
                            0
                          )}
                        </p>
                        <p style={{ padding: 0, margin: 0, fontWeight: 400 }}>
                          BHD{" "}
                          {cartItems.reduce(
                            (acc, item) =>
                              acc +
                              parseInt(item.amount) * parseInt(item.quantity),
                            0
                          )}
                        </p>
                      </div>
                    </div>
                  }
                  <div
                    style={{
                      width: "80%",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Button
                      style={{
                        display: "flex",
                        background: "#ae9d81",
                        width: "70%",
                        border: "none",
                        color: "#fff",
                        justifyContent: "center",
                        alignItems: "center",
                        alignContent: "center",
                      }}
                      onClick={() => {
                        setIsLengthErrMsgView(!cartItems.length);
                        if (cartItems.length) {
                          if (!isCheckout) {
                            setCheckout(true);
                          } else {
                            setIsErrMsgView(!checkoutData.vipCode);
                            if (checkoutData.vipCode) {
                              setIsConformationModalOpen(true);
                            }
                          }
                        }
                      }}
                    >
                      {isCheckout ? "Checkout" : "Next"}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
            {/* <hr style={{ height: "75vh", margin: "auto" }} /> */}
            {/* <div className="rightbody" style={{ flexBasis: "20%" }}>
            <div className="header">
              <div style={{ display: "flex" }}>
                <div className="heading">
                  <p>Summary</p>
                </div>
              </div>
            </div>
            <div className="body">
              <div style={{ display: "flex" }}>
                <div>
                  <p
                    style={{
                      padding: 0,
                      margin: 0,
                      color: "#00488F",
                      fontSize: "16px",
                      fontWeight: "600",
                    }}
                  >
                    Total
                  </p>
                </div>
                <div>
                  <p style={{ padding: 0, margin: 0, fontWeight: 400 }}>
                    {cartItems?.length}
                  </p>
                </div>
                <div>
                  <p style={{ padding: 0, margin: 0, fontWeight: 400 }}>
                    {cartItems.reduce(
                      (acc, item) =>
                        acc + parseInt(item.amount) * parseInt(item.quantity),
                      0
                    )}
                  </p>
                </div>
              </div>
            </div>
          </div> */}
          </div>
        </div>

        <Dialog
          header={
            <h3 style={{ textAlign: "center", color: "#ae9d81", margin: 0 }}>
              Confirmation
            </h3>
          }
          visible={isConfirmationModalOpen}
          style={{ width: "30%" }}
          onHide={() => {
            setIsConformationModalOpen(false);
          }}
        >
          <div className="track-delivery-view-success-modal">
            <div className="success-modal-content">
              <p style={{ fontSize: "18px" }}>
                Are you sure want to checkout?
              </p>
            </div>
            <div className="success-modal-btn" style={{ gap: "10px" }}>
              <Button
                label={"No"}
                style={{
                  backgroundColor: "#8e8e8e",
                  border: "none",
                  borderRadius: "3px",
                  width: "120px",
                  height: "40px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                onClick={() => {
                  setIsConformationModalOpen(false);
                }}
              />
              <Button
                label={"Yes"}
                style={{
                  backgroundColor: "#ae9d81",
                  border: "none",
                  borderRadius: "3px",
                  width: "120px",
                  height: "40px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                onClick={() => {
                  handleCheckOut();
                }}
              />
            </div>
          </div>
        </Dialog>
      </>

    </>
  );
}

export default Purchase;

{
  /* <>
  {successModal()}
  <div style={{ display: "flex" }}>
    <div className="menubtn" style={{ padding: "20px" }}>
      <i
        className="pi pi-align-justify"
        style={{ fontSize: "1rem", color: "#ae9d81", cursor: "pointer" }}
        onClick={() => dispatch(setNavVisibilty({ sideNavVisibility: true }))}
      ></i>
    </div>
    <div className="heading">
      <p>Purchase</p>
    </div>
  </div>

  <div className="flex justify-content-around" style={{ padding: "0 20px" }}>
    <div className="col-6 rightBorderStyle">
      <h2 className="headerTitle">Customer info</h2>
      <div className="pt-1">
        <p>Name</p>
        <InputText
          className="col-8"
          value={checkoutData?.name}
          onChange={(e) => {
            setCheckoutData({ ...checkoutData, name: e.target.value });
          }}
        />
      </div>
      <div>
        <p>Email</p>
        <InputText
          className="col-8"
          value={checkoutData?.emailAddress}
          onChange={(e) => {
            setCheckoutData({
              ...checkoutData,
              emailAddress: e.target.value,
            });
          }}
        />
      </div>
      <div>
        <p>Mobile Number</p>
        <InputText
          className="col-8"
          value={checkoutData?.mobileNo}
          onChange={(e) => {
            setCheckoutData({ ...checkoutData, mobileNo: e.target.value });
          }}
        />
      </div>
    </div>
    <div className="col-6 px-5">
      <div className="flex align-items-center justify-content-between">
        <h2 className="headerTitle">Your items ({cartItems?.length})</h2>
        <MultiSelect
          className="dropdownStyle"
          style={{ width: "160px" }}
          options={inventoryList}
          optionLabel="name"
          placeholder="Select items"
          filter
          filterPlaceholder="Search items"
          resetFilterOnHide={true}
          display="chip"
          onFilter={(e) => {
            getPurchaseItems(0, 10, e.filter);
          }}
          emptyFilterMessage="Item list is empty"
          onChange={(e) => {
            addToCart(e.target.value);
          }}
          value={cartItemsSelect}
        ></MultiSelect>
      </div>
      <div style={{ maxHeight: "320px", overflow: "auto" }}>
        {cartItems?.map((_, index) => (
          <div className="my-3 px-2" key={index}>
            <Card className="p-0" style={{ backgroundColor: "#FAF6EF" }}>
              <div className="flex align-items-center">
                <div className="col-6 p-0">
                  <p className="text-lg m-0 px-2">{_?.name}</p>
                </div>
                <div className="col-6 flex align-items-center  p-0">
                  <div className="flex align-items-center defaultThemeBackground py-1 border-round-sm">
                    <span
                      className="px-3 text-xl text-white  cursor-pointer "
                      onClick={() => {
                        decreaseQuantity(_);
                      }}
                    >
                      -
                    </span>
                    <span className="px-2 text-white">{_?.quantity}</span>
                    <span
                      className="px-3 text-xl text-white  cursor-pointer"
                      onClick={() => {
                        increaseQuantity(_);
                      }}
                    >
                      +
                    </span>
                  </div>
                  <span>
                    <IoTrashOutline
                      className="text-2xl text-red-600  cursor-pointer "
                      onClick={() => {
                        removeFromCart(_);
                      }}
                    />
                  </span>
                  <p className="font-bold">{_.amount * _.quantity}</p>
                </div>
              </div>
            </Card>
          </div>
        ))}
      </div>
      <div className="flex align-items-center">
        <div className="col-8">
          <p style={{ color: "#ae9d81" }} className="text-lg">
            Total
          </p>
        </div>
        <div className="col-2">
          <p className="text-center text-lg text-gray-500  font-normal">
            {cartItems.reduce((acc, item) => acc + parseInt(item.quantity), 0)}
          </p>
        </div>
        <div className="col-2">
          <p className="text-center text-lg text-gray-500 font-normal">
            {cartItems.reduce(
              (acc, item) =>
                acc + parseInt(item.amount) * parseInt(item.quantity),
              0
            )}
          </p>
        </div>
      </div>
      <div className="text-center flex justify-content-center">
        <Button className="buttonStyle" onClick={checkout}>
          Place Order
        </Button>
      </div>
    </div>
  </div>
</>; */
}
