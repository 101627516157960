import { toast } from "react-toastify";
import Axios, { clientLiveUrl } from "../Helpers/Axios";
import { apiUrl } from "../Helpers/Axios";


export function exportDataInExcel(urlRoute, token) {
  const getCookie = (name) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(";").shift();
  };
  // localStorage.getItem("userId");

  var queryparam = "strUserId=" + localStorage.getItem("userId");
  if (urlRoute.toLowerCase().indexOf('searchterm') >= 0) {
    queryparam = '&' + queryparam;
  } else {
    queryparam = '?' + queryparam;
  }

  var redirectUrl =
    apiUrl + "Export/" + urlRoute + queryparam;
  window.open(redirectUrl);
}

export function exportDataInLandD(urlRoute, queryparam) {
  var redirectUrl = apiUrl + "Export/" + urlRoute + queryparam;
  window.open(redirectUrl);
}

export function exportExcel(urlRoute) {
  var redirectUrl = apiUrl + "Export/" + urlRoute
  var apiEndpoint = "Export/" + urlRoute;
  Axios(apiEndpoint, 'get', null).then((res => {
    if (res?.status == 404) {
      toast.info(res?.response?.data || "No record found");
      return
    }
    if (res?.status == 200) {
      window.open(redirectUrl);
      return
    }
    console.log(res, 'reerere');
    toast.info(res?.response?.data || "Something went wrong, couldn't connect to the server. Please try again");
  }))

}
export function ExportOpenInNewTab(urlRoute) {
  var redirectUrl = apiUrl + urlRoute
  var apiEndpoint = urlRoute;
  Axios(apiEndpoint, 'get', null).then((res => {
    if (res?.status == 404) {
      toast.info(res?.response?.data || "No record found");
      return
    }
    if (res?.status == 200) {
      window.open(redirectUrl);
      return
    }
    console.log(res, 'reerere');
    toast.info(res?.response?.data || "Something went wrong, couldn't connect to the server. Please try again");
  }))

}


export async function exportFilteredCSV(urlRoute, token, data, method = "get") {
  try {
    const apiEndpoint = apiUrl + urlRoute;
    let finalUrl = apiEndpoint;
    let options = {
      method: method.toUpperCase(),
      headers: {
        'Authorization': `Bearer ${token}`,
      }
    };

    // Add data to the request appropriately based on the method
    if (method.toLowerCase() === 'get' && data) {
      const queryParams = new URLSearchParams(data).toString();
      finalUrl += `?${queryParams}`;
    } else if (data) {
      options.headers['Content-Type'] = 'application/json';
      options.body = JSON.stringify(data);
    }

    const response = await fetch(finalUrl, options);

    if (!response.ok) {
      toast.info("Something went wrong, couldn't connect to the server. Please try again");
      return;
    }

    // Extract the filename from the Content-Disposition header
    const disposition = response.headers.get('Content-Disposition');
    let fileName = 'download.csv'; // Default filename
    if (disposition && disposition.includes('attachment')) {
      const match = disposition.match(/filename="(.+)"/);
      if (match && match.length > 1) {
        fileName = match[1];
      }
    }

    const blob = await response.blob();
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    a.remove();
    window.URL.revokeObjectURL(url);
  } catch (error) {
    console.error('Error:', error);
    toast.error("An error occurred while exporting the data. Please try again.");
  }
}





export function exportFilterdExcel(urlRoute, token, data = null, method = 'get') {
  var redirectUrl = apiUrl + urlRoute
  var apiEndpoint = urlRoute;
  Axios(apiEndpoint, method, data, token).then((res => {
    if (res?.status == 404) {
      toast.info(res?.response?.data || "No record found");
      return
    }
    if (res?.status == 200) {
      // window.open(redirectUrl);
      exportFilteredCSV(urlRoute, token, data, method);
      return
    }
    console.log(res, 'reerere');
    toast.info(res?.response?.data || "Something went wrong, couldn't connect to the server. Please try again");
  }))

}

export function isShow(obj) {
  return !(Object.values(obj)?.some(_ => (_ !== "" || _ !== undefined || _ !== null || _ !== 0)));
}



