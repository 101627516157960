import React, { useEffect, useRef, useState } from "react";
import "./CheckIn.scss";
import { useDispatch, useSelector } from "react-redux";
import { useCookies } from "react-cookie";
import {
  parsePath,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { UploadBase64Files } from "../../../Services/Files";
import { toast } from "react-toastify";

import { stepsArrayData } from "../../../Redux/AppSlice";

import { delivery } from "../../Common/Config";
import { stepperNames } from "../../Common/Config";

//signature canvs
import SignatureCanvas from "react-signature-canvas";
import Signature from "../../utili/signature";

import {
  AddShopAndDropDeliveryDetails,
  GetCheckInAdditionalInfo,
  GetDeliveryCharge,
} from "../../../Services/ShopAndDropService";
import { useReactToPrint } from "react-to-print";

const CheckIn = (props) => {

  const shopAndDropDeliveryDetails = props.shopAndDropDeliveryDetails;
  const deliveryCharges = props.deliveryCharges;
  console.log("shopAndDropDeliveryDetails", shopAndDropDeliveryDetails);
  const navigate = useNavigate();

  const [isModalOPen, setIsModalOpen] = useState(false);
  const [isSuceesModalOpen, setIsSuccessModalOpen] = useState(false);
  const [cookies, setCookie, removeCookie] = useCookies();
  const [isErrMsgView, setIsErrMsgView] = useState(false);
  const [isLoader, setIsLoader] = useState(false);
  const [printDialog, setPrintDialog] = useState(false);
  const [isSigErrView, setIsSigErrView] = useState(false);
  const [isConformationOpen, setIsConformationOpen] = useState(false);
  const [isSinatureErrMsgView, setIsSinatureErrMsgView] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const [payableAmount, setPayableAmount] = useState(0);
  const dispatch = useDispatch();

  const stepperDetails = useSelector(
    (state) => state.AppContext.stepperDetails
  );

  const isInsideMall =
    shopAndDropDeliveryDetails?.deliveryType?.label === delivery.insideMall
      ? true
      : false;

  const isHomeDelivery =
    shopAndDropDeliveryDetails?.deliveryType?.label === delivery.homeDelivery
      ? true
      : false;
  var [isChargeable, setIsChargeable] = useState(false);
  // input values

  const handleInputValue = (key, value) => {
    props.handleInputValue(key, value);
  };

  const getImageUrl = (imageUrl) => {
    setIsSinatureErrMsgView(!imageUrl);
    if (imageUrl) {
      props.handleInputValue("signatureImageIrl", imageUrl);
      setIsModalOpen(false);
    }
  };

  const handleCheckIn = async () => {
    setIsLoader(true);

    let bagInformations = [];
    if (shopAndDropDeliveryDetails?.bagInformations?.length) {
      shopAndDropDeliveryDetails?.bagInformations.forEach((val) => {
        bagInformations.push({
          tenantId: val?.storeId,
          receiptAmount: Number(val?.reciptAmount),
          noofBags: val?.numberOfBags,
        });
      });
    }

    let data = {
      totalAmount: shopAndDropDeliveryDetails?.totalAmount,
      totalBags: shopAndDropDeliveryDetails?.totalBags,
      payableAmount: payableAmount,
      remarks: shopAndDropDeliveryDetails?.remarks,
      proof: shopAndDropDeliveryDetails?.proof?.name,
      guestInfo: {
        phoneNumber: shopAndDropDeliveryDetails?.phoneNumber,
        countryCode: shopAndDropDeliveryDetails?.phoneNumberCode,
        firstName: shopAndDropDeliveryDetails?.firstName,
        lastName: shopAndDropDeliveryDetails?.lastName,
        emailId: shopAndDropDeliveryDetails?.emailId || "",
        receiveEmaarEmails: shopAndDropDeliveryDetails?.receiveEmaarEmails,
      },
      baggageInfo: {
        baggageId: shopAndDropDeliveryDetails?.baggageTagNumber?.id,
        baggageTagNumber: shopAndDropDeliveryDetails?.baggageTagNumber?.name,
      },
      bagInformations,
      deliveryInformation: {
        deliveryTypeId: shopAndDropDeliveryDetails?.deliveryType?.id,
        isInsideMall: !isHomeDelivery,
        insideMallDelivery: {
          type: shopAndDropDeliveryDetails?.deliveryTab,
          deskID:
            shopAndDropDeliveryDetails?.deliveryTab === "Desk"
              ? shopAndDropDeliveryDetails?.deskLocation?.id
              : 0,
          tenantID:
            shopAndDropDeliveryDetails?.deliveryTab === "Tenant"
              ? shopAndDropDeliveryDetails?.tenantLocation?.id
              : 0,
          pickupTime:
            (new Date(
              shopAndDropDeliveryDetails?.pickupTime
            ).getHours() > 12 ? new Date(
              shopAndDropDeliveryDetails?.pickupTime
            ).getHours() - 12 : new Date(
              shopAndDropDeliveryDetails?.pickupTime
            ).getHours()) +
            ":" +
            (new Date(
              shopAndDropDeliveryDetails?.pickupTime
            ).getMinutes() > 9 ? new Date(
              shopAndDropDeliveryDetails?.pickupTime
            ).getMinutes() : "0" + new Date(
              shopAndDropDeliveryDetails?.pickupTime
            ).getMinutes()),
          pickupLocationId: shopAndDropDeliveryDetails?.pickupLocation?.id,
        },
        toHomeDelivery: {
          type: shopAndDropDeliveryDetails?.deliveryTab,
          deskID:
            shopAndDropDeliveryDetails?.deliveryTab === "Desk"
              ? shopAndDropDeliveryDetails?.deskLocation?.id
              : 0,
          tenantID:
            shopAndDropDeliveryDetails?.deliveryTab === "Tenant"
              ? shopAndDropDeliveryDetails?.tenantLocation?.id
              : 0,
          deliverTime: shopAndDropDeliveryDetails?.deliveryTime?.time
            ? shopAndDropDeliveryDetails?.deliveryTime?.time
            : "",
          name: shopAndDropDeliveryDetails?.name,
          mobile: shopAndDropDeliveryDetails?.mobile,
          street: shopAndDropDeliveryDetails?.street,
          flat: shopAndDropDeliveryDetails?.flat,
          city: shopAndDropDeliveryDetails?.city,
          deliveryCharge: payableAmount,
        },
      },
      paymentReferenceNumber: shopAndDropDeliveryDetails?.paymentReferenceNumber
    };

    let proofImageIrl = shopAndDropDeliveryDetails?.proofImageUrl;
    let signatureImageUrl = shopAndDropDeliveryDetails?.signatureImageIrl;
    await AddShopAndDropDeliveryDetails(
      data,
      shopAndDropDeliveryDetails?.checkInID,
      cookies?.bearerToken
    )
      .then(async (res) => {
        // if (res?.response?.status === 400) {
        //   toast.warning(res?.response?.data?.message, {
        //     position: "top-right",
        //   });
        //   return;
        // }
        // if (res?.status === 200) {
        //   if (res?.data?.httpStatusCode === 400) {
        //     toast.warning(res?.data?.message, { position: "top-right" });
        //     return;
        //   }
        //   if (res?.data?.httpStatusCode === 500) {
        //     toast.error(res?.data?.message, { position: "top-right" });
        //     return;
        //   } else {
        //     toast.success("VIP User Registered Succesfully", {
        //       position: "top-right",
        //     });
        //     setUserDetails({ ...userInitialValue });
        //     navigate("/vip-user-list");
        //   }
        // }

        if (res?.status == 200) {
          getCheckInAdditionalInfo(res?.data?.response?.checkInDetailId, !isHomeDelivery);
          props.handleReset();
          setIsConformationOpen(false);
          toast.success("Checked-in Successfully", { position: "top-right" });
          if (props.isCheckinRequest) {
            navigate("/checkin-request");
          } else {
            setPrintDialog(true);
          }
          setBtnLoading(false);
          setIsLoader(false);
          AddAttachment(
            res?.data?.response?.checkInDetailId,
            "VIPCheckInProof",
            proofImageIrl
          );
          AddAttachment(
            res?.data?.response?.checkInDetailId,
            "VIPSignature",
            signatureImageUrl
          );
        }
      })
      .finally((err) => {
        setBtnLoading(false);
        setIsLoader(false);
      });
  };

  const AddAttachment = async (returnId, module, image) => {
    let fileNameExtension = image.split(";base64,")[0].split("/")[1];

    let fileModule = "ShopandDrop";
    let filedate =
      new Date().getFullYear() + new Date().getMonth() + new Date().getDate();

    let fileName = fileModule + filedate + "." + fileNameExtension;

    let data = [
      {
        id: returnId,
        module: module,
        inspectionId: 0,
        files: [
          {
            fileName: fileName,
            base64: image.split(";base64,")[1],
          },
        ],
      },
    ];

    await UploadBase64Files(data, cookies.bearerToken)
      .then((res) => {
        if (res.status == 200 && module === "VIPSignature") {
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // const getAmount = async () => {
  //   await GetDeliveryCharge(cookies?.bearerToken)?.then((res) => {
  //     console.log("amount res", res);
  //     if (res?.status == 200) {
  //       if (res?.data[0]?.deliveryCharge) {
  //         setPayableAmount(res?.data[0]?.deliveryCharge);
  //       }
  //     }
  //   });
  // };
  const calculateAmount = async () => {
    console.log(shopAndDropDeliveryDetails?.deliveryLabel, shopAndDropDeliveryDetails?.deliveryTime, deliveryCharges);
    let type = "";
    if (shopAndDropDeliveryDetails?.deliveryLabel === "INSIDE_MALL") {
      type = "INSIDE_MALL";
    }
    else if (shopAndDropDeliveryDetails?.deliveryLabel === "TO_HOME") {
      if (shopAndDropDeliveryDetails?.deliveryTime?.time == "Same day") {
        type = "TO_HOME_SAME_DAY"
      }
      else if (shopAndDropDeliveryDetails?.deliveryTime?.time == "Next day") {
        type = "TO_HOME_NEXT_DAY"
      }
    }
    let filterDeliveryType = await props?.deliveryCharges?.filter((item) => item?.deliveryLabel === type);
    console.log("filterDeliveryType", filterDeliveryType, "Type", type);
    setPayableAmount(filterDeliveryType[0]?.deliveryCharge);
    console.log("Delivery Charge", filterDeliveryType[0]?.deliveryCharge);
    if (filterDeliveryType[0]?.deliveryCharge > 0) {
      setIsChargeable(true);
    }

  }

  // Deepak-Print
  const initialPrintDetails = {
    refrenceNo: "",
    deliveryType: "",
    name: "",
    mobileNumber: "",
    nobags: "",
    checkin: "",
    deliveryDate: "",
    deliveryTime: ""
  }
  const [printDetail, setPrintDetails] = useState(initialPrintDetails);
  const contentToPrint = useRef(null);
  // ----------------------

  // Deepak-Print
  const handlePrint = useReactToPrint({
    documentTitle: "Print This Document",
    onBeforePrint: () => console.log("before printing..."),
    onAfterPrint: () => {
      setPrintDialog(false);
      props.handleStepChange({
        stepLabel: stepperNames.guestInformation,
        index: 0,
      });
    },
    removeAfterPrint: true,
  });
  // -------------------------
  const getCheckInAdditionalInfo = async (id, isInsideMall) => {
    await GetCheckInAdditionalInfo(cookies?.bearerToken, id, isInsideMall).then((res => {
      if (res?.status == 200) {
        setPrintDetails({
          ...printDetail,
          refrenceNo: res?.data?.response?.code,
          name: res?.data?.response?.firstName + res?.data?.response?.lastName,
          mobileNumber: res?.data?.response?.phoneNumber,
          nobags: res?.data?.response?.totalBags,
          checkin: res?.data?.response?.deliveryInformation?.desk ? res?.data?.response?.deliveryInformation?.desk : res?.data?.response?.deliveryInformation?.tenant,
          deliveryStatus: res?.data?.response?.statusInformation[0]?.name,
          deliveryTime: res?.data?.response?.deliveryInformation?.pickupTime,
          deliveryDate: res?.data?.response?.deliveryInformation?.deliveryDate,
          deliveryType: isInsideMall ? "Inside Mall" : "Out Side Mall" || "-",
          pickupLocation: res?.data?.response?.deliveryInformation?.pickupLocation,
          tagNo: res?.data?.response?.baggageTag
        })
      }
    }))
  }
  useEffect(() => {
    const element = document.getElementById("top");
    element.scrollIntoView();
    // getAmount( );
    calculateAmount();
  }, []);

  return (
    <>
      {isLoader ? (
        <div
          className="spinner spinner-center"
          style={{ marginLeft: "490px" }}
        ></div>
      ) : (
        <>
          <div className="check-in" id="top">
            <div className="check-in-heading">
              <h2>Check-in information</h2>
            </div>
            <div className="check-in-content">
              <div className="input">
                <div className="input-label">
                  <p>First name</p>
                </div>
                <div className="input-text">
                  <p>{shopAndDropDeliveryDetails?.firstName}</p>
                </div>
              </div>
              <div className="input">
                <div className="input-label">
                  <p>Last name</p>
                </div>
                <div className="input-text">
                  <p>{shopAndDropDeliveryDetails?.lastName}</p>
                </div>
              </div>
              <div className="input">
                <div className="input-label">
                  <p>Mobile number</p>
                </div>
                <div className="input-text">
                  <p>{shopAndDropDeliveryDetails?.phoneNumber}</p>
                </div>
              </div>
              <div className="input">
                <div className="input-label">
                  <p>Email id</p>
                </div>
                <div className="input-text">
                  <p>{shopAndDropDeliveryDetails?.emailId || "-"}</p>
                </div>
              </div>
              <div className="input">
                <div className="input-label">
                  <p>Pickup type</p>
                </div>
                <div className="input-text">
                  <p>{shopAndDropDeliveryDetails?.deliveryTab}</p>
                </div>
              </div>
              <div className="input">
                <div className="input-label">
                  <p>
                    {shopAndDropDeliveryDetails?.deliveryTab === "Desk"
                      ? "Desk location"
                      : "Tenant location"}
                  </p>
                </div>
                <div className="input-text">
                  <p>
                    {shopAndDropDeliveryDetails?.deliveryTab === "Desk"
                      ? shopAndDropDeliveryDetails?.deskLocation?.name
                      : shopAndDropDeliveryDetails?.tenantLocation?.name}
                  </p>
                </div>
              </div>
              {!isHomeDelivery && (
                <div className="input">
                  <div className="input-label">
                    <p>Pickup location</p>
                  </div>
                  <div className="input-text">
                    <p>{shopAndDropDeliveryDetails?.pickupLocation?.name}</p>
                  </div>
                </div>
              )}
              {!isHomeDelivery && (
                <div className="input">
                  <div className="input-label">
                    <p>Pickup time</p>
                  </div>
                  <div className="input-text">
                    <p>
                      {(new Date(
                        shopAndDropDeliveryDetails?.pickupTime
                      ).getHours() > 12 ? new Date(
                        shopAndDropDeliveryDetails?.pickupTime
                      ).getHours() - 12 : new Date(
                        shopAndDropDeliveryDetails?.pickupTime
                      ).getHours()) +
                        ":" +
                        (new Date(
                          shopAndDropDeliveryDetails?.pickupTime
                        ).getMinutes() > 9 ? new Date(
                          shopAndDropDeliveryDetails?.pickupTime
                        ).getMinutes() : "0" + new Date(
                          shopAndDropDeliveryDetails?.pickupTime
                        ).getMinutes())}
                    </p>
                  </div>
                </div>
              )}
              {/* <div className="input">
                <div className="input-label">
                  <p>Check-in location</p>
                </div>
                <div className="input-text">
                  <p>{shopAndDropDeliveryDetails?.dropLocation?.name}</p>
                </div>
              </div> */}
              <div className="input">
                <div className="input-label">
                  <p>Number of bags </p>
                </div>
                <div className="input-text">
                  <p>{shopAndDropDeliveryDetails?.totalBags}</p>
                </div>
              </div>
              <div className="input">
                <div className="input-label">
                  <p>Baggage tag </p>
                </div>
                <div className="input-text">
                  <p>{shopAndDropDeliveryDetails?.baggageTagNumber?.name}</p>
                </div>
              </div>
            </div>
            <div className="check-in-heading">
              <h2 style={{ margin: "0px 0px 10px 5px" }}>Bag list</h2>
            </div>
            <div className="check-in-bag-list">
              <div className="check-in-bag-list-table">
                <div className="check-in-bag-list-table-data">
                  <div className="bag-list-table">
                    <DataTable
                      value={shopAndDropDeliveryDetails?.bagInformations}
                      tableStyle={{ width: "100%" }}
                      stripedRows
                      rows={10}
                      emptyMessage={
                        <>
                          <p style={{ textAlign: "center" }}></p>
                        </>
                      }
                    >
                      <Column
                        field="storeName"
                        header="Tenant name"
                        style={{ width: "35%" }}
                      ></Column>
                      <Column
                        field="reciptAmount"
                        header="Receipt amount"
                        style={{ width: "35%" }}
                      ></Column>
                      <Column
                        field="numberOfBags"
                        header="Number of bags"
                        style={{ width: "30%" }}
                      ></Column>
                    </DataTable>
                  </div>
                  <div className="bag-list-amount">
                    <div>
                      <span style={{ color: "#ae9d81", fontWeight: 700 }}>
                        Total amount :{" "}
                      </span>
                      <span>{shopAndDropDeliveryDetails?.totalAmount}</span>
                    </div>
                    <div>
                      <span style={{ color: "#ae9d81", fontWeight: 700 }}>
                        Total bags :{" "}
                      </span>
                      <span>{shopAndDropDeliveryDetails?.totalBags}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="check-in-bag-list-signature">
                <div className="check-in-bag-list-signature-box">
                  <div className="check-in-bag-list-signature-header">
                    <h4>
                      {isChargeable ? "Delivery charges" : "Free Delivery"}
                    </h4>
                  </div>
                  <div className="check-in-bag-list-content">
                    {isChargeable ?
                      <div className="check-in-bag-list-input">
                        <p>
                          Payment reference no{" "}
                          <span style={{ color: "gray" }}>(max 20 chars)</span>
                          {/* <span style={{ color: "red" }}>*</span> */}
                        </p>
                        <InputText
                          placeholder="Enter here"
                          value={
                            shopAndDropDeliveryDetails?.paymentReferenceNumber
                              ? shopAndDropDeliveryDetails?.paymentReferenceNumber
                              : ""
                          }
                          onChange={(e) => {
                            if (e?.target?.value?.length < 20) {
                              handleInputValue("paymentReferenceNumber", e.target.value);
                            }
                          }}
                        />
                        {/* {isErrMsgView && !shopAndDropDeliveryDetails?.paymentReferenceNumber && (
                          <p className="err-msg">Reference number is required</p>
                        )} */}
                      </div> : ""}
                    <div className="check-in-bag-list-input">
                      <p>
                        Remarks{" "}
                        <span style={{ color: "gray" }}>(max 1000 chars)</span>
                        <span style={{ color: "red" }}>*</span>
                      </p>
                      <InputTextarea
                        placeholder="Enter here"
                        value={
                          shopAndDropDeliveryDetails?.remarks
                            ? shopAndDropDeliveryDetails?.remarks
                            : ""
                        }
                        onChange={(e) => {
                          if (e?.target?.value?.length < 1000) {
                            handleInputValue("remarks", e.target.value);
                          }
                        }}
                      />
                      {isErrMsgView && !shopAndDropDeliveryDetails?.remarks && (
                        <p className="err-msg">Remarks is required</p>
                      )}
                      {isChargeable && (
                        <div>
                          <p>
                            <span style={{ color: "#ae9d81" }}>
                              Amount :{" "}
                            </span>
                            {" "}
                            {payableAmount}
                            {" "}
                            <span style={{ color: "#ae9d81" }}>
                              BHD
                            </span>
                          </p>
                        </div>
                      )}
                    </div>

                    <div className="check-in-bag-list-img-content">
                      <div className="check-in-bag-list-img">
                        {shopAndDropDeliveryDetails?.signatureImageIrl ? (
                          <img
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              setIsModalOpen(true);
                            }}
                            src={shopAndDropDeliveryDetails?.signatureImageIrl}
                          ></img>
                        ) : null}
                      </div>
                    </div>
                    <div className="check-in-bag-list-btn">
                      <Button
                        label={"Previous"}
                        style={{
                          backgroundColor: "#8e8e8e",
                          border: "none",
                          borderRadius: "3px",
                          width: "120px",
                          height: "40px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          marginBottom: "10px",
                        }}
                        onClick={() => {
                          props.handleStepChange({
                            stepLabel: stepperNames.bagInformation,
                            index: 1,
                          });
                        }}
                      />
                      <Button
                        label={
                          shopAndDropDeliveryDetails?.signatureImageIrl
                            ? "Check-in"
                            : "Signature"
                        }
                        style={{
                          backgroundColor: "#ae9d81",
                          border: "none",
                          borderRadius: "3px",
                          width: "120px",
                          height: "40px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          marginBottom: "10px",
                        }}
                        onClick={() => {
                          if (shopAndDropDeliveryDetails?.signatureImageIrl) {
                            let isValidation =
                              shopAndDropDeliveryDetails?.remarks
                            // &&
                            // (isHomeDelivery
                            //   ? shopAndDropDeliveryDetails?.amount
                            //   : true);
                            setIsErrMsgView(!isValidation);
                            if (isValidation) {
                              props.setIsCheckedIn(true);
                              setIsConformationOpen(true);
                            }
                          } else {
                            setIsModalOpen(true);
                          }
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Dialog
            header={
              <h3 style={{ textAlign: "center", color: "#ae9d81", margin: 0 }}>
                Signature
              </h3>
            }
            visible={isModalOPen}
            style={{ width: "40%" }}
            onHide={() => {
              setIsModalOpen(false);
              setIsSinatureErrMsgView(false);
              setIsSigErrView(false);
            }}
          >
            <div className="signature-modal">
              <div className="signature-modal-content">
                <Signature
                  imageUrl={shopAndDropDeliveryDetails.signatureImageIrl}
                  isSinatureErrMsgView={isSinatureErrMsgView}
                  getImageUrl={getImageUrl}
                  isImageView={false}
                  signatureStyels={{
                    width: "60%",
                    margin: "auto",
                    height: "150px",
                  }}
                />
                {isSigErrView && !shopAndDropDeliveryDetails?.remarks && (
                  <p className="err-msg">Signature is required</p>
                )}
              </div>
            </div>
          </Dialog>
          <Dialog
            header={
              <h3 style={{ textAlign: "center", color: "#ae9d81", margin: 0 }}>
                Success
              </h3>
            }
            visible={isSuceesModalOpen}
            style={{ width: "30%" }}
            onHide={() => {
              setIsSuccessModalOpen(false);
              props.handleStepChange({
                stepLabel: stepperNames.guestInformation,
                index: 0,
              });
              props.handleReset();
            }}
          >
            <div className="success-modal">
              <div className="success-modal-content">
                <p>Successfully checked in</p>
              </div>
              <div className="success-modal-btn">
                <Button
                  label={"Ok"}
                  style={{
                    backgroundColor: "#ae9d81",
                    border: "none",
                    borderRadius: "3px",
                    width: "120px",
                    height: "40px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginBottom: "30px",
                  }}
                  onClick={() => {
                    setIsSuccessModalOpen(false);
                    props.handleStepChange({
                      stepLabel: stepperNames.guestInformation,
                      index: 0,
                    });
                    props.handleReset();
                  }}
                />
              </div>
            </div>
          </Dialog>
          <Dialog
            header={
              <h3 style={{ textAlign: "center", color: "#ae9d81", margin: 0 }}>
                Confirmation
              </h3>
            }
            visible={isConformationOpen}
            style={{ width: "30%" }}
            onHide={() => {
              setIsConformationOpen(false);
              props.setIsCheckedIn(false);
            }}
          >
            <div className="track-delivery-view-success-modal">
              <div className="success-modal-content">
                <p style={{ fontSize: "18px" }}>
                  Are you sure want to check-in?
                </p>
              </div>
              <div className="success-modal-btn" style={{ gap: "10px" }}>
                <Button
                  label={"No"}
                  style={{
                    backgroundColor: "#8e8e8e",
                    border: "none",
                    borderRadius: "3px",
                    width: "120px",
                    height: "40px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  onClick={() => {
                    setIsConformationOpen(false);
                    props.setIsCheckedIn(false);
                  }}
                />
                <Button
                  label={"Yes"}
                  loading={btnLoading}
                  style={{
                    backgroundColor: "#ae9d81",
                    border: "none",
                    borderRadius: "3px",
                    width: "120px",
                    height: "40px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  onClick={() => {
                    setBtnLoading(true);
                    setIsConformationOpen(false);
                    handleCheckIn();
                  }}
                />
              </div>
            </div>
          </Dialog>
          <Dialog
            header={
              <h3 style={{ textAlign: "center", color: "#ae9d81", margin: 0 }}>
                Print Receipt
              </h3>
            }
            visible={printDialog}
            style={{ width: "30%" }}
            onHide={() => {
              setPrintDialog(false);
            }}
          >
            <div className="track-delivery-view-success-modal">
              <div className="success-modal-content">
                <p style={{ fontSize: "18px" }}>
                  Are you sure want to print receipt?
                </p>
              </div>
              <div className="success-modal-btn" style={{ gap: "10px" }}>
                <Button
                  label={"No"}
                  style={{
                    backgroundColor: "#8e8e8e",
                    border: "none",
                    borderRadius: "3px",
                    width: "120px",
                    height: "40px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  onClick={() => {
                    setPrintDialog(false);
                    props.handleStepChange({
                      stepLabel: stepperNames.guestInformation,
                      index: 0,
                    });
                  }}
                />
                <Button
                  label={"Yes"}
                  // loading={btnLoading}
                  style={{
                    backgroundColor: "#ae9d81",
                    border: "none",
                    borderRadius: "3px",
                    width: "120px",
                    height: "40px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  onClick={() => {
                    handlePrint(null, () => contentToPrint.current);
                  }}
                />
              </div>
            </div>
          </Dialog>
          <div className="print">
            <div ref={contentToPrint} >
              {/* <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                <div style={{ paddingLeft: "4.5rem", paddingRight: "20px", width: "50%" }}>
                  <div style={{ margin: 10 }}>
                    <span style={{ display: "block" }}>Marassi Galleria</span>
                    <span style={{ margin: "0px", padding: "0px" }}>Hands Free</span>
                  </div>
                  <br />
                  <div style={{ margin: 10 }}>
                    <span style={{ display: "block" }}>Delivery Service</span>
                    <span style={{ margin: "0px", padding: "0px" }}>Ref No : {printDetail.refrenceNo||"-"}</span>
                  </div>
                </div>

                <div style={{ paddingLeft: "20px", borderLeft: "solid", width: "50%" }} >
                  <div style={{ paddingTop: "2px", paddingBottom: "2px" }}>
                    <span>Delivery Type: {printDetail.deliveryType||"-"}</span>
                  </div>
                  <div style={{ paddingTop: "2px", paddingBottom: "2px" }}>
                    <span>Name: {printDetail.name||"-"}</span>
                  </div>
                  <div style={{ paddingTop: "2px", paddingBottom: "2px" }}>
                    <span>Tel: {printDetail.mobileNumber||"-"}</span>
                  </div>
                  <div style={{ paddingTop: "2px", paddingBottom: "2px" }}>
                    <span>No Bags: {printDetail.nobags||"-"}</span>
                  </div>
                  <div style={{ paddingTop: "2px", paddingBottom: "2px" }}>
                    <span>CheckIn: {printDetail.checkin||"-"}</span>
                  </div>
                  <div style={{ paddingTop: "2px", paddingBottom: "2px" }}>
                    <span>Delivery Date: {printDetail.deliveryDate||"-"}</span>
                  </div>
                  <div style={{ paddingTop: "2px", paddingBottom: "2px" }}>
                    <span>Delivery Time: {printDetail.deliveryTime||"-"}</span>
                  </div>
                </div>
              </div> */}


              <div style={{ width: '100%', margin: 'auto' }} >
                <div style={{ display: 'flex', aligntems: 'center', padding: '8px', border: 'solid 2px #5f3e3bff', margin: '6px', outline: 'solid 1px #5f3e3bff', outlineOffset: '2px' }}>
                  <div style={{ width: '27%', borderRight: 'solid 1px #000' }}>
                    <div style={{ textAlign: "center" }}>
                      <img src="https://marassigalleria.bh/wp-content/uploads/2023/05/logomarassi.svg" style={{ textAlign: "center" }} />
                    </div>
                    <div>
                      <span style={{ display: 'block', fontWeight: 700, textAlign: 'center', paddingTop: '0.4rem', paddingBottom: '0.4rem', fontSize: '1.3rem', color: '#5f3e3bff' }}>Hands-Free</span>
                    </div>
                    <div>
                      <span style={{ display: 'block', textDecoration: 'underline', paddingTop: '0.4rem', paddingBottom: '0.4rem', fontWeight: 700, textAlign: 'center', fontSize: '1.3rem', color: '#130b0aff' }}>{printDetail?.deliveryType}</span>
                    </div>
                    <div style={{ paddingTop: '0.4rem', paddingBottom: '0.4rem' }}>
                      <span style={{ display: 'block', fontWeight: 600, textAlign: 'center', color: '#130b0aff' }}>Tag No : {printDetail?.tagNo || "-"}</span>
                      <span style={{ display: 'block', fontWeight: 600, textAlign: 'center', color: '#130b0aff' }}>Ref : {printDetail?.refrenceNo}</span>
                    </div>
                  </div>
                  <div style={{ width: '70%', paddingLeft: '1rem' }}>
                    <div>
                      <span style={{ display: 'block', fontWeight: 600, paddingTop: '0.1rem', paddingBottom: '0.1rem', color: '#130b0aff' }}>Name: {printDetail?.name}</span>
                      <span style={{ display: 'block', fontWeight: 600, paddingTop: '0.1rem', paddingBottom: '0.1rem', color: '#130b0aff' }}>Tel: {printDetail?.mobileNumber}</span>
                      <span style={{ display: 'block', fontWeight: 600, paddingTop: '0.1rem', paddingBottom: '0.1rem', color: '#130b0aff' }}>No Of Bags: {printDetail?.nobags}</span>
                    </div>
                    <div style={{ paddingTop: '1rem', paddingBottom: '1rem', fontWeight: 600 }}>
                      <span style={{ color: '#130b0aff' }}  >Pickup Location: {printDetail?.pickupLocation || "-"}</span>
                    </div>
                    <div>
                      <span style={{ display: 'block', fontWeight: 600, paddingTop: '0.1rem', paddingBottom: '0.1rem', color: '#130b0aff' }}>Delivery Date: {printDetail?.deliveryDate || "-"}</span>
                      <span style={{ display: 'block', fontWeight: 600, paddingTop: '0.1rem', paddingBottom: '0.1rem', color: '#130b0aff' }}>Delivery Time: {printDetail?.deliveryTime || "-"}</span>
                    </div>
                  </div>
                </div >
              </div>
            </div>
          </div >
        </>
      )
      }
    </>
  );
};

export default CheckIn;
