// signature
import { useEffect, useState } from "react";
import SignatureCanvas from "react-signature-canvas";
import { Button } from "primereact/button";

const Signature = (props) => {
  const [state, setState] = useState("");

  var sigPad = {};

  const clear = () => {
    sigPad.clear();
  };

  const trim = () => {
    if (sigPad.isEmpty()) {
      props.getImageUrl("");
    } else {
      props.getImageUrl(sigPad?.getTrimmedCanvas()?.toDataURL("1"));
    }
    setState(sigPad?.getTrimmedCanvas()?.toDataURL("1"));
  };
  useEffect(() => {
    sigPad.fromDataURL(props.imageUrl);
    if (!props.imageUrl) {
      sigPad.clear();
    }
  }, []);

  return (
    <>
      <div style={props.signatureStyels ? props.signatureStyels : {}}>
        <SignatureCanvas
          onEnd={(e) => {
            // console.log("onEnd", e);
          }}
          onBegin={(e) => {
            // console.log("onBegin", e);
          }}
          penColor="black"
          velocityFilterWeight={1}
          minWidth={0.5}
          maxWidth={2.5}
          throttle={1}
          canvasProps={{
            className: "sigCanvas",
            style: {
              fontSize: "13px",
              width: "100%",
              height: "100%",
              border: "1px solid black",
            },
          }}
          ref={(ref) => {
            sigPad = ref;
          }}
        />
        {props.isSinatureErrMsgView && (
          <p className="err-msg" style={{ color: "red" }}>
            Signature is required
          </p>
        )}
      </div>

      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          gap: "10px",
          padding: "30px 0px 10px",
        }}
      >
        <Button
          label="Clear"
          style={{
            backgroundColor: "#8e8e8e",
            border: "none",
            width: "100px",
            padding: "5px 0px",
          }}
          onClick={() => {
            clear();
          }}
        />
        <Button
          label={"Save"}
          style={{
            backgroundColor: "#ae9d81",
            border: "none",
            width: "100px",
            padding: "5px 0px",
          }}
          onClick={() => {
            trim();
          }}
        />
      </div>
      {props.isImageView && state ? (
        <img className={""} alt="signature" src={state} />
      ) : null}
    </>
  );
};

export default Signature;
