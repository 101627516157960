import React from "react";
import "./style.scss";
import { useLocation } from "react-router";

function Footer() {
  const location = useLocation();
  return (
    <>
      {location.pathname !== "/" &&
      location.pathname !== "/feedbackform" &&
      location.pathname !== "/guest-login" ? (
        <div
          style={{
            position: "fixed",
            width: "100%",
            bottom: "0 ",
            zIndex: 100,
          }}
        >
          <p className="cp-text">
            © Copyright {new Date().getFullYear()} Marassi Galleria. All rights
            reserved.
          </p>
        </div>
      ) : (
        ""
      )}
    </>
  );
}

export default Footer;
